import { mergeProps, createSignal } from 'solid-js';
import {
  DynamicPartition,
  PartitionProps,
  minPartitionProps,
  maxPartitionProps,
  stepPartitionProps,
} from '~/components/designer2/DynamicPartition';
import { PersistentClient } from '~/shared/PersistentClient';
import { LogLevel } from '~/components/designer2/DynamicLog';
import {
  ImageOperationType,
  CropFrame,
  CoreImageOperation,
} from '~/shared/CoreImageOperation';

export class BandProps {
  // dimension length of the side of a single square partition
  dim: number;
  // dimension length when displaying vertically
  vdim: number;
  // Four possible values: 0, 90, 180, 270: 0 = left-right, 90 = top-bottom, 180 = right-left, 270 = bottom-top
  rotation: number;
  // Partition currently being edited - default 0 for band edit
  editing: number;
  // How many active partitions:
  partitionCount: number;
  // Include functions in progress
  alpha: string;
  // The currently active UI ImageOperation tool
  imageOperationType: ImageOperationType;
  // User and band data
  bandName: string;
  bandSecret: string;
  userEmail: string;
  userPhone: string;
  // window sizing
  editPartitionX: number;
  editPartitionY: number;
  editPartitionW: number;
  editPartitionH: number;
  showDebugConsole: boolean;
  isIFrame: boolean;
}
const defaultBandProps = {
  dim: 128,
  vdim: 80,
  rotation: 0,
  editing: 0,
  partitionCount: 7,
  alpha: 'false',
  imageOperationType: ImageOperationType.None,
  bandName: '',
  bandSecret: '',
  userEmail: '',
  userPhone: '',
  showDebugConsole: false,
  isIFrame: false,
} as BandProps;

export class Sticker {
  title: string = '';
  unicode: number = 0;
  uri: string = '';
}
export class GalleryPhoto {
  imageid: number = 0;
  title: string = '';
  local_filename: string = '';
  scale: number = 1.0;
  //	imgsrc:
  //		string = '';
  thumb_dataurl: string = '';
}
class PointerState {
  // co-ordinates of first touch
  x0: number;
  y0: number;
  // co-ordinates currently
  x1: number;
  y1: number;
  // view dimensions and position at start:
  clientX: number = 0;
  clientY: number = 0;
  clientW: number = 0;
  clientH: number = 0;
  // partition state at start of move:
  translateX: number = 0;
  translateY: number = 0;
  scale: number = 1;
  rotation: number = 0;
}
enum CompletionStep {
  name = 0,
  pic_1,
  pic_2,
  pic_3,
  pic_4,
  pic_5,
  pic_6,
  pic_7,
  email,
  //	phone,
  MAX,
}

export class DynamicBand {
  sigGetDim: Function;
  sigSetDim: Function;
  sigGetVDim: Function;
  sigSetVDim: Function;
  sigGetRotation: Function;
  sigSetRotation: Function;
  sigGetEditing: Function;
  sigSetEditing: Function;
  sigGetImageOperationType: Function;
  sigSetImageOperationType: Function;
  sigGetStickers: Function;
  sigSetStickers: Function;
  sigGetGallery: Function;
  sigSetGallery: Function;
  sigGetCompleted: Function;
  sigSetCompleted: Function;
  sigGetName: Function;
  sigSetName: Function;
  sigGetSecret: Function;
  sigSetSecret: Function;
  sigGetEmail: Function;
  sigSetEmail: Function;
  sigGetPhone: Function;
  sigSetPhone: Function;
  sigGetEditPartitionX: Function;
  sigSetEditPartitionX: Function;
  sigGetEditPartitionY: Function;
  sigSetEditPartitionY: Function;
  sigGetEditPartitionW: Function;
  sigSetEditPartitionW: Function;
  sigGetEditPartitionH: Function;
  sigSetEditPartitionH: Function;
  sigGetShowConfetti: Function;
  sigSetShowConfetti: Function;
  sigGetFailureMessage: Function;
  sigSetFailureMessage: Function;
  sigGetIsSubmitting: Function;
  sigSetIsSubmitting: Function;
  sigGetProgress: Function;
  sigSetProgress: Function;
  bandProps: BandProps;
  stepsCompleted: number = 0;
  showConfetti: boolean = false;
  isSubmitting: boolean = false;
  progress: number = 0; // per cent between 0 and 100
  failureMessage: string = '';
  static radToDeg: number = 180 / Math.PI;
  // there is only ever one instance of this class, we keep track of it here:
  public static instance: DynamicBand;
  partitions: Array<DynamicPartition> = new Array<DynamicPartition>(8);
  draggingPartition: number = 0;
  stickers: Sticker[] = [
    //		{title: "none",				unicode:       0, uri: '' },
    { title: 'sparkles', unicode: 0x2728 }, // ✨
    { title: 'heart', unicode: 0x2764 }, // ❤️
    { title: 'grinning face', unicode: 0x1f603 }, // 😃
    { title: 'party popper', unicode: 0x1f389 }, // 🎉
    { title: 'thumbs up', unicode: 0x1f44d }, // 👍
    { title: 'sparkling heart', unicode: 0x1f496 }, // 💖
    { title: 'beating heart', unicode: 0x1f493 }, // 💓
    { title: 'laughing', unicode: 0x1f602 }, // 😂
    { title: 'ribbon', unicode: 0x1f380 }, // 🎀
    { title: 'kiss', unicode: 0x1f48b }, // 💋
    { title: 'butterfly', unicode: 0x1f98b }, // 🦋
  ];
  gallery: GalleryPhoto[] = [
    { imageid: 0, title: 'none', local_filename: '', scale: 1 },
  ];
  pointers: Map<number, PointerState> = new Map<number, PointerState>();
  primaryPointerStack: Array<number> = new Array<number>();
  constructor(props: BandProps = {}) {
    this.bandProps = mergeProps(defaultBandProps, props);
    [this.sigGetDim, this.sigSetDim] = createSignal(this.bandProps.dim);
    [this.sigGetVDim, this.sigSetVDim] = createSignal(this.bandProps.vdim);
    [this.sigGetRotation, this.sigSetRotation] = createSignal(
      this.bandProps.rotation
    );
    [this.sigGetEditing, this.sigSetEditing] = createSignal(
      this.bandProps.editing
    );
    [this.sigGetImageOperationType, this.sigSetImageOperationType] =
      createSignal(this.bandProps.imageOperationType);
    [this.sigGetStickers, this.sigSetStickers] = createSignal(this.stickers);
    [this.sigGetGallery, this.sigSetGallery] = createSignal(this.gallery);
    [this.sigGetCompleted, this.sigSetCompleted] = createSignal(
      this.stepsCompleted
    );
    [this.sigGetName, this.sigSetName] = createSignal(this.bandProps.bandName);
    [this.sigGetSecret, this.sigSetSecret] = createSignal(
      this.bandProps.bandSecret
    );
    [this.sigGetEmail, this.sigSetEmail] = createSignal(
      this.bandProps.userEmail
    );
    [this.sigGetPhone, this.sigSetPhone] = createSignal(
      this.bandProps.userPhone
    );
    [this.sigGetEditPartitionX, this.sigSetEditPartitionX] = createSignal(
      this.bandProps.editPartitionX
    );
    [this.sigGetEditPartitionY, this.sigSetEditPartitionY] = createSignal(
      this.bandProps.editPartitionY
    );
    [this.sigGetEditPartitionW, this.sigSetEditPartitionW] = createSignal(
      this.bandProps.editPartitionW
    );
    [this.sigGetEditPartitionH, this.sigSetEditPartitionH] = createSignal(
      this.bandProps.editPartitionH
    );
    [this.sigGetShowConfetti, this.sigSetShowConfetti] = createSignal(
      this.showConfetti
    );
    [this.sigGetIsSubmitting, this.sigSetIsSubmitting] = createSignal(
      this.isSubmitting
    );
    [this.sigGetProgress, this.sigSetProgress] = createSignal(this.progress);
    [this.sigGetFailureMessage, this.sigSetFailureMessage] = createSignal(
      this.failureMessage
    );
    DynamicBand.instance = this;
    const perisitentClient = PersistentClient.instance;
    if (perisitentClient) {
      // this should only run in the browser
      perisitentClient.populateGallery(this);
      perisitentClient.populateBand();
    }
  }
  // we want the sliders to be in the middle for the default, so we fit
  // 0 (minimum) -> 1 (middle/default) -> 2 (maximum) step 0.1
  public static mapToRange(
    value: number,
    min: number,
    def: number,
    max: number
  ): number {
    let rvalue = value;
    if (value <= def) {
      // left side
      rvalue = (value - min) / (def - min);
    } else {
      // right side
      rvalue = 1 + (value - def) / (max - def);
    }
    return rvalue;
  }
  public static mapFromRange(
    rvalue: number,
    min: number,
    def: number,
    max: number
  ): number {
    let value = rvalue;
    if (rvalue <= 1.0) {
      // left side
      value = rvalue * (def - min) + min;
    } else {
      // right side
      value = (rvalue - 1) * (max - def) + def;
    }
    return value;
  }
  public static resize() {
    const rect = document.getElementById('partition0').getBoundingClientRect();
    const x0 = Math.round(rect.x + window.scrollX);
    const y0 = Math.round(rect.y + window.scrollY);
    const partition0clear = document.getElementById('partition0clear');
    partition0clear.style.left =
      (x0 + Math.round(0.96 * rect.width) - 48).toString() + 'px';
    partition0clear.style.top =
      (y0 + Math.round(0.04 * rect.height)).toString() + 'px';
    partition0clear.style.visibility = 'visible';
    const partition0add = document.getElementById('partition0add');
    partition0add.style.left =
      (x0 + Math.round(0.04 * rect.width)).toString() + 'px';
    partition0add.style.top =
      (y0 + Math.round(0.96 * rect.height) - 48).toString() + 'px';
    partition0add.style.visibility = 'visible';
  }
  public rotate90() {
    // 0 = left-right, 90 = top-bottom, 180 = right-left, 270 = bottom-top
    const rotation = PartitionProps.rotateModulo(this.sigGetRotation() + 90);
    this.sigSetRotation(rotation);
    if (PersistentClient.instance) {
      // only client side
      PersistentClient.instance.setBandRotation(rotation);
    }
    DynamicBand.resize();
  }
  public toggleOrientation() {
    // 0 = left-right, 90 = top-bottom, 180 = right-left, 270 = bottom-top
    const rotation = Number(this.sigGetRotation()) === 0 ? 90 : 0;
    this.sigSetRotation(rotation);
    const partitionDelta = rotation === 90 ? 90 : -90;
    for (let pindex = 1; pindex < 8; ++pindex) {
      const newRotation = PartitionProps.rotateModulo(
        Number(this.partitionGetImgRotation(pindex)) + partitionDelta
      );
      const newTransX =
        partitionDelta === 90
          ? -Number(this.partitionGetImgTranslateY(pindex))
          : Number(this.partitionGetImgTranslateY(pindex));
      const newTransY =
        partitionDelta === 90
          ? Number(this.partitionGetImgTranslateX(pindex))
          : -Number(this.partitionGetImgTranslateX(pindex));
      const newScale = Number(this.partitionGetImgScale(pindex));
      this.partitionSetImgPosition(
        newTransX,
        newTransY,
        newScale,
        newRotation,
        pindex,
        true
      );
      if (Number(this.sigGetEditing()) === pindex) {
        this.partitionSetImgPosition(
          newTransX,
          newTransY,
          newScale,
          newRotation,
          0,
          false
        );
      }
    }
    if (PersistentClient.instance) {
      // only client side
      PersistentClient.instance.setBandRotation(rotation);
    }
    DynamicBand.resize();
  }
  public isVertical(): boolean {
    const rot = this.sigGetRotation();
    return rot === 90 || rot === 270;
  }
  public imageIdChange(oldId: number, newId: number) {
    const gallery = this.sigGetGallery();
    let changed = false;
    for (const galleryItem of gallery) {
      if (Number(galleryItem.imageid) === Number(oldId)) {
        galleryItem.imageid = Number(newId);
        changed = true;
      }
    }
    if (changed) {
      this.sigSetGallery(gallery);
    }
    for (let i = 1; i <= 7; ++i) {
      if (
        this.partitions[i] &&
        Number(this.partitions[i].sigGetImageid()) === Number(oldId)
      ) {
        this.partitions[i].sigSetImageid(Number(newId));
      }
    }
  }
  public static fromBitstring(bits: number, bitlen: number = 0): boolean[] {
    let bitarray = bits ? [] : [false];
    while (bits) {
      bitarray.push((bits & 1) === 1);
      bits >>= 1;
    }
    return bitarray.concat(new Array(bitlen - bitarray.length).fill(false));
  }
  public static toBitstring(bitarray: boolean[]): number {
    let bit = 1;
    let bits = 0;
    for (const b of bitarray) {
      if (b) {
        bits = bits | bit;
      }
      bit = bit << 1;
    }
    return bits;
  }
  public setComplete(step: CompletionStep, is: boolean = true) {
    const steps = DynamicBand.fromBitstring(
      this.sigGetCompleted(),
      CompletionStep.MAX
    );
    steps[step] = is;
    this.sigSetCompleted(DynamicBand.toBitstring(steps));
  }
  public isComplete(): boolean {
    // have we done everything needed?
    return !DynamicBand.fromBitstring(
      this.sigGetCompleted(),
      CompletionStep.MAX
    ).includes(false);
  }
  public completionMessage(): string {
    let msg = '';
    const steps = DynamicBand.fromBitstring(
      this.sigGetCompleted(),
      CompletionStep.MAX
    );
    for (let i = 0; i < CompletionStep.MAX; ++i) {
      if (steps[i] === false) {
        msg =
          msg +
          (msg.length > 0 ? ', ' : '') +
          CompletionStep[i].replaceAll('_', ' ');
      }
    }
    return msg.length > 0 ? 'please complete these steps: ' + msg : '';
  }
  public async saveOnClient() {
    // the user wants to commit this band for production
    if (this.isComplete()) {
      const partitionProps = new Array<PartitionProps>();
      for (let i = 1; i < 8; ++i) {
        if (this.partitions[i]) {
          partitionProps.push(
            this.partitions[i].sigSetToProps(new PartitionProps())
          );
        }
      }
      if (PersistentClient.instance) {
        // only client side
        this.sigSetProgress(0);
        this.sigSetIsSubmitting(true);
        // const uriProduct = PersistentClient.instance.createProductOnWorker(this, partitionProps);
        const uriProduct = PersistentClient.instance.createProductOnClient(
          this,
          partitionProps
        );
      }
    }
  }
  public async saveOnWorker() {
    // the user wants to commit this band for production
    if (this.isComplete()) {
      const partitionProps = new Array<PartitionProps>();
      for (let i = 1; i < 8; ++i) {
        if (this.partitions[i]) {
          partitionProps.push(
            this.partitions[i].sigSetToProps(new PartitionProps())
          );
        }
      }
      if (PersistentClient.instance) {
        // only client side
        this.sigSetProgress(0);
        this.sigSetIsSubmitting(true);
        // const uriProduct = PersistentClient.instance.createProductOnWorker(this, partitionProps);
        const uriProduct = PersistentClient.instance.createProductOnWorker(
          this,
          partitionProps
        );
      }
    }
  }
  /**
   * Go to product page, e.g. https://shop.wearshare.com/products/dyo-og7-89450bf4?variant=41712039919706
   * To go to the cart: https://shop.wearshare.com/cart/add?id=41712039919706
   * If iframe, call POST https://shop.wearshare.com/cart/add.js id=41712039919706 quantity=1
   * https://shopify.dev/docs/api/ajax/reference/cart
   */
  public async saveWorked(productUrl: string) {
    this.sigSetIsSubmitting(false);
    this.sigSetProgress(0);
    this.sigSetShowConfetti(true);
    setTimeout(() => {
      this.sigSetShowConfetti(false);
    }, 5000); // Hide after 5 seconds
    await new Promise((resolve) => setTimeout(resolve, 100)); // after 0.1 second
    // promote the band to pre-production, create new band to be editing
    if (this.bandProps.isIFrame) {
      const variantPos = productUrl.indexOf('variant=');
      if (variantPos === -1) {
        window.open(productUrl, 'product');
      } else {
        const variantid = productUrl.substring(variantPos + 8);
        const quantity = 1;
        const formData = {
          items: [{ id: Number(variantid), quantity: Number(quantity) }],
        };
        try {
          const message = JSON.stringify(formData);
          PersistentClient.log(LogLevel.debug, 'cartAdd message', message);
          //window.parent.postMessage(message, "https://shop.wearshare.com/pages/create-your-own-developer");
          //window.parent.postMessage(message, "https://shop.wearshare.com/pages/create-your-own-designer");
          window.parent.postMessage('CARTADD:' + message, '*');
          PersistentClient.log(LogLevel.debug, 'cartAdd message dispatched');
        } catch (error) {
          PersistentClient.log(
            LogLevel.warn,
            'cartAdd message exception',
            error
          );
        }
      }
    } else if (this.bandProps.showDebugConsole) {
      window.open(productUrl, 'product');
    } else {
      window.open(productUrl, '_self');
    }
  }
  public setName(value: string = '', commit: boolean = true): boolean {
    let worked = true;
    this.setComplete(CompletionStep.name, value.length > 0);
    this.sigSetName(value);
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.setBandName(value);
    }
    return worked;
  }
  public setSecret(value: string = '', commit: boolean = true): boolean {
    let worked = true;
    this.sigSetSecret(value);
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.setBandSecret(value);
    }
    return worked;
  }
  public setEmail(value: string = '', commit: boolean = true): boolean {
    let worked = true;
    this.setComplete(CompletionStep.email, value.length > 0);
    this.sigSetEmail(value);
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.setUserEmail(value);
    }
    return worked;
  }
  public setPhone(value: string = '', commit: boolean = true): boolean {
    let worked = true;
    this.setComplete(CompletionStep.phone, value.length > 0);
    this.sigSetPhone(value);
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.setUserPhone(value);
    }
    return worked;
  }
  // add files, and if they are images, set them consecutively to the partitions
  // pindex is partition index 1-7, 0 is the editing partition, -1 means first blank one
  public addFiles(files: File[], pindex: number = -1) {
    // if our first target is blank, do not overwrite (just add to the gallery)
    // if our first target is not blank, just go consecutively
    let onlyBlankTargets = false;
    if (pindex === -1) {
      // find the first un-imaged partition:
      for (let i = 1; i <= this.bandProps.partitionCount; ++i) {
        if (
          this.partitions[i] &&
          (!this.partitions[i].sigGetImgLocalFilename() ||
            this.partitions[i].sigGetImgLocalFilename().length === 0)
        ) {
          pindex = i;
          onlyBlankTargets = true;
          break;
        }
      }
      if (pindex === -1) {
        if (this.sigGetEditing() > 0) {
          pindex = this.sigGetEditing();
        } else {
          pindex = 1;
        }
      }
    }
    if (
      !onlyBlankTargets &&
      this.partitions[pindex] &&
      (!this.partitions[pindex].sigGetImgLocalFilename() ||
        this.partitions[pindex].sigGetImgLocalFilename().length === 0)
    ) {
      // our first target is blank
      onlyBlankTargets = true;
    }
    const perisitentClient = PersistentClient.instance;
    if (perisitentClient) {
      // only client side
      for (const file of files) {
        if (file.type.startsWith('image/')) {
          perisitentClient.addImage(file, this, pindex);
          if (pindex === 0) {
            pindex = this.sigGetEditing();
          }
          pindex = pindex === this.bandProps.partitionCount ? 1 : pindex + 1;
          if (onlyBlankTargets) {
            let count = 0;
            while (
              pindex > 0 &&
              count < this.bandProps.partitionCount &&
              this.partitions[pindex] &&
              this.partitions[pindex].sigGetImgLocalFilename() &&
              this.partitions[pindex].sigGetImgLocalFilename().length > 0
            ) {
              pindex =
                pindex === this.bandProps.partitionCount ? 1 : pindex + 1;
              ++count;
            }
            if (count === this.bandProps.partitionCount) {
              // all partitions now have an image, and we are only filling blanks
              pindex = -1;
            }
          }
        }
      }
    }
  }
  public setPartitionInst(partition: DynamicPartition) {
    const pindex = partition.sigGetIndex();
    if (this.partitions[pindex]) {
      partition.sigSetFrom(this.partitions[pindex]);
    }
    this.partitions[pindex] = partition;
    if (pindex === 0) {
      const eindex = this.sigGetEditing();
      if (eindex > 0 && this.partitions[eindex]) {
        partition.sigSetFrom(this.partitions[eindex]);
      }
    }
  }
  // The child object passes its class instance to this DynamicBand
  public static setPartition(partition: DynamicPartition) {
    if (DynamicBand.instance) {
      DynamicBand.instance.setPartitionInst(partition);
    }
  }
  public selectPartition(pindex: number) {
    this.sigSetImageOperationType(ImageOperationType.None);
    const eindex = this.sigGetEditing();
    if (eindex > 0) {
      this.partitions[eindex].sigSetSelected('false');
    }
    this.sigSetEditing(Number(pindex));
    if (pindex > 0) {
      // we have clicked one of the band partitions
      if (this.partitions[0] && this.partitions[pindex]) {
        this.partitions[0].sigSetFrom(this.partitions[pindex]);
        // PersistentClient.log(LogLevel.warn, 'DynamicBand.selectPartition ', this.partitions[0]);
        this.partitions[pindex].sigSetSelected('true');
      }
    } else {
      // we have closed editing a partition
      if (this.partitions[0]) {
        const dest = this.partitions[0].sigGetIndex();
        if (this.partitions[dest]) {
          this.partitions[dest].sigSetFrom(this.partitions[0]);
        }
      }
    }
  }
  public selectDraggingPartition(index: number = 0) {
    this.draggingPartition = index;
  }
  public isDraggingPartition(): boolean {
    return this.draggingPartition > 0;
  }
  public dragOverUpload(event: Event) {
    if (!this.isDraggingPartition()) {
      event.preventDefault();
    }
  }
  public dropOnPartition(index: number, event: Event) {
    if (this.isDraggingPartition()) {
      if (this.draggingPartition !== index && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.bandDropPartition(
          this.draggingPartition,
          index
        );
      }
      this.draggingPartition = 0;
    } else {
      this.addFiles(
        event.target.files || (event.dataTransfer && event.dataTransfer.files),
        index
      );
    }
  }
  public pointerDown(event: Event): boolean {
    let handled = false;
    const pindex = 0;
    const eindex = this.sigGetEditing();
    if (this.partitions[pindex] && this.partitions[pindex].hasImage()) {
      // only if we have an image
      const rect = event.target.getBoundingClientRect();
      PersistentClient.log(LogLevel.debug, 'pointerDown', event, rect);
      const startState = {
        x0: event.clientX,
        y0: event.clientY,
        x1: event.clientX,
        y1: event.clientY,
        clientX: rect.left,
        clientY: rect.top,
        clientW: rect.width,
        clientH: rect.height,
      } as PointerState;
      // store the initial values:
      startState.translateX = parseFloat(
        this.partitions[pindex].sigGetImgTranslateX()
      );
      startState.translateY = parseFloat(
        this.partitions[pindex].sigGetImgTranslateY()
      );
      startState.scale = parseFloat(this.partitions[pindex].sigGetImgScale());
      startState.rotation = parseInt(
        this.partitions[pindex].sigGetImgRotation()
      );
      // put this pointer on the stack (top one is first, next is second for pinch twist)
      this.primaryPointerStack.push(event.pointerId);
      // add this pointer to the map
      this.pointers.set(event.pointerId, startState);
      handled = true;
    }
    if (handled) {
      //event.preventDefault();
    }
    return handled;
  }
  public pointerMove(event: Event): boolean {
    let handled = false;
    const pindex = 0;
    const eindex = this.sigGetEditing();
    if (this.partitions[pindex] && this.partitions[pindex].hasImage()) {
      // only if we have an image
      if (this.pointers.has(event.pointerId)) {
        PersistentClient.log(LogLevel.debug, 'pointerMove', event);
        const pointerRef = this.pointers.get(event.pointerId);
        pointerRef.x1 = event.clientX;
        pointerRef.y1 = event.clientY;
        // handle the change:
        this.movePinchTwist(event.pointerId, pindex);
        handled = true;
      }
    }
    if (handled) {
      //event.preventDefault();
    }
    return handled;
  }
  public pointerUp(event: Event): boolean {
    let handled = false;
    const pindex = 0;
    const eindex = this.sigGetEditing();
    if (this.partitions[pindex] && this.partitions[pindex].hasImage()) {
      // only if we have an image
      PersistentClient.log(LogLevel.debug, 'pointerUp', event);
      if (this.pointers.has(event.pointerId)) {
        const pointerRef = this.pointers.get(event.pointerId);
        pointerRef.x1 = event.clientX;
        pointerRef.y1 = event.clientY;
        // handle the change:
        this.movePinchTwist(event.pointerId, pindex);
        this.pointerComplete(pindex, event.pointerId);
      }
      handled = true;
    } else {
      document.getElementById('uploadimageoperation').click();
    }
    if (handled) {
      //event.preventDefault();
    }
    return handled;
  }
  public pointerCancel(event: Event): boolean {
    // pointerLeave will also be fired
    return false;
  }
  public pointerOut(event: Event): boolean {
    // pointerLeave will also be fired
    return false;
  }
  public pointerLeave(event: Event): boolean {
    let handled = false;
    const pindex = 0;
    const eindex = this.sigGetEditing();
    if (this.partitions[pindex] && this.partitions[pindex].hasImage()) {
      // only if we have an image
      if (this.pointers.has(event.pointerId)) {
        PersistentClient.log(LogLevel.debug, 'pointerLeave', event);
        this.pointerComplete(pindex, event.pointerId);
      }
      handled = true;
    }
    if (handled) {
      event.preventDefault();
    }
    return handled;
  }
  public pointerComplete(pindex: number, pointerId: number) {
    // commit the operation:
    if (PersistentClient.instance) {
      // only client side
      PersistentClient.log(
        LogLevel.warn,
        'DynamicBand.pointerComplete addImageOperation'
      );
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: this.sigGetEditing(),
          operationtype: ImageOperationType.PositionScaleRotate,
          param1: parseFloat(this.partitions[pindex].sigGetImgTranslateX()),
          param2: parseFloat(this.partitions[pindex].sigGetImgTranslateY()),
          param3: parseFloat(this.partitions[pindex].sigGetImgScale()),
          param4: parseInt(this.partitions[pindex].sigGetImgRotation()),
        })
      );
    }
    this.pointers.delete(pointerId);
    const primaryIndex = this.primaryPointerStack.indexOf(pointerId);
    if (primaryIndex !== -1) {
      // remove this one pointer from the stack
      this.primaryPointerStack.splice(primaryIndex, 1);
    }
  }
  public static clamp(num: number, lower: number, upper: number) {
    return Math.min(Math.max(num, lower), upper);
  }
  private movePinchTwist(pointerId: number, pindex: number = 0) {
    if (this.partitions[pindex] && this.primaryPointerStack.length > 0) {
      // 1 or more pointers:
      const pointerRef0 = this.pointers.get(this.primaryPointerStack[0]);
      if (pointerRef0) {
        if (pointerId === this.primaryPointerStack[0]) {
          // how much has it moved, accounting for pixels, and in steps:
          const delx = Math.round(
            (pointerRef0.scale * (pointerRef0.x1 - pointerRef0.x0)) /
              (pointerRef0.clientW * 0.5 * stepPartitionProps.imgtranslatex)
          );
          const dely = Math.round(
            (pointerRef0.scale * (pointerRef0.y1 - pointerRef0.y0)) /
              (pointerRef0.clientH * 0.5 * stepPartitionProps.imgtranslatey)
          );
          this.partitions[pindex].sigSetImgTranslateX(
            DynamicBand.clamp(
              pointerRef0.translateX + delx * stepPartitionProps.imgtranslatex,
              minPartitionProps.imgtranslatex,
              maxPartitionProps.imgtranslatex
            )
          );
          this.partitions[pindex].sigSetImgTranslateY(
            DynamicBand.clamp(
              pointerRef0.translateY + dely * stepPartitionProps.imgtranslatey,
              minPartitionProps.imgtranslatey,
              maxPartitionProps.imgtranslatey
            )
          );
        }
        if (
          this.pointers.size > 1 &&
          this.primaryPointerStack.length > 1 &&
          (event.pointerId === this.primaryPointerStack[0] ||
            event.pointerId === this.primaryPointerStack[1])
        ) {
          // 2 or more pointers and ours is the first or second
          const pointerRef1 = this.pointers.get(this.primaryPointerStack[1]);
          if (pointerRef1) {
            const delx0 = pointerRef1.x0 - pointerRef0.x0;
            const dely0 = pointerRef1.y0 - pointerRef0.y0;
            const delx1 = pointerRef1.x1 - pointerRef0.x1;
            const dely1 = pointerRef1.y1 - pointerRef0.y1;
            const dist0 = Math.sqrt(delx0 ** 2 + dely0 ** 2);
            const dist1 = Math.sqrt(delx1 ** 2 + dely1 ** 2);
            if (dist0 > 0) {
              const newscale =
                Math.round(
                  (pointerRef.scale * (dist1 / dist0)) /
                    stepPartitionProps.imgscale
                ) * stepPartitionProps.imgscale;
              this.partitions[pindex].sigSetImgScale(
                DynamicBand.clamp(
                  newscale,
                  minPartitionProps.imgscale,
                  maxPartitionProps.imgscale
                )
              );
            }
            const angleRad0 = Math.atan2(dely0, delx0);
            const angleRad1 = Math.atan2(dely1, delx1);
            const newrot = Math.round(
              pointerRef.rotation +
                (angleRad1 - angleRad0) * DynamicBand.radToDeg
            );
            this.partitions[pindex].sigSetImgRotation(
              DynamicBand.clamp(
                newrot,
                minPartitionProps.imgrotate,
                maxPartitionProps.imgrotate
              )
            );
          }
        }
      }
    }
  }
  public partitionUpdate(partitionProps: PartitionProps) {
    if (this.partitions[partitionProps.index]) {
      //console.warn('Updating partition:', partitionProps);
      this.partitions[partitionProps.index].sigSetFromProps(partitionProps);
      this.setComplete(
        partitionProps.index,
        !this.partitions[partitionProps.index].isEmpty()
      );
    }
    if (
      Number(this.sigGetEditing()) === Number(partitionProps.index) &&
      this.partitions[0]
    ) {
      this.partitions[0].sigSetFromProps(partitionProps);
    }
  }
  // Image Operations
  public partitionClear(pindex: number, commit: boolean = true) {
    if (pindex === -1) {
      for (let i = 1; i <= this.bandProps.partitionCount; ++i) {
        this.partitionClear(i, commit);
      }
    } else {
      if (this.partitions[pindex]) {
        this.partitions[pindex].clear(Number(this.sigGetEditing()));
      }
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.Clear,
          })
        );
      }
    }
  }
  public partitionClearSettings(pindex: number, commit: boolean = true) {
    if (pindex === -1) {
      for (let i = 1; i <= this.bandProps.partitionCount; ++i) {
        this.partitionClearSettings(i, commit);
      }
    } else {
      if (this.partitions[pindex]) {
        this.partitions[pindex].clearSettings();
      }
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.ClearSettings,
          })
        );
      }
    }
  }
  public partitionUndo(pindex: number, commit: boolean = true) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].clear(Number(this.sigGetEditing()));
    }
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
          operationtype: ImageOperationType.Undo,
        }),
        0
      ); // 0 sends the update to all, including us
    }
  }
  public partitionRedo(pindex: number, commit: boolean = true) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].clear(Number(this.sigGetEditing()));
    }
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
          operationtype: ImageOperationType.Redo,
        }),
        0
      ); // 0 sends the update to all, including us
    }
  }
  public partitionLink(pindex: number) {}
  public partitionUnlink(pindex: number) {}
  public updateGallery(gallery: GalleryPhoto[]) {
    //console.warn('updateGallery', gallery);
    for (let i = 1; i <= 7; ++i) {
      if (this.partitions[i]) {
        //console.warn('updateGallery partition', this.partitions[i]);
        if (
          (!this.partitions[i].sigGetImgLocalFilename() ||
            this.partitions[i].sigGetImgLocalFilename().length === 0) &&
          Number(this.partitions[i].sigGetImageid()) > 0
        ) {
          // we have an imageid, but no source
          for (const galleryItem of gallery) {
            if (
              Number(galleryItem.imageid) ===
              Number(this.partitions[i].sigGetImageid())
            ) {
              //console.warn('setting partition '+i+' to src '+galleryItem.src);
              this.partitions[i].sigSetImgLocalFilename(
                galleryItem.local_filename
              );
            }
          }
        }
      }
    }
    this.sigSetGallery(gallery);
  }
  public partitionSetImgLoading(value: boolean, pindex: number = 0) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetImgLoading(value);
    }
  }
  public partitionGetImgLocalFilename(pindex: number = 0): string {
    return this.partitions[pindex]
      ? this.partitions[pindex].sigGetImgLocalFilename()
      : null;
  }
  public partitionSetImgLocalFilename(
    value: string,
    scale: number = 1.0,
    imageid: number = 0,
    thumb_dataurl: string = '',
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      PersistentClient.log(
        LogLevel.debug,
        'DynamicBand.partitionSetImgLocalFilename pindex:' +
          pindex +
          ' local_filename:' +
          value +
          ' imageid:' +
          imageid
      );
      this.partitions[pindex].setImgHref(value, thumb_dataurl, imageid, scale);
      if (pindex === 0) {
        const eindex = Number(this.sigGetEditing());
        if (this.partitions[eindex]) {
          this.partitions[eindex].setImgHref(
            value,
            thumb_dataurl,
            imageid,
            scale
          );
        }
      }
    }
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
          operationtype: ImageOperationType.Gallery,
          imageid: imageid,
          param1: scale,
        })
      );
    }
  }
  public partitionGetImgCropFrame(pindex: number = 0): CropFrame {
    return this.partitions[pindex]
      ? this.partitions[pindex].sigGetImgCropFrame()
      : CropFrame.none;
  }
  public partitionSetImgCropFrame(
    value: CropFrame,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetImgCropFrame(value);
    }
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
          operationtype: ImageOperationType.Crop,
          param1: value,
        })
      );
    }
  }
  public partitionGetImgEffect(pindex: number = 0): Effect {
    return this.partitions[pindex]
      ? this.partitions[pindex].sigGetImgEffect()
      : Effect.none;
  }
  public partitionSetImgEffect(
    value: Effect,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetImgEffect(value);
    }
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
          operationtype: ImageOperationType.Effect,
          param1: value,
        })
      );
    }
  }
  public partitionGetImgTranslateX(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgTranslateX())
      : 0;
  }
  public partitionSetImgTranslateX(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgTranslateX(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.PositionScaleRotate,
            param1: valueF,
            param2: parseFloat(this.partitions[pindex].sigGetImgTranslateY()),
            param3: parseFloat(this.partitions[pindex].sigGetImgScale()),
            param4: parseInt(this.partitions[pindex].sigGetImgRotation()),
          })
        );
      }
    }
  }
  public partitionGetImgTranslateY(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgTranslateY())
      : 0;
  }
  public partitionSetImgTranslateY(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgTranslateY(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.PositionScaleRotate,
            param1: parseFloat(this.partitions[pindex].sigGetImgTranslateX()),
            param2: valueF,
            param3: parseFloat(this.partitions[pindex].sigGetImgScale()),
            param4: parseInt(this.partitions[pindex].sigGetImgRotation()),
          })
        );
      }
    }
  }
  public partitionGetImgRotation(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseInt(this.partitions[pindex].sigGetImgRotation())
      : 0;
  }
  public partitionSetImgRotation(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueI = parseInt(value);
      this.partitions[pindex].sigSetImgRotation(valueI);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.PositionScaleRotate,
            param1: parseFloat(this.partitions[pindex].sigGetImgTranslateX()),
            param2: parseFloat(this.partitions[pindex].sigGetImgTranslateY()),
            param3: parseFloat(this.partitions[pindex].sigGetImgScale()),
            param4: valueI,
          })
        );
      }
    }
  }
  public partitionGetImgScale(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgScale())
      : 1;
  }
  public partitionSetImgScale(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgScale(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.PositionScaleRotate,
            param1: parseFloat(this.partitions[pindex].sigGetImgTranslateX()),
            param2: parseFloat(this.partitions[pindex].sigGetImgTranslateY()),
            param3: valueF,
            param4: parseInt(this.partitions[pindex].sigGetImgRotation()),
          })
        );
      }
    }
  }
  public partitionSetImgPosition(
    translateX: number,
    translateY: number,
    scale: number,
    rotation: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetImgTranslateX(translateX);
      this.partitions[pindex].sigSetImgTranslateY(translateY);
      this.partitions[pindex].sigSetImgScale(scale);
      this.partitions[pindex].sigSetImgRotation(rotation);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.PositionScaleRotate,
            param1: translateX,
            param2: translateY,
            param3: scale,
            param4: rotation,
          })
        );
      }
    }
  }
  public partitionGetImgBrightness(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgBrightness())
      : 1;
  }
  public partitionSetImgBrightness(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgBrightness(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.ColorBalance,
            param1: valueF,
            param2: parseFloat(this.partitions[pindex].sigGetImgContrast()),
            param3: parseInt(this.partitions[pindex].sigGetImgHueRotate()),
            param4: parseFloat(this.partitions[pindex].sigGetImgSaturate()),
            param5: parseFloat(this.partitions[pindex].sigGetImgGrayscale()),
            param6: parseFloat(this.partitions[pindex].sigGetImgSepia()),
          })
        );
      }
    }
  }
  public partitionGetImgContrast(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgContrast())
      : 1;
  }
  public partitionSetImgContrast(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgContrast(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.ColorBalance,
            param1: parseFloat(this.partitions[pindex].sigGetImgBrightness()),
            param2: valueF,
            param3: parseInt(this.partitions[pindex].sigGetImgHueRotate()),
            param4: parseFloat(this.partitions[pindex].sigGetImgSaturate()),
            param5: parseFloat(this.partitions[pindex].sigGetImgGrayscale()),
            param6: parseFloat(this.partitions[pindex].sigGetImgSepia()),
          })
        );
      }
    }
  }
  public partitionSetImgColorBalance(
    brightness: number,
    contrast: number,
    huerotate: number,
    saturate: number,
    grayscale: number,
    sepia: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetImgBrightness(brightness);
      this.partitions[pindex].sigSetImgContrast(contrast);
      this.partitions[pindex].sigSetImgHueRotate(huerotate);
      this.partitions[pindex].sigSetImgSaturate(saturate);
      this.partitions[pindex].sigSetImgGrayscale(grayscale);
      this.partitions[pindex].sigSetImgSepia(sepia);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.ColorBalance,
            param1: brightness,
            param2: contrast,
            param3: huerotate,
            param4: saturate,
            param5: grayscale,
            param6: sepia,
          })
        );
      }
    }
  }
  public partitionGetImgHueRotate(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseInt(this.partitions[pindex].sigGetImgHueRotate())
      : 0;
  }
  public partitionSetImgHueRotate(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueI = parseInt(value);
      this.partitions[pindex].sigSetImgHueRotate(valueI);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.ColorBalance,
            param1: parseFloat(this.partitions[pindex].sigGetImgBrightness()),
            param2: parseFloat(this.partitions[pindex].sigGetImgContrast()),
            param3: valueI,
            param4: parseFloat(this.partitions[pindex].sigGetImgSaturate()),
            param5: parseFloat(this.partitions[pindex].sigGetImgGrayscale()),
            param6: parseFloat(this.partitions[pindex].sigGetImgSepia()),
          })
        );
      }
    }
  }
  public partitionGetImgSaturate(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgSaturate())
      : 1;
  }
  public partitionSetImgSaturate(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgSaturate(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.ColorBalance,
            param1: parseFloat(this.partitions[pindex].sigGetImgBrightness()),
            param2: parseFloat(this.partitions[pindex].sigGetImgContrast()),
            param3: parseInt(this.partitions[pindex].sigGetImgHueRotate()),
            param4: valueF,
            param5: parseFloat(this.partitions[pindex].sigGetImgGrayscale()),
            param6: parseFloat(this.partitions[pindex].sigGetImgSepia()),
          })
        );
      }
    }
  }
  public partitionGetImgGrayscale(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgGrayscale())
      : 0;
  }
  public partitionSetImgGrayscale(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgGrayscale(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.ColorBalance,
            param1: parseFloat(this.partitions[pindex].sigGetImgBrightness()),
            param2: parseFloat(this.partitions[pindex].sigGetImgContrast()),
            param3: parseInt(this.partitions[pindex].sigGetImgHueRotate()),
            param4: parseFloat(this.partitions[pindex].sigGetImgSaturate()),
            param5: valueF,
            param6: parseFloat(this.partitions[pindex].sigGetImgSepia()),
          })
        );
      }
    }
  }
  public partitionGetImgSepia(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgSepia())
      : 0;
  }
  public partitionSetImgSepia(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgSepia(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.ColorBalance,
            param1: parseFloat(this.partitions[pindex].sigGetImgBrightness()),
            param2: parseFloat(this.partitions[pindex].sigGetImgContrast()),
            param3: parseInt(this.partitions[pindex].sigGetImgHueRotate()),
            param4: parseFloat(this.partitions[pindex].sigGetImgSaturate()),
            param5: parseFloat(this.partitions[pindex].sigGetImgGrayscale()),
            param6: valueF,
          })
        );
      }
    }
  }
  public partitionGetImgSharpen(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgSharpen())
      : 0;
  }
  public partitionSetImgSharpen(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgSharpen(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.SharpenBlur,
            param1: valueF,
            param2: parseFloat(this.partitions[pindex].sigGetImgBlur()),
          })
        );
      }
    }
  }
  public partitionGetImgBlur(pindex: number = 0): number {
    return this.partitions[pindex]
      ? parseFloat(this.partitions[pindex].sigGetImgBlur())
      : 0;
  }
  public partitionSetImgBlur(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      const valueF = parseFloat(value);
      this.partitions[pindex].sigSetImgBlur(valueF);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.SharpenBlur,
            param1: parseFloat(this.partitions[pindex].sigGetImgSharpen()),
            param2: valueF,
          })
        );
      }
    }
  }
  public partitionSetImgSharpenBlur(
    sharpen: number,
    blur: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetImgSharpen(sharpen);
      this.partitions[pindex].sigSetImgBlur(blur);
      if (commit && PersistentClient.instance) {
        // only client side
        PersistentClient.instance.addImageOperation(
          new CoreImageOperation({
            partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
            operationtype: ImageOperationType.SharpenBlur,
            param1: sharpen,
            param2: blur,
          })
        );
      }
    }
  }
  public partitionGetImgPosterize(pindex: number = 0): number {
    return this.partitions[pindex]
      ? this.partitions[pindex].sigGetImgPosterize()
      : 256;
  }
  public partitionSetImgPosterize(
    value: number,
    pindex: number = 0,
    commit: boolean = true
  ) {
    const valueI = parseInt(value);
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetImgPosterize(valueI);
    }
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
          operationtype: ImageOperationType.Posterize,
          param1: valueI,
        })
      );
    }
  }
  public partitionGetImgSticker(pindex: number = 0): string {
    return this.partitions[pindex]
      ? this.partitions[pindex].sigGetImgSticker()
      : '';
  }
  public partitionSetImgSticker(
    value: string,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetImgSticker(value);
    }
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
          operationtype: ImageOperationType.Sticker,
          params: value,
        })
      );
    }
  }
  public partitionGetBgColor(pindex: number = 0): string {
    return this.partitions[pindex]
      ? this.partitions[pindex].sigGetBgColor()
      : 'lightgray';
  }
  public partitionSetBgColor(
    value: string,
    pindex: number = 0,
    commit: boolean = true
  ) {
    if (this.partitions[pindex]) {
      this.partitions[pindex].sigSetBgColor(value);
    }
    if (commit && PersistentClient.instance) {
      // only client side
      PersistentClient.instance.addImageOperation(
        new CoreImageOperation({
          partitionid: pindex === 0 ? Number(this.sigGetEditing()) : pindex,
          operationtype: ImageOperationType.Background,
          params: value,
        })
      );
    }
  }
}
