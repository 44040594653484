// CoreImageOperation.ts implements all persistent Image operation objects
import { wsImageOperation } from './schema';
import { PersistentWorker } from './PersistentWorker';
import { LogLevel } from '~/components/designer2/DynamicLog';

export enum ImageOperationType {
  None = 0,
  Undo,
  Redo,
  Clear,
  ClearSettings,
  Gallery,
  PositionScaleRotate,
  Background,
  Crop,
  Sticker,
  // beyond here are "settings"
  ColorBalance,
  SharpenBlur,
  Posterize,
  Effect,
  MAX,
}

export enum CropFrame {
  none = 0,
  circle,
  oval,
  square,
  splash,
  star,
  backsquare,
  forwardsquare,
}

export enum Effect {
  none = 0,
  warm,
  vintage,
  cool,
  faded,
  graphite,
}
/**
 * Implements all persistent Image operations, such as finding, storing, updating to local and server
 * databases.
 * An image operation is the finest grain action a user can take in modifying one of the band partitions,
 * and they are stacked to allow infinite undo/redo and later on multi-user collaboration.
 */
export class CoreImageOperation {
  data: wsImageOperation = new wsImageOperation();
  // in this database columns are sorted so that the members of the primary key come first
  static columnsStart: number = 2;
  static serverTable: string = 'image_operation';

  constructor(obj: object = null) {
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        // json lost type data, regaining with new
        for (const column of CoreImageOperation.columns()) {
          if (Object.hasOwn(obj.data, column)) {
            this.data[column] = obj.data[column];
          }
        }
      } else {
        // returned from the database, populate this object
        for (const column of CoreImageOperation.columns()) {
          if (Object.hasOwn(obj, column)) {
            this.data[column] = obj[column];
          }
        }
      }
    }
  }
  public clone(): CoreImageOperation {
    return new CoreImageOperation(this);
  }
  public static columns(): string[] {
    return wsImageOperation.columns;
  }
  public values(): unknown[] {
    return this.data.values();
  }
  // Local Database calls
  private createLocalQuery(timestamp: number): string {
    if (timestamp && timestamp > 0) {
      if (!this.data.created_timestamp || this.data.created_timestamp === 0) {
        this.data.created_timestamp = timestamp;
      }
      if (!this.data.updated_timestamp || this.data.updated_timestamp === 0) {
        this.data.updated_timestamp = timestamp;
      }
    }
    const sql =
      'INSERT INTO ' +
      wsImageOperation.table +
      ' (' +
      CoreImageOperation.columns().join(', ') +
      ') ' +
      'VALUES (' +
      Array(CoreImageOperation.columns().length).fill('?').join(', ') +
      ')';
    return sql;
  }
  private static findLocalQuery(where: string = '', orderby = ''): string {
    const sql =
      'SELECT * FROM ' +
      wsImageOperation.table +
      (where.length > 0 ? ' WHERE ' + where : '') +
      (orderby.length > 0 ? ' ORDER BY ' + orderby : '');
    return sql;
  }
  private readLocalQuery(): string {
    return CoreImageOperation.findLocalQuery(
      CoreImageOperation.columns()
        .slice(0, CoreImageOperation.columnsStart)
        .join('=? AND ') + '=?'
    );
  }
  private updateLocalQuery(timestamp: number): string {
    if (timestamp && timestamp > 0) {
      this.data.updated_timestamp = timestamp;
    }
    const sql =
      'UPDATE ' +
      wsImageOperation.table +
      ' SET ' +
      CoreImageOperation.columns()
        .slice(CoreImageOperation.columnsStart)
        .join('=?, ') +
      '=? ' +
      'WHERE ' +
      CoreImageOperation.columns()
        .slice(0, CoreImageOperation.columnsStart)
        .join('=? AND ') +
      '=?';
    return sql;
  }
  private deleteLocalQuery(): string {
    const sql =
      'DELETE FROM ' +
      wsImageOperation.table +
      ' ' +
      'WHERE who_created=? AND created_timestamp=?';
    return sql;
  }
  // Server Database Calls
  private createServerQuery(timestamp: number): wsImageOperation {
    if (!this.data.created_timestamp || this.data.created_timestamp === 0) {
      this.data.created_timestamp = timestamp;
    }
    if (!this.data.updated_timestamp || this.data.updated_timestamp === 0) {
      this.data.updated_timestamp = timestamp;
    }
    this.data.server_written = timestamp;
    return this.data;
  }
  private serverIdentity(): object {
    return {
      who_created: this.data.who_created,
      created_timestamp: this.data.created_timestamp,
    };
  }
  private serverIdentityString(): string {
    return (
      'who_created$' +
      this.data.who_created.toString() +
      '$created_timestamp$' +
      this.data.created_timestamp.toString()
    );
  }
  private updateServerQuery(timestamp: number): object {
    this.data.server_written = timestamp;
    return Object.fromEntries(
      Object.entries(this.data).slice(CoreImageOperation.columnsStart)
    );
  }
  // Convenience functions:
  public static async findServer(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreImageOperation[] {
    const coreImageOperations = new Array<CoreImageOperation>();
    try {
      const jsonFind = await worker.fetchServer(
        CoreImageOperation.serverTable,
        where ? where : {},
        'GET'
      );
      if (jsonFind) {
        if (
          jsonFind.success &&
          jsonFind.results &&
          jsonFind.results.length > 0
        ) {
          // found
          for (const row of jsonFind.results) {
            coreImageOperations.push(new CoreImageOperation(row));
          }
        } else {
          worker.log(
            LogLevel.debug,
            'CoreImageOperation.findServer not found',
            where,
            orderby,
            jsonFind
          );
        }
      } else {
        worker.log(
          LogLevel.warn,
          'CoreImageOperation.findServer failed',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.findServer exception:',
        error,
        where,
        orderby
      );
    }
    return coreImageOperations;
  }
  public static async findServerItem(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreImageOperation {
    let coreImageOperation = null;
    try {
      const jsonFind = await worker.fetchServer(
        CoreImageOperation.serverTable,
        where ? where : {},
        'GET'
      );
      if (jsonFind) {
        if (
          jsonFind.success &&
          jsonFind.results &&
          jsonFind.results.length > 0
        ) {
          // found
          coreImageOperation = new CoreImageOperation(jsonFind.results[0]);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreImageOperation.findServerItem not found',
            where,
            orderby,
            jsonFind
          );
        }
      } else {
        worker.log(
          LogLevel.warn,
          'CoreImageOperation.findServerItem failed',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.findServerItem exception:',
        error,
        where,
        orderby
      );
    }
    return coreImageOperation;
  }
  public static async findByWhoWhenServer(
    worker: PersistentWorker,
    userid: number,
    createdTimestamp: number
  ): CoreImageOperation {
    return CoreImageOperation.findServerItem(worker, {
      who_created: userid,
      created_timestamp: createdTimestamp,
    });
  }
  public static async findByUserServer(
    worker: PersistentWorker,
    userid: number
  ): CoreImageOperation[] {
    return CoreImageOperation.findServer(worker, { who_created: userid });
  }
  public static async findByBandServer(
    worker: PersistentWorker,
    bandid: number
  ): CoreImageOperation[] {
    return CoreImageOperation.findServer(worker, { bandid: bandid });
  }
  public static async createServer(
    worker: PersistentWorker,
    coreImageOperation: CoreImageOperation
  ): CoreImageOperation {
    try {
      const timestamp = PersistentWorker.getTimestamp();
      const jsonCreate = await worker.fetchServer(
        CoreImageOperation.serverTable,
        coreImageOperation.createServerQuery(timestamp),
        'POST'
      );
      if (jsonCreate) {
        if (jsonCreate.success) {
          // update the local server_written
          coreImageOperation.updateLocal(worker, timestamp);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreImageOperation.createServer not created',
            jsonCreate
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreImageOperation.createServer failed');
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.createServer exception:',
        error
      );
    }
    return coreImageOperation;
  }
  public async updateServer(worker: PersistentWorker): boolean {
    let worked = false;
    try {
      const timestamp = PersistentWorker.getTimestamp();
      const jsonCreate = await worker.fetchServer(
        CoreImageOperation.serverTable,
        this.updateServerQuery(timestamp),
        'PATCH',
        this.serverIdentityString()
      );
      if (jsonCreate) {
        if (jsonCreate.success) {
          worked = true;
          // now store the new server_written
          this.updateLocal(worker);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreImageOperation.updateServer not updated',
            jsonCreate
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreImageOperation.updateServer failed');
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.updateServer exception:',
        error
      );
    }
    return worked;
  }
  public async deleteServer(worker: PersistentWorker): boolean {
    let worked = false;
    try {
      const jsonCreate = await worker.fetchServer(
        CoreImageOperation.serverTable,
        this.serverIdentity(),
        'DELETE'
      );
      if (jsonCreate) {
        if (!jsonCreate.success) {
          worker.log(
            LogLevel.debug,
            'CoreImageOperation.deleteServer not deleted',
            jsonCreate
          );
        } else {
          worked = true;
        }
      } else {
        worker.log(LogLevel.warn, 'CoreImageOperation.deleteServer failed');
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.deleteServer exception:',
        error
      );
    }
    return worked;
  }
  public static findLocal(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreImageOperation[] {
    const coreImageOperations = new Array<CoreImageOperation>();
    try {
      let wherestr = '';
      let wherevals = [];
      if (where) {
        wherestr = Object.keys(where).join('=? AND ') + '=?';
        wherevals = Object.values(where);
      }
      const rows = worker.fetchLocal(
        CoreImageOperation.findLocalQuery(wherestr),
        wherevals
      );
      if (rows.length > 0) {
        for (const row of rows) {
          coreImageOperations.push(new CoreImageOperation(row));
        }
      } else {
        worker.log(
          LogLevel.debug,
          'CoreImageOperation.findLocal not found',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.findLocal exception:',
        error,
        where,
        orderby
      );
    }
    return coreImageOperations;
  }
  public static findLocalItem(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreImageOperation {
    let coreImageOperation = null;
    try {
      let wherestr = '';
      let wherevals = [];
      if (where) {
        wherestr = Object.keys(where).join('=? AND ') + '=?';
        wherevals = Object.values(where);
      }
      const rows = worker.fetchLocal(
        CoreImageOperation.findLocalQuery(wherestr),
        wherevals
      );
      if (rows.length > 0) {
        coreImageOperation = new CoreImageOperation(rows[0]);
      } else {
        worker.log(
          LogLevel.debug,
          'CoreImageOperation.findLocalItem not found',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.findLocalItem exception:',
        error,
        where,
        orderby
      );
    }
    return coreImageOperation;
  }
  public static findByWhoWhenLocal(
    worker: PersistentWorker,
    userid: number,
    createdTimestamp: number
  ): CoreImageOperation {
    return CoreImageOperation.findLocalItem(worker, {
      who_created: userid,
      created_timestamp: createdTimestamp,
    });
  }
  public static findByUserLocal(
    worker: PersistentWorker,
    userid: number
  ): CoreImageOperation[] {
    return CoreImageOperation.findLocal(worker, { who_created: userid });
  }
  public static findByBandLocal(
    worker: PersistentWorker,
    bandid: number
  ): CoreImageOperation[] {
    return CoreImageOperation.findLocal(worker, { bandid: bandid });
  }
  public static findByImageLocal(
    worker: PersistentWorker,
    imageid: number
  ): CoreImageOperation[] {
    return CoreImageOperation.findLocal(worker, { imageid: imageid });
  }
  public static createLocal(
    worker: PersistentWorker,
    coreImageOperation: CoreImageOperation
  ): CoreImageOperation {
    try {
      if (
        !worker.execLocal(
          coreImageOperation.createLocalQuery(PersistentWorker.getTimestamp()),
          coreImageOperation.values()
        )
      ) {
        worker.log(
          LogLevel.warn,
          'CoreImageOperation.createLocal failed',
          coreImageOperation
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.createLocal exception:',
        error,
        coreImageOperation
      );
    }
    return coreImageOperation;
  }
  public updateLocal(worker: PersistentWorker, timestamp: number = 0): boolean {
    let worked = false;
    try {
      const values = this.values()
        .slice(CoreImageOperation.columnsStart)
        .concat(this.values().slice(0, CoreImageOperation.columnsStart));
      if (!worker.execLocal(this.updateLocalQuery(timestamp), values)) {
        worker.log(LogLevel.warn, 'CoreImageOperation.updateLocal failed');
      } else {
        worked = true;
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.updateLocal exception:',
        error
      );
    }
    return worked;
  }
  public static deleteLocal(
    worker: PersistentWorker,
    coreImageOperation: CoreImageOperation
  ): boolean {
    let worked = false;
    try {
      if (
        !worker.execLocal(
          coreImageOperation.deleteLocalQuery(),
          coreImageOperation.values().slice(0, CoreImageOperation.columnsStart)
        )
      ) {
        worker.log(LogLevel.warn, 'CoreImageOperation.deleteLocal failed');
      } else {
        worked = true;
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImageOperation.deleteLocal exception:',
        error
      );
    }
    return worked;
  }
}
