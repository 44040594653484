// CoreBand.ts implements all persistent Band operations.
import { wsBand } from './schema';
import { CoreImageOperation, ImageOperationType } from './CoreImageOperation';
import { PersistentClient } from './PersistentClient';
import { PersistentWorker } from './PersistentWorker';
import { LogLevel } from '~/components/designer2/DynamicLog';
import { PartitionProps } from '~/components/designer2/DynamicPartition';
import { renderSVG } from 'uqr';
import {
  OffscreenBandEndCenterDot,
  OffscreenBandEndEyelet,
  OffscreenBandEndWearshare,
  OffscreenBandEndWRSR,
  OffscreenBandEnd0Blobs,
  OffscreenBandEnd1Blobs,
  OffscreenBandEnd2Blobs,
} from '~/components/designer2/OffscreenIcon';
import { OffscreenPartition } from '~/components/designer2/OffscreenPartition';

/**
 * Implements all persistent Band operations,
 * it also co-ordinates all other core objects.
 * There is only one CoreBand active at any time in the browser,
 * and it can be used to carry on editing where you left off, etc.
 * This is where a Band's image operations are co-ordinated.
 */
export enum ProductionStage {
  DesignInProgress = 0,
  DesignCompleted,
  OrderComplete,
  ProductionComplete,
  ProductionCancelled,
}
class QRbox {
  svguri: string = '';
  offset: number = 0;
}

export class CoreBand {
  data: wsBand = new wsBand();
  // localId is set only while we are updating to a server issued id
  localId: number = 0;
  // in this database columns are sorted so that the members of the primary key come first
  static columnsStart: number = 1;
  static serverTable: string = 'band';

  imageoperations: Map<number, CoreImageOperation>[] = [
    new Map<number, CoreImageOperation>(),
    new Map<number, CoreImageOperation>(),
    new Map<number, CoreImageOperation>(),
    new Map<number, CoreImageOperation>(),
    new Map<number, CoreImageOperation>(),
    new Map<number, CoreImageOperation>(),
    new Map<number, CoreImageOperation>(),
    new Map<number, CoreImageOperation>(),
  ];

  constructor(obj: object = null) {
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        // json lost type data, regaining with new
        for (const column of CoreBand.columns()) {
          if (Object.hasOwn(obj.data, column)) {
            this.data[column] = obj.data[column];
          }
        }
      } else {
        // returned from the database, populate this object
        for (const column of CoreBand.columns()) {
          if (Object.hasOwn(obj, column)) {
            this.data[column] = obj[column];
          }
        }
      }
    }
  }
  public static uniqueguid(): string {
    // for example "36b8f84d-df4e-4d49-b662-bcde71a8764f" - 36 chars
    return crypto.randomUUID();
  }
  public static async sha256Hash(message: string): string {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); // convert bytes to hex string
    return hashHex;
  }
  public static shortHash(hash: string): string {
    return hash.substring(0, 8).toUpperCase();
  }
  public async generateIdentity() {
    if (!this.data.identity_guid || this.data.identity_guid.length === 0) {
      this.data.identity_guid = CoreBand.uniqueguid();
    }
    this.data.identity_hash = await CoreBand.sha256Hash(
      this.data.identity_guid
    );
    this.data.identity_short = CoreBand.shortHash(this.data.identity_hash);
  }
  public async generateArtworksOnWorker(
    worker: PersistentWorker,
    partitionProps: PartitionProps[],
    bleedWidth: number = 51,
    partitionDim: number = 1024
  ): Uint8Array[] {
    let imageResolve = null;
    let imageReject = null;
    const byteArraysPromise = new Promise<Uint8Array[]>((resolve, reject) => {
      imageResolve = resolve;
      imageReject = reject;
    }).catch((error) => {
      worker.log(
        LogLevel.warn,
        'CoreBand.generateArtworksOnWorker rejected:',
        error
      );
    });
    const byteArrays = new Array<Uint8Array>(partitionProps.length);
    // Note Safari iOS limits canvas size to maximum 4096x4096
    try {
      await worker.svg2PngInitialized;
      const partitionWidth = partitionDim + 2 * bleedWidth;
      const partitionHeight = partitionDim;
      // this.data.orientation has four possible values: 0, 90, 180, 270: 0 = left-right, 90 = top-bottom, 180 = right-left, 270 = bottom-top
      // we are generating the band vertically top-bottom
      const partitionRotation = PartitionProps.rotateModulo(
        90 - this.data.orientation
      );
      const dataurlForImage = new Map<string, string>();
      for (const partition of partitionProps) {
        if (
          partition.imglocalfilename &&
          partition.imglocalfilename.length > 0
        ) {
          // cache them because if two partitions use the same image, can get a file handle race condition
          const key = partition.imglocalfilename + PersistentClient.imageExt;
          if (!dataurlForImage.has(key)) {
            // this partition uses an image, make sure it is loaded
            dataurlForImage.set(key, await worker.getImgDataurlPng(key));
            worker.addProgress(3);
          }
        }
      }
      worker.setProgress(21);
      const imagesLoaded = new Array<Promise<boolean>>();
      const offset = 0 * partitionHeight; // 1*partitionHeight
      for (const partition of partitionProps) {
        imagesLoaded.push(
          new Promise<boolean>((resolve, reject) => {
            if (
              partition.imglocalfilename &&
              partition.imglocalfilename.length > 0
            ) {
              partition.imgsrc = dataurlForImage.get(
                partition.imglocalfilename + PersistentClient.imageExt
              );
              worker.log(
                LogLevel.debug,
                'CoreBand.generateArtworksOnWorker image ' +
                  partition.index +
                  ' ' +
                  partition.imgsrc.substring(0, 120) +
                  ' ...'
              );
              //worker.log(LogLevel.debug, 'CoreBand.generateArtworksOnWorker image '+partition.index+' '+partition.imgsrc);
              const svguri = OffscreenPartition(
                partition,
                partitionRotation,
                bleedWidth,
                0,
                partitionDim
              );
              worker.log(
                LogLevel.debug,
                'CoreBand.generateArtworksOnWorker svg ' +
                  partition.index +
                  ' ' +
                  svguri.substring(0, 120) +
                  ' ...'
              );
              // worker.log(LogLevel.debug, svguri);
              worker.convertSvg2PngNonBlocking(svguri).then((pngbytes) => {
                byteArrays[partition.index - 1] = pngbytes;
                resolve(true);
                worker.addProgress(10);
              });
            } else {
              const svguri = OffscreenPartition(
                partition,
                partitionRotation,
                bleedWidth,
                0,
                partitionDim
              );
              worker.convertSvg2PngNonBlocking(svguri).then((pngbytes) => {
                byteArrays[partition.index - 1] = pngbytes;
                resolve(true);
                worker.addProgress(10);
              });
            }
          }).catch((error) => {
            worker.log(
              LogLevel.warn,
              'CoreBand.generateArtworksOnWorker finalPartitionImage ' +
                partition.index +
                ' rejected:',
              error
            );
          })
        );
      }
      Promise.all(imagesLoaded).then(() => {
        // image/webp not supported by Safari
        imageResolve(byteArrays);
        worker.setProgress(91);
      });
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreBand.generateArtworksOnWorker exception:',
        error
      );
    }
    return byteArraysPromise;
  }
  public static colorHexToRgb(hexvalue: string): number[] {
    // format #RRGGBB or #RRGGBBAA
    const rgbvalue = [0, 0, 0, 255];
    if (hexvalue.length >= 9) {
      rgbvalue[3] = parseInt(hexvalue.substring(7, 9), 16);
    }
    if (hexvalue.length >= 7) {
      rgbvalue[0] = parseInt(hexvalue.substring(1, 3), 16);
      rgbvalue[1] = parseInt(hexvalue.substring(3, 5), 16);
      rgbvalue[2] = parseInt(hexvalue.substring(5, 7), 16);
    }
    return rgbvalue;
  }
  public static async convertPartitionToDataurl(
    partition: PartitionProps,
    partitionDim: number,
    bleedWidth: number,
    partitionRotation: number
  ): string {
    const returnDataUrl = new Promise<string>((resolve, reject) => {
      const partitionWidth = partitionDim + 2 * bleedWidth;
      const partitionHeight = partitionDim;
      const partitionCanvas = document.createElement('canvas');
      partitionCanvas.width = partitionWidth; // 1126;
      partitionCanvas.height = partitionHeight; // 11776; endtab - partitionx7 - endtabx2 - qrx1.5
      const partitionCanvasContext = partitionCanvas.getContext('2d');
      partitionCanvasContext.fillStyle = partition.bgcolor;
      partitionCanvasContext.fillRect(
        0,
        0,
        partitionCanvas.width,
        partitionCanvas.height
      );
      const hasImage =
        partition.imglocalfilename && partition.imglocalfilename.length > 0;
      const finalPartitionImage = new Image();
      finalPartitionImage.onerror = (event) => {
        PersistentClient.log(
          LogLevel.warn,
          'CoreBand.convertPartitionToDataurl fail finalPartitionImage index:' +
            partition.index,
          event
        );
        reject(
          'CoreBand.convertPartitionToDataurl fail finalPartitionImage index:' +
            partition.index
        );
      };
      finalPartitionImage.onload = () => {
        PersistentClient.log(
          LogLevel.debug,
          'CoreBand.convertPartitionToDataurl write partition index:' +
            partition.index +
            ' ' +
            partition.imglocalfilename +
            PersistentClient.imageExt +
            ' at (0, 0)'
        );
        partitionCanvasContext.drawImage(
          finalPartitionImage,
          0,
          0,
          partitionWidth,
          partitionHeight,
          0,
          0,
          partitionWidth,
          partitionHeight
        );
        const x = Math.round(partitionDim / 2);
        const y = Math.round(partitionDim / 2);
        const bgpixel = CoreBand.colorHexToRgb(partition.bgcolor);
        PersistentClient.log(
          LogLevel.debug,
          'bgcolor == rgba(' +
            bgpixel[0] +
            ', ' +
            bgpixel[1] +
            ', ' +
            bgpixel[2] +
            ', ' +
            bgpixel[3] +
            ' )'
        );
        let pixel = partitionCanvasContext.getImageData(x, y, 1, 1);
        PersistentClient.log(
          LogLevel.debug,
          'color at (' +
            partitionDim +
            ', ' +
            partitionDim +
            ') == rgba(' +
            pixel.data[0] +
            ', ' +
            pixel.data[1] +
            ', ' +
            pixel.data[2] +
            ', ' +
            pixel.data[3] +
            ' )'
        );
        if (
          hasImage &&
          pixel.data[0] === bgpixel[0] &&
          pixel.data[1] === bgpixel[1] &&
          pixel.data[2] === bgpixel[2]
        ) {
          // wait until the canvas has been rendered:
          const retryCount = 8;
          const retryInterval = 4 * 1000; // retry every 4 seconds
          let retry = 0;
          const canvasCheck = setInterval(() => {
            pixel = partitionCanvasContext.getImageData(x, y, 1, 1);
            if (
              pixel.data[0] === bgpixel[0] &&
              pixel.data[1] === bgpixel[1] &&
              pixel.data[2] === bgpixel[2]
            ) {
              // still background
              ++retry;
              if (retry >= retryCount) {
                PersistentClient.log(
                  LogLevel.warn,
                  'CoreBand.convertPartitionToDataurl canvas failed to load index:' +
                    partition.index
                );
                const dataurl = partitionCanvas.toDataURL(); //'image/png', 1);
                resolve(dataurl);
                clearInterval(canvasCheck);
                PersistentClient.instance.addProgress(13);
              } else {
                PersistentClient.log(
                  LogLevel.warn,
                  'CoreBand.convertPartitionToDataurl canvas retry ' +
                    retry +
                    ' load index:' +
                    partition.index
                );
              }
            } else {
              // success
              PersistentClient.log(
                LogLevel.debug,
                'CoreBand.convertPartitionToDataurl canvas loaded ' +
                  retry +
                  ' time - after ' +
                  (retryInterval * retry) / 1000 +
                  ' seconds index:' +
                  partition.index
              );
              // image/webp not supported by Safari
              const dataurl = partitionCanvas.toDataURL(); //'image/png', 1);
              resolve(dataurl);
              clearInterval(canvasCheck);
              PersistentClient.instance.addProgress(13);
            }
          }, retryInterval);
        } else {
          PersistentClient.log(
            LogLevel.debug,
            'CoreBand.convertPartitionToDataurl canvas loaded first time ' +
              partition.index
          );
          // image/webp not supported by Safari
          const dataurl = partitionCanvas.toDataURL(); //'image/png', 1);
          resolve(dataurl);
          PersistentClient.instance.addProgress(13);
        }
      };
      if (hasImage) {
        // this partition uses an image, make sure it is loaded
        PersistentClient.instance
          .getImgDataurlPng(
            partition.imglocalfilename + PersistentClient.imageExt
          )
          .then((imguri) => {
            partition.imgsrc = imguri;
            PersistentClient.log(
              LogLevel.debug,
              'CoreBand.convertPartitionToDataurl image ' +
                partition.index +
                ' ' +
                partition.imgsrc.substring(0, 120) +
                ' ...'
            );
            const svguri = CoreBand.encodeSVGtoURI(
              OffscreenPartition(
                partition,
                partitionRotation,
                bleedWidth,
                0,
                partitionDim
              )
            );
            finalPartitionImage.src = svguri;
            PersistentClient.log(
              LogLevel.debug,
              'CoreBand.convertPartitionToDataurl svg ' +
                partition.index +
                ' ' +
                svguri.substring(0, 120) +
                ' ...'
            );
          });
      } else {
        const svguri = CoreBand.encodeSVGtoURI(
          OffscreenPartition(
            partition,
            partitionRotation,
            bleedWidth,
            0,
            partitionDim
          )
        );
        finalPartitionImage.src = svguri;
        PersistentClient.log(
          LogLevel.debug,
          'CoreBand.convertPartitionToDataurl no image svg ' +
            partition.index +
            ' ' +
            svguri.substring(0, 120) +
            ' ...'
        );
      }
    }).catch((error) => {
      PersistentClient.log(
        LogLevel.warn,
        'CoreBand.convertPartitionToDataurl return dataurl rejected:',
        error
      );
    });
    return returnDataUrl;
  }
  public async generateArtworksOnClient(
    partitionProps: PartitionProps[],
    bleedWidth: number = 51,
    partitionDim: number = 1024
  ): string[] {
    let imageResolve = null;
    let imageReject = null;
    const dataURLPromise = new Promise<string[]>((resolve, reject) => {
      imageResolve = resolve;
      imageReject = reject;
    }).catch((error) => {
      PersistentClient.log(
        LogLevel.warn,
        'CoreBand.generateArtworksOnClient rejected:',
        error
      );
    });
    const dataURL = new Array<string>(partitionProps.length);
    // Note Safari iOS limits canvas size to maximum 4096x4096
    try {
      const imagesLoaded = new Array<Promise<boolean>>();
      // this.data.orientation has four possible values: 0, 90, 180, 270: 0 = left-right, 90 = top-bottom, 180 = right-left, 270 = bottom-top
      // we are generating the band vertically top-bottom
      const partitionRotation = PartitionProps.rotateModulo(
        90 - this.data.orientation
      );
      // const offset = 0 * partitionHeight; // 1*partitionHeight
      const perisitentClient = PersistentClient.instance;
      if (perisitentClient) {
        // only client side
        for (const partition of partitionProps) {
          imagesLoaded.push(
            new Promise<boolean>((resolve) => {
              CoreBand.convertPartitionToDataurl(
                partition,
                partitionDim,
                bleedWidth,
                partitionRotation
              ).then((dataurl) => {
                dataURL[partition.index - 1] = dataurl;
                resolve(true);
              });
            }).catch((error) => {
              PersistentClient.log(
                LogLevel.warn,
                'CoreBand.generateArtworksOnClient finalPartitionImage ' +
                  partition.index +
                  ' rejected:',
                error
              );
            })
          );
        }
      }
      Promise.all(imagesLoaded).then(() => {
        // image/webp not supported by Safari
        imageResolve(dataURL);
      });
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'CoreBand.generateArtworksOnClient exception:',
        error
      );
    }
    return dataURLPromise;
  }
  public static async generateEnd0(
    partitionDim: number,
    bleedWidth: number,
    bgcolor: string = '#FBFF53'
  ): string {
    const returnDataUrl = new Promise<string>((resolve, reject) => {
      const partitionWidth = partitionDim + 2 * bleedWidth;
      const partitionHeight = partitionDim;
      const partitionCanvas = document.createElement('canvas');
      partitionCanvas.width = partitionWidth; // 1126;
      partitionCanvas.height = partitionHeight; // 11776; endtab - partitionx7 - endtabx2 - qrx1.5
      const partitionCanvasContext = partitionCanvas.getContext('2d');
      partitionCanvasContext.fillStyle = bgcolor;
      partitionCanvasContext.fillRect(
        0,
        0,
        partitionCanvas.width,
        partitionCanvas.height
      );
      const finalPartitionImage = new Image();
      finalPartitionImage.onerror = (event) => {
        PersistentClient.log(
          LogLevel.warn,
          'CoreBand.generateEnd0 fail',
          event
        );
        reject('CoreBand.generateEnd0 fail');
      };
      finalPartitionImage.onload = () => {
        partitionCanvasContext.drawImage(
          finalPartitionImage,
          0,
          0,
          partitionWidth,
          partitionHeight,
          0,
          0,
          partitionWidth,
          partitionHeight
        );
        const textImage = new Image();
        textImage.onerror = (event) => {
          PersistentClient.log(
            LogLevel.warn,
            'CoreBand.generateEnd0 text fail',
            event
          );
          reject('CoreBand.generateEnd0 text fail');
        };
        textImage.onload = () => {
          partitionCanvasContext.drawImage(textImage, 0, 0);
          const centerDotImage = new Image();
          centerDotImage.onerror = (event) => {
            PersistentClient.log(
              LogLevel.warn,
              'CoreBand.generateEnd0 center dot fail',
              event
            );
            reject('CoreBand.generateEnd0 center dot fail');
          };
          centerDotImage.onload = () => {
            partitionCanvasContext.drawImage(centerDotImage, 0, 0);
            // image/webp not supported by Safari
            const dataurl = partitionCanvas.toDataURL(); //'image/png', 1);
            resolve(dataurl);
          };
          const svgdoturi = CoreBand.encodeSVGtoURI(
            OffscreenBandEndCenterDot()
          );
          centerDotImage.src = svgdoturi;
        };
        const svgtexturi = CoreBand.encodeSVGtoURI(OffscreenBandEndWRSR(180));
        textImage.src = svgtexturi;
      };
      const svguri = CoreBand.encodeSVGtoURI(OffscreenBandEnd0Blobs(180));
      finalPartitionImage.src = svguri;
    }).catch((error) => {
      PersistentClient.log(
        LogLevel.warn,
        'CoreBand.generateEnd0 return dataurl rejected:',
        error
      );
    });
    return returnDataUrl;
  }
  public static async generateEnd1(
    partitionDim: number,
    bleedWidth: number,
    bgcolor: string = '#FBFF53'
  ): string {
    const returnDataUrl = new Promise<string>((resolve, reject) => {
      const partitionWidth = partitionDim + 2 * bleedWidth;
      const partitionHeight = partitionDim;
      const partitionCanvas = document.createElement('canvas');
      partitionCanvas.width = partitionWidth; // 1126;
      partitionCanvas.height = partitionHeight; // 11776; endtab - partitionx7 - endtabx2 - qrx1.5
      const partitionCanvasContext = partitionCanvas.getContext('2d');
      partitionCanvasContext.fillStyle = bgcolor;
      partitionCanvasContext.fillRect(
        0,
        0,
        partitionCanvas.width,
        partitionCanvas.height
      );
      const finalPartitionImage = new Image();
      finalPartitionImage.onerror = (event) => {
        PersistentClient.log(
          LogLevel.warn,
          'CoreBand.generateEnd1 fail',
          event
        );
        reject('CoreBand.generateEnd1 fail');
      };
      finalPartitionImage.onload = () => {
        partitionCanvasContext.drawImage(
          finalPartitionImage,
          0,
          0,
          partitionWidth,
          partitionHeight,
          0,
          0,
          partitionWidth,
          partitionHeight
        );
        const textImage = new Image();
        textImage.onerror = (event) => {
          PersistentClient.log(
            LogLevel.warn,
            'CoreBand.generateEnd1 text fail',
            event
          );
          reject('CoreBand.generateEnd1 text fail');
        };
        textImage.onload = () => {
          partitionCanvasContext.drawImage(textImage, 0, 0);
          const centerDotImage = new Image();
          centerDotImage.onerror = (event) => {
            PersistentClient.log(
              LogLevel.warn,
              'CoreBand.generateEnd1 center dot fail',
              event
            );
            reject('CoreBand.generateEnd1 center dot fail');
          };
          centerDotImage.onload = () => {
            partitionCanvasContext.drawImage(centerDotImage, 0, 0);
            // image/webp not supported by Safari
            const dataurl = partitionCanvas.toDataURL(); //'image/png', 1);
            resolve(dataurl);
          };
          const svgdoturi = CoreBand.encodeSVGtoURI(
            OffscreenBandEndCenterDot()
          );
          centerDotImage.src = svgdoturi;
        };
        const svgtexturi = CoreBand.encodeSVGtoURI(
          OffscreenBandEndWearshare(0)
        );
        textImage.src = svgtexturi;
      };
      const svguri = CoreBand.encodeSVGtoURI(OffscreenBandEnd1Blobs(0));
      finalPartitionImage.src = svguri;
    }).catch((error) => {
      PersistentClient.log(
        LogLevel.warn,
        'CoreBand.generateEnd1 return dataurl rejected:',
        error
      );
    });
    return returnDataUrl;
  }
  public static async generateEnd2(
    partitionDim: number,
    bleedWidth: number,
    bgcolor: string = '#FBFF53'
  ): string {
    const returnDataUrl = new Promise<string>((resolve, reject) => {
      const partitionWidth = partitionDim + 2 * bleedWidth;
      const partitionHeight = partitionDim;
      const partitionCanvas = document.createElement('canvas');
      partitionCanvas.width = partitionWidth; // 1126;
      partitionCanvas.height = partitionHeight; // 11776; endtab - partitionx7 - endtabx2 - qrx1.5
      const partitionCanvasContext = partitionCanvas.getContext('2d');
      partitionCanvasContext.fillStyle = bgcolor;
      partitionCanvasContext.fillRect(
        0,
        0,
        partitionCanvas.width,
        partitionCanvas.height
      );
      const finalPartitionImage = new Image();
      finalPartitionImage.onerror = (event) => {
        PersistentClient.log(
          LogLevel.warn,
          'CoreBand.generateEnd2 fail',
          event
        );
        reject('CoreBand.generateEnd2 fail');
      };
      finalPartitionImage.onload = () => {
        partitionCanvasContext.drawImage(
          finalPartitionImage,
          0,
          0,
          partitionWidth,
          partitionHeight,
          0,
          0,
          partitionWidth,
          partitionHeight
        );
        const textImage = new Image();
        textImage.onerror = (event) => {
          PersistentClient.log(
            LogLevel.warn,
            'CoreBand.generateEnd2 text fail',
            event
          );
          reject('CoreBand.generateEnd2 text fail');
        };
        textImage.onload = () => {
          partitionCanvasContext.drawImage(textImage, 0, 0);
          const centerDotImage = new Image();
          centerDotImage.onerror = (event) => {
            PersistentClient.log(
              LogLevel.warn,
              'CoreBand.generateEnd2 center dot fail',
              event
            );
            reject('CoreBand.generateEnd2 center dot fail');
          };
          centerDotImage.onload = () => {
            partitionCanvasContext.drawImage(centerDotImage, 0, 0);
            // image/webp not supported by Safari
            const dataurl = partitionCanvas.toDataURL(); //'image/png', 1);
            resolve(dataurl);
          };
          const svgdoturi = CoreBand.encodeSVGtoURI(
            OffscreenBandEndCenterDot()
          );
          centerDotImage.src = svgdoturi;
        };
        const svgtexturi = CoreBand.encodeSVGtoURI(OffscreenBandEndWRSR(180));
        textImage.src = svgtexturi;
      };
      const svguri = CoreBand.encodeSVGtoURI(OffscreenBandEnd2Blobs(180));
      finalPartitionImage.src = svguri;
    }).catch((error) => {
      PersistentClient.log(
        LogLevel.warn,
        'CoreBand.generateEnd2 return dataurl rejected:',
        error
      );
    });
    return returnDataUrl;
  }
  public static async generateQRCode(
    partitionDim: number,
    bleedWidth: number,
    identityShort: string,
    bgcolor: string = '#FFFFFF'
  ): string {
    const returnDataUrl = new Promise<string>((resolve, reject) => {
      const partitionWidth = partitionDim + 2 * bleedWidth;
      const partitionHeight = Math.round(partitionDim * 1.5);
      const partitionCanvas = document.createElement('canvas');
      partitionCanvas.width = partitionWidth; // 1126;
      partitionCanvas.height = partitionHeight; // 11776; endtab - partitionx7 - endtabx2 - qrx1.5
      const partitionCanvasContext = partitionCanvas.getContext('2d');
      partitionCanvasContext.fillStyle = bgcolor;
      partitionCanvasContext.fillRect(
        0,
        0,
        partitionCanvas.width,
        partitionCanvas.height
      );
      const finalPartitionImage = new Image();
      finalPartitionImage.onerror = (event) => {
        PersistentClient.log(
          LogLevel.warn,
          'CoreBand.generateQRCode fail',
          event
        );
        reject('CoreBand.generateQRCode fail');
      };
      const qrbox = CoreBand.encodeQRtoURI(identityShort);
      finalPartitionImage.onload = () => {
        partitionCanvasContext.drawImage(
          finalPartitionImage,
          bleedWidth + qrbox.offset,
          qrbox.offset
        );
        // image/webp not supported by Safari
        const dataurl = partitionCanvas.toDataURL(); //'image/png', 1);
        resolve(dataurl);
      };
      finalPartitionImage.src = qrbox.svguri;
    }).catch((error) => {
      PersistentClient.log(
        LogLevel.warn,
        'CoreBand.generateQRCode return dataurl rejected:',
        error
      );
    });
    return returnDataUrl;
  }

  public async generateArtwork(
    partitionProps: PartitionProps[],
    bleedWidth: number = 51,
    partitionDim: number = 1024
  ): string {
    let dataURL = '';
    try {
      let imageResolve = null;
      let imageReject = null;
      dataURL = new Promise<string>((resolve, reject) => {
        imageResolve = resolve;
        imageReject = reject;
      }).catch((error) => {
        PersistentClient.log(
          LogLevel.warn,
          'CoreBand.generateArtwork rejected:',
          error
        );
      });
      const bandCanvas = document.createElement('canvas');
      const bandWidth = partitionDim;
      bandCanvas.width = bandWidth + 2 * bleedWidth; // 1126;
      const partitionHeight = partitionDim;
      //bandCanvas.height = (1 + 7 + 2 + 1.5) * partitionHeight; // 11776; endtab - partitionx7 - endtabx2 - qrx1.5
      bandCanvas.height = 7 * partitionHeight; // 11776; endtab - partitionx7 - endtabx2 - qrx1.5
      const bandCanvasContext = bandCanvas.getContext('2d');
      bandCanvasContext.fillStyle = '#FBFF53'; // wearshare yellow
      bandCanvasContext.fillRect(0, 0, bandCanvas.width, bandCanvas.height);
      /* QR section
																bandCanvasContext.fillStyle = "white";
																bandCanvasContext.fillRect(0, 10240, 1126, 1536);
																*/
      const imagesLoaded = new Array<Promise<boolean>>();
      // this.data.orientation has four possible values: 0, 90, 180, 270: 0 = left-right, 90 = top-bottom, 180 = right-left, 270 = bottom-top
      // we are generating the band vertically top-bottom
      const partitionRotation = PartitionProps.rotateModulo(
        90 - this.data.orientation
      );
      const offset = 0 * partitionHeight; // 1*partitionHeight
      const perisitentClient = PersistentClient.instance;
      if (perisitentClient) {
        // only client side
        for (const partition of partitionProps) {
          imagesLoaded.push(
            new Promise<boolean>((resolve, reject) => {
              const finalPartitionImage = new Image();
              finalPartitionImage.onerror = (event) => {
                PersistentClient.log(
                  LogLevel.warn,
                  'CoreBand.generateArtwork fail finalPartitionImage ' +
                    partition.index,
                  event
                );
                reject(
                  'CoreBand.generateArtwork fail finalPartitionImage ' +
                    partition.index
                );
              };
              finalPartitionImage.onload = () => {
                const y = offset + (partition.index - 1) * partitionHeight;
                PersistentClient.log(
                  LogLevel.debug,
                  'CoreBand.generateArtwork write partition ' +
                    partition.index +
                    ' ' +
                    partition.imglocalfilename +
                    PersistentClient.imageExt +
                    ' at (0, ' +
                    y +
                    ')'
                );
                bandCanvasContext.drawImage(finalPartitionImage, 0, y);
                resolve(true);
              };
              if (
                partition.imglocalfilename &&
                partition.imglocalfilename.length > 0
              ) {
                // this partition uses an image, make sure it is loaded
                perisitentClient
                  .getImgDataurl(
                    partition.imglocalfilename + PersistentClient.imageExt
                  )
                  .then((imguri) => {
                    partition.imgsrc = imguri;
                    PersistentClient.log(
                      LogLevel.debug,
                      'CoreBand.generateArtwork image ' +
                        partition.index +
                        ' ' +
                        partition.imgsrc
                    );
                    const svguri = CoreBand.encodeSVGtoURI(
                      OffscreenPartition(
                        partition,
                        partitionRotation,
                        bleedWidth,
                        0,
                        partitionDim
                      )
                    );
                    finalPartitionImage.src = svguri;
                    PersistentClient.log(
                      LogLevel.debug,
                      'CoreBand.generateArtwork svg ' +
                        partition.index +
                        ' ' +
                        svguri
                    );
                  });
              } else {
                finalPartitionImage.src = CoreBand.encodeSVGtoURI(
                  OffscreenPartition(
                    partition,
                    partitionRotation,
                    bleedWidth,
                    0,
                    partitionDim
                  )
                );
              }
            }).catch((error) => {
              PersistentClient.log(
                LogLevel.warn,
                'CoreBand.generateArtwork finalPartitionImage ' +
                  partition.index +
                  ' rejected:',
                error
              );
            })
          );
        }
      }
      Promise.all(imagesLoaded).then(() => {
        // image/webp not supported by Safari
        imageResolve(bandCanvas.toDataURL('image/png', 1));
      });
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'CoreBand.generateArtwork exception:',
        error
      );
    }
    return dataURL;
  }
  public static encodeSVGtoURI(svg: string = ''): string {
    return (
      'data:image/svg+xml;utf8,' + encodeURIComponent(svg.replace(/\s+/g, ' '))
    );
  }
  public static encodeQRtoURI(secret: string = '', dim: number = 1024): QRbox {
    let qrbox = new QRbox();
    let pixelSize = 33;
    let qrsvg = renderSVG(secret, { pixelSize: pixelSize });
    // e.g. <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 992 992">...
    const re = /\sviewBox\s*=\s*"\s*(\d+)\s+(\d+)\s+(\d+)\s+(\d+)\s*"/;
    let viewBox = re.exec(qrsvg);
    const pixelCount = viewBox[3] / pixelSize;
    const optimalPixelSize = Math.floor(dim / pixelCount);
    if (pixelSize !== optimalPixelSize) {
      qrsvg = renderSVG(secret, { pixelSize: optimalPixelSize });
      viewBox = re.exec(qrsvg);
    }
    qrbox.offset = Math.round((dim - viewBox[3]) * 0.5);
    const pos = qrsvg.search('viewBox');
    qrbox.svguri = CoreBand.encodeSVGtoURI(
      qrsvg.substring(0, pos) +
        'width="' +
        viewBox[3] +
        '" height="' +
        viewBox[4] +
        '" ' +
        qrsvg.substring(pos)
    );
    return qrbox;
  }
  public calculateProps(pindex: number): PartitionProps {
    const partitionProps = new PartitionProps();
    partitionProps.setDefaults();
    partitionProps.index = pindex;
    const opstackRef = this.imageoperations[partitionProps.index];
    const haveOperationType = new Array<boolean>(ImageOperationType.MAX);
    haveOperationType.fill(true, 0, ImageOperationType.Gallery);
    haveOperationType.fill(false, ImageOperationType.Gallery);
    //console.log('Band.calculateProps opstack: ', opstackRef);
    for (const rkey of Array.from(opstackRef.keys()).reverse()) {
      // reverse iterate over the Map
      const op = opstackRef.get(rkey);
      //console.log('Band.calculateProps op: ', op);
      if (op.data.enabled === 1) {
        // only consider enabled operations
        //console.log('Band.calculateProps enabled op: ', op);
        if (op.data.operationtype === ImageOperationType.Clear) {
          // go no further
          break;
        } else if (op.data.operationtype === ImageOperationType.ClearSettings) {
          // go no further with settings
          haveOperationType.fill(true, ImageOperationType.ColorBalance);
        } else if (
          op.data.operationtype >= ImageOperationType.Gallery &&
          !haveOperationType[op.data.operationtype]
        ) {
          // we have not got this one yet:
          switch (op.data.operationtype) {
            case ImageOperationType.Gallery:
              {
                partitionProps.imageid = op.data.imageid;
                if (
                  !haveOperationType[ImageOperationType.PositionScaleRotate]
                ) {
                  // this image has not been positioned, set the default:
                  partitionProps.imgscale = op.data.param1;
                  haveOperationType[ImageOperationType.PositionScaleRotate] =
                    true;
                }
                // do not check image filter type settings which were applied before this image:
                haveOperationType.fill(true, ImageOperationType.ColorBalance);
                // console.log('Band.calculateProps Gallery partitionProps: ', partitionProps);
              }
              break;
            case ImageOperationType.PositionScaleRotate:
              partitionProps.imgtranslatex = op.data.param1;
              partitionProps.imgtranslatey = op.data.param2;
              partitionProps.imgscale = op.data.param3;
              partitionProps.imgrotation = op.data.param4;
              break;
            case ImageOperationType.Crop:
              partitionProps.imgcropframe = op.data.param1;
              break;
            case ImageOperationType.ColorBalance:
              partitionProps.imgbrightness = op.data.param1;
              partitionProps.imgcontrast = op.data.param2;
              partitionProps.imghuerotate = op.data.param3;
              partitionProps.imgsaturate = op.data.param4;
              partitionProps.imggrayscale = op.data.param5;
              partitionProps.imgsepia = op.data.param6;
              break;
            case ImageOperationType.SharpenBlur:
              partitionProps.imgsharpen = op.data.param1;
              partitionProps.imgblur = op.data.param2;
              break;
            case ImageOperationType.Posterize:
              partitionProps.imgposterize = op.data.param1;
              break;
            case ImageOperationType.Effect:
              partitionProps.imgeffect = op.data.param1;
              break;
            case ImageOperationType.Sticker:
              partitionProps.imgsticker = op.data.params;
              break;
            case ImageOperationType.Background:
              partitionProps.bgcolor = op.data.params;
              break;
          }
          haveOperationType[op.data.operationtype] = true;
        }
        if (!haveOperationType.includes(false)) {
          // we have every operation type
          break;
        }
      }
    }
    return partitionProps;
  }
  public addImageOperation(
    worker: PersistentWorker,
    coreImageOperation: CoreImageOperation
  ) {
    worker.log(
      LogLevel.debug,
      'CoreBand.addImageOperation',
      coreImageOperation
    );
    const opstackRef =
      this.imageoperations[coreImageOperation.data.partitionid];
    switch (coreImageOperation.data.operationtype) {
      case ImageOperationType.Undo:
        {
          worker.log(
            LogLevel.debug,
            'CoreBand.addImageOperation UNDO',
            coreImageOperation
          );
          for (const rkey of Array.from(opstackRef.keys()).reverse()) {
            // reverse iterate over the Map
            const op = opstackRef.get(rkey);
            if (op.data.enabled === 1) {
              op.data.enabled = 0;
              op.updateLocal(worker, PersistentWorker.getTimestamp());
              op.updateServer(worker); // no need to wait
              break;
            }
          }
        }
        break;
      case ImageOperationType.Redo:
        {
          worker.log(
            LogLevel.debug,
            'CoreBand.addImageOperation REDO',
            coreImageOperation
          );
          let candidate = null;
          for (const rkey of Array.from(opstackRef.keys()).reverse()) {
            // reverse iterate over the Map
            const op = opstackRef.get(rkey);
            if (op.data.enabled === 1) {
              // choose the last candidate, if there was one
              break;
            } else {
              candidate = op;
            }
          }
          if (candidate) {
            candidate.data.enabled = 1;
            candidate.updateLocal(worker, PersistentWorker.getTimestamp());
            candidate.updateServer(worker); // no need to wait
          }
        }
        break;
      default:
        {
          worker.log(
            LogLevel.debug,
            'CoreBand.addImageOperation DO',
            coreImageOperation
          );
          CoreImageOperation.createLocal(worker, coreImageOperation);
          // after create local has set the created timestamp
          opstackRef.set(
            coreImageOperation.data.created_timestamp,
            coreImageOperation
          );
          CoreImageOperation.createServer(worker, coreImageOperation); // no need to wait for it to complete
        }
        break;
    }
  }
  public populateBand(worker: PersistentWorker) {
    for (const coreImageOperation of CoreImageOperation.findByBandLocal(
      worker,
      this.data.bandid
    )) {
      this.imageoperations[coreImageOperation.data.partitionid].set(
        coreImageOperation.data.created_timestamp,
        coreImageOperation
      );
    }
  }
  // e.g. 1 2 3 4 5 6 7
  public movePartition(
    worker: PersistentWorker,
    fromIndex: number,
    toIndex: number,
    timestamp: number
  ) {
    let sequence = timestamp + 1;
    if (fromIndex < toIndex) {
      // e.g. 2 -> 4 : 2 -> 4, 4 -> 3, 3 -> 2
      for (let i = fromIndex; i < toIndex; ++i) {
        sequence = this.moveSinglePartition(
          worker,
          i + 1,
          i,
          timestamp,
          sequence
        );
      }
      sequence = this.moveSinglePartition(
        worker,
        fromIndex,
        toIndex,
        timestamp,
        sequence
      );
    } // fromIndex > toIndex
    else {
      // e.g. 4 -> 2 : 4 -> 2, 2 -> 3, 3 -> 4
      for (let i = fromIndex; i > toIndex; --i) {
        sequence = this.moveSinglePartition(
          worker,
          i - 1,
          i,
          timestamp,
          sequence
        );
      }
      sequence = this.moveSinglePartition(
        worker,
        fromIndex,
        toIndex,
        timestamp,
        sequence
      );
    }
  }
  private moveSinglePartition(
    worker: PersistentWorker,
    fromIndex: number,
    toIndex: number,
    timestamp: number,
    sequence: number
  ): number {
    const fromRef = this.imageoperations[fromIndex];
    const toRef = this.imageoperations[toIndex];
    if (toRef.size > 0) {
      // only need a clear operation if there is something below
      const earliestImageOperation = toRef.values().next().value;
      const clearValue = new CoreImageOperation({
        who_created: worker.user.data.userid,
        created_timestamp: sequence,
        bandid: earliestImageOperation.data.bandid,
        partitionid: toIndex,
        enabled: 1,
        layer: -1,
        operationtype: ImageOperationType.Clear,
        updated_timestamp: sequence,
      });
      toRef.set(sequence++, clearValue);
      CoreImageOperation.createLocal(worker, clearValue);
      CoreImageOperation.createServer(worker, clearValue); // no need to wait
    }
    for (const [key, value] of fromRef) {
      if (key > timestamp) {
        // we have reached the newly 'moved' values
        break;
      }
      const toValue = value.clone();
      toValue.data.partitionid = toIndex;
      toValue.data.created_timestamp = sequence;
      toValue.data.updated_timestamp = sequence;
      toValue.data.server_written = 0;
      toRef.set(sequence++, toValue);
      CoreImageOperation.createLocal(worker, toValue);
      CoreImageOperation.createServer(worker, toValue); // no need to wait
    }
    return sequence;
  }
  public imageIdChange(oldId: number, newId: number) {
    if (this.data.imageid === oldId) {
      this.data.imageid = newId;
    }
    // imageoperations
    for (const opstackRef of this.imageoperations) {
      for (const value of opstackRef.values()) {
        if (value.data.imageid === oldId) {
          value.data.imageid = newId;
        }
      }
    }
  }
  public userIdChange(oldId: number, newId: number) {
    if (this.data.who_created === oldId) {
      this.data.who_created = newId;
    }
    if (this.data.who_updated === oldId) {
      this.data.who_updated = newId;
    }
    // imageoperations
    for (const opstackRef of this.imageoperations) {
      for (const value of opstackRef.values()) {
        if (value.data.who_created === oldId) {
          value.data.who_created = newId;
        }
      }
    }
  }
  public bandIdChange(oldId: number, newId: number) {
    if (this.data.bandid === oldId) {
      this.data.bandid = newId;
    }
    // imageoperations
    for (const opstackRef of this.imageoperations) {
      for (const value of opstackRef.values()) {
        if (value.data.bandid === oldId) {
          value.data.bandid = newId;
        }
      }
    }
  }
  public linkPartition(lindex: number) {
    console.log('CoreBand.linkPartition ' + lindex);
  }
  public unlinkPartition(lindex: number) {
    console.log('CoreBand.unlinkPartition ' + lindex);
  }
  public static columns(): string[] {
    return wsBand.columns;
  }
  public values(): unknown[] {
    return this.data.values();
  }
  // Local Database calls
  private createLocalQuery(nextLocalId: number, timestamp: number): string {
    if (!this.data.bandid || this.data.bandid === 0) {
      this.data.bandid = nextLocalId;
    }
    if (timestamp && timestamp > 0) {
      if (!this.data.created_timestamp || this.data.created_timestamp === 0) {
        this.data.created_timestamp = timestamp;
      }
      if (!this.data.updated_timestamp || this.data.updated_timestamp === 0) {
        this.data.updated_timestamp = timestamp;
      }
    }
    if (!this.data.band_guid || this.data.band_guid.length === 0) {
      this.data.band_guid = CoreBand.uniqueguid();
    }
    if (!this.data.identity_guid || this.data.identity_guid.length === 0) {
      this.data.identity_guid = CoreBand.uniqueguid();
    }
    const sql =
      'INSERT INTO ' +
      wsBand.table +
      ' (' +
      CoreBand.columns().join(', ') +
      ') ' +
      'VALUES (' +
      Array(CoreBand.columns().length).fill('?').join(', ') +
      ')';
    return sql;
  }
  private static findLocalQuery(where: string = '', orderby = ''): string {
    const sql =
      'SELECT * FROM ' +
      wsBand.table +
      (where.length > 0 ? ' WHERE ' + where : '') +
      (orderby.length > 0 ? ' ORDER BY ' + orderby : '');
    return sql;
  }
  private readLocalQuery(): string {
    return CoreBand.findLocalQuery(
      CoreBand.columns().slice(0, CoreBand.columnsStart).join('=? AND ') + '=?'
    );
  }
  private updateLocalQuery(timestamp: number = 0): string {
    if (timestamp && timestamp > 0) {
      this.data.updated_timestamp = timestamp;
    }
    const sql =
      'UPDATE ' +
      wsBand.table +
      ' SET ' +
      CoreBand.columns().slice(CoreBand.columnsStart).join('=?, ') +
      '=? ' +
      'WHERE ' +
      CoreBand.columns().slice(0, CoreBand.columnsStart).join('=? AND ') +
      '=?';
    return sql;
  }
  private updateLocalWithIdQuery(timestamp: number = 0): string {
    if (timestamp && timestamp > 0) {
      this.data.updated_timestamp = timestamp;
    }
    const sql =
      'UPDATE ' +
      wsBand.table +
      ' SET ' +
      CoreBand.columns().join('=?, ') +
      '=? ' +
      'WHERE ' +
      CoreBand.columns().slice(0, CoreBand.columnsStart).join('=? AND ') +
      '=?';
    return sql;
  }
  private deleteLocalQuery(): string {
    const sql = 'DELETE FROM ' + wsBand.table + ' ' + 'WHERE bandid=?';
    return sql;
  }
  // Server Database Calls
  private createServerQuery(timestamp: number): object {
    if (!this.data.created_timestamp || this.data.created_timestamp === 0) {
      this.data.created_timestamp = timestamp;
    }
    if (!this.data.updated_timestamp || this.data.updated_timestamp === 0) {
      this.data.updated_timestamp = timestamp;
    }
    this.data.server_written = timestamp;
    return Object.fromEntries(
      Object.entries(this.data).slice(CoreBand.columnsStart)
    );
  }
  private serverIdentity(): object {
    return { bandid: this.data.bandid };
  }
  private updateServerQuery(timestamp: number): object {
    this.data.server_written = timestamp;
    return Object.fromEntries(
      Object.entries(this.data).slice(CoreBand.columnsStart)
    );
  }
  // Convenience functions:
  public static async findServer(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreBand[] {
    const coreBands = new Array<CoreBand>();
    try {
      const jsonFind = await worker.fetchServer(
        CoreBand.serverTable,
        where ? where : {},
        'GET'
      );
      if (jsonFind) {
        if (
          jsonFind.success &&
          jsonFind.results &&
          jsonFind.results.length > 0
        ) {
          // found
          for (const row of jsonFind.results) {
            coreBands.push(new CoreBand(row));
          }
        } else {
          worker.log(
            LogLevel.debug,
            'CoreBand.findServer not found',
            where,
            orderby,
            jsonFind
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreBand.findServer failed', where, orderby);
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreBand.findServer exception:',
        error,
        where,
        orderby
      );
    }
    return coreBands;
  }
  public static async findServerItem(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreBand {
    let coreBand = null;
    try {
      const jsonFind = await worker.fetchServer(
        CoreBand.serverTable,
        where ? where : {},
        'GET'
      );
      if (jsonFind) {
        if (
          jsonFind.success &&
          jsonFind.results &&
          jsonFind.results.length > 0
        ) {
          // found
          coreBand = new CoreBand(jsonFind.results[0]);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreBand.findServerItem not found',
            where,
            orderby,
            jsonFind
          );
        }
      } else {
        worker.log(
          LogLevel.warn,
          'CoreBand.findServerItem failed',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreBand.findServerItem exception:',
        error,
        where,
        orderby
      );
    }
    return coreBand;
  }
  public static async findByWhoWhenServer(
    worker: PersistentWorker,
    userid: number,
    createdTimestamp: number
  ): CoreBand {
    return CoreBand.findServerItem(worker, {
      who_created: userid,
      created_timestamp: createdTimestamp,
    });
  }
  public static async findByUserServer(
    worker: PersistentWorker,
    userid: number
  ): CoreBand[] {
    return CoreBand.findServer(worker, { who_created: userid });
  }
  public static async createServer(
    worker: PersistentWorker,
    coreBand: CoreBand
  ): CoreBand {
    try {
      const timestamp = PersistentWorker.getTimestamp();
      const jsonCreate = await worker.fetchServer(
        CoreBand.serverTable,
        coreBand.createServerQuery(timestamp),
        'POST'
      );
      if (jsonCreate) {
        if (jsonCreate.success) {
          // created
          let localId = 0;
          if (
            jsonCreate.meta &&
            jsonCreate.meta.last_row_id &&
            jsonCreate.meta.last_row_id > 0 &&
            coreBand.data.bandid > 0 &&
            coreBand.data.bandid < PersistentClient.minServerId
          ) {
            localId = coreBand.data.bandid;
            coreBand.localId = localId;
            coreBand.data.bandid = jsonCreate.meta.last_row_id;
          }
          // update server_written, and maybe also id
          coreBand.updateLocal(worker, timestamp);
          // if id changed, trigger a local id update
          if (localId > 0) {
            worker.bandIdChange(localId, coreBand.data.bandid);
          }
        } else {
          worker.log(
            LogLevel.debug,
            'CoreBand.createServer not created',
            jsonCreate
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreBand.createServer failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreBand.createServer exception:', error);
    }
    return coreBand;
  }
  public async updateServer(worker: PersistentWorker): boolean {
    let worked = false;
    try {
      const timestamp = PersistentWorker.getTimestamp();
      const jsonCreate = await worker.fetchServer(
        CoreBand.serverTable,
        this.updateServerQuery(timestamp),
        'PATCH',
        this.data.bandid
      );
      if (jsonCreate) {
        if (jsonCreate.success) {
          worked = true;
          // now store the new server_written
          this.updateLocal(worker);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreBand.updateServer not updated',
            jsonCreate
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreBand.updateServer failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreBand.updateServer exception:', error);
    }
    return worked;
  }
  public async deleteServer(worker: PersistentWorker): boolean {
    let worked = false;
    try {
      const jsonCreate = await worker.fetchServer(
        CoreBand.serverTable,
        {},
        'DELETE',
        this.data.bandid
      );
      if (jsonCreate) {
        if (!jsonCreate.success) {
          worker.log(
            LogLevel.debug,
            'CoreBand.deleteServer not created',
            jsonCreate
          );
        } else {
          worked = true;
        }
      } else {
        worker.log(LogLevel.warn, 'CoreBand.deleteServer failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreBand.deleteServer exception:', error);
    }
    return worked;
  }
  public static findLocal(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreBand[] {
    const coreBands = new Array<CoreBand>();
    try {
      let wherestr = '';
      let wherevals = [];
      if (where) {
        wherestr = Object.keys(where).join('=? AND ') + '=?';
        wherevals = Object.values(where);
      }
      const rows = worker.fetchLocal(
        CoreBand.findLocalQuery(wherestr),
        wherevals
      );
      if (rows.length > 0) {
        for (const row of rows) {
          coreBands.push(new CoreBand(row));
        }
      } else {
        worker.log(
          LogLevel.debug,
          'CoreBand.findLocal not found',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreBand.findLocal exception:',
        error,
        where,
        orderby
      );
    }
    return coreBands;
  }
  public static findLocalItem(
    worker: PersistentWorker,
    where: object = null,
    orderby: string = '',
    joiner: string = 'AND'
  ): CoreBand {
    let coreBand = null;
    try {
      let wherestr = '';
      let wherevals = [];
      if (where) {
        wherestr = Object.keys(where).join('=? ' + joiner + ' ') + '=?';
        wherevals = Object.values(where);
      }
      const rows = worker.fetchLocal(
        CoreBand.findLocalQuery(wherestr),
        wherevals
      );
      if (rows.length > 0) {
        coreBand = new CoreBand(rows[0]);
      } else {
        worker.log(
          LogLevel.debug,
          'CoreBand.findLocalItem not found',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreBand.findLocalItem exception:',
        error,
        where,
        orderby
      );
    }
    return coreBand;
  }
  public static findByWhoWhenLocal(
    worker: PersistentWorker,
    userid: number,
    createdTimestamp: number
  ): CoreBand {
    return CoreBand.findLocalItem(worker, {
      who_created: userid,
      created_timestamp: createdTimestamp,
    });
  }
  public static findByUserLocal(
    worker: PersistentWorker,
    userid: number
  ): CoreBand[] {
    return CoreBand.findLocal(worker, { who_created: userid });
  }
  public static findByUserCreatModLocal(
    worker: PersistentWorker,
    userid: number
  ): CoreBand[] {
    return CoreBand.findLocal(
      worker,
      { who_created: userid, who_updated: userid },
      '',
      'OR'
    );
  }
  public static findByImageLocal(
    worker: PersistentWorker,
    imageid: number
  ): CoreBand[] {
    return CoreBand.findLocal(worker, { imageid: imageid });
  }
  public static findByStageLatestLocal(
    worker: PersistentWorker,
    userid: number,
    productionStage: ProductionStage
  ): CoreBand {
    return CoreBand.findLocalItem(
      worker,
      { who_created: userid, production_stage: productionStage },
      'updated_timestamp DESC LIMIT 1'
    );
  }
  public static createLocal(
    worker: PersistentWorker,
    coreBand: CoreBand
  ): CoreBand {
    try {
      if (
        !worker.execLocal(
          coreBand.createLocalQuery(
            worker.nextLocalBandId++,
            PersistentWorker.getTimestamp()
          ),
          coreBand.values()
        )
      ) {
        worker.log(LogLevel.warn, 'CoreBand.createLocal failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreBand.createLocal exception:', error);
    }
    return coreBand;
  }
  public updateLocal(worker: PersistentWorker, timestamp: number = 0): boolean {
    let worked = false;
    try {
      const values = this.values();
      if (this.localId > 0) {
        // switch to server id
        values.push(this.localId);
        if (!worker.execLocal(this.updateLocalWithIdQuery(timestamp), values)) {
          worker.log(LogLevel.warn, 'CoreBand.updateLocal failed');
        } else {
          this.localId = 0;
          worked = true;
        }
      } else {
        values.push(values.shift());
        if (!worker.execLocal(this.updateLocalQuery(timestamp), values)) {
          worker.log(LogLevel.warn, 'CoreBand.updateLocal failed');
        } else {
          worked = true;
        }
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreBand.updateLocal exception:', error);
    }
    return worked;
  }
  public static deleteLocal(
    worker: PersistentWorker,
    coreBand: CoreBand
  ): boolean {
    let worked = false;
    try {
      if (
        !worker.execLocal(
          coreBand.deleteLocalQuery(),
          coreBand.values().slice(0, CoreBand.columnsStart)
        )
      ) {
        worker.log(LogLevel.warn, 'CoreBand.deleteLocal failed');
      } else {
        worked = true;
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreBand.deleteLocal exception:', error);
    }
    return worked;
  }
}
