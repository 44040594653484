import { PartitionProps } from '~/components/designer2/DynamicPartition';
import { CropFrame, Effect } from '~/shared/CoreImageOperation';

export function OffscreenPartition(
  partition: PartitionProps,
  rotation: number = 0,
  xBleed: number = 51,
  yBleed: number = 0,
  vDim: number = 1024,
  squares: number = 1
): string {
  const title = 'Band Partition ' + partition.index;
  const vWidth = vDim;
  const vHeight = vDim * squares;
  const fvWidth = vWidth + 2 * xBleed;
  const fvHeight = vHeight + 2 * yBleed;
  // rotated values:
  const isVertical = rotation === 0 || rotation === 180;
  const rvWidth = isVertical ? vWidth : vHeight;
  const rvHeight = isVertical ? vHeight : vWidth;
  const rvxMin = -rvWidth * 0.5;
  const rvyMin = -rvHeight * 0.5;
  const rfvWidth = isVertical ? fvWidth : fvHeight;
  const rfvHeight = isVertical ? fvHeight : fvWidth;
  const rfvxMin = -rfvWidth * 0.5;
  const rfvyMin = -rfvHeight * 0.5;
  // *INDENT-OFF*
  return (
    `<svg
			viewBox="${-fvWidth * 0.5} ${-fvHeight * 0.5} ${fvWidth} ${fvHeight}"
			width="${fvWidth}"
			height="${fvHeight}"
			xmlns="http://www.w3.org/2000/svg"
							>
			<title>${title}</title>
			<defs>
				<mask id="crop">
					<rect x="${rfvxMin}" y="${rfvyMin}" width="${rfvWidth}" height="${rfvHeight}" fill="${partition.imgcropframe === CropFrame.none ? 'white' : 'black'}"/>` +
    (partition.imgcropframe === CropFrame.circle
      ? `<ellipse cx="0" cy="0" rx="${Math.round(rvWidth * 0.5)}" ry="${Math.round(rvHeight * 0.5)}" fill="white"/>`
      : '') +
    (partition.imgcropframe === CropFrame.oval
      ? `<ellipse cx="0" cy="0" rx="${Math.round(rvWidth * 0.375)}" ry="${Math.round(rvHeight * 0.5)}" fill="white"/>`
      : '') +
    (partition.imgcropframe === CropFrame.square
      ? `<rect x="${Math.round(rvxMin * 0.75)}" y="${Math.round(rvyMin * 0.75)}" width="${Math.round(rvWidth * 0.75)}" height="${Math.round(rvHeight * 0.75)}" fill="white"/>`
      : '') +
    (partition.imgcropframe === CropFrame.splash
      ? `<path style="fill:#ffffff;stroke-width:7.447" transform="translate(${rvxMin},${rvyMin}) scale(${Math.round(rvWidth / 1024)},${Math.round(rvHeight / 1024)})"
						d="m 840.32948,990.88121 c -59.128,35.76809 -149.77718,-147.05918 -219.86852,-149.32852 -65.39046,-2.11714 48.35552,159.48891 -20.31548,168.13781 
						-68.67099,8.6489 -28.77046,-178.92658 -96.9178,-175.60961 -68.14733,3.31697 -18.05795,183.03961 -84.32186,176.26601 C 352.6419,1003.5733 
						438.06759,853.02618 379.43944,823.5474 320.81128,794.06863 231.41414,1008.3053 173.91436,976.97899 116.41457,945.65263 347.84717,766.34921 
						296.91013,725.5602 245.97309,684.77118 132.90235,881.94119 91.720938,828.38173 50.539518,774.82226 243.96276,715.92506 219.88551,651.32214 
						195.80826,586.71923 78.213035,695.22531 58.335735,633.05498 38.458435,570.88465 175.86401,605.99197 179.27794,536.77369 182.69187,467.55542 
						19.666819,480.80485 32.172569,416.96183 44.678319,353.1188 187.39205,436.79893 217.12785,376.24546 246.86365,315.69199 36.766989,243.93165 
						76.633769,189.59307 116.50055,135.25449 231.08004,344.96443 284.36325,300.5097 337.64645,256.05496 140.99879,98.932567 195.43804,61.440807 
						249.87729,23.949047 306.72337,256.23689 370.16702,231.7306 433.61067,207.22431 331.57571,48.924129 399.44137,41.018169 467.30705,33.112219 
						462.645,166.99579 530.38689,162.64954 598.12878,158.30329 601.35932,22.520202 669.75962,34.834532 738.15993,47.148852 588.37869,202.00029 
						654.17427,221.15212 719.96985,240.30395 707.37569,133.1008 755.01243,118.11513 1083.0089,14.933306 693.47857,255.09254 744.32881,302.52704 
						c 50.85022,47.43449 172.76043,-163.99721 206.23576,-103.7229 33.47533,60.27432 -162.48408,99.48699 -129.97893,160.10905 32.50515,60.62206 
						125.29049,-44.20912 142.87968,18.37527 17.58919,62.5844 -112.55124,85.66672 -106.2771,150.87498 6.27413,65.20826 161.15098,14.89652 
						149.93778,83.07259 -11.21312,68.17607 -127.97396,-20.52844 -134.80318,32.90588 -8.70289,68.09463 115.05564,111.53389 78.66529,165.64267 
						-36.39035,54.10878 -149.32769,-112.92818 -202.81716,-68.61731 -53.48947,44.31087 151.28653,213.94585 92.15853,249.71394 z" />`
      : '') +
    (partition.imgcropframe === CropFrame.star
      ? `<path style="fill:#ffffff;stroke-width:7.447" transform="translate(${rvxMin},${rvyMin}) scale(${Math.round(rvWidth / 1024)},${Math.round(rvHeight / 1024)})"
						d="M 827.14157,981.21833 511.63491,814.87793 195.74211,980.48388 256.4443,629.0172 1.3274419,379.76034 354.35013,328.88239 512.57204,9.2272298
						670.04986,329.2496 1022.9532,380.9487 767.2572,629.61136 Z" />`
      : '') +
    (partition.imgcropframe === CropFrame.backsquare
      ? `<g transform="rotate(-12)">
						<rect x="${Math.round(rvxMin * 0.83)}" y="${Math.round(rvyMin * 0.83)}" width="${Math.round(rvWidth * 0.83)}" height="${Math.round(rvHeight * 0.83)}" fill="white"/>
					</g>`
      : '') +
    (partition.imgcropframe === CropFrame.forwardsquare
      ? `<g transform="rotate(12)">
						<rect x="${Math.round(rvxMin * 0.83)}" y="${Math.round(rvyMin * 0.83)}" width="${Math.round(rvWidth * 0.83)}" height="${Math.round(rvHeight * 0.83)}" fill="white"/>
					</g>`
      : '') +
    `</mask>
			</defs>
			<filter id="filteroffscreen" x="0" y="0" color-interpolation-filters="sRGB">
				<feComponentTransfer>
					<feFuncR type="linear" slope="${partition.imgbrightness}" />
					<feFuncG type="linear" slope="${partition.imgbrightness}" />
					<feFuncB type="linear" slope="${partition.imgbrightness}" />
				</feComponentTransfer>` +
    (partition.imgcontrast !== 1
      ? `<feComponentTransfer>
						<feFuncR type="linear" slope="${partition.imgcontrast}" intercept="${-(0.5 * partition.imgcontrast) + 0.5}"/>
						<feFuncG type="linear" slope="${partition.imgcontrast}" intercept="${-(0.5 * partition.imgcontrast) + 0.5}"/>
						<feFuncB type="linear" slope="${partition.imgcontrast}" intercept="${-(0.5 * partition.imgcontrast) + 0.5}"/>
					</feComponentTransfer>`
      : '') +
    (partition.imgeffect === Effect.warm
      ? `<feComponentTransfer>
						<feFuncR type="linear" slope="1.1" />
						<feFuncG type="linear" slope="1.1" />
						<feFuncB type="linear" slope="1.1" />
					</feComponentTransfer>
					<feComponentTransfer>
						<feFuncR type="linear" slope="1.15" intercept="${-(0.5 * 1.15) + 0.5}"/>
						<feFuncG type="linear" slope="1.15" intercept="${-(0.5 * 1.15) + 0.5}"/>
						<feFuncB type="linear" slope="1.15" intercept="${-(0.5 * 1.15) + 0.5}"/>
					</feComponentTransfer>
					<feColorMatrix result="blendin1" type="saturate" values="${1.25}"/>
					<feFlood result="warmoverlay" x="-512" y="-512" width="100%" height="100%" flood-color="#fae900" flood-opacity="0.23" />
					<feBlend in="blendin1" in2="warmoverlay" mode="overlay" />`
      : '') +
    (partition.imgeffect === Effect.vintage
      ? `<feColorMatrix result="blendin1" type="matrix"
							values="${0.393 + 0.607 * (1 - 1.0)} ${0.769 - 0.769 * (1 - 1.0)} ${0.189 - 0.189 * (1 - 1.0)} 0 0 
									${0.349 - 0.349 * (1 - 1.0)} ${0.686 + 0.314 * (1 - 1.0)} ${0.168 - 0.168 * (1 - 1.0)} 0 0 
									${0.272 - 0.272 * (1 - 1.0)} ${0.534 - 0.534 * (1 - 1.0)} ${0.131 + 0.869 * (1 - 1.0)} 0 0 
									0 0 0 1 0"/>
					<feTurbulence baseFrequency="0.01 0.005" seed="0" xresult="colorNoise" />
					<feBlend in="blendin1" in2="colorNoise" mode="multiply" />`
      : '') +
    (partition.imgeffect === Effect.cool
      ? `<feComponentTransfer>
						<feFuncR type="linear" slope="1.1" />
						<feFuncG type="linear" slope="1.1" />
						<feFuncB type="linear" slope="1.1" />
					</feComponentTransfer>
					<feComponentTransfer>
						<feFuncR type="linear" slope="1.1" intercept="${-(0.5 * 1.1) + 0.5}"/>
						<feFuncG type="linear" slope="1.1" intercept="${-(0.5 * 1.1) + 0.5}"/>
						<feFuncB type="linear" slope="1.1" intercept="${-(0.5 * 1.1) + 0.5}"/>
					</feComponentTransfer>
					<feColorMatrix type="matrix"
							values="${0.2126 + 0.7874 * (1 - 0.1)} ${0.7152 - 0.7152 * (1 - 0.1)} ${0.0722 - 0.0722 * (1 - 0.1)} 0 0 
									${0.2126 - 0.2126 * (1 - 0.1)} ${0.7152 + 0.2848 * (1 - 0.1)} ${0.0722 - 0.0722 * (1 - 0.1)} 0 0 
									${0.2126 - 0.2126 * (1 - 0.1)} ${0.7152 - 0.7152 * (1 - 0.1)} ${0.0722 + 0.9278 * (1 - 0.1)} 0 0 
									0 0 0 1 0"/>
					<feColorMatrix type="matrix"
							values="${0.393 + 0.607 * (1 - 0.5)} ${0.769 - 0.769 * (1 - 0.5)} ${0.189 - 0.189 * (1 - 0.5)} 0 0 
									${0.349 - 0.349 * (1 - 0.5)} ${0.686 + 0.314 * (1 - 0.5)} ${0.168 - 0.168 * (1 - 0.5)} 0 0 
									${0.272 - 0.272 * (1 - 0.5)} ${0.534 - 0.534 * (1 - 0.5)} ${0.131 + 0.869 * (1 - 0.5)} 0 0 
									0 0 0 1 0"/>
					<feColorMatrix result="blendin1" type="saturate" values="${1 - 0.85}"/>
					<feFlood result="coolmultiply" x="-512" y="-512" width="100%" height="100%" flood-color="#00e5fa" flood-opacity="0.13" />
					<feBlend in="blendin1" in2="coolmultiply" mode="multiply" />`
      : '') +
    (partition.imgeffect === Effect.faded
      ? `<feComponentTransfer>
							<feFuncR type="linear" slope="${1.2}" />
							<feFuncG type="linear" slope="${1.2}" />
							<feFuncB type="linear" slope="${1.2}" />
					</feComponentTransfer>
					<feComponentTransfer>
						<feFuncR type="linear" slope="${0.7}" intercept="${-(0.5 * 0.7) + 0.5}"/>
						<feFuncG type="linear" slope="${0.7}" intercept="${-(0.5 * 0.7) + 0.5}"/>
						<feFuncB type="linear" slope="${0.7}" intercept="${-(0.5 * 0.7) + 0.5}"/>
					</feComponentTransfer>
					<feColorMatrix type="saturate" values="${1 - 0.75}"/>`
      : '') +
    (partition.imgeffect === Effect.graphite
      ? `<feColorMatrix type="matrix"
							values="${0.2126 + 0.7874 * (1 - 1.0)} ${0.7152 - 0.7152 * (1 - 1.0)} ${0.0722 - 0.0722 * (1 - 1.0)} 0 0 
									${0.2126 - 0.2126 * (1 - 1.0)} ${0.7152 + 0.2848 * (1 - 1.0)} ${0.0722 - 0.0722 * (1 - 1.0)} 0 0 
									${0.2126 - 0.2126 * (1 - 1.0)} ${0.7152 - 0.7152 * (1 - 1.0)} ${0.0722 + 0.9278 * (1 - 1.0)} 0 0 
									0 0 0 1 0"/>
					<feComponentTransfer>
						<feFuncR type="linear" slope="${1.2}" />
						<feFuncG type="linear" slope="${1.2}" />
						<feFuncB type="linear" slope="${1.2}" />
					</feComponentTransfer>
					<feComponentTransfer>
						<feFuncR type="linear" slope="${1.3}" intercept="${-(0.5 * 1.3) + 0.5}"/>
						<feFuncG type="linear" slope="${1.3}" intercept="${-(0.5 * 1.3) + 0.5}"/>
						<feFuncB type="linear" slope="${1.3}" intercept="${-(0.5 * 1.3) + 0.5}"/>
					</feComponentTransfer>
					<feComponentTransfer>
						<feFuncR type="discrete" tableValues="${PartitionProps.posterizeLevels(1)}" />
						<feFuncG type="discrete" tableValues="${PartitionProps.posterizeLevels(1)}" />
						<feFuncB type="discrete" tableValues="${PartitionProps.posterizeLevels(1)}" />
					</feComponentTransfer>`
      : '') +
    (partition.imginvert > 0
      ? `<feComponentTransfer>
						<feFuncR type="table" tableValues="${partition.imginvert} ${1 - partition.imginvert}"/>
						<feFuncG type="table" tableValues="${partition.imginvert} ${1 - partition.imginvert}"/>
						<feFuncB type="table" tableValues="${partition.imginvert} ${1 - partition.imginvert}"/>
					</feComponentTransfer>`
      : '') +
    (partition.imghuerotate !== 0
      ? `<feColorMatrix type="hueRotate" values="${partition.imghuerotate}"/>`
      : '') +
    (partition.imgsaturate !== 1
      ? `<feColorMatrix type="saturate" values="${partition.imgsaturate}"/>`
      : '') +
    (partition.imggrayscale > 0
      ? `<feColorMatrix type="matrix"
							values="${0.2126 + 0.7874 * (1 - partition.imggrayscale)} ${0.7152 - 0.7152 * (1 - partition.imggrayscale)} ${0.0722 - 0.0722 * (1 - partition.imggrayscale)} 0 0 
									${0.2126 - 0.2126 * (1 - partition.imggrayscale)} ${0.7152 + 0.2848 * (1 - partition.imggrayscale)} ${0.0722 - 0.0722 * (1 - partition.imggrayscale)} 0 0 
									${0.2126 - 0.2126 * (1 - partition.imggrayscale)} ${0.7152 - 0.7152 * (1 - partition.imggrayscale)} ${0.0722 + 0.9278 * (1 - partition.imggrayscale)} 0 0 
									0 0 0 1 0"/>`
      : '') +
    (partition.imgsepia > 0
      ? `<feColorMatrix type="matrix"
							values="${0.393 + 0.607 * (1 - partition.imgsepia)} ${0.769 - 0.769 * (1 - partition.imgsepia)} ${0.189 - 0.189 * (1 - partition.imgsepia)} 0 0 
									${0.349 - 0.349 * (1 - partition.imgsepia)} ${0.686 + 0.314 * (1 - partition.imgsepia)} ${0.168 - 0.168 * (1 - partition.imgsepia)} 0 0 
									${0.272 - 0.272 * (1 - partition.imgsepia)} ${0.534 - 0.534 * (1 - partition.imgsepia)} ${0.131 + 0.869 * (1 - partition.imgsepia)} 0 0 
									0 0 0 1 0"/>`
      : '') +
    (partition.imgsharpen > 0
      ? `<feConvolveMatrix kernelMatrix="0 ${-partition.imgsharpen} 0 
													${-partition.imgsharpen} ${1 + 4 * partition.imgsharpen} ${-partition.imgsharpen} 
													0 ${-partition.imgsharpen} 0"/>`
      : '') +
    (partition.imgblur > 0
      ? `<feGaussianBlur stdDeviation="${partition.imgblur}"/>`
      : '') +
    (partition.imgposterize < 16
      ? `<feComponentTransfer>
						<feFuncR type="discrete" tableValues="${PartitionProps.posterizeLevels(partition.imgposterize)}" />
						<feFuncG type="discrete" tableValues="${PartitionProps.posterizeLevels(partition.imgposterize)}" />
						<feFuncB type="discrete" tableValues="${PartitionProps.posterizeLevels(partition.imgposterize)}" />
					</feComponentTransfer>`
      : '') +
    `</filter>
			<g transform="rotate(${rotation} 0 0)">
				<rect
					x="${rfvxMin}" y="${rfvyMin}"
					width="${rfvWidth}"
					height="${rfvHeight}"
					fill="${partition.bgcolor}"
					stroke-width="0"
					stroke="none"
									/>
				<g style="mask: url(#crop);">
					<g
						transform="
							translate(${Math.round(partition.imgtranslatex * rvWidth * 0.5)} ${Math.round(partition.imgtranslatey * rvHeight * 0.5)}) 
							scale(${partition.imgscale}) 
							rotate(${partition.imgrotation}) 
							"
										>
						<image
							x="${rvxMin}" y="${rvyMin}"
							width="${rvWidth}"
							height="${rvHeight}"
							href="${partition.imgsrc}"
							filter="url(#filteroffscreen)"
											/>
					</g>
				</g>
				<image x="${-rvxMin - (264 + 16)}" y="${rvyMin + 32}" width="264" height="264" href="${PartitionProps.getStickerDataurl(partition.imgsticker)}" />
			</g>
		</svg>`
  );
  // *INDENT-ON*
}
