import { CoreUser, UserRole } from './CoreUser';
import { CoreBand, ProductionStage } from './CoreBand';
import { CoreImage } from './CoreImage';
import { CoreImageOperation } from './CoreImageOperation';
import { PartitionProps } from '~/components/designer2/DynamicPartition';
import { DynamicBand, GalleryPhoto } from '~/components/designer2/DynamicBand';
import { DynamicNav } from '~/components/designer2/DynamicNav';
import { LogLevel, DynamicLog } from '~/components/designer2/DynamicLog';
import { isServer, getRequestEvent } from 'solid-js/web';
// vite bug workaround for typescript worker https://github.com/vitejs/vite/issues/11823
import workerUrl from '~/shared/PersistentWorker?worker&url';

// vite bug  ERROR  [vite:worker-import-meta-url] ../../node_modules/vinxi/runtime/http.js (71:9): "AsyncLocalStorage"
// is not exported by "__vite-browser-external", imported by "../../node_modules/vinxi/runtime/http.js"
// import { createBandProduct, CreateBandProductRequest, CreateBandProductResponse } from '~/services/serverNoAsync';

// PersistentCall class is used for all IPC function calls from pages to the common shared Web Worker,
// and also carries the response return values
export class PersistentCall {
  // unique id of the client (page/tab) making the request
  clientId: number;
  // sequential id of the request from this client
  requestId: number;
  // the name of the PersistentWorker member function to call
  method: string;
  // variable ... list of parameters to pass to the function call
  parameters: unknown[];
  // the return value
  returnValue: unknown;
}
// ResponseLock holds function pointers to be able to resolve, reject or timeout a Promise return value
class ResponseLock {
  // call this with an any value to complete this Promise
  resolve: Function;
  // call this with a string reason explainign the failure
  reject: Function;
  // remaining retry count
  retryCount: number;
  // cancel this timeout retry/kill function with clearTimeout on success
  timeoutId: number;
}
/** Each client (page/tab) has its own instance of PersistentClient which handles all calls to the shared PersistentWorker code,
 * such as database both local and server, and image store.
 * Each of these PersistentClient's waits on an exclusive lock to create the PersistentWorker -
 * so there is only ever one worker, and any page/tab can be safely closed without interrupting operations
 */
export class PersistentClient {
  static serviceName: string = 'DesignerPersistentStore';
  // This version string needs to match the one the worker is using. Format is MAJOR_VERSION.DATABASE_VERSION.MINOR_VERSION - increment minor beteen production releases
  static versionString: string = '1.2.1';
  // This key must match the one in ws-database/src/index.ts :
  static wsDatabaseAPIkey: string = ''; // env.VITE_WEARSHARE_DB_API_KEY
  static wsDatabaseAPIURL: string =
    'https://wsdatabase.vizidrix.workers.dev/api/';
  //	static wsDatabaseAPIURL: string = 'http://localhost:8787/api/';
  static imageStoreURL: string = 'https://images.wrsr.io/v1'; // env.VITE_WEARSHARE_IMAGESTORE_URL e.g. https://images.wrsr.io/v1
  static sessionCookieId: string = ''; // cookie 'session_id'
  static imageMimeType: string = 'image/webp';
  static imageExt: string = '.webp';
  static productTemplate: string = ''; // was 'designer2-thumbnail';
  // https://shopify.dev/docs/api/admin-graphql/2025-01/queries/customer?language=Node.js
  static shopifyBearerToken: string = ''; // env.VITE_SHOPIFY_TOKEN
  static shopifyShopname: string = ''; // env.VITE_SHOPIFY_SHOPNAME
  static shopifyURL: string =
    'https://' +
    PersistentClient.shopifyShopname +
    '.myshopify.com/admin/api/2025-01/graphql.json';

  // 2 minute maximum timeout for each function call, before retry
  static maxTimeout: number = 1000 * 60 * 2;
  // how many times to retry a proxy call
  static retryCount: number = 12;
  // images larger than this are resized down
  static maxImageDim: number = 4096;
  // the thumbnail just fits within a square of this side
  static thumbnailDim: number = 64;
  // we distinguish database objects which have been set on the server by them having an id >= to this
  static minServerId: number = 65536;
  worker: Worker;
  clientId: number;
  nextRequestId: number;
  requestQueue: Map<number, ResponseLock>;
  workerLock: ResponseLock;
  broadcastChannelRequest: BroadcastChannel;
  broadcastChannelResponse: BroadcastChannel;
  envInitialized: Promise<boolean>;
  workerAvailable: Promise<boolean>;
  allVersionsString: string = '';
  // this is the single instance of this class used by all other imports:
  private static _instance: PersistentClient;
  // Usage: in other files
  // import { PersistentClient } from '../shared/PersistentClient.ts';
  // and then:
  // const perisitentClient = PersistentClient.instance;
  public static get instance(): PersistentClient {
    // the isServer reference is to stop SSR trying to do browser stuff
    if (!isServer && !PersistentClient._instance) {
      PersistentClient._instance = new PersistentClient();
    }
    return PersistentClient._instance;
  }
  // this static function is called from entry-client.tsx
  public static initialize() {
    // Create the singleton instance for this client/page/tab
    const perisitentClient = PersistentClient.instance;
  }

  // the constructor is private to ensure the shared singleton is the only usage:
  private constructor() {
    this.envInitialized = PersistentClient.getEnvParameters(); // don't wait
    PersistentClient.getCookies();
    this.clientId = PersistentClient.uniqueid();
    this.nextRequestId = 1;
    this.requestQueue = new Map<number, ResponseLock>();
    this.workerLock = new ResponseLock();
    PersistentClient.log(LogLevel.debug, 'PersistentClient constructor');
    // use BroadcastChannel to share one active Worker! :)
    try {
      this.broadcastChannelRequest = new BroadcastChannel(
        PersistentClient.serviceName + 'Request'
      );
      this.broadcastChannelResponse = new BroadcastChannel(
        PersistentClient.serviceName + 'Response'
      );
      this.broadcastChannelResponse.onmessage = (event) => {
        // The stringify - parse cycle ensures that even packets from other pages can be understood
        const data = JSON.parse(
          JSON.stringify(event.data)
        ) as unknown as PersistentCall;
        this.responseHandler(data);
      };
    } catch (error) {
      PersistentClient.log(
        LogLevel.error,
        'PersistentClient.constructor BroadcastChannel exception:',
        error
      );
    }
    // spin off async function to create the single shared Web Worker only if, or when, no other tab has done so:
    this.startWorker();
    this.checkVersions();
  }
  private async checkVersions() {
    // we may be the first call, give the worker time to start if it is not already running
    // await new Promise((resolve) => {setTimeout(resolve, 10 * 1000)}); // 10 sec
    await this.workerAvailable;
    const workerVersion = await this.checkWorkerVersion();
    let browserVersion = '';
    if (navigator.appCodeName) {
      browserVersion =
        browserVersion + ' appCodeName[' + navigator.appCodeName + ']';
    }
    if (navigator.appName) {
      browserVersion = browserVersion + ' appName[' + navigator.appName + ']';
    }
    if (navigator.appVersion) {
      browserVersion =
        browserVersion + ' appVersion[' + navigator.appVersion + ']';
    }
    if (navigator.platform) {
      browserVersion = browserVersion + ' platform[' + navigator.platform + ']';
    }
    if (navigator.userAgent) {
      browserVersion =
        browserVersion + ' userAgent[' + navigator.userAgent + ']';
    }
    this.allVersionsString =
      'client v' +
      PersistentClient.versionString +
      ' worker v' +
      workerVersion +
      ' browser: ' +
      browserVersion;
    if (workerVersion !== PersistentClient.versionString) {
      PersistentClient.log(
        LogLevel.warn,
        'Multiple versions of the app running: ' + this.allVersionsString
      );
      this.setFailure(
        'Multiple versions of the app running, please close other tabs and reload ' +
          this.allVersionsString
      );
    } else {
      PersistentClient.log(
        LogLevel.info,
        'Versions: ' + this.allVersionsString
      );
    }
  }
  public async checkWorkerVersion(): string {
    let result = 0;
    try {
      result = await this.proxycall(
        'checkWorkerVersion',
        -1,
        PersistentClient.versionString
      );
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.checkWorkerVersion exception:',
        error
      );
    }
    return result;
  }
  public static async getEnv(): object {
    const event = getRequestEvent(); // get the fetch request event that returned this page, in order to access the web server env parameters
    if (
      event != undefined &&
      event.nativeEvent.context.cloudflare &&
      event.nativeEvent.context.cloudflare.env
    ) {
      return event.nativeEvent.context.cloudflare.env;
    }
    return await import.meta.env;
  }
  public static async getCookies() {
    if (!isServer) {
      for (const cookie of document.cookie.split(';')) {
        const nameValue = cookie.split('=');
        if (nameValue[0].trim() === 'session_id') {
          PersistentClient.sessionCookieId = nameValue[1].trim();
          break;
        }
      }
    }
  }
  public static async getEnvParameters(): boolean {
    let envResolve = null;
    let envReject = null;
    const returnPromise = new Promise<boolean>((resolve, reject) => {
      envResolve = resolve;
      envReject = reject;
    });
    try {
      PersistentClient.getEnv().then((env) => {
        PersistentClient.shopifyBearerToken = env.VITE_SHOPIFY_TOKEN;
        PersistentClient.shopifyShopname = env.VITE_SHOPIFY_SHOPNAME;
        PersistentClient.shopifyURL =
          'https://' +
          PersistentClient.shopifyShopname +
          '.myshopify.com/admin/api/2025-01/graphql.json';
        PersistentClient.wsDatabaseAPIkey = env.VITE_WEARSHARE_DB_API_KEY;
        if (
          env.VITE_WEARSHARE_DB_API_URL &&
          env.VITE_WEARSHARE_DB_API_URL.length > 0
        ) {
          PersistentClient.log(
            LogLevel.debug,
            'PersistentClient.getEnvParameters wsDatabaseAPIURL was:' +
              PersistentClient.wsDatabaseAPIURL +
              ' -> env ' +
              env.VITE_WEARSHARE_DB_API_URL
          );
          PersistentClient.wsDatabaseAPIURL = env.VITE_WEARSHARE_DB_API_URL;
        }
        if (
          env.VITE_WEARSHARE_IMAGESTORE_URL &&
          env.VITE_WEARSHARE_IMAGESTORE_URL.length > 0
        ) {
          PersistentClient.imageStoreURL = env.VITE_WEARSHARE_IMAGESTORE_URL;
        }
        let worked = true;
        /*
         * If you get any of these errors, you may need to e.g. export VITE_SHOPIFY_TOKEN="..."
         * Also check src/global.d and .dev.vars
         */
        if (
          !PersistentClient.shopifyBearerToken ||
          PersistentClient.shopifyBearerToken.length === 0
        ) {
          worked = false;
          PersistentClient.log(
            LogLevel.error,
            'PersistentClient.getEnvParameters you must set environment variable VITE_SHOPIFY_TOKEN'
          );
        }
        if (
          !PersistentClient.shopifyShopname ||
          PersistentClient.shopifyShopname.length === 0
        ) {
          worked = false;
          PersistentClient.log(
            LogLevel.error,
            'PersistentClient.getEnvParameters you must set environment variable VITE_SHOPIFY_SHOPNAME'
          );
        }
        if (
          !PersistentClient.wsDatabaseAPIkey ||
          PersistentClient.wsDatabaseAPIkey.length === 0
        ) {
          worked = false;
          PersistentClient.log(
            LogLevel.error,
            'PersistentClient.getEnvParameters you must set environment variable VITE_WEARSHARE_DB_API_KEY'
          );
        }
        if (
          !PersistentClient.wsDatabaseAPIURL ||
          PersistentClient.wsDatabaseAPIURL.length === 0
        ) {
          //worked = false;
          PersistentClient.log(
            LogLevel.error,
            'PersistentClient.getEnvParameters you must set environment variable VITE_WEARSHARE_DB_API_URL'
          );
        }
        if (
          !PersistentClient.imageStoreURL ||
          PersistentClient.imageStoreURL.length === 0
        ) {
          //worked = false;
          PersistentClient.log(
            LogLevel.warn,
            'PersistentClient.getEnvParameters you must set environment variable VITE_WEARSHARE_IMAGESTORE_URL'
          );
        }
        PersistentClient.log(
          LogLevel.debug,
          'PersistentClient.getEnvParameters wsDatabaseAPIURL now:' +
            PersistentClient.wsDatabaseAPIURL
        );
        if (worked) {
          envResolve(worked);
        } else {
          envReject(
            'PersistentClient.getEnvParameters you must set all environment variables correctly'
          );
        }
      });
    } catch (error) {
      PersistentClient.log(
        LogLevel.error,
        'PersistentClient.getEnvParameters exception:',
        error
      );
    }
    return returnPromise;
  }
  private async startWorker() {
    let workerResolve = null;
    this.workerAvailable = new Promise<boolean>((resolve) => {
      workerResolve = resolve;
    });
    try {
      if (window && window.Worker) {
        if (navigator.locks) {
          setTimeout(() => {
            workerResolve(true);
          }, 5000); // assume connected to another worker after 5 secs
          navigator.locks.request(
            PersistentClient.serviceName,
            { mode: 'exclusive' },
            async () => {
              // not allowed to create a classic worker here, would have to be passed in from entry-client.tsx
              // however we need a type: module worker, to allow typescript imports, anyway :)
              // import.meta.url allows us to use relative paths from the current script.
              // type module is needed to allow typescript imports
              PersistentClient.log(
                LogLevel.debug,
                'PersistentClient.startWorker we have the lock'
              );
              const worker = new Worker(
                new URL('./PersistentWorker.ts', import.meta.url),
                { type: 'module' }
              );
              // vite bug workaround for typescript worker https://github.com/vitejs/vite/issues/11823
              //const worker = new Worker(workerUrl, { type: 'module' });
              this.worker = worker;
              PersistentClient.log(
                LogLevel.debug,
                'PersistentClient.startWorker worker started using',
                workerUrl,
                this.worker
              );
              this.worker.onmessage = (event) => {
                const response = JSON.parse(
                  event.data
                ) as unknown as PersistentCall;
                this.responseHandler(response);
              };
              const request = {
                clientId: this.clientId,
                requestId: this.nextRequestId++,
                method: 'setClientId',
                parameters: [
                  this.clientId,
                  parseInt(this.getCookieValue('customer') || 0),
                ],
              } as PersistentCall;
              // set the newly created worker our client id:
              //this.requestHandler(request);
              this.worker.postMessage(JSON.stringify(request));
              // keep the lock until the page exits:
              const workerLock = this.workerLock;
              const workerPromise = new Promise((resolve, reject) => {
                workerLock.resolve = resolve;
                workerLock.reject = reject;
              }).catch((error) => {
                PersistentClient.log(
                  LogLevel.error,
                  'PersistentClient.startWorker rejected:',
                  error
                );
              });
              workerResolve(true);
              await workerPromise;
            }
          );
        } else {
          PersistentClient.log(
            LogLevel.error,
            'navigator.locks unavailable in this context'
          );
        }
      } else {
        PersistentClient.log(
          LogLevel.error,
          'web Worker unavailable in this context'
        );
      }
    } catch (error) {
      PersistentClient.log(
        LogLevel.error,
        'PersistentClient.startWorker exception:',
        error
      );
    }
  }
  public refreshImages() {
    if (DynamicBand.instance) {
      this.populateGallery(DynamicBand.instance);
    }
  }
  public updatePartition(clientId: number, partitionProps: PartitionProps) {
    if (clientId !== this.clientId && DynamicBand.instance) {
      DynamicBand.instance.partitionUpdate(partitionProps);
    }
  }
  public updateBandRotation(clientId: number, rotation: number) {
    if (clientId !== this.clientId && DynamicBand.instance) {
      DynamicBand.instance.sigSetRotation(Number(rotation));
    }
  }
  public updateBand(clientId: number, coreBand: CoreBand) {
    if (clientId !== this.clientId && DynamicBand.instance) {
      DynamicBand.instance.setName(coreBand.data.name, false);
      DynamicBand.instance.setSecret(coreBand.data.secret_message, false);
    }
  }
  public updateUser(clientId: number, coreUser: CoreUser) {
    if (clientId !== this.clientId) {
      if (DynamicBand.instance) {
        DynamicBand.instance.setEmail(coreUser.data.email, false);
        DynamicBand.instance.setPhone(coreUser.data.phone, false);
      }
      if (DynamicNav.instance) {
        DynamicNav.instance.sigSetUsername(coreUser.data.name);
        DynamicNav.instance.sigSetAvatar(coreUser.data.avatar);
      }
    }
  }
  public static log(level: LogLevel, ...args: unknown[]) {
    DynamicLog.logByLevel(level, false, ...args);
  }
  public static logNocon(level: LogLevel, ...args: unknown[]) {
    DynamicLog.logByLevel(level, true, ...args);
  }
  public imageIdChange(oldId: number, newId: number) {
    //PersistentClient.log(LogLevel.debug, 'PersistentClient update image id '+oldId+' -> '+newId);
    // need to update gallery
    if (DynamicBand.instance) {
      DynamicBand.instance.imageIdChange(oldId, newId);
    }
  }
  public addProgress(delta: number = 10) {
    if (DynamicBand.instance) {
      const newProgress = Math.max(
        Math.min(
          Number(DynamicBand.instance.sigGetProgress()) +
            Math.round(Number(delta)),
          100
        ),
        0
      );
      DynamicBand.instance.sigSetProgress(newProgress);
    }
  }
  public setProgress(total: number = 100) {
    if (DynamicBand.instance) {
      const newProgress = Math.max(Math.min(Math.round(Number(total)), 100), 0);
      DynamicBand.instance.sigSetProgress(newProgress);
    }
  }
  public userIdChange(oldId: number, newId: number) {
    PersistentClient.log(
      LogLevel.debug,
      'PersistentClient update user id ' + oldId + ' -> ' + newId
    );
  }
  public bandIdChange(oldId: number, newId: number) {
    PersistentClient.log(
      LogLevel.debug,
      'PersistentClient update band id ' + oldId + ' -> ' + newId
    );
  }
  public setFailure(message: string = '') {
    if (DynamicBand.instance) {
      DynamicBand.instance.sigSetFailureMessage(message);
    }
  }
  private requestHandler(request: PersistentCall) {
    if (this.worker) {
      // if we are the tab that owns the worker, send the message directly:
      //PersistentClient.log(LogLevel.debug, 'sent request to server in client direct'+JSON.stringify(request));
      this.worker.postMessage(JSON.stringify(request));
    } else {
      // some other tab has the one Web Worker, send the message there:
      //PersistentClient.log(LogLevel.debug, 'sent request to server in client crosstab');
      if (this.broadcastChannelRequest) {
        // only client side
        this.broadcastChannelRequest.postMessage(request);
      } else {
        PersistentClient.log(
          LogLevel.error,
          'PersistentClient.requestHandler BroadcastChannel not available in this context'
        );
      }
    }
  }
  private responseHandler(response: PersistentCall) {
    if (response.clientId === this.clientId) {
      // PersistentClient.log(LogLevel.debug, 'PersistentClient received response from worker to me', response);
      // now wake the Promise
      if (response.requestId > 0) {
        if (this.requestQueue.has(response.requestId)) {
          const responseLock = this.requestQueue.get(
            response.requestId
          ) as ResponseLock;
          // cancel the kill timer:
          if (responseLock.timeoutId) {
            clearInterval(responseLock.timeoutId);
            responseLock.timeoutId = null;
          }
          responseLock.resolve(response.returnValue);
          this.requestQueue.delete(response.requestId);
        }
      } else {
        this.workercallback(response.method, ...response.parameters);
      }
    } else if (response.clientId === 0) {
      this.workercallback(response.method, ...response.parameters);
    }
  }
  functionByName: {
    [K: string]: Function;
  } = {
    refreshImages: this.refreshImages,
    updatePartition: this.updatePartition,
    updateBandRotation: this.updateBandRotation,
    updateBand: this.updateBand,
    updateUser: this.updateUser,
    imageIdChange: this.imageIdChange,
    userIdChange: this.userIdChange,
    bandIdChange: this.bandIdChange,
    addProgress: this.addProgress,
    setProgress: this.setProgress,
    logNocon: PersistentClient.logNocon,
    log: PersistentClient.log,
    setFailure: this.setFailure,
  };
  public async workercallback(method: string, ...args: unknown[]): unknown {
    let result = 0;
    try {
      if (!this.functionByName[method]) {
        throw new Error('PersistentClient.' + method + ' is not implemented.');
      } else {
        result = this.functionByName[method].apply(this, args);
      }
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.workercallback ' + method + ' exception:',
        error
      );
    }
    return result;
  }
  // make a proxy function call on the Web Worker
  // timeout is how long to wait, in milliseconds, for a result. Use -1 for maximum delay
  public async proxycall(
    method: string,
    timeout: number,
    ...args: unknown[]
  ): unknown {
    let returnPromise = null;
    try {
      const request = {
        clientId: this.clientId,
        requestId: this.nextRequestId++,
        method: method,
        parameters: args,
      } as PersistentCall;
      if (timeout < 0) {
        timeout = PersistentClient.maxTimeout;
      }
      const requestQueue = this.requestQueue;
      const responseLock = {
        resolve: null,
        reject: null,
        retryCount: PersistentClient.retryCount,
        timeoutId: setInterval(() => {
          if (requestQueue.has(request.requestId)) {
            const expiredResponseLock = requestQueue.get(
              request.requestId
            ) as ResponseLock;
            if (expiredResponseLock) {
              if (expiredResponseLock.retryCount > 0) {
                PersistentClient.log(
                  LogLevel.warn,
                  'PersistentClient timeout retry',
                  request,
                  expiredResponseLock
                );
                --expiredResponseLock.retryCount;
                this.requestHandler(request);
              } else {
                if (expiredResponseLock.timeoutId) {
                  clearInterval(expiredResponseLock.timeoutId);
                  expiredResponseLock.timeoutId = null;
                }
                PersistentClient.log(
                  LogLevel.warn,
                  'PersistentClient timeout kill',
                  request,
                  expiredResponseLock
                );
                expiredResponseLock.reject(
                  'PersistentClient timeout overrun on ' +
                    request.method +
                    ' requestId ' +
                    request.requestId,
                  request.parameters
                );
                requestQueue.delete(request.requestId);
              }
            }
          } else {
            PersistentClient.log(
              LogLevel.warn,
              'PersistentClient timeout kill request not found',
              request.requestId
            );
          }
        }, timeout),
      } as ResponseLock;
      returnPromise = new Promise((resolve, reject) => {
        responseLock.resolve = resolve;
        responseLock.reject = reject;
      }).catch((error) => {
        PersistentClient.log(
          LogLevel.warn,
          'PersistentClient.proxycall rejected:',
          request,
          error
        );
      });

      requestQueue.set(request.requestId, responseLock);
      this.requestHandler(request);
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.proxycall ' + method + ' exception:',
        error
      );
    }
    return returnPromise;
  }
  // return a unique 16-bit number
  public static uniqueid(): number {
    return Math.round(Math.random() * 32767.0);
  }
  // Here is the API for the client:
  public async getTimestamp(): number {
    let result = 0;
    try {
      result = this.proxycall('getTimestamp', -1);
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.getTimestamp exception:',
        error
      );
    }
    return result;
  }
  public refreshBand() {
    this.proxycall('refreshBand', -1);
  }
  public async addImage(
    file: File,
    band: DynamicBand = null,
    pindex: number = 0
  ): CoreImage {
    let returnImage = null;
    try {
      PersistentClient.log(LogLevel.debug, 'PersistentClient.addImage ', {
        file,
      });
      let imageResolve = null;
      let imageReject = null;
      returnImage = new Promise<CoreImage>((resolve, reject) => {
        imageResolve = resolve;
        imageReject = reject;
      }).catch((error) => {
        PersistentClient.log(
          LogLevel.warn,
          'PersistentClient.addImage ' + file.name + ' rejected:',
          error
        );
      });
      if (band && pindex >= 0) {
        // pindex -1 means just add to the gallery
        band.partitionSetImgLoading(true, pindex);
      }
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        if (file.type === 'image/svg+xml') {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            imageResolve(
              this.proxycall(
                'addImage',
                1000 * 60 * 5,
                file.name,
                file.lastModified,
                file.size,
                file.type,
                canvas.toDataURL('image/webp', 1)
              )
            );
          };
          img.src = event.target.result;
        } else {
          imageResolve(
            this.proxycall(
              'addImage',
              1000 * 60 * 5,
              file.name,
              file.lastModified,
              file.size,
              file.type,
              event.target.result
            )
          );
        }
      };
      fileReader.onerror = (event) => {
        imageReject(
          'PersistentClient.addImage error reading ' +
            file.name +
            ' ' +
            event.target.error
        );
      };
      fileReader.onabort = () => {
        imageReject('PersistentClient.addImage aborted reading ' + file.name);
      };
      fileReader.readAsDataURL(file);
      if (band && pindex >= 0) {
        // pindex -1 means just add to the gallery
        const coreImage = new CoreImage(await returnImage);
        // const src = await coreImage.getLocalURL();
        PersistentClient.log(
          LogLevel.debug,
          'PersistentClient.addImage image id ' + coreImage.data.imageid
        );
        band.partitionSetImgLocalFilename(
          coreImage.data.local_filename,
          coreImage.calculateScale(),
          coreImage.data.imageid,
          coreImage.data.thumb_dataurl,
          pindex
        );
      }
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.addImage ' + file.name + ' exception:',
        error
      );
    }
    return returnImage;
  }
  public async addImageOperation(
    coreImageOperation: CoreImageOperation,
    clientId: number = -1
  ) {
    if (clientId === -1) {
      // exclude me from the update (I am already up to date)
      clientId = this.clientId;
    }
    this.proxycall('addImageOperation', -1, 0, coreImageOperation);
  }
  public setBandRotation(rotation: number = 0) {
    this.proxycall('setBandRotation', -1, this.clientId, rotation);
  }
  public setBandName(value: string = '') {
    this.proxycall('setBandName', -1, this.clientId, value);
  }
  public setBandSecret(value: string = '') {
    this.proxycall('setBandSecret', -1, this.clientId, value);
  }
  public setBandProductionStage(
    value: ProductionStage,
    productId: string = '',
    productUrl: string = ''
  ) {
    this.proxycall(
      'setBandProductionStage',
      -1,
      this.clientId,
      value,
      productId,
      productUrl
    );
  }
  public setUserEmail(value: string = '') {
    this.proxycall('setUserEmail', -1, this.clientId, value);
  }
  public setUserPhone(value: string = '') {
    this.proxycall('setUserPhone', -1, this.clientId, value);
  }
  public bandDropPartition(fromIndex: number, toIndex: number) {
    this.proxycall('bandDropPartition', -1, fromIndex, toIndex);
  }
  public bandLinkPartition(lindex: number) {
    this.proxycall('bandLinkPartition', -1, lindex);
  }
  public bandUnlinkPartition(lindex: number) {
    this.proxycall('bandUnlinkPartition', -1, lindex);
  }

  public async populateGallery(band: DynamicBand) {
    try {
      await this.workerAvailable;
      const images = await this.proxycall('fetchImages', -1);
      if (images) {
        const gallery = new Array<GalleryPhoto>({
          imageid: 0,
          title: 'none',
          local_filename: '',
          scale: 1,
          imgsrc: '',
        });
        for (const img of images) {
          const coreImage = new CoreImage(img);
          gallery.push({
            imageid: coreImage.data.imageid,
            title: coreImage.data.original_name,
            local_filename: coreImage.data.local_filename,
            scale: coreImage.calculateScale(),
            thumb_dataurl: coreImage.data.thumb_dataurl,
          });
        }
        band.updateGallery(gallery);
      } else {
        PersistentClient.log(
          LogLevel.warn,
          'PersistentClient.populateGallery fetchImages returned null'
        );
      }
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.populateGallery exception:',
        error
      );
    }
  }
  public async populateBand() {
    try {
      await this.workerAvailable;
      this.proxycall('updateBand', -1);
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.populateBand exception:',
        error
      );
    }
  }
  public fetchServer(
    table: string,
    params: object,
    method: string = 'GET',
    identifier: string = ''
  ): unknown[] {
    let result = null;
    try {
      result = this.proxycall(
        'fetchServer',
        -1,
        table,
        params,
        method,
        identifier
      );
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.fetchServer ' + table + ' exception:',
        error
      );
    }
    return result;
  }
  public fetchLocal(sql: string, bind: unknown[] = []): unknown[] {
    let result = null;
    try {
      result = this.proxycall('fetchLocal', -1, sql, bind);
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.fetchLocal ' + sql + ' exception:',
        error
      );
    }
    return result;
  }
  private getCookieValue(cname: string = 'customer'): string {
    // document.cookie = 'customer='+customer_number.toString()+'; domain=wearshare.com; path=/; expires='+(new Date(Date.now() + (30*24*60*60*1000))).toUTCString()+'; secure;';
    let value = '';
    for (const cookie of document.cookie.split(';')) {
      const trimmed = cookie.trim();
      const keylen = cname.length + 1;
      if (trimmed.substring(0, keylen) === cname + '=') {
        const end = trimmed.indexOf(';', keylen);
        value = trimmed
          .substring(keylen, end > -1 ? end : trimmed.length)
          .trim();
      }
    }
    return value;
  }
  public async findBands(
    local: boolean = true,
    where: object = null,
    orderby = ''
  ): CoreBand[] {
    return await this.proxycall('findBands', -1, local, where, orderby);
  }
  public async findImages(
    local: boolean = true,
    where: object = null,
    orderby = ''
  ): CoreImage[] {
    return await this.proxycall('findImages', -1, local, where, orderby);
  }
  public async findImageOperations(
    local: boolean = true,
    where: object = null,
    orderby = ''
  ): CoreImageOperation[] {
    return await this.proxycall(
      'findImageOperations',
      -1,
      local,
      where,
      orderby
    );
  }
  public async findUsers(
    local: boolean = true,
    where: object = null,
    orderby = ''
  ): CoreUser[] {
    return await this.proxycall('findUsers', -1, local, where, orderby);
  }
  public async resetLocalAll() {
    this.proxycall('resetLocalAll', -1);
  }
  public async resetServerAll() {
    this.proxycall('resetServerAll', -1);
  }
  public async getUser(): CoreUser {
    return new CoreUser(await this.proxycall('getUser', -1));
  }
  public async getBand(): CoreBand {
    return new CoreBand(await this.proxycall('getBand', -1));
  }
  public async getImageById(imageid: number): CoreImage {
    return new CoreImage(await this.proxycall('getImageById', -1, imageid));
  }
  public async getImgSrc(local_filename_with_ext: string): string {
    return this.proxycall('getImgSrc', -1, local_filename_with_ext);
  }
  public async getImgDataurl(local_filename_with_ext: string): string {
    return this.proxycall('getImgDataurl', -1, local_filename_with_ext);
  }
  public async getImgDataurlPng(local_filename_with_ext: string): string {
    return this.proxycall('getImgDataurlPng', -1, local_filename_with_ext);
  }
  public async getImgSrcById(imageid: number): string {
    return this.proxycall('getImgSrcById', -1, imageid);
  }
  public async getImgThumbSrcById(imageid: number): string {
    return this.proxycall('getImgThumbSrcById', -1, imageid);
  }
  public async convertSvg2PngDataurl(svgtext: string): string {
    return this.proxycall('convertSvg2PngDataurl', -1, svgtext);
  }
  public async generateBand(
    partitionProps: PartitionProps[],
    bleedWidth: number = 51,
    partitionDim: number = 1024,
    blocking: boolean = false
  ): CoreImage {
    let returnImage = null;
    try {
      const coreBand = await this.getBand();
      if (coreBand.data.imageid === 0) {
        const dataURL = await coreBand.generateArtworksOnClient(
          partitionProps,
          bleedWidth,
          partitionDim
        );
        if (dataURL && dataURL.length > 0) {
          returnImage = new CoreImage(
            await this.proxycall(
              'generateBand',
              1000 * 60 * 5,
              dataURL,
              bleedWidth,
              partitionDim,
              blocking
            )
          );
        } else {
          PersistentClient.log(
            LogLevel.error,
            'PersistentClient.generateBand failed to create image data'
          );
        }
      } else {
        returnImage = await this.getImageById(coreBand.data.imageid);
      }
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.generateBand exception:',
        error
      );
    }
    return returnImage;
  }
  /**
					 * create a product in shopify
					 * this function is called when we hit the "CREATE" button in the app
					 * We used to try to do this in the app, but vite bugs prevent this.  Here is a simple test
					 * for a later version of vite/vixni/wrangler:
					 * if (!isServer)
							{
							// this gives error:
							//  ERROR  [vite:worker] ../../node_modules/vinxi/runtime/http.js (71:9): "AsyncLocalStorage" is not exported by "__vite-browser-external", imported by "../../node_modules/vinxi/runtime/http.js".
							response = await createBandProduct(request);
							}
					 */
  public async createProductOnClient(
    band: DynamicBand,
    partitionProps: PartitionProps[]
  ): string {
    let uriProduct = '';
    const bleedWidth = 0; //51;
    const partitionDim = 1024;
    // we call this in blocking mode, because we need the server to have the image, and this page will soon exit
    const coreImage = await this.generateBand(
      partitionProps,
      bleedWidth,
      partitionDim,
      true
    );
    try {
      const coreBand = await this.getBand();
      const coreUser = await this.getUser();
      const imageGUID = coreImage.getServerID();
      await this.envInitialized;
      PersistentClient.log(
        LogLevel.debug,
        'PersistentClient.createProductOnClient',
        coreImage.local_filename,
        coreImage.server_filename,
        coreBand,
        coreUser
      );
      const request = {
        bandGUID: coreBand.data.band_guid,
        bandTitle: coreBand.data.name,
        bandDescription: coreBand.data.secret_message,
        userEmail: coreUser.data.email,
        userPhone: coreUser.data.phone,
        imageGUID: imageGUID,
        imageMimetype: coreImage.data.mimetype,
        imageFilename: coreImage.data.server_filename,
        sessionCookieId: PersistentClient.sessionCookieId,
        thumbDataurl: coreImage.getServerThumbURL(),
        identityGuid: coreBand.data.identity_guid,
        identityHash: coreBand.data.identity_hash,
        identityShort: coreBand.data.identity_short,
        productTemplate: PersistentClient.productTemplate,
      };
      const response = await this.proxycall(
        'createBandProduct',
        1000 * 60 * 5,
        request
      );
      if (response && response.success === true) {
        PersistentClient.log(
          LogLevel.debug,
          'PersistentClient.createProductOnClient [PurchaseModal] success',
          response
        );
        this.setProgress(100);
        this.setBandProductionStage(
          ProductionStage.DesignCompleted,
          response.fullProductId,
          response.productUrl
        );
        band.saveWorked(response.results[0].productUrl); // do not wait for the confetti to complete
      } else {
        PersistentClient.log(
          LogLevel.warn,
          'PersistentClient.createProductOnClient [PurchaseModal] Failed to initiate purchase',
          response
        );
      }
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.createProductOnClient exception:',
        error
      );
    }
    return uriProduct;
  }
  public async createProductOnWorker(
    band: DynamicBand,
    partitionProps: PartitionProps[]
  ): string {
    let uriProduct = '';
    try {
      const response = await this.proxycall(
        'createProductOnWorker',
        1000 * 60 * 5,
        partitionProps
      );
      if (response && response.success === true) {
        PersistentClient.log(
          LogLevel.debug,
          'PersistentClient.createProductOnWorker [PurchaseModal] success',
          response
        );
        this.setProgress(100);
        this.setBandProductionStage(
          ProductionStage.DesignCompleted,
          response.fullProductId,
          response.productUrl
        );
        band.saveWorked(response.results[0].productUrl); // do not wait for the confetti to complete
      } else {
        PersistentClient.log(
          LogLevel.warn,
          'PersistentClient.createProductOnWorker [PurchaseModal] Failed to initiate purchase',
          response
        );
      }
    } catch (error) {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.createProductOnWorker exception:',
        error
      );
    }
    return uriProduct;
  }
  public async convertSrcToDataurl(
    src: string,
    width: number,
    height: number,
    bgcolor: string = '#FBFF53',
    mimetype: string = 'image/png'
  ): string {
    return new Promise<string>((resolve, reject) => {
      try {
        const image = new Image();
        // image.crossOrigin="anonymous";
        const canvas = document.createElement('canvas');
        image.onload = () => {
          canvas.width = image.naturalWidth;
          canvas.height = image.naturalHeight;
          const context = canvas.getContext('2d');
          context.fillStyle = bgcolor;
          context.fillRect(0, 0, canvas.width, canvas.height);
          context.drawImage(image, 0, 0);
          resolve(canvas.toDataURL(mimetype));
        };
        image.src = src;
      } catch (error) {
        reject('PersistentClient.convertSrcToDataurl failed');
        PersistentClient.log(
          LogLevel.warn,
          'PersistentClient.convertSrcToDataurl exception:',
          error
        );
      }
    }).catch((error) => {
      PersistentClient.log(
        LogLevel.warn,
        'PersistentClient.convertSrcToDataurl reject:',
        error
      );
    });
  }
}
