// src/shared/schema.ts Automatically generated by "schema/createdb.pl" from "schema/wearshare.dia" 2025-04-01 12:35:59 +0100

export class wsUser {
  userid: number;
  name: string = '';
  email: string = '';
  phone: string = '';
  /** 0=user, 1=admin, 2=banned */
  role: number = 0;
  /** shopify customer number as a string */
  shopify_customer: string = '';
  /** URL for an image which represents this user - shopify ones can be very long */
  avatar: string = '';
  /** in milliseconds since the epoch */
  created_timestamp: number = 0;
  /** in milliseconds since the epoch */
  updated_timestamp: number = 0;
  /** in milliseconds since the epoch */
  server_written: number = 0;
  static table: string = 'wsUser';
  static columns: string[] = [
    'userid',
    'name',
    'email',
    'phone',
    'role',
    'shopify_customer',
    'avatar',
    'created_timestamp',
    'updated_timestamp',
    'server_written',
  ];
  static columnTypes: string[] = [
    'INT',
    'varchar',
    'varchar',
    'varchar',
    'smallint',
    'varchar',
    'varchar',
    'bigint',
    'bigint',
    'bigint',
  ];
  constructor(obj: object = null) {
    this.name = '';
    this.email = '';
    this.phone = '';
    this.role = 0;
    this.shopify_customer = '';
    this.avatar = '';
    this.created_timestamp = 0;
    this.updated_timestamp = 0;
    this.server_written = 0;
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj.data, column)) {
            this[column] = obj.data[column];
          }
        }
      } else {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj, column)) {
            this[column] = obj[column];
          }
        }
      }
    }
  }
  public static keys(): string[] {
    return wsUser.columns;
  }
  public values(): unknown[] {
    return [
      this.userid,
      this.name,
      this.email,
      this.phone,
      this.role,
      this.shopify_customer,
      this.avatar,
      this.created_timestamp,
      this.updated_timestamp,
      this.server_written,
    ];
  }
}

export class wsImage {
  imageid: number;
  original_name: string = '';
  original_size: number = 0;
  original_modified: number = 0;
  original_mimetype: string = 'image/jpeg';
  description: string = '';
  who_created: number = 0;
  enabled: number = 1;
  mimetype: string = 'image/webp';
  size: number = 0;
  width: number = 0;
  height: number = 0;
  /** type of the image 0=source (user uploaded), 1=completed_band(generated), ... */
  imagetype: number = 0;
  /** of the form <guid> - the local file is then <local_filename>.webp  guid is a UUID v4 e.g. 123e4567-e89b-12d3-a456-426614174000 */
  local_filename: string = '';
  /** base64 encoded dataurl of high compression thumbnail */
  thumb_dataurl: string = '';
  /** imagestore filename of the form <guid>.webp guid is a UUID v4 e.g. 123e4567-e89b-12d3-a456-426614174000 */
  server_filename: string = '';
  /** imagestore filename of the form <guid>.webp guid is a UUID v4 e.g. 123e4567-e89b-12d3-a456-426614174000 */
  server_thumb_filename: string = '';
  /** in milliseconds since the epoch */
  created_timestamp: number = 0;
  /** in milliseconds since the epoch */
  updated_timestamp: number = 0;
  /** in milliseconds since the epoch */
  server_written: number = 0;
  static table: string = 'wsImage';
  static columns: string[] = [
    'imageid',
    'original_name',
    'original_size',
    'original_modified',
    'original_mimetype',
    'description',
    'who_created',
    'enabled',
    'mimetype',
    'size',
    'width',
    'height',
    'imagetype',
    'local_filename',
    'thumb_dataurl',
    'server_filename',
    'server_thumb_filename',
    'created_timestamp',
    'updated_timestamp',
    'server_written',
  ];
  static columnTypes: string[] = [
    'INT',
    'varchar',
    'int',
    'bigint',
    'varchar',
    'varchar',
    'int',
    'smallint',
    'varchar',
    'int',
    'smallint',
    'smallint',
    'smallint',
    'varchar',
    'text',
    'varchar',
    'varchar',
    'bigint',
    'bigint',
    'bigint',
  ];
  constructor(obj: object = null) {
    this.original_name = '';
    this.original_size = 0;
    this.original_modified = 0;
    this.original_mimetype = 'image/jpeg';
    this.description = '';
    this.who_created = 0;
    this.enabled = 1;
    this.mimetype = 'image/webp';
    this.size = 0;
    this.width = 0;
    this.height = 0;
    this.imagetype = 0;
    this.local_filename = '';
    this.thumb_dataurl = '';
    this.server_filename = '';
    this.server_thumb_filename = '';
    this.created_timestamp = 0;
    this.updated_timestamp = 0;
    this.server_written = 0;
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj.data, column)) {
            this[column] = obj.data[column];
          }
        }
      } else {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj, column)) {
            this[column] = obj[column];
          }
        }
      }
    }
  }
  public static keys(): string[] {
    return wsImage.columns;
  }
  public values(): unknown[] {
    return [
      this.imageid,
      this.original_name,
      this.original_size,
      this.original_modified,
      this.original_mimetype,
      this.description,
      this.who_created,
      this.enabled,
      this.mimetype,
      this.size,
      this.width,
      this.height,
      this.imagetype,
      this.local_filename,
      this.thumb_dataurl,
      this.server_filename,
      this.server_thumb_filename,
      this.created_timestamp,
      this.updated_timestamp,
      this.server_written,
    ];
  }
}

export class wsImageOperation {
  who_created: number = 0;
  /** in milliseconds since the epoch */
  created_timestamp: number = 0;
  bandid: number;
  partitionid: number = 0;
  enabled: number = 1;
  layer: number = -1;
  /** 1 = upload, 2 = gallery, etc */
  operationtype: number = 0;
  imageid: number = 0;
  param1: number = 0.0;
  param2: number = 0.0;
  param3: number = 0.0;
  param4: number = 0.0;
  param5: number = 0.0;
  param6: number = 0.0;
  params: string = '';
  /** in milliseconds since the epoch */
  updated_timestamp: number = 0;
  /** in milliseconds since the epoch */
  server_written: number = 0;
  static table: string = 'wsImageOperation';
  static columns: string[] = [
    'who_created',
    'created_timestamp',
    'bandid',
    'partitionid',
    'enabled',
    'layer',
    'operationtype',
    'imageid',
    'param1',
    'param2',
    'param3',
    'param4',
    'param5',
    'param6',
    'params',
    'updated_timestamp',
    'server_written',
  ];
  static columnTypes: string[] = [
    'int',
    'bigint',
    'int',
    'int',
    'smallint',
    'smallint',
    'smallint',
    'int',
    'real',
    'real',
    'real',
    'real',
    'real',
    'real',
    'varchar',
    'bigint',
    'bigint',
  ];
  constructor(obj: object = null) {
    this.who_created = 0;
    this.created_timestamp = 0;
    this.partitionid = 0;
    this.enabled = 1;
    this.layer = -1;
    this.operationtype = 0;
    this.imageid = 0;
    this.param1 = 0.0;
    this.param2 = 0.0;
    this.param3 = 0.0;
    this.param4 = 0.0;
    this.param5 = 0.0;
    this.param6 = 0.0;
    this.params = '';
    this.updated_timestamp = 0;
    this.server_written = 0;
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj.data, column)) {
            this[column] = obj.data[column];
          }
        }
      } else {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj, column)) {
            this[column] = obj[column];
          }
        }
      }
    }
  }
  public static keys(): string[] {
    return wsImageOperation.columns;
  }
  public values(): unknown[] {
    return [
      this.who_created,
      this.created_timestamp,
      this.bandid,
      this.partitionid,
      this.enabled,
      this.layer,
      this.operationtype,
      this.imageid,
      this.param1,
      this.param2,
      this.param3,
      this.param4,
      this.param5,
      this.param6,
      this.params,
      this.updated_timestamp,
      this.server_written,
    ];
  }
}

export class wsBand {
  bandid: number;
  name: string = '';
  description: string = '';
  who_created: number = 0;
  who_updated: number = 0;
  enabled: number = 1;
  /** 0 = left-right, 90 = top-bottom, 180 = right-left, 270 = bottom-top */
  orientation: number = 0;
  partitions: string = '1-2-3-4-5-6-7';
  /** the rendered image created for production */
  imageid: number = 0;
  secret_message: string = '';
  is_private: number = 1;
  tags: string = '';
  categories: string = '';
  /** production stage 0=being designed, 1=completed, 2=order_complete, 3=production_complete, 4=production_cancelled */
  production_stage: number = 0;
  /** guid is a UUID v4 e.g. 123e4567-e89b-12d3-a456-426614174000 */
  band_guid: string = '';
  /** guid is a UUID v4 e.g. 123e4567-e89b-12d3-a456-426614174000 */
  identity_guid: string = '';
  /** SHA-256 digest of the identity_guid - usually 64 hex characters */
  identity_hash: string = '';
  /** first eight hex characters of the identity_hash - uppercase */
  identity_short: string = '';
  /** e.g. gid://shopify/Product/7432160477274 where 7432160477274 is the actual product id number */
  product_id: string = '';
  /** e.g. https://shop.wearshare.com/products/dyo-og7-e782c4dd where dyo-og7-e782c4dd is the designid */
  product_url: string = '';
  /** in milliseconds since the epoch */
  created_timestamp: number = 0;
  /** in milliseconds since the epoch */
  updated_timestamp: number = 0;
  /** in milliseconds since the epoch */
  server_written: number = 0;
  static table: string = 'wsBand';
  static columns: string[] = [
    'bandid',
    'name',
    'description',
    'who_created',
    'who_updated',
    'enabled',
    'orientation',
    'partitions',
    'imageid',
    'secret_message',
    'is_private',
    'tags',
    'categories',
    'production_stage',
    'band_guid',
    'identity_guid',
    'identity_hash',
    'identity_short',
    'product_id',
    'product_url',
    'created_timestamp',
    'updated_timestamp',
    'server_written',
  ];
  static columnTypes: string[] = [
    'INT',
    'varchar',
    'varchar',
    'int',
    'int',
    'smallint',
    'smallint',
    'varchar',
    'int',
    'varchar',
    'smallint',
    'varchar',
    'varchar',
    'smallint',
    'varchar',
    'varchar',
    'varchar',
    'varchar',
    'varchar',
    'varchar',
    'bigint',
    'bigint',
    'bigint',
  ];
  constructor(obj: object = null) {
    this.name = '';
    this.description = '';
    this.who_created = 0;
    this.who_updated = 0;
    this.enabled = 1;
    this.orientation = 0;
    this.partitions = '1-2-3-4-5-6-7';
    this.imageid = 0;
    this.secret_message = '';
    this.is_private = 1;
    this.tags = '';
    this.categories = '';
    this.production_stage = 0;
    this.band_guid = '';
    this.identity_guid = '';
    this.identity_hash = '';
    this.identity_short = '';
    this.product_id = '';
    this.product_url = '';
    this.created_timestamp = 0;
    this.updated_timestamp = 0;
    this.server_written = 0;
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj.data, column)) {
            this[column] = obj.data[column];
          }
        }
      } else {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj, column)) {
            this[column] = obj[column];
          }
        }
      }
    }
  }
  public static keys(): string[] {
    return wsBand.columns;
  }
  public values(): unknown[] {
    return [
      this.bandid,
      this.name,
      this.description,
      this.who_created,
      this.who_updated,
      this.enabled,
      this.orientation,
      this.partitions,
      this.imageid,
      this.secret_message,
      this.is_private,
      this.tags,
      this.categories,
      this.production_stage,
      this.band_guid,
      this.identity_guid,
      this.identity_hash,
      this.identity_short,
      this.product_id,
      this.product_url,
      this.created_timestamp,
      this.updated_timestamp,
      this.server_written,
    ];
  }
}

export class wsInfo {
  /** Always 1 - used to read this info record from the server REST API easily */
  infoid: number;
  /** Increment this when you change the schema */
  version_number: number = 2;
  /** the current user */
  userid: number = 0;
  /** the last time the user accessed the app */
  last_accessed: number = 0;
  static table: string = 'wsInfo';
  static columns: string[] = [
    'infoid',
    'version_number',
    'userid',
    'last_accessed',
  ];
  static columnTypes: string[] = ['INT', 'int', 'int', 'bigint'];
  constructor(obj: object = null) {
    this.version_number = 2;
    this.userid = 0;
    this.last_accessed = 0;
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj.data, column)) {
            this[column] = obj.data[column];
          }
        }
      } else {
        for (const column of Object.keys(this)) {
          if (Object.hasOwn(obj, column)) {
            this[column] = obj[column];
          }
        }
      }
    }
  }
  public static keys(): string[] {
    return wsInfo.columns;
  }
  public values(): unknown[] {
    return [this.infoid, this.version_number, this.userid, this.last_accessed];
  }
}

// *INDENT-OFF*
export const schema = `
drop table if exists wsUser;
drop table if exists wsImage;
drop table if exists wsImageOperation;
drop table if exists wsBand;
drop table if exists wsInfo;
create table wsUser (
   userid            INTEGER PRIMARY KEY AUTOINCREMENT ,
   name              varchar(256) default ''           ,
   email             varchar(256) default ''           ,
   phone             varchar(256) default ''           ,
   role              smallint unsigned default 0       ,
   shopify_customer  varchar(256) default ''           ,
   avatar            varchar(2048) default ''          ,
   created_timestamp bigint unsigned default 0         ,
   updated_timestamp bigint unsigned default 0         ,
   server_written    bigint unsigned default 0         
)   ;
create table wsImage (
   imageid               INTEGER PRIMARY KEY AUTOINCREMENT ,
   original_name         varchar(256) default ''           ,
   original_size         int unsigned default 0            ,
   original_modified     bigint unsigned default 0         ,
   original_mimetype     varchar(256) default 'image/jpeg' ,
   description           varchar(256) default ''           ,
   who_created           int unsigned default 0            ,
   enabled               smallint unsigned default 1       ,
   mimetype              varchar(256) default 'image/webp' ,
   size                  int unsigned default 0            ,
   width                 smallint unsigned default 0       ,
   height                smallint unsigned default 0       ,
   imagetype             smallint unsigned default 0       ,
   local_filename        varchar(64) default ''            ,
   thumb_dataurl         text not null default ''          ,
   server_filename       varchar(64) default ''            ,
   server_thumb_filename varchar(64) default ''            ,
   created_timestamp     bigint unsigned default 0         ,
   updated_timestamp     bigint unsigned default 0         ,
   server_written        bigint unsigned default 0         
)   ;
create table wsImageOperation (
   who_created       int unsigned default 0      ,
   created_timestamp bigint unsigned default 0   ,
   bandid            int unsigned default null   ,
   partitionid       int unsigned default 0      ,
   enabled           smallint unsigned default 1 ,
   layer             smallint signed default -1  ,
   operationtype     smallint unsigned default 0 ,
   imageid           int unsigned default 0      ,
   param1            real default 0.0            ,
   param2            real default 0.0            ,
   param3            real default 0.0            ,
   param4            real default 0.0            ,
   param5            real default 0.0            ,
   param6            real default 0.0            ,
   params            varchar(256) default ''     ,
   updated_timestamp bigint unsigned default 0   ,
   server_written    bigint unsigned default 0   
)   ;
create table wsBand (
   bandid            INTEGER PRIMARY KEY AUTOINCREMENT   ,
   name              varchar(256) default ''             ,
   description       varchar(256) default ''             ,
   who_created       int unsigned default 0              ,
   who_updated       int unsigned default 0              ,
   enabled           smallint unsigned default 1         ,
   orientation       smallint unsigned default 0         ,
   partitions        varchar(32) default '1-2-3-4-5-6-7' ,
   imageid           int unsigned default 0              ,
   secret_message    varchar(2048) default ''            ,
   is_private        smallint unsigned default 1         ,
   tags              varchar(256) default ''             ,
   categories        varchar(256) default ''             ,
   production_stage  smallint unsigned default 0         ,
   band_guid         varchar(64) default ''              ,
   identity_guid     varchar(64) default ''              ,
   identity_hash     varchar(128) default ''             ,
   identity_short    varchar(16) default ''              ,
   product_id        varchar(64) default ''              ,
   product_url       varchar(128) default ''             ,
   created_timestamp bigint unsigned default 0           ,
   updated_timestamp bigint unsigned default 0           ,
   server_written    bigint unsigned default 0           
)   ;
create table wsInfo (
   infoid         INTEGER PRIMARY KEY AUTOINCREMENT ,
   version_number int unsigned default 2            ,
   userid         int unsigned default 0            ,
   last_accessed  bigint unsigned default 0         
)   ;
create unique index wsImageLookup ON wsImage(original_name, original_size, original_modified, who_created, imagetype);
create unique index wsImageOperationPrimary ON wsImageOperation(who_created, created_timestamp);
insert into wsInfo (infoid, version_number, userid, last_accessed) values (1, 2, 0, 0);
insert into wsUser (userid, name, email, role, created_timestamp, updated_timestamp, server_written) values (0, '', 'unknown@wearshare.com', 2, 1, 1, 1);
insert into wsBand (bandid, name, description, who_created, enabled, production_stage, created_timestamp, updated_timestamp, server_written) values (0, '', '', 0, 0, 4, 1, 1, 1);
insert into wsImage (imageid, description, who_created, enabled, created_timestamp, updated_timestamp, server_written) values (0, '', 0, 0, 1, 1, 1);
insert into wsUser (userid, name, email, role, created_timestamp, updated_timestamp, server_written) values (65536, 'public', 'wspublic@scarletline.com', 0, 1, 1, 1);
insert into wsBand (bandid, name, description, who_created, enabled, production_stage, created_timestamp, updated_timestamp, server_written) values (65536, 'blank', '', 65536, 1, 4, 1, 1, 1);
insert into wsImage (imageid, original_modified, description, who_created, enabled, created_timestamp, updated_timestamp, server_written) values (65536, 1, 'blank image', 65536, 1, 1, 1, 1);
insert into wsUser (userid, name, email, role, created_timestamp, updated_timestamp, server_written) values (65537, 'admin', 'wearshare@scarletline.com', 1, 1, 1, 1);
insert into wsUser (userid, name, email, role, shopify_customer, created_timestamp, updated_timestamp, server_written) values (65538, 'Eric Anderson', 'eric.dr.anderson@gmail.com', 1, 7591651672154, 1, 1, 1);
insert into wsUser (userid, name, email, role, shopify_customer, created_timestamp, updated_timestamp, server_written) values (65539, 'Perry Birch', 'perrybirch@vizidrix.com', 1, 7631265103962, 1, 1, 1);
insert into wsUser (userid, name, email, role, shopify_customer, created_timestamp, updated_timestamp, server_written) values (65540, 'Kavan Bahrami', 'kavan@wearshare.com', 1, 7590930317402, 1, 1, 1);
insert into wsUser (userid, name, email, role, created_timestamp, updated_timestamp, server_written) values (65541, 'Marcelo Cantero', 'marcelo.c@sdsharp.com', 1, 1, 1, 1);
insert into wsUser (userid, name, email, role, shopify_customer, created_timestamp, updated_timestamp, server_written) values (65542, 'Lyndsay Lee', 'lyndsayanderson1@gmail.com', 1, 7590907838554, 1, 1, 1);
insert into wsUser (userid, name, email, role, shopify_customer, created_timestamp, updated_timestamp, server_written) values (65543, 'Kevin Shepherd', 'kshepherd@scarletline.com', 1, 7739507867738, 1, 1, 1);
`;
export const update = [
  /* 0 */ '',
  /* 1 */ '',
  /* 1 -> 2 */ `
alter table wsBand drop column local_filename;
alter table wsBand drop column server_filename;
alter table wsBand drop column server_thumb_filename;
alter table wsImage add column thumb_dataurl text not null default '';
alter table wsBand add column band_guid varchar(64) default '';
alter table wsBand add column identity_guid varchar(64) default '';
alter table wsBand add column identity_hash varchar(128) default '';
alter table wsBand add column identity_short varchar(16) default '';
alter table wsBand add column product_id varchar(64) default '';
alter table wsBand add column product_url varchar(128) default '';
update wsInfo set version_number = 2 where version_number = 1;

`,
];
// *INDENT-ON*
export const databaseVersion: number = 2;
