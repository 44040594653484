// CoreUser.ts implements all persistent User operations
import { wsUser } from './schema';
import { PersistentClient } from './PersistentClient';
import { PersistentWorker } from './PersistentWorker';
import { LogLevel } from '~/components/designer2/DynamicLog';

export enum UserRole {
  user = 0,
  admin,
  banned,
}

/**
 * Implements all User operations, such as finding, retrieving, storing to local and server database
 */
export class CoreUser {
  data: wsUser = new wsUser();
  // localId is set only while we are updating to a server issued id
  localId: number = 0;
  // in this database columns are sorted so that the members of the primary key come first
  static columnsStart: number = 1;
  static serverTable: string = 'user';

  constructor(obj: object = null) {
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        // json lost type data, regaining with new
        for (const column of CoreUser.columns()) {
          if (Object.hasOwn(obj.data, column)) {
            this.data[column] = obj.data[column];
          }
        }
      } else {
        // returned from the database, populate this object
        for (const column of CoreUser.columns()) {
          if (Object.hasOwn(obj, column)) {
            this.data[column] = obj[column];
          }
        }
      }
    }
  }
  public userIdChange(oldId: number, newId: number) {
    if (this.data.userid === oldId) {
      this.data.userid = newId;
    }
  }

  public static columns(): string[] {
    return wsUser.columns;
  }
  public values(): unknown[] {
    return this.data.values();
  }
  // Local Database calls
  private createLocalQuery(nextLocalId: number, timestamp: number): string {
    if (!this.data.userid || this.data.userid === 0) {
      this.data.userid = nextLocalId;
    }
    if (timestamp && timestamp > 0) {
      if (!this.data.created_timestamp || this.data.created_timestamp === 0) {
        this.data.created_timestamp = timestamp;
      }
      if (!this.data.updated_timestamp || this.data.updated_timestamp === 0) {
        this.data.updated_timestamp = timestamp;
      }
    }
    const sql =
      'INSERT INTO ' +
      wsUser.table +
      ' (' +
      CoreUser.columns().join(', ') +
      ') ' +
      'VALUES (' +
      Array(CoreUser.columns().length).fill('?').join(', ') +
      ')';
    return sql;
  }
  private static findLocalQuery(where: string = '', orderby = ''): string {
    const sql =
      'SELECT * FROM ' +
      wsUser.table +
      (where.length > 0 ? ' WHERE ' + where : '') +
      (orderby.length > 0 ? ' ORDER BY ' + orderby : '');
    return sql;
  }
  private readLocalQuery(): string {
    return CoreUser.findLocalQuery(
      CoreUser.columns().slice(0, CoreUser.columnsStart).join('=? AND ') + '=?'
    );
  }
  private updateLocalQuery(timestamp: number = 0): string {
    if (timestamp && timestamp > 0) {
      this.data.updated_timestamp = timestamp;
    }
    const sql =
      'UPDATE ' +
      wsUser.table +
      ' SET ' +
      CoreUser.columns().slice(CoreUser.columnsStart).join('=?, ') +
      '=? ' +
      'WHERE ' +
      CoreUser.columns().slice(0, CoreUser.columnsStart).join('=? AND ') +
      '=?';
    return sql;
  }
  private updateLocalWithIdQuery(timestamp: number = 0): string {
    if (timestamp && timestamp > 0) {
      this.data.updated_timestamp = timestamp;
    }
    const sql =
      'UPDATE ' +
      wsUser.table +
      ' SET ' +
      CoreUser.columns().join('=?, ') +
      '=? ' +
      'WHERE ' +
      CoreUser.columns().slice(0, CoreUser.columnsStart).join('=? AND ') +
      '=?';
    return sql;
  }
  private deleteLocalQuery(): string {
    const sql = 'DELETE FROM ' + wsUser.table + ' ' + 'WHERE userid=?';
    return sql;
  }
  // Server Database Calls
  private createServerQuery(timestamp: number): object {
    if (!this.data.created_timestamp || this.data.created_timestamp === 0) {
      this.data.created_timestamp = timestamp;
    }
    if (!this.data.updated_timestamp || this.data.updated_timestamp === 0) {
      this.data.updated_timestamp = timestamp;
    }
    this.data.server_written = timestamp;
    return Object.fromEntries(
      Object.entries(this.data).slice(CoreUser.columnsStart)
    );
  }
  private serverIdentity(): object {
    return { userid: this.data.userid };
  }
  private updateServerQuery(timestamp: number): object {
    this.data.server_written = timestamp;
    return Object.fromEntries(
      Object.entries(this.data).slice(CoreUser.columnsStart)
    );
  }
  // Convenience functions:
  public static async findServer(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreUser[] {
    const coreUsers = new Array<CoreUser>();
    try {
      const jsonFind = await worker.fetchServer(
        CoreUser.serverTable,
        where ? where : {},
        'GET'
      );
      if (jsonFind) {
        if (
          jsonFind.success &&
          jsonFind.results &&
          jsonFind.results.length > 0
        ) {
          // found
          for (const row of jsonFind.results) {
            coreUsers.push(new CoreUser(row));
          }
        } else {
          worker.log(
            LogLevel.debug,
            'CoreUser.findServer not found',
            where,
            orderby,
            jsonFind
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreUser.findServer failed', where, orderby);
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreUser.findServer exception:',
        error,
        where,
        orderby
      );
    }
    return coreUsers;
  }
  public static async findServerItem(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreUser {
    let coreUser = null;
    try {
      const jsonFind = await worker.fetchServer(
        CoreUser.serverTable,
        where ? where : {},
        'GET'
      );
      if (jsonFind) {
        if (
          jsonFind.success &&
          jsonFind.results &&
          jsonFind.results.length > 0
        ) {
          // found
          coreUser = new CoreUser(jsonFind.results[0]);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreUser.findServerItem not found',
            where,
            orderby,
            jsonFind
          );
        }
      } else {
        worker.log(
          LogLevel.warn,
          'CoreUser.findServerItem failed',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreUser.findServerItem exception:',
        error,
        where,
        orderby
      );
    }
    return coreUser;
  }
  public static async findByShopifyCustomerServer(
    worker: PersistentWorker,
    shopifyCustomer: string
  ): CoreUser {
    return CoreUser.findServerItem(worker, {
      shopify_customer: shopifyCustomer,
    });
  }
  public static async findByUserServer(
    worker: PersistentWorker,
    userid: number
  ): CoreUser {
    return CoreUser.findServerItem(worker, { userid: userid });
  }
  public static async findByEmailServer(
    worker: PersistentWorker,
    email: string
  ): CoreUser {
    return CoreUser.findServerItem(worker, { email: email });
  }
  public static async createServer(
    worker: PersistentWorker,
    coreUser: CoreUser
  ): CoreUser {
    try {
      const timestamp = PersistentWorker.getTimestamp();
      const jsonCreate = await worker.fetchServer(
        CoreUser.serverTable,
        coreUser.createServerQuery(timestamp),
        'POST'
      );
      if (jsonCreate) {
        if (jsonCreate.success) {
          // found
          let localId = 0;
          if (
            jsonCreate.meta &&
            jsonCreate.meta.last_row_id &&
            jsonCreate.meta.last_row_id > 0 &&
            coreUser.data.userid > 0 &&
            coreUser.data.userid < PersistentClient.minServerId
          ) {
            localId = coreUser.data.userid;
            coreUser.localId = localId;
            coreUser.data.userid = jsonCreate.meta.last_row_id;
          }
          // update server_written, and maybe also id
          coreUser.updateLocal(worker);
          // if id changed, trigger a local id update
          if (localId > 0) {
            worker.userIdChange(localId, coreUser.data.userid);
          }
        } else {
          worker.log(
            LogLevel.debug,
            'CoreUser.createServer not created',
            jsonCreate
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreUser.createServer failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreUser.createServer exception:', error);
    }
    return coreUser;
  }
  public async updateServer(worker: PersistentWorker): boolean {
    let worked = false;
    try {
      const timestamp = PersistentWorker.getTimestamp();
      const jsonCreate = await worker.fetchServer(
        CoreUser.serverTable,
        this.updateServerQuery(timestamp),
        'PATCH',
        this.data.userid
      );
      if (jsonCreate) {
        if (jsonCreate.success) {
          worked = true;
          // now store the new server_written
          this.updateLocal(worker);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreUser.updateServer not updated',
            jsonCreate
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreUser.updateServer failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreUser.updateServer exception:', error);
    }
    return worked;
  }
  public async deleteServer(worker: PersistentWorker): boolean {
    let worked = false;
    try {
      const jsonCreate = await worker.fetchServer(
        CoreUser.serverTable,
        {},
        'DELETE',
        this.data.userid
      );
      if (jsonCreate) {
        if (!jsonCreate.success) {
          worker.log(
            LogLevel.debug,
            'CoreUser.deleteServer not created',
            jsonCreate
          );
        } else {
          worked = true;
        }
      } else {
        worker.log(LogLevel.warn, 'CoreUser.deleteServer failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreUser.deleteServer exception:', error);
    }
    return worked;
  }
  public static findLocal(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreUser[] {
    const coreUsers = new Array<CoreUser>();
    try {
      let wherestr = '';
      let wherevals = [];
      if (where) {
        wherestr = Object.keys(where).join('=? AND ') + '=?';
        wherevals = Object.values(where);
      }
      const rows = worker.fetchLocal(
        CoreUser.findLocalQuery(wherestr),
        wherevals
      );
      if (rows.length > 0) {
        for (const row of rows) {
          coreUsers.push(new CoreUser(row));
        }
      } else {
        worker.log(
          LogLevel.debug,
          'CoreUser.findLocal not found',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreUser.findLocal exception:',
        error,
        where,
        orderby
      );
    }
    return coreUsers;
  }
  public static findLocalItem(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreUser {
    let coreUser = null;
    try {
      let wherestr = '';
      let wherevals = [];
      if (where) {
        wherestr = Object.keys(where).join('=? AND ') + '=?';
        wherevals = Object.values(where);
      }
      const rows = worker.fetchLocal(
        CoreUser.findLocalQuery(wherestr),
        wherevals
      );
      if (rows.length > 0) {
        coreUser = new CoreUser(rows[0]);
      } else {
        worker.log(
          LogLevel.debug,
          'CoreUser.findLocalItem not found',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreUser.findLocalItem exception:',
        error,
        where,
        orderby
      );
    }
    return coreUser;
  }
  public static findByShopifyCustomerLocal(
    worker: PersistentWorker,
    shopifyCustomer: number
  ): CoreUser {
    return CoreUser.findLocalItem(worker, {
      shopify_customer: shopifyCustomer,
    });
  }
  public static findByUserLocal(
    worker: PersistentWorker,
    userid: number
  ): CoreUser {
    return CoreUser.findLocalItem(worker, { userid: userid });
  }
  public static findByEmailLocal(
    worker: PersistentWorker,
    email: string
  ): CoreUser {
    return CoreUser.findLocalItem(worker, { email: email });
  }
  public static createLocal(
    worker: PersistentWorker,
    coreUser: CoreUser
  ): CoreUser {
    try {
      if (
        !worker.execLocal(
          coreUser.createLocalQuery(
            worker.nextLocalUserId++,
            PersistentWorker.getTimestamp()
          ),
          coreUser.values()
        )
      ) {
        worker.log(LogLevel.warn, 'CoreUser.createLocal failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreUser.createLocal exception:', error);
    }
    return coreUser;
  }
  public updateLocal(worker: PersistentWorker, timestamp: number = 0): boolean {
    let worked = false;
    try {
      const values = this.values();
      if (this.localId > 0) {
        // switch to server id
        values.push(this.localId);
        if (!worker.execLocal(this.updateLocalWithIdQuery(timestamp), values)) {
          worker.log(LogLevel.warn, 'CoreUser.updateLocal failed');
        } else {
          this.localId = 0;
          worked = true;
        }
      } else {
        values.push(values.shift());
        if (!worker.execLocal(this.updateLocalQuery(timestamp), values)) {
          worker.log(LogLevel.warn, 'CoreUser.updateLocal failed');
        }
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreUser.updateLocal exception:', error);
    }
    return worked;
  }
  public static deleteLocal(
    worker: PersistentWorker,
    coreUser: CoreUser
  ): boolean {
    let worked = false;
    try {
      if (
        !worker.execLocal(
          coreUser.deleteLocalQuery(),
          coreUser.values().slice(0, CoreUser.columnsStart)
        )
      ) {
        worker.log(LogLevel.warn, 'CoreBand.deleteLocal failed');
      } else {
        worked = true;
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreBand.deleteLocal exception:', error);
    }
    return worked;
  }
}
