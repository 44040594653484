export function OffscreenBandEndCenterDot(): string {
  const title = 'Band End Center Dot';
  const viewWidth = 1126;
  const viewHeight = 1024;
  // *INDENT-OFF*
  return `<svg
			width="1126"
			height="1024"
			viewBox="0 0 ${viewWidth} ${viewHeight}"
			xmlns="http://www.w3.org/2000/svg"
							>
			<title>${title}</title>
			<rect x="${Math.round((viewWidth - 32) * 0.5)}" y="${Math.round((viewHeight - 32) * 0.5)}" width="32" height="32" fill="#ED1E24" stroke-width="0" stroke="none"/>
		</svg>`;
  // *INDENT-ON*
}
export function OffscreenBandEndEyelet(): string {
  const title = 'Band End Eyelet';
  const viewWidth = 1126;
  const viewHeight = 1024;
  // *INDENT-OFF*
  return `<svg
			width="1126"
			height="1024"
			viewBox="0 0 ${viewWidth} ${viewHeight}"
			xmlns="http://www.w3.org/2000/svg"
							>
			<title>${title}</title>
			<circle cx="${Math.round(viewWidth * 0.5)}" cy="${Math.round(viewHeight * 0.5)}" r="179" fill="none" stroke-width="50" stroke="#000000"/>
		</svg>`;
  // *INDENT-ON*
}
export function OffscreenBandEndWearshare(rotation: number = 0): string {
  const title = 'Band End WEARSHARE';
  const viewWidth = 297.92083;
  const viewHeight = 270.93334;
  // *INDENT-OFF*
  return `<svg
			width="1126"
			height="1024"
			viewBox="0 0 ${viewWidth} ${viewHeight}"
			xmlns="http://www.w3.org/2000/svg"
							>
			<title>${title}</title>
			<g transform="rotate(${rotation} ${viewWidth * 0.5} ${viewHeight * 0.5})">
				<path style="fill:#000000;stroke-width:0.264583"
					d="m 152.67298,263.64316 c 
					0.26376,-0.16695 -0.0117,-0.28782 -0.92604,-0.40638 -0.7158,-0.0928 
					-1.5082,-0.28173 -1.76089,-0.41979 -0.25268,-0.13807 -0.69917,-0.25103 
					-0.99218,-0.25103 -0.29302,0 -0.53276,-0.11906 -0.53276,-0.26458 0,-0.14552 
					-0.24663,-0.26458 -0.54807,-0.26458 -0.51881,0 -1.30401,-0.55761 
					-1.30401,-0.92605 0,-0.0935 -0.17009,-0.34017 -0.37798,-0.54806 
					-0.20789,-0.20789 -0.45452,-0.37798 -0.54807,-0.37798 -0.36843,0 
					-0.92604,-0.7852 -0.92604,-1.30402 0,-0.30143 -0.11906,-0.54806 
					-0.26458,-0.54806 -0.14552,0 -0.26458,-0.23813 -0.26458,-0.52917 0,-0.29104 
					-0.1003,-0.52916 -0.22289,-0.52916 -0.12259,0 -0.24794,-0.77391 -0.27856,-1.7198 
					l -0.0557,-1.71979 h 2.87148 c 2.97569,0 3.50648,0.17108 3.50648,1.13015 
					0,0.27233 0.14883,0.66111 0.33073,0.86394 0.78516,0.87553 0.99219,1.17481 
					0.99219,1.43433 0,0.15166 0.27384,0.27575 0.60854,0.27575 0.33469,0 
					0.737,0.12846 0.89401,0.28547 0.15701,0.15701 1.23895,0.28547 2.40431,0.28547 
					2.12429,0 3.23689,-0.35454 3.23689,-1.03146 0,-0.18328 0.11906,-0.33323 
					0.26458,-0.33323 0.14699,0 0.26458,-0.70556 0.26458,-1.5875 0,-1.41111 
					-0.0588,-1.5875 -0.52916,-1.5875 -0.29105,0 -0.52917,-0.11906 -0.52917,-0.26458 
					0,-0.14553 -0.41672,-0.26459 -0.92604,-0.26459 -0.50933,0 -0.92604,-0.11906 
					-0.92604,-0.26458 0,-0.14552 -0.53579,-0.26458 -1.19063,-0.26458 -0.88194,0 
					-1.19062,-0.1029 -1.19062,-0.39688 0,-0.27718 -0.27929,-0.39687 
					-0.92605,-0.39687 -0.50932,0 -0.92604,-0.11907 -0.92604,-0.26459 0,-0.14552 
					-0.41672,-0.26458 -0.92604,-0.26458 -0.50932,0 -0.92604,-0.11906 
					-0.92604,-0.26458 0,-0.14552 -0.23813,-0.26459 -0.52917,-0.26459 -0.29104,0 
					-0.52916,-0.11906 -0.52916,-0.26458 0,-0.14552 -0.44649,-0.26641 
					-0.99219,-0.26863 -0.73803,-0.003 -0.89052,-0.0698 -0.59531,-0.26054 
					0.24469,-0.15813 0.27466,-0.25803 0.0782,-0.26053 -0.17529,-0.002 
					-0.61636,-0.30171 -0.98016,-0.66551 -0.36381,-0.3638 -0.78594,-0.66146 
					-0.93807,-0.66146 -0.15214,0 -0.27661,-0.17859 -0.27661,-0.39687 0,-0.21828 
					-0.11906,-0.39688 -0.26458,-0.39688 -0.14553,0 -0.26459,-0.23812 
					-0.26459,-0.52916 0,-0.29105 -0.11906,-0.52917 -0.26458,-0.52917 -0.16105,0 
					-0.26458,-1.19063 -0.26458,-3.04271 0,-1.85208 0.10353,-3.04271 0.26458,-3.04271 
					0.14552,0 0.26458,-0.23812 0.26458,-0.52916 0,-0.29105 0.11906,-0.52917 
					0.26459,-0.52917 0.14552,0 0.26458,-0.17859 0.26458,-0.39688 0,-0.21828 
					0.13082,-0.39687 0.29072,-0.39687 0.1599,0 0.62438,-0.35719 1.0322,-0.79375 
					0.40781,-0.43656 0.99136,-0.79375 1.29677,-0.79375 0.31722,0 0.55531,-0.17016 
					0.55531,-0.39688 0,-0.22048 0.23518,-0.39687 0.52917,-0.39687 0.29104,0 
					0.52916,-0.11906 0.52916,-0.26458 0,-0.14552 0.53578,-0.26459 1.19063,-0.26459 
					0.65484,0 1.19062,-0.11906 1.19062,-0.26458 0,-0.15679 0.97014,-0.26458 
					2.38125,-0.26458 1.41111,0 2.38125,0.10779 2.38125,0.26458 0,0.14699 
					0.70556,0.26458 1.5875,0.26458 0.88195,0 1.5875,0.11759 1.5875,0.26459 0,0.14552 
					0.23813,0.26458 0.52917,0.26458 0.29398,0 0.52917,0.17639 0.52917,0.39687 
					0,0.22672 0.23809,0.39688 0.5553,0.39688 0.30542,0 0.88897,0.35719 
					1.29678,0.79375 0.40781,0.43656 0.8723,0.79375 1.03219,0.79375 0.1599,0 
					0.29073,0.17859 0.29073,0.39687 0,0.21829 0.11906,0.39688 0.26458,0.39688 
					0.14552,0 0.26458,0.41672 0.26458,0.92604 0,0.50932 0.11906,0.92604 
					0.26459,0.92604 0.14552,0 0.26458,0.65485 0.26458,1.45521 v 1.45521 h -3.04271 c 
					-2.88294,0 -3.04271,-0.0283 -3.04271,-0.53799 0,-0.69682 -1.6756,-2.37243 
					-2.37243,-2.37243 -0.30182,0 -0.53798,-0.17422 -0.53798,-0.39687 0,-0.31273 
					-0.37063,-0.39688 -1.7481,-0.39688 -1.44517,0 -1.72171,0.0688 -1.59581,0.39688 
					0.11132,0.2901 -0.0329,0.39687 -0.53597,0.39687 -0.82328,0 -1.67637,0.89702 
					-1.67637,1.76269 0,0.71539 1.30664,2.20606 1.93372,2.20606 0.23168,0 
					0.51644,0.15399 0.63282,0.34219 0.11637,0.1882 0.74736,0.49826 1.40221,0.68901 
					0.65484,0.19075 1.46411,0.45349 1.79838,0.58386 0.33427,0.13036 0.95935,0.23703 
					1.38906,0.23703 0.42972,0 0.78131,0.11906 0.78131,0.26458 0,0.14552 
					0.41671,0.26458 0.92604,0.26458 0.64676,0 0.92604,0.11969 0.92604,0.39688 
					0,0.21828 0.23408,0.39687 0.52019,0.39687 0.63906,0 2.39023,0.81128 
					2.39023,1.10734 0,0.11857 0.17859,0.21558 0.39687,0.21558 0.21828,0 
					0.39688,0.15231 0.39688,0.33846 0,0.18616 0.23812,0.5101 0.52916,0.71987 
					0.31075,0.22397 0.52917,0.68493 0.52917,1.11675 0,0.40443 0.11906,0.73534 
					0.26458,0.73534 0.15875,0 0.26459,1.05833 0.26459,2.64583 0,1.5875 
					-0.10584,2.64583 -0.26459,2.64583 -0.14552,0 -0.26458,0.3626 -0.26458,0.80578 
					0,0.7935 -0.98148,2.10464 -1.57548,2.10464 -0.15213,0 -0.2766,0.12756 
					-0.2766,0.28348 0,0.42154 -0.69654,1.03944 -1.17173,1.03944 -0.22868,0 
					-0.41577,0.11906 -0.41577,0.26458 0,0.14552 -0.29766,0.26458 -0.66146,0.26458 
					-0.3638,0 -0.66146,0.11906 -0.66146,0.26459 0,0.14552 -0.52728,0.26458 
					-1.17173,0.26458 -0.68876,0 -1.33531,0.16359 -1.5686,0.39687 -0.29668,0.29668 
					-1.01951,0.39585 -2.86317,0.39283 -1.56138,-0.003 -2.32855,-0.0912 
					-2.09088,-0.24167 z M 31.515273,262.43367 c 0,-0.3638 0.119062,-0.66146 
					0.264583,-0.66146 0.145521,0 0.264583,-0.53578 0.264583,-1.19062 0,-0.81139 
					0.105344,-1.16956 0.33073,-1.12448 0.223337,0.0447 0.356698,-0.38491 
					0.410702,-1.32292 0.04398,-0.76398 0.192812,-1.38906 0.330729,-1.38906 
					0.137917,0 0.250756,-0.53578 0.250756,-1.19063 0,-0.65484 0.119063,-1.19062 
					0.264583,-1.19062 0.145521,0 0.264584,-0.41672 0.264584,-0.92604 0,-0.50933 
					0.119062,-0.92604 0.264583,-0.92604 0.145521,0 0.264583,-0.53579 
					0.264583,-1.19063 0,-0.81139 0.105344,-1.16956 0.33073,-1.12448 0.206822,0.0414 
					0.361494,-0.30557 0.412847,-0.92604 0.04516,-0.5457 0.193993,-0.99219 
					0.33073,-0.99219 0.136734,0 0.24861,-0.53578 0.24861,-1.19062 0,-0.65485 
					0.119063,-1.19063 0.264583,-1.19063 0.145521,0 0.264584,-0.41672 
					0.264584,-0.92604 0,-0.50932 0.119062,-0.92604 0.264583,-0.92604 0.145521,0 
					0.273984,-0.56555 0.285472,-1.25677 0.01148,-0.69123 0.08119,-1.1072 
					0.15489,-0.92439 0.253212,0.62806 0.617971,0.0322 0.617971,-1.00946 0,-0.57349 
					0.119063,-1.04271 0.264584,-1.04271 0.145521,0 0.264583,-0.41672 
					0.264583,-0.92605 0,-0.50932 0.119063,-0.92604 0.264583,-0.92604 0.145521,0 
					0.264584,-0.36569 0.264584,-0.81265 0,-1.2865 0.496213,-1.5686 2.759226,-1.5686 
					1.159126,0 2.003274,0.11149 2.003274,0.26459 0,0.14552 0.178594,0.26458 
					0.396875,0.26458 0.246944,0 0.396875,0.24988 0.396875,0.66146 0,0.3638 
					0.119062,0.66146 0.264583,0.66146 0.145521,0 0.264584,0.53578 0.264584,1.19062 
					0,0.65485 0.119062,1.19063 0.264583,1.19063 0.145521,0 0.264583,0.41672 
					0.264583,0.92604 0,0.50932 0.119063,0.92604 0.264584,0.92604 0.14552,0 
					0.264583,0.40398 0.264583,0.89773 0,0.55391 0.152,0.95606 0.396875,1.05003 
					0.30071,0.1154 0.396875,0.60308 0.396875,2.01269 0,1.47728 -0.08173,1.86038 
					-0.396875,1.86038 -0.29986,0 -0.396875,0.32338 -0.396875,1.32292 0,0.7276 
					-0.119063,1.32292 -0.264583,1.32292 -0.145521,0 -0.264584,0.52586 
					-0.264584,1.16857 0,0.64272 -0.113083,1.20827 -0.251296,1.25677 -0.138213,0.0485 
					-0.280553,0.59421 -0.316312,1.21268 -0.03576,0.61846 -0.1608,1.12448 
					-0.27787,1.12448 -0.117071,0 -0.212855,0.23812 -0.212855,0.52916 0,0.29398 
					-0.17639,0.52917 -0.396875,0.52917 -0.277183,0 -0.396875,-0.27928 
					-0.396875,-0.92604 0,-0.50933 -0.119063,-0.92604 -0.264583,-0.92604 -0.145521,0 
					-0.264584,-0.35719 -0.264584,-0.79375 0,-0.43657 -0.119062,-0.79375 
					-0.264583,-0.79375 -0.145521,0 -0.264583,-0.41672 -0.264583,-0.92605 0,-0.50932 
					-0.119063,-0.92604 -0.264584,-0.92604 -0.145521,0 -0.264583,-0.29765 
					-0.264583,-0.66146 0,-0.41157 -0.149932,-0.66145 -0.396875,-0.66145 -0.218281,0 
					-0.396875,0.23252 -0.396875,0.51671 0,0.2842 -0.110247,0.79021 -0.244994,1.12448 
					-0.134744,0.33427 -0.454935,1.3519 -0.71153,2.26141 -0.256596,0.90951 
					-0.563068,1.65365 -0.681049,1.65365 -0.11798,0 -0.214511,0.35718 
					-0.214511,0.79375 0,0.43656 -0.119062,0.79375 -0.264583,0.79375 -0.145521,0 
					-0.264583,0.41671 -0.264583,0.92604 0,0.50932 -0.119063,0.92604 
					-0.264584,0.92604 -0.14552,0 -0.264583,0.40969 -0.264583,0.91042 0,0.50074 
					-0.119062,0.98402 -0.264583,1.07395 -0.145521,0.0899 -0.266639,0.48392 
					-0.269156,0.87552 -0.0026,0.40114 -0.278762,0.97189 -0.632602,1.3073 
					-0.55684,0.52782 -0.901245,0.59531 -3.038139,0.59531 -2.394387,0 
					-2.410103,-0.004 -2.410103,-0.66146 z m 14.750521,0.47907 c -0.615061,-0.16073 
					-0.67637,-2.19886 -0.06615,-2.19886 0.294743,0 0.396875,-0.31042 
					0.396875,-1.20624 0,-0.73679 0.102973,-1.1426 0.264583,-1.04272 0.15979,0.0988 
					0.264584,-0.26184 0.264584,-0.91043 0,-0.59067 0.119062,-1.07394 
					0.264583,-1.07394 0.145521,0 0.264583,-0.53579 0.264583,-1.19063 0,-0.65484 
					0.119063,-1.19062 0.264584,-1.19062 0.145521,0 0.264583,-0.53579 
					0.264583,-1.19063 0,-0.88194 0.102894,-1.19062 0.396875,-1.19062 0.286631,0 
					0.396875,-0.29399 0.396875,-1.05834 0,-0.58208 0.119063,-1.05833 
					0.264583,-1.05833 0.145521,0 0.264584,-0.47625 0.264584,-1.05833 0,-0.58209 
					0.119062,-1.05834 0.264583,-1.05834 0.145521,0 0.264583,-0.53578 
					0.264583,-1.19062 0,-0.88195 0.102894,-1.19063 0.396875,-1.19063 0.285642,0 
					0.396875,-0.29224 0.396875,-1.04271 0,-0.5735 0.119063,-1.11631 
					0.264584,-1.20625 0.145521,-0.0899 0.264583,-0.51368 0.264583,-0.94165 
					0,-0.42798 0.119063,-0.77814 0.264583,-0.77814 0.145521,0 0.264584,-0.59531 
					0.264584,-1.32291 0,-0.72761 0.103386,-1.32292 0.229745,-1.32292 0.12636,0 
					0.293188,-0.47299 0.370727,-1.05108 0.227335,-1.69491 0.558625,-1.85934 
					3.746254,-1.85934 h 2.797024 v 1.45521 c 0,0.80037 -0.119063,1.45521 
					-0.264583,1.45521 -0.145521,0 -0.264584,0.41672 -0.264584,0.92604 0,0.50932 
					-0.119062,0.92604 -0.264583,0.92604 -0.145521,0 -0.266406,0.56555 
					-0.268634,1.25677 -0.0026,0.78797 -0.0875,1.12513 -0.227764,0.90386 
					-0.287073,-0.45287 -0.561935,0.1833 -0.561935,1.3006 0,0.42466 -0.119063,0.77211 
					-0.264584,0.77211 -0.145521,0 -0.264583,0.41671 -0.264583,0.92604 0,0.50932 
					-0.119063,0.92604 -0.264583,0.92604 -0.145521,0 -0.264584,0.53578 
					-0.264584,1.19062 0,0.65485 -0.119062,1.19063 -0.264583,1.19063 -0.145521,0 
					-0.264583,0.41672 -0.264583,0.92604 0,0.64676 -0.119693,0.92604 
					-0.396875,0.92604 -0.277183,0 -0.396875,0.27929 -0.396875,0.92604 0,0.50933 
					-0.119063,0.92605 -0.264584,0.92605 -0.145521,0 -0.264583,0.53578 
					-0.264583,1.19062 0,0.65484 -0.119063,1.19063 -0.264584,1.19063 -0.14552,0 
					-0.264583,0.35718 -0.264583,0.79375 0,0.43656 -0.119062,0.79375 
					-0.264583,0.79375 -0.145521,0 -0.264584,0.41671 -0.264584,0.92604 0,0.64676 
					-0.119692,0.92604 -0.396875,0.92604 -0.277182,0 -0.396875,0.27928 
					-0.396875,0.92604 0,0.50932 -0.119062,0.92604 -0.264583,0.92604 -0.145521,0 
					-0.264583,0.48476 -0.264583,1.07723 0,1.60327 -0.385734,1.83835 -2.957664,1.8025 
					-1.210942,-0.0169 -2.410071,-0.0851 -2.664732,-0.1517 z m 17.424662,-0.23613 c 
					-0.209775,-0.54666 -0.209775,-26.13297 0,-26.67963 0.142484,-0.37131 
					1.276602,-0.41852 10.054167,-0.41852 h 9.893567 v 2.6242 c 0,1.4433 
					-0.07227,2.81252 -0.160599,3.0427 -0.14061,0.36643 -0.996786,0.41852 
					-6.879167,0.41852 h -6.718567 v 7.7769 c 0,6.84744 0.05002,7.7961 
					0.418515,7.9375 0.230182,0.0883 3.432133,0.1606 7.115443,0.1606 5.862645,0 
					6.716934,0.0521 6.857526,0.41851 0.208508,0.54337 0.208508,4.17586 0,4.71922 
					-0.142576,0.37155 -1.297427,0.41852 -10.290442,0.41852 -8.993016,0 
					-10.147867,-0.047 -10.290443,-0.41852 z m 23.916485,-0.24294 c 0,-0.3638 
					0.119062,-0.66146 0.264583,-0.66146 0.145521,0 0.264583,-0.41672 
					0.264583,-0.92604 0,-0.50932 0.119063,-0.92604 0.264584,-0.92604 0.14552,0 
					0.264583,-0.35719 0.264583,-0.79375 0,-0.52917 0.132292,-0.79375 
					0.396875,-0.79375 0.277183,0 0.396875,-0.27928 0.396875,-0.92604 0,-0.50933 
					0.119062,-0.92604 0.264583,-0.92604 0.145521,0 0.264584,-0.29766 
					0.264584,-0.66146 0,-0.3638 0.119062,-0.66146 0.264583,-0.66146 0.145521,0 
					0.264583,-0.35719 0.264583,-0.79375 0,-0.43656 0.119063,-0.79375 
					0.264584,-0.79375 0.14552,0 0.264583,-0.41672 0.264583,-0.92604 0,-0.64676 
					0.119692,-0.92604 0.396875,-0.92604 0.264583,0 0.396875,-0.26459 
					0.396875,-0.79375 0,-0.43657 0.119063,-0.79375 0.264583,-0.79375 0.145521,0 
					0.264584,-0.29766 0.264584,-0.66146 0,-0.3638 0.119062,-0.66146 
					0.264583,-0.66146 0.145521,0 0.264583,-0.41672 0.264583,-0.92604 0,-0.50933 
					0.119063,-0.92604 0.264584,-0.92604 0.145521,0 0.264583,-0.41672 
					0.264583,-0.92605 0,-0.59972 0.116544,-0.90273 0.330729,-0.85989 0.198313,0.0397 
					0.364075,-0.28015 0.414012,-0.79877 0.04581,-0.47571 0.194636,-0.79611 
					0.330729,-0.71199 0.136097,0.0841 0.247447,-0.21129 0.247447,-0.65644 0,-0.44515 
					0.119062,-0.80936 0.264583,-0.80936 0.145521,0 0.264583,-0.41672 
					0.264583,-0.92605 0,-0.50932 0.119063,-0.92604 0.264584,-0.92604 0.145521,0 
					0.272952,-0.44648 0.283181,-0.99218 0.01452,-0.77554 0.0654,-0.87665 
					0.232949,-0.46303 0.18129,0.44755 0.244182,0.37613 0.407723,-0.46302 
					0.106355,-0.5457 0.299183,-0.99218 0.428509,-0.99218 0.129326,0 
					0.235138,-0.24663 0.235138,-0.54807 0,-1.09339 0.57694,-1.30402 
					3.571879,-1.30402 3.13489,0 3.57187,0.19191 3.57187,1.5686 0,0.44696 
					0.11906,0.81265 0.26458,0.81265 0.14553,0 0.26459,0.29766 0.26459,0.66146 
					0,0.41158 0.14993,0.66146 0.39687,0.66146 0.27719,0 0.39688,0.27928 
					0.39688,0.92604 0,0.50932 0.11906,0.92604 0.26458,0.92604 0.14552,0 
					0.26458,0.35719 0.26458,0.79375 0,0.43657 0.11907,0.79375 0.26459,0.79375 
					0.14552,0 0.26458,0.29766 0.26458,0.66146 0,0.41158 0.14993,0.66146 
					0.39688,0.66146 0.27718,0 0.39687,0.27928 0.39687,0.92604 0,0.50932 
					0.11906,0.92604 0.26458,0.92604 0.14553,0 0.26459,0.35719 0.26459,0.79375 
					0,0.43657 0.11906,0.79375 0.26458,0.79375 0.14552,0 0.26458,0.35719 
					0.26458,0.79375 0,0.43657 0.11907,0.79375 0.26459,0.79375 0.14552,0 
					0.26458,0.35719 0.26458,0.79375 0,0.52917 0.13229,0.79375 0.39688,0.79375 
					0.27718,0 0.39687,0.27929 0.39687,0.92604 0,0.50933 0.11906,0.92605 
					0.26458,0.92605 0.14553,0 0.26459,0.36421 0.26459,0.80936 0,0.4722 
					0.11022,0.74125 0.26458,0.64584 0.15746,-0.0973 0.26458,0.21774 0.26458,0.77814 
					0,0.51791 0.11907,0.94166 0.26459,0.94166 0.14552,0 0.26458,0.35718 
					0.26458,0.79375 0,0.52916 0.13229,0.79375 0.39688,0.79375 0.27718,0 
					0.39687,0.27928 0.39687,0.92604 0,0.50932 0.11906,0.92604 0.26458,0.92604 
					0.14553,0 0.26459,0.29765 0.26459,0.66146 0,0.3638 0.11906,0.66146 
					0.26458,0.66146 0.14552,0 0.26458,0.59531 0.26458,1.32291 v 1.32292 h -2.4919 c 
					-2.71387,0 -3.32893,-0.12744 -3.32893,-0.68977 0,-0.20271 -0.17859,-0.36856 
					-0.39687,-0.36856 -0.27719,0 -0.39688,-0.27929 -0.39688,-0.92605 0,-0.50932 
					-0.11906,-0.92604 -0.26458,-0.92604 -0.14552,0 -0.26459,-0.23812 
					-0.26459,-0.52916 0,-0.29105 -0.11906,-0.52917 -0.26458,-0.52917 -0.14552,0 
					-0.26458,-0.17859 -0.26458,-0.39688 0,-0.33161 -0.54387,-0.39687 
					-3.30729,-0.39687 h -3.307296 v -0.79375 c 0,-0.43656 0.119063,-0.79375 
					0.264584,-0.79375 0.14552,0 0.264583,-0.35719 0.264583,-0.79375 0,-0.43656 
					0.119062,-0.79375 0.264583,-0.79375 0.145521,0 0.264586,-0.35719 
					0.264586,-0.79375 0,-0.52917 0.13229,-0.79375 0.39687,-0.79375 0.21828,0 
					0.39688,-0.11906 0.39688,-0.26458 0,-0.14553 0.65484,-0.26459 1.45521,-0.26459 
					1.37583,0 1.4552,-0.0361 1.4552,-0.66146 0,-0.3638 -0.11906,-0.66145 
					-0.26458,-0.66145 -0.14552,0 -0.26458,-0.35719 -0.26458,-0.79375 0,-0.52917 
					-0.13229,-0.79375 -0.39688,-0.79375 -0.27718,0 -0.39687,-0.27929 
					-0.39687,-0.92605 0,-0.50932 -0.11906,-0.92604 -0.26459,-0.92604 -0.14552,0 
					-0.26458,-0.41672 -0.26458,-0.92604 0,-0.50932 -0.11906,-0.92604 
					-0.26458,-0.92604 -0.14552,0 -0.26459,-0.36569 -0.26459,-0.81265 0,-0.81068 
					-0.50223,-1.5686 -1.03943,-1.5686 -0.15592,0 -0.28348,0.41672 -0.28348,0.92604 
					0,0.50932 -0.119065,0.92604 -0.264586,0.92604 -0.145521,0 -0.264583,0.35719 
					-0.264583,0.79375 0,0.43657 -0.10555,0.79375 -0.234553,0.79375 -0.129006,0 
					-0.297799,0.32742 -0.375097,0.72761 -0.328703,1.70171 -0.703803,2.84111 
					-0.963854,2.9278 -0.153217,0.0511 -0.27858,0.31546 -0.27858,0.58752 0,0.27207 
					-0.109376,0.76817 -0.243059,1.10244 -0.664361,1.66122 -0.739005,1.90678 
					-0.782201,2.57323 -0.0182,0.28065 -0.211667,0.68886 -0.429948,0.90715 
					-0.218281,0.21828 -0.396875,0.6435 -0.396875,0.94494 0,0.30143 -0.119062,0.54806 
					-0.264583,0.54806 -0.145521,0 -0.264584,0.41672 -0.264584,0.92604 0,0.50933 
					-0.100046,0.92605 -0.222326,0.92605 -0.122278,0 -0.379418,0.65484 
					-0.571424,1.4552 -0.207848,0.86642 -0.49258,1.45521 -0.703715,1.45521 -0.19504,0 
					-0.354618,0.11906 -0.354618,0.26459 0,0.16035 -1.146527,0.26458 
					-2.910417,0.26458 h -2.910416 z m 30.058519,0.24294 c -0.20978,-0.54666 
					-0.20978,-26.13297 0,-26.67963 0.14113,-0.36779 1.05508,-0.41852 
					7.54062,-0.41852 4.74363,0 7.38003,0.0945 7.38003,0.26459 0,0.14552 
					0.53578,0.26458 1.19062,0.26458 0.88195,0 1.19063,0.10289 1.19063,0.39687 
					0,0.22049 0.23518,0.39688 0.52916,0.39688 0.29105,0 0.52917,0.11906 
					0.52917,0.26458 0,0.14552 0.12447,0.26459 0.27661,0.26459 0.35916,0 
					1.57548,1.21632 1.57548,1.57547 0,0.15214 0.11906,0.27661 0.26458,0.27661 
					0.14552,0 0.26458,0.23812 0.26458,0.52917 0,0.29398 0.17639,0.52916 
					0.39688,0.52916 0.24694,0 0.39687,0.24989 0.39687,0.66146 0,0.3638 
					0.11906,0.66146 0.26459,0.66146 0.16035,0 0.26458,1.14653 0.26458,2.91042 
					0,1.76389 -0.10423,2.91041 -0.26458,2.91041 -0.14553,0 -0.26459,0.29766 
					-0.26459,0.66146 0,0.3638 -0.11104,0.66146 -0.24675,0.66146 -0.13572,0 
					-0.33002,0.26234 -0.43178,0.58297 -0.10177,0.32064 -0.31815,0.72277 
					-0.48085,0.89362 -0.16271,0.17086 -0.47094,0.56337 -0.68497,0.87224 
					-0.21402,0.30887 -0.60098,0.56159 -0.85989,0.56159 -0.25892,0 -0.47076,0.12756 
					-0.47076,0.28348 0,0.42154 -0.69654,1.03943 -1.17172,1.03943 -0.23939,0 
					-0.41578,0.2245 -0.41578,0.52917 0,0.29104 0.09,0.52917 0.2,0.52917 0.11,0 
					0.35249,0.34451 0.53888,0.76559 0.18639,0.42108 0.60241,1.05818 0.92448,1.41577 
					0.70379,0.78139 1.48936,2.01115 1.6911,2.64728 0.0808,0.25466 0.25202,0.46302 
					0.38057,0.46302 0.12854,0 0.23372,0.14504 0.23372,0.32231 0,0.17727 
					0.26789,0.56551 0.59531,0.86276 0.57495,0.52196 1.38173,1.73468 1.20896,1.81726 
					-0.0465,0.0222 -0.35075,0.18921 -0.6762,0.37111 -0.36139,0.20199 -1.6036,0.33073 
					-3.19125,0.33073 -2.23382,0 -2.67945,-0.0744 -3.16753,-0.52917 -0.3124,-0.29104 
					-0.69204,-0.52916 -0.84364,-0.52916 -0.15161,0 -0.27565,-0.21568 
					-0.27565,-0.47928 0,-0.2636 -0.21499,-0.71009 -0.47776,-0.99219 -0.26277,-0.2821 
					-0.64903,-0.89986 -0.85834,-1.37281 -0.20932,-0.47294 -0.51566,-0.85989 
					-0.68076,-0.85989 -0.1651,0 -0.4567,-0.38695 -0.648,-0.8599 -0.19129,-0.47294 
					-0.42725,-0.91942 -0.52434,-0.99218 -0.0971,-0.0728 -0.37175,-0.51334 
					-0.61034,-0.97905 -0.32474,-0.63389 -0.43379,-1.58171 -0.43379,-3.77031 v 
					-2.92356 h 1.04588 c 1.14002,0 2.40328,-0.45528 2.73758,-0.98662 
					0.11637,-0.18496 0.38152,-0.3363 0.58921,-0.3363 0.2077,0 0.56966,-0.29307 
					0.80436,-0.65126 0.47762,-0.72895 0.61154,-3.00688 0.19257,-3.27574 
					-0.14916,-0.0957 -0.3548,-0.44193 -0.45698,-0.76935 -0.10857,-0.34792 
					-0.39828,-0.59531 -0.69712,-0.59531 -0.28123,0 -0.51134,-0.12392 
					-0.51134,-0.27538 0,-0.18013 -1.37272,-0.26304 -3.96875,-0.2397 l 
					-3.96875,0.0357 v 10.29386 c 0,7.1437 0.0868,10.29387 0.28349,10.29387 0.15591,0 
					0.10489,0.17859 -0.1134,0.39687 -0.59358,0.59359 -5.866,0.57487 -6.0949,-0.0216 
					z m 53.54982,-13.33981 v -13.75834 h 2.91041 2.91042 v 13.75834 13.75833 h 
					-2.91042 -2.91041 z m 16.04014,13.35165 c -0.0908,-0.23669 -0.16514,-2.62327 
					-0.16514,-5.3035 v -4.87315 h -3.175 -3.175 v -3.04271 -3.04271 h 3.175 3.175 v 
					-5.00544 c 0,-2.753 0.0723,-5.19378 0.1606,-5.42396 0.13219,-0.34449 
					0.64701,-0.41852 2.91041,-0.41852 2.02131,0 2.75089,0.0876 2.75387,0.33073 
					0.003,0.22049 0.0896,0.19844 0.26053,-0.0661 0.16741,-0.25905 0.25789,4.39385 
					0.26054,13.39777 l 0.004,13.79465 -3.00985,0.0417 c -2.56022,0.0354 
					-3.03453,-0.0226 -3.175,-0.38869 z m 10.27193,0.16469 c 0.0823,-0.13309 
					-1e-5,-0.30228 -0.18282,-0.37598 -0.26414,-0.10649 -0.26448,-0.13829 
					-0.002,-0.15489 0.19761,-0.0125 0.33073,-0.39363 0.33073,-0.94693 0,-0.59973 
					0.11654,-0.90273 0.33073,-0.8599 0.18601,0.0372 0.36736,-0.25223 
					0.41445,-0.66146 0.046,-0.40018 0.19487,-0.7276 0.33073,-0.7276 0.13585,0 
					0.24701,-0.35016 0.24701,-0.77814 0,-0.42797 0.11906,-0.85172 0.26458,-0.94165 
					0.14552,-0.0899 0.26458,-0.51369 0.26458,-0.94166 0,-0.4814 0.12613,-0.75291 
					0.33073,-0.71199 0.19799,0.0396 0.36401,-0.27898 0.41364,-0.79375 
					0.0456,-0.47294 0.19443,-0.85989 0.33073,-0.85989 0.1363,0 0.24782,-0.35719 
					0.24782,-0.79375 0,-0.43657 0.11906,-0.79375 0.26458,-0.79375 0.14552,0 
					0.26459,-0.35016 0.26459,-0.77814 0,-0.42797 0.11906,-0.85172 0.26458,-0.94166 
					0.14552,-0.0899 0.26458,-0.45415 0.26458,-0.80936 0,-0.39769 0.15249,-0.64584 
					0.39688,-0.64584 0.27718,0 0.39687,-0.27929 0.39687,-0.92605 0,-0.50932 
					0.11907,-0.92604 0.26459,-0.92604 0.14552,0 0.26458,-0.35718 0.26458,-0.79375 
					0,-0.43656 0.11906,-0.79375 0.26458,-0.79375 0.14552,0 0.26459,-0.29765 
					0.26459,-0.66146 0,-0.3638 0.11906,-0.66145 0.26458,-0.66145 0.14552,0 
					0.26458,-0.41672 0.26458,-0.92605 0,-0.64675 0.1197,-0.92604 0.39688,-0.92604 
					0.26458,0 0.39687,-0.26458 0.39687,-0.79375 0,-0.43656 0.11907,-0.79375 
					0.26459,-0.79375 0.14552,0 0.26458,-0.29765 0.26458,-0.66146 0,-0.3638 
					0.11906,-0.66145 0.26458,-0.66145 0.14552,0 0.26459,-0.41672 0.26459,-0.92605 
					0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 0.14552,0 0.26458,-0.41672 
					0.26458,-0.92604 0,-0.64676 0.1197,-0.92604 0.39688,-0.92604 0.21828,0 
					0.39687,-0.11906 0.39687,-0.26458 0,-0.16036 1.14653,-0.26459 2.91042,-0.26459 
					1.76389,0 2.91042,0.10423 2.91042,0.26459 0,0.14552 0.16201,0.26458 
					0.36003,0.26458 0.32435,0 1.49205,3.06009 1.49205,3.91007 0,0.1778 
					0.11906,0.32326 0.26458,0.32326 0.14553,0 0.26459,0.41672 0.26459,0.92604 
					0,0.64676 0.11969,0.92605 0.39687,0.92605 0.21828,0 0.39688,0.18047 
					0.39688,0.40104 0,0.64155 0.63877,2.50937 0.85817,2.50937 0.11009,0 
					0.20016,0.41672 0.20016,0.92604 0,0.64676 0.11969,0.92605 0.39688,0.92605 
					0.26281,0 0.39687,0.26284 0.39687,0.77813 0,0.42797 0.11906,0.85172 
					0.26458,0.94166 0.14553,0.0899 0.26459,0.44712 0.26459,0.79375 0,0.34662 
					0.11906,0.70381 0.26458,0.79375 0.14552,0.0899 0.26458,0.45415 0.26458,0.80936 
					0,0.35522 0.11907,0.64585 0.26459,0.64585 0.14552,0 0.26458,0.34445 
					0.26458,0.76544 0,0.43632 0.17065,0.83092 0.39688,0.91774 0.21828,0.0838 
					0.39687,0.44666 0.39687,0.80644 0,0.35978 0.11906,0.72773 0.26458,0.81767 
					0.14553,0.0899 0.26459,0.45415 0.26459,0.80936 0,0.35522 0.11906,0.64585 
					0.26458,0.64585 0.14552,0 0.26458,0.41671 0.26458,0.92604 0,0.50932 
					0.11907,0.92604 0.26459,0.92604 0.14552,0 0.26458,0.35719 0.26458,0.79375 
					0,0.52917 0.13229,0.79375 0.39688,0.79375 0.27718,0 0.39687,0.27928 
					0.39687,0.92604 0,0.50932 0.11906,0.92604 0.26458,0.92604 0.14553,0 
					0.26459,0.29766 0.26459,0.66146 v 0.66146 h -2.92932 c -3.02233,0 
					-3.68533,-0.2108 -3.68533,-1.17173 0,-0.22867 -0.11906,-0.41577 
					-0.26458,-0.41577 -0.14552,0 -0.26458,-0.41672 -0.26458,-0.92604 0,-0.64676 
					-0.1197,-0.92604 -0.39688,-0.92604 -0.24694,0 -0.39687,-0.24989 
					-0.39687,-0.66146 v -0.66146 h -3.57188 c -2.20486,0 -3.57187,-0.10126 
					-3.57187,-0.26458 0,-0.14553 0.14882,-0.23482 0.33073,-0.19844 0.20682,0.0414 
					0.36149,-0.30557 0.41284,-0.92604 0.0452,-0.54571 0.194,-0.99219 
					0.33073,-0.99219 0.13674,0 0.24861,-0.41672 0.24861,-0.92604 0,-0.50933 
					0.11907,-0.92604 0.26459,-0.92604 0.14552,0 0.26458,-0.16586 0.26458,-0.36857 
					0,-0.47586 0.58107,-0.68977 1.87372,-0.68977 h 1.0367 v -1.19062 c 0,-0.65485 
					-0.11907,-1.19063 -0.26459,-1.19063 -0.14552,0 -0.26458,-0.41672 
					-0.26458,-0.92604 0,-0.50932 -0.11906,-0.92604 -0.26458,-0.92604 -0.14552,0 
					-0.26459,-0.36422 -0.26459,-0.80937 0,-0.4722 -0.11022,-0.74124 
					-0.26458,-0.64584 -0.15436,0.0954 -0.26458,-0.17364 -0.26458,-0.64584 0,-0.54305 
					-0.13059,-0.80937 -0.39688,-0.80937 -0.27718,0 -0.39687,-0.27928 
					-0.39687,-0.92604 0,-0.50932 -0.068,-0.92604 -0.15119,-0.92604 -0.26655,0 
					-0.90715,0.74562 -0.90715,1.05586 0,0.53452 -0.87828,3.04043 -1.12327,3.20491 
					-0.13085,0.0879 -0.33881,0.5641 -0.46214,1.05834 -0.55975,2.24321 
					-1.19457,4.10275 -1.51002,4.42323 -0.18928,0.19229 -0.34415,0.57364 
					-0.34415,0.84744 0,0.2738 -0.10666,0.77131 -0.23703,1.10558 -0.30117,0.77223 
					-0.95143,2.93284 -0.95266,3.1654 -5.3e-4,0.097 -0.0902,0.26569 -0.19937,0.37483 
					-0.10914,0.10914 -0.19844,0.41672 -0.19844,0.68351 0,0.26678 -0.1168,0.48507 
					-0.25954,0.48507 -0.14275,0 -0.33551,0.32742 -0.42836,0.7276 -0.0928,0.40018 
					-0.33598,0.99549 -0.5403,1.32292 -0.34881,0.55895 -0.54982,0.59531 
					-3.29143,0.59531 -1.69746,0 -2.85732,-0.10131 -2.77038,-0.24199 z m 
					30.11032,-0.15378 c -0.52154,-0.59238 -0.47627,-26.2194 0.0472,-26.74292 
					0.30861,-0.30861 1.66813,-0.37798 7.40833,-0.37798 4.51052,0 7.03036,0.0948 
					7.03036,0.26459 0,0.14552 0.53578,0.26458 1.19063,0.26458 0.88194,0 
					1.19062,0.10289 1.19062,0.39687 0,0.24695 0.24988,0.39688 0.66146,0.39688 
					0.3638,0 0.66146,0.11906 0.66146,0.26458 0,0.14552 0.21993,0.26459 
					0.48873,0.26459 0.26881,0 0.61793,0.26789 0.77585,0.59531 0.1579,0.32742 
					0.29516,0.43798 0.30501,0.24568 0.0303,-0.59137 0.81166,0.36379 0.81166,0.99219 
					0,0.30144 0.11906,0.54807 0.26458,0.54807 0.14552,0 0.26458,0.29765 
					0.26458,0.66145 0,0.36381 0.10535,0.66146 0.2341,0.66146 0.12875,0 
					0.31591,0.65485 0.41591,1.45521 0.1,0.80037 0.29232,1.45521 0.42737,1.45521 
					0.13505,0 0.24554,0.30468 0.24554,0.67707 0,0.37239 -0.11279,0.60737 
					-0.25065,0.52216 -0.14079,-0.087 -0.32972,0.54397 -0.43105,1.4396 
					-0.0992,0.87698 -0.28408,1.5945 -0.41081,1.5945 -0.12672,0 -0.23041,0.23813 
					-0.23041,0.52917 0,0.29104 -0.11906,0.52917 -0.26458,0.52917 -0.14552,0 
					-0.26458,0.24662 -0.26458,0.54806 0,0.58577 -0.57252,1.30402 -1.03944,1.30402 
					-0.15591,0 -0.28348,0.10518 -0.28348,0.23372 0,0.12855 -0.2501,0.29913 
					-0.55579,0.37907 -0.30604,0.08 -0.47736,0.27224 -0.38126,0.42773 0.096,0.15532 
					-0.0111,0.2824 -0.23796,0.2824 -0.22687,0 -0.41249,0.11906 -0.41249,0.26458 
					0,0.14552 -0.29766,0.26458 -0.66146,0.26458 -0.65378,0 -0.91994,0.39891 
					-0.38512,0.57719 0.15199,0.0507 0.29639,0.35824 0.32089,0.6835 0.0245,0.32527 
					0.17769,0.5914 0.34041,0.5914 0.16272,0 0.45516,0.29765 0.64986,0.66145 
					0.19471,0.36381 0.45295,0.66146 0.57388,0.66146 0.12093,0 0.21987,0.23813 
					0.21987,0.52917 0,0.29104 0.10518,0.52917 0.23373,0.52917 0.12855,0 
					0.29913,0.2501 0.37907,0.55578 0.08,0.30604 0.27223,0.47737 0.42773,0.38126 
					0.16521,-0.1021 0.28239,0.0515 0.28239,0.37026 0,0.29963 0.11906,0.54478 
					0.26458,0.54478 0.14553,0 0.26459,0.17859 0.26459,0.39687 0,0.21828 
					0.11906,0.39688 0.26458,0.39688 0.14552,0 0.26458,0.17859 0.26458,0.39687 
					0,0.21828 0.11079,0.39688 0.24619,0.39688 0.1354,0 0.28705,0.26789 
					0.33701,0.59531 0.0499,0.32742 0.23683,0.56555 0.41527,0.52917 0.48319,-0.0985 
					0.39208,0.48564 -0.13857,0.88846 -0.56278,0.4272 -6.15156,0.52144 
					-6.15156,0.10373 0,-0.14553 -0.13487,-0.26459 -0.2997,-0.26459 -0.36496,0 
					-1.2878,-1.02635 -1.2878,-1.43225 0,-0.16112 -0.27466,-0.58939 -0.61036,-0.9517 
					-0.3357,-0.36232 -1.06251,-1.43266 -1.61513,-2.37855 -0.55262,-0.94588 
					-1.17093,-1.815 -1.37402,-1.93137 -0.20308,-0.11638 -0.36924,-0.38427 
					-0.36924,-0.59532 0,-0.21105 -0.12297,-0.38372 -0.27325,-0.38372 -0.17618,0 
					-0.24667,-1.05736 -0.19844,-2.97657 l 0.0748,-2.97656 1.19063,-0.17929 c 
					0.65484,-0.0986 1.53059,-0.26372 1.94611,-0.36691 0.79728,-0.19798 
					2.28722,-1.48392 2.28722,-1.97404 0,-0.15901 0.11906,-0.21553 0.26458,-0.12559 
					0.16161,0.0999 0.26459,-0.30593 0.26459,-1.04272 0,-0.66343 -0.10983,-1.20624 
					-0.24407,-1.20624 -0.13423,0 -0.38159,-0.41672 -0.54968,-0.92604 
					-0.1681,-0.50933 -0.47499,-0.92604 -0.68198,-0.92604 -0.207,0 -0.37636,-0.11907 
					-0.37636,-0.26459 0,-0.16463 -1.4993,-0.26458 -3.96875,-0.26458 h -3.96875 v 
					10.71562 10.71563 l -2.84427,-0.0101 c -2.11515,-0.007 -2.92905,-0.10635 
					-3.175,-0.3857 z m 27.97969,-13.36256 v -13.75834 h 9.80848 c 8.14792,0 
					9.87247,0.064 10.18645,0.37798 0.27205,0.27202 0.37799,1.05077 0.37799,2.77812 
					0,1.99746 -0.0692,2.40015 -0.41249,2.40015 -0.22688,0 -0.3389,0.11907 
					-0.24897,0.26459 0.10739,0.17375 -2.15932,0.26458 -6.60292,0.26458 h -6.76641 l 
					0.0701,7.87135 0.0701,7.87136 6.85755,0.0707 c 4.5179,0.0466 6.97775,0.17047 
					7.2099,0.36313 0.50586,0.41982 0.48511,4.36598 -0.0257,4.87673 -0.31432,0.31434 
					-2.07383,0.37798 -10.45104,0.37798 h -10.07303 z m -226.483337,6.40057 c 
					0,-0.49691 -0.136086,-0.84599 -0.33073,-0.84838 -0.220485,-0.003 
					-0.198437,-0.0895 0.06615,-0.26053 0.264584,-0.17098 0.286631,-0.25783 
					0.06615,-0.26053 -0.200816,-0.002 -0.330729,-0.36781 -0.330729,-0.93009 
					0,-0.64676 -0.119692,-0.92604 -0.396875,-0.92604 -0.277183,0 -0.396875,-0.27929 
					-0.396875,-0.92605 0,-0.50932 -0.119063,-0.92604 -0.264583,-0.92604 -0.145521,0 
					-0.264584,-0.41672 -0.264584,-0.92604 0,-0.50932 -0.119062,-0.92604 
					-0.264583,-0.92604 -0.145521,0 -0.264583,-0.53578 -0.264583,-1.19063 0,-0.65484 
					-0.119063,-1.19062 -0.264584,-1.19062 -0.145521,0 -0.264583,-0.41672 
					-0.264583,-0.92604 0,-0.64676 -0.119693,-0.92604 -0.396875,-0.92604 -0.277183,0 
					-0.396875,-0.27929 -0.396875,-0.92605 0,-0.50932 -0.119063,-0.92604 
					-0.264583,-0.92604 -0.145521,0 -0.264584,-0.35718 -0.264584,-0.79375 0,-0.43656 
					-0.0893,-0.79384 -0.198437,-0.79396 -0.109141,-1.2e-4 -0.234772,-0.5627 
					-0.279181,-1.25018 -0.04441,-0.68748 -0.174825,-1.31246 -0.289815,-1.38885 
					-0.282353,-0.18756 -0.56342,-2.05929 -0.380726,-2.53539 0.115131,-0.30003 
					0.760931,-0.38162 3.020544,-0.38162 h 2.874103 l 0.386453,1.25677 c 
					0.212548,0.69123 0.409646,1.07818 0.437996,0.8599 0.02835,-0.21828 0.1445,0.0794 
					0.258109,0.66146 0.113607,0.58208 0.404765,1.77271 0.647012,2.64583 
					0.24225,0.87313 0.505095,1.91492 0.584103,2.31511 0.07901,0.40018 
					0.249197,0.7276 0.378203,0.7276 0.129003,0 0.234553,0.53578 0.234553,1.19062 
					0,0.65485 -0.103571,1.19063 -0.230158,1.19063 -0.12659,0 -0.385691,0.74414 
					-0.575784,1.65365 -0.190093,0.9095 -0.35978,1.71317 -0.377087,1.78593 
					-0.0173,0.0728 -0.135927,0.60854 -0.263602,1.19063 -0.127674,0.58208 
					-0.256249,1.14763 -0.28572,1.25677 -0.02947,0.10914 -0.06847,0.44359 
					-0.08666,0.74322 -0.01818,0.29963 -0.1386,0.47956 -0.267576,0.39985 
					-0.246756,-0.15251 -0.543695,0.67343 -0.56405,1.56891 -0.0066,0.29104 
					-0.186835,0.76028 -0.400486,1.04275 -0.3677,0.48615 -0.388461,0.46847 
					-0.388461,-0.33072 z m 43.920834,-6.66516 v -2.64583 h 4.7625 4.7625 v 2.26786 c 
					0,1.6127 -0.109193,2.37705 -0.377976,2.64583 -0.297166,0.29717 -1.315357,0.37798 
					-4.7625,0.37798 h -4.384524 z m 192.087503,0 v -2.64583 h 4.49792 4.49791 v 
					2.64583 2.64584 h -4.49791 -4.49792 z" />
			</g>
		</svg>`;
  // *INDENT-ON*
}
export function OffscreenBandEndWRSR(rotation: number = 0): string {
  const title = 'Band End WRSR';
  const viewWidth = 297.92083;
  const viewHeight = 270.93334;
  // *INDENT-OFF*
  return `<svg
			width="1126"
			height="1024"
			viewBox="0 0 ${viewWidth} ${viewHeight}"
			xmlns="http://www.w3.org/2000/svg"
							>
			<title>${title}</title>
			<g transform="rotate(${rotation} ${viewWidth * 0.5} ${viewHeight * 0.5})">
				<path style="fill:#000000;stroke-width:0.264583" 
					d="m 164.10829,266.69195 c 
					-5.19002,-0.79159 -8.66152,-3.7445 -9.21837,-7.84131 -0.27089,-1.99301 
					0.0578,-2.22125 3.19909,-2.22125 2.60391,0 3.53558,0.25337 3.53558,0.96151 
					0,0.81349 0.87806,2.2435 1.60996,2.62198 1.39797,0.72292 3.32361,0.98964 
					4.72233,0.65409 1.4711,-0.35291 2.3177,-1.11931 2.55675,-2.31453 
					0.21421,-1.07108 -0.67735,-2.02004 -2.30827,-2.45683 -2.61109,-0.69931 
					-7.85288,-2.48327 -9.01161,-3.06696 -3.4505,-1.73811 -4.83992,-5.56666 
					-3.4077,-9.3899 1.59238,-4.25078 7.33903,-6.45039 13.32693,-5.10107 
					4.5835,1.03284 7.32828,3.7114 7.32828,7.15146 0,1.37991 -0.008,1.39269 
					-0.92383,1.56459 -1.39538,0.26177 -4.97245,0.0212 -5.09371,-0.34259 
					-0.3022,-0.90659 -1.38959,-2.11797 -2.32033,-2.58487 -2.11433,-1.06067 
					-5.20517,-0.46993 -5.93622,1.13456 -0.6636,1.45643 1.17656,2.79224 
					5.72841,4.15837 5.97284,1.7926 7.52391,2.73397 8.7169,5.2904 1.10728,2.37276 
					0.64859,6.25338 -0.97626,8.25946 -2.13019,2.62997 -7.20804,4.18175 
					-11.52793,3.52289 z m -65.452881,-1.13287 c 0.0019,-0.71244 2.526951,-10.56884 
					3.868621,-15.10142 0.52438,-1.77154 1.47255,-4.99808 2.10705,-7.17009 
					0.63449,-2.17202 1.2794,-4.0749 1.43313,-4.22863 0.45603,-0.45603 
					4.01464,-0.52424 4.59736,-0.0881 0.32192,0.24094 0.90952,1.7706 1.65924,4.31945 
					1.71886,5.84365 1.70329,5.11834 0.22679,10.56099 -0.39477,1.45521 
					-0.85528,3.23143 -1.02335,3.94716 -0.27625,1.17644 -0.96424,2.01788 
					-0.96424,1.17931 0,-0.57478 -1.88647,-6.31742 -2.0736,-6.31231 -0.0965,0.003 
					-1.05396,2.83038 -2.1278,6.28386 -1.07384,3.45348 -2.13409,6.48742 
					-2.35611,6.74208 -0.33055,0.37915 -0.85158,0.46302 -2.87621,0.46302 -2.388212,0 
					-2.472479,-0.0204 -2.470881,-0.59531 z m 14.287461,0.20037 c -0.26517,-0.31949 
					-0.16626,-0.98907 0.51784,-3.50572 1.84677,-6.79388 3.98708,-14.69336 
					5.03654,-18.58892 0.60764,-2.25557 1.27305,-4.27543 1.47868,-4.48857 
					0.55284,-0.57304 5.34268,-0.72443 6.05286,-0.19131 0.51159,0.38405 
					0.50396,0.44404 -0.48259,3.79182 -0.55133,1.87088 -1.10555,3.69926 
					-1.23161,4.06306 -0.12605,0.3638 -1.37252,4.47146 -2.76993,9.12812 
					-1.3974,4.65667 -2.71228,8.85362 -2.92195,9.32657 l -0.38123,0.85989 h -2.48542 
					c -1.88106,0 -2.56513,-0.096 -2.81319,-0.39494 z m 16.98464,0.0774 c 
					-0.46977,-0.46977 -0.45692,-26.54878 0.0132,-26.85223 0.1819,-0.11741 
					3.60494,-0.2021 7.60677,-0.18821 6.63919,0.023 7.4078,0.0729 8.78144,0.56909 
					0.82797,0.29911 1.93772,0.87358 2.46611,1.27661 3.12607,2.38437 4.16089,7.30054 
					2.34478,11.13948 -0.78793,1.66554 -2.52929,3.52653 -3.78751,4.0477 
					-0.44494,0.1843 -0.80899,0.44355 -0.80899,0.57612 0,0.13256 1.26465,2.05116 
					2.81034,4.26354 1.54569,2.21239 2.87472,4.19029 2.95341,4.39534 0.29514,0.76915 
					-0.75086,1.09006 -3.55291,1.09006 -1.49331,0 -2.9886,-0.12602 -3.32287,-0.28006 
					-0.33427,-0.15401 -1.79839,-2.0776 -3.25359,-4.2746 l -2.64584,-3.99456 
					-0.0893,-2.70561 c -0.0851,-2.57636 -0.0601,-2.72605 0.52189,-3.13372 
					0.34388,-0.24086 1.13519,-0.42855 1.80913,-0.4291 1.96628,-0.002 3.71144,-1.4919 
					3.71144,-3.16937 0,-1.026 -0.75788,-2.37185 -1.63773,-2.90828 -0.75725,-0.46168 
					-1.33249,-0.5341 -4.24925,-0.53496 l -3.37344,-9.9e-4 v 10.20385 c 0,6.5278 
					-0.0987,10.38822 -0.27389,10.71562 -0.24348,0.45495 -0.57545,0.51178 
					-2.98979,0.51178 -1.75959,0 -2.8277,-0.11179 -3.0334,-0.3175 z m 
					51.39753,-0.0132 c -0.0697,-0.1819 -0.0957,-6.28385 -0.0578,-13.55989 l 
					0.0688,-13.22917 3.30729,-0.23389 c 1.99253,-0.14091 5.20056,-0.13311 
					8.06979,0.0196 4.34582,0.23132 4.88074,0.31417 6.11389,0.94686 1.76535,0.90574 
					3.64242,3.01205 4.24676,4.76539 0.65998,1.91479 0.59801,4.66176 -0.14875,6.59391 
					-0.68132,1.76281 -2.5756,3.98544 -4.0283,4.72656 l -1.01972,0.52022 
					1.60743,2.31087 c 1.61071,2.31556 3.845,5.56284 4.34119,6.30942 0.53854,0.81026 
					-0.54889,1.16083 -3.6008,1.16083 -1.56102,0 -3.03544,-0.0757 -3.2765,-0.16819 
					-0.24106,-0.0925 -1.64978,-1.96773 -3.13049,-4.16718 l -2.69221,-3.99899 
					-0.0903,-2.87302 c -0.0516,-1.64152 0.0201,-2.98345 0.16731,-3.13064 
					0.1417,-0.1417 1.05953,-0.35708 2.03961,-0.47863 1.4915,-0.18497 
					1.92588,-0.36047 2.66474,-1.07659 1.15933,-1.12367 1.34121,-2.03596 
					0.69649,-3.49348 -0.76053,-1.71929 -1.64696,-2.04452 -5.57244,-2.04452 h 
					-3.18964 l -0.0777,10.46089 c -0.0665,8.95282 -0.13488,10.49702 
					-0.47455,10.71157 -0.61975,0.39148 -5.8106,0.32885 -5.96413,-0.0719 z m 
					-85.136169,-6.97292 c -0.457949,-1.42474 -3.255103,-10.57212 -4.320048,-14.1276 
					-0.370486,-1.23692 -0.840907,-2.77618 -1.045379,-3.42057 -0.784527,-2.47238 
					-0.579917,-2.65762 2.93552,-2.65762 2.635679,0 3.169087,0.14045 3.528396,0.92905 
					0.571169,1.25358 2.424729,7.89372 2.424729,8.68628 0,0.80342 -2.525165,10.80059 
					-2.90893,11.5165 -0.132797,0.24773 -0.33497,-0.057 -0.614288,-0.92604 z" />
			</g>
		</svg>`;
  // *INDENT-ON*
}
export function OffscreenBandEnd0Blobs(rotation: number = 0): string {
  const title = 'Band End Artwork';
  const viewWidth = 297.92083;
  const viewHeight = 270.93334;
  // *INDENT-OFF*
  return `<svg
			width="1126"
			height="1024"
			viewBox="0 0 ${viewWidth} ${viewHeight}"
			xmlns="http://www.w3.org/2000/svg"
							>
			<title>${title}</title>
			<g transform="rotate(${rotation} ${viewWidth * 0.5} ${viewHeight * 0.5})">
				<path style="fill:#676FF4;stroke-width:0.264583" 
					d="m 227.06648,270.56325 c 
					0,-0.29398 0.17639,-0.52917 0.39688,-0.52917 0.27718,0 0.39687,-0.27929 
					0.39687,-0.92604 0,-0.50932 0.11906,-0.92604 0.26459,-0.92604 0.14552,0 
					0.26458,-0.23812 0.26458,-0.52917 0,-0.29104 0.11906,-0.52916 0.26458,-0.52916 
					0.14552,0 0.26459,-0.29766 0.26459,-0.66146 0,-0.41159 0.14993,-0.66146 
					0.39687,-0.66146 0.27718,0 0.39688,-0.27929 0.39688,-0.92604 0,-0.50932 
					0.11906,-0.92604 0.26458,-0.92604 0.14552,0 0.26458,-0.23813 0.26458,-0.52917 
					0,-0.29104 0.11906,-0.52916 0.26459,-0.52916 0.14552,0 0.26458,-0.41672 
					0.26458,-0.92605 0,-0.64675 0.11969,-0.92604 0.39687,-0.92604 0.24695,0 
					0.39688,-0.24988 0.39688,-0.66146 0,-0.3638 0.11906,-0.66145 0.26458,-0.66145 
					0.14552,0 0.26459,-0.41672 0.26459,-0.92605 0,-0.50932 0.11906,-0.92604 
					0.26458,-0.92604 0.14552,0 0.26458,-0.41672 0.26458,-0.92604 0,-0.64676 
					0.11969,-0.92604 0.39688,-0.92604 0.30467,0 0.39687,-0.33808 0.39687,-1.45521 
					0,-0.80036 0.11906,-1.45521 0.26459,-1.45521 0.15782,0 0.26458,-1.01423 
					0.26458,-2.51354 0,-1.49931 -0.10676,-2.51354 -0.26458,-2.51354 -0.14553,0 
					-0.26459,-0.29766 -0.26459,-0.66146 0,-0.41157 -0.14993,-0.66146 
					-0.39687,-0.66146 -0.21828,0 -0.39688,-0.18709 -0.39688,-0.41577 0,-0.58364 
					-0.65448,-1.17173 -1.30402,-1.17173 -0.30143,0 -0.54806,-0.12756 
					-0.54806,-0.28348 0,-0.88258 -0.73579,-1.03943 -4.87589,-1.03943 -2.57024,0 
					-4.11994,0.0995 -4.11994,0.26458 0,0.14552 -0.59532,0.26458 -1.32292,0.26458 
					-0.99954,0 -1.32292,0.097 -1.32292,0.39688 0,0.29398 -0.30868,0.39687 
					-1.19062,0.39687 -0.65485,0 -1.19063,0.11907 -1.19063,0.26459 0,0.14699 
					-0.70555,0.26458 -1.5875,0.26458 -0.88194,0 -1.5875,0.11759 -1.5875,0.26458 
					0,0.14552 -0.53578,0.26459 -1.19062,0.26459 -0.65485,0 -1.19063,0.11906 
					-1.19063,0.26458 0,0.14552 -0.53578,0.26458 -1.19062,0.26458 -0.88195,0 
					-1.19063,0.1029 -1.19063,0.39688 0,0.30868 -0.35278,0.39687 -1.5875,0.39687 
					-0.88194,0 -1.5875,0.11759 -1.5875,0.26459 0,0.14552 -0.53578,0.26458 
					-1.19062,0.26458 -0.65485,0 -1.19063,0.11906 -1.19063,0.26458 0,0.14552 
					-0.65484,0.26459 -1.45521,0.26459 -1.11713,0 -1.45521,0.0922 -1.45521,0.39687 
					0,0.32493 -0.45567,0.39688 -2.51354,0.39688 -2.05787,0 -2.51354,-0.072 
					-2.51354,-0.39688 0,-0.27209 -0.27279,-0.39687 -0.86763,-0.39687 -0.56411,0 
					-1.00106,-0.18512 -1.24904,-0.52917 -0.20977,-0.29104 -0.53371,-0.52917 
					-0.71986,-0.52917 -0.18616,0 -0.33847,-0.15231 -0.33847,-0.33846 0,-0.18616 
					-0.23812,-0.5101 -0.52917,-0.71987 -0.29104,-0.20977 -0.52916,-0.59324 
					-0.52916,-0.85216 0,-0.25892 -0.11906,-0.47076 -0.26459,-0.47076 -0.14552,0 
					-0.26458,-0.29765 -0.26458,-0.66146 0,-0.41157 -0.14993,-0.66145 
					-0.39687,-0.66145 -0.33388,0 -0.39688,-0.58797 -0.39688,-3.70417 0,-3.1162 
					0.063,-3.70417 0.39688,-3.70417 0.29398,0 0.39687,-0.30868 0.39687,-1.19062 
					0,-0.65485 0.11906,-1.19063 0.26458,-1.19063 0.14553,0 0.26459,-0.41672 
					0.26459,-0.92604 0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 0.14552,0 
					0.26458,-0.41672 0.26458,-0.92604 0,-0.50932 0.11907,-0.92604 0.26459,-0.92604 
					0.14552,0 0.26458,-0.41672 0.26458,-0.92604 0,-0.64676 0.11969,-0.92605 
					0.39688,-0.92605 0.24694,0 0.39687,-0.24988 0.39687,-0.66145 0,-0.36381 
					0.11906,-0.66146 0.26458,-0.66146 0.14553,0 0.26459,-0.23813 0.26459,-0.52917 
					0,-0.29104 0.11906,-0.52917 0.26458,-0.52917 0.14552,0 0.26458,-0.29765 
					0.26458,-0.66145 0,-0.41158 0.14994,-0.66146 0.39688,-0.66146 0.24694,0 
					0.39687,-0.24989 0.39687,-0.66146 0,-0.3638 0.11907,-0.66146 0.26459,-0.66146 
					0.14552,0 0.26458,-0.1871 0.26458,-0.41577 0,-0.47519 0.6179,-1.17173 
					1.03944,-1.17173 0.15591,0 0.28348,-0.27137 0.28348,-0.60305 0,-0.33167 
					0.23812,-0.77468 0.52916,-0.98445 0.29105,-0.20977 0.52917,-0.65277 
					0.52917,-0.98445 0,-0.35965 0.16018,-0.60305 0.39688,-0.60305 0.24694,0 
					0.39687,-0.24988 0.39687,-0.66146 0,-0.3638 0.11906,-0.66146 0.26458,-0.66146 
					0.14553,0 0.26459,-0.65484 0.26459,-1.4552 0,-0.80037 -0.11906,-1.45521 
					-0.26459,-1.45521 -0.14552,0 -0.26458,-0.1786 -0.26458,-0.39688 0,-0.21828 
					-0.15231,-0.39687 -0.33846,-0.39687 -0.18616,0 -0.5101,-0.23813 
					-0.71987,-0.52917 -0.35608,-0.49403 -0.68493,-0.52917 -4.9532,-0.52917 
					-2.87148,0 -4.5718,0.0984 -4.5718,0.26459 0,0.14552 -0.53578,0.26458 
					-1.19063,0.26458 -0.65484,0 -1.19062,0.11906 -1.19062,0.26458 0,0.14552 
					-0.53578,0.26459 -1.19063,0.26459 -0.88194,0 -1.19062,0.10289 -1.19062,0.39687 
					0,0.27719 -0.27929,0.39688 -0.92604,0.39688 -0.50933,0 -0.92605,0.11906 
					-0.92605,0.26458 0,0.14552 -0.53578,0.26458 -1.19062,0.26458 -0.65484,0 
					-1.19063,0.11907 -1.19063,0.26459 0,0.14552 -0.41671,0.26458 -0.92604,0.26458 
					-0.64676,0 -0.92604,0.11969 -0.92604,0.39688 0,0.27718 -0.27928,0.39687 
					-0.92604,0.39687 -0.50932,0 -0.92604,0.11906 -0.92604,0.26458 0,0.14552 
					-0.59532,0.26459 -1.32292,0.26459 -0.7276,0 -1.32292,0.11906 -1.32292,0.26458 
					0,0.14552 -0.65484,0.26458 -1.4552,0.26458 -1.11713,0 -1.45521,0.0922 
					-1.45521,0.39688 0,0.33388 -0.58797,0.39687 -3.70417,0.39687 -3.1162,0 
					-3.70417,-0.063 -3.70417,-0.39687 0,-0.29398 -0.30868,-0.39688 -1.19062,-0.39688 
					-0.65485,0 -1.19063,-0.11906 -1.19063,-0.26458 0,-0.14552 -0.29765,-0.26458 
					-0.66145,-0.26458 -0.36381,0 -0.66146,-0.11907 -0.66146,-0.26459 0,-0.14552 
					-0.23813,-0.26458 -0.52917,-0.26458 -0.29398,0 -0.52917,-0.17639 
					-0.52917,-0.39687 0,-0.2367 -0.24339,-0.39688 -0.60305,-0.39688 -0.33167,0 
					-0.77467,-0.23812 -0.98445,-0.52917 -0.20977,-0.29104 -0.53371,-0.52916 
					-0.71986,-0.52916 -0.18616,0 -0.33847,-0.12448 -0.33847,-0.27661 0,-0.29502 
					-1.18829,-1.57548 -1.46208,-1.57548 -0.32694,0 -0.91917,-0.76713 
					-0.91917,-1.19062 0,-0.49089 -0.61088,-1.19063 -1.03943,-1.19063 -0.15592,0 
					-0.28349,-0.41671 -0.28349,-0.92604 0,-0.50932 -0.11906,-0.92604 
					-0.26458,-0.92604 -0.14552,0 -0.26458,-0.29766 -0.26458,-0.66146 0,-0.3638 
					-0.11907,-0.66146 -0.26459,-0.66146 -0.14552,0 -0.26458,-0.53578 
					-0.26458,-1.19062 0,-0.88194 -0.10289,-1.19063 -0.39687,-1.19063 -0.33766,0 
					-0.39688,-0.69085 -0.39688,-4.6302 0,-3.93936 0.0592,-4.63021 0.39688,-4.63021 
					0.27718,0 0.39687,-0.27929 0.39687,-0.92604 0,-0.50933 0.11906,-0.92604 
					0.26458,-0.92604 0.14552,0 0.26459,-0.41672 0.26459,-0.92605 0,-0.50932 
					0.11906,-0.92604 0.26458,-0.92604 0.14552,0 0.26458,-0.29765 0.26458,-0.66146 
					0,-0.41157 0.14993,-0.66145 0.39688,-0.66145 0.22048,0 0.39687,-0.23519 
					0.39687,-0.52917 0,-0.29104 0.11907,-0.52917 0.26459,-0.52917 0.14552,0 
					0.26458,-0.29765 0.26458,-0.66146 0,-0.3638 0.11906,-0.66145 0.26458,-0.66145 
					0.14552,0 0.26459,-0.23813 0.26459,-0.52917 0,-0.29398 0.17639,-0.52917 
					0.39687,-0.52917 0.21828,0 0.39688,-0.17859 0.39688,-0.39687 0,-0.21828 
					0.11906,-0.39688 0.26458,-0.39688 0.14552,0 0.26458,-0.23812 0.26458,-0.52916 
					0,-0.29105 0.11907,-0.52917 0.26459,-0.52917 0.14552,0 0.26458,-0.17859 
					0.26458,-0.39688 0,-0.21828 0.17859,-0.39687 0.39688,-0.39687 0.22048,0 
					0.39687,-0.23519 0.39687,-0.52917 0,-0.29104 0.11906,-0.52916 0.26458,-0.52916 
					0.14552,0 0.26459,-0.12757 0.26459,-0.28349 0,-0.30835 0.65132,-1.03943 
					0.92604,-1.03943 0.21713,0 0.92604,-0.70365 0.92604,-0.91917 0,-0.21252 
					1.24956,-1.46208 1.46208,-1.46208 0.21552,0 0.91917,-0.70891 0.91917,-0.92604 
					0,-0.22077 0.70528,-0.92604 0.92604,-0.92604 0.22077,0 0.92604,-0.70528 
					0.92604,-0.92605 0,-0.22076 0.70528,-0.92604 0.92605,-0.92604 0.27471,0 
					0.92604,-0.73108 0.92604,-1.03943 0,-0.15592 0.24353,-0.28348 0.54119,-0.28348 
					0.5631,0 1.84006,-1.09334 1.84006,-1.57548 0,-0.15213 0.17859,-0.27661 
					0.39687,-0.27661 0.21828,0 0.39688,-0.11906 0.39688,-0.26458 0,-0.14552 
					0.18709,-0.26458 0.41577,-0.26458 0.47519,0 1.17173,-0.6179 1.17173,-1.03944 
					0,-0.15591 0.17859,-0.28348 0.39687,-0.28348 0.21828,0 0.39688,-0.11906 
					0.39688,-0.26458 0,-0.14552 0.23812,-0.26459 0.52916,-0.26459 0.29105,0 
					0.52917,-0.11906 0.52917,-0.26458 0,-0.14552 0.17859,-0.26458 0.39688,-0.26458 
					0.21828,0 0.39687,-0.12448 0.39687,-0.27661 0,-0.43338 1.25169,-1.57548 
					1.72666,-1.57548 0.48518,0 1.18376,-0.6134 1.18376,-1.03943 0,-0.15592 
					0.15231,-0.28348 0.33846,-0.28348 0.18616,0 0.5101,-0.23813 0.71987,-0.52917 
					0.20977,-0.29104 0.65278,-0.52917 0.98445,-0.52917 0.33168,0 0.60305,-0.12447 
					0.60305,-0.27661 0,-0.15213 0.29225,-0.56885 0.64943,-0.92604 0.35719,-0.35718 
					0.77391,-0.64943 0.92605,-0.64943 0.15213,0 0.2766,-0.17859 0.2766,-0.39688 
					0,-0.21828 0.21185,-0.39687 0.47076,-0.39687 0.25892,0 0.64239,-0.23813 
					0.85216,-0.52917 0.20977,-0.29104 0.53371,-0.52916 0.71987,-0.52916 0.18615,0 
					0.33846,-0.12448 0.33846,-0.27661 0,-0.15214 0.29225,-0.56886 0.64944,-0.92604 
					0.35718,-0.35719 0.7739,-0.64944 0.92604,-0.64944 0.15213,0 0.27661,-0.12447 
					0.27661,-0.27661 0,-0.15213 0.29224,-0.56885 0.64943,-0.92604 0.35719,-0.35718 
					0.77391,-0.64943 0.92604,-0.64943 0.15214,0 0.27661,-0.12447 0.27661,-0.27661 
					0,-0.29502 1.18829,-1.57547 1.46208,-1.57547 0.21552,0 0.91917,-0.70891 
					0.91917,-0.92604 0,-0.22077 0.70528,-0.92605 0.92604,-0.92605 0.22077,0 
					0.92604,-0.70527 0.92604,-0.92604 0,-0.22076 0.70528,-0.92604 0.92605,-0.92604 
					0.21713,0 0.92604,-0.70365 0.92604,-0.91917 0,-0.27379 1.28045,-1.46208 
					1.57547,-1.46208 0.15214,0 0.27661,-0.12447 0.27661,-0.27661 0,-0.35915 
					1.21632,-1.57547 1.57548,-1.57547 0.15213,0 0.2766,-0.12448 0.2766,-0.27661 
					0,-0.48214 1.27696,-1.57548 1.84006,-1.57548 0.29766,0 0.54119,-0.12756 
					0.54119,-0.28348 0,-0.44791 0.70885,-1.03943 1.24561,-1.03943 0.26931,0 
					0.66129,-0.23813 0.87106,-0.52917 0.20977,-0.29104 0.65278,-0.52917 
					0.98445,-0.52917 0.35966,0 0.60305,-0.16018 0.60305,-0.39687 0,-0.22049 
					0.23519,-0.39688 0.52917,-0.39688 0.29104,0 0.52916,-0.11906 0.52916,-0.26458 
					0,-0.14552 0.41672,-0.26458 0.92605,-0.26458 0.50932,0 0.92604,-0.11906 
					0.92604,-0.26459 0,-0.14552 0.41672,-0.26458 0.92604,-0.26458 0.64676,0 
					0.92604,-0.11969 0.92604,-0.39687 0,-0.33243 0.55857,-0.39688 3.43958,-0.39688 
					2.88102,0 3.43959,0.0645 3.43959,0.39688 0,0.27209 0.27279,0.39687 
					0.86763,0.39687 0.56411,0 1.00106,0.18512 1.24903,0.52917 0.20978,0.29104 
					0.65278,0.52916 0.98446,0.52916 0.33167,0 0.60304,0.15231 0.60304,0.33847 
					0,0.18616 0.23813,0.5101 0.52917,0.71987 0.29104,0.20977 0.52917,0.59324 
					0.52917,0.85216 0,0.25891 0.17859,0.47075 0.39687,0.47075 0.24695,0 
					0.39688,0.24989 0.39688,0.66146 0,0.3638 0.11906,0.66146 0.26458,0.66146 
					0.14552,0 0.26459,0.29766 0.26459,0.66146 0,0.3638 0.11906,0.66146 
					0.26458,0.66146 0.16536,0 0.26458,1.5875 0.26458,4.23333 0,2.64583 
					-0.0992,4.23333 -0.26458,4.23333 -0.14552,0 -0.26458,0.41672 -0.26458,0.92605 
					0,0.50932 -0.11907,0.92604 -0.26459,0.92604 -0.14552,0 -0.26458,0.41672 
					-0.26458,0.92604 0,0.64676 -0.11969,0.92604 -0.39688,0.92604 -0.24694,0 
					-0.39687,0.24988 -0.39687,0.66146 0,0.3638 -0.11906,0.66146 -0.26459,0.66146 
					-0.14552,0 -0.26458,0.23812 -0.26458,0.52916 0,0.29104 -0.11906,0.52917 
					-0.26458,0.52917 -0.14552,0 -0.26459,0.29766 -0.26459,0.66146 0,0.3638 
					-0.12756,0.66146 -0.28348,0.66146 -0.46691,0 -1.03943,0.71825 -1.03943,1.30401 
					0,0.30144 -0.11906,0.54807 -0.26459,0.54807 -0.14552,0 -0.26458,0.29766 
					-0.26458,0.66146 0,0.3638 -0.12447,0.66146 -0.27661,0.66146 -0.43338,0 
					-1.57547,1.25169 -1.57547,1.72666 0,0.42227 -0.59571,1.18375 -0.92604,1.18375 
					-0.22077,0 -0.92605,0.70528 -0.92605,0.92604 0,0.22077 -0.70527,0.92605 
					-0.92604,0.92605 -0.33269,0 -0.92604,0.76287 -0.92604,1.19062 0,0.49089 
					-0.61088,1.19063 -1.03943,1.19063 -0.15592,0 -0.28349,0.12447 -0.28349,0.2766 
					0,0.48214 -1.27695,1.57548 -1.84005,1.57548 -0.29766,0 -0.5412,0.12757 
					-0.5412,0.28348 0,0.30836 -0.65132,1.03944 -0.92604,1.03944 -0.22076,0 
					-0.92604,0.70527 -0.92604,0.92604 0,0.21713 -0.70365,0.92604 -0.91917,0.92604 
					-0.21252,0 -1.46208,1.24956 -1.46208,1.46208 0,0.26923 -0.73498,0.91917 
					-1.03943,0.91917 -0.15592,0 -0.28349,0.29766 -0.28349,0.66146 0,0.3638 
					-0.11906,0.66146 -0.26458,0.66146 -0.14552,0 -0.26458,0.23812 -0.26458,0.52916 
					0,0.29104 -0.11906,0.52917 -0.26459,0.52917 -0.14552,0 -0.26458,0.29766 
					-0.26458,0.66146 0,0.41157 -0.14993,0.66146 -0.39687,0.66146 -0.29398,0 
					-0.39688,0.30868 -0.39688,1.19062 0,0.88194 0.1029,1.19063 0.39688,1.19063 
					0.24694,0 0.39687,0.24988 0.39687,0.66145 0,0.48507 0.14111,0.66146 
					0.52917,0.66146 0.29104,0 0.52916,0.11906 0.52916,0.26459 0,0.15782 
					1.01424,0.26458 2.51355,0.26458 1.4993,0 2.51354,-0.10676 2.51354,-0.26458 
					0,-0.14553 0.53578,-0.26459 1.19062,-0.26459 0.65485,0 1.19063,-0.11906 
					1.19063,-0.26458 0,-0.16105 1.19062,-0.26458 3.04271,-0.26458 1.85208,0 
					3.0427,0.10353 3.0427,0.26458 0,0.14552 0.41672,0.26458 0.92605,0.26458 
					0.50932,0 0.92604,0.11906 0.92604,0.26459 0,0.14552 0.29765,0.26458 
					0.66146,0.26458 0.3638,0 0.66145,0.11906 0.66145,0.26458 0,0.14552 
					0.23813,0.26459 0.52917,0.26459 0.29398,0 0.52917,0.17639 0.52917,0.39687 
					0,0.21828 0.12447,0.39688 0.27661,0.39688 0.35915,0 1.57547,1.21631 
					1.57547,1.57547 0,0.15214 0.1786,0.27661 0.39688,0.27661 0.22048,0 
					0.39687,0.23518 0.39687,0.52917 0,0.29104 0.11906,0.52916 0.26459,0.52916 
					0.14552,0 0.26458,0.29766 0.26458,0.66146 0,0.3638 0.11906,0.66146 
					0.26458,0.66146 0.14552,0 0.26459,0.29765 0.26459,0.66146 0,0.41157 
					0.14993,0.66145 0.39687,0.66145 0.30467,0 0.39688,0.33808 0.39688,1.45521 
					0,0.80037 0.11906,1.45521 0.26458,1.45521 0.15119,0 0.26458,0.79375 
					0.26458,1.85208 0,1.05834 -0.11339,1.85209 -0.26458,1.85209 -0.14699,0 
					-0.26458,0.70555 -0.26458,1.5875 0,1.23472 -0.0882,1.5875 -0.39688,1.5875 
					-0.29398,0 -0.39687,0.30868 -0.39687,1.19062 0,0.65485 -0.11907,1.19063 
					-0.26459,1.19063 -0.16428,0 -0.26458,1.46151 -0.26458,3.85536 0,3.86554 
					0.1681,4.6113 1.03943,4.6113 0.15592,0 0.28349,0.1786 0.28349,0.39688 0,0.31207 
					0.36747,0.39687 1.71979,0.39687 1.35231,0 1.71979,-0.0848 1.71979,-0.39687 
					0,-0.21828 0.12447,-0.39688 0.27661,-0.39688 0.35915,0 1.57547,-1.21631 
					1.57547,-1.57547 0,-0.15214 0.1786,-0.27661 0.39688,-0.27661 0.22048,0 
					0.39687,-0.23518 0.39687,-0.52917 0,-0.29104 0.11906,-0.52916 0.26459,-0.52916 
					0.14552,0 0.26458,-0.12757 0.26458,-0.28349 0,-0.33788 0.66212,-1.03943 
					0.98102,-1.03943 0.12379,0 0.39671,-0.23812 0.60648,-0.52917 0.20977,-0.29104 
					0.65277,-0.52916 0.98445,-0.52916 0.33168,0 0.60305,-0.12757 0.60305,-0.28348 
					0,-0.5372 0.75792,-1.03944 1.5686,-1.03944 0.44696,0 0.81265,-0.11906 
					0.81265,-0.26458 0,-0.14552 0.41672,-0.26459 0.92604,-0.26459 0.64676,0 
					0.92604,-0.11969 0.92604,-0.39687 0,-0.3197 0.41158,-0.39688 2.11667,-0.39688 
					1.70509,0 2.11667,0.0772 2.11667,0.39688 0,0.27718 0.27928,0.39687 
					0.92604,0.39687 0.50932,0 0.92604,0.11907 0.92604,0.26459 0,0.14552 
					0.29766,0.26458 0.66146,0.26458 0.3638,0 0.66146,0.11906 0.66146,0.26458 
					0,0.14553 0.23812,0.26459 0.52916,0.26459 0.29398,0 0.52917,0.17639 
					0.52917,0.39687 0,0.21828 0.12303,0.39688 0.2734,0.39688 0.35572,0 
					2.10785,1.75213 2.10785,2.10785 0,0.15037 0.12757,0.2734 0.28348,0.2734 
					0.42856,0 1.03944,0.69973 1.03944,1.19062 0,0.49089 0.61087,1.19063 
					1.03943,1.19063 0.15592,0 0.28348,0.29765 0.28348,0.66146 0,0.3638 
					0.11906,0.66145 0.26459,0.66145 0.14552,0 0.26458,0.41672 0.26458,0.92605 
					0,0.50932 0.11906,0.92604 0.26458,0.92604 0.14552,0 0.26459,0.41672 
					0.26459,0.92604 0,0.64676 0.11969,0.92604 0.39687,0.92604 0.26458,0 
					0.39688,0.26458 0.39688,0.79375 0,0.43656 0.11906,0.79375 0.26458,0.79375 
					0.15782,0 0.26458,1.01424 0.26458,2.51354 0,1.49931 0.10676,2.51354 
					0.26459,2.51354 0.16105,0 0.26458,1.19063 0.26458,3.04271 0,1.85208 
					-0.10353,3.04271 -0.26458,3.04271 -0.16757,0 -0.26459,1.94028 -0.26459,5.29167 
					0,3.35139 -0.097,5.29166 -0.26458,5.29166 -0.14925,0 -0.26458,0.74965 
					-0.26458,1.71979 0,0.97014 0.11533,1.7198 0.26458,1.7198 0.15959,0 
					0.26458,1.10243 0.26458,2.77812 0,1.67569 0.105,2.77813 0.26459,2.77813 
					0.14552,0 0.26458,0.29765 0.26458,0.66145 0,0.41158 0.14993,0.66146 
					0.39687,0.66146 0.22049,0 0.39688,0.23519 0.39688,0.52917 0,0.35278 
					0.17639,0.52917 0.52917,0.52917 0.29398,0 0.52916,0.17639 0.52916,0.39687 
					0,0.30868 0.35278,0.39688 1.5875,0.39688 1.23473,0 1.5875,-0.0882 
					1.5875,-0.39688 0,-0.22048 0.23519,-0.39687 0.52917,-0.39687 0.35278,0 
					0.52917,-0.17639 0.52917,-0.52917 0,-0.29104 0.12756,-0.52917 0.28348,-0.52917 
					0.5372,0 1.03943,-0.75792 1.03943,-1.5686 0,-0.44696 0.11907,-0.81265 
					0.26459,-0.81265 0.14552,0 0.26458,-0.41672 0.26458,-0.92604 0,-0.64676 
					0.11969,-0.92604 0.39687,-0.92604 0.24695,0 0.39688,-0.24988 0.39688,-0.66146 
					0,-0.3638 0.11906,-0.66146 0.26458,-0.66146 0.14552,0 0.26459,-0.29765 
					0.26459,-0.66146 0,-0.3638 0.11906,-0.66145 0.26458,-0.66145 0.14552,0 
					0.26458,-0.23813 0.26458,-0.52917 0,-0.29398 0.17639,-0.52917 0.39688,-0.52917 
					0.23669,0 0.39687,-0.24339 0.39687,-0.60305 0,-0.33167 0.23813,-0.77468 
					0.52917,-0.98445 0.29104,-0.20977 0.52917,-0.65277 0.52917,-0.98445 0,-0.33167 
					0.12447,-0.60305 0.2766,-0.60305 0.35916,0 1.57548,-1.21632 1.57548,-1.57547 
					0,-0.15214 0.12449,-0.27661 0.27662,-0.27661 0.35915,0 1.57546,-1.21632 
					1.57546,-1.57548 0,-0.15213 0.27139,-0.2766 0.60304,-0.2766 0.33168,0 
					0.7747,-0.23813 0.98446,-0.52917 0.20976,-0.29104 0.65278,-0.52917 
					0.98446,-0.52917 0.35965,0 0.60304,-0.16018 0.60304,-0.39687 0,-0.22049 
					0.23519,-0.39688 0.52917,-0.39688 0.29104,0 0.52917,-0.11906 0.52917,-0.26458 
					0,-0.14552 0.29765,-0.26458 0.66145,-0.26458 0.36381,0 0.66146,-0.11907 
					0.66146,-0.26459 0,-0.14552 0.53578,-0.26458 1.19063,-0.26458 0.88193,0 
					1.19062,-0.10289 1.19062,-0.39688 0,-0.33387 0.58796,-0.39687 3.70417,-0.39687 
					2.95992,0 3.70417,0.0728 3.70417,0.36247 0,0.23358 0.42547,0.41042 
					1.19663,0.49734 2.01644,0.22728 3.51562,1.57706 6.66168,5.99789 1.41372,1.98654 
					3.29848,7.01938 3.89898,10.41141 0.59354,3.35278 1.73778,13.89499 
					1.73683,16.00224 v 0.8599 h -34.66042 c -34.48403,0 -34.66042,-0.003 
					-34.66042,-0.52917 z M 50.060228,268.57888 c 0,-0.22048 -0.235186,-0.39688 
					-0.529167,-0.39688 -0.291041,0 -0.529166,-0.11906 -0.529166,-0.26458 0,-0.14552 
					-0.127567,-0.26459 -0.283483,-0.26459 -0.466915,0 -1.039434,-0.71823 
					-1.039434,-1.30402 0,-0.30144 -0.119063,-0.54806 -0.264583,-0.54806 -0.145521,0 
					-0.264584,-0.41672 -0.264584,-0.92604 0,-0.50932 -0.119062,-0.92604 
					-0.264583,-0.92604 -0.145521,0 -0.264584,-0.41672 -0.264584,-0.92604 0,-0.64676 
					-0.119692,-0.92604 -0.396875,-0.92604 -0.314981,0 -0.396875,-0.38218 
					-0.396875,-1.85209 0,-1.05833 -0.113392,-1.85208 -0.264583,-1.85208 -0.163322,0 
					-0.264583,-1.36702 -0.264583,-3.57188 0,-2.20486 0.101261,-3.57187 
					0.264583,-3.57187 0.145521,0 0.264583,-0.41672 0.264583,-0.92604 0,-0.64676 
					0.119693,-0.92604 0.396875,-0.92604 0.225047,0 0.396875,-0.23729 
					0.396875,-0.54807 0,-1.01133 0.619313,-1.30402 2.759226,-1.30402 1.159127,0 
					2.003275,0.11149 2.003275,0.26459 0,0.14552 0.246628,0.26458 0.548065,0.26458 
					0.585769,0 1.304018,0.57252 1.304018,1.03943 0,0.15592 0.119062,0.28349 
					0.264583,0.28349 0.145521,0 0.264584,0.23812 0.264584,0.52916 0,0.29398 
					0.176389,0.52917 0.396875,0.52917 0.246943,0 0.396875,0.24988 0.396875,0.66146 
					0,0.3638 0.119062,0.66146 0.264583,0.66146 0.145521,0 0.264583,0.41671 
					0.264583,0.92604 0,0.50932 0.119063,0.92604 0.264584,0.92604 0.151191,0 
					0.264583,0.79375 0.264583,1.85208 0,1.46991 0.08189,1.85209 0.396875,1.85209 
					0.329771,0 0.396875,0.51446 0.396875,3.0427 0,2.52825 -0.0671,3.04271 
					-0.396875,3.04271 -0.293981,0 -0.396875,0.30868 -0.396875,1.19063 0,0.65484 
					-0.119062,1.19062 -0.264583,1.19062 -0.145521,0 -0.264584,0.29766 
					-0.264584,0.66146 0,0.3638 -0.119062,0.66146 -0.264583,0.66146 -0.145521,0 
					-0.264583,0.23812 -0.264583,0.52916 0,0.38807 -0.17639,0.52917 -0.661459,0.52917 
					-0.411575,0 -0.661458,0.14994 -0.661458,0.39688 0,0.30869 -0.352777,0.39687 
					-1.5875,0.39687 -1.234723,0 -1.5875,-0.0882 -1.5875,-0.39687 z m 
					17.991667,-0.66146 c 0,-0.14552 -0.124473,-0.26459 -0.276609,-0.26459 
					-0.482137,0 -1.575474,-1.27695 -1.575474,-1.84004 0,-0.30469 -0.173451,-0.54121 
					-0.396875,-0.54121 -0.246944,0 -0.396875,-0.24987 -0.396875,-0.66145 0,-0.36381 
					-0.119063,-0.66146 -0.264584,-0.66146 -0.145521,0 -0.264583,-0.53578 
					-0.264583,-1.19063 0,-0.65484 -0.119063,-1.19062 -0.264583,-1.19062 -0.146992,0 
					-0.264584,-0.70556 -0.264584,-1.5875 0,-1.23472 -0.08819,-1.5875 
					-0.396875,-1.5875 -0.33624,0 -0.396875,-0.64676 -0.396875,-4.23333 0,-3.58658 
					0.06063,-4.23334 0.396875,-4.23334 0.272092,0 0.396875,-0.27279 
					0.396875,-0.86763 0,-0.56411 0.185119,-1.00106 0.529167,-1.24903 
					0.291042,-0.20978 0.529167,-0.53372 0.529167,-0.71987 0,-0.24669 
					0.573918,-0.33847 2.116666,-0.33847 1.705092,0 2.116667,0.0772 2.116667,0.39688 
					0,0.21828 0.178594,0.39687 0.396875,0.39687 0.218281,0 0.396875,0.11906 
					0.396875,0.26459 0,0.14552 0.238125,0.26458 0.529167,0.26458 0.388056,0 
					0.529166,0.17639 0.529166,0.66146 0,0.3638 0.127567,0.66146 0.283483,0.66146 
					0.537197,0 1.039434,0.75792 1.039434,1.5686 0,0.44695 0.119063,0.81265 
					0.264583,0.81265 0.145521,0 0.264584,0.41671 0.264584,0.92604 0,0.64676 
					0.119692,0.92604 0.396875,0.92604 0.314981,0 0.396875,0.38217 0.396875,1.85208 
					0,1.05834 0.113392,1.85209 0.264583,1.85209 0.15679,0 0.264583,0.97014 
					0.264583,2.38125 0,1.41111 -0.107793,2.38125 -0.264583,2.38125 -0.146992,0 
					-0.264583,0.70555 -0.264583,1.5875 0,1.23473 -0.08819,1.58749 -0.396875,1.58749 
					-0.223425,0 -0.396875,0.23652 -0.396875,0.54121 0,0.56309 -1.093338,1.84004 
					-1.575475,1.84004 -0.152135,0 -0.276609,0.11907 -0.276609,0.26459 0,0.14925 
					-0.749652,0.26458 -1.719791,0.26458 -0.97014,0 -1.719792,-0.11533 
					-1.719792,-0.26458 z m 42.862505,-3.57188 c 0,-0.27718 -0.27929,-0.39687 
					-0.92605,-0.39687 -0.50932,0 -0.92604,-0.11906 -0.92604,-0.26458 0,-0.14553 
					-0.29765,-0.26459 -0.66146,-0.26459 -0.3638,0 -0.66145,-0.11906 
					-0.66145,-0.26458 0,-0.14552 -0.29766,-0.26458 -0.66146,-0.26458 -0.3638,0 
					-0.66146,-0.12448 -0.66146,-0.27661 0,-0.35916 -1.21632,-1.57548 
					-1.57548,-1.57548 -0.15213,0 -0.2766,-0.15231 -0.2766,-0.33846 0,-0.18616 
					-0.23813,-0.5101 -0.52917,-0.71987 -0.29104,-0.20977 -0.52917,-0.65278 
					-0.52917,-0.98445 0,-0.33168 -0.12756,-0.60305 -0.28348,-0.60305 -0.44792,0 
					-1.03943,-0.70885 -1.03943,-1.24561 0,-0.26931 -0.23813,-0.66129 
					-0.52917,-0.87106 -0.29104,-0.20977 -0.52917,-0.59324 -0.52917,-0.85216 
					0,-0.25891 -0.17859,-0.47075 -0.39687,-0.47075 -0.2367,0 -0.39688,-0.2434 
					-0.39688,-0.60305 0,-0.33168 -0.23812,-0.77468 -0.529164,-0.98445 
					-0.291042,-0.20978 -0.529167,-0.65278 -0.529167,-0.98446 0,-0.35965 
					-0.160181,-0.60304 -0.396875,-0.60304 -0.246944,0 -0.396875,-0.24989 
					-0.396875,-0.66146 0,-0.3638 -0.119063,-0.66146 -0.264583,-0.66146 -0.145521,0 
					-0.264584,-0.41672 -0.264584,-0.92604 0,-0.50933 -0.119062,-0.92604 
					-0.264583,-0.92604 -0.145521,0 -0.264583,-0.23813 -0.264583,-0.52917 0,-0.29398 
					-0.17639,-0.52917 -0.396875,-0.52917 -0.246944,0 -0.396875,-0.24988 
					-0.396875,-0.66146 0,-0.3638 -0.119063,-0.66145 -0.264584,-0.66145 -0.145521,0 
					-0.264583,-0.41672 -0.264583,-0.92605 0,-0.50932 -0.119063,-0.92604 
					-0.264583,-0.92604 -0.145521,0 -0.264584,-0.41672 -0.264584,-0.92604 0,-0.64676 
					-0.119692,-0.92604 -0.396875,-0.92604 -0.293981,0 -0.396875,-0.30868 
					-0.396875,-1.19063 0,-0.65484 -0.119062,-1.19062 -0.264583,-1.19062 -0.145521,0 
					-0.264583,-0.41672 -0.264583,-0.92604 0,-0.50933 -0.119063,-0.92604 
					-0.264584,-0.92604 -0.145521,0 -0.264583,-0.53578 -0.264583,-1.19063 0,-0.88194 
					-0.102894,-1.19062 -0.396875,-1.19062 -0.293981,0 -0.396875,-0.30869 
					-0.396875,-1.19063 0,-0.65484 -0.119063,-1.19062 -0.264583,-1.19062 -0.16169,0 
					-0.264584,-1.23473 -0.264584,-3.175 0,-1.94028 0.102894,-3.175 0.264584,-3.175 
					0.14552,0 0.264583,-0.23813 0.264583,-0.52917 0,-0.29398 0.17639,-0.52917 
					0.396875,-0.52917 0.236693,0 0.396875,-0.24339 0.396875,-0.60305 0,-0.33167 
					0.238125,-0.77467 0.529167,-0.98445 0.291041,-0.20977 0.529166,-0.53371 
					0.529166,-0.71986 0,-0.18616 0.297656,-0.33847 0.661459,-0.33847 0.363802,0 
					0.661458,-0.11906 0.661458,-0.26458 0,-0.14552 0.297656,-0.26459 
					0.661458,-0.26459 0.363802,0 0.661459,-0.11906 0.661459,-0.26458 0,-0.16105 
					1.190625,-0.26458 3.042704,-0.26458 1.85209,0 3.04271,0.10353 3.04271,0.26458 
					0,0.14552 0.36569,0.26458 0.81265,0.26458 0.81068,0 1.5686,0.50224 
					1.5686,1.03944 0,0.15591 0.24354,0.28348 0.54119,0.28348 0.56311,0 
					1.84006,1.09334 1.84006,1.57547 0,0.15214 0.12448,0.27661 0.27661,0.27661 
					0.35916,0 1.57548,1.21632 1.57548,1.57548 0,0.15213 0.12447,0.27661 
					0.2766,0.27661 0.48214,0 1.57548,1.27695 1.57548,1.84005 0,0.29766 
					0.12757,0.5412 0.28348,0.5412 0.46692,0 1.03944,0.71825 1.03944,1.30401 
					0,0.30144 0.11906,0.54807 0.26458,0.54807 0.14552,0 0.26458,0.29766 
					0.26458,0.66146 0,0.3638 0.11907,0.66146 0.26459,0.66146 0.14552,0 
					0.26458,0.23812 0.26458,0.52916 0,0.29398 0.17639,0.52917 0.39687,0.52917 
					0.24695,0 0.39688,0.24988 0.39688,0.66146 0,0.3638 0.11906,0.66146 
					0.26458,0.66146 0.14552,0 0.26459,0.23812 0.26459,0.52916 0,0.29104 
					0.11906,0.52917 0.26458,0.52917 0.14552,0 0.26458,0.29766 0.26458,0.66146 
					0,0.41157 0.14993,0.66146 0.39688,0.66146 0.27718,0 0.39687,0.27928 
					0.39687,0.92604 0,0.50932 0.11907,0.92604 0.26459,0.92604 0.14552,0 
					0.26458,0.29766 0.26458,0.66146 0,0.3638 0.11906,0.66146 0.26458,0.66146 
					0.14552,0 0.26459,0.53578 0.26459,1.19062 0,0.88194 0.10289,1.19063 
					0.39687,1.19063 0.27718,0 0.39688,0.27928 0.39688,0.92604 0,0.50932 
					0.11906,0.92604 0.26458,0.92604 0.14552,0 0.26458,0.53578 0.26458,1.19062 
					0,0.65485 0.11907,1.19063 0.26459,1.19063 0.14699,0 0.26458,0.70556 
					0.26458,1.5875 0,1.23472 0.0882,1.5875 0.39687,1.5875 0.33161,0 0.39688,0.54387 
					0.39688,3.30729 0,2.76343 -0.0653,3.30729 -0.39688,3.30729 -0.27718,0 
					-0.39687,0.27929 -0.39687,0.92604 0,0.50933 -0.11906,0.92605 -0.26458,0.92605 
					-0.14552,0 -0.26459,0.29765 -0.26459,0.66145 0,0.36381 -0.11906,0.66146 
					-0.26458,0.66146 -0.14552,0 -0.26458,0.23813 -0.26458,0.52917 0,0.29398 
					-0.17639,0.52917 -0.39688,0.52917 -0.21828,0 -0.39687,0.12756 -0.39687,0.28348 
					0,0.46691 -0.71825,1.03943 -1.30402,1.03943 -0.30144,0 -0.54807,0.11906 
					-0.54807,0.26459 0,0.14552 -0.23812,0.26458 -0.52916,0.26458 -0.29399,0 
					-0.52917,0.17639 -0.52917,0.39687 0,0.31971 -0.41158,0.39688 -2.11667,0.39688 
					-1.70509,0 -2.11666,-0.0772 -2.11666,-0.39688 z m 52.65208,-1.85208 c 0,-0.29986 
					-0.32338,-0.39687 -1.32292,-0.39687 -0.7276,0 -1.32291,-0.11907 
					-1.32291,-0.26459 0,-0.14552 -0.41672,-0.26458 -0.92604,-0.26458 -0.50933,0 
					-0.92605,-0.11906 -0.92605,-0.26458 0,-0.14553 -0.23812,-0.26459 
					-0.52916,-0.26459 -0.29398,0 -0.52917,-0.17639 -0.52917,-0.39687 0,-0.27719 
					-0.27928,-0.39688 -0.92604,-0.39688 -0.50932,0 -0.92604,-0.11906 
					-0.92604,-0.26458 0,-0.14552 -0.29766,-0.26458 -0.66146,-0.26458 -0.3638,0 
					-0.66146,-0.11907 -0.66146,-0.26459 0,-0.14552 -0.23812,-0.26458 
					-0.52917,-0.26458 -0.29398,0 -0.52916,-0.17639 -0.52916,-0.39688 0,-0.21828 
					-0.1786,-0.39687 -0.39688,-0.39687 -0.21828,0 -0.39687,-0.11906 
					-0.39687,-0.26458 0,-0.14553 -0.23813,-0.26459 -0.52917,-0.26459 -0.29104,0 
					-0.52917,-0.11906 -0.52917,-0.26458 0,-0.14552 -0.17859,-0.26458 
					-0.39687,-0.26458 -0.21828,0 -0.39688,-0.1786 -0.39688,-0.39688 0,-0.22048 
					-0.23518,-0.39687 -0.52916,-0.39687 -0.29104,0 -0.52917,-0.11907 
					-0.52917,-0.26459 0,-0.14552 -0.17859,-0.26458 -0.39687,-0.26458 -0.21829,0 
					-0.39688,-0.11906 -0.39688,-0.26458 0,-0.14553 -0.23812,-0.26459 
					-0.52917,-0.26459 -0.29104,0 -0.52916,-0.11906 -0.52916,-0.26458 0,-0.14552 
					-0.1786,-0.26458 -0.39688,-0.26458 -0.21828,0 -0.39687,-0.13083 
					-0.39687,-0.29073 0,-0.15989 -0.35719,-0.62438 -0.79375,-1.03219 
					-0.43657,-0.40781 -0.79375,-0.8723 -0.79375,-1.0322 0,-0.15989 -0.1786,-0.29072 
					-0.39688,-0.29072 -0.22048,0 -0.39687,-0.23518 -0.39687,-0.52916 0,-0.29105 
					-0.11907,-0.52917 -0.26459,-0.52917 -0.14699,0 -0.26458,-0.70556 
					-0.26458,-1.5875 0,-0.88194 0.11759,-1.5875 0.26458,-1.5875 0.14552,0 
					0.26459,-0.29766 0.26459,-0.66146 0,-0.3638 0.13082,-0.66146 0.29072,-0.66146 
					0.15989,0 0.62438,-0.35718 1.03219,-0.79375 0.40781,-0.43656 0.99137,-0.79375 
					1.29678,-0.79375 0.31722,0 0.55531,-0.17016 0.55531,-0.39687 0,-0.29398 
					0.30868,-0.39688 1.19062,-0.39688 0.65485,0 1.19063,-0.11906 1.19063,-0.26458 
					0,-0.14552 0.41672,-0.26458 0.92604,-0.26458 0.50932,0 0.92604,0.11906 
					0.92604,0.26458 0,0.14699 0.70556,0.26458 1.5875,0.26458 1.23472,0 1.5875,0.0882 
					1.5875,0.39688 0,0.27718 0.27928,0.39687 0.92604,0.39687 0.50932,0 
					0.92604,0.11907 0.92604,0.26459 0,0.14552 0.35719,0.26458 0.79375,0.26458 
					0.43657,0 0.79375,0.11906 0.79375,0.26458 0,0.14552 0.29766,0.26459 
					0.66146,0.26459 0.3638,0 0.66146,0.11906 0.66146,0.26458 0,0.14552 
					0.41672,0.26458 0.92604,0.26458 0.50932,0 0.92604,0.12757 0.92604,0.28349 
					0,0.46691 0.71825,1.03943 1.30402,1.03943 0.30144,0 0.54807,0.11906 
					0.54807,0.26458 0,0.14552 0.29765,0.26459 0.66146,0.26459 0.3638,0 
					0.66145,0.12447 0.66145,0.2766 0,0.48214 1.27696,1.57548 1.84006,1.57548 
					0.29766,0 0.54119,0.12447 0.54119,0.27661 0,0.15213 0.29225,0.56885 
					0.64944,0.92604 0.35718,0.35719 0.7739,0.64943 0.92604,0.64943 0.15213,0 
					0.27661,0.12448 0.27661,0.27661 0,0.35916 1.21632,1.57548 1.57547,1.57548 
					0.15214,0 0.27661,0.29765 0.27661,0.66145 0,0.36381 0.11906,0.66146 
					0.26458,0.66146 0.15434,0 0.26459,0.88195 0.26459,2.11667 0,1.23472 
					-0.11025,2.11667 -0.26459,2.11667 -0.14552,0 -0.26458,0.29765 -0.26458,0.66145 
					0,0.41158 -0.14993,0.66146 -0.39687,0.66146 -0.21829,0 -0.39688,0.11906 
					-0.39688,0.26459 0,0.14552 -0.23812,0.26458 -0.52917,0.26458 -0.29104,0 
					-0.52916,0.11906 -0.52916,0.26458 0,0.14552 -0.29766,0.26459 -0.66146,0.26459 
					-0.41158,0 -0.66146,0.14993 -0.66146,0.39687 0,0.32338 -0.44097,0.39688 
					-2.38125,0.39688 -1.94028,0 -2.38125,-0.0735 -2.38125,-0.39688 z M 
					42.122728,239.87159 c 0,-0.14552 -0.535782,-0.26459 -1.190625,-0.26459 
					-0.881944,0 -1.190625,-0.10289 -1.190625,-0.39687 0,-0.2367 -0.243396,-0.39688 
					-0.603049,-0.39688 -0.331677,0 -0.774679,-0.23812 -0.984451,-0.52916 
					-0.209773,-0.29105 -0.652775,-0.52917 -0.984451,-0.52917 -0.331677,0 
					-0.603049,-0.13083 -0.603049,-0.29072 0,-0.1599 -0.357188,-0.62439 
					-0.79375,-1.0322 -0.436563,-0.40781 -0.79375,-0.99136 -0.79375,-1.29678 
					0,-0.30541 -0.127567,-0.5553 -0.283483,-0.5553 -0.466915,0 -1.039434,-0.71825 
					-1.039434,-1.30402 0,-0.30143 -0.119063,-0.54806 -0.264583,-0.54806 -0.145521,0 
					-0.264584,-0.23813 -0.264584,-0.52917 0,-0.29398 -0.17639,-0.52917 
					-0.396875,-0.52917 -0.277183,0 -0.396875,-0.27928 -0.396875,-0.92604 0,-0.50932 
					-0.119062,-0.92604 -0.264583,-0.92604 -0.145521,0 -0.264584,-0.41672 
					-0.264584,-0.92604 0,-0.50932 -0.119062,-0.92604 -0.264583,-0.92604 -0.151191,0 
					-0.264583,-0.79375 -0.264583,-1.85209 0,-1.4699 -0.08189,-1.85208 
					-0.396875,-1.85208 -0.337659,0 -0.396875,-0.69086 -0.396875,-4.63021 0,-3.93935 
					0.05922,-4.63021 0.396875,-4.63021 0.319704,0 0.396875,-0.41157 
					0.396875,-2.11666 0,-1.23473 0.110244,-2.11667 0.264583,-2.11667 0.145521,0 
					0.264583,-0.53578 0.264583,-1.19062 0,-0.65485 0.119063,-1.19063 
					0.264584,-1.19063 0.145521,0 0.264583,-0.59531 0.264583,-1.32292 0,-0.99953 
					0.09702,-1.32291 0.396875,-1.32291 0.277183,0 0.396875,-0.27929 
					0.396875,-0.92604 0,-0.50933 0.119063,-0.92605 0.264584,-0.92605 0.14552,0 
					0.264583,-0.23812 0.264583,-0.52916 0,-0.29104 0.119062,-0.52917 
					0.264583,-0.52917 0.145521,0 0.264584,-0.29765 0.264584,-0.66146 0,-0.41157 
					0.149931,-0.66146 0.396875,-0.66146 0.220485,0 0.396875,-0.23518 
					0.396875,-0.52916 0,-0.29104 0.119062,-0.52917 0.264583,-0.52917 0.145521,0 
					0.264583,-0.29765 0.264583,-0.66146 0,-0.3638 0.119063,-0.66146 
					0.264584,-0.66146 0.14552,0 0.264583,-0.24662 0.264583,-0.54806 0,-0.58577 
					0.572519,-1.30402 1.039434,-1.30402 0.155916,0 0.283483,-0.24353 
					0.283483,-0.54119 0,-0.56311 1.093337,-1.84006 1.575474,-1.84006 0.152136,0 
					0.276609,-0.12447 0.276609,-0.27661 0,-0.35915 1.216319,-1.57547 
					1.575475,-1.57547 0.152135,0 0.276608,-0.12447 0.276608,-0.27661 0,-0.35916 
					1.216319,-1.57547 1.575475,-1.57547 0.152136,0 0.276609,-0.12757 
					0.276609,-0.28349 0,-0.44791 0.708845,-1.03943 1.245608,-1.03943 0.269311,0 
					0.661286,-0.23813 0.871058,-0.52917 0.209773,-0.29104 0.652775,-0.52916 
					0.984452,-0.52916 0.359653,0 0.603048,-0.16019 0.603048,-0.39688 0,-0.22048 
					0.235186,-0.39687 0.529167,-0.39687 0.291042,0 0.529167,-0.11907 
					0.529167,-0.26459 0,-0.14552 0.416719,-0.26458 0.926042,-0.26458 0.509322,0 
					0.926041,-0.11906 0.926041,-0.26458 0,-0.14553 0.416719,-0.26459 
					0.926042,-0.26459 0.646758,0 0.926042,-0.11969 0.926042,-0.39687 0,-0.31971 
					0.411575,-0.39688 2.116666,-0.39688 1.234723,0 2.116667,-0.11024 
					2.116667,-0.26458 0,-0.14552 0.297656,-0.26458 0.661458,-0.26458 0.363802,0 
					0.661459,0.11906 0.661459,0.26458 0,0.15434 0.881943,0.26458 2.116666,0.26458 
					1.705092,0 2.116667,0.0772 2.116667,0.39688 0,0.29398 0.308682,0.39687 
					1.190625,0.39687 0.654844,0 1.190625,0.11906 1.190625,0.26459 0,0.14552 
					0.416719,0.26458 0.926042,0.26458 0.509323,0 0.926042,0.11906 0.926042,0.26458 
					0,0.14552 0.297656,0.26459 0.661458,0.26459 0.411575,0 0.661458,0.14993 
					0.661458,0.39687 0,0.24694 0.249883,0.39688 0.661459,0.39688 0.363802,0 
					0.661458,0.11906 0.661458,0.26458 0,0.14552 0.211841,0.26458 0.470757,0.26458 
					0.258916,0 0.642387,0.23813 0.85216,0.52917 0.209772,0.29104 0.652774,0.52917 
					0.984451,0.52917 0.331676,0 0.603049,0.12756 0.603049,0.28348 0,0.30835 
					0.651325,1.03943 0.926041,1.03943 0.220766,0 0.926042,0.70528 0.926042,0.92604 
					0,0.22077 0.705276,0.92605 0.926042,0.92605 0.220765,0 0.926041,0.70527 
					0.926041,0.92604 0,0.22076 0.705276,0.92604 0.926042,0.92604 0.220766,0 
					0.926042,0.70527 0.926042,0.92604 0,0.27472 0.731078,0.92604 1.039434,0.92604 
					0.155916,0 0.283483,0.27137 0.283483,0.60305 0,0.33168 0.238125,0.77468 
					0.529166,0.98445 0.291042,0.20977 0.529167,0.65278 0.529167,0.98445 0,0.35966 
					0.160181,0.60305 0.396875,0.60305 0.220485,0 0.396875,0.23519 0.396875,0.52917 
					0,0.29104 0.119062,0.52916 0.264583,0.52916 0.145521,0 0.264584,0.29766 
					0.264584,0.66146 0,0.36381 0.119062,0.66146 0.264583,0.66146 0.145521,0 
					0.264583,0.23813 0.264583,0.52917 0,0.29104 0.119063,0.52916 0.264584,0.52916 
					0.14552,0 0.264583,0.29766 0.264583,0.66146 0,0.41158 0.149931,0.66146 
					0.396875,0.66146 0.246944,0 0.396875,0.24988 0.396875,0.66146 0,0.3638 
					0.119062,0.66146 0.264583,0.66146 0.145521,0 0.264584,0.41672 0.264584,0.92604 
					0,0.50932 0.119062,0.92604 0.264583,0.92604 0.145521,0 0.264583,0.41672 
					0.264583,0.92604 0,0.64676 0.119693,0.92604 0.396875,0.92604 0.277183,0 
					0.396875,0.27929 0.396875,0.92605 0,0.50932 0.119063,0.92604 0.264584,0.92604 
					0.145521,0 0.264583,0.65484 0.264583,1.45521 0,0.80036 0.119063,1.4552 
					0.264583,1.4552 0.146992,0 0.264584,0.70556 0.264584,1.5875 0,1.23473 
					0.08819,1.5875 0.396875,1.5875 0.323379,0 0.396875,0.44098 0.396875,2.38125 
					0,1.41111 0.107794,2.38125 0.264583,2.38125 0.151191,0 0.264583,0.79375 
					0.264583,1.85209 0,1.05833 -0.113392,1.85208 -0.264583,1.85208 -0.151191,0 
					-0.264583,0.79375 -0.264583,1.85208 0,1.46991 -0.08189,1.85209 -0.396875,1.85209 
					-0.277183,0 -0.396875,0.27928 -0.396875,0.92604 0,0.50932 -0.119063,0.92604 
					-0.264584,0.92604 -0.14552,0 -0.264583,0.29766 -0.264583,0.66146 0,0.3638 
					-0.119062,0.66146 -0.264583,0.66146 -0.145521,0 -0.264584,0.23812 
					-0.264584,0.52916 0,0.29104 -0.127566,0.52917 -0.283482,0.52917 -0.428556,0 
					-1.039434,0.69973 -1.039434,1.19062 0,0.60045 -0.646385,1.19063 
					-1.304018,1.19063 -0.301437,0 -0.548066,0.12757 -0.548066,0.28348 0,0.46692 
					-0.718248,1.03944 -1.304017,1.03944 -0.301437,0 -0.548066,0.11906 
					-0.548066,0.26458 0,0.14552 -0.654844,0.26458 -1.455208,0.26458 -1.11713,0 
					-1.455209,0.0922 -1.455209,0.39688 0,0.21828 -0.178594,0.39687 -0.396875,0.39687 
					-0.218281,0 -0.396875,-0.17859 -0.396875,-0.39687 0,-0.30468 -0.338079,-0.39688 
					-1.455208,-0.39688 -0.800365,0 -1.455209,-0.11906 -1.455209,-0.26458 0,-0.14552 
					-0.297656,-0.26458 -0.661458,-0.26458 -0.363802,0 -0.661458,-0.11907 
					-0.661458,-0.26459 0,-0.14552 -0.297657,-0.26458 -0.661459,-0.26458 -0.363802,0 
					-0.661458,-0.13083 -0.661458,-0.29072 0,-0.1599 -0.357188,-0.62439 
					-0.79375,-1.0322 -0.436563,-0.40781 -0.79375,-0.8723 -0.79375,-1.03219 0,-0.1599 
					-0.124473,-0.29072 -0.276609,-0.29072 -0.482137,0 -1.575474,-1.27696 
					-1.575474,-1.84006 0,-0.30467 -0.173451,-0.54119 -0.396875,-0.54119 -0.220486,0 
					-0.396875,-0.23519 -0.396875,-0.52917 0,-0.29104 -0.119063,-0.52917 
					-0.264584,-0.52917 -0.145521,0 -0.264583,-0.29765 -0.264583,-0.66146 0,-0.3638 
					-0.119063,-0.66145 -0.264583,-0.66145 -0.145521,0 -0.264584,-0.41672 
					-0.264584,-0.92604 0,-0.64676 -0.119692,-0.92605 -0.396875,-0.92605 -0.293981,0 
					-0.396875,-0.30868 -0.396875,-1.19062 0,-0.65485 -0.119062,-1.19063 
					-0.264583,-1.19063 -0.145521,0 -0.264583,-0.59531 -0.264583,-1.32291 0,-0.72761 
					-0.119063,-1.32292 -0.264584,-1.32292 -0.145521,0 -0.264583,-0.53578 
					-0.264583,-1.19062 0,-0.88195 -0.102894,-1.19063 -0.396875,-1.19063 -0.277183,0 
					-0.396875,-0.27928 -0.396875,-0.92604 0,-0.50932 -0.119063,-0.92604 
					-0.264584,-0.92604 -0.14552,0 -0.264583,-0.23813 -0.264583,-0.52917 0,-0.29104 
					-0.119062,-0.52917 -0.264583,-0.52917 -0.145521,0 -0.264584,-0.17859 
					-0.264584,-0.39687 0,-0.21828 -0.178593,-0.39688 -0.396875,-0.39688 -0.220485,0 
					-0.396875,-0.23518 -0.396875,-0.52916 0,-0.29104 -0.119062,-0.52917 
					-0.264583,-0.52917 -0.145521,0 -0.264583,-0.12757 -0.264583,-0.28348 0,-0.36631 
					-0.673124,-1.03944 -1.039434,-1.03944 -0.155917,0 -0.283483,-0.11906 
					-0.283483,-0.26458 0,-0.14552 -0.238125,-0.26458 -0.529167,-0.26458 -0.293981,0 
					-0.529166,-0.17639 -0.529166,-0.39688 0,-0.29398 -0.308682,-0.39687 
					-1.190625,-0.39687 -0.881944,0 -1.190625,0.10289 -1.190625,0.39687 0,0.21828 
					-0.124474,0.39688 -0.276609,0.39688 -0.482137,0 -1.575475,1.27695 
					-1.575475,1.84006 0,0.30467 -0.17345,0.54119 -0.396875,0.54119 -0.343958,0 
					-0.396875,1.05833 -0.396875,7.9375 0,5.11527 -0.09408,7.9375 -0.264583,7.9375 
					-0.145521,0 -0.264583,0.53578 -0.264583,1.19062 0,0.65485 -0.119063,1.19063 
					-0.264584,1.19063 -0.145521,0 -0.264583,0.29765 -0.264583,0.66145 0,0.36381 
					-0.127566,0.66146 -0.283483,0.66146 -0.308356,0 -1.039434,0.65133 
					-1.039434,0.92604 0,0.27472 -0.731078,0.92605 -1.039434,0.92605 -0.155916,0 
					-0.283482,0.11906 -0.283482,0.26458 0,0.14552 -0.238125,0.26458 
					-0.529167,0.26458 -0.293981,0 -0.529167,0.17639 -0.529167,0.39688 0,0.30868 
					-0.352777,0.39687 -1.5875,0.39687 -0.881943,0 -1.5875,0.11759 -1.5875,0.26459 
					0,0.14552 -0.416719,0.26458 -0.926042,0.26458 -0.509323,0 -0.926041,-0.11906 
					-0.926041,-0.26458 z M 241.88315,193.30492 c 0,-0.14552 -0.41672,-0.26458 
					-0.92604,-0.26458 -0.64676,0 -0.92604,-0.11969 -0.92604,-0.39688 0,-0.22342 
					-0.23653,-0.39687 -0.5412,-0.39687 -0.5631,0 -1.84005,-1.09334 -1.84005,-1.57548 
					0,-0.15213 -0.12448,-0.2766 -0.27661,-0.2766 -0.48214,0 -1.57548,-1.27696 
					-1.57548,-1.84006 0,-0.30467 -0.17345,-0.54119 -0.39687,-0.54119 -0.22049,0 
					-0.39688,-0.23519 -0.39688,-0.52917 0,-0.29104 -0.11906,-0.52917 
					-0.26458,-0.52917 -0.14552,0 -0.26458,-0.29765 -0.26458,-0.66146 0,-0.3638 
					-0.11907,-0.66145 -0.26459,-0.66145 -0.14552,0 -0.26458,-0.41672 
					-0.26458,-0.92605 0,-0.50932 -0.11906,-0.92604 -0.26458,-0.92604 -0.14553,0 
					-0.26459,-0.41672 -0.26459,-0.92604 0,-0.64676 -0.11969,-0.92604 
					-0.39687,-0.92604 -0.27719,0 -0.39688,-0.27928 -0.39688,-0.92604 0,-0.50933 
					-0.11906,-0.92604 -0.26458,-0.92604 -0.15119,0 -0.26458,-0.79375 
					-0.26458,-1.85209 0,-1.05833 -0.1134,-1.85208 -0.26459,-1.85208 -0.16105,0 
					-0.26458,-1.19063 -0.26458,-3.04271 0,-1.85208 0.10353,-3.04271 0.26458,-3.04271 
					0.15119,0 0.26459,-0.79375 0.26459,-1.85208 0,-1.05833 0.11339,-1.85208 
					0.26458,-1.85208 0.14552,0 0.26458,-0.41672 0.26458,-0.92605 0,-0.64675 
					0.11969,-0.92604 0.39688,-0.92604 0.22048,0 0.39687,-0.23518 0.39687,-0.52916 
					0,-0.29105 0.11906,-0.52917 0.26459,-0.52917 0.14552,0 0.26458,-0.27137 
					0.26458,-0.60305 0,-0.33168 0.23812,-0.77468 0.52917,-0.98445 0.29104,-0.20977 
					0.52916,-0.53371 0.52916,-0.71987 0,-0.21043 0.35031,-0.33846 0.92604,-0.33846 
					0.50933,0 0.92605,-0.11907 0.92605,-0.26459 0,-0.14552 0.59531,-0.26458 
					1.32291,-0.26458 0.72761,0 1.32292,0.11906 1.32292,0.26458 0,0.14552 
					0.41672,0.26459 0.92604,0.26459 0.50932,0 0.92604,0.12756 0.92604,0.28348 
					0,0.42856 0.69974,1.03943 1.19063,1.03943 0.42774,0 1.19062,0.59335 
					1.19062,0.92604 0,0.22077 0.70528,0.92605 0.92604,0.92605 0.35073,0 
					0.92605,0.77384 0.92605,1.2456 0,0.26932 0.23812,0.66129 0.52916,0.87106 
					0.29104,0.20978 0.52917,0.65278 0.52917,0.98445 0,0.33168 0.12757,0.60305 
					0.28348,0.60305 0.42856,0 1.03944,0.69974 1.03944,1.19063 0,0.49089 
					0.61087,1.19062 1.03943,1.19062 0.15592,0 0.28348,0.24354 0.28348,0.54119 
					0,0.56311 1.09334,1.84006 1.57548,1.84006 0.15213,0 0.27661,0.12757 
					0.27661,0.28349 0,0.5892 0.79403,1.03943 1.83318,1.03943 0.59248,0 
					1.07723,-0.11906 1.07723,-0.26458 0,-0.14552 0.1786,-0.26459 0.39688,-0.26459 
					0.24694,0 0.39687,-0.24988 0.39687,-0.66146 0,-0.3638 0.11906,-0.66145 
					0.26459,-0.66145 0.14552,0 0.26458,-0.29766 0.26458,-0.66146 0,-0.3638 
					0.11906,-0.66146 0.26458,-0.66146 0.14552,0 0.26459,-0.53578 0.26459,-1.19063 
					0,-0.88194 0.10289,-1.19062 0.39687,-1.19062 0.27718,0 0.39688,-0.27928 
					0.39688,-0.92604 0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 0.14552,0 
					0.26458,-0.41672 0.26458,-0.92605 0,-0.50932 0.11907,-0.92604 0.26459,-0.92604 
					0.14552,0 0.26458,-0.41672 0.26458,-0.92604 0,-0.50932 0.11906,-0.92604 
					0.26458,-0.92604 0.14552,0 0.26459,-0.23813 0.26459,-0.52917 0,-0.29398 
					0.17639,-0.52916 0.39687,-0.52916 0.21828,0 0.39688,-0.1786 0.39688,-0.39688 
					0,-0.30467 0.33808,-0.39687 1.4552,-0.39687 1.11713,0 1.45521,0.0922 
					1.45521,0.39687 0,0.2367 0.2434,0.39688 0.60305,0.39688 0.33168,0 
					0.77468,0.23812 0.98445,0.52916 0.20977,0.29104 0.53371,0.52917 0.71987,0.52917 
					0.18616,0 0.33847,0.27137 0.33847,0.60305 0,0.33168 0.23812,0.77468 
					0.52916,0.98445 0.29104,0.20977 0.52917,0.65277 0.52917,0.98445 0,0.35965 
					0.16018,0.60305 0.39687,0.60305 0.24694,0 0.39688,0.24988 0.39688,0.66146 
					0,0.3638 0.11906,0.66146 0.26458,0.66146 0.14552,0 0.26459,0.23812 
					0.26459,0.52916 0,0.29104 0.11906,0.52917 0.26458,0.52917 0.14552,0 
					0.26458,0.29766 0.26458,0.66146 0,0.57956 0.11465,0.66146 0.92604,0.66146 
					0.64675,0 0.92605,-0.1197 0.92605,-0.39688 0,-0.21828 0.11906,-0.39687 
					0.26458,-0.39687 0.14552,0 0.26458,-0.23813 0.26458,-0.52917 0,-0.29398 
					0.1764,-0.52917 0.39688,-0.52917 0.24693,0 0.39687,-0.24988 0.39687,-0.66146 
					0,-0.3638 0.11907,-0.66145 0.26459,-0.66145 0.14552,0 0.26458,-0.41672 
					0.26458,-0.92605 0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 0.14552,0 
					0.26459,-0.53578 0.26459,-1.19062 0,-0.88195 0.10289,-1.19063 0.39687,-1.19063 
					0.29398,0 0.39688,-0.30868 0.39688,-1.19062 0,-0.65485 0.11906,-1.19063 
					0.26458,-1.19063 0.14552,0 0.26458,-0.24663 0.26458,-0.54806 0,-0.58577 
					0.57251,-1.30402 1.03944,-1.30402 0.15592,0 0.28348,-0.12447 0.28348,-0.27661 
					0,-0.59399 1.31114,-1.57547 2.10463,-1.57547 0.44317,0 0.80579,-0.11907 
					0.80579,-0.26459 0,-0.14552 0.29765,-0.26458 0.66145,-0.26458 0.36381,0 
					0.66146,0.11906 0.66146,0.26458 0,0.14552 0.41672,0.26459 0.92604,0.26459 
					0.50933,0 0.92605,0.11906 0.92605,0.26458 0,0.14552 0.23812,0.26458 
					0.52916,0.26458 0.29104,0 0.52917,0.11907 0.52917,0.26459 0,0.14552 
					0.17859,0.26458 0.39687,0.26458 0.21829,0 0.39688,0.12447 0.39688,0.27661 
					0,0.32245 1.19978,1.57523 1.50932,1.57598 0.46206,0.001 2.02451,2.72382 
					2.90546,5.06305 2.08913,5.54729 2.00211,5.16578 1.8323,8.03332 -0.0862,1.45521 
					-0.26011,5.3995 -0.38653,8.76508 -0.26906,7.16318 -0.43971,7.78018 
					-2.42097,8.75266 -1.49084,0.73177 -2.1599,0.75125 -2.64583,0.0771 
					-0.20976,-0.29104 -0.65278,-0.52917 -0.98446,-0.52917 -0.45128,0 
					-0.60304,-0.15176 -0.60304,-0.60305 0,-0.33167 -0.23813,-0.77468 
					-0.52917,-0.98445 -0.29104,-0.20977 -0.52916,-0.65277 -0.52916,-0.98445 
					0,-0.35965 -0.16018,-0.60305 -0.39688,-0.60305 -0.22048,0 -0.39687,-0.23518 
					-0.39687,-0.52916 0,-0.29105 -0.11907,-0.52917 -0.26459,-0.52917 -0.14552,0 
					-0.26458,-0.41672 -0.26458,-0.92604 0,-0.50933 -0.11906,-0.92604 
					-0.26458,-0.92604 -0.14552,0 -0.26459,-0.59532 -0.26459,-1.32292 0,-0.72761 
					-0.11906,-1.32292 -0.26458,-1.32292 -0.14925,0 -0.26458,-0.74965 
					-0.26458,-1.71979 0,-1.35231 -0.0848,-1.71979 -0.39688,-1.71979 -0.22505,0 
					-0.39687,-0.23729 -0.39687,-0.54807 0,-0.65763 -0.59018,-1.30401 
					-1.19063,-1.30401 -0.60044,0 -1.19062,0.64638 -1.19062,1.30401 0,0.30144 
					-0.11907,0.54807 -0.26459,0.54807 -0.14552,0 -0.26458,0.41672 -0.26458,0.92604 
					0,0.50932 -0.13084,0.92604 -0.29072,0.92604 -0.15989,0 -0.62439,0.35719 
					-1.0322,0.79375 -0.4078,0.43656 -0.99136,0.79375 -1.29677,0.79375 -0.31721,0 
					-0.55531,0.17016 -0.55531,0.39688 0,0.24694 -0.24987,0.39687 -0.66146,0.39687 
					-0.41158,0 -0.66146,-0.14993 -0.66146,-0.39687 0,-0.26555 -0.26556,-0.39688 
					-0.80256,-0.39688 -0.56298,0 -1.0764,-0.27382 -1.71979,-0.91722 
					-0.50448,-0.50447 -0.91723,-1.04025 -0.91723,-1.19063 0,-0.15037 
					-0.12449,-0.2734 -0.27662,-0.2734 -0.35915,0 -1.57546,-1.21632 -1.57546,-1.57547 
					0,-0.15214 -0.41672,-0.27661 -0.92605,-0.27661 h -0.92604 v 1.5875 c 0,0.88194 
					0.11759,1.5875 0.26459,1.5875 0.14552,0 0.26458,0.65484 0.26458,1.45521 
					0,0.80036 -0.11906,1.45521 -0.26458,1.45521 -0.14552,0 -0.26459,0.29765 
					-0.26459,0.66145 0,0.36381 -0.12756,0.66146 -0.28348,0.66146 -0.36631,0 
					-1.03943,0.67313 -1.03943,1.03944 0,0.15591 -0.41672,0.28348 -0.92605,0.28348 
					-0.50932,0 -0.92604,0.11906 -0.92604,0.26458 0,0.14552 -0.41672,0.26459 
					-0.92604,0.26459 -0.50932,0 -0.92604,-0.11907 -0.92604,-0.26459 0,-0.14552 
					-0.41672,-0.26458 -0.92604,-0.26458 -0.64676,0 -0.92604,-0.11969 
					-0.92604,-0.39688 0,-0.22048 -0.23519,-0.39687 -0.52917,-0.39687 -0.29104,0 
					-0.52917,-0.11906 -0.52917,-0.26458 0,-0.14553 -0.17859,-0.26459 
					-0.39687,-0.26459 -0.21828,0 -0.39688,-0.11906 -0.39688,-0.26458 0,-0.14552 
					-0.23812,-0.26458 -0.52916,-0.26458 -0.29399,0 -0.52917,-0.17639 
					-0.52917,-0.39688 0,-0.21828 -0.12447,-0.39687 -0.27661,-0.39687 -0.15213,0 
					-0.56885,-0.29225 -0.92604,-0.64944 -0.35719,-0.35718 -0.64943,-0.7739 
					-0.64943,-0.92604 0,-0.15213 -0.27138,-0.27661 -0.60305,-0.27661 -0.33168,0 
					-0.77468,-0.23812 -0.98445,-0.52916 -0.20978,-0.29105 -0.53372,-0.52917 
					-0.71987,-0.52917 -0.18616,0 -0.33847,-0.11906 -0.33847,-0.26458 0,-0.14552 
					-0.23812,-0.26459 -0.52916,-0.26459 -0.29399,0 -0.52917,-0.17639 
					-0.52917,-0.39687 0,-0.27719 -0.27928,-0.39688 -0.92604,-0.39688 -0.64676,0 
					-0.92604,0.11969 -0.92604,0.39688 0,0.21828 -0.11907,0.39687 -0.26459,0.39687 
					-0.14552,0 -0.26458,0.23813 -0.26458,0.52917 0,0.29104 0.11906,0.52917 
					0.26458,0.52917 0.14552,0 0.26459,0.27137 0.26459,0.60304 0,0.33168 
					0.23812,0.77468 0.52916,0.98446 0.29104,0.20977 0.52917,0.65277 0.52917,0.98445 
					0,0.35965 0.16018,0.60305 0.39687,0.60305 0.27719,0 0.39688,0.27928 
					0.39688,0.92604 0,0.50932 0.11906,0.92604 0.26458,0.92604 0.15119,0 
					0.26459,0.79375 0.26459,1.85208 0,1.05834 -0.1134,1.85209 -0.26459,1.85209 
					-0.14552,0 -0.26458,0.35718 -0.26458,0.79375 0,0.52916 -0.13229,0.79375 
					-0.39688,0.79375 -0.23669,0 -0.39687,0.24339 -0.39687,0.60304 0,0.33168 
					-0.23813,0.77468 -0.52917,0.98446 -0.29104,0.20977 -0.52916,0.53371 
					-0.52916,0.71986 0,0.21043 -0.35031,0.33847 -0.92605,0.33847 -0.50932,0 
					-0.92604,0.11906 -0.92604,0.26458 0,0.14552 -0.41672,0.26459 -0.92604,0.26459 
					-0.50932,0 -0.92604,-0.11907 -0.92604,-0.26459 z m -114.56459,-3.70416 c 
					0,-0.14553 -0.36569,-0.26459 -0.81265,-0.26459 -0.81067,0 -1.5686,-0.50224 
					-1.5686,-1.03943 0,-0.15592 -0.24663,-0.28348 -0.54806,-0.28348 -0.65764,0 
					-1.30402,-0.59018 -1.30402,-1.19063 0,-0.49089 -0.61088,-1.19062 
					-1.03943,-1.19062 -0.15592,0 -0.28349,-0.29766 -0.28349,-0.66146 0,-0.3638 
					-0.11906,-0.66146 -0.26458,-0.66146 -0.14552,0 -0.26458,-0.53578 
					-0.26458,-1.19063 0,-0.65484 -0.11907,-1.19062 -0.26459,-1.19062 -0.16799,0 
					-0.26458,-2.02847 -0.26458,-5.55625 0,-3.52778 0.0966,-5.55625 0.26458,-5.55625 
					0.15434,0 0.26459,-0.88194 0.26459,-2.11667 0,-1.23472 0.11024,-2.11666 
					0.26458,-2.11666 0.14552,0 0.26458,-0.53578 0.26458,-1.19063 0,-0.88194 
					0.1029,-1.19062 0.39688,-1.19062 0.29398,0 0.39687,-0.30868 0.39687,-1.19063 
					0,-0.65484 0.11907,-1.19062 0.26459,-1.19062 0.14552,0 0.26458,-0.41672 
					0.26458,-0.92604 0,-0.50933 0.11906,-0.92605 0.26458,-0.92605 0.14552,0 
					0.26459,-0.41672 0.26459,-0.92604 0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 
					0.14552,0 0.26458,-0.41672 0.26458,-0.92604 0,-0.64676 0.1197,-0.92604 
					0.39688,-0.92604 0.27718,0 0.39687,-0.27929 0.39687,-0.92604 0,-0.50933 
					0.11907,-0.92605 0.26459,-0.92605 0.14552,0 0.26458,-0.41672 0.26458,-0.92604 
					0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 0.14552,0 0.26459,-0.29766 
					0.26459,-0.66146 0,-0.41157 0.14993,-0.66146 0.39687,-0.66146 0.22049,0 
					0.39688,-0.23518 0.39688,-0.52916 0,-0.29104 0.11906,-0.52917 0.26458,-0.52917 
					0.14552,0 0.26458,-0.24663 0.26458,-0.54807 0,-0.58576 0.57252,-1.30401 
					1.03944,-1.30401 0.15591,0 0.28348,-0.24663 0.28348,-0.54807 0,-0.70189 
					0.60213,-1.30402 1.30402,-1.30402 0.31078,0 0.54806,-0.17182 0.54806,-0.39687 
					0,-0.22049 0.23519,-0.39688 0.52917,-0.39688 0.29104,0 0.52917,-0.11906 
					0.52917,-0.26458 0,-0.15959 1.10243,-0.26458 2.77812,-0.26458 1.6757,0 
					2.77813,0.10499 2.77813,0.26458 0,0.14552 0.29765,0.26458 0.66145,0.26458 
					0.36381,0 0.66146,0.12757 0.66146,0.28349 0,0.42855 0.69974,1.03943 
					1.19063,1.03943 0.55868,0 1.19062,0.63194 1.19062,1.19063 0,0.49089 
					0.61088,1.19062 1.03944,1.19062 0.15591,0 0.28348,0.29766 0.28348,0.66146 
					0,0.3638 0.11906,0.66146 0.26458,0.66146 0.14552,0 0.26459,0.29765 
					0.26459,0.66146 0,0.3638 0.11906,0.66145 0.26458,0.66145 0.14552,0 
					0.26458,0.41672 0.26458,0.92605 0,0.64675 0.1197,0.92604 0.39688,0.92604 
					0.31207,0 0.39687,0.36747 0.39687,1.71979 0,0.97014 0.11534,1.71979 
					0.26459,1.71979 0.15564,0 0.26458,0.92604 0.26458,2.24896 0,1.32292 
					-0.10894,2.24896 -0.26458,2.24896 -0.15679,0 -0.26459,0.97014 -0.26459,2.38125 
					0,1.94027 -0.0735,2.38125 -0.39687,2.38125 -0.30868,0 -0.39688,0.35277 
					-0.39688,1.5875 0,0.88194 -0.11759,1.5875 -0.26458,1.5875 -0.14552,0 
					-0.26458,0.53578 -0.26458,1.19062 0,0.65484 -0.11907,1.19063 -0.26459,1.19063 
					-0.14552,0 -0.26458,0.41671 -0.26458,0.92604 0,0.64676 -0.11969,0.92604 
					-0.39688,0.92604 -0.29398,0 -0.39687,0.30868 -0.39687,1.19062 0,0.65485 
					-0.11906,1.19063 -0.26458,1.19063 -0.14552,0 -0.26459,0.29766 -0.26459,0.66146 
					0,0.3638 -0.11906,0.66146 -0.26458,0.66146 -0.14552,0 -0.26458,0.41671 
					-0.26458,0.92604 0,0.64676 -0.1197,0.92604 -0.39688,0.92604 -0.22048,0 
					-0.39687,0.23518 -0.39687,0.52917 0,0.29104 -0.11907,0.52916 -0.26459,0.52916 
					-0.14552,0 -0.26458,0.29766 -0.26458,0.66146 0,0.3638 -0.11906,0.66146 
					-0.26458,0.66146 -0.14552,0 -0.26459,0.29766 -0.26459,0.66146 0,0.41157 
					-0.14993,0.66146 -0.39687,0.66146 -0.22049,0 -0.39688,0.23518 -0.39688,0.52916 
					0,0.29104 -0.11906,0.52917 -0.26458,0.52917 -0.14552,0 -0.26458,0.17859 
					-0.26458,0.39687 0,0.21829 -0.11907,0.39688 -0.26459,0.39688 -0.14552,0 
					-0.26458,0.23812 -0.26458,0.52917 0,0.29398 -0.17639,0.52916 -0.39688,0.52916 
					-0.21828,0 -0.39687,0.13083 -0.39687,0.29072 0,0.1599 -0.35719,0.62439 
					-0.79375,1.0322 -0.43656,0.40781 -0.79375,0.8723 -0.79375,1.03219 0,0.1599 
					-0.29766,0.29073 -0.66146,0.29073 -0.3638,0 -0.66146,0.11906 -0.66146,0.26458 
					0,0.14552 -0.41672,0.26458 -0.92604,0.26458 -0.50932,0 -0.92604,0.11906 
					-0.92604,0.26459 0,0.15119 -0.79375,0.26458 -1.85208,0.26458 -1.05834,0 
					-1.85209,-0.11339 -1.85209,-0.26458 z M 53.764395,160.23201 c 0,-0.15959 
					-1.102432,-0.26459 -2.778125,-0.26459 -2.293057,0 -2.778125,-0.0693 
					-2.778125,-0.39687 0,-0.29398 -0.308682,-0.39688 -1.190626,-0.39688 -0.654843,0 
					-1.190625,-0.11906 -1.190625,-0.26458 0,-0.14552 -0.535781,-0.26458 
					-1.190625,-0.26458 -0.654843,0 -1.190625,-0.11907 -1.190625,-0.26459 0,-0.14552 
					-0.297656,-0.26458 -0.661458,-0.26458 -0.411575,0 -0.661458,-0.14993 
					-0.661458,-0.39687 0,-0.22049 -0.235186,-0.39688 -0.529167,-0.39688 -0.291042,0 
					-0.529167,-0.11906 -0.529167,-0.26458 0,-0.14552 -0.271372,-0.26459 
					-0.603049,-0.26459 -0.331676,0 -0.774678,-0.23812 -0.984451,-0.52916 
					-0.209772,-0.29104 -0.652774,-0.52917 -0.984451,-0.52917 -0.331676,0 
					-0.603049,-0.12757 -0.603049,-0.28348 0,-0.36631 -0.673124,-1.03944 
					-1.039434,-1.03944 -0.155916,0 -0.283482,-0.27137 -0.283482,-0.60305 0,-0.33167 
					-0.238125,-0.77467 -0.529167,-0.98445 -0.291042,-0.20977 -0.529167,-0.65277 
					-0.529167,-0.98445 0,-0.33167 -0.119062,-0.60305 -0.264583,-0.60305 -0.145521,0 
					-0.264583,-0.23812 -0.264583,-0.52916 0,-0.29398 -0.17639,-0.52917 
					-0.396875,-0.52917 -0.277183,0 -0.396875,-0.27928 -0.396875,-0.92604 0,-0.50932 
					-0.119063,-0.92604 -0.264584,-0.92604 -0.166854,0 -0.264583,-1.80799 
					-0.264583,-4.89479 0,-3.08681 0.09773,-4.8948 0.264583,-4.8948 0.145521,0 
					0.264584,-0.41671 0.264584,-0.92604 0,-0.64676 0.119692,-0.92604 
					0.396875,-0.92604 0.277182,0 0.396875,-0.27928 0.396875,-0.92604 0,-0.50932 
					0.119062,-0.92604 0.264583,-0.92604 0.145521,0 0.264583,-0.41672 
					0.264583,-0.92604 0,-0.50933 0.119063,-0.92605 0.264584,-0.92605 0.14552,0 
					0.264583,-0.29765 0.264583,-0.66145 0,-0.36381 0.119062,-0.66146 
					0.264583,-0.66146 0.145521,0 0.264584,-0.23813 0.264584,-0.52917 0,-0.29398 
					0.176389,-0.52917 0.396875,-0.52917 0.246943,0 0.396875,-0.24988 
					0.396875,-0.66145 0,-0.36381 0.119062,-0.66146 0.264583,-0.66146 0.145521,0 
					0.264583,-0.24663 0.264583,-0.54807 0,-0.58577 0.572519,-1.30401 
					1.039434,-1.30401 0.155917,0 0.283483,-0.29766 0.283483,-0.66146 0,-0.36381 
					0.119062,-0.66146 0.264583,-0.66146 0.145521,0 0.264584,-0.23813 
					0.264584,-0.52917 0,-0.29104 0.119062,-0.52916 0.264583,-0.52916 0.145521,0 
					0.264583,-0.1786 0.264583,-0.39688 0,-0.21828 0.127567,-0.39687 
					0.283483,-0.39687 0.421539,0 1.039434,-0.69654 1.039434,-1.17173 0,-0.22868 
					0.119063,-0.41577 0.264583,-0.41577 0.145521,0 0.264584,-0.1786 
					0.264584,-0.39688 0,-0.21828 0.124473,-0.39687 0.276608,-0.39687 0.359156,0 
					1.575475,-1.21632 1.575475,-1.57548 0,-0.15213 0.124473,-0.27661 
					0.276609,-0.27661 0.359156,0 1.575474,-1.21632 1.575474,-1.57547 0,-0.15214 
					0.124474,-0.27661 0.276609,-0.27661 0.295024,0 1.575475,-1.18829 
					1.575475,-1.46208 0,-0.26923 0.734981,-0.91917 1.039434,-0.91917 0.155916,0 
					0.283483,-0.24354 0.283483,-0.54119 0,-0.56311 1.093337,-1.84006 
					1.575474,-1.84006 0.152136,0 0.276609,-0.12447 0.276609,-0.27661 0,-0.29502 
					1.188291,-1.57547 1.46208,-1.57547 0.215521,0 0.91917,-0.70891 0.91917,-0.92605 
					0,-0.27471 0.731078,-0.92604 1.039434,-0.92604 0.155916,0 0.283483,-0.24353 
					0.283483,-0.54119 0,-0.5631 1.093337,-1.84006 1.575474,-1.84006 0.152136,0 
					0.276609,-0.17859 0.276609,-0.39687 0,-0.21828 0.119063,-0.39688 
					0.264583,-0.39688 0.145521,0 0.264584,-0.23812 0.264584,-0.52916 0,-0.29105 
					0.119062,-0.52917 0.264583,-0.52917 0.145521,0 0.264583,-0.29766 
					0.264583,-0.66146 0,-0.3638 0.119063,-0.66146 0.264584,-0.66146 0.145521,0 
					0.264583,-0.29765 0.264583,-0.66146 0,-0.41157 0.149932,-0.66145 
					0.396875,-0.66145 0.293981,0 0.396875,-0.30869 0.396875,-1.19063 0,-0.65484 
					0.119063,-1.19062 0.264583,-1.19062 0.145521,0 0.264584,-0.23813 
					0.264584,-0.52917 0,-0.291042 -0.119063,-0.529167 -0.264584,-0.529167 -0.14552,0 
					-0.264583,-0.416719 -0.264583,-0.926042 0,-0.646758 -0.119692,-0.926041 
					-0.396875,-0.926041 -0.236694,0 -0.396875,-0.243396 -0.396875,-0.603049 
					0,-0.331676 -0.238125,-0.774679 -0.529167,-0.984451 -0.291041,-0.209772 
					-0.529166,-0.533712 -0.529166,-0.719868 0,-0.186155 -0.271373,-0.338465 
					-0.603049,-0.338465 -0.331677,0 -0.774679,-0.238125 -0.984451,-0.529167 
					-0.247976,-0.344048 -0.684925,-0.529167 -1.249035,-0.529167 -0.594839,0 
					-0.867632,-0.124782 -0.867632,-0.396875 0,-0.293981 -0.308681,-0.396875 
					-1.190625,-0.396875 -0.654844,0 -1.190625,-0.119062 -1.190625,-0.264583 
					0,-0.154339 -0.881944,-0.264583 -2.116667,-0.264583 -1.234723,0 
					-2.116667,-0.110244 -2.116667,-0.264584 0,-0.155638 -0.926041,-0.264583 
					-2.248958,-0.264583 -1.822685,0 -2.248958,-0.07522 -2.248958,-0.396875 
					0,-0.314981 -0.382175,-0.396875 -1.852084,-0.396875 -1.058333,0 
					-1.852083,-0.113392 -1.852083,-0.264583 0,-0.15434 -0.881944,-0.264583 
					-2.116667,-0.264583 -1.234723,0 -2.116666,-0.110244 -2.116666,-0.264584 
					0,-0.154339 -0.881944,-0.264583 -2.116667,-0.264583 -1.234723,0 
					-2.116667,-0.110244 -2.116667,-0.264583 0,-0.151191 -0.79375,-0.264584 
					-1.852083,-0.264584 -1.469908,0 -1.852084,-0.08189 -1.852084,-0.396875 
					0,-0.277183 -0.279282,-0.396875 -0.926041,-0.396875 -0.509323,0 
					-0.926042,-0.119062 -0.926042,-0.264583 0,-0.145521 -0.238125,-0.264583 
					-0.529167,-0.264583 -0.291041,0 -0.529166,-0.119063 -0.529166,-0.264584 
					0,-0.145521 -0.178594,-0.264583 -0.396875,-0.264583 -0.218282,0 
					-0.396875,-0.15231 -0.396875,-0.338466 0,-0.186155 -0.238125,-0.510095 
					-0.529167,-0.719867 -0.291042,-0.209773 -0.529167,-0.652775 -0.529167,-0.984451 
					0,-0.359654 -0.160181,-0.603049 -0.396875,-0.603049 -0.220486,0 
					-0.396875,-0.235186 -0.396875,-0.529167 0,-0.291041 -0.119062,-0.529166 
					-0.264583,-0.529166 -0.157822,0 -0.264583,-1.014236 -0.264583,-2.513542 
					0,-1.499306 0.106761,-2.513542 0.264583,-2.513542 0.145521,0 0.264583,-0.416718 
					0.264583,-0.926041 0,-0.646758 0.119693,-0.926042 0.396875,-0.926042 0.220486,0 
					0.396875,-0.235185 0.396875,-0.529166 0,-0.291042 0.119063,-0.529167 
					0.264584,-0.529167 0.14552,0 0.264583,-0.297656 0.264583,-0.661458 0,-0.363802 
					0.119062,-0.661459 0.264583,-0.661459 0.145521,0 0.264584,-0.297656 
					0.264584,-0.661458 0,-0.363802 0.124474,-0.661458 0.27661,-0.661458 0.433382,0 
					1.575473,-1.251694 1.575473,-1.726663 0,-0.48518 0.613407,-1.183754 
					1.039434,-1.183754 0.155916,0 0.283483,-0.124473 0.283483,-0.276608 0,-0.295024 
					1.188291,-1.575475 1.462079,-1.575475 0.215522,0 0.919171,-0.708909 
					0.919171,-0.926042 0,-0.220765 0.705276,-0.926041 0.926041,-0.926041 0.220766,0 
					0.926042,-0.705276 0.926042,-0.926042 0,-0.220766 0.705276,-0.926042 
					0.926042,-0.926042 0.220765,0 0.926041,-0.705275 0.926041,-0.926041 0,-0.274717 
					0.731079,-0.926042 1.039435,-0.926042 0.155916,0 0.283482,-0.119062 
					0.283482,-0.264583 0,-0.145521 0.238125,-0.264583 0.529167,-0.264583 0.293981,0 
					0.529167,-0.17639 0.529167,-0.396875 0,-0.218282 0.178593,-0.396875 
					0.396875,-0.396875 0.218281,0 0.396875,-0.119063 0.396875,-0.264584 0,-0.145521 
					0.187097,-0.264583 0.415774,-0.264583 0.475189,0 1.171726,-0.617895 
					1.171726,-1.039434 0,-0.155916 0.124473,-0.283483 0.276608,-0.283483 0.152136,0 
					0.568854,-0.292245 0.926042,-0.649433 0.552611,-0.552608 0.649433,-0.898911 
					0.649433,-2.32284 0,-1.369886 -0.09598,-1.742586 -0.529167,-2.05481 
					-0.291041,-0.209772 -0.529166,-0.533712 -0.529166,-0.719867 0,-0.186156 
					-0.178594,-0.338466 -0.396875,-0.338466 -0.218282,0 -0.396875,-0.119062 
					-0.396875,-0.264583 0,-0.145521 -0.238125,-0.264584 -0.529167,-0.264584 
					-0.291042,0 -0.529167,-0.119062 -0.529167,-0.264583 0,-0.145521 
					-0.416719,-0.264583 -0.926041,-0.264583 -0.646759,0 -0.926042,-0.119692 
					-0.926042,-0.396875 0,-0.277183 -0.279284,-0.396875 -0.926042,-0.396875 
					-0.509323,0 -0.926042,-0.119063 -0.926042,-0.264584 0,-0.146991 
					-0.705556,-0.264583 -1.5875,-0.264583 -0.881943,0 -1.5875,-0.117591 
					-1.5875,-0.264583 0,-0.15679 -0.970139,-0.264584 -2.38125,-0.264584 -1.940277,0 
					-2.38125,-0.0735 -2.38125,-0.396875 0,-0.338814 -0.733436,-0.396874 
					-5.013425,-0.396874 -4.662622,0 -5.1656,-0.04807 -7.188202,-0.687012 C 
					9.6223861,48.833121 9.3589228,48.660073 7.33443,46.634063 4.7787058,44.076421 
					3.0648688,41.142954 2.1323182,37.729929 1.3364246,34.817052 1.330957,31.665153 
					2.1132711,26.749722 2.6494218,23.380982 2.8330799,22.780504 4.1414327,20.118554 
					5.363826,17.631495 5.8956144,16.875899 7.6794919,15.09147 c 1.154688,-1.155044 
					2.5495911,-2.457268 3.0997841,-2.893831 1.200025,-0.952185 3.507245,-2.2489582 
					4.001348,-2.2489582 0.195032,0 0.354603,-0.1785937 0.354603,-0.3968749 
					0,-0.2771826 0.279283,-0.396875 0.926042,-0.396875 0.509323,0 
					0.926041,-0.1190625 0.926041,-0.2645833 0,-0.1455209 0.297657,-0.2645834 
					0.661459,-0.2645834 0.363802,0 0.661458,-0.1190625 0.661458,-0.2645833 
					0,-0.1455208 0.416719,-0.2645833 0.926042,-0.2645833 0.646759,0 
					0.926042,-0.1196925 0.926042,-0.396875 0,-0.2771825 0.279282,-0.396875 
					0.926041,-0.396875 0.509323,0 0.926042,-0.1190625 0.926042,-0.2645833 
					0,-0.1455208 0.416719,-0.2645833 0.926042,-0.2645833 0.509323,0 
					0.926041,-0.1190625 0.926041,-0.2645833 0,-0.1455209 0.416719,-0.2645834 
					0.926042,-0.2645834 0.646759,0 0.926042,-0.1196924 0.926042,-0.3968749 
					0,-0.2939815 0.30868,-0.396875 1.190625,-0.396875 0.654843,0 1.190625,-0.1190625 
					1.190625,-0.2645833 0,-0.1455209 0.416719,-0.2645834 0.926041,-0.2645834 
					0.509323,0 0.926042,-0.1190624 0.926042,-0.2645833 0,-0.1455208 
					0.416719,-0.2645833 0.926042,-0.2645833 0.646758,0 0.926041,-0.1196925 
					0.926041,-0.396875 0,-0.2939814 0.308682,-0.396875 1.190626,-0.396875 0.654843,0 
					1.190625,-0.1190625 1.190625,-0.2645833 0,-0.1455208 0.416718,-0.2645833 
					0.926041,-0.2645833 0.509323,0 0.926042,-0.1190625 0.926042,-0.2645833 
					0,-0.1455208 0.416719,-0.2645833 0.926042,-0.2645833 0.646758,0 
					0.926041,-0.1196925 0.926041,-0.396875 0,-0.2771825 0.279284,-0.396875 
					0.926042,-0.396875 0.509323,0 0.926042,-0.1190625 0.926042,-0.2645833 
					0,-0.1455208 0.416718,-0.2645833 0.926041,-0.2645833 0.509323,0 
					0.926042,-0.1190625 0.926042,-0.26458336 0,-0.14552083 0.416719,-0.26458332 
					0.926042,-0.26458332 0.509323,0 0.926041,-0.11906249 0.926041,-0.26458332 
					0,-0.1739219 6.482292,-0.26458332 18.917709,-0.26458332 H 81.281062 V 2.5403479 
					c 0,1.4111109 -0.107794,2.3812498 -0.264583,2.3812498 -0.145521,0 
					-0.264584,0.4167188 -0.264584,0.9260417 0,0.5093229 -0.119062,0.9260416 
					-0.264583,0.9260416 -0.145521,0 -0.264583,0.2976562 -0.264583,0.6614583 
					0,0.3638021 -0.119063,0.6614583 -0.264584,0.6614583 -0.145521,0 
					-0.264583,0.238125 -0.264583,0.5291666 0,0.2939815 -0.17639,0.5291667 
					-0.396875,0.5291667 -0.218281,0 -0.396875,0.1785937 -0.396875,0.396875 
					0,0.2182812 -0.119063,0.3968749 -0.264583,0.3968749 -0.145521,0 
					-0.264584,0.2381252 -0.264584,0.5291662 0,0.291042 -0.119062,0.529167 
					-0.264583,0.529167 -0.145521,0 -0.264583,0.178594 -0.264583,0.396875 0,0.218281 
					-0.130826,0.396875 -0.290722,0.396875 -0.159896,0 -0.624385,0.357188 
					-1.032195,0.79375 -0.40781,0.436563 -0.8723,0.79375 -1.032195,0.79375 
					-0.159896,0 -0.290722,0.124475 -0.290722,0.27661 0,0.359156 -1.216319,1.575473 
					-1.575475,1.575473 -0.152135,0 -0.276608,0.127567 -0.276608,0.283482 0,0.428557 
					-0.699733,1.039435 -1.190625,1.039435 -0.490892,0 -1.190625,0.610878 
					-1.190625,1.039434 0,0.155915 -0.271373,0.283482 -0.603049,0.283482 -0.331677,0 
					-0.774679,0.238125 -0.984451,0.529167 -0.209773,0.291042 -0.593244,0.529167 
					-0.85216,0.529167 -0.258916,0 -0.470757,0.178593 -0.470757,0.396875 0,0.218281 
					-0.178594,0.396875 -0.396875,0.396875 -0.218281,0 -0.396875,0.119062 
					-0.396875,0.264583 0,0.145521 -0.238125,0.264583 -0.529167,0.264583 -0.291041,0 
					-0.529166,0.119063 -0.529166,0.264584 0,0.14552 -0.297657,0.264583 
					-0.661459,0.264583 -0.411575,0 -0.661458,0.149931 -0.661458,0.396875 0,0.246944 
					-0.249883,0.396875 -0.661458,0.396875 -0.363803,0 -0.661459,0.119062 
					-0.661459,0.264583 0,0.145521 -0.238125,0.264584 -0.529166,0.264584 -0.291042,0 
					-0.529167,0.119062 -0.529167,0.264583 0,0.145521 -0.297656,0.264583 
					-0.661459,0.264583 -0.411575,0 -0.661458,0.149931 -0.661458,0.396875 0,0.277183 
					-0.279283,0.396875 -0.926042,0.396875 -0.509322,0 -0.926041,0.119063 
					-0.926041,0.264583 0,0.145521 -0.297656,0.264584 -0.661459,0.264584 -0.363802,0 
					-0.661458,0.119062 -0.661458,0.264583 0,0.145521 -0.357187,0.264583 
					-0.79375,0.264583 -0.436562,0 -0.79375,0.119063 -0.79375,0.264584 0,0.145521 
					-0.416719,0.264583 -0.926042,0.264583 -0.646758,0 -0.926041,0.119693 
					-0.926041,0.396875 0,0.246944 -0.249883,0.396875 -0.661459,0.396875 -0.363802,0 
					-0.661458,0.119063 -0.661458,0.264583 0,0.145521 -0.246629,0.264584 
					-0.548066,0.264584 -0.585769,0 -1.304017,0.572518 -1.304017,1.039434 0,0.155916 
					-0.243536,0.283482 -0.541192,0.283482 -0.698506,0 -1.840058,1.141553 
					-1.840058,1.840058 0,0.297656 -0.127567,0.541192 -0.283483,0.541192 -0.592413,0 
					-1.039434,0.796512 -1.039434,1.852083 0,1.01405 0.443897,1.852084 
					0.981025,1.852084 0.12379,0 0.396703,0.238125 0.606475,0.529166 
					0.247975,0.344049 0.684924,0.529167 1.249034,0.529167 0.594839,0 
					0.867633,0.124783 0.867633,0.396875 0,0.319704 0.411575,0.396875 
					2.116666,0.396875 1.234723,0 2.116667,0.110244 2.116667,0.264583 0,0.145521 
					0.535781,0.264584 1.190625,0.264584 0.654844,0 1.190625,0.119062 
					1.190625,0.264583 0,0.145521 0.416719,0.264583 0.926042,0.264583 0.646758,0 
					0.926041,0.119692 0.926041,0.396875 0,0.236694 0.243396,0.396875 
					0.603049,0.396875 0.331677,0 0.774679,0.238125 0.984451,0.529167 
					0.209773,0.291041 0.533713,0.529166 0.719868,0.529166 0.186156,0 
					0.338466,0.124474 0.338466,0.276609 0,0.359156 1.216319,1.575475 
					1.575475,1.575475 0.152135,0 0.276608,0.178593 0.276608,0.396875 0,0.218281 
					0.119063,0.396875 0.264584,0.396875 0.14552,0 0.264583,0.238125 
					0.264583,0.529166 0,0.291042 0.119062,0.529167 0.264583,0.529167 0.145521,0 
					0.264584,0.297656 0.264584,0.661458 0,0.411575 0.149931,0.661459 
					0.396875,0.661459 0.335139,0 0.396875,0.61736 0.396875,3.968749 0,3.35139 
					-0.06174,3.96875 -0.396875,3.96875 -0.277183,0 -0.396875,0.279284 
					-0.396875,0.926042 0,0.509323 -0.119063,0.926041 -0.264584,0.926041 -0.145521,0 
					-0.264583,0.297657 -0.264583,0.661459 0,0.363802 -0.119063,0.661458 
					-0.264583,0.661458 -0.145521,0 -0.264584,0.238125 -0.264584,0.529167 0,0.293981 
					-0.17639,0.529166 -0.396875,0.529166 -0.218281,0 -0.396875,0.178594 
					-0.396875,0.396875 0,0.218281 -0.119062,0.396875 -0.264583,0.396875 -0.145521,0 
					-0.264583,0.187098 -0.264583,0.415774 0,0.475189 -0.617895,1.171726 
					-1.039435,1.171726 -0.155916,0 -0.283482,0.271373 -0.283482,0.603049 0,0.331676 
					-0.238125,0.774679 -0.529167,0.984451 -0.291042,0.209772 -0.529167,0.652775 
					-0.529167,0.984451 0,0.359653 -0.160181,0.603049 -0.396875,0.603049 -0.246943,0 
					-0.396875,0.249883 -0.396875,0.661458 0,0.363802 -0.119062,0.661459 
					-0.264583,0.661459 -0.145521,0 -0.264583,0.238125 -0.264583,0.529166 0,0.291042 
					-0.119063,0.529167 -0.264584,0.529167 -0.154463,0 -0.264583,0.885952 
					-0.264583,2.128692 0,1.907317 0.06754,2.196232 0.649433,2.778125 
					0.557229,0.557231 0.895305,0.649433 2.38125,0.649433 0.978157,0 
					1.731817,-0.115144 1.731817,-0.264584 0,-0.145521 0.246629,-0.264583 
					0.548066,-0.264583 0.585769,0 1.304018,-0.572519 1.304018,-1.039434 0,-0.155916 
					0.297656,-0.283483 0.661458,-0.283483 0.363802,0 0.661458,-0.119062 
					0.661458,-0.264583 0,-0.145521 0.297657,-0.264583 0.661459,-0.264583 0.363802,0 
					0.661458,-0.119063 0.661458,-0.264584 0,-0.14552 0.357187,-0.264583 
					0.79375,-0.264583 0.529167,0 0.79375,-0.132292 0.79375,-0.396875 0,-0.330729 
					0.529167,-0.396875 3.175,-0.396875 2.645833,0 3.175,0.06615 3.175,0.396875 
					0,0.277183 0.279284,0.396875 0.926042,0.396875 0.509323,0 0.926042,0.119063 
					0.926042,0.264583 0,0.145521 0.238125,0.264584 0.529166,0.264584 0.291042,0 
					0.529167,0.119062 0.529167,0.264583 0,0.145521 0.178594,0.264583 
					0.396875,0.264583 0.218281,0 0.396875,0.123032 0.396875,0.273402 0,0.355717 
					1.752132,2.107848 2.107848,2.107848 0.150371,0 0.273402,0.178594 
					0.273402,0.396875 0,0.218281 0.119062,0.396875 0.264583,0.396875 0.145521,0 
					0.264584,0.238125 0.264584,0.529167 0,0.291041 0.119062,0.529166 
					0.264583,0.529166 0.145521,0 0.264583,0.297657 0.264583,0.661459 0,0.411575 
					0.149932,0.661458 0.396875,0.661458 0.277183,0 0.396875,0.279284 
					0.396875,0.926042 0,0.509323 0.119063,0.926041 0.264584,0.926041 0.151191,0 
					0.264583,0.79375 0.264583,1.852084 0,1.058333 0.113392,1.852083 
					0.264583,1.852083 0.151191,0 0.264584,0.79375 0.264584,1.852083 0,1.058333 
					-0.113393,1.852083 -0.264584,1.852083 -0.151191,0 -0.264583,0.79375 
					-0.264583,1.852084 0,1.058333 -0.113392,1.852083 -0.264583,1.852083 -0.145521,0 
					-0.264584,0.535781 -0.264584,1.190625 0,0.881943 -0.102893,1.190625 
					-0.396875,1.190625 -0.277182,0 -0.396875,0.279283 -0.396875,0.926041 0,0.509323 
					-0.119062,0.926042 -0.264583,0.926042 -0.145521,0 -0.264583,0.416719 
					-0.264583,0.926042 0,0.509322 -0.119063,0.926041 -0.264584,0.926041 -0.145521,0 
					-0.264583,0.238125 -0.264583,0.529167 0,0.293981 -0.17639,0.529166 
					-0.396875,0.529166 -0.277183,0 -0.396875,0.279284 -0.396875,0.926042 0,0.509323 
					-0.119063,0.926042 -0.264583,0.926042 -0.145521,0 -0.264584,0.297656 
					-0.264584,0.661458 0,0.363802 -0.119062,0.661458 -0.264583,0.661458 -0.145521,0 
					-0.264583,0.297656 -0.264583,0.661459 0,0.363802 -0.119063,0.661458 
					-0.264584,0.661458 -0.145521,0 -0.264583,0.238125 -0.264583,0.529166 0,0.293982 
					-0.17639,0.529167 -0.396875,0.529167 -0.246944,0 -0.396875,0.249883 
					-0.396875,0.661458 0,0.363803 -0.119063,0.661459 -0.264583,0.661459 -0.145521,0 
					-0.264584,0.29766 -0.264584,0.66146 0,0.3638 -0.119062,0.66146 -0.264583,0.66146 
					-0.145521,0 -0.264583,0.35718 -0.264583,0.79375 0,0.52916 -0.132292,0.79375 
					-0.396875,0.79375 -0.246944,0 -0.396875,0.24988 -0.396875,0.66145 0,0.36381 
					-0.119063,0.66146 -0.264584,0.66146 -0.145521,0 -0.264583,0.29766 
					-0.264583,0.66146 0,0.3638 -0.119063,0.66146 -0.264584,0.66146 -0.14552,0 
					-0.264583,0.41672 -0.264583,0.92604 0,0.64676 -0.119692,0.92604 
					-0.396875,0.92604 -0.277183,0 -0.396875,0.27929 -0.396875,0.92604 0,0.50933 
					-0.119062,0.92605 -0.264583,0.92605 -0.145521,0 -0.264584,0.41671 
					-0.264584,0.92604 0,0.50932 -0.119062,0.92604 -0.264583,0.92604 -0.156297,0 
					-0.264583,0.95067 -0.264583,2.32284 0,2.01932 0.06915,2.37268 0.529166,2.70424 
					0.291042,0.20977 0.529167,0.53371 0.529167,0.71987 0,0.18616 0.178594,0.33847 
					0.396875,0.33847 0.218281,0 0.396875,0.11906 0.396875,0.26458 0,0.14552 
					0.654844,0.26458 1.455209,0.26458 0.800364,0 1.455208,-0.11906 1.455208,-0.26458 
					0,-0.14552 0.297656,-0.26458 0.661458,-0.26458 0.363802,0 0.661459,-0.12448 
					0.661459,-0.27661 0,-0.35916 1.216318,-1.57548 1.575474,-1.57548 0.152136,0 
					0.276609,-0.27137 0.276609,-0.60305 0,-0.33167 0.238125,-0.77468 
					0.529167,-0.98445 0.291041,-0.20977 0.529166,-0.53371 0.529166,-0.71987 
					0,-0.18615 0.130826,-0.33846 0.290722,-0.33846 0.159895,0 0.624385,-0.35719 
					1.032195,-0.79375 0.40781,-0.43656 0.8723,-0.79375 1.032195,-0.79375 0.159896,0 
					0.290722,-0.17859 0.290722,-0.39688 0,-0.22048 0.235185,-0.39687 
					0.529166,-0.39687 0.291042,0 0.529167,-0.11906 0.529167,-0.26458 0,-0.14552 
					0.178594,-0.26459 0.396875,-0.26459 0.218281,0 0.396875,-0.11906 
					0.396875,-0.26458 0,-0.14552 0.238125,-0.26458 0.529167,-0.26458 0.293981,0 
					0.529166,-0.17639 0.529166,-0.39688 0,-0.21828 0.178594,-0.39687 
					0.396875,-0.39687 0.218282,0 0.396875,-0.11907 0.396875,-0.26459 0,-0.14552 
					0.238125,-0.26458 0.529167,-0.26458 0.291042,0 0.529167,-0.11906 
					0.529167,-0.26458 0,-0.14552 0.416719,-0.26459 0.926041,-0.26459 0.646759,0 
					0.926042,-0.11969 0.926042,-0.39687 0,-0.27719 0.279284,-0.39688 
					0.926042,-0.39688 0.509323,0 0.926042,-0.11906 0.926042,-0.26458 0,-0.14699 
					0.705556,-0.26458 1.5875,-0.26458 0.881943,0 1.5875,-0.1176 1.5875,-0.26459 
					0,-0.15119 0.79375,-0.26458 1.852083,-0.26458 1.058331,0 1.852081,0.11339 
					1.852081,0.26458 0,0.14552 0.65485,0.26459 1.45521,0.26459 0.80037,0 
					1.45521,0.11906 1.45521,0.26458 0,0.14552 0.41672,0.26458 0.92604,0.26458 
					0.64676,0 0.92604,0.11969 0.92604,0.39688 0,0.24694 0.24989,0.39687 
					0.66146,0.39687 0.3638,0 0.66146,0.11907 0.66146,0.26459 0,0.14552 
					0.23812,0.26458 0.52917,0.26458 0.29104,0 0.52916,0.11906 0.52916,0.26458 
					0,0.14552 0.1786,0.26459 0.39688,0.26459 0.21828,0 0.39687,0.17859 
					0.39687,0.39687 0,0.22049 0.23519,0.39688 0.52917,0.39688 0.29104,0 
					0.52917,0.11906 0.52917,0.26458 0,0.14552 0.12756,0.26458 0.28348,0.26458 
					0.30835,0 1.03943,0.65133 1.03943,0.92604 0,0.27472 0.73108,0.92605 
					1.03944,0.92605 0.15591,0 0.28348,0.23812 0.28348,0.52916 0,0.29104 
					0.11906,0.52917 0.26458,0.52917 0.14552,0 0.26459,0.29766 0.26459,0.66146 
					0,0.3638 0.11906,0.66146 0.26458,0.66146 0.14552,0 0.26458,0.29765 
					0.26458,0.66145 0,0.36381 0.11907,0.66146 0.26459,0.66146 0.14552,0 
					0.26458,0.41672 0.26458,0.92604 0,0.64676 0.11969,0.92605 0.39687,0.92605 
					0.31499,0 0.39688,0.38217 0.39688,1.85208 0,1.05833 0.11339,1.85208 
					0.26458,1.85208 0.14552,0 0.26459,0.41672 0.26459,0.92604 0,0.50933 
					-0.11907,0.92605 -0.26459,0.92605 -0.15434,0 -0.26458,0.88194 -0.26458,2.11666 
					0,1.70509 -0.0772,2.11667 -0.39688,2.11667 -0.27718,0 -0.39687,0.27928 
					-0.39687,0.92604 0,0.50932 -0.11906,0.92604 -0.26458,0.92604 -0.14552,0 
					-0.26459,0.41672 -0.26459,0.92604 0,0.50933 -0.11906,0.92604 -0.26458,0.92604 
					-0.14552,0 -0.26458,0.23813 -0.26458,0.52917 0,0.29104 -0.11907,0.52917 
					-0.26459,0.52917 -0.14552,0 -0.26458,0.29765 -0.26458,0.66146 0,0.3638 
					-0.12757,0.66145 -0.28348,0.66145 -0.42856,0 -1.03944,0.69974 -1.03944,1.19063 
					0,0.42775 -0.59335,1.19062 -0.92604,1.19062 -0.33269,0 -0.92604,0.76288 
					-0.92604,1.19063 0,0.49089 -0.61088,1.19062 -1.03943,1.19062 -0.15592,0 
					-0.28349,0.12448 -0.28349,0.27661 0,0.35916 -1.21632,1.57548 -1.57547,1.57548 
					-0.15214,0 -0.27661,0.12447 -0.27661,0.27661 0,0.35915 -1.21632,1.57547 
					-1.57548,1.57547 -0.15213,0 -0.2766,0.12447 -0.2766,0.27661 0,0.48214 
					-1.27696,1.57547 -1.84006,1.57547 -0.29766,0 -0.54119,0.12448 -0.54119,0.27661 
					0,0.35916 -1.21632,1.57548 -1.57548,1.57548 -0.15213,0 -0.27661,0.11906 
					-0.27661,0.26458 0,0.14552 -0.23812,0.26458 -0.529164,0.26458 -0.293982,0 
					-0.529167,0.17639 -0.529167,0.39688 0,0.21828 -0.178594,0.39687 
					-0.396875,0.39687 -0.218281,0 -0.396875,0.11907 -0.396875,0.26459 0,0.14552 
					-0.238125,0.26458 -0.529167,0.26458 -0.291041,0 -0.529166,0.11906 
					-0.529166,0.26458 0,0.14552 -0.178594,0.26459 -0.396875,0.26459 -0.218282,0 
					-0.396875,0.17859 -0.396875,0.39687 0,0.22049 -0.235186,0.39688 
					-0.529167,0.39688 -0.291042,0 -0.529167,0.11906 -0.529167,0.26458 0,0.14552 
					-0.178594,0.26458 -0.396875,0.26458 -0.218281,0 -0.396875,0.11907 
					-0.396875,0.26459 0,0.14552 -0.238125,0.26458 -0.529166,0.26458 -0.293982,0 
					-0.529167,0.17639 -0.529167,0.39688 0,0.21828 -0.178594,0.39687 
					-0.396875,0.39687 -0.218281,0 -0.396875,0.11906 -0.396875,0.26458 0,0.14552 
					-0.238125,0.26459 -0.529167,0.26459 -0.291041,0 -0.529166,0.11906 
					-0.529166,0.26458 0,0.14552 -0.297657,0.26458 -0.661459,0.26458 -0.411575,0 
					-0.661458,0.14994 -0.661458,0.39688 0,0.22048 -0.235186,0.39687 
					-0.529167,0.39687 -0.291042,0 -0.529167,0.11907 -0.529167,0.26459 0,0.14552 
					-0.297656,0.26458 -0.661458,0.26458 -0.363802,0 -0.661458,0.11906 
					-0.661458,0.26458 0,0.14552 -0.297657,0.26459 -0.661459,0.26459 -0.411575,0 
					-0.661458,0.14993 -0.661458,0.39687 0,0.22049 -0.235185,0.39688 
					-0.529167,0.39688 -0.291041,0 -0.529166,0.11906 -0.529166,0.26458 0,0.14552 
					-0.297657,0.26458 -0.661459,0.26458 -0.363802,0 -0.661458,0.11907 
					-0.661458,0.26459 0,0.14552 -0.416719,0.26458 -0.926042,0.26458 -0.646758,0 
					-0.926041,0.11969 -0.926041,0.39688 0,0.24694 -0.249883,0.39687 
					-0.661459,0.39687 -0.363802,0 -0.661458,0.11906 -0.661458,0.26458 0,0.14552 
					-0.357188,0.26459 -0.79375,0.26459 -0.436563,0 -0.79375,0.11906 -0.79375,0.26458 
					0,0.14552 -0.416719,0.26458 -0.926042,0.26458 -0.509323,0 -0.926041,0.11907 
					-0.926041,0.26459 0,0.14552 -0.595313,0.26458 -1.322917,0.26458 -0.999538,0 
					-1.322917,0.097 -1.322917,0.39688 0,0.27718 -0.279283,0.39687 -0.926042,0.39687 
					-0.509322,0 -0.926041,0.11906 -0.926041,0.26458 0,0.14552 -0.654844,0.26459 
					-1.455209,0.26459 -0.800364,0 -1.455208,0.11906 -1.455208,0.26458 0,0.14699 
					-0.705556,0.26458 -1.5875,0.26458 -1.234723,0 -1.5875,0.0882 -1.5875,0.39688 
					0,0.32758 -0.485069,0.39687 -2.778125,0.39687 -1.675694,0 -2.778125,0.105 
					-2.778125,0.26459 0,0.16463 -1.499307,0.26458 -3.96875,0.26458 -2.469444,0 
					-3.96875,-0.1 -3.96875,-0.26458 z M 185.5269,133.90597 c 0,-0.22343 
					-0.23652,-0.39688 -0.54119,-0.39688 -0.56311,0 -1.84006,-1.09334 
					-1.84006,-1.57547 0,-0.15214 -0.12757,-0.27661 -0.28348,-0.27661 -0.46692,0 
					-1.03944,-0.71825 -1.03944,-1.30402 0,-0.30144 -0.11906,-0.54807 
					-0.26458,-0.54807 -0.14552,0 -0.26459,-0.23812 -0.26459,-0.52916 0,-0.29398 
					-0.17639,-0.52917 -0.39687,-0.52917 -0.27718,0 -0.39688,-0.27928 
					-0.39688,-0.92604 0,-0.50932 -0.11906,-0.92604 -0.26458,-0.92604 -0.14552,0 
					-0.26458,-0.41672 -0.26458,-0.92604 0,-0.50933 -0.11907,-0.92604 
					-0.26459,-0.92604 -0.16756,0 -0.26458,-1.94028 -0.26458,-5.29167 0,-3.35139 
					-0.097,-5.29167 -0.26458,-5.29167 -0.15875,0 -0.26459,-1.05833 -0.26459,-2.64583 
					0,-2.17546 -0.0705,-2.64583 -0.39687,-2.64583 -0.22505,0 -0.39688,-0.23729 
					-0.39688,-0.54807 0,-0.9651 -0.63318,-1.30402 -2.43623,-1.30402 -1.37676,0 
					-1.74918,0.0956 -2.06168,0.52917 -0.20977,0.29104 -0.65278,0.52917 
					-0.98445,0.52917 -0.33168,0 -0.60305,0.12756 -0.60305,0.28348 0,0.30835 
					-0.65133,1.03943 -0.92604,1.03943 -0.22077,0 -0.92605,0.70528 -0.92605,0.92604 
					0,0.21714 -0.70364,0.92605 -0.91917,0.92605 -0.21252,0 -1.46208,1.24956 
					-1.46208,1.46208 0,0.26922 -0.73498,0.91917 -1.03943,0.91917 -0.15592,0 
					-0.28348,0.11906 -0.28348,0.26458 0,0.14552 -0.23813,0.26458 -0.52917,0.26458 
					-0.29398,0 -0.52917,0.17639 -0.52917,0.39688 0,0.21828 -0.12447,0.39687 
					-0.2766,0.39687 -0.35916,0 -1.57548,1.21632 -1.57548,1.57548 0,0.15213 
					-0.17859,0.27661 -0.39687,0.27661 -0.21829,0 -0.39688,0.11906 -0.39688,0.26458 
					0,0.14552 -0.23812,0.26458 -0.52917,0.26458 -0.29104,0 -0.52916,0.11906 
					-0.52916,0.26459 0,0.14552 -0.41672,0.26458 -0.92604,0.26458 -0.50933,0 
					-0.92605,-0.11906 -0.92605,-0.26458 0,-0.14553 -0.24663,-0.26459 
					-0.54806,-0.26459 -0.58577,0 -1.30402,-0.57252 -1.30402,-1.03943 0,-0.15592 
					-0.11906,-0.28348 -0.26458,-0.28348 -0.14552,0 -0.26459,-0.41672 
					-0.26459,-0.92605 0,-0.64675 -0.11969,-0.92604 -0.39687,-0.92604 -0.31207,0 
					-0.39688,-0.36747 -0.39688,-1.71979 0,-0.97014 -0.11533,-1.71979 
					-0.26458,-1.71979 -0.14699,0 -0.26458,-0.70556 -0.26458,-1.5875 0,-0.88194 
					0.11759,-1.5875 0.26458,-1.5875 0.44465,0 0.33949,-5.78373 -0.11339,-6.23661 
					-0.43123,-0.43123 -1.47411,-0.51145 -1.47411,-0.11339 0,0.14552 -0.23812,0.26458 
					-0.52917,0.26458 -0.29104,0 -0.52916,0.11907 -0.52916,0.26459 0,0.14552 
					-0.24663,0.26458 -0.54807,0.26458 -0.58577,0 -1.30402,0.57252 -1.30402,1.03943 
					0,0.15592 -0.12447,0.28349 -0.27661,0.28349 -0.35915,0 -1.57547,1.21631 
					-1.57547,1.57547 0,0.15214 -0.12447,0.27661 -0.27661,0.27661 -0.35915,0 
					-1.57547,1.21632 -1.57547,1.57547 0,0.15214 -0.12448,0.27661 -0.27661,0.27661 
					-0.29503,0 -1.57548,1.18829 -1.57548,1.46208 0,0.21552 -0.70891,0.91917 
					-0.92604,0.91917 -0.27472,0 -0.92604,0.73108 -0.92604,1.03944 0,0.15591 
					-0.29766,0.28348 -0.66146,0.28348 -0.3638,0 -0.66146,0.11906 -0.66146,0.26458 
					0,0.14552 -0.23812,0.26459 -0.52916,0.26459 -0.29105,0 -0.52917,0.11906 
					-0.52917,0.26458 0,0.14552 -0.29766,0.26458 -0.66146,0.26458 -0.3638,0 
					-0.66146,0.11906 -0.66146,0.26459 0,0.15119 -0.79375,0.26458 -1.85208,0.26458 
					-1.05833,0 -1.85208,-0.11339 -1.85208,-0.26458 0,-0.14553 -0.29766,-0.26459 
					-0.66146,-0.26459 -0.3638,0 -0.66146,-0.11906 -0.66146,-0.26458 0,-0.14552 
					-0.1871,-0.26458 -0.41577,-0.26458 -0.47519,0 -1.17173,-0.6179 -1.17173,-1.03944 
					0,-0.15591 -0.17859,-0.28348 -0.39688,-0.28348 -0.22048,0 -0.39687,-0.23519 
					-0.39687,-0.52917 0,-0.29104 -0.11906,-0.52916 -0.26458,-0.52916 -0.14552,0 
					-0.26459,-0.29766 -0.26459,-0.66146 0,-0.3638 -0.11906,-0.66146 
					-0.26458,-0.66146 -0.14552,0 -0.26458,-0.29766 -0.26458,-0.66146 0,-0.41157 
					-0.14994,-0.66146 -0.39688,-0.66146 -0.33318,0 -0.39687,-0.57326 
					-0.39687,-3.57187 0,-2.998612 0.0637,-3.571877 0.39687,-3.571877 0.29986,0 
					0.39688,-0.323379 0.39688,-1.322917 0,-0.727604 0.11906,-1.322916 
					0.26458,-1.322916 0.14552,0 0.26458,-0.416719 0.26458,-0.926042 0,-0.509323 
					0.11907,-0.926041 0.26459,-0.926041 0.14552,0 0.26458,-0.238125 
					0.26458,-0.529167 0,-0.293981 0.17639,-0.529167 0.39687,-0.529167 0.27719,0 
					0.39688,-0.279283 0.39688,-0.926041 0,-0.509323 0.11906,-0.926042 
					0.26458,-0.926042 0.14552,0 0.26459,-0.297656 0.26459,-0.661458 0,-0.363802 
					0.11906,-0.661458 0.26458,-0.661458 0.14552,0 0.26458,-0.238125 
					0.26458,-0.529167 0,-0.291042 0.11907,-0.529167 0.26459,-0.529167 0.14552,0 
					0.26458,-0.297656 0.26458,-0.661458 0,-0.411575 0.14993,-0.661458 
					0.39687,-0.661458 0.24695,0 0.39688,-0.249883 0.39688,-0.661459 0,-0.363802 
					0.11906,-0.661458 0.26458,-0.661458 0.14552,0 0.26459,-0.238125 
					0.26459,-0.529167 0,-0.291041 0.11906,-0.529166 0.26458,-0.529166 0.14552,0 
					0.26458,-0.297656 0.26458,-0.661459 0,-0.411575 0.14993,-0.661458 
					0.39688,-0.661458 0.27718,0 0.39687,-0.279283 0.39687,-0.926041 0,-0.509323 
					0.11907,-0.926042 0.26459,-0.926042 0.14552,0 0.26458,-0.297656 
					0.26458,-0.661458 0,-0.363802 0.11906,-0.661459 0.26458,-0.661459 0.14552,0 
					0.26459,-0.416718 0.26459,-0.926041 0,-0.646758 0.11969,-0.926042 
					0.39687,-0.926042 0.22049,0 0.39688,-0.235185 0.39688,-0.529167 0,-0.291041 
					0.11906,-0.529166 0.26458,-0.529166 0.14552,0 0.26458,-0.416719 
					0.26458,-0.926042 0,-0.509323 0.11907,-0.926041 0.26459,-0.926041 0.14552,0 
					0.26458,-0.535782 0.26458,-1.190625 0,-0.881944 0.10289,-1.190625 
					0.39687,-1.190625 0.24695,0 0.39688,-0.249883 0.39688,-0.661459 0,-0.363802 
					0.11906,-0.661458 0.26458,-0.661458 0.14552,0 0.26459,-0.416719 
					0.26459,-0.926042 0,-0.509322 0.11906,-0.926041 0.26458,-0.926041 0.14552,0 
					0.26458,-0.297656 0.26458,-0.661458 0,-0.363803 0.12757,-0.661459 
					0.28349,-0.661459 0.42154,0 1.03943,-0.696537 1.03943,-1.171726 0,-0.228676 
					0.11906,-0.415774 0.26458,-0.415774 0.14552,0 0.26459,-0.178593 
					0.26459,-0.396875 0,-0.218281 0.15231,-0.396875 0.33846,-0.396875 0.18616,0 
					0.5101,-0.238125 0.71987,-0.529166 0.20977,-0.291042 0.59324,-0.529167 
					0.85216,-0.529167 0.25892,0 0.47076,-0.178594 0.47076,-0.396875 0,-0.246943 
					0.24988,-0.396875 0.66146,-0.396875 0.3638,0 0.66145,-0.119062 0.66145,-0.264583 
					0,-0.145521 0.53578,-0.264584 1.19063,-0.264584 0.65484,0 1.19062,0.119063 
					1.19062,0.264584 0,0.145521 0.29766,0.264583 0.66146,0.264583 0.3638,0 
					0.66146,0.127566 0.66146,0.283483 0,0.36631 0.67312,1.039434 1.03943,1.039434 
					0.15592,0 0.28349,0.297656 0.28349,0.661458 0,0.363802 0.11906,0.661458 
					0.26458,0.661458 0.14552,0 0.26458,0.654844 0.26458,1.455209 0,0.800364 
					0.11907,1.455208 0.26459,1.455208 0.14552,0 0.26458,0.535781 0.26458,1.190625 
					0,0.654843 -0.11906,1.190625 -0.26458,1.190625 -0.15119,0 -0.26459,0.79375 
					-0.26459,1.852083 0,1.058333 -0.11339,1.852083 -0.26458,1.852083 -0.14552,0 
					-0.26458,0.535781 -0.26458,1.190625 0,0.881944 -0.1029,1.190625 
					-0.39688,1.190625 -0.27718,0 -0.39687,0.279284 -0.39687,0.926042 0,0.509323 
					-0.11907,0.926041 -0.26459,0.926041 -0.14552,0 -0.26458,0.416719 
					-0.26458,0.926042 0,0.509323 -0.11906,0.926042 -0.26458,0.926042 -0.14552,0 
					-0.26459,0.297656 -0.26459,0.661458 0,0.411575 -0.14993,0.661458 
					-0.39687,0.661458 -0.27718,0 -0.39688,0.279284 -0.39688,0.926042 0,0.509323 
					-0.11906,0.926041 -0.26458,0.926041 -0.14552,0 -0.26458,0.535782 
					-0.26458,1.190625 0,0.654844 0.11906,1.190625 0.26458,1.190625 0.14552,0 
					0.26458,0.297657 0.26458,0.661459 0,0.579564 0.11466,0.661458 0.92605,0.661458 
					0.50932,0 0.92604,-0.119063 0.92604,-0.264583 0,-0.145521 0.17859,-0.264584 
					0.39687,-0.264584 0.21828,0 0.39688,-0.178593 0.39688,-0.396875 0,-0.220485 
					0.23518,-0.396875 0.52916,-0.396875 0.29105,0 0.52917,-0.119062 
					0.52917,-0.264583 0,-0.145521 0.12757,-0.264583 0.28348,-0.264583 0.36631,0 
					1.03944,-0.673124 1.03944,-1.039434 0,-0.155917 0.29765,-0.283483 
					0.66146,-0.283483 0.3638,0 0.66145,-0.119062 0.66145,-0.264583 0,-0.145521 
					0.35719,-0.264584 0.79375,-0.264584 0.43657,0 0.79375,-0.119062 
					0.79375,-0.264583 0,-0.145521 0.1786,-0.264583 0.39688,-0.264583 0.21828,0 
					0.39687,0.119062 0.39687,0.264583 0,0.145521 0.36569,0.264583 0.81265,0.264583 
					0.73504,0 1.5686,0.492104 1.5686,0.926042 0,0.220766 0.70528,0.926042 
					0.92605,0.926042 0.43393,0 0.92604,0.833561 0.92604,1.5686 0,0.446958 
					0.11906,0.81265 0.26458,0.81265 0.14699,0 0.26458,0.705556 0.26458,1.587499 
					0,1.234723 0.0882,1.5875 0.39688,1.5875 0.27718,0 0.39687,0.279284 
					0.39687,0.926042 0,0.509323 0.11907,0.926042 0.26459,0.926042 0.14552,0 
					0.26458,0.238125 0.26458,0.529166 0,0.291042 0.11906,0.529167 0.26458,0.529167 
					0.14552,0 0.26459,0.178594 0.26459,0.396875 0,0.246943 0.24988,0.396875 
					0.66146,0.396875 0.3638,0 0.66145,-0.15231 0.66145,-0.338466 0,-0.186155 
					0.23813,-0.510095 0.52917,-0.719867 0.38731,-0.279157 0.52917,-0.684925 
					0.52917,-1.513618 0,-0.830024 0.10592,-1.132216 0.39687,-1.132216 0.27719,0 
					0.39688,-0.279283 0.39688,-0.926041 0,-0.509323 0.11906,-0.926042 
					0.26458,-0.926042 0.14552,0 0.26458,-0.127566 0.26458,-0.283482 0,-0.304454 
					0.64994,-1.039434 0.91917,-1.039434 0.27379,0 1.46208,-1.280451 
					1.46208,-1.575475 0,-0.173011 0.94138,-0.276609 2.51355,-0.276609 1.61101,0 
					2.51354,0.101788 2.51354,0.283483 0,0.426027 0.69857,1.039434 1.18375,1.039434 
					0.6051,0 1.72666,1.195218 1.72666,1.840058 0,0.297656 0.12757,0.541192 
					0.28349,0.541192 0.46691,0 1.03943,0.718248 1.03943,1.304017 0,0.301437 
					0.11906,0.548066 0.26458,0.548066 0.14553,0 0.26459,0.416719 0.26459,0.926042 
					0,0.646758 0.11969,0.926041 0.39687,0.926041 0.24695,0 0.39688,0.249883 
					0.39688,0.661459 0,0.363802 0.11906,0.661458 0.26458,0.661458 0.14552,0 
					0.26458,0.416719 0.26458,0.926041 0,0.509323 0.11907,0.926042 0.26459,0.926042 
					0.14552,0 0.26458,0.416719 0.26458,0.926037 0,0.64676 0.11969,0.92605 
					0.39688,0.92605 0.26458,0 0.39687,0.26458 0.39687,0.79375 0,0.43656 
					0.11906,0.79375 0.26458,0.79375 0.14553,0 0.26459,0.29765 0.26459,0.66145 
					0,0.36381 0.11906,0.66146 0.26458,0.66146 0.14552,0 0.26458,0.29766 
					0.26458,0.66146 0,0.3638 0.15231,0.66146 0.33847,0.66146 0.18616,0 
					0.5101,0.23812 0.71987,0.52917 0.22792,0.31622 0.68492,0.52916 1.13564,0.52916 
					1.32346,0 1.51019,-0.45566 1.51019,-3.68526 0,-2.42745 0.068,-2.92932 
					0.39688,-2.92932 0.29398,0 0.39687,-0.308682 0.39687,-1.190625 0,-0.654844 
					0.11906,-1.190625 0.26458,-1.190625 0.14553,0 0.26459,-0.416719 
					0.26459,-0.926042 0,-0.509323 0.11906,-0.926041 0.26458,-0.926041 0.14552,0 
					0.26458,-0.178594 0.26458,-0.396875 0,-0.218282 0.11907,-0.396875 
					0.26459,-0.396875 0.14552,0 0.26458,-0.238125 0.26458,-0.529167 0,-0.293981 
					0.17639,-0.529167 0.39688,-0.529167 0.21828,0 0.39687,-0.124473 
					0.39687,-0.276608 0,-0.359156 1.21632,-1.575475 1.57548,-1.575475 0.15213,0 
					0.2766,-0.178594 0.2766,-0.396875 0,-0.220485 0.23519,-0.396875 
					0.52917,-0.396875 0.29104,0 0.52917,-0.119062 0.52917,-0.264583 0,-0.145521 
					0.41672,-0.264583 0.92604,-0.264583 0.50932,0 0.92604,-0.119063 
					0.92604,-0.264584 0,-0.145521 0.59531,-0.264583 1.32292,-0.264583 0.7276,0 
					1.32291,0.119062 1.32291,0.264583 0,0.145521 0.35719,0.264584 0.79375,0.264584 
					0.43657,0 0.79375,0.119062 0.79375,0.264583 0,0.145521 0.29766,0.264583 
					0.66146,0.264583 0.36381,0 0.66146,0.127566 0.66146,0.283483 0,0.36631 
					0.67313,1.039434 1.03944,1.039434 0.15591,0 0.28348,0.297656 0.28348,0.661458 
					0,0.363802 0.11906,0.661458 0.26458,0.661458 0.14552,0 0.26458,0.238125 
					0.26458,0.529167 0,0.291042 0.11907,0.529167 0.26459,0.529167 0.14552,0 
					0.26458,0.297656 0.26458,0.661458 0,0.411575 0.14993,0.661458 0.39688,0.661458 
					0.31498,0 0.39687,0.382175 0.39687,1.852083 0,1.058334 0.11339,1.852084 
					0.26458,1.852084 0.14553,0 0.26459,0.65484 0.26459,1.45521 0,0.80036 
					-0.11906,1.45521 -0.26459,1.45521 -0.15782,0 -0.26458,1.01423 -0.26458,2.51354 
					0,2.05787 -0.0719,2.51354 -0.39687,2.51354 -0.30869,0 -0.39688,0.35278 
					-0.39688,1.5875 0,0.88194 -0.11759,1.5875 -0.26458,1.5875 -0.14552,0 
					-0.26459,0.53578 -0.26459,1.19062 0,0.65485 -0.11906,1.19063 -0.26458,1.19063 
					-0.14552,0 -0.26458,0.41672 -0.26458,0.92604 0,0.64676 -0.11969,0.92604 
					-0.39688,0.92604 -0.22048,0 -0.39687,0.23519 -0.39687,0.52917 0,0.29104 
					-0.11906,0.52917 -0.26459,0.52917 -0.14552,0 -0.26458,0.41671 -0.26458,0.92604 
					0,0.50932 -0.11906,0.92604 -0.26458,0.92604 -0.14552,0 -0.26459,0.29766 
					-0.26459,0.66146 0,0.41157 -0.14993,0.66146 -0.39687,0.66146 -0.24694,0 
					-0.39688,0.24988 -0.39688,0.66145 0,0.36381 -0.11906,0.66146 -0.26458,0.66146 
					-0.14552,0 -0.26458,0.23813 -0.26458,0.52917 0,0.29104 -0.11906,0.52917 
					-0.26459,0.52917 -0.14552,0 -0.26458,0.24662 -0.26458,0.54806 0,0.58577 
					-0.57252,1.30402 -1.03943,1.30402 -0.15592,0 -0.28349,0.27137 -0.28349,0.60305 
					0,0.33167 -0.23812,0.77468 -0.52916,0.98445 -0.29104,0.20977 -0.52917,0.65277 
					-0.52917,0.98445 0,0.33168 -0.12447,0.60305 -0.27661,0.60305 -0.35915,0 
					-1.57547,1.21632 -1.57547,1.57547 0,0.15214 -0.12448,0.27661 -0.27661,0.27661 
					-0.35916,0 -1.57548,1.21632 -1.57548,1.57548 0,0.15213 -0.12447,0.2766 
					-0.2766,0.2766 -0.35916,0 -1.57548,1.21632 -1.57548,1.57548 0,0.15213 
					-0.17859,0.27661 -0.39687,0.27661 -0.21829,0 -0.39688,0.11906 -0.39688,0.26458 
					0,0.14552 -0.23812,0.26458 -0.52917,0.26458 -0.29104,0 -0.52916,0.11907 
					-0.52916,0.26459 0,0.14552 -0.29766,0.26458 -0.66146,0.26458 -0.41158,0 
					-0.66146,0.14993 -0.66146,0.39688 0,0.3197 -0.41157,0.39687 -2.11667,0.39687 
					-1.70509,0 -2.11666,-0.0772 -2.11666,-0.39687 z m 74.08333,-0.66146 c 0,-0.14552 
					-0.41672,-0.26459 -0.92604,-0.26459 -0.50932,0 -0.92604,-0.11906 
					-0.92604,-0.26458 0,-0.14552 -0.23813,-0.26458 -0.52917,-0.26458 -0.29398,0 
					-0.52916,-0.17639 -0.52916,-0.39688 0,-0.21828 -0.12448,-0.39687 
					-0.27661,-0.39687 -0.35916,0 -1.57548,-1.21632 -1.57548,-1.57548 0,-0.15213 
					-0.12447,-0.27661 -0.27661,-0.27661 -0.48213,0 -1.57547,-1.27695 
					-1.57547,-1.84005 0,-0.30467 -0.17345,-0.54119 -0.39688,-0.54119 -0.24694,0 
					-0.39687,-0.24989 -0.39687,-0.66146 0,-0.36381 -0.11906,-0.66146 
					-0.26458,-0.66146 -0.14553,0 -0.26459,-0.23813 -0.26459,-0.52917 0,-0.29104 
					-0.11906,-0.52916 -0.26458,-0.52916 -0.14552,0 -0.26458,-0.41672 
					-0.26458,-0.92605 0,-0.64675 -0.1197,-0.92604 -0.39688,-0.92604 -0.27718,0 
					-0.39687,-0.27928 -0.39687,-0.92604 0,-0.50932 -0.11907,-0.92604 
					-0.26459,-0.92604 -0.14552,0 -0.26458,-0.53578 -0.26458,-1.19063 0,-0.65484 
					-0.11906,-1.19062 -0.26458,-1.19062 -0.14553,0 -0.26459,-0.59531 
					-0.26459,-1.32292 0,-0.99954 -0.097,-1.32291 -0.39687,-1.32291 -0.31498,0 
					-0.39688,-0.38218 -0.39688,-1.85209 0,-1.05833 -0.11339,-1.85208 
					-0.26458,-1.85208 -0.15875,0 -0.26458,-1.05833 -0.26458,-2.64583 0,-1.5875 
					-0.10584,-2.64584 -0.26459,-2.64584 -0.16685,0 -0.26458,-1.80798 
					-0.26458,-4.89479 0,-3.086805 0.0977,-4.89479 0.26458,-4.89479 0.15782,0 
					0.26459,-1.014235 0.26459,-2.513542 0,-1.499306 0.10676,-2.513541 
					0.26458,-2.513541 0.14552,0 0.26458,-0.416719 0.26458,-0.926042 0,-0.646758 
					0.11969,-0.926041 0.39688,-0.926041 0.22048,0 0.39687,-0.235186 
					0.39687,-0.529167 0,-0.291042 0.11906,-0.529167 0.26459,-0.529167 0.14552,0 
					0.26458,-0.246628 0.26458,-0.548066 0,-0.701886 0.60213,-1.304017 
					1.30402,-1.304017 0.30143,0 0.54806,-0.127566 0.54806,-0.283483 0,-0.537196 
					0.75793,-1.039434 1.5686,-1.039434 0.44696,0 0.81265,-0.119062 0.81265,-0.264583 
					0,-0.154339 0.88195,-0.264583 2.11667,-0.264583 1.23472,0 2.11667,0.110244 
					2.11667,0.264583 0,0.145521 0.41671,0.264583 0.92604,0.264583 0.50932,0 
					0.92604,0.119063 0.92604,0.264584 0,0.145521 0.29766,0.264583 0.66146,0.264583 
					0.41157,0 0.66146,0.149931 0.66146,0.396875 0,0.223425 0.23652,0.396875 
					0.54119,0.396875 0.5631,0 1.84006,1.093338 1.84006,1.575475 0,0.152135 
					0.12756,0.276608 0.28348,0.276608 0.42154,0 1.03943,0.696537 1.03943,1.171726 
					0,0.228677 0.11907,0.415774 0.26459,0.415774 0.14552,0 0.26458,0.178594 
					0.26458,0.396875 0,0.218281 0.17859,0.396875 0.39687,0.396875 0.22048,0 
					0.39688,0.235186 0.39688,0.529167 0,0.291041 0.11906,0.529166 0.26458,0.529166 
					0.14552,0 0.26459,0.297657 0.26459,0.661459 0,0.363802 0.11906,0.661458 
					0.26458,0.661458 0.14552,0 0.26458,0.297656 0.26458,0.661458 0,0.363802 
					0.11907,0.661459 0.26459,0.661459 0.14552,0 0.26458,0.416718 0.26458,0.926041 
					0,0.646758 0.1197,0.926042 0.39687,0.926042 0.27718,0 0.39688,0.279283 
					0.39688,0.926041 0,0.509323 0.11906,0.926042 0.26458,0.926042 0.14552,0 
					0.26459,0.535781 0.26459,1.190627 0,0.65484 0.11906,1.19062 0.26458,1.19062 
					0.14552,0 0.26458,0.65485 0.26458,1.45521 0,1.11713 0.0922,1.45521 
					0.39688,1.45521 0.32165,0 0.39687,0.42627 0.39687,2.24896 0,1.32291 
					0.10896,2.24896 0.26459,2.24896 0.16777,0 0.26458,1.98437 0.26458,5.42395 
					0,3.43959 -0.0968,5.42396 -0.26458,5.42396 -0.15434,0 -0.26459,0.88195 
					-0.26459,2.11667 0,1.70509 -0.0772,2.11667 -0.39687,2.11667 -0.27718,0 
					-0.39688,0.27928 -0.39688,0.92604 0,0.50932 -0.11906,0.92604 -0.26458,0.92604 
					-0.14552,0 -0.26458,0.41672 -0.26458,0.92604 0,0.50932 -0.11907,0.92604 
					-0.26459,0.92604 -0.14552,0 -0.26458,0.29766 -0.26458,0.66146 0,0.3638 
					-0.12756,0.66146 -0.28348,0.66146 -0.42857,0 -1.03944,0.69973 -1.03944,1.19062 
					0,0.60045 -0.64637,1.19063 -1.30402,1.19063 -0.30144,0 -0.54806,0.12756 
					-0.54806,0.28348 0,0.5372 -0.75793,1.03943 -1.5686,1.03943 -0.44696,0 
					-0.81265,0.11907 -0.81265,0.26459 0,0.15119 -0.79375,0.26458 -1.85208,0.26458 
					-1.05834,0 -1.85209,-0.11339 -1.85209,-0.26458 z M 234.47482,82.973677 c 
					0,-0.145521 -0.23813,-0.264583 -0.52917,-0.264583 -0.29104,0 -0.52917,-0.119063 
					-0.52917,-0.264583 0,-0.145521 -0.17859,-0.264584 -0.39687,-0.264584 -0.21828,0 
					-0.39688,-0.178593 -0.39688,-0.396875 0,-0.218281 -0.11906,-0.396875 
					-0.26458,-0.396875 -0.14552,0 -0.26458,-0.238125 -0.26458,-0.529166 0,-0.291042 
					-0.11907,-0.529167 -0.26459,-0.529167 -0.16631,0 -0.26458,-1.719792 
					-0.26458,-4.630208 0,-2.910417 0.0983,-4.630208 0.26458,-4.630208 0.14552,0 
					0.26459,-0.535781 0.26459,-1.190625 0,-0.654844 0.11906,-1.190625 
					0.26458,-1.190625 0.14552,0 0.26458,-0.416719 0.26458,-0.926042 0,-0.646758 
					0.11969,-0.926041 0.39688,-0.926041 0.27718,0 0.39687,-0.279284 
					0.39687,-0.926042 0,-0.509323 0.11906,-0.926042 0.26459,-0.926042 0.14552,0 
					0.26458,-0.390435 0.26458,-0.867632 0,-0.56411 0.18512,-1.001059 
					0.52917,-1.249034 0.29104,-0.209772 0.52916,-0.533712 0.52916,-0.719868 
					0,-0.186155 0.29766,-0.338465 0.66146,-0.338465 0.3638,0 0.66146,-0.119063 
					0.66146,-0.264584 0,-0.145521 0.53578,-0.264583 1.19062,-0.264583 0.65485,0 
					1.19063,0.119062 1.19063,0.264583 0,0.145521 0.29766,0.264584 0.66146,0.264584 
					0.3638,0 0.66146,0.127566 0.66146,0.283482 0,0.36631 0.67312,1.039434 
					1.03943,1.039434 0.15592,0 0.28348,0.297656 0.28348,0.661459 0,0.363802 
					0.11906,0.661458 0.26459,0.661458 0.14552,0 0.26458,0.416719 0.26458,0.926041 
					0,0.509323 0.11906,0.926042 0.26458,0.926042 0.16105,0 0.26459,1.190625 
					0.26459,3.042708 0,1.852083 -0.10354,3.042708 -0.26459,3.042708 -0.14552,0 
					-0.26458,0.535782 -0.26458,1.190625 0,0.654844 -0.11906,1.190625 
					-0.26458,1.190625 -0.14553,0 -0.26459,0.535782 -0.26459,1.190625 0,0.881944 
					-0.10289,1.190625 -0.39687,1.190625 -0.24695,0 -0.39688,0.249883 
					-0.39688,0.661458 0,0.363803 -0.11906,0.661459 -0.26458,0.661459 -0.14552,0 
					-0.26458,0.365691 -0.26458,0.812649 0,0.735039 -0.49211,1.568601 
					-0.92605,1.568601 -0.22076,0 -0.92604,0.705276 -0.92604,0.926041 0,0.368433 
					-0.7852,0.926042 -1.30402,0.926042 -0.30143,0 -0.54806,0.119062 
					-0.54806,0.264583 0,0.145521 -0.41672,0.264584 -0.92604,0.264584 -0.50933,0 
					-0.92604,-0.119063 -0.92604,-0.264584 z m -18.25626,-4.7625 c 0,-0.14552 
					-0.17859,-0.264583 -0.39687,-0.264583 -0.21828,0 -0.39688,-0.15231 
					-0.39688,-0.338465 0,-0.186156 -0.23812,-0.510096 -0.52916,-0.719868 
					-0.29104,-0.209772 -0.52917,-0.652775 -0.52917,-0.984451 0,-0.359654 
					-0.16018,-0.603049 -0.39687,-0.603049 -0.33624,0 -0.39688,-0.646758 
					-0.39688,-4.233333 0,-3.586575 0.0606,-4.233333 0.39688,-4.233333 0.29398,0 
					0.39687,-0.308682 0.39687,-1.190625 0,-0.654844 0.11906,-1.190625 
					0.26458,-1.190625 0.14553,0 0.26459,-0.416719 0.26459,-0.926042 0,-0.509323 
					0.11906,-0.926041 0.26458,-0.926041 0.14552,0 0.26458,-0.416719 
					0.26458,-0.926042 0,-0.646758 0.1197,-0.926042 0.39688,-0.926042 0.27718,0 
					0.39687,-0.279283 0.39687,-0.926041 0,-0.509323 0.11907,-0.926042 
					0.26459,-0.926042 0.14552,0 0.26458,-0.127566 0.26458,-0.283482 0,-0.366311 
					0.67313,-1.039435 1.03944,-1.039435 0.15591,0 0.28348,-0.119062 
					0.28348,-0.264583 0,-0.145521 0.23812,-0.264583 0.52917,-0.264583 0.29104,0 
					0.52916,-0.119063 0.52916,-0.264583 0,-0.145521 0.29766,-0.264584 
					0.66146,-0.264584 0.3638,0 0.66146,0.119063 0.66146,0.264584 0,0.14552 
					0.39043,0.264583 0.86763,0.264583 0.56411,0 1.00106,0.185118 1.24904,0.529166 
					0.20977,0.291042 0.53371,0.529167 0.71986,0.529167 0.18616,0 0.33847,0.297656 
					0.33847,0.661458 0,0.363802 0.11906,0.661459 0.26458,0.661459 0.14552,0 
					0.26459,0.297656 0.26459,0.661458 0,0.363802 0.11906,0.661458 0.26458,0.661458 
					0.16379,0 0.26458,1.41111 0.26458,3.704167 0,2.293056 -0.10079,3.704166 
					-0.26458,3.704166 -0.14552,0 -0.26458,0.535782 -0.26458,1.190625 0,0.654844 
					-0.11907,1.190625 -0.26459,1.190625 -0.14552,0 -0.26458,0.416719 
					-0.26458,0.926042 0,0.646758 -0.11969,0.926041 -0.39688,0.926041 -0.27718,0 
					-0.39687,0.279284 -0.39687,0.926042 0,0.509323 -0.11906,0.926042 
					-0.26458,0.926042 -0.14553,0 -0.26459,0.238125 -0.26459,0.529166 0,0.291042 
					-0.11906,0.529167 -0.26458,0.529167 -0.14552,0 -0.26458,0.297656 
					-0.26458,0.661458 0,0.363802 -0.12757,0.661459 -0.28349,0.661459 -0.36631,0 
					-1.03943,0.673123 -1.03943,1.039434 0,0.155916 -0.29766,0.283482 
					-0.66146,0.283482 -0.3638,0 -0.66146,0.119063 -0.66146,0.264583 0,0.145521 
					-0.65484,0.264584 -1.45521,0.264584 -0.80036,0 -1.45521,-0.119063 
					-1.45521,-0.264584 z M 93.716479,61.542428 c 0,-0.145521 -0.246629,-0.264583 
					-0.548066,-0.264583 -0.585769,0 -1.304017,-0.572519 -1.304017,-1.039434 
					0,-0.155916 -0.124474,-0.283483 -0.276609,-0.283483 -0.295024,0 
					-1.575475,-1.188291 -1.575475,-1.462079 0,-0.26923 -0.734981,-0.919171 
					-1.039434,-0.919171 -0.155916,0 -0.283483,-0.238125 -0.283483,-0.529166 
					0,-0.291042 -0.119062,-0.529167 -0.264583,-0.529167 -0.145521,0 
					-0.264583,-0.246628 -0.264583,-0.548066 0,-0.585769 -0.572519,-1.304017 
					-1.039434,-1.304017 -0.155917,0 -0.283483,-0.297656 -0.283483,-0.661458 
					0,-0.363802 -0.119062,-0.661459 -0.264583,-0.661459 -0.145521,0 
					-0.264584,-0.416718 -0.264584,-0.926041 0,-0.509323 -0.119062,-0.926042 
					-0.264583,-0.926042 -0.145521,0 -0.264583,-0.297656 -0.264583,-0.661458 
					0,-0.411575 -0.149932,-0.661458 -0.396875,-0.661458 -0.277183,0 
					-0.396875,-0.279284 -0.396875,-0.926042 0,-0.509323 -0.119063,-0.926042 
					-0.264584,-0.926042 -0.14552,0 -0.264583,-0.416718 -0.264583,-0.926041 
					0,-0.509323 -0.119062,-0.926042 -0.264583,-0.926042 -0.145521,0 
					-0.264584,-0.357187 -0.264584,-0.79375 0,-0.529167 -0.132291,-0.79375 
					-0.396875,-0.79375 -0.29986,0 -0.396875,-0.323379 -0.396875,-1.322916 
					0,-0.727605 -0.119062,-1.322917 -0.264583,-1.322917 -0.145521,0 
					-0.264583,-0.654844 -0.264583,-1.455208 0,-0.800365 -0.119063,-1.455209 
					-0.264584,-1.455209 -0.145521,0 -0.264583,-0.416718 -0.264583,-0.926041 
					0,-0.509323 0.119062,-0.926042 0.264583,-0.926042 0.145521,0 0.264584,-0.416719 
					0.264584,-0.926041 0,-0.509323 0.119062,-0.926042 0.264583,-0.926042 0.145521,0 
					0.264583,-0.297656 0.264583,-0.661458 0,-0.411576 0.149932,-0.661459 
					0.396875,-0.661459 0.218282,0 0.396875,-0.119062 0.396875,-0.264583 0,-0.145521 
					0.238125,-0.264583 0.529167,-0.264583 0.291042,0 0.529167,-0.119063 
					0.529167,-0.264584 0,-0.14552 0.595312,-0.264583 1.322916,-0.264583 0.999538,0 
					1.322917,-0.09701 1.322917,-0.396875 0,-0.218281 0.119063,-0.396875 
					0.264583,-0.396875 0.145521,0 0.264584,0.178594 0.264584,0.396875 0,0.293981 
					0.308681,0.396875 1.190625,0.396875 0.654844,0 1.190625,0.119063 
					1.190625,0.264583 0,0.145521 0.271372,0.264584 0.603049,0.264584 0.331676,0 
					0.774679,0.238125 0.984451,0.529166 0.209772,0.291042 0.652775,0.529167 
					0.984451,0.529167 0.359653,0 0.603049,0.160181 0.603049,0.396875 0,0.218281 
					0.119062,0.396875 0.264583,0.396875 0.145521,0 0.264584,0.238125 
					0.264584,0.529167 0,0.291041 0.119062,0.529166 0.264583,0.529166 0.145521,0 
					0.264583,0.178594 0.264583,0.396875 0,0.218281 0.127566,0.396875 
					0.283483,0.396875 0.428556,0 1.039434,0.699733 1.039434,1.190625 0,0.490892 
					0.610878,1.190625 1.039434,1.190625 0.155916,0 0.283483,0.297656 
					0.283483,0.661458 0,0.363802 0.119062,0.661459 0.264583,0.661459 0.145521,0 
					0.264583,0.238125 0.264583,0.529166 0,0.291042 0.119063,0.529167 
					0.264584,0.529167 0.14552,0 0.264583,0.416719 0.264583,0.926042 0,0.646758 
					0.119692,0.926041 0.396875,0.926041 0.246944,0 0.396875,0.249883 
					0.396875,0.661458 0,0.363803 0.119063,0.661459 0.264583,0.661459 0.145521,0 
					0.264584,0.535781 0.264584,1.190625 0,0.654843 0.119062,1.190625 
					0.264584,1.190625 0.14552,0 0.26458,0.416718 0.26458,0.926041 0,0.646758 
					0.11969,0.926042 0.39688,0.926042 0.30868,0 0.39687,0.352777 0.39687,1.5875 
					0,0.881943 0.11759,1.5875 0.26459,1.5875 0.15679,0 0.26458,0.970139 
					0.26458,2.38125 0,1.41111 -0.10779,2.381249 -0.26458,2.381249 -0.14553,0 
					-0.26459,0.416719 -0.26459,0.926042 0,0.509323 -0.12756,0.926042 
					-0.28348,0.926042 -0.30836,0 -1.039434,0.651324 -1.039434,0.926041 0,0.274717 
					-0.731078,0.926042 -1.039434,0.926042 -0.155917,0 -0.283483,0.119062 
					-0.283483,0.264583 0,0.15679 -0.97014,0.264584 -2.38125,0.264584 -1.41111,0 
					-2.38125,-0.107794 -2.38125,-0.264584 z M 268.0769,58.49972 c 0,-0.220485 
					-0.23519,-0.396875 -0.52917,-0.396875 -0.29104,0 -0.52916,-0.119062 
					-0.52916,-0.264583 0,-0.145521 -0.53579,-0.264584 -1.19063,-0.264584 -0.65484,0 
					-1.19062,-0.119062 -1.19062,-0.264583 0,-0.145521 -0.59532,-0.264583 
					-1.32292,-0.264583 -0.7276,0 -1.32292,-0.119063 -1.32292,-0.264583 0,-0.145521 
					-0.65484,-0.264584 -1.45521,-0.264584 -1.11712,0 -1.4552,-0.0922 
					-1.4552,-0.396875 0,-0.293981 -0.30869,-0.396875 -1.19063,-0.396875 -0.65484,0 
					-1.19062,-0.119062 -1.19062,-0.264583 0,-0.145521 -0.41672,-0.264583 
					-0.92605,-0.264583 -0.50932,0 -0.92604,-0.119063 -0.92604,-0.264584 0,-0.145521 
					-0.59531,-0.264583 -1.32291,-0.264583 -0.99954,0 -1.32292,-0.09702 
					-1.32292,-0.396875 0,-0.293981 -0.30868,-0.396875 -1.19063,-0.396875 -0.65484,0 
					-1.19062,-0.119062 -1.19062,-0.264583 0,-0.145521 -0.36569,-0.264584 
					-0.81265,-0.264584 -1.03309,0 -1.5686,-0.535511 -1.5686,-1.5686 0,-0.545965 
					-0.13024,-0.81265 -0.39688,-0.81265 -0.32758,0 -0.39687,-0.485068 
					-0.39687,-2.778124 0,-1.675694 -0.105,-2.778125 -0.26458,-2.778125 -0.14553,0 
					-0.26459,-0.297656 -0.26459,-0.661459 0,-0.363802 -0.11906,-0.661458 
					-0.26458,-0.661458 -0.14552,0 -0.26458,-0.238125 -0.26458,-0.529166 0,-0.291042 
					-0.11907,-0.529167 -0.26459,-0.529167 -0.14552,0 -0.26458,-0.178594 
					-0.26458,-0.396875 0,-0.218281 -0.12447,-0.396875 -0.27661,-0.396875 -0.35916,0 
					-1.57547,-1.216319 -1.57547,-1.575475 0,-0.152135 -0.1786,-0.276608 
					-0.39688,-0.276608 -0.21828,0 -0.39687,-0.119063 -0.39687,-0.264584 0,-0.14552 
					-0.23813,-0.264583 -0.52917,-0.264583 -0.29104,0 -0.52917,-0.119062 
					-0.52917,-0.264583 0,-0.145521 -0.41672,-0.264584 -0.92604,-0.264584 -0.64676,0 
					-0.92604,-0.119692 -0.92604,-0.396875 0,-0.32758 -0.48507,-0.396875 
					-2.77813,-0.396875 -2.29305,0 -2.77812,0.06929 -2.77812,0.396875 0,0.293982 
					-0.30868,0.396875 -1.19063,0.396875 -0.65484,0 -1.19062,0.119063 
					-1.19062,0.264584 0,0.145521 -0.41672,0.264583 -0.92604,0.264583 -0.50933,0 
					-0.92604,0.119063 -0.92604,0.264583 0,0.145521 -0.29766,0.264584 
					-0.66146,0.264584 -0.41158,0 -0.66146,0.149931 -0.66146,0.396875 0,0.246943 
					-0.24988,0.396875 -0.66146,0.396875 -0.3638,0 -0.66146,0.119062 
					-0.66146,0.264583 0,0.145521 -0.23812,0.264583 -0.52916,0.264583 -0.29105,0 
					-0.52917,0.119063 -0.52917,0.264584 0,0.14552 -0.29766,0.264583 
					-0.66146,0.264583 -0.41158,0 -0.66146,0.149931 -0.66146,0.396875 0,0.220485 
					-0.23518,0.396875 -0.52916,0.396875 -0.29105,0 -0.52917,0.119062 
					-0.52917,0.264583 0,0.145521 -0.1786,0.264584 -0.39688,0.264584 -0.21828,0 
					-0.39687,0.119062 -0.39687,0.264583 0,0.145521 -0.23813,0.264583 
					-0.52917,0.264583 -0.29398,0 -0.52916,0.17639 -0.52916,0.396875 0,0.218281 
					-0.12757,0.396875 -0.28349,0.396875 -0.42154,0 -1.03943,0.696537 
					-1.03943,1.171726 0,0.228677 -0.11906,0.415774 -0.26458,0.415774 -0.14553,0 
					-0.26459,0.178594 -0.26459,0.396875 0,0.218281 -0.17859,0.396875 
					-0.39687,0.396875 -0.21828,0 -0.39688,0.119062 -0.39688,0.264583 0,0.145521 
					-0.23812,0.264584 -0.52916,0.264584 -0.29105,0 -0.52917,0.119062 
					-0.52917,0.264583 0,0.151191 -0.79375,0.264583 -1.85209,0.264583 -1.05833,0 
					-1.85208,-0.113392 -1.85208,-0.264583 0,-0.145521 -0.53578,-0.264583 
					-1.19062,-0.264583 -0.65485,0 -1.19063,-0.119063 -1.19063,-0.264584 0,-0.145521 
					-0.59531,-0.264583 -1.32292,-0.264583 -0.99953,0 -1.32291,-0.09701 
					-1.32291,-0.396875 0,-0.220485 -0.23519,-0.396875 -0.52917,-0.396875 -0.29104,0 
					-0.52917,-0.119062 -0.52917,-0.264583 0,-0.145521 -0.41671,-0.264584 
					-0.92604,-0.264584 -0.50932,0 -0.92604,-0.119062 -0.92604,-0.264583 0,-0.145521 
					-0.41672,-0.264583 -0.92604,-0.264583 -0.50932,0 -0.92604,-0.119063 
					-0.92604,-0.264584 0,-0.14552 -0.29766,-0.264583 -0.66146,-0.264583 -0.41158,0 
					-0.66146,-0.149931 -0.66146,-0.396875 0,-0.236693 -0.24339,-0.396875 
					-0.60305,-0.396875 -0.33167,0 -0.77468,-0.238125 -0.98445,-0.529166 
					-0.20977,-0.291042 -0.59324,-0.529167 -0.85216,-0.529167 -0.25891,0 
					-0.47076,-0.178594 -0.47076,-0.396875 0,-0.225047 -0.23728,-0.396875 
					-0.54806,-0.396875 -0.70189,0 -1.30402,-0.602131 -1.30402,-1.304018 0,-0.310779 
					-0.17183,-0.548065 -0.39687,-0.548065 -0.27719,0 -0.39688,-0.279284 
					-0.39688,-0.926042 0,-0.646758 0.11969,-0.926042 0.39688,-0.926042 0.2659,0 
					0.39687,-0.265919 0.39687,-0.805775 0,-0.793498 0.98148,-2.104641 
					1.57548,-2.104641 0.15213,0 0.2766,-0.178594 0.2766,-0.396875 0,-0.218281 
					0.11907,-0.396875 0.26459,-0.396875 0.14552,0 0.26458,-0.211841 
					0.26458,-0.470757 0,-0.258916 0.23813,-0.642387 0.52917,-0.85216 
					0.29104,-0.209772 0.52916,-0.533712 0.52916,-0.719867 0,-0.186156 
					0.1786,-0.338466 0.39688,-0.338466 0.22048,0 0.39687,-0.235185 0.39687,-0.529167 
					0,-0.291041 0.11907,-0.529166 0.26459,-0.529166 0.14552,0 0.26458,-0.127566 
					0.26458,-0.283483 0,-0.308356 0.65133,-1.039434 0.92604,-1.039434 0.3327,0 
					0.92604,-0.762878 0.92604,-1.190625 0,-0.490892 0.61088,-1.190625 
					1.03944,-1.190625 0.15592,0 0.28348,-0.271372 0.28348,-0.603049 0,-0.331676 
					0.23813,-0.77468 0.52917,-0.984451 0.29104,-0.209771 0.52916,-0.533711 
					0.52916,-0.719867 0,-0.186156 0.13083,-0.338466 0.29073,-0.338466 0.15989,0 
					0.62438,-0.357187 1.03219,-0.79375 0.40781,-0.436562 0.8723,-0.79375 
					1.0322,-0.79375 0.15989,0 0.29072,-0.124474 0.29072,-0.27661 0,-0.359156 
					1.21632,-1.575473 1.57547,-1.575473 0.15214,0 0.27661,-0.130825 
					0.27661,-0.290722 0,-0.159897 0.35719,-0.624384 0.79375,-1.032195 
					0.43656,-0.40781 0.79375,-0.872298 0.79375,-1.032195 0,-0.159896 
					0.12447,-0.290721 0.27661,-0.290721 0.35916,0 1.57547,-1.216318 
					1.57547,-1.575474 0,-0.152135 0.29766,-0.27661 0.66146,-0.27661 0.36381,0 
					0.66146,-0.119062 0.66146,-0.264583 0,-0.145521 0.24663,-0.264583 
					0.54807,-0.264583 0.51881,0 1.30402,-0.55761 1.30402,-0.926042 0,-0.368432 
					0.7852,-0.926041 1.30401,-0.926041 0.30144,0 0.54807,-0.119063 0.54807,-0.264584 
					0,-0.145521 0.29765,-0.264583 0.66146,-0.264583 0.41157,0 0.66146,-0.149931 
					0.66146,-0.396875 0,-0.277183 0.27928,-0.396875 0.92604,-0.396875 0.50932,0 
					0.92604,-0.119062 0.92604,-0.264583 0,-0.145521 0.35719,-0.264584 
					0.79375,-0.264584 0.43656,0 0.79375,-0.119062 0.79375,-0.264583 0,-0.145521 
					0.59531,-0.264583 1.32292,-0.264583 0.7276,0 1.32291,-0.119063 1.32291,-0.264584 
					0,-0.15119 0.79375,-0.264583 1.85209,-0.264583 1.4699,0 1.85208,-0.08189 
					1.85208,-0.396875 0,-0.338476 0.72025,-0.396875 4.89479,-0.396875 4.17454,0 
					4.89479,0.0584 4.89479,0.396875 0,0.319705 0.41158,0.396875 2.11667,0.396875 
					1.23472,0 2.11667,0.110243 2.11667,0.264583 0,0.145521 0.41671,0.264584 
					0.92604,0.264584 0.50932,0 0.92604,0.119062 0.92604,0.264583 0,0.145521 
					0.53578,0.264583 1.19062,0.264583 0.65485,0 1.19063,0.119063 1.19063,0.264584 
					0,0.145521 0.29766,0.264583 0.66146,0.264583 0.41157,0 0.66146,0.149931 
					0.66146,0.396875 0,0.277182 0.27928,0.396875 0.92604,0.396875 0.50932,0 
					0.92604,0.119062 0.92604,0.264583 0,0.145521 0.23812,0.264584 0.52917,0.264584 
					0.29104,0 0.52916,0.119062 0.52916,0.264583 0,0.145521 0.29766,0.264583 
					0.66146,0.264583 0.41158,0 0.66146,0.149931 0.66146,0.396875 0,0.246945 
					0.24988,0.396875 0.66146,0.396875 0.3638,0 0.66146,0.119063 0.66146,0.264584 
					0,0.14552 0.18709,0.264583 0.41577,0.264583 0.47519,0 1.17173,0.617896 
					1.17173,1.039434 0,0.155915 0.15231,0.283482 0.33846,0.283482 0.18616,0 
					0.5101,0.238125 0.71987,0.529167 0.20977,0.291042 0.65277,0.529167 
					0.98445,0.529167 0.33168,0 0.60305,0.152309 0.60305,0.338466 0,0.186156 
					0.23812,0.510096 0.52917,0.719867 0.29104,0.209771 0.52916,0.652774 
					0.52916,0.984451 0,0.331677 0.12448,0.603049 0.27661,0.603049 0.35916,0 
					1.57548,1.216317 1.57548,1.575473 0,0.152136 0.12756,0.27661 0.28348,0.27661 
					0.42154,0 1.03943,0.696537 1.03943,1.171726 0,0.228676 0.11907,0.415774 
					0.26459,0.415774 0.14552,0 0.26458,0.297656 0.26458,0.661458 0,0.411575 
					0.14994,0.661459 0.39687,0.661459 0.24694,0 0.39688,0.249884 0.39688,0.661458 
					0,0.363802 0.11906,0.661458 0.26458,0.661458 0.14552,0 0.26459,0.238125 
					0.26459,0.529167 0,0.291042 0.11906,0.529167 0.26458,0.529167 0.14552,0 
					0.26458,0.297656 0.26458,0.661458 0,0.363802 0.11907,0.661458 0.26459,0.661458 
					0.14552,0 0.26458,0.297656 0.26458,0.661458 0,0.411576 0.14994,0.661459 
					0.39687,0.661459 0.27718,0 0.39688,0.279283 0.39688,0.926041 0,0.509323 
					0.11906,0.926042 0.26458,0.926042 0.14552,0 0.26459,0.357188 0.26459,0.79375 
					0,0.436562 0.11906,0.79375 0.26458,0.79375 0.14552,0 0.26458,0.595312 
					0.26458,1.322917 0,0.999537 0.097,1.322916 0.39688,1.322916 0.31498,0 
					0.39687,0.382175 0.39687,1.852083 0,1.058334 0.1134,1.852084 0.26459,1.852084 
					0.15118,0 0.26458,0.79375 0.26458,1.852083 0,1.058333 0.1134,1.852083 
					0.26458,1.852083 0.15679,0 0.26459,0.97014 0.26459,2.38125 0,1.41111 
					-0.1078,2.38125 -0.26459,2.38125 -0.16377,0 -0.26458,1.41111 -0.26458,3.704166 
					0,2.293057 -0.10081,3.704167 -0.26458,3.704167 -0.14552,0 -0.26459,0.416719 
					-0.26459,0.926041 v 0.926042 h -1.32291 c -0.99955,0 -1.32292,-0.09701 
					-1.32292,-0.396875 z M 162.77273,53.604929 c 0,-0.145521 -0.36569,-0.264584 
					-0.81265,-0.264584 -0.94175,0 -1.5686,-0.52112 -1.5686,-1.304017 0,-0.301437 
					-0.11906,-0.548066 -0.26458,-0.548066 -0.14552,0 -0.26459,-0.297656 
					-0.26459,-0.661458 0,-0.411575 -0.14993,-0.661458 -0.39687,-0.661458 -0.27718,0 
					-0.39688,-0.279284 -0.39688,-0.926042 0,-0.646758 0.1197,-0.926042 
					0.39688,-0.926042 0.29398,0 0.39687,-0.308681 0.39687,-1.190625 0,-0.654843 
					0.11907,-1.190625 0.26459,-1.190625 0.14552,0 0.26458,-0.238125 
					0.26458,-0.529166 0,-0.291042 0.11906,-0.529167 0.26458,-0.529167 0.14552,0 
					0.26459,-0.178594 0.26459,-0.396875 0,-0.218281 0.17859,-0.396875 
					0.39687,-0.396875 0.22049,0 0.39688,-0.235185 0.39688,-0.529166 0,-0.291042 
					0.11906,-0.529167 0.26458,-0.529167 0.14552,0 0.26458,-0.127566 
					0.26458,-0.283483 0,-0.308356 0.65133,-1.039434 0.92605,-1.039434 0.22076,0 
					0.92604,-0.705276 0.92604,-0.926041 0,-0.220766 0.70527,-0.926042 
					0.92604,-0.926042 0.22076,0 0.92604,-0.705276 0.92604,-0.926042 0,-0.220765 
					0.70528,-0.926041 0.92604,-0.926041 0.21714,0 0.92604,-0.703649 
					0.92604,-0.919171 0,-0.273788 1.28045,-1.462079 1.57548,-1.462079 0.16169,0 
					0.27661,-0.65954 0.27661,-1.5875 0,-1.234723 -0.0882,-1.5875 -0.39688,-1.5875 
					-0.21828,0 -0.39687,-0.119062 -0.39687,-0.264583 0,-0.145521 -0.35719,-0.264584 
					-0.79375,-0.264584 -0.43657,0 -0.79375,-0.119062 -0.79375,-0.264583 0,-0.162819 
					-1.32292,-0.264583 -3.43959,-0.264583 -2.88102,0 -3.43958,-0.06445 
					-3.43958,-0.396875 0,-0.314981 -0.38217,-0.396875 -1.85208,-0.396875 -1.05834,0 
					-1.85209,-0.113393 -1.85209,-0.264584 0,-0.14552 -0.23812,-0.264583 
					-0.52916,-0.264583 -0.47713,0 -0.52917,-0.17639 -0.52917,-1.793674 0,-1.490151 
					0.0895,-1.858214 0.52917,-2.175076 0.29104,-0.209771 0.52916,-0.533711 
					0.52916,-0.719867 0,-0.186156 0.1786,-0.338466 0.39688,-0.338466 0.21828,0 
					0.39687,-0.187098 0.39687,-0.415774 0,-0.534733 0.637,-1.171726 
					1.17173,-1.171726 0.22868,0 0.41577,-0.178594 0.41577,-0.396875 0,-0.218281 
					0.15231,-0.396875 0.33847,-0.396875 0.18615,0 0.51009,-0.238125 
					0.71987,-0.529166 0.20977,-0.291042 0.65277,-0.529167 0.98445,-0.529167 
					0.35965,0 0.60305,-0.160181 0.60305,-0.396875 0,-0.218281 0.11906,-0.396875 
					0.26458,-0.396875 0.14552,0 0.26458,-0.238125 0.26458,-0.529167 0,-0.291041 
					0.11907,-0.529166 0.26459,-0.529166 0.14552,0 0.26458,-0.127567 
					0.26458,-0.283483 0,-0.466916 -0.71825,-1.039434 -1.30402,-1.039434 -0.30143,0 
					-0.54806,-0.119062 -0.54806,-0.264583 0,-0.16463 -1.49931,-0.264584 
					-3.96875,-0.264584 -3.35139,0 -3.96875,-0.06174 -3.96875,-0.396875 0,-0.293981 
					-0.30868,-0.396875 -1.19063,-0.396875 -0.65484,0 -1.19062,-0.119062 
					-1.19062,-0.264583 0,-0.145521 -0.36569,-0.264583 -0.81265,-0.264583 -0.81068,0 
					-1.5686,-0.502237 -1.5686,-1.039435 0,-0.155915 -0.1786,-0.283482 
					-0.39688,-0.283482 -0.27718,0 -0.39687,-0.279282 -0.39687,-0.926041 0,-0.509323 
					-0.11907,-0.926042 -0.26459,-0.926042 -0.14552,0 -0.26458,-0.535781 
					-0.26458,-1.190625 0,-0.654844 0.11906,-1.190625 0.26458,-1.190625 0.14552,0 
					0.26459,-0.416719 0.26459,-0.926042 0,-0.5093224 0.12756,-0.9260411 
					0.28348,-0.9260411 0.42856,0 1.03943,-0.699733 1.03943,-1.190625 0,-0.490892 
					0.61088,-1.1906249 1.03944,-1.1906249 0.15591,0 0.28348,-0.1190625 
					0.28348,-0.2645833 0,-0.1455209 0.23813,-0.2645834 0.52917,-0.2645834 0.29398,0 
					0.52916,-0.1763889 0.52916,-0.3968749 0,-0.2182813 0.1786,-0.396875 
					0.39688,-0.396875 0.21828,0 0.39687,-0.1190625 0.39687,-0.2645833 0,-0.1455209 
					0.23813,-0.2645834 0.52917,-0.2645834 0.29104,0 0.52917,-0.1190624 
					0.52917,-0.2645833 0,-0.1455208 0.29765,-0.2645833 0.66146,-0.2645833 0.41157,0 
					0.66145,-0.1499306 0.66145,-0.396875 0,-0.2771825 0.27929,-0.396875 
					0.92605,-0.396875 0.50932,0 0.92604,-0.1190625 0.92604,-0.2645833 0,-0.1455208 
					0.41672,-0.2645833 0.92604,-0.2645833 0.50932,0 0.92604,-0.1190625 
					0.92604,-0.2645833 0,-0.1455208 0.41672,-0.2645833 0.92604,-0.2645833 0.64676,0 
					0.92604,-0.1196925 0.92604,-0.396875 0,-0.2939815 0.30869,-0.396875 
					1.19063,-0.396875 0.65484,0 1.19062,-0.1190625 1.19062,-0.2645833 0,-0.1455208 
					0.41672,-0.2645833 0.92605,-0.2645833 0.50932,0 0.92604,-0.1190625 
					0.92604,-0.26458336 0,-0.14552083 0.41672,-0.26458332 0.92604,-0.26458332 
					0.50932,0 0.92604,-0.11906249 0.92604,-0.26458332 0,-0.16973269 
					2.51354,-0.26458332 7.01146,-0.26458332 6.83507,0 7.01146,0.0133124 
					7.01146,0.52916664 0,0.29104165 -0.11907,0.52916668 -0.26459,0.52916668 
					-0.14552,0 -0.26458,0.2466294 -0.26458,0.5480654 0,0.5857687 -0.57252,1.3040178 
					-1.03943,1.3040178 -0.15592,0 -0.28349,0.2976562 -0.28349,0.6614583 0,0.4850693 
					-0.14111,0.6614583 -0.52916,0.6614583 -0.29104,0 -0.52917,0.1190625 
					-0.52917,0.2645833 0,0.1455209 -0.12757,0.2645833 -0.28348,0.2645833 -0.36631,0 
					-1.03944,0.6731251 -1.03944,1.0394344 0,0.1559153 -0.29765,0.2834822 
					-0.66145,0.2834822 -0.36381,0 -0.66146,0.1190625 -0.66146,0.2645834 0,0.1455208 
					-0.23813,0.2645833 -0.52917,0.2645833 -0.29104,0 -0.52917,0.1190625 
					-0.52917,0.2645833 0,0.1455208 -0.29765,0.2645833 -0.66145,0.2645833 -0.41158,0 
					-0.66146,0.1499307 -0.66146,0.396875 0,0.2771825 -0.27929,0.396875 
					-0.92604,0.396875 -0.50933,0 -0.92605,0.1190625 -0.92605,0.2645833 0,0.1455208 
					-0.29765,0.2645833 -0.66145,0.2645833 -0.36381,0 -0.66146,0.1190625 
					-0.66146,0.2645834 0,0.1455208 -0.23813,0.2645833 -0.52917,0.2645833 -0.29104,0 
					-0.52917,0.127567 -0.52917,0.2834822 0,0.3663093 -0.67312,1.0394339 
					-1.03943,1.0394339 -0.15592,0 -0.28348,0.297657 -0.28348,0.661459 0,0.411574 
					0.14993,0.661458 0.39687,0.661458 0.21828,0 0.39688,0.119063 0.39688,0.264583 
					0,0.149253 0.74965,0.264584 1.71979,0.264584 0.97014,0 1.71979,0.115331 
					1.71979,0.264583 0,0.145521 0.24663,0.264583 0.54807,0.264583 0.58577,0 
					1.30401,0.572518 1.30401,1.039435 0,0.155915 0.1786,0.283482 0.39688,0.283482 
					0.31498,0 0.39687,0.382176 0.39687,1.852083 0,1.469908 -0.0819,1.852083 
					-0.39687,1.852083 -0.22049,0 -0.39688,0.235186 -0.39688,0.529167 0,0.291042 
					-0.11906,0.529167 -0.26458,0.529167 -0.14552,0 -0.26458,0.127567 
					-0.26458,0.283482 0,0.366309 -0.67313,1.039434 -1.03944,1.039434 -0.15591,0 
					-0.28348,0.297657 -0.28348,0.661459 0,0.363802 -0.11906,0.661458 
					-0.26458,0.661458 -0.14552,0 -0.26459,0.416719 -0.26459,0.926042 0,0.921141 
					0.006,0.926041 1.19063,0.926041 0.88194,0 1.19062,-0.102893 1.19062,-0.396875 
					0,-0.246944 0.24989,-0.396875 0.66146,-0.396875 0.3638,0 0.66146,-0.119062 
					0.66146,-0.264583 0,-0.151191 0.79375,-0.264583 1.85208,-0.264583 1.05834,0 
					1.85209,0.113392 1.85209,0.264583 0,0.145521 0.29765,0.264583 0.66146,0.264583 
					0.41157,0 0.66145,0.149931 0.66145,0.396875 0,0.218281 0.11907,0.396875 
					0.26459,0.396875 0.14552,0 0.26458,0.357188 0.26458,0.79375 0,0.436563 
					0.11906,0.79375 0.26458,0.79375 0.147,0 0.26459,0.705556 0.26459,1.5875 
					0,0.881944 -0.11759,1.5875 -0.26459,1.5875 -0.14552,0 -0.26458,0.416719 
					-0.26458,0.926042 0,0.509322 -0.11906,0.926041 -0.26458,0.926041 -0.14552,0 
					-0.26459,0.297656 -0.26459,0.661458 0,0.411576 -0.14993,0.661459 
					-0.39687,0.661459 -0.22049,0 -0.39688,0.235185 -0.39688,0.529166 0,0.291042 
					-0.11906,0.529167 -0.26458,0.529167 -0.14552,0 -0.26458,0.297656 
					-0.26458,0.661458 0,0.363802 -0.11907,0.661459 -0.26459,0.661459 -0.14552,0 
					-0.26458,0.238125 -0.26458,0.529166 0,0.293981 -0.17639,0.529167 
					-0.39687,0.529167 -0.24695,0 -0.39688,0.249883 -0.39688,0.661458 0,0.363802 
					-0.11906,0.661459 -0.26458,0.661459 -0.14552,0 -0.26459,0.297656 
					-0.26459,0.661458 0,0.363802 -0.11906,0.661458 -0.26458,0.661458 -0.14552,0 
					-0.26458,0.238125 -0.26458,0.529167 0,0.291041 0.11906,0.529166 0.26458,0.529166 
					0.14552,0 0.26458,0.178594 0.26458,0.396875 0,0.277183 0.27929,0.396875 
					0.92605,0.396875 0.50932,0 0.92604,-0.127566 0.92604,-0.283482 0,-0.36631 
					0.67312,-1.039434 1.03943,-1.039434 0.15592,0 0.28348,-0.119063 
					0.28348,-0.264584 0,-0.14552 0.23813,-0.264583 0.52917,-0.264583 0.29104,0 
					0.52917,-0.127566 0.52917,-0.283482 0,-0.466916 0.71825,-1.039434 
					1.30401,-1.039434 0.30144,0 0.54807,-0.119063 0.54807,-0.264584 0,-0.145521 
					0.41672,-0.264583 0.92604,-0.264583 0.64676,0 0.92604,-0.119692 
					0.92604,-0.396875 0,-0.308682 0.35278,-0.396875 1.5875,-0.396875 1.23473,0 
					1.5875,0.08819 1.5875,0.396875 0,0.265901 0.26592,0.396875 0.80578,0.396875 
					0.7935,0 2.10464,0.981482 2.10464,1.575475 0,0.152135 0.17859,0.276608 
					0.39688,0.276608 0.22048,0 0.39687,0.235186 0.39687,0.529167 0,0.291041 
					0.11906,0.529166 0.26458,0.529166 0.14553,0 0.26459,0.595313 0.26459,1.322917 
					0,0.727604 -0.11906,1.322917 -0.26459,1.322917 -0.14552,0 -0.26458,0.416718 
					-0.26458,0.926041 0,0.646758 -0.11969,0.926042 -0.39687,0.926042 -0.22049,0 
					-0.39688,0.235185 -0.39688,0.529167 0,0.291041 -0.11906,0.529166 
					-0.26458,0.529166 -0.14552,0 -0.26459,0.127566 -0.26459,0.283483 0,0.304453 
					-0.64994,1.039434 -0.91917,1.039434 -0.27378,0 -1.46208,1.280451 
					-1.46208,1.575474 0,0.152136 -0.15231,0.276609 -0.33846,0.276609 -0.18616,0 
					-0.5101,0.238125 -0.71987,0.529167 -0.20977,0.291041 -0.65277,0.529166 
					-0.98445,0.529166 -0.33168,0 -0.60305,0.127566 -0.60305,0.283483 0,0.466915 
					-0.71825,1.039434 -1.30402,1.039434 -0.30143,0 -0.54806,0.119062 
					-0.54806,0.264583 0,0.145521 -0.23813,0.264584 -0.52917,0.264584 -0.29398,0 
					-0.52917,0.176389 -0.52917,0.396874 0,0.246944 -0.24988,0.396875 
					-0.66145,0.396875 -0.36381,0 -0.66146,0.119063 -0.66146,0.264584 0,0.145521 
					-0.23813,0.264583 -0.52917,0.264583 -0.29104,0 -0.52917,0.119063 
					-0.52917,0.264583 0,0.145521 -0.41671,0.264584 -0.92604,0.264584 -0.64676,0 
					-0.92604,0.119692 -0.92604,0.396875 0,0.277183 -0.27928,0.396875 
					-0.92604,0.396875 -0.50932,0 -0.92604,0.119062 -0.92604,0.264583 0,0.145521 
					-0.59532,0.264583 -1.32292,0.264583 -0.7276,0 -1.32292,0.119063 
					-1.32292,0.264584 0,0.166854 -1.80798,0.264583 -4.89479,0.264583 -3.0868,0 
					-4.89479,-0.09773 -4.89479,-0.264583 z m -45.77292,-1.190625 c 0,-0.246944 
					-0.24988,-0.396875 -0.66146,-0.396875 -0.3638,0 -0.66145,-0.119063 
					-0.66145,-0.264584 0,-0.14552 -0.24663,-0.264583 -0.54807,-0.264583 -0.58577,0 
					-1.30402,-0.572518 -1.30402,-1.039434 0,-0.155916 -0.11906,-0.283482 
					-0.26458,-0.283482 -0.14552,0 -0.26458,-0.238125 -0.26458,-0.529167 0,-0.291042 
					-0.11907,-0.529167 -0.26459,-0.529167 -0.14552,0 -0.26458,-0.178594 
					-0.26458,-0.396875 0,-0.218281 -0.17859,-0.396875 -0.39688,-0.396875 -0.27718,0 
					-0.39687,-0.279283 -0.39687,-0.926041 0,-0.509323 -0.11906,-0.926042 
					-0.26458,-0.926042 -0.14552,0 -0.26459,-0.357187 -0.26459,-0.79375 0,-0.436562 
					-0.11906,-0.79375 -0.26458,-0.79375 -0.16711,0 -0.26458,-1.852083 
					-0.26458,-5.027083 0,-3.175 0.0975,-5.027083 0.26458,-5.027083 0.15434,0 
					0.26458,-0.881944 0.26458,-2.116667 0,-1.234723 0.11025,-2.116666 
					0.26459,-2.116666 0.14552,0 0.26458,-0.654844 0.26458,-1.455208 0,-1.117129 
					0.0922,-1.455209 0.39687,-1.455209 0.29986,0 0.39688,-0.323379 0.39688,-1.322916 
					0,-0.727604 0.11906,-1.322917 0.26458,-1.322917 0.14552,0 0.26459,-0.357187 
					0.26459,-0.79375 0,-0.436562 0.11906,-0.79375 0.26458,-0.79375 0.14552,0 
					0.26458,-0.416719 0.26458,-0.926041 0,-0.509323 0.11907,-0.926042 
					0.26459,-0.926042 0.14552,0 0.26458,-0.416719 0.26458,-0.926042 0,-0.646759 
					0.11969,-0.926041 0.39687,-0.926041 0.27719,0 0.39688,-0.279283 
					0.39688,-0.926042 0,-0.509323 0.11906,-0.926042 0.26458,-0.926042 0.14552,0 
					0.26459,-0.416718 0.26459,-0.926041 0,-0.509323 0.11906,-0.926042 
					0.26458,-0.926042 0.14552,0 0.26458,-0.297656 0.26458,-0.661458 0,-0.411574 
					0.14993,-0.661458 0.39688,-0.661458 0.27209,0 0.39687,-0.272793 
					0.39687,-0.867633 0,-0.56411 0.18512,-1.001057 0.52917,-1.249034 
					0.29104,-0.209771 0.52917,-0.652774 0.52917,-0.984451 0,-0.331677 
					0.12447,-0.603049 0.2766,-0.603049 0.35916,0 1.57548,-1.2163175 
					1.57548,-1.5754732 0,-0.1521354 0.29766,-0.2766099 0.66146,-0.2766099 0.3638,0 
					0.66146,-0.1190625 0.66146,-0.2645833 0,-0.1455209 0.29765,-0.2645834 
					0.66145,-0.2645834 0.36381,0 0.66146,-0.1190625 0.66146,-0.2645833 0,-0.1543402 
					0.88195,-0.2645833 2.11667,-0.2645833 1.23472,0 2.11667,0.1102431 
					2.11667,0.2645833 0,0.1455208 0.36569,0.2645833 0.81265,0.2645833 0.73503,0 
					1.5686,0.492103 1.5686,0.9260417 0,0.2207646 0.70527,0.9260411 0.92604,0.9260411 
					0.22076,0 0.92604,0.705277 0.92604,0.926042 0,0.274718 0.73108,0.926042 
					1.03943,0.926042 0.15592,0 0.28349,0.238125 0.28349,0.529166 0,0.291042 
					0.11906,0.529167 0.26458,0.529167 0.14552,0 0.26458,0.297656 0.26458,0.661458 
					0,0.363802 0.11907,0.661459 0.26459,0.661459 0.14552,0 0.26458,0.416718 
					0.26458,0.926041 0,0.646759 0.11969,0.926042 0.39687,0.926042 0.30869,0 
					0.39688,0.352778 0.39688,1.5875 0,0.881944 0.11759,1.5875 0.26458,1.5875 
					0.16228,0 0.26459,1.278819 0.26459,3.307291 0,2.028472 -0.10231,3.307292 
					-0.26459,3.307292 -0.15434,0 -0.26458,0.881943 -0.26458,2.116666 0,1.705092 
					-0.0772,2.116667 -0.39688,2.116667 -0.30868,0 -0.39687,0.352777 -0.39687,1.5875 
					0,0.881943 -0.11759,1.5875 -0.26458,1.5875 -0.14552,0 -0.26459,0.416718 
					-0.26459,0.926041 0,0.509323 -0.11906,0.926042 -0.26458,0.926042 -0.14552,0 
					-0.26458,0.416719 -0.26458,0.926042 0,0.646758 -0.1197,0.926041 
					-0.39688,0.926041 -0.27718,0 -0.39687,0.279284 -0.39687,0.926042 0,0.509323 
					-0.11907,0.926041 -0.26459,0.926041 -0.14552,0 -0.26458,0.416719 
					-0.26458,0.926042 0,0.509323 -0.11906,0.926042 -0.26458,0.926042 -0.14552,0 
					-0.26459,0.416718 -0.26459,0.926041 0,0.646758 -0.11969,0.926042 
					-0.39687,0.926042 -0.22049,0 -0.39688,0.235185 -0.39688,0.529167 0,0.291041 
					-0.11906,0.529166 -0.26458,0.529166 -0.14552,0 -0.26458,0.297656 
					-0.26458,0.661458 0,0.363803 -0.11907,0.661459 -0.26459,0.661459 -0.14552,0 
					-0.26458,0.238125 -0.26458,0.529166 0,0.293982 -0.17639,0.529167 
					-0.39688,0.529167 -0.23669,0 -0.39687,0.243396 -0.39687,0.603049 0,0.331676 
					-0.23813,0.774679 -0.52917,0.984451 -0.29104,0.209772 -0.52916,0.533712 
					-0.52916,0.719868 0,0.186155 -0.12448,0.338465 -0.27661,0.338465 -0.35916,0 
					-1.57548,1.216319 -1.57548,1.575475 0,0.152135 -0.15231,0.276608 
					-0.33846,0.276608 -0.18616,0 -0.5101,0.238125 -0.71987,0.529167 
					-0.20977,0.291042 -0.59324,0.529167 -0.85216,0.529167 -0.25892,0 
					-0.47076,0.178594 -0.47076,0.396875 0,0.32758 -0.48507,0.396875 
					-2.77812,0.396875 -2.29306,0 -2.77813,-0.0693 -2.77813,-0.396875 z" />
			</g>
		</svg>`;
  // *INDENT-ON*
}
export function OffscreenBandEnd1Blobs(rotation: number = 0): string {
  const title = 'Band End Artwork';
  const viewWidth = 297.92083;
  const viewHeight = 270.93334;
  // *INDENT-OFF*
  return `<svg
			width="1126"
			height="1024"
			viewBox="0 0 ${viewWidth} ${viewHeight}"
			xmlns="http://www.w3.org/2000/svg"
							>
			<title>${title}</title>
			<g transform="rotate(${rotation} ${viewWidth * 0.5} ${viewHeight * 0.5})">
				<path style="fill:#676FF4;stroke-width:0.264583"
					d="m 227.02567,225.87806 c -0.29104,-0.12963 -0.91612,-0.24407 
					-1.38906,-0.25429 -0.5797,-0.0125 -0.8599,-0.14792 -0.8599,-0.41548 0,-0.22048 
					-0.23518,-0.39687 -0.52917,-0.39687 -0.29104,0 -0.52916,-0.11906 
					-0.52916,-0.26458 0,-0.14552 -0.24354,-0.26459 -0.54119,-0.26459 -0.63531,0 
					-1.84006,-1.11814 -1.84006,-1.70776 0,-0.2249 -0.11906,-0.4089 -0.26459,-0.4089 
					-0.14552,0 -0.26458,-0.35719 -0.26458,-0.79375 0,-0.52917 -0.13229,-0.79375 
					-0.39687,-0.79375 -0.3175,0 -0.39688,-0.39688 -0.39688,-1.98438 0,-1.5875 
					0.0794,-1.98437 0.39688,-1.98437 0.27718,0 0.39687,-0.27929 0.39687,-0.92604 
					0,-0.50933 0.11906,-0.92605 0.26458,-0.92605 0.14553,0 0.26459,-0.29765 
					0.26459,-0.66145 0,-0.36381 0.11906,-0.66146 0.26458,-0.66146 0.14552,0 
					0.26458,-0.23813 0.26458,-0.52917 0,-0.29104 0.11907,-0.52917 0.26459,-0.52917 
					0.14552,0 0.26458,-0.17859 0.26458,-0.39687 0,-0.21828 0.12303,-0.39688 
					0.2734,-0.39688 0.35572,0 2.10785,-1.75213 2.10785,-2.10784 0,-0.15037 
					0.13083,-0.27341 0.29072,-0.27341 0.1599,0 0.60878,-0.34047 0.99751,-0.75661 
					0.38873,-0.41614 0.98801,-0.84587 1.33174,-0.95497 0.48359,-0.15348 
					0.54224,-0.25074 0.25926,-0.42996 -0.29965,-0.18978 -0.29108,-0.23233 
					0.0474,-0.23566 0.22723,-0.002 0.64394,-0.21241 0.92605,-0.46707 0.2821,-0.25466 
					0.96574,-0.67248 1.5192,-0.92848 0.55346,-0.25601 0.93339,-0.58343 
					0.84428,-0.72761 -0.0909,-0.14704 0.13526,-0.26214 0.51506,-0.26214 0.37239,0 
					0.67707,-0.10098 0.67707,-0.2244 0,-0.12341 0.50602,-0.44755 1.12448,-0.7203 
					0.61847,-0.27274 1.18737,-0.58255 1.26424,-0.68846 0.19682,-0.27117 
					3.85402,-1.80642 4.30317,-1.80642 0.204,0 0.64546,-0.23812 0.98103,-0.52917 
					0.33558,-0.29104 0.826,-0.52916 1.08984,-0.52916 0.26384,0 0.8707,-0.16715 
					1.34858,-0.37145 0.47788,-0.20429 1.39064,-0.5262 2.02835,-0.71536 
					0.63772,-0.18915 1.29256,-0.47294 1.45521,-0.63064 0.16265,-0.1577 
					0.71779,-0.54478 1.23363,-0.86017 0.99811,-0.61025 2.16721,-1.55871 
					2.09772,-1.70182 -0.0959,-0.19762 0.63924,-1.5414 0.84331,-1.5414 0.12216,0 
					0.22211,-0.30468 0.22211,-0.67707 0,-0.384 -0.11452,-0.60629 -0.26458,-0.51355 
					-0.14552,0.0899 -0.26458,-0.002 -0.26458,-0.20426 0,-0.20228 -0.32743,-0.53653 
					-0.72761,-0.74279 -0.40018,-0.20625 -0.82282,-0.52403 -0.93919,-0.70616 
					-0.14341,-0.22447 -0.96953,-0.33117 -2.56407,-0.33117 -1.29387,0 
					-2.42607,-0.11906 -2.51601,-0.26458 -0.0899,-0.14552 -0.69228,-0.26458 
					-1.33853,-0.26458 -0.64626,0 -1.17501,-0.11906 -1.17501,-0.26459 0,-0.14552 
					-0.23813,-0.26458 -0.52917,-0.26458 -0.29104,0 -0.52917,-0.11906 
					-0.52917,-0.26458 0,-0.14552 -0.29765,-0.26458 -0.66145,-0.26458 -0.41158,0 
					-0.66146,-0.14994 -0.66146,-0.39688 0,-0.21828 -0.21184,-0.39687 
					-0.47076,-0.39687 -0.25891,0 -0.64239,-0.23813 -0.85216,-0.52917 
					-0.20977,-0.29104 -0.53371,-0.52917 -0.71987,-0.52917 -0.18615,0 
					-0.33846,-0.29765 -0.33846,-0.66146 0,-0.3638 -0.11906,-0.66145 
					-0.26459,-0.66145 -0.14552,0 -0.26458,-0.23813 -0.26458,-0.52917 0,-0.29104 
					-0.10483,-0.52917 -0.23295,-0.52917 -0.12813,0 -0.25293,-1.25015 
					-0.27734,-2.77812 -0.0286,-1.78907 0.0544,-2.77813 0.23296,-2.77813 0.15253,0 
					0.27733,-0.35719 0.27733,-0.79375 0,-0.43656 0.11906,-0.79375 0.26458,-0.79375 
					0.14553,0 0.26459,-0.29765 0.26459,-0.66146 0,-0.39106 0.15165,-0.66145 
					0.37099,-0.66145 0.20405,0 0.49509,-0.35599 0.64677,-0.79109 0.15167,-0.43509 
					0.58004,-1.09535 0.95192,-1.46723 0.37188,-0.37189 0.67615,-0.80157 
					0.67615,-0.95485 0,-0.15328 0.36978,-0.62412 0.82173,-1.04631 0.45195,-0.42218 
					0.73503,-0.76761 0.62906,-0.76761 -0.10596,0 -0.0445,-0.17859 0.13671,-0.39687 
					0.18116,-0.21828 0.24677,-0.39688 0.14579,-0.39688 -0.10097,0 -0.0262,-0.17446 
					0.16604,-0.38769 1.20357,-1.3346 1.54025,-2.09637 1.54025,-3.48489 0,-1.25626 
					-1.11094,-2.21123 -2.57609,-2.21443 -0.96175,-0.002 -3.49904,1.10043 
					-3.50481,1.52295 -0.002,0.1819 -0.24264,0.33073 -0.53368,0.33073 -0.29104,0 
					-0.52917,0.10555 -0.52917,0.23455 0,0.24116 -0.51188,0.37196 -3.38145,0.86407 
					-1.78123,0.30547 -5.61438,0.0819 -5.61438,-0.32744 0,-0.13311 -0.23252,-0.24201 
					-0.51672,-0.24201 -0.77475,0 -1.86453,-0.56607 -1.86453,-0.96852 0,-0.19492 
					-0.12447,-0.3544 -0.27661,-0.3544 -0.42472,0 -1.57547,-1.24738 -1.57547,-1.70777 
					0,-0.22489 -0.11906,-0.4089 -0.26459,-0.4089 -0.14552,0 -0.26458,-0.29765 
					-0.26458,-0.66146 0,-0.41157 -0.14993,-0.66145 -0.39687,-0.66145 -0.27719,0 
					-0.39688,-0.27929 -0.39688,-0.92605 0,-0.50932 -0.11906,-0.92604 
					-0.26458,-0.92604 -0.16176,0 -0.26459,-1.23992 -0.26459,-3.19061 0,-2.0597 
					0.0938,-3.13266 0.26459,-3.02709 0.16307,0.10078 0.26458,-0.35004 
					0.26458,-1.17501 0,-1.01342 0.0964,-1.33854 0.39688,-1.33854 0.27718,0 
					0.39687,-0.27928 0.39687,-0.92604 0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 
					0.14553,0 0.26459,-0.29766 0.26459,-0.66146 0,-0.3638 0.11906,-0.66146 
					0.26458,-0.66146 0.14552,0 0.26458,-0.29765 0.26458,-0.66145 0,-0.36381 
					0.11907,-0.66146 0.26459,-0.66146 0.14552,0 0.26458,-0.29766 0.26458,-0.66146 
					0,-0.41158 0.14993,-0.66146 0.39688,-0.66146 0.22048,0 0.39687,-0.23518 
					0.39687,-0.52917 0,-0.29104 0.11906,-0.52916 0.26458,-0.52916 0.14553,0 
					0.26459,-0.29766 0.26459,-0.66146 0,-0.3638 0.11906,-0.66146 0.26458,-0.66146 
					0.14552,0 0.26458,-0.23812 0.26458,-0.52917 0,-0.29104 0.11907,-0.52916 
					0.26459,-0.52916 0.14552,0 0.26458,-0.29766 0.26458,-0.66146 0,-0.41158 
					0.14993,-0.66146 0.39688,-0.66146 0.21828,0 0.39687,-0.17859 0.39687,-0.39687 
					0,-0.21829 0.11906,-0.39688 0.26458,-0.39688 0.14553,0 0.26459,-0.23812 
					0.26459,-0.52917 0,-0.29104 0.11906,-0.52916 0.26458,-0.52916 0.14552,0 
					0.26458,-0.29766 0.26458,-0.66146 0,-0.41158 0.14994,-0.66146 0.39688,-0.66146 
					0.24694,0 0.39687,-0.24988 0.39687,-0.66146 0,-0.3638 0.11907,-0.66146 
					0.26459,-0.66146 0.14552,0 0.26458,-0.23812 0.26458,-0.52916 0,-0.29104 
					0.11906,-0.52917 0.26458,-0.52917 0.14553,0 0.26459,-0.41672 0.26459,-0.92604 
					0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 0.14552,0 0.26458,-0.41672 
					0.26458,-0.92604 0,-0.64676 0.1197,-0.92605 0.39688,-0.92605 0.30467,0 
					0.39687,-0.33807 0.39687,-1.4552 0,-1.11713 -0.0922,-1.45521 -0.39687,-1.45521 
					-0.24694,0 -0.39688,-0.24989 -0.39688,-0.66146 0,-0.48507 -0.14111,-0.66146 
					-0.52916,-0.66146 -0.29104,0 -0.52917,-0.11906 -0.52917,-0.26458 0,-0.14552 
					-0.41672,-0.26459 -0.92604,-0.26459 -0.50932,0 -0.92604,0.11907 -0.92604,0.26459 
					0,0.14552 -0.29766,0.26458 -0.66146,0.26458 -0.41158,0 -0.66146,0.14993 
					-0.66146,0.39688 0,0.21828 -0.1871,0.39687 -0.41577,0.39687 -0.58364,0 
					-1.17173,0.65449 -1.17173,1.30402 0,0.30144 -0.13082,0.54806 -0.29072,0.54806 
					-0.1599,0 -0.62439,0.35719 -1.0322,0.79375 -0.40781,0.43657 -0.99136,0.79375 
					-1.29677,0.79375 -0.30542,0 -0.55531,0.12448 -0.55531,0.27661 0,0.42332 
					-1.24669,1.57548 -1.70474,1.57548 -0.51722,0 -1.4862,0.81849 -1.3088,1.10553 
					0.0739,0.11956 -0.17033,0.21738 -0.54272,0.21738 -0.37239,0 -0.67707,0.11907 
					-0.67707,0.26459 0,0.14552 -0.29063,0.26458 -0.64584,0.26458 -0.35522,0 
					-0.71943,0.11906 -0.80937,0.26458 -0.0899,0.14553 -0.43034,0.26459 
					-0.75645,0.26459 -0.32611,0 -0.70604,0.11192 -0.84428,0.24871 -0.13824,0.13679 
					-0.84667,0.28562 -1.57427,0.33073 -0.88438,0.0548 -1.30099,0.19165 
					-1.25677,0.41275 0.10234,0.51172 -4.40771,0.45959 -4.79948,-0.0555 
					-0.1795,-0.23599 -0.83402,-0.40875 -1.68281,-0.44416 -0.76399,-0.0319 
					-1.38907,-0.15575 -1.38907,-0.27526 0,-0.11952 -0.23812,-0.2173 -0.52916,-0.2173 
					-0.29104,0 -0.52917,-0.11906 -0.52917,-0.26459 0,-0.14552 -0.29766,-0.26458 
					-0.66146,-0.26458 -0.3638,0 -0.66146,-0.11906 -0.66146,-0.26458 0,-0.14552 
					-0.17859,-0.26459 -0.39687,-0.26459 -0.21828,0 -0.39688,-0.17859 
					-0.39688,-0.39687 0,-0.21828 -0.184,-0.39688 -0.4089,-0.39688 -0.56958,0 
					-1.68265,-1.1719 -2.01292,-2.11933 -0.15168,-0.4351 -0.44272,-0.79108 
					-0.64677,-0.79108 -0.20404,0 -0.37099,-0.23813 -0.37099,-0.52917 0,-0.29104 
					-0.10656,-0.52917 -0.23679,-0.52917 -0.24471,0 -0.44935,-1.09442 
					-0.71739,-3.83645 -0.14207,-1.45348 -0.0403,-2.19129 0.88543,-6.41615 
					0.0717,-0.32742 0.29515,-0.59531 0.49647,-0.59531 0.21404,0 0.36603,-0.27466 
					0.36603,-0.66146 0,-0.3638 0.11906,-0.66146 0.26458,-0.66146 0.14552,0 
					0.26459,-0.23812 0.26459,-0.52916 0,-0.29105 0.0951,-0.52917 0.21143,-0.52917 
					0.11629,0 0.36212,-0.41672 0.54629,-0.92604 0.18418,-0.50933 0.45472,-0.92604 
					0.60122,-0.92604 0.35236,0 1.02231,-0.68117 1.02231,-1.03944 0,-0.15592 
					0.11906,-0.28348 0.26458,-0.28348 0.14552,0 0.27398,-0.26789 0.28547,-0.59531 
					0.0115,-0.32743 0.0798,-0.44987 0.15184,-0.2721 0.14507,0.35807 1.41477,-0.75229 
					1.41477,-1.23723 0,-0.15214 0.11104,-0.27661 0.24676,-0.27661 0.13571,0 
					0.32732,-0.25386 0.4258,-0.56413 0.14065,-0.44315 0.22871,-0.4857 
					0.41065,-0.19843 0.16722,0.26402 0.23273,0.27374 0.23566,0.035 0.002,-0.1819 
					0.18264,-0.33072 0.40093,-0.33072 0.21828,0 0.39687,-0.12448 0.39687,-0.27661 
					0,-0.35916 1.21632,-1.57548 1.57548,-1.57548 0.15213,0 0.2766,-0.10517 
					0.2766,-0.23372 0,-0.12855 0.25011,-0.29913 0.55579,-0.37907 0.30604,-0.08 
					0.47736,-0.27224 0.38126,-0.42773 -0.096,-0.15532 0.0111,-0.28239 
					0.23796,-0.28239 0.22687,0 0.41249,-0.11907 0.41249,-0.26459 0,-0.14552 
					0.1786,-0.26458 0.39688,-0.26458 0.21828,0 0.39687,-0.11906 0.39687,-0.26458 
					0,-0.14553 0.23813,-0.26459 0.52917,-0.26459 0.29104,0 0.52917,-0.11906 
					0.52917,-0.26458 0,-0.14552 0.17859,-0.26458 0.39687,-0.26458 0.21828,0 
					0.39688,-0.1786 0.39688,-0.39688 0,-0.21828 0.21184,-0.39687 0.47075,-0.39687 
					0.25892,0 0.64239,-0.23813 0.85216,-0.52917 0.20978,-0.29104 0.65278,-0.52917 
					0.98446,-0.52917 0.33167,0 0.60304,-0.11104 0.60304,-0.24675 0,-0.13572 
					0.25386,-0.32732 0.56413,-0.4258 0.44315,-0.14065 0.4857,-0.22872 
					0.19844,-0.41065 -0.26085,-0.16521 -0.15709,-0.23277 0.36191,-0.23566 
					0.40019,-0.002 0.72761,-0.12312 0.72761,-0.26864 0,-0.14552 0.23812,-0.26458 
					0.52916,-0.26458 0.29105,0 0.52917,-0.11906 0.52917,-0.26458 0,-0.14552 
					0.29766,-0.26459 0.66146,-0.26459 0.41157,0 0.66146,-0.14993 0.66146,-0.39687 
					0,-0.22049 0.23518,-0.39688 0.52916,-0.39688 0.29105,0 0.52917,-0.11906 
					0.52917,-0.26458 0,-0.14552 0.29766,-0.26458 0.66146,-0.26458 0.3638,0 
					0.66146,-0.11907 0.66146,-0.264587 0,-0.145521 0.23812,-0.264584 
					0.52916,-0.264584 0.29105,0 0.52917,-0.119062 0.52917,-0.264583 0,-0.145521 
					0.22839,-0.264583 0.50753,-0.264583 0.27914,0 0.70584,-0.0761 0.94822,-0.169111 
					0.35155,-0.134903 0.36931,-0.214368 0.0878,-0.392824 -0.22895,-0.14513 
					-0.004,-0.225134 0.63927,-0.227764 0.54571,-0.0021 0.99219,-0.09412 
					0.99219,-0.204214 0,-0.219403 1.86782,-0.85817 2.50938,-0.85817 0.22057,0 
					0.40104,-0.119063 0.40104,-0.264584 0,-0.145521 0.40821,-0.264583 
					0.90714,-0.264583 0.49893,0 1.06657,-0.159425 1.26142,-0.354275 
					0.19485,-0.194849 0.70937,-0.439375 1.14338,-0.543385 3.06004,-0.733348 
					5.34947,-1.151969 8.06514,-1.4747 1.09141,-0.129704 2.02117,-0.244295 
					2.06614,-0.254648 0.045,-0.01035 0.008,-0.137888 -0.0818,-0.283409 
					-0.15269,-0.247054 0.98764,-0.293981 7.14375,-0.293981 5.82304,0 7.29199,0.05412 
					7.16234,0.263901 -0.18258,0.295415 0.74465,0.550929 2.01631,0.555635 
					0.53712,0.0021 1.05016,0.122675 1.1401,0.268195 0.0899,0.145521 0.51368,0.264584 
					0.94165,0.264584 0.42798,0 0.77814,0.119062 0.77814,0.264583 0,0.145521 
					0.29765,0.264583 0.66146,0.264583 0.3638,0 0.66146,0.119063 0.66146,0.264584 
					0,0.14552 0.16585,0.264583 0.36856,0.264583 0.20272,0 0.4371,0.178594 
					0.52087,0.396875 0.0838,0.218281 0.28689,0.396875 0.45139,0.396875 0.49877,0 
					2.52023,2.313612 2.94671,3.372577 0.7695,1.91073 0.92018,2.62622 0.96455,4.58029 
					0.048,2.11544 -0.47583,5.58477 -0.98779,6.54136 -0.17279,0.32286 
					-0.24885,0.58702 -0.16901,0.58702 0.18396,0 -0.60907,1.9172 -0.8717,2.1074 
					-0.10752,0.0779 -0.41864,0.64758 -0.69138,1.26604 -0.27275,0.61846 
					-0.59689,1.12448 -0.7203,1.12448 -0.12342,0 -0.2244,0.12409 -0.2244,0.27575 
					0,0.15166 -0.12127,0.41955 -0.26949,0.59531 -1.06512,1.26303 -1.31801,1.62895 
					-1.31801,1.90706 0,0.43615 -0.91728,1.45521 -1.30986,1.45521 -0.17696,0 
					-0.27326,0.14545 -0.214,0.32321 0.0593,0.17777 -0.13919,0.58876 -0.44098,0.91331 
					-0.95076,1.02247 -1.21016,1.37887 -1.21016,1.66273 0,0.15166 -0.12304,0.27575 
					-0.27341,0.27575 -0.35571,0 -2.10784,1.75213 -2.10784,2.10785 0,0.15037 
					-0.1944,0.2734 -0.43199,0.2734 -0.46591,0 -1.41753,0.95523 -1.42415,1.42955 
					-0.002,0.16293 -0.10218,0.13544 -0.22776,-0.0627 -0.1799,-0.28379 
					-0.25542,-0.27029 -0.38559,0.0689 -0.089,0.23201 -0.13536,0.45886 
					-0.10296,0.50411 0.13662,0.1908 -0.8637,0.97051 -1.24511,0.97051 -0.22868,0 
					-0.41578,0.11906 -0.41578,0.26458 0,0.14552 -0.17859,0.26458 -0.39687,0.26458 
					-0.21828,0 -0.39688,0.12304 -0.39688,0.27341 0,0.31838 -1.73463,2.10784 
					-2.04326,2.10784 -0.11485,0 -0.3777,0.38696 -0.58412,0.8599 -0.20642,0.47294 
					-0.59267,1.05245 -0.85834,1.28781 -0.26567,0.23535 -0.48303,0.56277 
					-0.48303,0.7276 0,0.16483 -0.13497,0.29969 -0.29994,0.29969 -0.43732,0 
					-1.55144,1.37719 -1.55182,1.91823 -1.8e-4,0.25466 -0.17892,0.46302 
					-0.3972,0.46302 -0.22048,0 -0.39687,0.23519 -0.39687,0.52917 0,0.29104 
					-0.11906,0.52917 -0.26458,0.52917 -0.15434,0 -0.26459,0.88194 -0.26459,2.11666 
					0,1.23473 0.11025,2.11667 0.26459,2.11667 0.14552,0 0.26458,0.17859 
					0.26458,0.39688 0,0.21828 0.17859,0.39687 0.39687,0.39687 0.21828,0 
					0.39688,0.16567 0.39688,0.36815 0,0.69183 3.94516,2.00598 6.03489,2.01024 
					0.86843,0.002 1.34225,0.10387 1.24115,0.26744 -0.0899,0.14552 0.0519,0.27146 
					0.31511,0.27986 0.61858,0.0197 5.78325,0.45277 9.34218,0.7833 1.52797,0.14191 
					3.93626,0.33373 5.35176,0.42626 1.41549,0.0925 2.51669,0.26033 2.44713,0.37289 
					-0.0696,0.11255 0.49871,0.28202 1.26285,0.37661 2.29384,0.28393 3.7986,1.03329 
					4.33195,2.15727 0.22304,0.47 0.57491,0.91955 0.78195,0.999 0.5643,0.21654 
					0.5448,4.65898 -0.0204,4.65898 -0.26207,0 -0.39687,0.26213 -0.39687,0.7717 
					0,0.42444 -0.0893,0.81139 -0.19844,0.8599 -0.10914,0.0485 -0.48503,0.65374 
					-0.83534,1.34496 -0.35031,0.69123 -0.76703,1.25759 -0.92604,1.25858 
					-0.15902,0.001 -0.5868,0.44382 -0.9506,0.98405 -0.78682,1.16838 -3.8781,4.30581 
					-4.24249,4.30581 -0.14049,0 -0.22566,0.12208 -0.18928,0.27129 0.0364,0.14921 
					-0.29072,0.47663 -0.72686,0.7276 -0.43617,0.25097 -0.98592,0.66468 
					-1.22166,0.91934 -0.23577,0.25466 -0.57054,0.46302 -0.74396,0.46302 -0.17342,0 
					-0.24693,0.11064 -0.16336,0.24587 0.0836,0.13522 -0.44518,0.57086 
					-1.17501,0.96809 -0.72983,0.39722 -1.32696,0.82236 -1.32696,0.94476 0,0.12239 
					-0.0893,0.20843 -0.19844,0.1912 -0.54576,-0.0862 -0.8599,0.0703 -0.8599,0.4282 
					0,0.21829 -0.13082,0.39688 -0.29072,0.39688 -0.57514,0 -1.82594,1.63554 
					-1.82594,2.38759 0,1.01242 0.24811,1.58116 0.68976,1.58116 0.20271,0 
					0.36857,0.17859 0.36857,0.39687 0,0.21829 0.1786,0.39688 0.39688,0.39688 
					0.21828,0 0.39687,0.11906 0.39687,0.26458 0,0.14552 0.35719,0.26459 
					0.79375,0.26459 0.43656,0 0.79375,0.11906 0.79375,0.26458 0,0.14552 
					0.41672,0.26458 0.92604,0.26458 0.64676,0 0.92605,0.11969 0.92605,0.39688 
					0,0.31498 0.38217,0.39687 1.85208,0.39687 1.05833,0 1.85208,0.11339 
					1.85208,0.26459 0,0.16837 2.11667,0.26458 5.82084,0.26458 3.70416,0 
					5.82083,0.0962 5.82083,0.26458 0,0.15434 0.88193,0.26459 2.11667,0.26459 
					1.23473,0 2.11666,0.11024 2.11666,0.26458 0,0.14552 0.2195,0.26458 
					0.48774,0.26458 1.05253,0 3.63447,1.11237 4.96559,2.13932 1.56353,1.20626 
					2.6833,2.97248 3.42434,5.40131 0.37658,1.23425 0.48776,2.5023 0.49969,5.69862 l 
					0.0153,4.11113 -1.29201,2.50345 c -1.32829,2.5737 -2.75823,4.46817 
					-5.04005,6.67727 -0.97676,0.94563 -1.6755,1.36394 -2.77813,1.66316 
					-1.61292,0.4377 -4.30596,1.44166 -4.51585,1.68351 -0.27377,0.31546 
					-1.33123,0.8139 -1.72675,0.8139 -0.22604,0 -0.87776,0.23812 -1.44825,0.52916 
					-0.5705,0.29105 -1.27162,0.52917 -1.55805,0.52917 -0.28647,0 -0.68025,0.15943 
					-0.87509,0.35428 -0.19486,0.19484 -0.64984,0.42469 -1.0111,0.51075 
					-0.36124,0.0861 -1.07352,0.27905 -1.58284,0.42885 -0.50933,0.1498 
					-1.52136,0.41959 -2.24896,0.59953 -0.72761,0.17993 -1.3934,0.42921 
					-1.47958,0.55394 -0.0862,0.12474 -0.86005,0.40435 -1.71979,0.62138 
					-1.51136,0.38152 -3.39984,0.87148 -3.94438,1.02337 -0.14552,0.0406 
					-0.65154,0.16338 -1.12448,0.27288 -0.47295,0.10949 -0.8599,0.28073 
					-0.8599,0.38052 0,0.0998 -0.74414,0.39516 -1.65364,0.65637 -3.23557,0.92925 
					-3.51097,1.01574 -3.96516,1.24519 -0.25269,0.12766 -0.62972,0.23211 
					-0.83785,0.23211 -0.20813,0 -0.4181,0.0984 -0.46661,0.2187 -0.0485,0.12028 
					-0.92163,0.5252 -1.94028,0.89982 -3.14377,1.15617 -3.6987,1.38757 
					-3.86281,1.61083 -0.26873,0.36549 -1.59422,0.97481 -2.12061,0.97481 -0.27441,0 
					-0.49892,0.11907 -0.49892,0.26459 0,0.14552 -0.29766,0.26458 -0.66146,0.26458 
					-0.36381,0 -0.66146,0.1496 -0.66146,0.33245 0,0.31747 -1.69009,0.99047 
					-2.48733,0.99047 -0.20845,0 -0.41868,0.11174 -0.46719,0.24832 -0.0485,0.13658 
					-0.41111,0.29083 -0.80579,0.34278 -0.39468,0.0519 -0.80148,0.17832 
					-0.90399,0.28084 -0.10252,0.10251 -0.52374,0.18639 -0.93605,0.18639 -0.46987,0 
					-0.74965,0.13969 -0.74965,0.3743 0,0.26942 -0.50041,0.4205 -1.78594,0.5392 
					-3.39824,0.31376 -7.07977,0.38788 -7.60677,0.15314 z M 62.852017,224.33548 c 
					-1.091237,-0.0435 -2.097315,-0.19232 -2.235729,-0.33073 -0.138414,-0.13841 
					-0.64457,-0.25166 -1.124789,-0.25166 -0.480219,0 -0.873125,-0.11907 
					-0.873125,-0.26459 0,-0.14552 -0.279439,-0.26458 -0.620974,-0.26458 -0.341535,0 
					-0.876544,-0.22165 -1.188905,-0.49256 -0.312365,-0.27091 -1.104488,-0.69748 
					-1.760276,-0.94793 -0.655791,-0.25045 -1.192345,-0.61608 -1.192345,-0.81252 
					0,-0.19644 -0.255693,-0.43832 -0.568206,-0.53751 -0.524838,-0.16657 
					-2.558285,-1.99168 -2.408356,-2.1616 0.183501,-0.20797 0.02913,-0.60413 
					-0.235416,-0.60413 -0.406609,0 -1.002549,-0.81722 -1.012942,-1.38906 
					-0.0046,-0.25466 -0.127476,-0.46302 -0.272997,-0.46302 -0.14552,0 
					-0.264583,-0.23813 -0.264583,-0.52917 0,-0.29398 -0.17639,-0.52917 
					-0.396875,-0.52917 -0.264583,0 -0.396875,-0.26458 -0.396875,-0.79375 0,-0.43656 
					-0.119063,-0.79375 -0.264583,-0.79375 -0.145521,0 -0.264584,-0.35718 
					-0.264584,-0.79375 0,-0.43656 -0.119062,-0.79375 -0.264583,-0.79375 -0.145521,0 
					-0.264583,-0.41671 -0.264583,-0.92604 0,-0.56228 -0.129913,-0.92763 
					-0.33073,-0.93009 -0.220485,-0.003 -0.198437,-0.0896 0.06615,-0.26053 
					0.264584,-0.17099 0.286631,-0.25783 0.06615,-0.26054 -0.227385,-0.003 
					-0.330729,-0.50009 -0.330729,-1.59155 0,-1.23472 -0.08819,-1.5875 
					-0.396875,-1.5875 -0.317352,0 -0.396875,-0.39595 -0.396875,-1.97607 0,-1.6478 
					0.06593,-1.95077 0.396875,-1.82377 0.333595,0.12801 0.396875,-0.20629 
					0.396875,-2.09666 0,-2.47364 0.07603,-2.58687 1.586984,-2.36324 0.669822,0.0991 
					0.794748,0.18286 0.452178,0.30303 -0.284549,0.0998 0.507143,0.3548 
					1.984891,0.63929 4.451234,0.85693 7.580953,1.60644 7.46841,1.78854 
					-0.06091,0.0986 0.304927,0.25718 0.812977,0.35249 0.807726,0.15153 
					0.923727,0.27146 0.923727,0.95505 0,0.42997 0.119062,0.78176 0.264583,0.78176 
					0.146992,0 0.264584,0.70556 0.264584,1.5875 0,0.88194 0.117591,1.5875 
					0.264583,1.5875 0.145521,0 0.264583,0.23813 0.264583,0.52917 0,0.29104 
					0.119063,0.52916 0.264584,0.52916 0.14552,0 0.264583,0.1786 0.264583,0.39688 
					0,0.21828 0.178594,0.39687 0.396875,0.39687 0.218281,0 0.396875,0.10462 
					0.396875,0.23249 0,0.81537 5.030777,1.2907 6.762763,0.63898 1.658065,-0.62391 
					1.848178,-0.68919 2.225821,-0.76437 0.214297,-0.0427 0.511953,-0.23802 
					0.661458,-0.43414 0.149508,-0.19612 0.711642,-0.56238 1.249188,-0.8139 
					0.537546,-0.25152 1.311452,-0.8592 1.719792,-1.3504 0.925391,-1.11317 
					2.317938,-1.71566 3.38827,-1.46594 0.436563,0.10186 1.478359,0.33856 
					2.315104,0.526 0.836745,0.18744 1.521354,0.43545 1.521354,0.55114 0,0.11568 
					0.416719,0.28851 0.926042,0.38406 0.509323,0.0956 0.926042,0.27954 
					0.926042,0.40886 0,0.12933 0.24951,0.23514 0.554469,0.23514 0.842933,0 
					3.414281,1.97315 3.414281,2.61998 0,0.50361 -0.602237,1.87794 -0.82292,1.87794 
					-0.133091,0 -0.651275,0.63982 -1.151517,1.42182 -0.500243,0.78201 
					-1.111118,1.49919 -1.357501,1.59373 -0.246382,0.0945 -0.372448,0.24742 
					-0.280149,0.33972 0.0923,0.0923 -0.247845,0.67508 -0.75588,1.29507 
					-0.508034,0.61999 -0.9237,1.1782 -0.9237,1.24046 0,0.34594 -4.394645,4.42795 
					-4.767072,4.42795 -0.143007,0 -0.260011,0.17859 -0.260011,0.39687 0,0.21828 
					-0.156144,0.39688 -0.346986,0.39688 -0.190841,0 -0.634531,0.26789 
					-0.985977,0.59531 -0.351446,0.32742 -0.671552,0.56555 -0.711348,0.52917 
					-0.233993,-0.21391 -0.85533,-0.0133 -0.79996,0.2583 0.03638,0.17844 
					-0.201745,0.36532 -0.529167,0.41527 -0.327422,0.05 -0.595312,0.17565 
					-0.595312,0.27931 0,0.10366 -0.327422,0.21407 -0.727605,0.24536 -0.400182,0.0313 
					-1.02526,0.1731 -1.389062,0.31514 -0.709462,0.277 -5.484273,0.45516 
					-8.730941,0.32578 z m 73.353773,-1.28734 c -0.18281,-0.0737 -0.26508,-0.24289 
					-0.18282,-0.37598 0.0823,-0.1331 -0.01,-0.24199 -0.20453,-0.24199 -0.19474,0 
					-0.52571,-0.23812 -0.73548,-0.52917 -0.20977,-0.29104 -0.5147,-0.52916 
					-0.67761,-0.52916 -1.12608,0 -1.07841,-7.80678 0.0546,-8.93979 0.19713,-0.19714 
					0.35843,-0.64055 0.35843,-0.98537 0,-0.34482 0.11906,-0.70053 0.26458,-0.79047 
					0.14552,-0.0899 0.26458,-0.391 0.26458,-0.66902 0,-0.27803 0.23813,-0.92947 
					0.52917,-1.44764 0.29104,-0.51818 0.52917,-1.11711 0.52917,-1.33097 0,-0.21385 
					0.10704,-0.38883 0.23786,-0.38883 0.28392,0 1.4277,-2.11486 1.14695,-2.12071 
					-0.1068,-0.002 -0.0749,-0.0915 0.0708,-0.19844 0.14575,-0.10691 0.4434,-0.5452 
					0.66145,-0.97398 0.21805,-0.42878 0.65998,-1.06652 0.98205,-1.41722 
					0.32208,-0.35069 0.7381,-0.98213 0.92449,-1.40321 0.18639,-0.42108 
					0.42888,-0.7656 0.53888,-0.7656 0.11,0 0.2,-0.24515 0.2,-0.54478 0,-0.29963 
					0.1199,-0.47068 0.26645,-0.38011 0.14655,0.0906 0.33383,-0.0512 0.41619,-0.31511 
					0.0823,-0.26389 0.26011,-0.68654 0.395,-0.93922 0.1349,-0.25269 0.24527,-0.69215 
					0.24527,-0.97658 0,-0.28443 0.11907,-0.59072 0.26459,-0.68066 0.43041,-0.26601 
					0.30334,-1.04924 -0.2701,-1.66476 -0.29408,-0.31565 -0.53221,-0.66553 
					-0.52917,-0.7775 0.003,-0.11196 -0.20284,-0.20357 -0.4575,-0.20357 -0.25467,0 
					-0.43326,0.14882 -0.39688,0.33073 0.0388,0.19402 -0.20726,0.33072 
					-0.59531,0.33072 -0.3638,0 -0.66146,0.11907 -0.66146,0.26459 0,0.14552 
					-0.23813,0.26458 -0.52917,0.26458 -0.29104,0 -0.52916,0.11906 -0.52916,0.26458 
					0,0.14553 -0.1786,0.26459 -0.39688,0.26459 -0.21828,0 -0.3978,0.14883 
					-0.39894,0.33073 -0.002,0.37529 -1.18539,0.99218 -1.90276,0.99218 -0.26203,0 
					-0.47642,0.11907 -0.47642,0.26459 0,0.15434 -0.88195,0.26458 -2.11667,0.26458 
					-1.23472,0 -2.11667,-0.11024 -2.11667,-0.26458 0,-0.14552 -0.17859,-0.26459 
					-0.39687,-0.26459 -0.21828,0 -0.39688,-0.11906 -0.39688,-0.26458 0,-0.14552 
					-0.14883,-0.26503 -0.33073,-0.26557 -0.1819,-5.3e-4 -0.56885,-0.5661 
					-0.85989,-1.25677 -0.46827,-1.11125 -0.52155,-1.75823 -0.46295,-5.62141 
					0.0801,-5.28097 -0.0654,-5.82083 -1.56914,-5.82083 -0.62719,0 -0.97677,0.1053 
					-0.87833,0.26458 0.0899,0.14552 -0.0291,0.26459 -0.26458,0.26459 -0.23546,0 
					-0.35452,0.11906 -0.26459,0.26458 0.0899,0.14552 0.0374,0.26458 -0.11667,0.26458 
					-0.15411,0 -0.2802,0.1786 -0.2802,0.39688 0,0.21828 -0.17859,0.39687 
					-0.39688,0.39687 -0.22048,0 -0.39687,0.23519 -0.39687,0.52917 0,0.29104 
					-0.11906,0.52917 -0.26458,0.52917 -0.14553,0 -0.26459,0.24662 -0.26459,0.54806 
					0,0.58577 -0.57252,1.30402 -1.03943,1.30402 -0.15592,0 -0.28348,0.16867 
					-0.28348,0.37483 0,0.20615 -0.14883,0.43488 -0.33073,0.50827 -0.25231,0.10181 
					-0.24783,0.13841 0.0189,0.15434 0.50995,0.0305 -0.31779,0.81464 -0.8599,0.81464 
					-0.22867,0 -0.41577,0.11906 -0.41577,0.26458 0,0.14553 -0.1786,0.26459 
					-0.39688,0.26459 -0.21828,0 -0.39687,0.11906 -0.39687,0.26458 0,0.14552 
					-0.35719,0.26458 -0.79375,0.26458 -0.52917,0 -0.79375,0.1323 -0.79375,0.39688 
					0,0.30868 -0.35278,0.39687 -1.5875,0.39687 -1.23473,0 -1.5875,-0.0882 
					-1.5875,-0.39687 0,-0.22049 -0.23519,-0.39688 -0.52917,-0.39688 -0.29104,0 
					-0.52917,-0.11906 -0.52917,-0.26458 0,-0.14552 -0.24662,-0.26458 
					-0.54806,-0.26458 -0.65763,0 -1.30401,-0.59018 -1.30401,-1.19063 0,-0.49089 
					-0.61088,-1.19062 -1.03944,-1.19062 -0.15591,0 -0.28348,-0.41672 
					-0.28348,-0.92604 0,-0.50933 -0.11906,-0.92604 -0.26458,-0.92604 -0.14552,0 
					-0.26458,-0.53579 -0.26458,-1.19063 0,-0.65484 -0.11907,-1.19062 
					-0.26459,-1.19062 -0.15875,0 -0.26458,-1.05834 -0.26458,-2.64584 0,-1.5875 
					-0.10583,-2.64583 -0.26458,-2.64583 -0.16228,0 -0.26459,-1.27882 
					-0.26459,-3.30729 0,-2.76343 -0.0653,-3.30729 -0.39687,-3.30729 -0.28663,0 
					-0.39688,-0.29399 -0.39688,-1.05834 0,-0.58208 -0.11906,-1.05833 
					-0.26458,-1.05833 -0.14552,0 -0.26458,-0.17859 -0.26458,-0.39688 0,-0.64433 
					-2.16407,-0.53613 -2.64584,0.1323 -0.20977,0.29104 -0.53371,0.52916 
					-0.71987,0.52916 -0.21042,0 -0.33846,0.35031 -0.33846,0.92604 0,0.50933 
					-0.11906,0.92605 -0.26458,0.92605 -0.14553,0 -0.26459,0.58828 -0.26459,1.3073 
					0,0.71901 -0.11906,1.38088 -0.26458,1.47082 -0.14552,0.0899 -0.26458,0.45415 
					-0.26458,0.80937 0,0.35521 -0.11907,0.64584 -0.26459,0.64584 -0.14552,0 
					-0.26458,0.41672 -0.26458,0.92604 0,0.64676 -0.11969,0.92604 -0.39688,0.92604 
					-0.35198,0 -0.43935,0.15689 -0.37949,0.6815 0.01,0.0838 -0.22857,0.36564 
					-0.52917,0.62635 -0.3006,0.26071 -0.54654,0.67691 -0.54654,0.9249 0,0.44069 
					-0.58789,1.20684 -0.92605,1.20684 -0.21713,0 -0.926038,0.70364 -0.926038,0.91917 
					0,0.3913 -1.347597,1.46208 -1.840058,1.46208 -0.297657,0 -0.541192,0.11906 
					-0.541192,0.26458 0,0.14552 -0.297657,0.26458 -0.661459,0.26458 -0.411575,0 
					-0.661458,0.14993 -0.661458,0.39688 0,0.22048 -0.235185,0.39687 
					-0.529167,0.39687 -0.291041,0 -0.529166,0.11906 -0.529166,0.26459 0,0.15875 
					-1.058334,0.26458 -2.645834,0.26458 -1.5875,0 -2.645833,-0.10583 
					-2.645833,-0.26458 0,-0.14553 -0.245152,-0.26459 -0.544782,-0.26459 -0.299631,0 
					-0.470575,-0.12007 -0.379876,-0.26682 0.0907,-0.14675 -0.110636,-0.37733 
					-0.447408,-0.51239 -0.336769,-0.13507 -0.518319,-0.25313 -0.403439,-0.26235 
					0.114877,-0.009 0.05535,-0.18219 -0.132292,-0.38437 -0.247144,-0.26629 
					-0.394729,-0.28452 -0.535548,-0.0661 -0.114837,0.17808 -0.196043,0.0578 
					-0.198438,-0.29387 -0.0022,-0.32742 -0.123113,-0.59531 -0.268634,-0.59531 
					-0.145521,0 -0.264583,-0.29765 -0.264583,-0.66146 0,-0.3638 -0.119063,-0.66145 
					-0.264583,-0.66145 -0.145521,0 -0.264584,-0.65485 -0.264584,-1.45521 0,-0.87914 
					-0.114848,-1.45521 -0.290118,-1.45521 -0.193659,0 -0.237591,-0.46184 
					-0.13213,-1.38906 0.0869,-0.76399 0.230462,-2.25227 0.319039,-3.3073 
					0.08857,-1.05502 0.254591,-1.91822 0.36892,-1.91822 0.114331,0 0.279913,-0.47625 
					0.367956,-1.05834 0.08804,-0.58208 0.233489,-1.54892 0.323204,-2.14854 
					0.112287,-0.75045 0.233505,-0.97721 0.388959,-0.72761 0.162184,0.26042 
					0.274672,0.12026 0.399116,-0.49728 0.0953,-0.47295 0.277463,-0.8599 
					0.404794,-0.8599 0.127331,0 0.23151,-0.35016 0.23151,-0.77813 0,-0.42798 
					0.119063,-0.85172 0.264584,-0.94166 0.14552,-0.0899 0.264583,-0.45415 
					0.264583,-0.80937 0,-0.39769 0.15249,-0.64584 0.396875,-0.64584 0.220485,0 
					0.396875,-0.23519 0.396875,-0.52917 0,-0.29104 0.119062,-0.52916 
					0.264583,-0.52916 0.145521,0 0.264584,-0.21439 0.264584,-0.47642 0,-0.59126 
					0.591431,-1.89998 0.859895,-1.90277 0.109141,-0.001 0.198438,-0.29972 
					0.198438,-0.66352 0,-0.41158 0.149931,-0.66146 0.396875,-0.66146 0.220485,0 
					0.396875,-0.23519 0.396875,-0.52917 0,-0.29104 0.119062,-0.52916 
					0.264583,-0.52916 0.145521,0 0.264584,-0.23813 0.264584,-0.52917 0,-0.29104 
					0.119062,-0.52917 0.264583,-0.52917 0.145521,0 0.264583,-0.17859 
					0.264583,-0.39687 0,-0.21828 0.119063,-0.39688 0.264584,-0.39688 0.145521,0 
					0.264583,-0.29765 0.264583,-0.66145 0,-0.36381 0.127566,-0.66146 
					0.283483,-0.66146 0.447915,0 1.039434,-0.70885 1.039434,-1.24561 0,-0.26931 
					0.238125,-0.66129 0.529166,-0.87106 0.291042,-0.20977 0.529167,-0.53371 
					0.529167,-0.71987 0,-0.18615 0.141756,-0.3087 0.315016,-0.27232 0.173259,0.0364 
					0.363955,-0.15973 0.42377,-0.4358 0.124661,-0.57534 1.218144,-1.74701 
					1.630439,-1.74701 0.152135,0 0.276608,-0.17859 0.276608,-0.39687 0,-0.21829 
					0.119063,-0.39688 0.264584,-0.39688 0.145521,0 0.264583,-0.12447 
					0.264583,-0.27661 0,-0.35915 1.216315,-1.57547 1.575475,-1.57547 0.15214,0 
					0.27661,-0.12303 0.27661,-0.2734 0,-0.35572 1.75213,-2.10785 2.10785,-2.10785 
					0.15037,0 0.2734,-0.15231 0.2734,-0.33847 0,-0.18615 0.25271,-0.51358 
					0.56158,-0.7276 0.30888,-0.21403 0.71709,-0.53797 0.90715,-0.71987 
					0.19005,-0.1819 0.47312,-0.33073 0.62904,-0.33073 0.15591,0 0.28348,-0.17057 
					0.28348,-0.37904 0,-0.20848 0.23812,-0.45463 0.52917,-0.547 0.29104,-0.0924 
					0.52916,-0.26608 0.52916,-0.38602 0,-0.11994 0.29346,-0.29173 0.65213,-0.38175 
					0.35868,-0.09 0.92422,-0.43247 1.25677,-0.761 0.59989,-0.59264 3.55489,-2.04311 
					4.16236,-2.04311 0.17409,0 0.49513,-0.17859 0.71342,-0.39687 0.41622,-0.41623 
					1.8224,-0.51431 3.25776,-0.22724 0.59685,0.11937 0.74384,0.23576 0.49609,0.39282 
					-0.25771,0.16338 -0.2067,0.22428 0.19033,0.22724 0.29831,0.002 0.68527,0.14693 
					0.85989,0.32155 0.17463,0.17463 0.3175,0.22875 0.3175,0.12027 0,-0.10848 
					0.29766,0.10042 0.66146,0.46422 0.3638,0.3638 0.66146,0.84547 0.66146,1.07036 
					0,0.2249 0.11906,0.4089 0.26458,0.4089 0.14552,0 0.26459,0.23813 0.26459,0.52917 
					0,0.29398 0.17639,0.52917 0.39687,0.52917 0.26458,0 0.39688,0.26458 
					0.39688,0.79375 0,0.43656 0.11906,0.79375 0.26458,0.79375 0.15268,0 
					0.26458,0.83264 0.26458,1.96876 0,1.08281 0.11906,2.04234 0.26459,2.13228 
					0.14552,0.0899 0.26458,0.63274 0.26458,1.20624 0,0.57349 -0.11906,1.04272 
					-0.26458,1.04272 -0.15783,0 -0.26459,1.01423 -0.26459,2.51354 0,1.4993 
					-0.10676,2.51354 -0.26458,2.51354 -0.14552,0 -0.26458,0.53578 -0.26458,1.19063 
					0,0.88194 -0.1029,1.19062 -0.39688,1.19062 -0.29398,0 -0.39687,0.30868 
					-0.39687,1.19062 0,0.65485 -0.11907,1.19063 -0.26459,1.19063 -0.14552,0 
					-0.26458,0.53578 -0.26458,1.19062 0,0.65485 -0.11906,1.19063 -0.26458,1.19063 
					-0.1512,0 -0.26459,0.79375 -0.26459,1.85208 0,1.05834 -0.11339,1.85209 
					-0.26458,1.85209 -0.15287,0 -0.26458,0.83784 -0.26458,1.98437 0,1.14653 
					0.11171,1.98438 0.26458,1.98438 0.14552,0 0.26458,0.12447 0.26458,0.2766 0,0.594 
					1.31114,1.57548 2.10464,1.57548 0.44318,0 0.80578,-0.11906 0.80578,-0.26458 
					0,-0.14552 0.41672,-0.26459 0.92604,-0.26459 0.50932,0 0.92604,-0.11906 
					0.92604,-0.26458 0,-0.14552 0.1786,-0.26458 0.39688,-0.26458 0.21828,0 
					0.39687,-0.11907 0.39687,-0.26459 0,-0.14552 0.23813,-0.26458 0.52917,-0.26458 
					0.29398,0 0.52917,-0.17639 0.52917,-0.39688 0,-0.21828 0.17859,-0.39687 
					0.39687,-0.39687 0.21828,0 0.39688,-0.11906 0.39688,-0.26458 0,-0.14552 
					0.23812,-0.26459 0.52916,-0.26459 0.29104,0 0.52917,-0.11906 0.52917,-0.26458 
					0,-0.14552 0.17859,-0.26458 0.39687,-0.26458 0.21829,0 0.39688,-0.11907 
					0.39688,-0.26459 0,-0.14552 0.17859,-0.26458 0.39687,-0.26458 0.21829,0 
					0.39688,-0.17859 0.39688,-0.39688 0,-0.22048 0.23518,-0.39687 0.52917,-0.39687 
					0.29104,0 0.53009,-0.0893 0.53123,-0.19844 0.002,-0.23761 1.28371,-0.85989 
					1.77047,-0.85989 0.18927,0 0.34413,-0.1786 0.34413,-0.39688 0,-0.21828 
					0.17859,-0.39687 0.39687,-0.39687 0.21829,0 0.39688,-0.11907 0.39688,-0.26459 
					0,-0.14552 0.23812,-0.26458 0.52917,-0.26458 0.29104,0 0.52916,-0.11906 
					0.52916,-0.26458 0,-0.14552 0.1786,-0.26459 0.39688,-0.26459 0.21828,0 
					0.39687,-0.11906 0.39687,-0.26458 0,-0.14552 0.1786,-0.26458 0.39688,-0.26458 
					0.21828,0 0.39687,-0.1786 0.39687,-0.39688 0,-0.22048 0.23519,-0.39687 
					0.52917,-0.39687 0.29104,0 0.52917,-0.11907 0.52917,-0.26459 0,-0.14552 
					0.17859,-0.26458 0.39687,-0.26458 0.21828,0 0.39688,-0.11906 0.39688,-0.26458 
					0,-0.14552 0.29927,-0.26459 0.66504,-0.26459 0.36578,0 0.89421,-0.14311 
					1.1743,-0.31803 0.38027,-0.23748 0.66416,-0.24356 1.12089,-0.024 0.3364,0.16172 
					0.82498,0.36254 1.08573,0.44627 0.26075,0.0837 0.62587,0.51322 0.81139,0.95444 
					0.18552,0.44121 0.41099,0.72853 0.50104,0.63848 0.31137,-0.31138 0.51574,1.72303 
					0.36639,3.64728 -0.0825,1.06238 -0.1541,2.21933 -0.15923,2.57101 -0.005,0.35167 
					-0.095,0.5537 -0.19976,0.44895 -0.17025,-0.17025 -0.39346,0.63464 
					-1.44604,5.21446 -0.19757,0.85961 -0.36553,1.16167 -0.51493,0.92604 
					-0.16758,-0.2643 -0.22271,-0.26893 -0.22577,-0.019 -0.007,0.56907 
					-0.0424,0.70408 -0.42502,1.61981 -0.20503,0.49071 -0.37278,1.53251 
					-0.37278,2.3151 v 1.4229 h 1.32291 c 0.87979,0 1.32428,-0.11078 1.32697,-0.33073 
					0.003,-0.22049 0.0896,-0.19844 0.26053,0.0662 0.21919,0.33916 0.25707,0.32955 
					0.26054,-0.0662 0.003,-0.31642 0.21348,-0.46302 0.6655,-0.46302 0.36381,0 
					0.66146,-0.11906 0.66146,-0.26458 0,-0.14553 0.23813,-0.26459 0.52917,-0.26459 
					0.29104,0 0.52917,-0.11906 0.52917,-0.26458 0,-0.14552 0.17859,-0.26458 
					0.39687,-0.26458 0.21828,0 0.39688,-0.11907 0.39688,-0.26459 0,-0.14552 
					0.23812,-0.26458 0.52916,-0.26458 0.29398,0 0.52917,-0.17639 0.52917,-0.39688 
					0,-0.21828 0.17859,-0.39687 0.39687,-0.39687 0.21829,0 0.39688,-0.11906 
					0.39688,-0.26458 0,-0.14553 0.17859,-0.26459 0.39687,-0.26459 0.21829,0 
					0.39688,-0.11906 0.39688,-0.26458 0,-0.14552 0.17859,-0.26458 0.39687,-0.26458 
					0.21829,0 0.39688,-0.1786 0.39688,-0.39688 0,-0.21828 0.17859,-0.39687 
					0.39687,-0.39687 0.21829,0 0.39688,-0.11907 0.39688,-0.26459 0,-0.14552 
					0.17859,-0.26458 0.39687,-0.26458 0.21829,0 0.39688,-0.11906 0.39688,-0.26458 
					0,-0.14552 0.1871,-0.26459 0.41577,-0.26459 0.47519,2e-5 1.17173,-0.61788 
					1.17173,-1.03942 0,-0.15591 0.23812,-0.28348 0.52917,-0.28348 0.29104,0 
					0.52916,-0.11906 0.52916,-0.26458 0,-0.14552 0.1786,-0.26459 0.39688,-0.26459 
					0.21828,0 0.39687,-0.11906 0.39687,-0.26458 0,-0.14552 0.23813,-0.26458 
					0.52917,-0.26458 0.29104,0 0.52917,-0.11907 0.52917,-0.26459 0,-0.14552 
					0.29765,-0.26458 0.66145,-0.26458 0.41158,0 0.66146,-0.14993 0.66146,-0.39688 
					0,-0.26458 0.26459,-0.39687 0.79375,-0.39687 0.43657,0 0.79375,-0.11906 
					0.79375,-0.26458 0,-0.15783 1.01424,-0.26459 2.51354,-0.26459 1.49931,0 
					2.51355,0.10676 2.51355,0.26459 0,0.14552 0.23812,0.26458 0.52916,0.26458 
					0.29104,0 0.52917,0.12757 0.52917,0.28348 0,0.30836 0.65132,1.03944 
					0.92604,1.03944 0.36843,0 0.92604,0.7852 0.92604,1.30401 0,0.30144 
					0.11907,0.54807 0.26459,0.54807 0.14552,0 0.26458,0.35719 0.26458,0.79375 
					0,0.43656 0.11906,0.79375 0.26458,0.79375 0.16277,0 0.26303,1.29817 
					0.26054,3.37344 -0.003,2.2758 -0.0875,3.2443 -0.26054,2.97656 -0.1855,-0.28705 
					-0.2576,-0.0491 -0.26053,0.85989 -0.002,0.69123 -0.12311,1.25678 
					-0.26863,1.25678 -0.14552,0 -0.26459,0.41671 -0.26459,0.92604 0,0.50932 
					-0.11906,0.92604 -0.26458,0.92604 -0.14552,0 -0.26458,0.29765 -0.26458,0.66146 
					0,0.41157 -0.14993,0.66146 -0.39688,0.66146 -0.22048,0 -0.39687,0.23518 
					-0.39687,0.52916 0,0.29104 -0.11907,0.52917 -0.26459,0.52917 -0.14552,0 
					-0.26458,0.29765 -0.26458,0.66146 0,0.3638 -0.11906,0.66146 -0.26458,0.66146 
					-0.14552,0 -0.26459,0.18709 -0.26459,0.41577 0,0.47519 -0.61789,1.17172 
					-1.03943,1.17172 -0.15592,0 -0.28348,0.27138 -0.28348,0.60305 0,0.33168 
					-0.23813,0.77468 -0.52917,0.98445 -0.29104,0.20978 -0.52917,0.60175 
					-0.52917,0.87106 0,0.53677 -0.59152,1.24561 -1.03943,1.24561 -0.15592,0 
					-0.28348,0.1786 -0.28348,0.39688 0,0.21828 -0.12609,0.39687 -0.2802,0.39687 
					-0.15411,0 -0.21611,0.1037 -0.13778,0.23043 0.17531,0.28367 -0.72198,1.35707 
					-1.13441,1.35707 -0.16483,0 -0.2997,0.1786 -0.2997,0.39688 0,0.21828 
					-0.12609,0.39687 -0.2802,0.39687 -0.15411,0 -0.21115,0.11172 -0.12676,0.24826 
					0.0844,0.13654 -0.32531,0.73666 -0.91043,1.33361 -0.58512,0.59694 
					-1.06386,1.23284 -1.06386,1.41312 0,0.18028 -0.23812,0.49941 -0.52916,0.70918 
					-0.29105,0.20977 -0.52917,0.53371 -0.52917,0.71987 0,0.18615 -0.11906,0.33846 
					-0.26458,0.33846 -0.14552,0 -0.25049,0.0893 -0.23325,0.19844 0.0834,0.52839 
					-0.0644,0.85989 -0.3835,0.85989 -0.19369,0 -0.51888,0.32743 -0.72264,0.72761 
					-0.43407,0.8525 -1.63274,2.78542 -1.84588,2.97656 -0.44646,0.40038 
					-1.31265,1.85906 -1.31265,2.21051 0,0.22497 -0.15985,0.50424 -0.35523,0.62061 
					-0.42661,0.25411 -1.36942,1.6668 -1.11239,1.6668 0.0994,0 -0.0499,0.32742 
					-0.33173,0.7276 -0.28186,0.40018 -0.5985,1.00447 -0.70364,1.34286 
					-0.10514,0.33839 -0.25924,0.54717 -0.34244,0.46397 -0.16258,-0.16259 
					-1.25884,1.45018 -1.1839,1.7417 0.0248,0.0963 -0.42773,0.64784 -1.00554,1.22564 
					-0.5778,0.57781 -1.05055,1.12951 -1.05055,1.226 0,0.29177 -1.14396,1.31795 
					-1.9092,1.71262 -0.39521,0.20384 -0.65782,0.4689 -0.58358,0.58903 
					0.15766,0.25509 -2.42064,1.55391 -3.08468,1.55391 -0.24824,0 -0.51987,0.17859 
					-0.60364,0.39688 -0.14907,0.38849 -1.72315,0.5285 -2.48565,0.22109 z m 
					40.30642,-1.3762 c -0.0882,-0.14266 -0.51718,-0.33777 -0.95334,-0.43357 
					-0.43617,-0.0958 -0.72543,-0.28355 -0.64281,-0.41723 0.0826,-0.13368 
					0.0187,-0.24305 -0.14201,-0.24305 -0.37102,0 -1.59109,-1.2081 -1.59109,-1.57548 
					0,-0.15213 -0.17859,-0.27661 -0.39687,-0.27661 -0.21829,0 -0.39688,-0.23109 
					-0.39688,-0.51355 0,-0.28245 -0.11906,-0.58713 -0.26458,-0.67707 
					-0.14552,-0.0899 -0.26459,-0.69228 -0.26459,-1.33853 0,-0.64626 -0.0893,-1.17501 
					-0.19843,-1.17501 -0.10914,0 -0.19844,-0.35719 -0.19844,-0.79375 0,-0.43657 
					0.0893,-0.79375 0.19844,-0.79375 0.10914,0 0.19843,-0.51197 0.19843,-1.13771 
					0,-0.62574 0.11837,-1.25608 0.26304,-1.40075 0.14468,-0.14467 0.28971,-0.5852 
					0.32229,-0.97896 0.0326,-0.39375 0.21188,-0.71592 0.39842,-0.71592 0.18655,0 
					0.33917,-0.15614 0.33917,-0.34698 0,-0.19084 0.23813,-0.60258 0.52917,-0.91498 
					0.29104,-0.3124 0.52916,-0.69203 0.52916,-0.84364 0,-0.1516 0.11907,-0.27565 
					0.26459,-0.27565 0.14552,0 0.26458,-0.17859 0.26458,-0.39687 0,-0.21828 
					0.1284,-0.39688 0.28533,-0.39688 0.15693,0 0.7429,-0.47625 1.30217,-1.05833 
					0.55927,-0.58208 1.14524,-1.05833 1.30217,-1.05833 0.15693,0 0.28533,-0.11104 
					0.28533,-0.24676 0,-0.13571 0.23985,-0.32288 0.53301,-0.41592 0.29315,-0.093 
					0.46472,-0.27966 0.38126,-0.41471 -0.1347,-0.21794 0.0582,-0.29242 
					0.5775,-0.22293 0.0929,0.0124 0.2417,-0.0913 0.33073,-0.23054 0.089,-0.13923 
					0.78695,-0.57859 1.55094,-0.97637 0.76398,-0.39778 1.38906,-0.8083 
					1.38906,-0.91227 0,-0.22071 3.4982,-1.77079 4.56406,-2.02237 0.40019,-0.0945 
					0.72761,-0.26733 0.72761,-0.38415 0,-0.11682 1.04179,-0.52832 2.3151,-0.91445 
					1.27331,-0.38612 2.66029,-0.81342 3.08219,-0.94954 0.4219,-0.13612 
					1.054,-0.24749 1.40468,-0.24749 0.35068,0 0.57129,-0.10727 0.49026,-0.23839 
					-0.19073,-0.30861 2.15227,-0.76575 5.756,-1.12306 3.68401,-0.36526 
					7.73926,0.13313 8.68129,1.06693 0.65783,0.65208 1.54258,1.35285 1.70803,1.35285 
					0.30792,0 0.90411,0.77266 0.90411,1.17173 0,0.22867 0.11907,0.41577 
					0.26459,0.41577 0.14552,0 0.26458,0.29063 0.26458,0.64584 0,0.35522 
					0.10917,0.71332 0.24259,0.79578 0.26912,0.16632 0.19378,3.19834 -0.11539,4.6438 
					-0.10893,0.50932 -0.44955,1.28323 -0.75692,1.71979 -0.30736,0.43656 
					-0.52954,0.82351 -0.49373,0.85989 0.18065,0.18351 0.0296,0.59532 
					-0.21836,0.59532 -0.3353,0 -0.64764,0.31414 -1.66147,1.67102 -0.41488,0.55526 
					-0.81463,0.94926 -0.88833,0.87556 -0.0737,-0.0737 -0.44742,0.1566 
					-0.83048,0.51179 -0.38305,0.35519 -0.8465,0.64579 -1.02989,0.64579 -0.18339,0 
					-0.33425,0.0893 -0.33524,0.19844 -0.002,0.23368 -1.83989,1.52136 
					-2.17127,1.52136 -0.12511,0 -0.3152,0.14194 -0.42242,0.31542 -0.20972,0.33934 
					-3.82589,2.19812 -4.27634,2.19812 -0.14955,0 -0.27192,0.10865 -0.27192,0.24145 
					0,0.1328 -0.8632,0.55948 -1.91823,0.94819 -1.05502,0.3887 -2.27541,0.83884 
					-2.71197,1.00029 -0.43657,0.16146 -0.86563,0.39823 -0.95349,0.52616 
					-0.16894,0.24601 -1.64355,0.68097 -3.08386,0.90963 -0.4743,0.0753 
					-0.9278,0.24282 -1.00781,0.37226 -0.08,0.12944 -0.60765,0.23535 -1.17255,0.23535 
					-0.56491,0 -1.10069,0.11906 -1.19063,0.26458 -0.0899,0.14552 -0.56329,0.26459 
					-1.0519,0.26459 -0.48861,0 -0.93311,0.13418 -0.98777,0.29819 -0.0547,0.164 
					-0.68967,0.41759 -1.41111,0.56353 -1.87475,0.37924 -7.44426,0.54847 
					-7.63976,0.23213 z M 73.867468,198.02813 c -0.868518,-0.93694 -1.226177,-1.97409 
					-1.226177,-3.55571 0,-1.4413 0.548786,-3.82963 0.982107,-4.27417 
					0.187447,-0.19229 0.34081,-0.58086 0.34081,-0.86348 0,-1.04497 1.021059,-2.35876 
					1.833184,-2.35876 0.301437,0 0.548066,-0.11907 0.548066,-0.26459 0,-0.14552 
					0.178594,-0.26458 0.396875,-0.26458 0.218281,0 0.396875,0.11906 0.396875,0.26458 
					0,0.14552 0.134863,0.26459 0.299694,0.26459 0.419594,0 1.272077,1.03582 
					1.456182,1.76936 0.27927,1.1127 -0.256413,6.11919 -0.688288,6.43272 
					-0.100226,0.0728 -0.391765,0.78156 -0.647869,1.57512 -0.256101,0.79355 
					-0.663668,1.59344 -0.9057,1.77752 -0.785223,0.59721 -1.963293,0.38467 
					-2.785759,-0.5026 z m -9.957427,-2.45317 c 0,-0.21828 -0.104619,-0.39687 
					-0.232484,-0.39687 -0.127865,0 -0.378053,-0.35143 -0.555969,-0.78096 
					-0.490196,-1.18344 -0.02554,-7.42112 0.552818,-7.42112 0.129598,0 
					0.235635,-0.3014 0.235635,-0.66977 0,-0.36837 0.178594,-0.73829 
					0.396875,-0.82205 0.218281,-0.0838 0.396875,-0.29169 0.396875,-0.46207 
					0,-0.54476 1.037984,-1.22111 1.874007,-1.22111 0.808085,0 1.565576,0.50292 
					1.565576,1.03943 0,0.15592 0.178594,0.28348 0.396875,0.28348 0.332238,0 
					0.396217,0.54942 0.392825,3.37344 -0.0024,1.9416 -0.09901,3.22364 
					-0.227764,3.02052 -0.178456,-0.28152 -0.257921,-0.26376 -0.392824,0.0878 
					-0.09301,0.24238 -0.169112,0.72862 -0.169112,1.08052 0,0.3519 -0.119062,0.63981 
					-0.264583,0.63981 -0.145521,0 -0.264583,0.41672 -0.264583,0.92605 0,0.50932 
					-0.119063,0.92604 -0.264584,0.92604 -0.14552,0 -0.264583,0.17859 
					-0.264583,0.39687 0,0.30868 -0.352777,0.39688 -1.5875,0.39688 -1.234723,0 
					-1.5875,-0.0882 -1.5875,-0.39688 z m -25.069271,-8.9234 c 0.03638,-0.17845 
					-0.231511,-0.36905 -0.595313,-0.42355 -0.363802,-0.0545 -0.748932,-0.23593 
					-0.855845,-0.40316 -0.135096,-0.21131 -0.19562,-0.19083 -0.198437,0.0672 
					-0.0027,0.24936 -0.16623,0.18422 -0.498108,-0.19844 -0.271729,-0.31331 
					-0.688448,-0.56965 -0.926041,-0.56965 -0.237591,0 -0.431986,-0.17859 
					-0.431986,-0.39687 0,-0.21828 -0.123031,-0.39688 -0.273402,-0.39688 -0.422132,0 
					-2.024279,-1.70113 -2.238843,-2.37717 -0.148892,-0.46911 -0.242914,-0.52189 
					-0.423045,-0.23747 -0.172413,0.27222 -0.232643,0.21354 -0.235659,-0.22963 
					-0.0021,-0.32742 -0.106937,-0.59531 -0.232688,-0.59531 -0.125751,0 
					-0.433255,-0.53578 -0.683342,-1.19063 -0.250087,-0.65484 -0.563919,-1.19062 
					-0.697404,-1.19062 -0.133485,0 -0.2427,-0.29766 -0.2427,-0.66146 0,-0.3638 
					-0.119063,-0.66146 -0.264583,-0.66146 -0.145521,0 -0.264584,-0.23812 
					-0.264584,-0.52916 0,-0.29105 -0.106555,-0.52917 -0.236791,-0.52917 -0.130239,0 
					-0.299241,-0.44649 -0.375568,-0.99219 -0.07633,-0.5457 -0.215117,-1.34937 
					-0.308428,-1.78594 -0.09331,-0.43656 -0.230142,-1.08519 -0.30407,-1.44141 
					-0.106275,-0.51206 -0.185158,-0.56745 -0.376772,-0.26458 -0.163266,0.25807 
					-0.243673,-0.11338 -0.246403,-1.13828 -0.0021,-0.83674 -0.114367,-1.52135 
					-0.249196,-1.52135 -0.514347,0 -1.060587,-15.99802 -0.563223,-16.49538 
					0.153561,-0.15356 0.279201,-0.96274 0.279201,-1.79817 0,-0.83543 
					0.119063,-1.59255 0.264584,-1.68249 0.14552,-0.0899 0.264583,-0.56048 
					0.264583,-1.04564 0,-0.54003 0.153911,-0.94118 0.396875,-1.03442 
					0.218281,-0.0838 0.396875,-0.3829 0.396875,-0.66476 0,-0.54621 1.056619,-2.75187 
					1.389062,-2.89962 0.109141,-0.0485 0.198438,-0.25687 0.198438,-0.46303 
					0,-0.20615 0.178594,-0.37482 0.396875,-0.37482 0.218281,0 0.396875,-0.11104 
					0.396875,-0.24676 0,-0.13571 0.235707,-0.32156 0.523793,-0.41299 
					0.288084,-0.0914 0.496443,-0.30241 0.463021,-0.46883 -0.03343,-0.16642 
					0.05829,-0.31221 0.203811,-0.32398 0.145521,-0.0118 0.561544,-0.18576 
					0.924496,-0.38664 1.738903,-0.96241 6.440057,-0.50011 7.957114,0.78248 
					0.353865,0.29917 1.027772,0.86768 1.497568,1.26335 0.469797,0.39568 
					0.85675,0.86824 0.859896,1.05014 0.0031,0.1819 0.184312,0.33073 0.402593,0.33073 
					0.218281,0 0.396875,0.17859 0.396875,0.39687 0,0.21828 0.119062,0.39688 
					0.264583,0.39688 0.145521,0 0.264584,0.17859 0.264584,0.39687 0,0.21828 
					0.119062,0.39688 0.264583,0.39688 0.145521,0 0.264583,0.29765 0.264583,0.66146 
					0,0.3638 0.119063,0.66145 0.264584,0.66145 0.14552,0 0.264583,0.29766 
					0.264583,0.66146 0,0.41158 0.149931,0.66146 0.396875,0.66146 0.264583,0 
					0.396875,0.26458 0.396875,0.79375 0,0.43656 0.119062,0.79375 0.264583,0.79375 
					0.145521,0 0.264584,0.47625 0.264584,1.05833 0,0.58209 0.119062,1.05834 
					0.264583,1.05834 0.145521,0 0.264583,0.47625 0.264583,1.05833 0,0.58208 
					0.119063,1.05833 0.264584,1.05833 0.14552,0 0.264583,0.59532 0.264583,1.32292 
					0,0.99954 0.09701,1.32292 0.396875,1.32292 0.314981,0 0.396875,0.38217 
					0.396875,1.85208 0,1.05833 0.113392,1.85208 0.264583,1.85208 0.15875,0 
					0.264584,1.05834 0.264584,2.64584 0,1.5875 0.105833,2.64583 0.264583,2.64583 
					0.16659,0 0.264583,1.76389 0.264583,4.7625 0,2.99861 -0.09799,4.7625 
					-0.264583,4.7625 -0.157821,0 -0.264583,1.01423 -0.264583,2.51354 0,1.49931 
					-0.106762,2.51354 -0.264584,2.51354 -0.14552,0 -0.264583,0.41298 
					-0.264583,0.91774 0,0.70707 -0.09111,0.88278 -0.396875,0.76544 -0.218281,-0.0838 
					-0.396875,-0.0205 -0.396875,0.1406 0,0.16109 0.208359,0.30229 0.463021,0.31378 
					0.364844,0.0165 0.379873,0.0535 0.07087,0.17481 -0.597408,0.23449 
					-1.063059,0.77792 -1.063059,1.24061 0,0.22867 -0.119062,0.41577 
					-0.264583,0.41577 -0.145521,0 -0.264583,0.12757 -0.264583,0.28348 0,0.46692 
					-0.718249,1.03944 -1.304018,1.03944 -0.301437,0 -0.548066,0.10581 
					-0.548066,0.23514 0,0.12932 -0.446484,0.32215 -0.992187,0.4285 -0.839147,0.16355 
					-0.910566,0.22644 -0.463021,0.40773 0.321659,0.13029 -0.586192,0.22164 
					-2.315104,0.23295 -2.113492,0.0138 -2.827277,-0.0648 -2.778125,-0.30585 z m 
					155.24427,-8.4068 c 0,-0.14553 -0.29765,-0.26459 -0.66145,-0.26459 -0.41158,0 
					-0.66146,-0.14993 -0.66146,-0.39687 0,-0.21828 -0.1786,-0.39688 
					-0.39688,-0.39688 -0.21828,0 -0.39687,-0.11906 -0.39687,-0.26458 0,-0.14552 
					-0.1786,-0.26458 -0.39688,-0.26458 -0.21828,0 -0.39687,-0.11907 
					-0.39687,-0.26459 0,-0.14552 -0.1786,-0.26458 -0.39688,-0.26458 -0.21828,0 
					-0.39687,-0.16038 -0.39687,-0.35639 0,-0.44421 -0.89092,-1.49569 
					-1.26729,-1.49569 -0.15355,0 -0.4167,-0.41672 -0.5848,-0.92605 -0.16809,-0.50932 
					-0.40252,-0.92604 -0.52095,-0.92604 -0.11844,0 -0.41093,-0.48882 
					-0.64998,-1.08626 -0.23905,-0.59745 -0.60091,-1.25254 -0.80413,-1.45577 
					-0.20322,-0.20322 -0.62594,-1.23244 -0.93937,-2.28717 -0.31342,-1.05472 
					-0.67887,-2.12441 -0.81209,-2.3771 -0.13323,-0.25269 -0.24223,-0.70768 
					-0.24223,-1.01109 0,-0.30341 -0.17859,-0.73025 -0.39687,-0.94853 
					-0.21828,-0.21828 -0.39688,-0.75339 -0.39688,-1.18912 0,-0.43573 
					-0.1233,-1.02264 -0.274,-1.30423 -0.37308,-0.6971 -0.3816,-7.21629 
					-0.01,-7.34029 0.15599,-0.052 0.28361,-0.38227 0.28361,-0.73394 0,-0.35168 
					0.12015,-0.63941 0.26699,-0.63941 0.35054,0 1.99966,-1.57835 1.69048,-1.61793 
					-0.51744,-0.0662 1.9369,-0.55375 2.80503,-0.55718 1.27196,-0.005 2.47139,0.32884 
					2.72513,0.75849 0.11637,0.19706 0.34053,0.35828 0.49813,0.35828 0.1576,0 
					0.47911,0.21663 0.71446,0.48139 0.23535,0.26476 0.63897,0.55972 0.89693,0.65546 
					0.25796,0.0957 0.54799,0.37986 0.64451,0.63137 0.1775,0.46257 0.66583,1.12721 
					1.41136,1.92092 0.22452,0.23903 0.56639,0.81645 0.7597,1.28315 0.19332,0.4667 
					0.46313,0.84855 0.59958,0.84855 0.13646,0 0.19152,0.0915 0.12238,0.20342 
					-0.0692,0.11189 0.0984,0.67744 0.37228,1.25677 0.2739,0.57934 0.50205,1.22202 
					0.50698,1.42818 0.005,0.20615 0.0906,0.29323 0.1903,0.1935 0.20421,-0.20421 
					1.37913,2.36426 1.39556,3.05079 0.006,0.24427 0.16441,0.60146 0.35238,0.79375 
					0.31304,0.32025 1.02841,2.97909 1.41102,5.24442 0.086,0.50932 0.25208,1.10687 
					0.36901,1.32789 0.25752,0.48675 0.16009,4.31318 -0.13798,5.41898 
					-0.11768,0.43657 -0.43358,0.98631 -0.70201,1.22166 -0.26843,0.23535 
					-0.48805,0.56278 -0.48805,0.72761 0,0.16483 -0.23812,0.29969 -0.52917,0.29969 
					-0.29104,0 -0.52916,0.11906 -0.52916,0.26459 0,0.15782 -1.01424,0.26458 
					-2.51354,0.26458 -1.49931,0 -2.51355,-0.10676 -2.51355,-0.26458 z M 
					84.547541,131.67809 c 0,-0.14552 -0.357187,-0.26458 -0.79375,-0.26458 
					-0.436562,0 -0.79375,-0.11906 -0.79375,-0.26459 0,-0.14552 -0.238125,-0.26458 
					-0.529166,-0.26458 -0.291042,0 -0.529167,-0.11906 -0.529167,-0.26458 0,-0.14552 
					-0.178594,-0.26459 -0.396875,-0.26459 -0.218281,0 -0.396875,-0.12756 
					-0.396875,-0.28348 0,-0.33765 -0.662032,-1.03943 -0.980559,-1.03943 -0.123534,0 
					-0.571111,-0.80367 -0.994616,-1.78594 -0.423506,-0.98226 -0.892765,-1.86859 
					-1.042795,-1.96961 -0.371014,-0.24982 -0.367382,-7.85284 0.0039,-8.09932 
					0.301511,-0.20017 1.162024,-2.67522 1.162024,-3.34224 0,-0.22732 
					0.119062,-0.41331 0.264583,-0.41331 0.145521,0 0.264583,-0.23812 
					0.264583,-0.52916 0,-0.29399 0.17639,-0.52917 0.396875,-0.52917 0.246944,0 
					0.396875,-0.24988 0.396875,-0.66146 0,-0.3638 0.119063,-0.66146 
					0.264584,-0.66146 0.145521,0 0.264583,-0.29765 0.264583,-0.66146 0,-0.3638 
					0.119063,-0.66145 0.264583,-0.66145 0.145521,0 0.264584,-0.1871 
					0.264584,-0.41578 0,-0.47519 0.617894,-1.17172 1.039434,-1.17172 0.155916,0 
					0.283482,-0.27138 0.283482,-0.60305 0,-0.33168 0.237393,-0.77416 
					0.527537,-0.98328 0.290145,-0.20912 0.656566,-0.77119 0.814269,-1.24903 
					0.157702,-0.47785 0.401973,-0.86881 0.542824,-0.86881 0.412756,0 
					1.425586,-1.13086 1.828919,-2.04205 0.212702,-0.48053 0.448588,-0.71898 
					0.549582,-0.55557 0.103352,0.16723 0.25717,0.0335 0.369864,-0.32158 
					0.188524,-0.59398 1.267238,-1.843301 1.591585,-1.843301 0.211905,0 
					0.919171,-0.707265 0.919171,-0.91917 0,-0.273788 1.280451,-1.462079 
					1.575474,-1.462079 0.152136,0 0.276609,-0.111041 0.276609,-0.246753 0,-0.135716 
					0.239853,-0.322882 0.533008,-0.415925 0.293156,-0.09304 0.464723,-0.27966 
					0.38126,-0.414706 -0.08346,-0.135043 0.03387,-0.245533 0.260741,-0.245533 
					0.22687,0 0.412491,-0.119063 0.412491,-0.264583 0,-0.145521 0.124473,-0.264584 
					0.276609,-0.264584 0.295023,0 1.575474,-1.188291 1.575474,-1.462079 0,-0.26923 
					0.734981,-0.919171 1.039435,-0.919171 0.155916,0 0.283482,-0.178593 
					0.283482,-0.396875 0,-0.218281 0.119063,-0.396875 0.264583,-0.396875 0.145521,0 
					0.264584,-0.238125 0.264584,-0.529166 0,-0.291042 0.119062,-0.529167 
					0.264583,-0.529167 0.145521,0 0.264583,-0.238125 0.264583,-0.529166 0,-0.293982 
					0.17639,-0.529167 0.396875,-0.529167 0.277183,0 0.396875,-0.279284 
					0.396875,-0.926042 0,-0.509323 0.119063,-0.926041 0.264584,-0.926041 0.145521,0 
					0.264583,-0.416719 0.264583,-0.926042 0,-0.509323 -0.119062,-0.926042 
					-0.264583,-0.926042 -0.145521,0 -0.264584,-0.248046 -0.264584,-0.551214 
					0,-0.321265 -0.136985,-0.505555 -0.328366,-0.441761 -0.368477,0.122827 
					-0.99455,-0.406091 -0.99455,-0.840209 0,-0.439647 -4.109826,-0.369194 
					-4.557141,0.07812 -0.506331,0.506333 -1.956634,0.732301 -5.599954,0.872517 
					-2.024415,0.07791 -3.245697,0.02101 -3.426355,-0.159653 -0.154395,-0.154395 
					-0.601355,-0.280717 -0.993243,-0.280717 -0.39189,0 -0.781058,-0.178594 
					-0.864822,-0.396875 -0.08376,-0.218281 -0.394155,-0.396875 -0.689766,-0.396875 
					-0.295609,0 -0.53747,-0.09 -0.53747,-0.199996 0,-0.109998 -0.354287,-0.35682 
					-0.787307,-0.548492 -0.43302,-0.191672 -0.760442,-0.434321 -0.727604,-0.539218 
					0.03284,-0.104897 -0.416547,-0.661747 -0.99863,-1.237446 -0.582084,-0.575696 
					-1.091856,-1.248714 -1.132827,-1.495591 -0.04097,-0.24688 -0.2486,-0.782661 
					-0.461391,-1.190625 -0.212794,-0.407966 -0.387631,-0.890585 -0.388528,-1.072486 
					-7.93e-4,-0.181901 -0.101507,-0.330729 -0.223578,-0.330729 -0.35624,0 
					-0.925131,-4.304961 -0.755758,-5.719035 0.307181,-2.564624 0.532339,-3.676602 
					0.776814,-3.836431 0.408252,-0.266902 0.983853,-4.163219 0.989438,-6.697623 
					0.0061,-2.754638 -0.324622,-3.880818 -1.270661,-4.327276 -0.819833,-0.386898 
					-3.486634,-0.430599 -3.486634,-0.05713 0,0.145521 -0.178594,0.264583 
					-0.396875,0.264583 -0.218282,0 -0.396875,0.178594 -0.396875,0.396875 0,0.218281 
					-0.124474,0.396875 -0.276609,0.396875 -0.433382,0 -1.575475,1.251694 
					-1.575475,1.726663 0,0.418028 -0.594574,1.183754 -0.91917,1.183754 -0.273789,0 
					-1.46208,1.280451 -1.46208,1.575474 0,0.152136 -0.178594,0.276609 
					-0.396875,0.276609 -0.218281,0 -0.396875,0.119062 -0.396875,0.264583 0,0.145521 
					-0.238125,0.264584 -0.529167,0.264584 -0.291041,0 -0.529166,0.119062 
					-0.529166,0.264583 0,0.145521 -0.297657,0.264583 -0.661459,0.264583 -0.363802,0 
					-0.661458,0.119063 -0.661458,0.264584 0,0.14552 -0.535781,0.264583 
					-1.190625,0.264583 -0.881944,0 -1.190625,0.102894 -1.190625,0.396875 0,0.319704 
					-0.411575,0.396875 -2.116667,0.396875 -1.705091,0 -2.116666,-0.07717 
					-2.116666,-0.396875 0,-0.264584 -0.264584,-0.396875 -0.79375,-0.396875 
					-0.436563,0 -0.793751,-0.119063 -0.793751,-0.264583 0,-0.145521 
					-0.297656,-0.264584 -0.661458,-0.264584 -0.363802,0 -0.661458,-0.119062 
					-0.661458,-0.264583 0,-0.145521 -0.238125,-0.264583 -0.529167,-0.264583 
					-0.291042,0 -0.529167,-0.119063 -0.529167,-0.264584 0,-0.145521 
					-0.178593,-0.264583 -0.396875,-0.264583 -0.218281,0 -0.396875,-0.123031 
					-0.396875,-0.273402 0,-0.355716 -1.752131,-2.107848 -2.107848,-2.107848 
					-0.15037,0 -0.273402,-0.178594 -0.273402,-0.396875 0,-0.218281 
					-0.119062,-0.396875 -0.264583,-0.396875 -0.145521,0 -0.264583,-0.187097 
					-0.264583,-0.415774 0,-0.475189 -0.617895,-1.171726 -1.039434,-1.171726 
					-0.155917,0 -0.283483,-0.297656 -0.283483,-0.661458 0,-0.363802 
					-0.119063,-0.661458 -0.264583,-0.661458 -0.145521,0 -0.264584,-0.416719 
					-0.264584,-0.926042 0,-0.509323 -0.119062,-0.926042 -0.264583,-0.926042 
					-0.145521,0 -0.264583,-0.416718 -0.264583,-0.926041 0,-0.509323 
					-0.119063,-0.926042 -0.264584,-0.926042 -0.145521,0 -0.264583,-0.357187 
					-0.264583,-0.79375 0,-0.529167 -0.132292,-0.79375 -0.396875,-0.79375 -0.323379,0 
					-0.396875,-0.440973 -0.396875,-2.38125 0,-1.41111 -0.107794,-2.38125 
					-0.264583,-2.38125 -0.161052,0 -0.264584,-1.190625 -0.264584,-3.042708 
					0,-1.852083 0.103532,-3.042708 0.264584,-3.042708 0.167343,0 0.264583,-1.896181 
					0.264583,-5.159375 0,-4.409723 0.05767,-5.159374 0.396875,-5.159374 0.314981,0 
					0.396875,-0.382175 0.396875,-1.852084 0,-1.469908 -0.08189,-1.852083 
					-0.396875,-1.852083 -0.326319,0 -0.396875,-0.47037 -0.396875,-2.645833 0,-1.5875 
					-0.105833,-2.645833 -0.264583,-2.645833 -0.145521,0 -0.264584,-0.297657 
					-0.264584,-0.661459 0,-0.363802 -0.119062,-0.661458 -0.264583,-0.661458 
					-0.145521,0 -0.264583,-0.235857 -0.264583,-0.524126 0,-0.338408 
					-0.234382,-0.582951 -0.661459,-0.690141 -0.363802,-0.09131 -0.661458,-0.272171 
					-0.661458,-0.401916 0,-0.129745 -0.654844,-0.2359 -1.455208,-0.2359 -0.800365,0 
					-1.455209,0.106155 -1.455209,0.2359 0,0.129745 -0.297656,0.310607 
					-0.661458,0.401916 -0.363802,0.09131 -0.661459,0.347319 -0.661459,0.568913 
					0,0.221593 -0.238125,0.658494 -0.529166,0.970891 -0.291042,0.312396 
					-0.529167,0.90273 -0.529167,1.311853 0,0.409123 -0.119062,0.74386 
					-0.264583,0.74386 -0.145521,0 -0.264584,0.416719 -0.264584,0.926042 0,0.646759 
					-0.119692,0.926041 -0.396875,0.926041 -0.218281,0 -0.396875,0.180209 
					-0.396875,0.400465 0,0.220256 -0.104155,0.607209 -0.231457,0.859896 
					-0.991092,1.967257 -1.356043,2.809706 -1.356043,3.130267 0,0.204531 
					-0.15667,0.371872 -0.348154,0.371872 -0.191485,0 -0.631325,0.506016 
					-0.977424,1.124479 -0.718055,1.283131 -1.158925,1.785937 -1.565939,1.785937 
					-0.155916,0 -0.283628,0.0893 -0.283805,0.198438 -5.3e-4,0.307705 
					-1.221476,1.653646 -1.500111,1.653646 -0.136533,0 -0.682766,0.398007 
					-1.213848,0.884462 -0.906412,0.830239 -3.719231,2.290538 -4.412023,2.290538 
					-0.172292,0 -0.249487,0.103184 -0.171544,0.229301 0.187971,0.304141 
					-2.370543,0.814974 -5.005277,0.999352 -1.570648,0.109913 -2.784326,0.0097 
					-4.704878,-0.388596 C 11.367357,37.012486 11.470296,37.093864 
					7.4490073,31.707789 6.1260856,29.935883 3.8414992,24.924058 3.1921358,22.369254 
					2.1659435,18.331885 2.1029263,16.23741 2.8907134,12.351014 3.2594339,10.532003 
					3.7333926,8.5558944 3.9439554,7.9596601 4.9347653,5.154059 6.8988785,2.2454153 
					8.6679729,0.96388994 L 9.7498518,0.180181 H 40.827005 c 29.489138,0 
					31.067997,0.0236599 30.898024,0.46302081 -0.09852,0.25466145 
					-0.245206,0.79044269 -0.325965,1.19062499 -0.08076,0.4001822 -0.310546,0.7276041 
					-0.510637,0.7276041 -0.239385,0 -0.363803,0.3166983 -0.363803,0.9260416 
					0,0.5093229 -0.100046,0.9260416 -0.222326,0.9260416 -0.122277,0 
					-0.379418,0.6548437 -0.571424,1.4552083 -0.192005,0.8003645 -0.449146,1.4552082 
					-0.571423,1.4552082 -0.12228,0 -0.222327,0.2976563 -0.222327,0.6614583 
					0,0.4115742 -0.149931,0.6614583 -0.396875,0.6614583 -0.343251,0 
					-0.558225,0.8301887 -0.428207,1.6536458 0.01723,0.109141 -0.08773,0.198437 
					-0.233251,0.198437 -0.145521,0 -0.264583,0.238125 -0.264583,0.529167 0,0.291042 
					-0.119063,0.529167 -0.264584,0.529167 -0.14552,0 -0.266406,0.446484 
					-0.268634,0.992187 -0.003,0.731811 -0.07034,0.888037 -0.256667,0.595313 
					-0.198321,-0.311581 -0.289853,-0.212071 -0.425894,0.463021 -0.09531,0.472942 
					-0.277463,0.859895 -0.404794,0.859895 -0.127331,0 -0.231511,0.416719 
					-0.231511,0.926042 0,0.509323 -0.119062,0.926042 -0.264583,0.926042 -0.145521,0 
					-0.264583,0.35016 -0.264583,0.778135 0,0.427974 -0.119063,0.851719 
					-0.264584,0.941656 -0.14552,0.08994 -0.264583,0.573214 -0.264583,1.073948 
					0,0.587231 -0.117406,0.886946 -0.330729,0.844281 -0.21898,-0.0438 
					-0.357979,0.358503 -0.411372,1.190625 -0.04435,0.691224 -0.193183,1.256771 
					-0.330729,1.256771 -0.137549,0 -0.250087,1.012031 -0.250087,2.248958 0,1.322917 
					0.108945,2.248958 0.264584,2.248958 0.145521,0 0.264583,0.321628 
					0.264583,0.71473 0,0.504079 0.350914,1.046215 1.190625,1.839431 
					0.654844,0.618585 1.190625,1.249457 1.190625,1.401937 0,0.152479 
					0.214313,0.277235 0.47625,0.277235 0.261938,0 0.601975,0.125725 
					0.755637,0.279387 0.298095,0.298096 6.605064,0.200938 8.293113,-0.127756 
					1.965638,-0.382747 2.134061,-0.438603 1.996006,-0.661977 -0.07827,-0.126638 
					0.36335,-0.283131 0.981366,-0.347766 1.225929,-0.128212 5.053357,-0.867304 
					6.578325,-1.270304 0.526206,-0.139057 1.261843,-0.252833 1.634747,-0.252833 
					0.372904,0 0.84073,-0.162722 1.039614,-0.361604 0.198882,-0.198882 
					0.772933,-0.4304 1.275668,-0.514482 0.502737,-0.08408 1.509379,-0.273947 
					2.236983,-0.421923 0.727604,-0.147976 2.213615,-0.411274 3.302246,-0.585105 
					1.430674,-0.228447 1.834047,-0.372806 1.455208,-0.520791 -0.295362,-0.115376 
					0.861645,-0.211084 2.650879,-0.219281 1.992477,-0.0091 3.034007,0.07468 
					2.796437,0.225023 -0.28012,0.177264 -0.1253,0.287084 0.59531,0.42227 
					0.53563,0.100486 0.97388,0.28049 0.97388,0.400008 0,0.11952 0.23812,0.292886 
					0.52917,0.38526 0.29104,0.09237 0.52916,0.338521 0.52916,0.546997 0,0.208473 
					0.11906,0.379044 0.26459,0.379044 0.14552,0 0.26458,0.178594 0.26458,0.396875 
					0,0.218281 0.17859,0.396875 0.39687,0.396875 0.21828,0 0.39688,0.178594 
					0.39688,0.396875 0,0.218281 0.11906,0.396875 0.26458,0.396875 0.14552,0 
					0.26459,0.357188 0.26459,0.79375 0,0.436563 0.11906,0.79375 0.26458,0.79375 
					0.16332,0 0.26458,1.367015 0.26458,3.571875 0,2.20486 -0.10126,3.571875 
					-0.26458,3.571875 -0.14552,0 -0.26458,0.535781 -0.26458,1.190625 0,0.654843 
					-0.11907,1.190625 -0.26459,1.190625 -0.14552,0 -0.26458,0.416718 
					-0.26458,0.926041 0,0.646758 -0.11969,0.926042 -0.39688,0.926042 -0.27534,0 
					-0.39687,0.276833 -0.39687,0.903994 0,0.497194 -0.11804,0.943679 
					-0.2623,0.992187 -0.14427,0.04851 -0.2893,0.415616 -0.3223,0.815798 
					-0.033,0.400182 -0.15308,0.727604 -0.26686,0.727604 -0.11378,0 -0.20687,0.297656 
					-0.20687,0.661458 0,0.363802 -0.11907,0.661459 -0.26459,0.661459 -0.14552,0 
					-0.26458,0.297656 -0.26458,0.661458 0,0.411575 -0.14993,0.661458 
					-0.39688,0.661458 -0.22048,0 -0.39687,0.235186 -0.39687,0.529167 0,0.291042 
					-0.11906,0.529167 -0.26458,0.529167 -0.14553,0 -0.26459,0.243104 
					-0.26459,0.540228 0,0.297127 -0.23812,0.795827 -0.52916,1.108224 
					-0.29105,0.312398 -0.52917,0.724138 -0.52917,0.914979 0,0.190841 
					-0.1786,0.346985 -0.39688,0.346985 -0.23669,0 -0.39687,0.243396 
					-0.39687,0.603049 0,0.331676 -0.238127,0.774679 -0.529168,0.984451 
					-0.291042,0.209772 -0.529167,0.652775 -0.529167,0.984451 0,0.331676 
					-0.119062,0.603049 -0.264583,0.603049 -0.145521,0 -0.264584,0.238125 
					-0.264584,0.529167 0,0.293981 -0.176389,0.529166 -0.396875,0.529166 -0.308681,0 
					-0.396875,0.352777 -0.396875,1.5875 0,0.977098 0.111784,1.5875 0.290722,1.5875 
					0.159896,0 0.624385,0.357188 1.032195,0.79375 l 0.741474,0.79375 h 4.391761 c 
					2.75145,0 4.39177,-0.09882 4.39177,-0.264583 0,-0.145521 0.53578,-0.264584 
					1.19062,-0.264584 0.65484,0 1.19063,-0.119062 1.19063,-0.264583 0,-0.145521 
					0.53578,-0.264583 1.19062,-0.264583 0.65484,0 1.19063,-0.119063 
					1.19063,-0.264584 0,-0.14552 0.41671,-0.264583 0.92604,-0.264583 0.64676,0 
					0.92604,-0.119692 0.92604,-0.396875 0,-0.293981 0.30868,-0.396875 
					1.19062,-0.396875 0.65485,0 1.19063,-0.119062 1.19063,-0.264583 0,-0.145521 
					0.41672,-0.264584 0.92604,-0.264584 0.50932,0 0.92604,-0.119062 
					0.92604,-0.264583 0,-0.145521 0.41672,-0.264583 0.92604,-0.264583 0.50933,0 
					0.92605,-0.119063 0.92605,-0.264584 0,-0.14552 0.53578,-0.264583 
					1.19062,-0.264583 0.88194,0 1.19063,-0.102894 1.19063,-0.396875 0,-0.308681 
					0.35277,-0.396875 1.5875,-0.396875 0.88194,0 1.5875,-0.117591 1.5875,-0.264583 
					0,-0.162819 1.32291,-0.264583 3.43958,-0.264583 2.11667,0 3.43958,0.101764 
					3.43958,0.264583 0,0.145521 0.53578,0.264583 1.19063,0.264583 0.88194,0 
					1.19062,0.102894 1.19062,0.396875 0,0.264583 0.26459,0.396875 0.79375,0.396875 
					0.43657,0 0.79375,0.119063 0.79375,0.264583 0,0.145521 0.23813,0.264584 
					0.52917,0.264584 0.29104,0 0.52917,0.119062 0.52917,0.264583 0,0.145521 
					0.23812,0.264583 0.52916,0.264583 0.29104,0 0.52917,0.119063 0.52917,0.264584 
					0,0.145521 0.17859,0.264583 0.39687,0.264583 0.21829,0 0.39688,0.127566 
					0.39688,0.283483 0,0.31737 0.65455,1.039434 0.94225,1.039434 0.30332,0 
					1.17442,1.109403 1.17442,1.495692 0,0.196014 0.16415,0.356391 0.36477,0.356391 
					0.20063,0 0.51273,0.357187 0.69356,0.79375 0.18083,0.436562 0.41388,0.79375 
					0.51789,0.79375 0.104,0 0.38772,0.714375 0.63047,1.5875 0.24275,0.873125 
					0.54132,1.5875 0.66348,1.5875 0.12217,0 0.43628,1.0205 0.69803,2.26778 
					0.55103,2.625725 0.43525,7.755501 -0.22649,10.035344 -0.21119,0.727604 
					-0.3977,1.203854 -0.41447,1.058333 -0.0284,-0.24629 -1.21291,2.908443 
					-1.45408,3.872624 -0.14771,0.590532 -0.76411,1.551334 -0.99526,1.551334 
					-0.10371,0 -0.42106,0.525598 -0.70522,1.167998 -0.28416,0.642398 
					-0.77618,1.306888 -1.09337,1.476645 -0.3172,0.169757 -0.50805,0.377322 
					-0.42411,0.461256 0.0839,0.08394 -0.26305,0.659873 -0.77109,1.279864 
					-0.50803,0.61999 -0.9237,1.256043 -0.9237,1.413454 0,0.157409 -0.0938,0.256434 
					-0.20846,0.220054 -0.26541,-0.08422 -1.6557,1.288791 -1.48145,1.46304 
					0.0716,0.07156 -0.46637,0.590201 -1.19539,1.152541 -0.72902,0.562342 
					-1.33056,1.12024 -1.33676,1.239776 -0.0133,0.256305 -0.69635,0.973289 
					-0.92723,0.973289 -0.088,0 -0.54882,0.386953 -1.02403,0.859895 -0.47521,0.472943 
					-1.30386,1.157552 -1.84145,1.521354 -0.5376,0.363801 -0.97826,0.750751 
					-0.97925,0.859901 -0.001,0.10914 -0.1804,0.19843 -0.39869,0.19843 -0.21828,0 
					-0.39687,0.16415 -0.39687,0.36478 0,0.20062 -0.35719,0.51273 -0.79375,0.69356 
					-0.43656,0.18083 -0.79375,0.4334 -0.79375,0.56126 0,0.12787 -0.17859,0.23249 
					-0.39688,0.23249 -0.21828,0 -0.39687,0.13486 -0.39687,0.29969 0,0.38761 
					-0.9651,1.22255 -1.67273,1.44715 -0.5728,0.1818 -1.23769,0.73581 
					-1.23769,1.03128 0,0.29984 -0.6459,0.79108 -1.15514,0.87854 -0.53943,0.0926 
					-1.75527,1.19698 -1.75527,1.59429 0,0.13636 -0.29346,0.32158 -0.65214,0.41161 
					-0.35867,0.09 -0.94857,0.46011 -1.31089,0.82243 -0.36231,0.36231 
					-0.78323,0.65876 -0.93537,0.65876 -0.15213,0 -0.2766,0.12756 -0.2766,0.28348 
					0,0.42154 -0.69654,1.03943 -1.17173,1.03943 -0.22868,0 -0.41577,0.11906 
					-0.41577,0.26459 0,0.14552 -0.1786,0.26458 -0.39688,0.26458 -0.21828,0 
					-0.39687,0.12447 -0.39687,0.27661 0,0.29502 -1.1883,1.57547 -1.46208,1.57547 
					-0.21191,0 -0.91917,0.70727 -0.91917,0.91917 0,0.21252 -1.24957,1.46208 
					-1.46208,1.46208 -0.21191,0 -0.91917,0.70727 -0.91917,0.91917 0,0.21252 
					-1.24957,1.46208 -1.46208,1.46208 -0.21191,0 -0.91917,0.70727 -0.91917,0.91917 
					0,0.27379 -1.28046,1.46208 -1.57548,1.46208 -0.15213,0 -0.27661,0.12447 
					-0.27661,0.27661 0,0.29502 -1.18829,1.57547 -1.46208,1.57547 -0.2119,0 
					-0.91917,0.70727 -0.91917,0.91918 0,0.21251 -1.24956,1.46207 -1.46208,1.46207 
					-0.2119,0 -0.91917,0.70727 -0.91917,0.91918 0,0.3447 -1.31957,1.46207 
					-1.72666,1.46207 -0.485181,0 -1.183755,0.61341 -1.183755,1.03944 0,0.15592 
					-0.178594,0.28348 -0.396875,0.28348 -0.218281,0 -0.396875,0.11906 
					-0.396875,0.26458 0,0.14553 -0.238125,0.26459 -0.529167,0.26459 -0.291041,0 
					-0.529166,0.11906 -0.529166,0.26458 0,0.14552 -0.297657,0.26458 
					-0.661459,0.26458 -0.411575,0 -0.661458,0.14994 -0.661458,0.39688 0,0.22048 
					-0.235185,0.39687 -0.529167,0.39687 -0.291041,0 -0.529166,0.11907 
					-0.529166,0.26459 0,0.14552 -0.416719,0.26458 -0.926042,0.26458 -0.509323,0 
					-0.926042,0.11906 -0.926042,0.26458 0,0.14553 -0.416718,0.26459 
					-0.926041,0.26459 -0.509323,0 -0.926042,0.11906 -0.926042,0.26458 0,0.16228 
					-1.278819,0.26458 -3.307292,0.26458 -2.028473,0 -3.307292,-0.1023 
					-3.307292,-0.26458 z m 74.083339,-3.57187 c 0,-0.22049 -0.23519,-0.39688 
					-0.52917,-0.39688 -0.29104,0 -0.52917,-0.11906 -0.52917,-0.26458 0,-0.14552 
					-0.24353,-0.26459 -0.54119,-0.26459 -0.5631,0 -1.84006,-1.09333 
					-1.84006,-1.57547 0,-0.15214 -0.17859,-0.27661 -0.39687,-0.27661 -0.21828,0 
					-0.39688,-0.2282 -0.39688,-0.50712 0,-0.27891 -0.12554,-0.54896 
					-0.27899,-0.60011 -0.15344,-0.0512 -0.30877,-0.26943 -0.34516,-0.48507 
					-0.0364,-0.21564 -0.26804,-0.77311 -0.51476,-1.23883 -0.24672,-0.46572 
					-0.44859,-1.08229 -0.44859,-1.37016 0,-0.28788 -0.17859,-0.702 -0.39687,-0.92028 
					-0.27226,-0.27226 -0.39688,-0.94284 -0.39688,-2.13557 0,-0.98274 
					-0.11503,-1.73869 -0.26458,-1.73869 -0.15287,0 -0.26458,-0.83785 
					-0.26458,-1.98438 0,-1.14652 0.11171,-1.98437 0.26458,-1.98437 0.15973,0 
					0.26619,-1.12718 0.26863,-2.84427 0.002,-1.65323 0.0977,-2.69649 
					0.22777,-2.49136 0.32926,0.51942 0.56193,-0.23822 0.56193,-1.82976 0,-0.7157 
					0.11907,-1.30128 0.26459,-1.30128 0.14552,0 0.26458,-0.46922 0.26458,-1.04272 
					0,-0.57349 0.11906,-1.1163 0.26458,-1.20624 0.14552,-0.0899 0.26459,-0.57321 
					0.26459,-1.07394 0,-0.50074 0.11906,-0.910431 0.26458,-0.910431 0.14552,0 
					0.26458,-0.416718 0.26458,-0.926041 0,-0.646758 0.1197,-0.926042 
					0.39688,-0.926042 0.27718,0 0.39687,-0.279283 0.39687,-0.926041 0,-0.509323 
					0.11907,-0.926042 0.26459,-0.926042 0.14552,0 0.26458,-0.238125 
					0.26458,-0.529167 0,-0.291041 0.11906,-0.529166 0.26458,-0.529166 0.14552,0 
					0.26459,-0.357188 0.26459,-0.79375 0,-0.436563 0.11906,-0.79375 0.26458,-0.79375 
					0.14552,0 0.26458,-0.238125 0.26458,-0.529167 0,-0.291042 0.15326,-0.529167 
					0.34057,-0.529167 0.18732,0 0.36062,-0.266128 0.38512,-0.591394 0.0245,-0.325265 
					0.1689,-0.632843 0.32089,-0.683506 0.15199,-0.05066 0.27634,-0.260786 
					0.27634,-0.466939 0,-0.206155 0.11906,-0.374827 0.26458,-0.374827 0.14552,0 
					0.26459,-0.238125 0.26459,-0.529167 0,-0.291041 0.11906,-0.529166 
					0.26458,-0.529166 0.14552,0 0.26458,-0.178594 0.26458,-0.396875 0,-0.218282 
					0.12757,-0.396875 0.28349,-0.396875 0.30835,0 1.03943,-0.651325 
					1.03943,-0.926042 0,-0.274717 0.73108,-0.926042 1.03943,-0.926042 0.15592,0 
					0.28349,-0.09701 0.28349,-0.215579 0,-0.309195 1.76239,-1.106594 
					2.45637,-1.111388 0.47558,-0.0033 0.51137,-0.0525 0.18946,-0.260532 
					-0.27073,-0.174964 0.40201,-0.251857 2.11667,-0.241935 1.64736,0.0095 
					2.33118,0.0929 1.98437,0.241935 -0.47327,0.203374 -0.45431,0.229354 
					0.17954,0.245985 0.38979,0.01024 0.8873,0.197192 1.10558,0.415473 
					0.21828,0.218281 0.58939,0.396875 0.82468,0.396875 0.54435,0 1.72666,1.169371 
					1.72666,1.707766 0,0.224896 0.17859,0.4089 0.39688,0.4089 0.21828,0 
					0.39687,0.178594 0.39687,0.396875 0,0.218282 0.11906,0.396875 0.26458,0.396875 
					0.14552,0 0.26459,0.297657 0.26459,0.661459 0,0.363802 0.11906,0.661458 
					0.26458,0.661458 0.14552,0 0.26458,0.357187 0.26458,0.79375 0,0.436562 
					0.11907,0.79375 0.26459,0.79375 0.16855,0 0.26458,2.160764 0.26458,5.953125 
					0,3.792355 -0.096,5.953125 -0.26458,5.953125 -0.15119,0 -0.26459,0.79375 
					-0.26459,1.85208 0,1.05834 -0.11339,1.85209 -0.26458,1.85209 -0.14552,0 
					-0.26458,0.65484 -0.26458,1.4552 0,0.80037 -0.11907,1.45521 -0.26459,1.45521 
					-0.14552,0 -0.26458,0.47625 -0.26458,1.05834 0,0.76435 -0.11024,1.05833 
					-0.39687,1.05833 -0.26459,0 -0.39688,0.26458 -0.39688,0.79375 0,0.43656 
					-0.11906,0.79375 -0.26458,0.79375 -0.14552,0 -0.26459,0.41672 -0.26459,0.92604 
					0,0.50932 -0.11906,0.92604 -0.26458,0.92604 -0.14552,0 -0.26458,0.41672 
					-0.26458,0.92604 0,0.50933 -0.11907,0.92605 -0.26459,0.92605 -0.14552,0 
					-0.26458,0.41671 -0.26458,0.92604 0,0.64675 -0.11969,0.92604 -0.39687,0.92604 
					-0.27719,0 -0.39688,0.27928 -0.39688,0.92604 0,0.50932 -0.11906,0.92604 
					-0.26458,0.92604 -0.14552,0 -0.26459,0.23813 -0.26459,0.52917 0,0.29104 
					-0.11906,0.52916 -0.26458,0.52916 -0.14552,0 -0.26458,0.29766 -0.26458,0.66146 
					0,0.41158 -0.14993,0.66146 -0.39688,0.66146 -0.21828,0 -0.39687,0.21184 
					-0.39687,0.47076 0,0.25891 -0.23813,0.64239 -0.52917,0.85216 -0.29104,0.20977 
					-0.52917,0.60175 -0.52917,0.87106 0,0.64951 -0.62405,1.2456 -1.30401,1.2456 
					-0.30144,0 -0.54807,0.11907 -0.54807,0.26459 0,0.14552 -0.23813,0.26458 
					-0.52917,0.26458 -0.29398,0 -0.52916,0.17639 -0.52916,0.39688 0,0.32758 
					-0.48507,0.39687 -2.77813,0.39687 -2.29305,0 -2.77812,-0.0693 -2.77812,-0.39687 
					z M 19.724623,117.91976 c 0,-0.14552 -0.47625,-0.26458 -1.058333,-0.26458 
					-0.582083,0 -1.058333,-0.11907 -1.058333,-0.26459 0,-0.14552 -0.238125,-0.26458 
					-0.529167,-0.26458 -0.293981,0 -0.529167,-0.17639 -0.529167,-0.39688 0,-0.21828 
					-0.150334,-0.39687 -0.334076,-0.39687 -0.183742,0 -0.558522,-0.29888 
					-0.832844,-0.66417 -0.274323,-0.36529 -1.195482,-0.97683 -2.047021,-1.35898 
					-2.194512,-0.98484 -4.6303097,-3.74193 -5.7282001,-6.48378 -1.1456408,-2.8611 
					-1.4840093,-4.86035 -1.283684,-7.58463 0.092897,-1.263342 0.2839834,-3.895901 
					0.4246354,-5.850135 0.239786,-3.331609 0.3200741,-3.687249 1.2876673,-5.703766 
					2.0534814,-4.279569 3.9646984,-5.460108 6.9888054,-4.316909 0.398333,0.150579 
					0.672323,0.35779 0.608867,0.460465 -0.137633,0.222694 0.961449,1.224494 
					1.246579,1.136242 0.109141,-0.03378 0.198438,0.117174 0.198438,0.335455 
					0,0.218281 0.119062,0.396875 0.264583,0.396875 0.145521,0 0.264584,0.156231 
					0.264584,0.347178 0,0.190947 0.238125,0.840041 0.529166,1.442426 
					0.291042,0.602388 0.529167,1.269857 0.529167,1.483268 0,0.643083 
					0.304652,1.231511 0.556891,1.075618 0.130272,-0.08051 0.236859,0.285187 
					0.236859,0.812662 0,0.527479 0.105689,1.159585 0.234864,1.404679 
					0.129175,0.245094 0.291045,1.10047 0.359711,1.900835 0.119023,1.387327 
					0.358812,2.128194 1.070432,3.307291 0.400197,0.663094 1.509993,0.869778 
					1.509993,0.281216 0,-0.227428 0.119063,-0.413507 0.264583,-0.413507 0.145521,0 
					0.264584,-0.304684 0.264584,-0.677074 0,-0.372391 0.106586,-0.611198 
					0.236859,-0.530686 0.270802,0.167365 0.556891,-0.446603 0.556891,-1.19513 
					0,-0.279138 0.119062,-0.507527 0.264583,-0.507527 0.145521,0 0.264583,-0.171841 
					0.264583,-0.381873 0,-0.210029 0.264618,-0.567216 0.588039,-0.79375 
					0.323421,-0.226531 0.472249,-0.41756 0.330729,-0.424508 -0.14152,-0.007 
					0.26715,-0.196247 0.908156,-0.420669 0.890538,-0.311785 1.314768,-0.340013 
					1.798387,-0.11966 0.348107,0.158607 0.872471,0.288377 1.165254,0.288377 
					0.292783,0 0.471874,0.09782 0.397978,0.217387 -0.12488,0.202062 0.72848,1.10553 
					1.044218,1.10553 0.25745,0 1.440156,1.293831 1.440156,1.575474 0,0.152136 
					0.134864,0.276609 0.299694,0.276609 0.164833,0 0.525187,0.259995 
					0.80079,0.577768 0.416611,0.480354 0.622581,0.53504 1.221658,0.324355 
					0.653873,-0.229954 0.720567,-0.375261 0.720567,-1.569953 0,-0.912124 
					-0.101595,-1.296225 -0.330729,-1.250399 -0.516713,0.103343 -0.452541,-3.005495 
					0.06614,-3.204535 0.218282,-0.08376 0.396875,-0.308341 0.396875,-0.499065 
					0,-0.190723 0.238125,-0.518399 0.529167,-0.728171 0.291042,-0.209772 
					0.529167,-0.520197 0.529167,-0.689837 0,-0.903965 4.716491,-0.899994 
					5.74086,0.0048 0.884849,0.781592 1.142715,0.949613 1.457868,0.949909 
					0.431577,5.29e-4 1.797105,1.159886 1.797105,1.525932 0,0.150735 
					0.360736,0.399817 0.801635,0.553513 0.785879,0.27396 1.579615,0.890773 
					1.579615,1.227524 0,0.368432 0.785207,0.926042 1.304018,0.926042 0.301437,0 
					0.548066,0.107415 0.548066,0.238699 0,0.249743 1.407189,0.819634 
					2.023856,0.819634 0.196567,0 0.357394,-0.168672 0.357394,-0.374827 0,-0.206153 
					0.148828,-0.428723 0.330729,-0.494597 0.264583,-0.09582 0.264583,-0.231801 
					0,-0.679918 -0.181901,-0.308086 -0.330729,-0.740362 -0.330729,-0.960617 
					0,-0.220255 -0.119063,-0.400466 -0.264584,-0.400466 -0.14552,0 
					-0.264583,-0.178593 -0.264583,-0.396875 0,-0.218281 -0.119062,-0.396875 
					-0.264583,-0.396875 -0.145521,0 -0.264584,-0.297656 -0.264584,-0.661458 
					0,-0.411575 -0.149931,-0.661458 -0.396875,-0.661458 -0.277182,0 
					-0.396875,-0.279284 -0.396875,-0.926042 0,-0.509323 -0.119062,-0.926041 
					-0.264583,-0.926041 -0.149251,0 -0.264583,-0.749652 -0.264583,-1.719792 
					0,-0.97014 0.115332,-1.719792 0.264583,-1.719792 0.145521,0 0.264583,-0.416718 
					0.264583,-0.926041 0,-0.646758 0.119693,-0.926042 0.396875,-0.926042 0.218282,0 
					0.396875,-0.190357 0.396875,-0.423013 0,-0.853543 1.523125,-1.913181 
					2.985337,-2.076897 1.655415,-0.185351 1.76027,-0.167772 3.695393,0.619509 
					0.836745,0.340418 1.521354,0.783709 1.521354,0.985088 0,0.20138 
					0.124473,0.366147 0.276609,0.366147 0.424719,0 1.575474,1.247383 
					1.575474,1.707766 0,0.224896 0.105177,0.4089 0.233725,0.4089 0.128548,0 
					0.299128,0.250105 0.379066,0.555789 0.08003,0.306038 0.272238,0.477361 
					0.427734,0.381259 0.172034,-0.106322 0.282392,0.09007 0.282392,0.502545 
					0,0.37239 0.119062,0.677074 0.264583,0.677074 0.145521,0 0.264584,0.297656 
					0.264584,0.661458 0,0.363802 0.119062,0.661458 0.264583,0.661458 0.145521,0 
					0.264583,0.239742 0.264583,0.532757 0,0.293016 0.111871,0.7395 0.248603,0.992188 
					0.136731,0.252687 0.323503,0.810408 0.415052,1.239382 0.12836,0.601456 
					0.221916,0.692282 0.408807,0.396875 0.164833,-0.260546 0.243647,0.06123 
					0.246404,1.005986 0.0021,0.763984 0.123114,1.389062 0.268634,1.389062 0.146699,0 
					0.264584,0.700349 0.264584,1.571884 0,0.864537 0.119062,1.64547 
					0.264583,1.735408 0.151313,0.09352 0.264583,1.45275 0.264583,3.174997 0,1.72225 
					-0.11327,3.08149 -0.264583,3.175 -0.145521,0.0899 -0.264583,0.87087 
					-0.264583,1.73541 0,0.87154 -0.117885,1.57189 -0.264584,1.57189 -0.14552,0 
					-0.264583,0.41671 -0.264583,0.92604 0,0.64676 -0.119692,0.92604 
					-0.396875,0.92604 -0.246944,0 -0.396875,0.24988 -0.396875,0.66146 0,0.3638 
					-0.119062,0.66146 -0.264583,0.66146 -0.145521,0 -0.264584,0.23812 
					-0.264584,0.52916 0,0.29104 -0.119062,0.52917 -0.264583,0.52917 -0.145521,0 
					-0.264583,0.12757 -0.264583,0.28348 0,0.50075 -0.736278,1.03944 
					-1.420694,1.03944 -0.365609,0 -0.738328,0.11906 -0.828265,0.26458 
					-0.08994,0.14552 -0.566187,0.26458 -1.058333,0.26458 -0.492146,0 
					-0.968396,-0.11906 -1.058333,-0.26458 -0.08994,-0.14552 -0.430414,-0.26458 
					-0.756616,-0.26458 -0.815361,0 -2.016938,-0.59036 -2.019446,-0.99219 
					-0.0011,-0.1819 -0.210423,-0.33088 -0.465084,-0.33105 -0.541047,-2.7e-4 
					-1.91823,-1.1145 -1.91823,-1.55182 0,-0.16497 -0.178593,-0.29994 
					-0.396875,-0.29994 -0.218281,0 -0.396875,-0.1786 -0.396875,-0.39688 0,-0.21828 
					-0.119062,-0.39687 -0.264583,-0.39687 -0.145521,0 -0.264583,-0.1786 
					-0.264583,-0.39688 0,-0.21828 -0.119063,-0.39687 -0.264584,-0.39687 -0.14552,0 
					-0.264583,-0.1786 -0.264583,-0.39688 0,-0.21828 -0.119062,-0.39687 
					-0.264583,-0.39687 -0.145521,0 -0.264584,-0.23813 -0.264584,-0.52917 0,-0.29104 
					-0.127566,-0.52917 -0.283482,-0.52917 -0.466916,0 -1.039434,-0.71825 
					-1.039434,-1.30401 0,-0.30144 -0.119063,-0.54807 -0.264584,-0.54807 -0.14552,0 
					-0.264583,-0.17859 -0.264583,-0.39688 0,-0.21828 -0.178594,-0.39687 
					-0.396875,-0.39687 -0.218281,0 -0.396875,-0.19036 -0.396875,-0.42301 0,-0.23266 
					-0.357188,-0.75668 -0.79375,-1.16449 -0.436563,-0.40781 -0.79375,-0.8723 
					-0.79375,-1.0322 0,-0.15989 -0.178594,-0.29072 -0.396875,-0.29072 -0.218281,0 
					-0.396875,-0.11906 -0.396875,-0.26458 0,-0.4075 -2.105837,-0.31321 
					-2.532441,0.11339 -0.207886,0.20789 -0.377976,0.62461 -0.377976,0.92604 
					0,0.30144 -0.119062,0.54807 -0.264583,0.54807 -0.145521,0 -0.264583,0.29766 
					-0.264583,0.66146 0,0.3638 -0.119063,0.66146 -0.264584,0.66146 -0.145521,0 
					-0.264583,0.47998 -0.264583,1.06663 0,0.70405 -0.134914,1.11841 
					-0.396875,1.21893 -0.218281,0.0838 -0.396875,0.43019 -0.396875,0.76984 0,0.33964 
					-0.119063,0.69112 -0.264584,0.78105 -0.14552,0.0899 -0.264583,0.57322 
					-0.264583,1.07395 0,0.50074 -0.119062,0.91043 -0.264583,0.91043 -0.145521,0 
					-0.264584,0.41672 -0.264584,0.92604 0,0.64676 -0.119692,0.92604 
					-0.396875,0.92604 -0.225046,0 -0.396875,0.23729 -0.396875,0.54807 0,0.85262 
					-0.650959,1.30402 -1.880544,1.30402 -1.30066,0 -2.881956,-0.50523 
					-2.881956,-0.92079 0,-0.15692 -0.238125,-0.45694 -0.529166,-0.66671 
					-0.291042,-0.20978 -0.529167,-0.59189 -0.529167,-0.84914 0,-0.56515 
					-0.801333,-1.55866 -1.10553,-1.37066 -0.119562,0.0739 -0.217387,-0.11079 
					-0.217387,-0.41042 0,-0.29963 -0.119062,-0.54478 -0.264583,-0.54478 -0.145521,0 
					-0.264583,-0.29766 -0.264583,-0.66146 0,-0.3638 -0.110781,-0.66146 
					-0.246182,-0.66146 -0.1354,0 -0.287054,-0.26789 -0.33701,-0.59531 
					-0.04996,-0.32743 -0.236832,-0.56555 -0.415277,-0.52917 -0.187328,0.0382 
					-0.324448,-0.2134 -0.324448,-0.59531 0,-0.56199 -0.122211,-0.66146 
					-0.812649,-0.66146 -0.446956,0 -0.95785,0.14883 -1.135317,0.33073 
					-0.891651,0.91393 -2.244899,5.00024 -2.300053,6.94531 -0.01707,0.60214 
					-0.06824,0.67775 -0.211945,0.31324 -0.134422,-0.34096 -0.283468,-0.0253 
					-0.51321,1.08704 -0.178074,0.86215 -0.560709,1.84074 -0.850299,2.17464 
					-0.28959,0.3339 -0.526527,0.70794 -0.526527,0.83119 0,0.12325 -0.178594,0.2241 
					-0.396875,0.2241 -0.218281,0 -0.396875,0.12756 -0.396875,0.28348 0,0.5372 
					-0.757922,1.03944 -1.568601,1.03944 -0.446957,0 -0.812649,0.11906 
					-0.812649,0.26458 0,0.14552 -0.238125,0.26458 -0.529167,0.26458 -0.291042,0 
					-0.529167,-0.11906 -0.529167,-0.26458 z M 235.09546,88.10901 c 
					-1.23693,-0.204885 -2.66029,-0.443248 -3.16302,-0.529698 -0.50274,-0.08645 
					-1.07679,-0.3199 -1.27567,-0.518782 -0.19889,-0.198882 -0.65076,-0.361603 
					-1.00417,-0.361603 -0.3534,0 -0.64256,-0.119063 -0.64256,-0.264584 0,-0.14552 
					-0.23812,-0.264583 -0.52916,-0.264583 -0.29104,0 -0.52917,-0.119062 
					-0.52917,-0.264583 0,-0.145521 -0.29765,-0.264584 -0.66146,-0.264584 -0.3638,0 
					-0.66146,-0.119062 -0.66146,-0.264583 0,-0.145521 -0.23812,-0.264583 
					-0.52916,-0.264583 -0.29398,0 -0.52917,-0.17639 -0.52917,-0.396875 0,-0.218281 
					-0.17859,-0.396875 -0.39687,-0.396875 -0.21828,0 -0.39688,-0.119063 
					-0.39688,-0.264583 0,-0.145521 -0.1871,-0.264584 -0.41577,-0.264584 -0.47519,0 
					-1.17173,-0.617894 -1.17173,-1.039434 0,-0.155916 -0.12756,-0.283482 
					-0.28348,-0.283482 -0.15591,0 -0.44038,-0.148829 -0.63214,-0.33073 
					-0.19176,-0.181901 -0.65727,-0.554082 -1.03446,-0.827071 -0.37719,-0.272989 
					-0.62148,-0.600411 -0.54287,-0.727604 0.0786,-0.127193 -0.0427,-0.231262 
					-0.26956,-0.231262 -0.22687,0 -0.41249,-0.12609 -0.41249,-0.280199 0,-0.154109 
					-0.11906,-0.206613 -0.26458,-0.116676 -0.14552,0.08994 -0.26459,-0.02209 
					-0.26459,-0.248968 0,-0.226869 -0.11906,-0.41249 -0.26458,-0.41249 -0.14552,0 
					-0.26458,-0.178594 -0.26458,-0.396875 0,-0.218281 -0.11906,-0.396875 
					-0.26459,-0.396875 -0.14552,0 -0.26458,-0.178594 -0.26458,-0.396875 0,-0.218281 
					-0.12757,-0.396875 -0.28348,-0.396875 -0.42154,0 -1.03944,-0.696537 
					-1.03944,-1.171726 0,-0.228677 -0.11906,-0.415774 -0.26458,-0.415774 -0.14552,0 
					-0.26458,-0.178594 -0.26458,-0.396875 0,-0.218281 -0.1786,-0.396875 
					-0.39688,-0.396875 -0.22048,0 -0.39687,-0.235185 -0.39687,-0.529167 0,-0.291041 
					-0.11906,-0.529166 -0.26459,-0.529166 -0.14552,0 -0.26458,-0.271373 
					-0.26458,-0.603049 0,-0.331677 -0.23812,-0.774679 -0.52917,-0.984451 
					-0.29104,-0.209772 -0.52916,-0.583504 -0.52916,-0.830519 0,-0.247015 
					-0.0761,-0.647433 -0.16911,-0.889818 -0.13491,-0.351549 -0.21437,-0.369308 
					-0.39283,-0.08779 -0.16045,0.253124 -0.22485,0.184584 -0.22776,-0.242398 
					-0.002,-0.327422 -0.12311,-0.595312 -0.26864,-0.595312 -0.14552,0 
					-0.26458,-0.416719 -0.26458,-0.926042 0,-0.509323 -0.11906,-0.926042 
					-0.26458,-0.926042 -0.14552,0 -0.26459,-0.416718 -0.26459,-0.926041 0,-0.509323 
					-0.11906,-0.926042 -0.26458,-0.926042 -0.14552,0 -0.26458,-0.416719 
					-0.26458,-0.926042 0,-0.646758 -0.11969,-0.926041 -0.39688,-0.926041 -0.26458,0 
					-0.39687,-0.264583 -0.39687,-0.79375 0,-0.436563 -0.11906,-0.79375 
					-0.26459,-0.79375 -0.14552,0 -0.26458,-0.535781 -0.26458,-1.190625 0,-0.654844 
					-0.1089,-1.190625 -0.24201,-1.190625 -0.23665,0 -0.32949,-0.72286 
					-0.74476,-5.799097 -0.13114,-1.602952 -0.32183,-2.53102 -0.53458,-2.601736 
					-0.22529,-0.07488 -0.33073,-0.740537 -0.33073,-2.087872 0,-1.14895 
					0.1029,-1.914339 0.24553,-1.826193 0.34523,0.213365 0.51961,-0.516872 
					0.58162,-2.435622 0.0294,-0.909505 0.15296,-1.653646 0.2746,-1.653646 0.12164,0 
					0.22116,-0.357187 0.22116,-0.79375 0,-0.436562 0.11907,-0.79375 0.26459,-0.79375 
					0.14552,0 0.26458,-0.246628 0.26458,-0.548066 0,-0.585769 0.57252,-1.304017 
					1.03944,-1.304017 0.15591,0 0.28348,-0.178594 0.28348,-0.396875 0,-0.218281 
					0.14883,-0.398698 0.33073,-0.400926 0.2247,-0.0028 0.23887,-0.06637 
					0.0442,-0.198437 -0.18265,-0.123915 0.0318,-0.507487 0.59157,-1.058042 
					0.48295,-0.47501 0.93931,-0.861963 1.01413,-0.859896 0.75866,0.02096 
					0.92978,-0.05139 0.92978,-0.393115 0,-0.220486 0.23519,-0.396875 
					0.52917,-0.396875 0.29104,0 0.52917,-0.119063 0.52917,-0.264584 0,-0.145521 
					0.54361,-0.264583 1.20802,-0.264583 0.66441,0 1.46239,-0.136136 
					1.77329,-0.302525 0.42411,-0.226975 0.59045,-0.226975 0.66611,0 0.0591,0.177332 
					0.69392,0.302525 1.534,0.302525 0.78824,0 1.43316,0.119062 1.43316,0.264583 
					0,0.145521 0.29766,0.264584 0.66146,0.264584 0.41157,0 0.66146,0.149931 
					0.66146,0.396875 0,0.220485 0.23518,0.396874 0.52916,0.396874 0.29105,0 
					0.52917,0.119063 0.52917,0.264584 0,0.145521 0.12447,0.264583 0.27661,0.264583 
					0.35916,0 1.57547,1.216319 1.57547,1.575475 0,0.152135 0.12448,0.276608 
					0.27661,0.276608 0.48214,0 1.57548,1.276954 1.57548,1.840058 0,0.297657 
					0.11906,0.541192 0.26458,0.541192 0.14552,0 0.26458,0.238125 0.26458,0.529167 
					0,0.293981 0.17639,0.529167 0.39688,0.529167 0.24694,0 0.39687,0.249883 
					0.39687,0.661458 0,0.363802 0.11907,0.661458 0.26459,0.661458 0.14552,0 
					0.26458,0.416719 0.26458,0.926042 0,0.509323 0.11906,0.926041 0.26458,0.926041 
					0.14553,0 0.26459,0.535782 0.26459,1.190625 0,0.654844 0.11906,1.190625 
					0.26458,1.190625 0.14552,0 0.26458,0.535781 0.26458,1.190625 0,0.881944 
					0.1029,1.190625 0.39688,1.190625 0.29398,0 0.39687,0.308682 0.39687,1.190625 
					0,0.654844 0.11907,1.190625 0.26459,1.190625 0.14552,0 0.26458,0.416719 
					0.26458,0.926042 0,0.509323 0.11906,0.926041 0.26458,0.926041 0.14553,0 
					0.26459,0.187098 0.26459,0.415774 0,0.475189 0.61789,1.171726 1.03943,1.171726 
					0.15592,0 0.28348,0.243536 0.28348,0.541192 0,0.563105 1.09334,1.840058 
					1.57548,1.840058 0.15213,0 0.27661,0.178594 0.27661,0.396875 0,0.220485 
					0.23518,0.396875 0.52916,0.396875 0.29105,0 0.52917,0.119063 0.52917,0.264583 
					0,0.145521 0.53578,0.264584 1.19063,0.264584 0.65484,0 1.19062,-0.119063 
					1.19062,-0.264584 0,-0.14552 0.1786,-0.264583 0.39688,-0.264583 0.21828,0 
					0.39687,-0.178594 0.39687,-0.396875 0,-0.218281 0.15948,-0.396875 
					0.35441,-0.396875 0.91782,0 1.09755,-1.30025 1.26172,-9.128124 0.0885,-4.220104 
					0.24379,-8.089635 0.34507,-8.598958 0.10129,-0.509323 0.24072,-1.198801 
					0.30986,-1.532176 0.0691,-0.333372 0.24128,-0.534704 0.38253,-0.447405 
					0.14126,0.0873 0.25683,-0.09145 0.25683,-0.397222 0,-0.53572 0.2776,-0.997249 
					1.00493,-1.670764 0.1889,-0.174932 0.62998,-0.585949 0.98016,-0.913371 
					0.35018,-0.327422 0.8207,-0.595312 1.04559,-0.595312 0.2249,0 0.4089,-0.119063 
					0.4089,-0.264583 0,-0.145521 0.40184,-0.264584 0.89297,-0.264584 1.30603,0 
					2.55971,-0.293931 2.39441,-0.561379 -0.079,-0.127805 0.20787,-0.232371 
					0.63745,-0.232371 0.4502,0 0.84557,0.168119 0.93335,0.396875 0.0997,0.259781 
					0.51211,0.396875 1.19393,0.396875 1.17143,0 3.20831,0.964954 3.20831,1.51991 
					0,0.199871 0.11227,0.294018 0.24948,0.209214 0.13722,-0.0848 0.66903,0.265358 
					1.18181,0.778137 0.51277,0.512778 1.02712,0.932322 1.14298,0.932322 0.24089,0 
					0.44361,0.342927 1.46963,2.485986 0.40513,0.846211 0.9013,1.703263 
					1.10259,1.904552 0.40093,0.400929 1.05987,3.075334 1.34656,5.465191 
					0.10038,0.836745 0.28785,1.521354 0.4166,1.521354 0.12875,0 0.2341,2.024063 
					0.2341,4.497917 0,2.473854 -0.10197,4.497916 -0.22659,4.497916 -0.12462,0 
					-0.30412,0.834181 -0.39888,1.853734 -0.19322,2.07904 -1.20154,6.938552 
					-1.48193,7.142099 -0.10023,0.07276 -0.39334,0.787135 -0.65135,1.5875 
					-0.25801,0.800364 -0.69534,1.924055 -0.97185,2.497092 -0.2765,0.573037 
					-0.51214,1.287412 -0.52362,1.5875 -0.0143,0.373388 -0.0687,0.42426 
					-0.17225,0.161155 -0.13899,-0.353031 -0.85335,0.819161 -1.96765,3.228732 
					-0.18506,0.400182 -0.48897,0.727604 -0.67535,0.727604 -0.18637,0 
					-0.33886,0.100544 -0.33886,0.22343 0,0.122886 -0.29286,0.564248 -0.6508,0.980805 
					-0.35793,0.416555 -0.60322,0.80495 -0.54507,0.863095 0.15283,0.152828 
					-0.4934,0.805931 -0.6839,0.691178 -0.088,-0.053 -0.43617,0.19762 
					-0.77373,0.556938 -0.33756,0.359317 -0.72576,0.653304 -0.86267,0.653304 
					-0.13691,0 -0.63846,0.425275 -1.11456,0.945059 -0.47611,0.519782 
					-1.05317,0.972582 -1.28237,1.006221 -0.22921,0.03364 -0.6436,0.322739 
					-0.92088,0.64244 -0.27727,0.319704 -0.69853,0.581279 -0.93612,0.581279 
					-0.23759,0 -0.43199,0.119063 -0.43199,0.264584 0,0.145521 -0.29765,0.264583 
					-0.66146,0.264583 -0.3638,0 -0.66145,0.105812 -0.66145,0.235138 0,0.129326 
					-0.42003,0.313936 -0.9334,0.410244 -0.51337,0.09631 -0.87772,0.265182 
					-0.80968,0.375277 0.1317,0.213093 -1.67752,0.620993 -4.79018,1.079974 
					-2.16958,0.319918 -1.87988,0.32957 -4.84383,-0.161383 z M 191.83609,52.303095 c 
					-0.0899,-0.145521 -0.37089,-0.264583 -0.62433,-0.264583 -0.75506,0 
					-1.88464,-0.632119 -1.8912,-1.058334 -0.005,-0.291742 -0.0608,-0.310821 
					-0.21248,-0.07203 -0.15167,0.238747 -0.30633,0.186151 -0.58353,-0.198437 
					-0.20744,-0.287806 -0.50318,-0.523282 -0.65721,-0.523282 -0.15403,0 
					-0.45169,-0.238125 -0.66146,-0.529167 -0.20978,-0.291042 -0.53372,-0.529167 
					-0.71987,-0.529167 -0.18616,0 -0.33847,-0.128397 -0.33847,-0.285326 0,-0.15693 
					-0.50601,-0.771507 -1.12448,-1.365727 -0.61846,-0.594219 -1.12466,-1.150432 
					-1.12488,-1.236027 -2.3e-4,-0.08559 -0.26812,-0.531196 -0.59532,-0.990222 
					-0.32719,-0.459025 -0.5949,-0.952545 -0.5949,-1.096708 0,-0.144164 
					-0.2956,-0.542502 -0.65689,-0.885198 -0.36128,-0.342696 -0.65894,-0.748175 
					-0.66145,-0.901065 -0.007,-0.442304 -0.64625,-1.702059 -0.86447,-1.704329 
					-0.10914,-0.0011 -0.19844,-0.29972 -0.19844,-0.663522 0,-0.363802 
					-0.11906,-0.661459 -0.26458,-0.661459 -0.14552,0 -0.26459,-0.238125 
					-0.26459,-0.529166 0,-0.293981 -0.17639,-0.529167 -0.39687,-0.529167 -0.24439,0 
					-0.39688,-0.24815 -0.39688,-0.645842 0,-0.355214 -0.11906,-0.719429 
					-0.26458,-0.809366 -0.14552,-0.08994 -0.26458,-0.513683 -0.26458,-0.941657 
					0,-0.427975 -0.11907,-0.778135 -0.26459,-0.778135 -0.14552,0 -0.26458,-0.284919 
					-0.26458,-0.63315 0,-0.348234 -0.17859,-0.701683 -0.39687,-0.785448 
					-0.24911,-0.09559 -0.39688,-0.500186 -0.39688,-1.086641 0,-0.513889 
					-0.11906,-0.934344 -0.26458,-0.934344 -0.14552,0 -0.26459,-0.409691 
					-0.26459,-0.910426 0,-0.500734 -0.11906,-0.984012 -0.26458,-1.073949 
					-0.14552,-0.08994 -0.26458,-0.625718 -0.26458,-1.190625 0,-0.564906 
					-0.11907,-1.100688 -0.26459,-1.190625 -0.14552,-0.08994 -0.26458,-0.760311 
					-0.26458,-1.489722 0,-0.826067 -0.14967,-1.475869 -0.39687,-1.723075 
					-0.30169,-0.301686 -0.39688,-1.054134 -0.39688,-3.137202 0,-2.083069 
					0.0952,-2.835517 0.39688,-3.137203 0.21828,-0.218281 0.39687,-0.762567 
					0.39687,-1.209523 0,-0.446957 0.11906,-0.812649 0.26458,-0.812649 0.14552,0 
					0.26459,-0.297656 0.26459,-0.661458 0,-0.363802 0.0934,-0.661459 
					0.20753,-0.661459 0.11415,0 0.41854,-0.357187 0.67642,-0.79375 0.25789,-0.436562 
					0.64777,-0.79375 0.86641,-0.79375 0.21864,0 0.32925,-0.11049 0.24579,-0.245534 
					-0.0835,-0.135044 0.0881,-0.321661 0.38126,-0.414704 0.29315,-0.09304 
					0.533,-0.28021 0.533,-0.415925 0,-0.135714 0.35016,-0.246753 0.77814,-0.246753 
					0.42797,0 0.85172,-0.119063 0.94166,-0.264584 0.21317,-0.344918 
					3.75557,-0.344918 3.96875,0 0.0899,0.145521 0.44712,0.264584 0.79375,0.264584 
					0.34662,0 0.70381,0.119062 0.79375,0.264583 0.0899,0.145521 0.39462,0.264583 
					0.67707,0.264583 0.28245,0 0.51355,0.164149 0.51355,0.364776 0,0.200627 
					0.38695,0.524163 0.8599,0.718969 0.93478,0.385041 2.05052,1.372432 
					2.05052,1.814645 0,0.152136 0.14883,0.278084 0.33073,0.279886 0.34288,0.0034 
					1.52135,1.637589 1.52135,2.10968 0,0.147562 0.11906,0.268294 0.26458,0.268294 
					0.14552,0 0.26459,0.182331 0.26459,0.405179 0,0.222848 0.17859,0.473712 
					0.39687,0.557474 0.21828,0.08376 0.39688,0.308229 0.39688,0.498814 0,0.190586 
					0.0941,0.386207 0.20908,0.434714 0.115,0.04851 0.53018,0.772803 0.92262,1.609548 
					0.39244,0.836745 0.80507,1.521354 0.91695,1.521354 0.11188,0 0.36354,0.386953 
					0.55924,0.859896 0.19571,0.472943 0.51325,1.217083 0.70565,1.653646 
					0.1924,0.436562 0.43385,1.130787 0.53656,1.542722 0.10271,0.411935 
					0.35001,0.811627 0.54957,0.888203 0.19956,0.07658 0.36283,0.417121 
					0.36283,0.756767 0,0.339645 0.10547,0.68272 0.23437,0.762389 0.12891,0.07967 
					0.44333,0.916794 0.69872,1.860282 0.25539,0.943488 0.56664,1.652212 
					0.69167,1.57494 0.12503,-0.07727 0.22732,0.158589 0.22732,0.524132 0,0.365543 
					0.10863,0.938117 0.24139,1.272387 0.13277,0.334269 0.5746,2.057783 
					0.98185,3.830034 0.40724,1.77225 0.82068,3.172684 0.91875,3.112076 
					0.28822,-0.178131 0.54425,3.054842 0.37753,4.767239 -0.0844,0.867124 
					-0.25575,1.64103 -0.38073,1.719791 -0.12498,0.07876 -0.42003,0.606203 
					-0.65568,1.172094 -0.23564,0.565888 -0.66575,1.234699 -0.95578,1.486241 
					-0.29003,0.251545 -0.52733,0.592217 -0.52733,0.75705 0,0.16483 -0.2282,0.299693 
					-0.50712,0.299693 -0.27891,0 -0.54857,0.124352 -0.59923,0.276339 
					-0.0507,0.151987 -0.35824,0.296386 -0.68351,0.320889 -0.32526,0.0245 
					-0.59139,0.138274 -0.59139,0.252825 0,0.309637 -5.76063,0.255154 
					-5.95312,-0.0563 z M 141.96213,31.004138 c -1.09141,-0.180597 -1.86532,-0.345599 
					-1.7198,-0.366668 0.52055,-0.07537 -0.54328,-0.551352 -1.24115,-0.555323 
					-0.39159,-0.0021 -0.771,-0.09954 -0.84313,-0.216239 -0.0721,-0.116705 
					-0.74154,-0.378407 -1.48759,-0.581562 -0.74605,-0.203155 -1.41546,-0.464857 
					-1.48759,-0.581562 -0.0721,-0.116702 -0.29657,-0.212188 -0.49877,-0.212188 
					-0.20219,0 -0.6888,-0.297656 -1.08135,-0.661458 -0.39255,-0.363802 
					-0.93447,-0.661458 -1.20426,-0.661458 -0.26979,0 -0.49053,-0.119063 
					-0.49053,-0.264584 0,-0.14552 -0.12475,-0.264583 -0.27722,-0.264583 -0.15247,0 
					-0.55307,-0.297656 -0.89023,-0.661458 -0.33716,-0.363802 -0.80773,-0.661459 
					-1.0457,-0.661459 -0.23798,0 -0.43268,-0.09894 -0.43268,-0.219874 0,-0.12093 
					-0.29766,-0.379174 -0.66146,-0.573876 -0.3638,-0.194701 -0.66146,-0.512476 
					-0.66146,-0.706167 0,-0.193691 -0.12303,-0.352166 -0.2734,-0.352166 -0.35572,0 
					-2.10785,-1.752131 -2.10785,-2.107847 0,-0.150371 -0.097,-0.273403 
					-0.21558,-0.273403 -0.44344,0 -1.10734,-1.916807 -1.10734,-3.197115 0,-1.733442 
					1.14578,-3.946634 2.04318,-3.946634 0.18594,0 0.33807,-0.119063 
					0.33807,-0.264584 0,-0.14552 0.29063,-0.264583 0.64585,-0.264583 0.35521,0 
					0.71942,-0.119062 0.80936,-0.264583 0.21329,-0.345107 4.54921,-0.345107 4.7625,0 
					0.0899,0.145521 0.5494,0.264583 1.02103,0.264583 0.47163,0 0.97256,0.115051 
					1.11318,0.255669 0.14062,0.140618 0.64068,0.285006 1.11125,0.320862 
					0.47057,0.03586 0.85558,0.15893 0.85558,0.273498 0,0.114567 0.24186,0.208304 
					0.53747,0.208304 0.29561,0 0.60601,0.178594 0.68977,0.396875 0.0838,0.218282 
					0.37406,0.396875 0.64511,0.396875 0.27104,0 0.91677,0.238125 1.43494,0.529167 
					0.51818,0.291042 1.09973,0.529167 1.29233,0.529167 0.19261,0 0.67137,0.297656 
					1.06392,0.661458 0.39255,0.363802 0.84875,0.661458 1.01377,0.661458 0.16503,0 
					0.56272,0.246768 0.88376,0.548373 0.32105,0.301606 0.80497,0.585312 
					1.07539,0.630459 0.27042,0.04515 0.46816,0.196306 0.43944,0.335909 
					-0.0287,0.139603 0.65589,0.652118 1.52136,1.138922 0.86546,0.486804 
					1.57358,0.995553 1.57358,1.130554 0,0.135001 0.39444,0.618701 0.87653,1.07489 
					1.01768,0.962998 1.65653,2.7003 1.39146,3.783923 -0.10087,0.412324 
					-0.24922,1.0771 -0.32968,1.477282 -0.0805,0.400182 -0.2636,0.727604 
					-0.40698,0.727604 -0.14338,0 -0.5839,0.345996 -0.97893,0.768879 
					-0.39503,0.422881 -0.9833,0.809834 -1.30726,0.859896 -0.32396,0.05006 
					-0.57836,0.209976 -0.56534,0.355373 0.0227,0.252962 -2.61561,0.927777 
					-3.4423,0.880467 -0.21829,-0.01249 -1.28985,-0.170477 -2.38125,-0.351073 z m 
					83.54219,-2.430235 c -0.40031,-0.06517 -0.72761,-0.289078 -0.72761,-0.497755 
					0,-0.208616 -0.1871,-0.379302 -0.41577,-0.379302 -0.53474,0 -1.17173,-0.636992 
					-1.17173,-1.171726 0,-0.228675 -0.17859,-0.415774 -0.39687,-0.415774 -0.21829,0 
					-0.39688,-0.178593 -0.39688,-0.396875 0,-0.218281 -0.11906,-0.396875 
					-0.26458,-0.396875 -0.14552,0 -0.26459,-0.357187 -0.26459,-0.79375 0,-0.436562 
					-0.11906,-0.793749 -0.26458,-0.793749 -0.14552,0 -0.26458,-0.416719 
					-0.26458,-0.926042 0,-0.509323 -0.11906,-0.926042 -0.26459,-0.926042 -0.15119,0 
					-0.26458,-0.79375 -0.26458,-1.852083 0,-1.469907 -0.0819,-1.852083 
					-0.39687,-1.852083 -0.32338,0 -0.39688,-0.440973 -0.39688,-2.38125 0,-1.940278 
					0.0735,-2.38125 0.39688,-2.38125 0.30467,0 0.39687,-0.338079 0.39687,-1.455208 
					0,-0.800365 0.11906,-1.455209 0.26458,-1.455209 0.14553,0 0.26459,-0.271372 
					0.26459,-0.6030487 0,-0.3316768 0.23812,-0.7746798 0.52916,-0.9844507 
					0.29105,-0.2097709 0.52917,-0.5337114 0.52917,-0.7198675 0,-0.1861563 
					0.17859,-0.3384658 0.39688,-0.3384658 0.21828,0 0.39687,-0.1190625 
					0.39687,-0.2645833 0,-0.3679545 2.29046,-0.331664 3.35465,0.053152 
					0.48328,0.1747544 0.87868,0.4461264 0.87868,0.6030491 0,0.1569228 
					0.23813,0.456945 0.52917,0.6667159 0.29104,0.2097709 0.52917,0.6527739 
					0.52917,0.9844507 0,0.3316763 0.11906,0.6030483 0.26458,0.6030483 0.14552,0 
					0.26458,0.238125 0.26458,0.529167 0,0.293982 0.17639,0.529167 0.39688,0.529167 
					0.29398,0 0.39687,0.30868 0.39687,1.190625 0,0.654843 0.11907,1.190625 
					0.26459,1.190625 0.14552,0 0.26458,0.654843 0.26458,1.455208 0,0.800364 
					0.11906,1.455208 0.26458,1.455208 0.16537,0 0.26459,1.5875 0.26459,4.233333 
					0,2.645834 -0.0992,4.233333 -0.26459,4.233333 -0.14552,0 -0.26458,0.365692 
					-0.26458,0.812649 0,0.810679 -0.50224,1.568601 -1.03943,1.568601 -0.15592,0 
					-0.28349,0.178594 -0.28349,0.396875 0,0.218282 -0.14882,0.402926 
					-0.33072,0.410321 -0.18191,0.0074 -0.80698,0.05279 -1.38907,0.100881 
					-0.58208,0.04809 -1.38575,0.03413 -1.78593,-0.03102 z m 18.05781,-0.827812 c 
					-0.97194,-0.266748 -2.38125,-1.174144 -2.38125,-1.533192 0,-0.171336 
					-0.1786,-0.380054 -0.39688,-0.463816 -0.21828,-0.08376 -0.39687,-0.334626 
					-0.39687,-0.557474 0,-0.222849 -0.11906,-0.405179 -0.26459,-0.405179 -0.14552,0 
					-0.26458,-0.297656 -0.26458,-0.661459 0,-0.363802 -0.10604,-0.661458 
					-0.23563,-0.661458 -0.26306,0 -0.49293,-1.155465 -0.72952,-3.66693 
					-0.0912,-0.967859 -0.24587,-1.541205 -0.36417,-1.349783 -0.30311,0.49043 
					-0.61155,-3.430039 -0.39419,-5.010393 0.10212,-0.742487 0.29356,-1.349977 
					0.42542,-1.349977 0.13187,0 0.23976,-0.535781 0.23976,-1.190625 0,-0.654843 
					0.11906,-1.1906245 0.26458,-1.1906245 0.14552,0 0.26458,-0.238125 
					0.26458,-0.5291666 0,-0.2910417 0.10616,-0.5291667 0.2359,-0.5291667 0.12975,0 
					0.31061,-0.2976562 0.40192,-0.6614583 0.10719,-0.4270782 0.35173,-0.6614583 
					0.69014,-0.6614583 0.28827,0 0.52413,-0.1190624 0.52413,-0.2645833 0,-0.3453074 
					2.82929,-0.3453074 3.04271,0 0.0899,0.1455209 0.34359,0.2645833 
					0.56368,0.2645833 0.46224,0 1.15611,0.6238436 1.15611,1.0394344 0,0.1559153 
					0.11906,0.2834822 0.26458,0.2834822 0.14552,0 0.26459,0.238125 0.26459,0.5291667 
					0,0.2910416 0.11906,0.5291666 0.26458,0.5291666 0.14552,0 0.26458,0.2891518 
					0.26458,0.6425595 0,0.353408 0.1786,0.821153 0.39688,1.039435 0.21828,0.218281 
					0.39687,0.762566 0.39687,1.209523 0,0.446957 0.11906,0.812649 0.26459,0.812649 
					0.14925,0 0.26458,0.749653 0.26458,1.719792 0,0.970139 0.11533,1.719791 
					0.26458,1.719791 0.33489,0 0.35962,7.000335 0.0269,7.624165 -0.13071,0.245095 
					-0.27954,0.802814 -0.33073,1.239376 -0.0963,0.82091 -0.52264,1.455209 
					-0.97818,1.455209 -0.14379,0 -0.30184,0.121232 -0.35123,0.269406 
					-0.11199,0.335968 -2.50257,0.553622 -3.39388,0.309005 z" />
			</g>
		</svg>`;
  // *INDENT-ON*
}
export function OffscreenBandEnd2Blobs(rotation: number = 0): string {
  const title = 'Band End Artwork';
  const viewWidth = 297.92083;
  const viewHeight = 270.93334;
  // *INDENT-OFF*
  return `<svg
			width="1126"
			height="1024"
			viewBox="0 0 ${viewWidth} ${viewHeight}"
			xmlns="http://www.w3.org/2000/svg"
							>
			<title>${title}</title>
			<g transform="rotate(${rotation} ${viewWidth * 0.5} ${viewHeight * 0.5})">
				<path style="fill:#676FF4;stroke-width:0.264583" 
					d="M 9.0172157,270.18274 C 
					5.9583158,267.18501 2.899638,262.88958 1.529077,259.66685 l -1.06895642,-2.51354 
					-0.0733652,-27.0906 -0.0733652,-27.0906 1.22182032,0.16747 c 2.2989341,0.3151 
					3.6695518,1.13877 7.8015715,4.6883 4.03014,3.46201 4.386946,3.8721 
					5.249332,6.03317 0.452631,1.13426 0.822965,2.26052 0.822965,2.5028 0,0.24227 
					0.119063,0.4405 0.264584,0.4405 0.14552,0 0.264583,0.23812 0.264583,0.52917 
					0,0.29104 0.119062,0.52916 0.264583,0.52916 0.145521,0 0.264584,0.29766 
					0.264584,0.66146 0,0.41158 0.14993,0.66146 0.396875,0.66146 0.277182,0 
					0.396875,0.27928 0.396875,0.92604 0,0.50932 0.119062,0.92604 0.264583,0.92604 
					0.145521,0 0.264583,0.29766 0.264583,0.66146 0,0.3638 0.119063,0.66146 
					0.264584,0.66146 0.14552,0 0.264583,0.35719 0.264583,0.79375 0,0.43656 
					0.119062,0.79375 0.264583,0.79375 0.145521,0 0.264584,0.41672 0.264584,0.92604 
					0,0.64676 0.119692,0.92604 0.396875,0.92604 0.246944,0 0.396875,0.24989 
					0.396875,0.66146 0,0.3638 0.119062,0.66146 0.264583,0.66146 0.145521,0 
					0.264583,0.24663 0.264583,0.54807 0,0.58576 0.572518,1.30401 1.039435,1.30401 
					0.155915,0 0.283482,0.24354 0.283482,0.54119 0,0.69851 1.141553,1.84006 
					1.840057,1.84006 0.297656,0 0.541193,0.12757 0.541193,0.28349 0,0.59241 
					0.796513,1.03943 1.852083,1.03943 1.01405,0 1.852084,-0.4439 1.852084,-0.98103 
					0,-0.12379 0.238125,-0.3967 0.529167,-0.60647 0.344048,-0.24798 
					0.529166,-0.68492 0.529166,-1.24904 0,-0.59483 0.124783,-0.86763 
					0.396875,-0.86763 0.319704,0 0.396875,-0.41157 0.396875,-2.11666 0,-1.23473 
					0.110244,-2.11667 0.264584,-2.11667 0.14552,0 0.264583,-0.53578 
					0.264583,-1.19063 0,-0.65484 0.119062,-1.19062 0.264583,-1.19062 0.145521,0 
					0.264584,-0.41672 0.264584,-0.92604 0,-0.64676 0.119692,-0.92604 
					0.396875,-0.92604 0.236693,0 0.396875,-0.2434 0.396875,-0.60305 0,-0.33168 
					0.238125,-0.77468 0.529166,-0.98445 0.291042,-0.20978 0.529167,-0.53372 
					0.529167,-0.71987 0,-0.18616 0.124473,-0.33847 0.276609,-0.33847 0.359156,0 
					1.575474,-1.21632 1.575474,-1.57547 0,-0.15214 0.178594,-0.27661 
					0.396875,-0.27661 0.218282,0 0.396875,-0.11906 0.396875,-0.26458 0,-0.14552 
					0.238125,-0.26459 0.529167,-0.26459 0.291042,0 0.529167,-0.11906 
					0.529167,-0.26458 0,-0.14552 0.297656,-0.26458 0.661458,-0.26458 0.411575,0 
					0.661458,-0.14993 0.661458,-0.39688 0,-0.33514 0.617361,-0.39687 
					3.96875,-0.39687 3.35139,0 3.968751,0.0617 3.968751,0.39687 0,0.27718 
					0.279283,0.39688 0.926041,0.39688 0.509323,0 0.926042,0.11906 0.926042,0.26458 
					0,0.14552 0.297656,0.26458 0.661458,0.26458 0.363802,0 0.661459,0.11907 
					0.661459,0.26459 0,0.14552 0.238125,0.26458 0.529166,0.26458 0.293982,0 
					0.529167,0.17639 0.529167,0.39687 0,0.21829 0.178594,0.39688 0.396875,0.39688 
					0.218281,0 0.396875,0.11906 0.396875,0.26458 0,0.14552 0.187097,0.26459 
					0.415774,0.26459 0.475189,0 1.171726,0.61789 1.171726,1.03943 0,0.15592 
					0.271373,0.28348 0.603049,0.28348 0.331676,0 0.774679,0.23813 0.984451,0.52917 
					0.209772,0.29104 0.652775,0.52917 0.984451,0.52917 0.359654,0 0.603049,0.16018 
					0.603049,0.39687 0,0.24694 0.249883,0.39688 0.661458,0.39688 0.363803,0 
					0.661459,0.11906 0.661459,0.26458 0,0.14552 0.238125,0.26458 0.529167,0.26458 
					0.291041,0 0.529166,0.11907 0.529166,0.26459 0,0.15446 0.885952,0.26458 
					2.128692,0.26458 1.907318,0 2.196232,-0.0675 2.778125,-0.64943 0.557231,-0.55723 
					0.649433,-0.89531 0.649433,-2.38125 0,-0.97816 -0.115144,-1.73182 
					-0.264583,-1.73182 -0.145521,0 -0.264583,-0.24663 -0.264583,-0.54807 0,-0.58576 
					-0.572519,-1.30401 -1.039435,-1.30401 -0.155916,0 -0.283482,-0.29766 
					-0.283482,-0.66146 0,-0.3638 -0.119063,-0.66146 -0.264583,-0.66146 -0.145521,0 
					-0.264584,-0.29766 -0.264584,-0.66146 0,-0.3638 -0.119062,-0.66146 
					-0.264583,-0.66146 -0.145521,0 -0.264583,-0.35718 -0.264583,-0.79375 0,-0.52916 
					-0.132292,-0.79375 -0.396875,-0.79375 -0.33073,0 -0.396875,-0.52916 
					-0.396875,-3.175 0,-2.64583 0.06614,-3.175 0.396875,-3.175 0.277182,0 
					0.396875,-0.27928 0.396875,-0.92604 0,-0.50932 0.119062,-0.92604 
					0.264583,-0.92604 0.145521,0 0.264583,-0.23812 0.264583,-0.52917 0,-0.29104 
					0.119063,-0.52916 0.264584,-0.52916 0.14552,0 0.264583,-0.1786 0.264583,-0.39688 
					0,-0.21828 0.123031,-0.39687 0.273402,-0.39687 0.355716,0 2.107848,-1.75213 
					2.107848,-2.10785 0,-0.15037 0.178594,-0.2734 0.396875,-0.2734 0.218281,0 
					0.396875,-0.11906 0.396875,-0.26459 0,-0.14552 0.238125,-0.26458 
					0.529167,-0.26458 0.291041,0 0.529166,-0.11906 0.529166,-0.26458 0,-0.14552 
					0.297657,-0.26459 0.661459,-0.26459 0.411575,0 0.661458,-0.14993 
					0.661458,-0.39687 0,-0.27718 0.279284,-0.39688 0.926042,-0.39688 0.509323,0 
					0.926041,-0.11906 0.926041,-0.26458 0,-0.15119 0.79375,-0.26458 
					1.852084,-0.26458 1.058333,0 1.852083,-0.11339 1.852083,-0.26459 0,-0.15119 
					0.79375,-0.26458 1.852084,-0.26458 1.058333,0 1.852083,0.11339 1.852083,0.26458 
					0,0.1512 0.79375,0.26459 1.852083,0.26459 1.058334,0 1.852084,0.11339 
					1.852084,0.26458 0,0.14552 0.535781,0.26458 1.190625,0.26458 0.881943,0 
					1.190625,0.1029 1.190625,0.39688 0,0.27718 0.279283,0.39687 0.926041,0.39687 
					0.509323,0 0.926042,0.11907 0.926042,0.26459 0,0.14552 0.416719,0.26458 
					0.926042,0.26458 0.509323,0 0.926041,0.11906 0.926041,0.26458 0,0.14553 
					0.238125,0.26459 0.529167,0.26459 0.293981,0 0.529167,0.17639 0.529167,0.39687 
					0,0.27719 0.279283,0.39688 0.926042,0.39688 0.509322,0 0.926041,0.11906 
					0.926041,0.26458 0,0.14552 0.297656,0.26458 0.661459,0.26458 0.363802,0 
					0.661458,0.11907 0.661458,0.26459 0,0.14552 0.297656,0.26458 0.661458,0.26458 
					0.363802,0 0.661459,0.11906 0.661459,0.26458 0,0.14552 0.238125,0.26459 
					0.529166,0.26459 0.293981,0 0.529167,0.17639 0.529167,0.39687 0,0.24695 
					0.249883,0.39688 0.661458,0.39688 0.363802,0 0.661459,0.11906 0.661459,0.26458 
					0,0.14552 0.297656,0.26458 0.661458,0.26458 0.363802,0 0.661458,0.11907 
					0.661458,0.26459 0,0.14552 0.357188,0.26458 0.79375,0.26458 0.529167,0 
					0.79375,0.13229 0.79375,0.39688 0,0.24694 0.249883,0.39687 0.661459,0.39687 
					0.363802,0 0.661458,0.11906 0.661458,0.26458 0,0.14552 0.297656,0.26459 
					0.661458,0.26459 0.363803,0 0.661459,0.11906 0.661459,0.26458 0,0.14552 
					0.416719,0.26458 0.926042,0.26458 0.64676,0 0.92604,0.1197 0.92604,0.39688 
					0,0.27718 0.27928,0.39687 0.92604,0.39687 0.50933,0 0.92604,0.11907 
					0.92604,0.26459 0,0.14552 0.41672,0.26458 0.92605,0.26458 0.50932,0 
					0.92604,0.11906 0.92604,0.26458 0,0.1563 0.95067,0.26459 2.32284,0.26459 
					2.01932,0 2.37268,-0.0692 2.70424,-0.52917 0.20977,-0.29104 0.53371,-0.52917 
					0.71987,-0.52917 0.18615,0 0.33846,-0.17859 0.33846,-0.39687 0,-0.21828 
					0.11907,-0.39688 0.26459,-0.39688 0.14552,0 0.26458,-0.65484 0.26458,-1.4552 
					0,-0.80037 -0.11906,-1.45521 -0.26458,-1.45521 -0.14552,0 -0.26459,-0.29766 
					-0.26459,-0.66146 0,-0.3638 -0.12447,-0.66146 -0.2766,-0.66146 -0.35916,0 
					-1.57548,-1.21632 -1.57548,-1.57547 0,-0.15214 -0.27137,-0.27661 
					-0.60305,-0.27661 -0.33167,0 -0.77468,-0.23813 -0.98445,-0.52917 
					-0.20977,-0.29104 -0.53371,-0.52917 -0.71987,-0.52917 -0.18615,0 
					-0.33846,-0.13082 -0.33846,-0.29072 0,-0.15989 -0.35719,-0.62438 
					-0.79375,-1.03219 -0.43656,-0.40781 -0.79375,-0.8723 -0.79375,-1.0322 0,-0.15989 
					-0.1786,-0.29072 -0.39688,-0.29072 -0.22048,0 -0.39687,-0.23518 
					-0.39687,-0.52917 0,-0.29104 -0.11906,-0.52916 -0.26459,-0.52916 -0.14552,0 
					-0.26458,-0.1786 -0.26458,-0.39688 0,-0.21828 -0.11906,-0.39687 
					-0.26458,-0.39687 -0.14552,0 -0.26459,-0.23813 -0.26459,-0.52917 0,-0.29398 
					-0.17639,-0.52917 -0.39687,-0.52917 -0.21828,0 -0.39688,-0.17859 
					-0.39688,-0.39687 0,-0.21828 -0.11906,-0.39688 -0.26458,-0.39688 -0.14552,0 
					-0.26458,-0.23812 -0.26458,-0.52916 0,-0.29104 -0.11906,-0.52917 
					-0.26459,-0.52917 -0.14552,0 -0.26458,-0.41672 -0.26458,-0.92604 0,-0.64676 
					-0.11969,-0.92604 -0.39687,-0.92604 -0.27719,0 -0.39688,-0.27929 
					-0.39688,-0.92604 0,-0.50933 -0.11906,-0.92605 -0.26458,-0.92605 -0.146994,0 
					-0.264586,-0.70555 -0.264586,-1.5875 0,-0.88194 -0.117591,-1.5875 
					-0.264583,-1.5875 -0.151191,0 -0.264583,-0.79375 -0.264583,-1.85208 0,-1.05833 
					0.113392,-1.85208 0.264583,-1.85208 0.145521,0 0.264583,-0.65485 
					0.264583,-1.45521 0,-0.80036 0.119063,-1.45521 0.264586,-1.45521 0.14552,0 
					0.26458,-0.41672 0.26458,-0.92604 0,-0.64676 0.11969,-0.92604 0.39688,-0.92604 
					0.24694,0 0.39687,-0.24988 0.39687,-0.66146 0,-0.3638 0.11906,-0.66146 
					0.26458,-0.66146 0.14553,0 0.26459,-0.23812 0.26459,-0.52916 0,-0.29105 
					0.11906,-0.52917 0.26458,-0.52917 0.14552,0 0.26458,-0.17859 0.26458,-0.39688 
					0,-0.21828 0.1786,-0.39687 0.39688,-0.39687 0.22048,0 0.39687,-0.23519 
					0.39687,-0.52917 0,-0.29104 0.11907,-0.52916 0.26459,-0.52916 0.14552,0 
					0.26458,-0.12757 0.26458,-0.28349 0,-0.30835 0.65133,-1.03943 0.92604,-1.03943 
					0.27472,0 0.92604,-0.73108 0.92604,-1.03944 0,-0.15591 0.23813,-0.28348 
					0.52917,-0.28348 0.29104,0 0.52917,-0.11906 0.52917,-0.26458 0,-0.14552 
					0.29765,-0.26458 0.66146,-0.26458 0.3638,0 0.66145,-0.11907 0.66145,-0.26459 
					0,-0.14552 0.29766,-0.26458 0.66146,-0.26458 0.36381,0 0.66146,-0.11906 
					0.66146,-0.26458 0,-0.14553 0.41672,-0.26459 0.92604,-0.26459 0.64676,0 
					0.92604,-0.11969 0.92604,-0.39687 0,-0.31498 0.38218,-0.39688 1.85209,-0.39688 
					1.05833,0 1.85208,-0.11339 1.85208,-0.26458 0,-0.14552 0.41672,-0.26458 
					0.92604,-0.26458 0.50933,0 0.92604,0.11906 0.92604,0.26458 0,0.15434 
					0.88195,0.26458 2.11667,0.26458 1.70509,0 2.11667,0.0772 2.11667,0.39688 
					0,0.27718 0.27928,0.39687 0.92604,0.39687 0.50932,0 0.92604,0.11906 
					0.92604,0.26459 0,0.14552 0.41672,0.26458 0.92604,0.26458 0.50933,0 
					0.92605,0.11906 0.92605,0.26458 0,0.14552 0.23812,0.26459 0.52916,0.26459 
					0.29104,0 0.52917,0.11906 0.52917,0.26458 0,0.14552 0.29765,0.26458 
					0.66146,0.26458 0.3638,0 0.66146,0.12757 0.66146,0.28348 0,0.42856 
					0.69973,1.03944 1.19062,1.03944 0.42775,0 1.19063,0.59335 1.19063,0.92604 
					0,0.33269 0.76287,0.92604 1.19062,0.92604 0.49089,0 1.19063,0.61088 
					1.19063,1.03944 0,0.15591 0.12447,0.28348 0.2766,0.28348 0.35916,0 
					1.57548,1.21632 1.57548,1.57547 0,0.15214 0.12447,0.27661 0.27661,0.27661 
					0.35915,0 1.57547,1.21632 1.57547,1.57548 0,0.15213 0.12448,0.27661 
					0.27661,0.27661 0.48214,0 1.57548,1.27695 1.57548,1.84005 0,0.29766 
					0.12447,0.5412 0.2766,0.5412 0.35916,0 1.57548,1.21631 1.57548,1.57547 0,0.15214 
					0.11906,0.27661 0.26458,0.27661 0.14552,0 0.26459,0.23812 0.26459,0.52917 
					0,0.29398 0.17639,0.52916 0.39687,0.52916 0.21828,0 0.39688,0.1786 
					0.39688,0.39688 0,0.21828 0.11906,0.39687 0.26458,0.39687 0.14552,0 
					0.26458,0.23813 0.26458,0.52917 0,0.29104 0.11906,0.52916 0.26459,0.52916 
					0.14552,0 0.26458,0.1786 0.26458,0.39688 0,0.21828 0.17859,0.39687 
					0.39687,0.39687 0.22049,0 0.39688,0.23519 0.39688,0.52917 0,0.29104 
					0.11906,0.52917 0.26458,0.52917 0.14552,0 0.26459,0.17859 0.26459,0.39687 
					0,0.21828 0.11906,0.39688 0.26458,0.39688 0.14552,0 0.26458,0.23812 
					0.26458,0.52916 0,0.29399 0.17639,0.52917 0.39688,0.52917 0.21828,0 
					0.39687,0.1786 0.39687,0.39688 0,0.21828 0.11906,0.39687 0.26459,0.39687 
					0.14552,0 0.26458,0.23813 0.26458,0.52917 0,0.29104 0.11906,0.52916 
					0.26458,0.52916 0.14552,0 0.26459,0.29766 0.26459,0.66146 0,0.41158 
					0.14993,0.66146 0.39687,0.66146 0.22049,0 0.39688,0.23519 0.39688,0.52917 
					0,0.29104 0.11906,0.52916 0.26458,0.52916 0.14552,0 0.26458,0.29766 
					0.26458,0.66146 0,0.3638 0.11906,0.66146 0.26459,0.66146 0.14552,0 
					0.26458,0.29766 0.26458,0.66146 0,0.41157 0.14993,0.66146 0.39687,0.66146 
					0.22049,0 0.39688,0.23518 0.39688,0.52916 0,0.29105 0.11906,0.52917 
					0.26458,0.52917 0.14552,0 0.26459,0.29766 0.26459,0.66146 0,0.3638 
					0.11906,0.66146 0.26458,0.66146 0.14552,0 0.26458,0.41672 0.26458,0.92604 
					0,0.64676 0.11969,0.92604 0.39688,0.92604 0.24694,0 0.39687,0.24988 
					0.39687,0.66146 0,0.3638 0.11906,0.66146 0.26459,0.66146 0.14552,0 
					0.26458,0.35718 0.26458,0.79375 0,0.43656 0.11906,0.79375 0.26458,0.79375 
					0.14552,0 0.26459,0.41672 0.26459,0.92604 0,0.50932 0.11906,0.92604 
					0.26458,0.92604 0.14552,0 0.26458,0.59531 0.26458,1.32292 0,0.99953 
					0.097,1.32291 0.39688,1.32291 0.27718,0 0.39687,0.27929 0.39687,0.92605 
					0,0.50932 0.11906,0.92604 0.26459,0.92604 0.14552,0 0.26458,0.65484 
					0.26458,1.45521 0,0.80036 0.11906,1.4552 0.26458,1.4552 0.14699,0 
					0.26459,0.70556 0.26459,1.5875 0,1.23473 0.0882,1.5875 0.39687,1.5875 0.32758,0 
					0.39688,0.48507 0.39688,2.77813 0,1.67569 0.10499,2.77812 0.26458,2.77812 
					0.16463,0 0.26458,1.49931 0.26458,3.96875 0,2.46945 -0.0999,3.96875 
					-0.26458,3.96875 -0.15959,0 -0.26458,1.10243 -0.26458,2.77813 0,2.29305 
					-0.0693,2.77812 -0.39688,2.77812 -0.29398,0 -0.39687,0.30868 -0.39687,1.19063 
					0,0.65484 -0.11907,1.19062 -0.26459,1.19062 -0.14552,0 -0.26458,0.53578 
					-0.26458,1.19063 0,0.65484 -0.11906,1.19062 -0.26458,1.19062 -0.14553,0 
					-0.26459,0.29766 -0.26459,0.66146 0,0.41158 -0.14993,0.66146 -0.39687,0.66146 
					-0.22049,0 -0.39688,0.23518 -0.39688,0.52917 0,0.29104 -0.11906,0.52916 
					-0.26458,0.52916 -0.14552,0 -0.26458,0.27137 -0.26458,0.60305 0,0.33168 
					-0.23813,0.77468 -0.52917,0.98445 -0.29104,0.20977 -0.52917,0.65278 
					-0.52917,0.98445 0,0.33168 -0.12756,0.60305 -0.28348,0.60305 -0.36631,0 
					-1.03943,0.67313 -1.03943,1.03944 0,0.15591 -0.27138,0.28348 -0.60305,0.28348 
					-0.33168,0 -0.77468,0.23812 -0.98445,0.52917 -0.20978,0.29104 -0.65278,0.52916 
					-0.98446,0.52916 -0.33167,0 -0.60304,0.11906 -0.60304,0.26459 0,0.14552 
					-0.23813,0.26458 -0.52917,0.26458 -0.29398,0 -0.52917,0.17639 -0.52917,0.39687 
					0,0.27719 -0.27928,0.39688 -0.92604,0.39688 -0.50932,0 -0.92604,0.11906 
					-0.92604,0.26458 0,0.16686 -1.80799,0.26459 -4.89479,0.26459 -3.08681,0 
					-4.89479,-0.0977 -4.89479,-0.26459 0,-0.14552 -0.41672,-0.26458 
					-0.92605,-0.26458 -0.64675,0 -0.92604,-0.11969 -0.92604,-0.39688 0,-0.27718 
					-0.27928,-0.39687 -0.92604,-0.39687 -0.50932,0 -0.92604,-0.11906 
					-0.92604,-0.26458 0,-0.14553 -0.41672,-0.26459 -0.92604,-0.26459 -0.50933,0 
					-0.92604,-0.11906 -0.92604,-0.26458 0,-0.14552 -0.29766,-0.26458 
					-0.66146,-0.26458 -0.36381,0 -0.66146,-0.11907 -0.66146,-0.26459 0,-0.14552 
					-0.23813,-0.26458 -0.52917,-0.26458 -0.29398,0 -0.52916,-0.17639 
					-0.52916,-0.39688 0,-0.24694 -0.24989,-0.39687 -0.66146,-0.39687 -0.36381,0 
					-0.66146,-0.11906 -0.66146,-0.26458 0,-0.14553 -0.24663,-0.26459 
					-0.54807,-0.26459 -0.58577,0 -1.30402,-0.57252 -1.30402,-1.03943 0,-0.15592 
					-0.29765,-0.28348 -0.66145,-0.28348 -0.36381,0 -0.66146,-0.11907 
					-0.66146,-0.26459 0,-0.14552 -0.23813,-0.26458 -0.52917,-0.26458 -0.29104,0 
					-0.52917,-0.11906 -0.52917,-0.26458 0,-0.14553 -0.17859,-0.26459 
					-0.39687,-0.26459 -0.21828,0 -0.39688,-0.12756 -0.39688,-0.28348 0,-0.42154 
					-0.69653,-1.03943 -1.17172,-1.03943 -0.22868,0 -0.41578,-0.11907 
					-0.41578,-0.26459 0,-0.14552 -0.17859,-0.26458 -0.39687,-0.26458 -0.21828,0 
					-0.39688,-0.12447 -0.39688,-0.27661 0,-0.35916 -1.21631,-1.57547 
					-1.57547,-1.57547 -0.15214,0 -0.27661,-0.12448 -0.27661,-0.27661 0,-0.35916 
					-1.21632,-1.57548 -1.57547,-1.57548 -0.15214,0 -0.27661,-0.12447 
					-0.27661,-0.27661 0,-0.29502 -1.18829,-1.57547 -1.46208,-1.57547 -0.26923,0 
					-0.91917,-0.73498 -0.91917,-1.03943 0,-0.15592 -0.24354,-0.28349 
					-0.54119,-0.28349 -0.56311,0 -1.84006,-1.09334 -1.84006,-1.57547 0,-0.15214 
					-0.12448,-0.27661 -0.27661,-0.27661 -0.29502,0 -1.57548,-1.18829 
					-1.57548,-1.46208 0,-0.21552 -0.7089,-0.91917 -0.92604,-0.91917 -0.27471,0 
					-0.92604,-0.73108 -0.92604,-1.03943 0,-0.15592 -0.24353,-0.28349 
					-0.54119,-0.28349 -0.56311,0 -1.84006,-1.09333 -1.84006,-1.57547 0,-0.15214 
					-0.17859,-0.27661 -0.39687,-0.27661 -0.21829,0 -0.39688,-0.11906 
					-0.39688,-0.26458 0,-0.14552 -0.23812,-0.26459 -0.529166,-0.26459 -0.291041,0 
					-0.529166,-0.11906 -0.529166,-0.26458 0,-0.14552 -0.297656,-0.26458 
					-0.661459,-0.26458 -0.363802,0 -0.661458,-0.11907 -0.661458,-0.26459 0,-0.14552 
					-0.297656,-0.26458 -0.661458,-0.26458 -0.411576,0 -0.661459,-0.14993 
					-0.661459,-0.39688 0,-0.29398 -0.308681,-0.39687 -1.190625,-0.39687 -0.654843,0 
					-1.190625,-0.11906 -1.190625,-0.26458 0,-0.14552 -0.238125,-0.26459 
					-0.529166,-0.26459 -0.291042,0 -0.529167,0.11907 -0.529167,0.26459 0,0.14552 
					-0.416719,0.26458 -0.926042,0.26458 -0.646758,0 -0.926041,0.11969 
					-0.926041,0.39687 0,0.2367 -0.243396,0.39688 -0.603049,0.39688 -0.331677,0 
					-0.774679,0.23812 -0.984451,0.52917 -0.209773,0.29104 -0.533713,0.52916 
					-0.719868,0.52916 -0.186156,0 -0.338466,0.27138 -0.338466,0.60305 0,0.33168 
					-0.238125,0.77468 -0.529166,0.98445 -0.344049,0.24798 -0.529167,0.68493 
					-0.529167,1.24904 0,0.59484 -0.124783,0.86763 -0.396875,0.86763 -0.293981,0 
					-0.396875,0.30868 -0.396875,1.19062 0,0.65485 -0.119063,1.19063 
					-0.264583,1.19063 -0.15434,0 -0.264584,0.88194 -0.264584,2.11667 0,1.23472 
					-0.110244,2.11666 -0.264583,2.11666 -0.155639,0 -0.264584,0.92604 
					-0.264584,2.24896 0,1.82269 -0.07522,2.24896 -0.396875,2.24896 -0.314981,0 
					-0.396875,0.38217 -0.396875,1.85208 0,1.05834 -0.113392,1.85209 
					-0.264583,1.85209 -0.154339,0 -0.264583,0.88194 -0.264583,2.11666 0,1.23472 
					-0.110244,2.11667 -0.264584,2.11667 -0.154339,0 -0.264583,0.88194 
					-0.264583,2.11667 0,1.23472 -0.110244,2.11666 -0.264583,2.11666 -0.151191,0 
					-0.264584,0.79375 -0.264584,1.85208 0,1.46991 -0.08189,1.85209 -0.396875,1.85209 
					-0.277182,0 -0.396875,0.27928 -0.396875,0.92604 0,0.50932 -0.119062,0.92604 
					-0.264583,0.92604 -0.145521,0 -0.264583,0.23813 -0.264583,0.52917 0,0.29104 
					-0.119063,0.52916 -0.264584,0.52916 -0.14552,0 -0.264583,0.1786 
					-0.264583,0.39688 0,0.21828 -0.15231,0.39687 -0.338466,0.39687 -0.186155,0 
					-0.510095,0.23813 -0.719867,0.52917 -0.209773,0.29104 -0.652775,0.52917 
					-0.984451,0.52917 -0.359654,0 -0.603049,0.16018 -0.603049,0.39687 0,0.22049 
					-0.235186,0.39688 -0.529167,0.39688 -0.291042,0 -0.529167,0.11906 
					-0.529167,0.26458 0,0.15782 -1.014235,0.26458 -2.513541,0.26458 -1.499307,0 
					-2.513542,-0.10676 -2.513542,-0.26458 0,-0.14552 -0.416719,-0.26458 
					-0.926042,-0.26458 -0.646758,0 -0.926042,-0.11969 -0.926042,-0.39688 0,-0.22048 
					-0.235185,-0.39687 -0.529166,-0.39687 -0.291042,0 -0.529167,-0.11906 
					-0.529167,-0.26459 0,-0.14552 -0.297656,-0.26458 -0.661458,-0.26458 -0.363802,0 
					-0.661459,-0.11906 -0.661459,-0.26458 0,-0.14552 -0.297656,-0.26459 
					-0.661458,-0.26459 -0.363802,0 -0.661458,-0.12447 -0.661458,-0.2766 0,-0.43339 
					-1.251694,-1.57548 -1.726663,-1.57548 -0.48518,0 -1.183754,-0.61341 
					-1.183754,-1.03943 0,-0.15592 -0.124473,-0.28349 -0.276609,-0.28349 -0.295023,0 
					-1.575474,-1.18829 -1.575474,-1.46207 0,-0.21553 -0.708909,-0.91918 
					-0.926042,-0.91918 -0.220766,0 -0.926042,-0.70527 -0.926042,-0.92604 0,-0.22076 
					-0.705276,-0.92604 -0.926041,-0.92604 -0.220766,0 -0.926042,-0.70527 
					-0.926042,-0.92604 0,-0.22077 -0.705276,-0.92604 -0.926042,-0.92604 -0.274717,0 
					-0.926041,-0.73108 -0.926041,-1.03944 0,-0.15591 -0.119063,-0.28348 
					-0.264584,-0.28348 -0.145521,0 -0.264583,-0.23812 -0.264583,-0.52916 0,-0.29399 
					-0.17639,-0.52917 -0.396875,-0.52917 -0.218281,0 -0.396875,-0.1786 
					-0.396875,-0.39688 0,-0.21828 -0.119063,-0.39687 -0.264584,-0.39687 -0.14552,0 
					-0.264583,-0.1871 -0.264583,-0.41578 0,-0.47518 -0.617895,-1.17172 
					-1.039434,-1.17172 -0.155916,0 -0.283483,-0.12447 -0.283483,-0.27661 0,-0.15214 
					-0.292245,-0.56885 -0.649433,-0.92604 -0.552608,-0.55261 -0.898911,-0.64943 
					-2.32284,-0.64943 -1.369886,0 -1.742586,0.096 -2.05481,0.52916 -0.209772,0.29104 
					-0.533712,0.52917 -0.719868,0.52917 -0.186155,0 -0.338465,0.17859 
					-0.338465,0.39687 0,0.21828 -0.119063,0.39688 -0.264584,0.39688 -0.145521,0 
					-0.264583,0.23812 -0.264583,0.52917 0,0.29104 -0.119063,0.52916 
					-0.264583,0.52916 -0.145521,0 -0.264584,0.41672 -0.264584,0.92604 0,0.64676 
					-0.119692,0.92605 -0.396875,0.92605 -0.277183,0 -0.396875,0.27928 
					-0.396875,0.92604 0,0.50932 -0.119062,0.92604 -0.264583,0.92604 -0.146992,0 
					-0.264583,0.70555 -0.264583,1.5875 0,0.88194 -0.117592,1.5875 -0.264584,1.5875 
					-0.156789,0 -0.264583,0.97014 -0.264583,2.38125 0,1.94027 -0.0735,2.38125 
					-0.396875,2.38125 -0.33514,0 -0.396875,0.61736 -0.396875,3.96875 0,2.46944 
					-0.09995,3.96875 -0.264583,3.96875 -0.15434,0 -0.264584,0.88193 
					-0.264584,2.11666 0,1.23474 -0.110244,2.11667 -0.264583,2.11667 -0.145521,0 
					-0.264583,0.59531 -0.264583,1.32292 v 1.32291 H 26.545862 10.165977 Z M 
					208.8195,249.48039 c 0,-0.3197 -0.41158,-0.39687 -2.11667,-0.39687 -1.23472,0 
					-2.11666,-0.11025 -2.11666,-0.26459 0,-0.14552 -0.53578,-0.26458 
					-1.19063,-0.26458 -0.65484,0 -1.19062,-0.11906 -1.19062,-0.26458 0,-0.14553 
					-0.59532,-0.26459 -1.32292,-0.26459 -0.99954,0 -1.32292,-0.097 -1.32292,-0.39687 
					0,-0.27719 -0.27928,-0.39688 -0.92604,-0.39688 -0.50932,0 -0.92604,-0.11906 
					-0.92604,-0.26458 0,-0.14552 -0.23813,-0.26458 -0.52917,-0.26458 -0.29104,0 
					-0.52916,-0.11907 -0.52916,-0.26459 0,-0.14552 -0.29766,-0.26458 
					-0.66146,-0.26458 -0.41158,0 -0.66146,-0.14993 -0.66146,-0.39688 0,-0.22048 
					-0.23519,-0.39687 -0.52917,-0.39687 -0.29104,0 -0.52916,-0.11906 
					-0.52916,-0.26458 0,-0.14553 -0.29766,-0.26459 -0.66146,-0.26459 -0.3638,0 
					-0.66146,-0.11906 -0.66146,-0.26458 0,-0.14552 -0.24663,-0.26458 
					-0.54807,-0.26458 -0.58576,0 -1.30401,-0.57252 -1.30401,-1.03944 0,-0.15592 
					-0.24354,-0.28348 -0.5412,-0.28348 -0.5631,0 -1.84005,-1.09334 -1.84005,-1.57548 
					0,-0.15213 -0.12448,-0.2766 -0.27661,-0.2766 -0.35916,0 -1.57548,-1.21632 
					-1.57548,-1.57548 0,-0.15213 -0.12447,-0.27661 -0.27661,-0.27661 -0.35915,0 
					-1.57547,-1.21632 -1.57547,-1.57547 0,-0.15214 -0.12757,-0.27661 
					-0.28348,-0.27661 -0.44792,0 -1.03944,-0.70885 -1.03944,-1.24561 0,-0.26931 
					-0.23812,-0.66129 -0.52916,-0.87106 -0.29105,-0.20977 -0.52917,-0.65277 
					-0.52917,-0.98445 0,-0.35965 -0.16018,-0.60305 -0.39688,-0.60305 -0.22048,0 
					-0.39687,-0.23518 -0.39687,-0.52916 0,-0.29105 -0.11906,-0.52917 
					-0.26458,-0.52917 -0.14552,0 -0.26459,-0.41672 -0.26459,-0.92604 0,-0.50933 
					-0.11906,-0.92604 -0.26458,-0.92604 -0.14552,0 -0.26458,-0.41672 
					-0.26458,-0.92605 0,-0.64675 -0.1197,-0.92604 -0.39688,-0.92604 -0.3197,0 
					-0.39687,-0.41157 -0.39687,-2.11666 0,-1.23473 -0.11025,-2.11667 
					-0.26459,-2.11667 -0.14552,0 -0.26458,-0.29766 -0.26458,-0.66146 0,-0.3638 
					0.11906,-0.66146 0.26458,-0.66146 0.15434,0 0.26459,-0.88194 0.26459,-2.11666 
					0,-1.70509 0.0772,-2.11667 0.39687,-2.11667 0.29398,0 0.39688,-0.30868 
					0.39688,-1.19063 0,-0.65484 0.11906,-1.19062 0.26458,-1.19062 0.14552,0 
					0.26458,-0.41672 0.26458,-0.92604 0,-0.50932 0.11907,-0.92604 0.26459,-0.92604 
					0.14552,0 0.26458,-0.29766 0.26458,-0.66146 0,-0.41158 0.14993,-0.66146 
					0.39687,-0.66146 0.24695,0 0.39688,-0.24988 0.39688,-0.66146 0,-0.3638 
					0.11906,-0.66146 0.26458,-0.66146 0.14552,0 0.26459,-0.21184 0.26459,-0.47075 
					0,-0.25892 0.23812,-0.64239 0.52916,-0.85216 0.29104,-0.20978 0.52917,-0.65278 
					0.52917,-0.98445 0,-0.33168 0.12757,-0.60305 0.28348,-0.60305 0.30836,0 
					1.03944,-0.65133 1.03944,-0.92604 0,-0.22077 0.70527,-0.92605 0.92604,-0.92605 
					0.22076,0 0.92604,-0.70527 0.92604,-0.92604 0,-0.22076 0.70528,-0.92604 
					0.92604,-0.92604 0.22077,0 0.92604,-0.70528 0.92604,-0.92604 0,-0.22077 
					0.70528,-0.92604 0.92604,-0.92604 0.27472,0 0.92605,-0.73108 0.92605,-1.03944 
					0,-0.15591 0.27137,-0.28348 0.60305,-0.28348 0.33167,0 0.77467,-0.23812 
					0.98445,-0.52917 0.20977,-0.29104 0.65277,-0.52916 0.98445,-0.52916 0.35965,0 
					0.60305,-0.16018 0.60305,-0.39688 0,-0.22048 0.23518,-0.39687 0.52916,-0.39687 
					0.29104,0 0.52917,-0.11907 0.52917,-0.26459 0,-0.14552 0.29766,-0.26458 
					0.66146,-0.26458 0.3638,0 0.66146,-0.11906 0.66146,-0.26458 0,-0.14552 
					0.23812,-0.26459 0.52916,-0.26459 0.29104,0 0.52917,-0.11906 0.52917,-0.26458 
					0,-0.14552 0.29766,-0.26458 0.66146,-0.26458 0.41157,0 0.66146,-0.14993 
					0.66146,-0.39688 0,-0.24694 0.24988,-0.39687 0.66146,-0.39687 0.3638,0 
					0.66145,-0.11906 0.66145,-0.26459 0,-0.14552 0.41672,-0.26458 0.92605,-0.26458 
					0.50932,0 0.92604,-0.11906 0.92604,-0.26458 0,-0.14552 0.41672,-0.26459 
					0.92604,-0.26459 0.64676,0 0.92604,-0.11969 0.92604,-0.39687 0,-0.27718 
					0.27928,-0.39688 0.92604,-0.39688 0.50932,0 0.92604,-0.11906 0.92604,-0.26458 
					0,-0.14552 0.65485,-0.26458 1.45521,-0.26458 0.80037,0 1.45521,-0.11906 
					1.45521,-0.26459 0,-0.14699 0.70556,-0.26458 1.5875,-0.26458 1.23472,0 
					1.5875,-0.0882 1.5875,-0.39687 0,-0.32338 0.44097,-0.39688 2.38125,-0.39688 
					1.41111,0 2.38125,-0.10779 2.38125,-0.26458 0,-0.15119 0.79375,-0.26459 
					1.85208,-0.26459 1.05834,0 1.85209,0.1134 1.85209,0.26459 0,0.15119 
					0.79375,0.26458 1.85208,0.26458 1.46991,0 1.85208,0.0819 1.85208,0.39688 
					0,0.27718 0.27929,0.39687 0.92605,0.39687 0.50932,0 0.92604,0.11906 
					0.92604,0.26458 0,0.14553 0.29765,0.26459 0.66146,0.26459 0.3638,0 
					0.66145,0.11906 0.66145,0.26458 0,0.14552 0.23813,0.26458 0.52917,0.26458 
					0.29104,0 0.52917,0.12757 0.52917,0.28349 0,0.42855 0.69973,1.03943 
					1.19062,1.03943 0.60045,0 1.19063,0.64639 1.19063,1.30402 0,0.30144 
					0.12756,0.54806 0.28348,0.54806 0.46692,0 1.03943,0.71825 1.03943,1.30402 
					0,0.30144 0.11907,0.54807 0.26459,0.54807 0.14552,0 0.26458,0.65484 
					0.26458,1.45521 0,1.11712 0.0922,1.4552 0.39688,1.4552 0.21828,0 0.39687,0.1786 
					0.39687,0.39688 0,0.21828 -0.17859,0.39687 -0.39687,0.39687 -0.30468,0 
					-0.39688,0.33808 -0.39688,1.45521 0,0.80037 -0.11906,1.45521 -0.26458,1.45521 
					-0.14552,0 -0.26459,0.29766 -0.26459,0.66146 0,0.3638 -0.11906,0.66146 
					-0.26458,0.66146 -0.14552,0 -0.26458,0.29765 -0.26458,0.66146 0,0.3638 
					-0.13083,0.66145 -0.29072,0.66145 -0.1599,0 -0.62439,0.35719 -1.0322,0.79375 
					-0.40781,0.43657 -0.8723,0.79375 -1.03219,0.79375 -0.1599,0 -0.29073,0.12448 
					-0.29073,0.27661 0,0.48214 -1.27695,1.57548 -1.84005,1.57548 -0.30467,0 
					-0.5412,0.17345 -0.5412,0.39687 0,0.22049 -0.23518,0.39688 -0.52916,0.39688 
					-0.29104,0 -0.52917,0.11906 -0.52917,0.26458 0,0.14552 -0.29766,0.26458 
					-0.66146,0.26458 -0.3638,0 -0.66146,0.11907 -0.66146,0.26459 0,0.14552 
					-0.41671,0.26458 -0.92604,0.26458 -0.64676,0 -0.92604,0.11969 -0.92604,0.39687 
					0,0.29399 -0.30868,0.39688 -1.19062,0.39688 -0.65485,0 -1.19063,0.11906 
					-1.19063,0.26458 0,0.14552 -0.59531,0.26459 -1.32292,0.26459 -0.7276,0 
					-1.32291,0.11906 -1.32291,0.26458 0,0.14552 -0.53578,0.26458 -1.19063,0.26458 
					-0.88194,0 -1.19062,0.1029 -1.19062,0.39688 0,0.27718 -0.27929,0.39687 
					-0.92604,0.39687 -0.50933,0 -0.92605,0.11907 -0.92605,0.26459 0,0.14552 
					-0.23812,0.26458 -0.52916,0.26458 -0.29104,0 -0.52917,0.11906 -0.52917,0.26458 
					0,0.14552 -0.17859,0.26459 -0.39687,0.26459 -0.21829,0 -0.39688,0.17859 
					-0.39688,0.39687 0,0.22049 -0.23519,0.39688 -0.52917,0.39688 -0.29104,0 
					-0.52916,0.11906 -0.52916,0.26458 0,0.14552 -0.12757,0.26458 -0.28349,0.26458 
					-0.36631,0 -1.03943,0.67313 -1.03943,1.03944 0,0.15591 -0.11906,0.28348 
					-0.26458,0.28348 -0.14552,0 -0.26459,0.23812 -0.26459,0.52917 0,0.29398 
					-0.17639,0.52916 -0.39687,0.52916 -0.29398,0 -0.39688,0.30868 -0.39688,1.19063 
					0,0.88194 0.1029,1.19062 0.39688,1.19062 0.21828,0 0.39687,0.12448 
					0.39687,0.27661 0,0.48214 1.27696,1.57548 1.84006,1.57548 0.30467,0 
					0.54119,0.17345 0.54119,0.39687 0,0.34396 1.05834,0.39688 7.9375,0.39688 
					5.11528,0 7.9375,0.0941 7.9375,0.26458 0,0.14552 0.53579,0.26458 1.19063,0.26458 
					0.65484,0 1.19062,0.11907 1.19062,0.26459 0,0.14552 0.29766,0.26458 
					0.66146,0.26458 0.3638,0 0.66146,0.12757 0.66146,0.28348 0,0.30836 
					0.65133,1.03944 0.92604,1.03944 0.27472,0 0.92604,0.73107 0.92604,1.03943 
					0,0.15592 0.11907,0.28348 0.26459,0.28348 0.14552,0 0.26458,0.23813 
					0.26458,0.52917 0,0.29398 0.17639,0.52917 0.39688,0.52917 0.30868,0 
					0.39687,0.35277 0.39687,1.5875 0,0.88194 0.11759,1.5875 0.26458,1.5875 0.14552,0 
					0.26459,0.41671 0.26459,0.92604 0,0.50932 -0.11907,0.92604 -0.26459,0.92604 
					-0.14552,0 -0.26458,0.53578 -0.26458,1.19062 0,0.88195 -0.10289,1.19063 
					-0.39687,1.19063 -0.2367,0 -0.39688,0.24339 -0.39688,0.60305 0,0.33167 
					-0.23812,0.77468 -0.52917,0.98445 -0.29104,0.20977 -0.52916,0.65277 
					-0.52916,0.98445 0,0.33168 -0.13083,0.60305 -0.29072,0.60305 -0.1599,0 
					-0.62439,0.35719 -1.0322,0.79375 -0.40781,0.43656 -0.99136,0.79375 
					-1.29678,0.79375 -0.30541,0 -0.5553,0.12756 -0.5553,0.28348 0,0.46692 
					-0.71825,1.03944 -1.30402,1.03944 -0.30144,0 -0.54807,0.11906 -0.54807,0.26458 
					0,0.14552 -0.23812,0.26458 -0.52916,0.26458 -0.29398,0 -0.52917,0.17639 
					-0.52917,0.39688 0,0.27718 -0.27928,0.39687 -0.92604,0.39687 -0.50932,0 
					-0.92604,0.11906 -0.92604,0.26459 0,0.14552 -0.41672,0.26458 -0.92604,0.26458 
					-0.50933,0 -0.92605,0.11906 -0.92605,0.26458 0,0.15119 -0.79375,0.26459 
					-1.85208,0.26459 -1.46991,0 -1.85208,0.0819 -1.85208,0.39687 0,0.33766 
					-0.69086,0.39687 -4.63021,0.39687 -3.93935,0 -4.63021,-0.0592 -4.63021,-0.39687 
					z m 36.24792,-14.68437 c 0,-0.14553 -0.41672,-0.26459 -0.92604,-0.26459 
					-0.64676,0 -0.92605,-0.11969 -0.92605,-0.39687 0,-0.22505 -0.23728,-0.39688 
					-0.54806,-0.39688 -1.01133,0 -1.30402,-0.61931 -1.30402,-2.75922 0,-1.15913 
					0.11149,-2.00328 0.26458,-2.00328 0.14553,0 0.26459,-0.24663 0.26459,-0.54806 
					0,-0.58577 0.57252,-1.30402 1.03943,-1.30402 0.15592,0 0.28348,-0.11906 
					0.28348,-0.26458 0,-0.14552 0.23813,-0.26459 0.52917,-0.26459 0.29398,0 
					0.52917,-0.17639 0.52917,-0.39687 0,-0.24695 0.24988,-0.39688 0.66146,-0.39688 
					0.3638,0 0.66145,-0.11906 0.66145,-0.26458 0,-0.14552 0.41672,-0.26458 
					0.92605,-0.26458 0.50932,0 0.92604,-0.11907 0.92604,-0.26459 0,-0.15119 
					0.79375,-0.26458 1.85208,-0.26458 1.46991,0 1.85208,-0.0819 1.85208,-0.39688 
					0,-0.32977 0.51447,-0.39687 3.04271,-0.39687 2.52824,0 3.04271,0.0671 
					3.04271,0.39687 0,0.29399 0.30868,0.39688 1.19063,0.39688 0.65484,0 
					1.19062,0.11906 1.19062,0.26458 0,0.14552 0.29766,0.26459 0.66146,0.26459 
					0.3638,0 0.66146,0.11906 0.66146,0.26458 0,0.14552 0.23812,0.26458 
					0.52916,0.26458 0.38806,0 0.52917,0.17639 0.52917,0.66146 0,0.41158 
					0.14993,0.66146 0.39688,0.66146 0.30868,0 0.39687,0.35278 0.39687,1.5875 
					0,1.23472 -0.0882,1.5875 -0.39687,1.5875 -0.22049,0 -0.39688,0.23518 
					-0.39688,0.52917 0,0.29104 -0.11906,0.52916 -0.26458,0.52916 -0.14552,0 
					-0.26459,0.12757 -0.26459,0.28349 0,0.46691 -0.71824,1.03943 -1.30401,1.03943 
					-0.30144,0 -0.54807,0.11906 -0.54807,0.26458 0,0.14552 -0.41672,0.26459 
					-0.92604,0.26459 -0.50932,0 -0.92604,0.11906 -0.92604,0.26458 0,0.14552 
					-0.41672,0.26458 -0.92604,0.26458 -0.64676,0 -0.92605,0.11969 -0.92605,0.39688 
					0,0.31498 -0.38217,0.39687 -1.85208,0.39687 -1.05833,0 -1.85208,0.11339 
					-1.85208,0.26459 0,0.16332 -1.36702,0.26458 -3.57188,0.26458 -2.20486,0 
					-3.57187,-0.10126 -3.57187,-0.26458 z m -1.32292,-18.38855 c 0,-0.27209 
					-0.27279,-0.39687 -0.86763,-0.39687 -0.56411,0 -1.00106,-0.18512 
					-1.24904,-0.52917 -0.20977,-0.29104 -0.53371,-0.52916 -0.71986,-0.52916 
					-0.2467,0 -0.33847,-0.57392 -0.33847,-2.11667 0,-1.70509 0.0772,-2.11667 
					0.39688,-2.11667 0.21828,0 0.39687,-0.17859 0.39687,-0.39687 0,-0.21828 
					0.11906,-0.39688 0.26458,-0.39688 0.14553,0 0.26459,-0.23812 0.26459,-0.52916 
					0,-0.38806 0.17639,-0.52917 0.66146,-0.52917 0.3638,0 0.66145,-0.12757 
					0.66145,-0.28348 0,-0.5372 0.75793,-1.03944 1.56861,-1.03944 0.44695,0 
					0.81264,-0.11906 0.81264,-0.26458 0,-0.14552 0.41672,-0.26458 0.92605,-0.26458 
					0.64675,0 0.92604,-0.1197 0.92604,-0.39688 0,-0.31498 0.38217,-0.39687 
					1.85208,-0.39687 1.05833,0 1.85208,-0.1134 1.85208,-0.26459 0,-0.15679 
					0.97014,-0.26458 2.38125,-0.26458 1.41111,0 2.38125,0.10779 2.38125,0.26458 
					0,0.147 0.70556,0.26459 1.5875,0.26459 1.23473,0 1.5875,0.0882 1.5875,0.39687 
					0,0.22343 0.23653,0.39688 0.5412,0.39688 0.5631,0 1.84005,1.09333 
					1.84005,1.57547 0,0.15214 0.11907,0.27661 0.26459,0.27661 0.14925,0 
					0.26458,0.74965 0.26458,1.71979 0,0.97014 -0.11533,1.71979 -0.26458,1.71979 
					-0.14552,0 -0.26459,0.12448 -0.26459,0.27661 0,0.48214 -1.27695,1.57548 
					-1.84005,1.57548 -0.30467,0 -0.5412,0.17345 -0.5412,0.39687 0,0.24695 
					-0.24988,0.39688 -0.66145,0.39688 -0.36381,0 -0.66146,0.11906 -0.66146,0.26458 
					0,0.14552 -0.53578,0.26458 -1.19063,0.26458 -0.65484,0 -1.19062,0.11907 
					-1.19062,0.26459 0,0.14699 -0.70556,0.26458 -1.5875,0.26458 -1.23473,0 
					-1.5875,0.0882 -1.5875,0.39687 0,0.33625 -0.64676,0.39688 -4.23334,0.39688 
					-3.58657,0 -4.23333,-0.0606 -4.23333,-0.39688 z M 31.28408,198.01893 c 
					0,-0.14552 -0.416719,-0.26458 -0.926042,-0.26458 -0.509323,0 -0.926042,-0.11906 
					-0.926042,-0.26458 0,-0.14552 -0.297656,-0.26459 -0.661458,-0.26459 -0.411575,0 
					-0.661458,-0.14993 -0.661458,-0.39687 0,-0.21828 -0.119063,-0.39688 
					-0.264584,-0.39688 -0.145521,0 -0.264583,-0.23812 -0.264583,-0.52916 0,-0.29104 
					-0.119063,-0.52917 -0.264583,-0.52917 -0.145521,0 -0.264584,-0.59531 
					-0.264584,-1.32292 0,-0.99953 -0.09701,-1.32291 -0.396875,-1.32291 -0.218281,0 
					-0.396875,-0.11906 -0.396875,-0.26459 0,-0.14552 0.178594,-0.26458 
					0.396875,-0.26458 0.293981,0 0.396875,-0.30868 0.396875,-1.19062 0,-0.65485 
					0.119063,-1.19063 0.264584,-1.19063 0.14552,0 0.264583,-0.27137 
					0.264583,-0.60305 0,-0.33167 0.238125,-0.77468 0.529167,-0.98445 
					0.291041,-0.20977 0.529166,-0.65277 0.529166,-0.98445 0,-0.35965 
					0.160182,-0.60305 0.396875,-0.60305 0.218281,0 0.396875,-0.11906 
					0.396875,-0.26458 0,-0.14552 0.238125,-0.26459 0.529167,-0.26459 0.291042,0 
					0.529167,-0.11906 0.529167,-0.26458 0,-0.14552 0.178593,-0.26458 
					0.396875,-0.26458 0.218281,0 0.396875,-0.12757 0.396875,-0.28348 0,-0.42856 
					0.699733,-1.03944 1.190625,-1.03944 0.490892,0 1.190625,-0.61088 
					1.190625,-1.03943 0,-0.15592 0.297656,-0.28349 0.661458,-0.28349 0.363802,0 
					0.661458,-0.11906 0.661458,-0.26458 0,-0.14552 0.238125,-0.26458 
					0.529167,-0.26458 0.291042,0 0.529167,-0.11906 0.529167,-0.26459 0,-0.14552 
					0.416718,-0.26458 0.926041,-0.26458 0.646758,0 0.926042,-0.11969 
					0.926042,-0.39687 0,-0.24695 0.249883,-0.39688 0.661458,-0.39688 0.363803,0 
					0.661459,-0.11906 0.661459,-0.26458 0,-0.14552 0.535781,-0.26458 
					1.190625,-0.26458 0.654844,0 1.190625,-0.11907 1.190625,-0.26459 0,-0.14552 
					0.416719,-0.26458 0.926042,-0.26458 0.646758,0 0.926041,-0.11969 
					0.926041,-0.39688 0,-0.30868 0.352777,-0.39687 1.5875,-0.39687 0.881944,0 
					1.5875,-0.11759 1.5875,-0.26458 0,-0.15679 0.97014,-0.26459 2.38125,-0.26459 
					1.411111,0 2.38125,0.1078 2.38125,0.26459 0,0.14552 0.416719,0.26458 
					0.926042,0.26458 0.509323,0 0.926042,0.12756 0.926042,0.28348 0,0.30836 
					0.651325,1.03944 0.926041,1.03944 0.274717,0 0.926042,0.73107 0.926042,1.03943 
					0,0.15592 0.119063,0.28348 0.264583,0.28348 0.15679,0 0.264584,0.97014 
					0.264584,2.38125 0,1.41111 -0.107794,2.38125 -0.264584,2.38125 -0.14552,0 
					-0.264583,0.24663 -0.264583,0.54807 0,0.58577 -0.572519,1.30401 
					-1.039434,1.30401 -0.155916,0 -0.283483,0.12448 -0.283483,0.27661 0,0.29503 
					-1.188291,1.57548 -1.462079,1.57548 -0.26923,0 -0.919171,0.73498 
					-0.919171,1.03943 0,0.15592 -0.238125,0.28348 -0.529166,0.28348 -0.291042,0 
					-0.529167,0.11907 -0.529167,0.26459 0,0.14552 -0.246629,0.26458 
					-0.548066,0.26458 -0.585769,0 -1.304017,0.57252 -1.304017,1.03944 0,0.15591 
					-0.297657,0.28348 -0.661459,0.28348 -0.363802,0 -0.661458,0.11906 
					-0.661458,0.26458 0,0.14552 -0.416719,0.26458 -0.926042,0.26458 -0.509323,0 
					-0.926041,0.11907 -0.926041,0.26459 0,0.14552 -0.297657,0.26458 
					-0.661459,0.26458 -0.411575,0 -0.661458,0.14993 -0.661458,0.39688 0,0.27718 
					-0.279284,0.39687 -0.926042,0.39687 -0.509323,0 -0.926041,0.11906 
					-0.926041,0.26458 0,0.14553 -0.416719,0.26459 -0.926042,0.26459 -0.509323,0 
					-0.926042,0.11906 -0.926042,0.26458 0,0.14552 -0.357187,0.26458 -0.79375,0.26458 
					-0.529167,0 -0.79375,0.1323 -0.79375,0.39688 0,0.29986 -0.323379,0.39687 
					-1.322917,0.39687 -0.727604,0 -1.322916,0.11907 -1.322916,0.26459 0,0.14552 
					-0.654844,0.26458 -1.455209,0.26458 -0.800364,0 -1.455208,0.11906 
					-1.455208,0.26458 0,0.14553 -0.416719,0.26459 -0.926042,0.26459 -0.509323,0 
					-0.926041,-0.11906 -0.926041,-0.26459 z m 189.70625,-10.31875 c 0,-0.14552 
					-0.23812,-0.26458 -0.52916,-0.26458 -0.29398,0 -0.52917,-0.17639 
					-0.52917,-0.39687 0,-0.2367 -0.2434,-0.39688 -0.60305,-0.39688 -0.33167,0 
					-0.77468,-0.23812 -0.98445,-0.52917 -0.20977,-0.29104 -0.53371,-0.52916 
					-0.71987,-0.52916 -0.18615,0 -0.33846,-0.29766 -0.33846,-0.66146 0,-0.3638 
					-0.11907,-0.66146 -0.26459,-0.66146 -0.14552,0 -0.26458,-0.29765 
					-0.26458,-0.66146 0,-0.3638 -0.11906,-0.66146 -0.26458,-0.66146 -0.16105,0 
					-0.26459,-1.19062 -0.26459,-3.0427 0,-1.85209 0.10354,-3.04271 0.26459,-3.04271 
					0.14552,0 0.26458,-0.36569 0.26458,-0.81265 0,-0.81068 0.50224,-1.5686 
					1.03943,-1.5686 0.15592,0 0.28349,-0.24354 0.28349,-0.54119 0,-0.56311 
					1.09333,-1.84006 1.57547,-1.84006 0.15214,0 0.27661,-0.12447 0.27661,-0.27661 
					0,-0.35916 1.21632,-1.57547 1.57548,-1.57547 0.15213,0 0.2766,-0.12448 
					0.2766,-0.27661 0,-0.48214 1.27696,-1.57548 1.84006,-1.57548 0.29766,0 
					0.54119,-0.12756 0.54119,-0.28348 0,-0.46692 0.71825,-1.03943 1.30402,-1.03943 
					0.30144,0 0.54807,-0.11907 0.54807,-0.26459 0,-0.14552 0.29765,-0.26458 
					0.66146,-0.26458 0.3638,0 0.66145,-0.11906 0.66145,-0.26458 0,-0.14553 
					0.23813,-0.26459 0.52917,-0.26459 0.29398,0 0.52917,-0.17639 0.52917,-0.39687 
					0,-0.24695 0.24988,-0.39688 0.66146,-0.39688 0.3638,0 0.66145,-0.11906 
					0.66145,-0.26458 0,-0.14552 0.23813,-0.26458 0.52917,-0.26458 0.29104,0 
					0.52917,-0.11907 0.52917,-0.26459 0,-0.14552 0.29765,-0.26458 0.66146,-0.26458 
					0.41157,0 0.66145,-0.14993 0.66145,-0.39688 0,-0.27718 0.27929,-0.39687 
					0.92605,-0.39687 0.50932,0 0.92604,-0.11906 0.92604,-0.26458 0,-0.14552 
					0.29765,-0.26459 0.66146,-0.26459 0.3638,0 0.66145,-0.11906 0.66145,-0.26458 
					0,-0.14552 0.53579,-0.26458 1.19063,-0.26458 0.88194,0 1.19062,-0.1029 
					1.19062,-0.39688 0,-0.27718 0.27929,-0.39687 0.92605,-0.39687 0.50932,0 
					0.92604,-0.11907 0.92604,-0.26459 0,-0.14552 0.53578,-0.26458 1.19062,-0.26458 
					0.65485,0 1.19063,-0.11906 1.19063,-0.26458 0,-0.147 0.70555,-0.26459 
					1.5875,-0.26459 1.23472,0 1.5875,-0.0882 1.5875,-0.39687 0,-0.33161 
					0.54386,-0.39688 3.30729,-0.39688 2.76342,0 3.30729,0.0653 3.30729,0.39688 
					0,0.27718 0.27928,0.39687 0.92604,0.39687 0.50933,0 0.92604,0.11907 
					0.92604,0.26459 0,0.14552 0.29766,0.26458 0.66146,0.26458 0.36381,0 
					0.66146,0.11906 0.66146,0.26458 0,0.14552 0.23813,0.26459 0.52917,0.26459 
					0.29398,0 0.52916,0.17639 0.52916,0.39687 0,0.21828 0.12757,0.39688 
					0.28349,0.39688 0.46691,0 1.03943,0.71824 1.03943,1.30401 0,0.30144 
					0.11906,0.54807 0.26458,0.54807 0.14553,0 0.26459,0.23812 0.26459,0.52917 
					0,0.29398 0.17639,0.52916 0.39687,0.52916 0.31971,0 0.39688,0.41158 
					0.39688,2.11667 0,1.70509 -0.0772,2.11667 -0.39688,2.11667 -0.27718,0 
					-0.39687,0.27928 -0.39687,0.92604 0,0.50932 -0.11906,0.92604 -0.26459,0.92604 
					-0.14552,0 -0.26458,0.29766 -0.26458,0.66146 0,0.3638 -0.11906,0.66146 
					-0.26458,0.66146 -0.14552,0 -0.26459,0.29765 -0.26459,0.66145 0,0.36381 
					-0.12447,0.66146 -0.2766,0.66146 -0.35916,0 -1.57548,1.21632 -1.57548,1.57548 
					0,0.15213 -0.15231,0.27661 -0.33846,0.27661 -0.18616,0 -0.5101,0.23812 
					-0.71987,0.52916 -0.20977,0.29104 -0.65278,0.52917 -0.98445,0.52917 -0.33168,0 
					-0.60305,0.12756 -0.60305,0.28348 0,0.44792 -0.70885,1.03944 -1.24561,1.03944 
					-0.26931,0 -0.66129,0.23812 -0.87106,0.52916 -0.20977,0.29104 -0.59324,0.52917 
					-0.85216,0.52917 -0.25891,0 -0.47076,0.17859 -0.47076,0.39687 0,0.2367 
					-0.24339,0.39688 -0.60304,0.39688 -0.33168,0 -0.77468,0.23812 -0.98446,0.52917 
					-0.20977,0.29104 -0.65277,0.52916 -0.98445,0.52916 -0.35965,0 -0.60305,0.16018 
					-0.60305,0.39688 0,0.24694 -0.24988,0.39687 -0.66145,0.39687 -0.36381,0 
					-0.66146,0.11906 -0.66146,0.26458 0,0.14553 -0.41672,0.26459 -0.92604,0.26459 
					-0.50933,0 -0.92605,0.11906 -0.92605,0.26458 0,0.14552 -0.23812,0.26458 
					-0.52916,0.26458 -0.29398,0 -0.52917,0.17639 -0.52917,0.39688 0,0.24694 
					-0.24988,0.39687 -0.66146,0.39687 -0.3638,0 -0.66146,0.11907 -0.66146,0.26459 
					0,0.14552 -0.41671,0.26458 -0.92604,0.26458 -0.50932,0 -0.92604,0.11906 
					-0.92604,0.26458 0,0.14553 -0.41672,0.26459 -0.92604,0.26459 -0.64676,0 
					-0.92604,0.11969 -0.92604,0.39687 0,0.29398 -0.30868,0.39688 -1.19063,0.39688 
					-0.65484,0 -1.19062,0.11906 -1.19062,0.26458 0,0.14552 -0.41672,0.26458 
					-0.92604,0.26458 -0.50933,0 -0.92605,0.11907 -0.92605,0.26459 0,0.14552 
					-0.53578,0.26458 -1.19062,0.26458 -0.88194,0 -1.19063,0.1029 -1.19063,0.39688 
					0,0.29398 -0.30868,0.39687 -1.19062,0.39687 -0.65484,0 -1.19063,0.11906 
					-1.19063,0.26458 0,0.16169 -1.23472,0.26459 -3.175,0.26459 -1.94027,0 
					-3.175,-0.1029 -3.175,-0.26459 z M 28.638246,169.17935 c 0,-0.15434 
					-0.881943,-0.26458 -2.116667,-0.26458 -1.234722,0 -2.116666,-0.11024 
					-2.116666,-0.26458 0,-0.14553 -0.654844,-0.26459 -1.455209,-0.26459 -1.117129,0 
					-1.455208,-0.0922 -1.455208,-0.39687 0,-0.29986 -0.32338,-0.39688 
					-1.322917,-0.39688 -0.727604,0 -1.322916,-0.11906 -1.322916,-0.26458 0,-0.14552 
					-0.357188,-0.26458 -0.79375,-0.26458 -0.436563,0 -0.79375,-0.10358 
					-0.79375,-0.23018 0,-0.12659 -0.526202,-0.28948 -1.169338,-0.36197 
					-1.389732,-0.15665 -4.367357,-1.49922 -5.585031,-2.51822 -0.477209,-0.39935 
					-1.3513788,-1.07336 -1.9425987,-1.49781 -1.3895211,-0.99754 -2.5198277,-2.41902 
					-3.6667957,-4.61137 -0.8594572,-1.64278 -0.9130991,-1.88855 -0.8012224,-3.67091 
					0.2337528,-3.72403 2.5536939,-6.85918 5.8573875,-7.91564 1.6667853,-0.533 
					10.2180143,-0.85533 10.2180143,-0.38516 0,0.18508 0.761953,0.28917 
					2.116667,0.28917 1.705093,0 2.116667,0.0772 2.116667,0.39688 0,0.30868 
					0.352777,0.39687 1.5875,0.39687 0.881943,0 1.5875,0.11759 1.5875,0.26459 
					0,0.14552 0.416719,0.26458 0.926042,0.26458 0.509322,0 0.926041,0.11906 
					0.926041,0.26458 0,0.14552 0.416719,0.26459 0.926042,0.26459 0.646758,0 
					0.926042,0.11969 0.926042,0.39687 0,0.27718 0.279283,0.39688 0.926041,0.39688 
					0.509323,0 0.926042,0.11906 0.926042,0.26458 0,0.14552 0.416719,0.26458 
					0.926042,0.26458 0.509323,0 0.926041,0.11907 0.926041,0.26459 0,0.14552 
					0.416719,0.26458 0.926042,0.26458 0.646758,0 0.926042,0.11969 0.926042,0.39687 
					0,0.22049 0.235185,0.39688 0.529166,0.39688 0.291042,0 0.529167,0.11906 
					0.529167,0.26458 0,0.14552 0.297656,0.26459 0.661458,0.26459 0.363803,0 
					0.661459,0.11906 0.661459,0.26458 0,0.14552 0.238125,0.26458 0.529166,0.26458 
					0.293982,0 0.529167,0.17639 0.529167,0.39688 0,0.23669 0.243396,0.39687 
					0.603049,0.39687 0.331676,0 0.774679,0.23813 0.984451,0.52917 0.209772,0.29104 
					0.533712,0.52917 0.719868,0.52917 0.186155,0 0.338466,0.12447 0.338466,0.27661 
					0,0.35915 1.216318,1.57547 1.575474,1.57547 0.152136,0 0.276609,0.15231 
					0.276609,0.33847 0,0.18615 0.238125,0.51009 0.529167,0.71986 0.291041,0.20978 
					0.529166,0.59325 0.529166,0.85216 0,0.25892 0.178594,0.47076 0.396875,0.47076 
					0.327581,0 0.396875,0.48507 0.396875,2.77812 0,2.29306 -0.06929,2.77813 
					-0.396875,2.77813 -0.246943,0 -0.396875,0.24988 -0.396875,0.66146 0,0.3638 
					-0.119062,0.66146 -0.264583,0.66146 -0.145521,0 -0.264583,0.24662 
					-0.264583,0.54806 0,0.58577 -0.572519,1.30402 -1.039435,1.30402 -0.155916,0 
					-0.283482,0.11906 -0.283482,0.26458 0,0.14552 -0.238125,0.26459 
					-0.529167,0.26459 -0.291041,0 -0.529166,0.11906 -0.529166,0.26458 0,0.14552 
					-0.178594,0.26458 -0.396875,0.26458 -0.218282,0 -0.396875,0.1786 
					-0.396875,0.39688 0,0.27718 -0.279284,0.39687 -0.926042,0.39687 -0.509323,0 
					-0.926042,0.11906 -0.926042,0.26459 0,0.14552 -0.357187,0.26458 -0.79375,0.26458 
					-0.436562,0 -0.79375,0.11906 -0.79375,0.26458 0,0.16711 -1.852083,0.26459 
					-5.027083,0.26459 -3.175,0 -5.027084,-0.0975 -5.027084,-0.26459 z m 
					135.995834,-9.78958 c 0,-0.15434 -0.88194,-0.26458 -2.11666,-0.26458 -1.23473,0 
					-2.11667,-0.11025 -2.11667,-0.26459 0,-0.14552 -0.53578,-0.26458 
					-1.19063,-0.26458 -0.88194,0 -1.19062,-0.10289 -1.19062,-0.39688 0,-0.29398 
					-0.30868,-0.39687 -1.19063,-0.39687 -0.65484,0 -1.19062,-0.11906 
					-1.19062,-0.26458 0,-0.14552 -0.41672,-0.26459 -0.92604,-0.26459 -0.50933,0 
					-0.92604,-0.11906 -0.92604,-0.26458 0,-0.14552 -0.41672,-0.26458 
					-0.92605,-0.26458 -0.50932,0 -0.92604,-0.11907 -0.92604,-0.26459 0,-0.14552 
					-0.41672,-0.26458 -0.92604,-0.26458 -0.64676,0 -0.92604,-0.11969 
					-0.92604,-0.39688 0,-0.27718 -0.27928,-0.39687 -0.92604,-0.39687 -0.50933,0 
					-0.92604,-0.11906 -0.92604,-0.26458 0,-0.14552 -0.41672,-0.26459 
					-0.92605,-0.26459 -0.50932,0 -0.92604,-0.11906 -0.92604,-0.26458 0,-0.14552 
					-0.29765,-0.26458 -0.66146,-0.26458 -0.41157,0 -0.66145,-0.14994 
					-0.66145,-0.39688 0,-0.22048 -0.23519,-0.39687 -0.52917,-0.39687 -0.29104,0 
					-0.52917,-0.11907 -0.52917,-0.26459 0,-0.14552 -0.24663,-0.26458 
					-0.54806,-0.26458 -0.58577,0 -1.30402,-0.57252 -1.30402,-1.03943 0,-0.15592 
					-0.24663,-0.28349 -0.54807,-0.28349 -0.70188,0 -1.30401,-0.60213 
					-1.30401,-1.30401 0,-0.31078 -0.17183,-0.54807 -0.39688,-0.54807 -0.22048,0 
					-0.39687,-0.23519 -0.39687,-0.52917 0,-0.29104 -0.11907,-0.52916 
					-0.26459,-0.52916 -0.15959,0 -0.26458,-1.10243 -0.26458,-2.77813 0,-1.67569 
					0.10499,-2.77812 0.26458,-2.77812 0.14552,0 0.26459,-0.29766 0.26459,-0.66146 
					0,-0.3638 0.12756,-0.66146 0.28348,-0.66146 0.42855,0 1.03943,-0.69973 
					1.03943,-1.19062 0,-0.23907 0.17009,-0.60477 0.37798,-0.81265 0.20789,-0.20789 
					0.57358,-0.37798 0.81265,-0.37798 0.49089,0 1.19062,-0.61088 1.19062,-1.03943 
					0,-0.15592 0.29766,-0.28349 0.66146,-0.28349 0.3638,0 0.66146,-0.11906 
					0.66146,-0.26458 0,-0.14552 0.29766,-0.26458 0.66146,-0.26458 0.3638,0 
					0.66146,-0.11907 0.66146,-0.26459 0,-0.14552 0.41671,-0.26458 0.92604,-0.26458 
					0.64676,0 0.92604,-0.11969 0.92604,-0.39687 0,-0.31208 0.36748,-0.39688 
					1.71979,-0.39688 0.97014,0 1.71979,-0.11533 1.71979,-0.26458 0,-0.15564 
					0.92604,-0.26459 2.24896,-0.26459 1.32292,0 2.24896,0.10895 2.24896,0.26459 
					0,0.15679 0.97014,0.26458 2.38125,0.26458 1.94028,0 2.38125,0.0735 
					2.38125,0.39688 0,0.30868 0.35278,0.39687 1.5875,0.39687 0.88194,0 
					1.5875,0.11759 1.5875,0.26458 0,0.14552 0.53578,0.26459 1.19062,0.26459 
					0.65485,0 1.19063,0.11906 1.19063,0.26458 0,0.14552 0.41672,0.26458 
					0.92604,0.26458 0.64676,0 0.92604,0.1197 0.92604,0.39688 0,0.29398 
					0.30868,0.39687 1.19063,0.39687 0.65484,0 1.19062,0.11907 1.19062,0.26459 
					0,0.14552 0.29766,0.26458 0.66146,0.26458 0.3638,0 0.66146,0.11906 
					0.66146,0.26458 0,0.14552 0.41672,0.26459 0.92604,0.26459 0.64676,0 
					0.92604,0.11969 0.92604,0.39687 0,0.22049 0.23519,0.39688 0.52917,0.39688 
					0.29104,0 0.52917,0.11906 0.52917,0.26458 0,0.14552 0.29765,0.26458 
					0.66145,0.26458 0.36381,0 0.66146,0.11907 0.66146,0.26459 0,0.14552 
					0.29766,0.26458 0.66146,0.26458 0.41158,0 0.66146,0.14993 0.66146,0.39688 
					0,0.22048 0.23519,0.39687 0.52917,0.39687 0.29104,0 0.52916,0.11906 
					0.52916,0.26458 0,0.14552 0.1786,0.26459 0.39688,0.26459 0.21828,0 
					0.39687,0.11906 0.39687,0.26458 0,0.14552 0.23813,0.26458 0.52917,0.26458 
					0.29398,0 0.52917,0.17639 0.52917,0.39688 0,0.21828 0.13082,0.39687 
					0.29072,0.39687 0.15989,0 0.62438,0.35719 1.03219,0.79375 0.40781,0.43657 
					0.8723,0.79375 1.0322,0.79375 0.15989,0 0.29072,0.29766 0.29072,0.66146 0,0.3638 
					0.11906,0.66146 0.26458,0.66146 0.14552,0 0.26459,0.41672 0.26459,0.92604 
					0,0.50932 0.11906,0.92604 0.26458,0.92604 0.15119,0 0.26458,0.79375 
					0.26458,1.85209 0,1.05833 -0.11339,1.85208 -0.26458,1.85208 -0.14552,0 
					-0.26458,0.36569 -0.26458,0.81265 0,0.81068 -0.50224,1.5686 -1.03944,1.5686 
					-0.15591,0 -0.28348,0.24663 -0.28348,0.54807 0,0.65763 -0.59018,1.30401 
					-1.19063,1.30401 -0.49089,0 -1.19062,0.61088 -1.19062,1.03944 0,0.15591 
					-0.29766,0.28348 -0.66146,0.28348 -0.3638,0 -0.66146,0.11906 -0.66146,0.26458 
					0,0.14552 -0.53578,0.26459 -1.19062,0.26459 -0.65485,0 -1.19063,0.11906 
					-1.19063,0.26458 0,0.16799 -2.02847,0.26458 -5.55625,0.26458 -3.52777,0 
					-5.55625,-0.0966 -5.55625,-0.26458 z M 93.196581,148.40956 c 0,-0.29986 
					-0.323379,-0.39687 -1.322917,-0.39687 -0.727604,0 -1.322916,-0.11907 
					-1.322916,-0.26459 0,-0.14552 -0.416719,-0.26458 -0.926042,-0.26458 -0.509323,0 
					-0.926042,-0.11906 -0.926042,-0.26458 0,-0.14552 -0.238125,-0.26459 
					-0.529166,-0.26459 -0.293982,0 -0.529167,-0.17639 -0.529167,-0.39687 0,-0.27718 
					-0.279284,-0.39688 -0.926042,-0.39688 -0.509323,0 -0.926042,-0.11906 
					-0.926042,-0.26458 0,-0.14552 -0.297656,-0.26458 -0.661458,-0.26458 -0.363802,0 
					-0.661458,-0.11907 -0.661458,-0.26459 0,-0.14552 -0.238125,-0.26458 
					-0.529167,-0.26458 -0.291042,0 -0.529167,-0.11906 -0.529167,-0.26458 0,-0.14552 
					-0.297656,-0.26459 -0.661458,-0.26459 -0.411575,0 -0.661458,-0.14993 
					-0.661458,-0.39687 0,-0.24695 -0.249883,-0.39688 -0.661459,-0.39688 -0.363802,0 
					-0.661458,-0.11906 -0.661458,-0.26458 0,-0.14552 -0.238125,-0.26458 
					-0.529167,-0.26458 -0.291041,0 -0.529166,-0.11907 -0.529166,-0.26459 0,-0.14552 
					-0.297657,-0.26458 -0.661459,-0.26458 -0.411575,0 -0.661458,-0.14993 
					-0.661458,-0.39687 0,-0.27719 -0.279284,-0.39688 -0.926042,-0.39688 -0.509323,0 
					-0.926041,-0.11906 -0.926041,-0.26458 0,-0.14552 -0.297657,-0.26459 
					-0.661459,-0.26459 -0.363802,0 -0.661458,-0.11906 -0.661458,-0.26458 0,-0.14552 
					-0.416719,-0.26458 -0.926042,-0.26458 -0.646758,0 -0.926041,-0.1197 
					-0.926041,-0.39688 0,-0.22048 -0.235186,-0.39687 -0.529167,-0.39687 -0.291042,0 
					-0.529167,-0.11907 -0.529167,-0.26459 0,-0.14552 -0.416719,-0.26458 
					-0.926042,-0.26458 -0.509322,0 -0.926041,-0.11906 -0.926041,-0.26458 0,-0.14552 
					-0.535781,-0.26459 -1.190625,-0.26459 -0.881944,0 -1.190625,-0.10289 
					-1.190625,-0.39687 0,-0.24694 -0.249883,-0.39688 -0.661459,-0.39688 -0.363802,0 
					-0.661458,-0.11906 -0.661458,-0.26458 0,-0.14552 -0.416719,-0.26458 
					-0.926042,-0.26458 -0.509323,0 -0.926041,-0.11907 -0.926041,-0.26459 0,-0.14552 
					-0.297657,-0.26458 -0.661459,-0.26458 -0.363802,0 -0.661458,-0.12757 
					-0.661458,-0.28348 0,-0.42154 -0.696537,-1.03944 -1.171726,-1.03944 -0.228677,0 
					-0.415774,-0.11906 -0.415774,-0.26458 0,-0.14552 -0.178594,-0.26458 
					-0.396875,-0.26458 -0.218281,0 -0.396875,-0.15231 -0.396875,-0.33847 0,-0.18615 
					-0.238125,-0.51009 -0.529167,-0.71987 -0.291041,-0.20977 -0.529166,-0.59324 
					-0.529166,-0.85216 0,-0.25891 -0.178594,-0.47075 -0.396875,-0.47075 -0.246944,0 
					-0.396875,-0.24989 -0.396875,-0.66146 0,-0.3638 -0.119063,-0.66146 
					-0.264584,-0.66146 -0.145521,0 -0.264583,-0.53578 -0.264583,-1.19062 0,-0.65485 
					0.119062,-1.19063 0.264583,-1.19063 0.145521,0 0.264584,-0.29766 
					0.264584,-0.66146 0,-0.3638 0.127566,-0.66146 0.283482,-0.66146 0.36631,0 
					1.039434,-0.67312 1.039434,-1.03943 0,-0.15592 0.297657,-0.28348 
					0.661459,-0.28348 0.363802,0 0.661458,-0.11906 0.661458,-0.26459 0,-0.14552 
					0.654844,-0.26458 1.455208,-0.26458 0.800365,0 1.455209,-0.11906 
					1.455209,-0.26458 0,-0.14552 0.535781,-0.26459 1.190625,-0.26459 0.654843,0 
					1.190625,0.11907 1.190625,0.26459 0,0.15119 0.79375,0.26458 1.852083,0.26458 
					1.058333,0 1.852083,0.11339 1.852083,0.26458 0,0.14553 0.535782,0.26459 
					1.190626,0.26459 0.881943,0 1.190625,0.10289 1.190625,0.39687 0,0.27719 
					0.279283,0.39688 0.926041,0.39688 0.509323,0 0.926042,0.11906 0.926042,0.26458 
					0,0.14552 0.416719,0.26458 0.926042,0.26458 0.509323,0 0.926041,0.11907 
					0.926041,0.26459 0,0.14552 0.297657,0.26458 0.661459,0.26458 0.411575,0 
					0.661458,0.14993 0.661458,0.39688 0,0.27718 0.279284,0.39687 0.926042,0.39687 
					0.509323,0 0.926041,0.11906 0.926041,0.26458 0,0.14553 0.535782,0.26459 
					1.190625,0.26459 0.654844,0 1.190625,-0.11906 1.190625,-0.26459 0,-0.14552 
					0.297657,-0.26458 0.661459,-0.26458 0.579564,0 0.661458,-0.11465 
					0.661458,-0.92604 0,-0.50932 -0.119062,-0.92604 -0.264583,-0.92604 -0.145521,0 
					-0.264584,-0.1786 -0.264584,-0.39688 0,-0.21828 -0.178593,-0.39687 
					-0.396875,-0.39687 -0.220485,0 -0.396875,-0.23519 -0.396875,-0.52917 0,-0.29104 
					-0.119062,-0.52917 -0.264583,-0.52917 -0.145521,0 -0.264583,-0.12756 
					-0.264583,-0.28348 0,-0.36631 -0.673124,-1.03943 -1.039434,-1.03943 -0.155917,0 
					-0.283483,-0.29766 -0.283483,-0.66146 0,-0.3638 -0.119062,-0.66146 
					-0.264583,-0.66146 -0.145521,0 -0.264584,-0.35719 -0.264584,-0.79375 0,-0.43656 
					-0.119062,-0.79375 -0.264583,-0.79375 -0.145521,0 -0.264583,-0.17859 
					-0.264583,-0.39687 0,-0.21828 0.119062,-0.39688 0.264583,-0.39688 0.145521,0 
					0.264583,-0.36569 0.264583,-0.81265 0,-0.73504 0.492104,-1.5686 0.926042,-1.5686 
					0.220766,0 0.926042,-0.70527 0.926042,-0.92604 0,-0.43394 0.833562,-0.92604 
					1.568601,-0.92604 0.446958,0 0.812649,-0.11906 0.812649,-0.26459 0,-0.14699 
					0.705556,-0.26458 1.5875,-0.26458 1.234723,0 1.5875,-0.0882 1.5875,-0.39687 
					0,-0.27719 0.279283,-0.39688 0.926042,-0.39688 0.509322,0 0.926041,-0.11906 
					0.926041,-0.26458 0,-0.14552 0.238125,-0.26459 0.529167,-0.26459 0.291042,0 
					0.529167,-0.11906 0.529167,-0.26458 0,-0.14552 0.178593,-0.26458 
					0.396875,-0.26458 0.246943,0 0.396875,-0.24988 0.396875,-0.66146 0,-0.3638 
					-0.15231,-0.66146 -0.338466,-0.66146 -0.186156,0 -0.510095,-0.23812 
					-0.719868,-0.52916 -0.279156,-0.38731 -0.684924,-0.52917 -1.513618,-0.52917 
					-0.830024,0 -1.132215,-0.10593 -1.132215,-0.39688 0,-0.27718 -0.279284,-0.39687 
					-0.926042,-0.39687 -0.509323,0 -0.926042,-0.11906 -0.926042,-0.26458 0,-0.14553 
					-0.127566,-0.26459 -0.283482,-0.26459 -0.304453,0 -1.039434,-0.64994 
					-1.039434,-0.91917 0,-0.27379 -1.280451,-1.46208 -1.575475,-1.46208 -0.173011,0 
					-0.276609,-0.94138 -0.276609,-2.51354 0,-1.61102 0.101788,-2.51354 
					0.283483,-2.51354 0.426027,0 1.039434,-0.69858 1.039434,-1.18375 0,-0.6051 
					1.195218,-1.726668 1.840058,-1.726668 0.297656,0 0.541192,-0.127566 
					0.541192,-0.283482 0,-0.466916 0.718249,-1.039434 1.304018,-1.039434 0.301437,0 
					0.548066,-0.119063 0.548066,-0.264584 0,-0.14552 0.416718,-0.264583 
					0.926041,-0.264583 0.646758,0 0.926042,-0.119692 0.926042,-0.396875 0,-0.246943 
					0.249883,-0.396875 0.661458,-0.396875 0.363802,0 0.661459,-0.119062 
					0.661459,-0.264583 0,-0.145521 0.416718,-0.264583 0.926041,-0.264583 0.509323,0 
					0.926042,-0.119063 0.926042,-0.264584 0,-0.145521 0.416719,-0.264583 
					0.926042,-0.264583 0.646758,0 0.926041,-0.119692 0.926041,-0.396875 0,-0.264583 
					0.264584,-0.396875 0.79375,-0.396875 0.436563,0 0.79375,-0.119063 
					0.79375,-0.264583 0,-0.145521 0.297657,-0.264584 0.661459,-0.264584 0.363802,0 
					0.661458,-0.119062 0.661458,-0.264583 0,-0.145521 0.297656,-0.264583 
					0.661458,-0.264583 0.363803,0 0.661459,-0.15231 0.661459,-0.338466 0,-0.186155 
					0.238125,-0.510095 0.529166,-0.719868 0.316216,-0.227917 0.529166,-0.684924 
					0.529166,-1.135642 0,-1.323464 -0.455664,-1.510191 -3.685266,-1.510191 
					-2.427447,0 -2.929316,-0.06799 -2.929316,-0.396875 0,-0.293981 
					-0.308682,-0.396875 -1.190625,-0.396875 -0.654844,0 -1.190625,-0.119062 
					-1.190625,-0.264583 0,-0.145521 -0.416719,-0.264583 -0.926042,-0.264583 
					-0.509323,0 -0.926042,-0.119063 -0.926042,-0.264584 0,-0.145521 
					-0.178594,-0.264583 -0.396875,-0.264583 -0.218281,0 -0.396875,-0.119063 
					-0.396875,-0.264583 0,-0.145521 -0.238125,-0.264584 -0.529166,-0.264584 
					-0.293982,0 -0.529167,-0.176389 -0.529167,-0.396875 0,-0.218281 
					-0.124473,-0.396875 -0.276609,-0.396875 -0.359156,0 -1.575475,-1.216318 
					-1.575475,-1.575474 0,-0.152136 -0.178593,-0.276609 -0.396875,-0.276609 
					-0.220485,0 -0.396875,-0.235185 -0.396875,-0.529167 0,-0.291041 
					-0.119062,-0.529166 -0.264583,-0.529166 -0.145521,0 -0.264583,-0.416719 
					-0.264583,-0.926042 0,-0.509323 -0.119063,-0.926041 -0.264584,-0.926041 
					-0.14552,0 -0.264583,-0.595313 -0.264583,-1.322917 0,-0.727604 
					0.119063,-1.322917 0.264583,-1.322917 0.145521,0 0.264584,-0.357187 
					0.264584,-0.79375 0,-0.436562 0.119062,-0.79375 0.264583,-0.79375 0.145521,0 
					0.264583,-0.297656 0.264583,-0.661458 0,-0.363802 0.127567,-0.661458 
					0.283483,-0.661458 0.36631,0 1.039434,-0.673124 1.039434,-1.039434 0,-0.155917 
					0.297656,-0.283483 0.661459,-0.283483 0.363802,0 0.661458,-0.119062 
					0.661458,-0.264583 0,-0.145521 0.238125,-0.264583 0.529167,-0.264583 0.291041,0 
					0.529166,-0.119063 0.529166,-0.264584 0,-0.145521 0.297656,-0.264583 
					0.661459,-0.264583 0.411575,0 0.661458,-0.149932 0.661458,-0.396875 0,-0.314981 
					0.382175,-0.396875 1.852083,-0.396875 1.058334,0 1.852084,-0.113393 
					1.852084,-0.264583 0,-0.145521 0.654843,-0.264584 1.455208,-0.264584 0.800365,0 
					1.455208,0.119063 1.455208,0.264584 0,0.157821 1.014236,0.264583 
					2.513542,0.264583 2.057874,0 2.513544,0.07195 2.513544,0.396875 0,0.308681 
					0.35277,0.396875 1.5875,0.396875 0.88194,0 1.5875,0.117591 1.5875,0.264583 
					0,0.145521 0.53578,0.264584 1.19062,0.264584 0.65485,0 1.19063,0.119062 
					1.19063,0.264583 0,0.145521 0.41672,0.264583 0.92604,0.264583 0.64676,0 
					0.92604,0.119692 0.92604,0.396875 0,0.220485 0.23519,0.396875 0.52917,0.396875 
					0.29104,0 0.52916,0.119063 0.52916,0.264583 0,0.145521 0.41672,0.264584 
					0.92605,0.264584 0.50932,0 0.92604,0.119062 0.92604,0.264583 0,0.145521 
					0.29765,0.264583 0.66146,0.264583 0.41157,0 0.66145,0.149932 0.66145,0.396875 
					0,0.246944 0.24989,0.396875 0.66146,0.396875 0.36381,0 0.66146,0.119063 
					0.66146,0.264584 0,0.145521 0.23813,0.264583 0.52917,0.264583 0.29104,0 
					0.52916,0.119063 0.52916,0.264583 0,0.145521 0.24663,0.264584 0.54807,0.264584 
					0.58577,0 1.30402,0.572518 1.30402,1.039434 0,0.155916 0.27137,0.283482 
					0.60305,0.283482 0.33167,0 0.77468,0.238125 0.98445,0.529167 0.20977,0.291042 
					0.65277,0.529167 0.98445,0.529167 0.33168,0 0.60305,0.124473 0.60305,0.276608 
					0,0.359156 1.21632,1.575475 1.57547,1.575475 0.15214,0 0.27661,0.124473 
					0.27661,0.276608 0,0.359156 1.21632,1.575475 1.57548,1.575475 0.15213,0 
					0.27661,0.124473 0.27661,0.276609 0,0.359156 1.21631,1.575474 1.57547,1.575474 
					0.15214,0 0.27661,0.178594 0.27661,0.396875 0,0.218282 0.11906,0.396875 
					0.26458,0.396875 0.14552,0 0.26459,0.238125 0.26459,0.529167 0,0.291042 
					0.11906,0.529167 0.26458,0.529167 0.14552,0 0.26458,0.297656 0.26458,0.661458 
					0,0.411575 0.14993,0.661458 0.39688,0.661458 0.3197,0 0.39687,0.411575 
					0.39687,2.116667 0,1.705091 -0.0772,2.116666 -0.39687,2.116666 -0.22343,0 
					-0.39688,0.236522 -0.39688,0.541192 0,0.563105 -1.09334,1.840058 
					-1.57547,1.840058 -0.15214,0 -0.27661,0.127566 -0.27661,0.283483 0,0.466915 
					-0.71825,1.039434 -1.30402,1.039434 -0.30144,0 -0.54806,0.119062 
					-0.54806,0.264583 0,0.145521 -0.23813,0.264583 -0.52917,0.264583 -0.29398,0 
					-0.52917,0.17639 -0.52917,0.396875 0,0.277183 -0.27928,0.396875 
					-0.92604,0.396875 -0.50932,0 -0.92604,0.119063 -0.92604,0.264588 0,0.14552 
					-0.41672,0.26458 -0.92604,0.26458 -0.50933,0 -0.92605,0.11906 -0.92605,0.26458 
					0,0.16757 -1.94027,0.26459 -5.29166,0.26459 -3.35139,0 -5.29167,0.097 
					-5.29167,0.26458 0,0.15875 -1.05833,0.26458 -2.64583,0.26458 -2.17546,0 
					-2.64584,0.0706 -2.64584,0.39688 0,0.22504 -0.23728,0.39687 -0.54806,0.39687 
					-0.9651,0 -1.30402,0.63318 -1.30402,2.43624 0,1.37675 0.0956,1.74917 
					0.52917,2.06168 0.29104,0.20977 0.52916,0.65277 0.52916,0.98445 0,0.33168 
					0.12757,0.60305 0.28349,0.60305 0.30835,0 1.03943,0.65132 1.03943,0.92604 
					0,0.22077 0.70528,0.92604 0.92604,0.92604 0.21714,0 0.92604,0.70365 
					0.92604,0.91917 0,0.21252 1.24957,1.46208 1.46208,1.46208 0.26923,0 
					0.91917,0.73498 0.91917,1.03944 0,0.15591 0.11907,0.28348 0.26459,0.28348 
					0.14552,0 0.26458,0.23812 0.26458,0.52916 0,0.29399 0.17639,0.52917 
					0.39688,0.52917 0.21828,0 0.39687,0.12447 0.39687,0.27661 0,0.35916 
					1.21632,1.57547 1.57548,1.57547 0.15213,0 0.2766,0.1786 0.2766,0.39688 0,0.21828 
					0.11907,0.39687 0.26459,0.39687 0.14552,0 0.26458,0.23813 0.26458,0.52917 
					0,0.29104 0.11906,0.52917 0.26458,0.52917 0.14553,0 0.26459,0.41672 
					0.26459,0.92604 0,0.50932 -0.11906,0.92604 -0.26459,0.92604 -0.14552,0 
					-0.26458,0.24663 -0.26458,0.54807 0,0.58577 -0.57252,1.30401 -1.03943,1.30401 
					-0.15592,0 -0.28349,0.11907 -0.28349,0.26459 0,0.14552 -0.41671,0.26458 
					-0.92604,0.26458 -0.64676,0 -0.92604,0.11969 -0.92604,0.39688 0,0.31207 
					-0.36748,0.39687 -1.71979,0.39687 -0.97014,0 -1.71979,0.11533 -1.71979,0.26458 
					0,0.147 -0.70556,0.26459 -1.5875,0.26459 -0.88195,0 -1.5875,-0.11759 
					-1.5875,-0.26459 0,-0.44464 -5.783728,-0.33948 -6.23661,0.1134 -0.431228,0.43123 
					-0.51145,1.4741 -0.113392,1.4741 0.145521,0 0.264583,0.23813 0.264583,0.52917 
					0,0.29104 0.119063,0.52917 0.264583,0.52917 0.145521,0 0.264584,0.24663 
					0.264584,0.54806 0,0.58577 0.572518,1.30402 1.039434,1.30402 0.155916,0 
					0.283482,0.12447 0.283482,0.27661 0,0.35916 1.216319,1.57547 1.575475,1.57547 
					0.152136,0 0.276609,0.12448 0.276609,0.27661 0,0.35916 1.216322,1.57548 
					1.575472,1.57548 0.15214,0 0.27661,0.12447 0.27661,0.27661 0,0.29502 
					1.18829,1.57547 1.46208,1.57547 0.21552,0 0.91917,0.70891 0.91917,0.92604 
					0,0.27472 0.73108,0.92604 1.03944,0.92604 0.15591,0 0.28348,0.29766 
					0.28348,0.66146 0,0.3638 0.11906,0.66146 0.26458,0.66146 0.14552,0 
					0.26458,0.23813 0.26458,0.52917 0,0.29104 0.11907,0.52916 0.26459,0.52916 
					0.14552,0 0.26458,0.29766 0.26458,0.66146 0,0.3638 0.11906,0.66146 
					0.26458,0.66146 0.1512,0 0.26459,0.79375 0.26459,1.85208 0,1.05834 
					-0.11339,1.85209 -0.26459,1.85209 -0.14552,0 -0.26458,0.29765 -0.26458,0.66146 
					0,0.3638 -0.11906,0.66145 -0.26458,0.66145 -0.14552,0 -0.26459,0.1871 
					-0.26459,0.41578 0,0.47519 -0.61789,1.17172 -1.03943,1.17172 -0.15592,0 
					-0.28348,0.1786 -0.28348,0.39688 0,0.22048 -0.23519,0.39687 -0.52917,0.39687 
					-0.29104,0 -0.52917,0.11907 -0.52917,0.26459 0,0.14552 -0.29765,0.26458 
					-0.66145,0.26458 -0.36381,0 -0.66146,0.11906 -0.66146,0.26458 0,0.14552 
					-0.29766,0.26459 -0.66146,0.26459 -0.41158,0 -0.66146,0.14993 -0.66146,0.39687 
					0,0.33318 -0.573264,0.39688 -3.571874,0.39688 -2.99861,0 -3.571875,-0.0637 
					-3.571875,-0.39688 z m 204.476039,-4.29948 c -0.9208,-1.08354 -3.62961,-5.04521 
					-4.1157,-6.01927 -0.52507,-1.05213 -1.72191,-5.12327 -2.58884,-8.80613 
					-0.42672,-1.81275 -0.42733,-2.14991 -0.0103,-5.68854 0.58346,-4.95137 
					1.01859,-6.98216 1.84592,-8.61479 0.37539,-0.7408 0.68252,-1.58658 
					0.68252,-1.87951 0,-1.28206 0.89617,-2.6868 2.80267,-4.3931 l 1.95983,-1.75404 v 
					18.8092 c 0,10.34506 -0.0411,18.8092 -0.0913,18.8092 -0.0502,0 -0.26834,-0.20836 
					-0.48477,-0.46302 z m -55.7802,-8.53281 c 0,-0.14552 -0.29766,-0.26458 
					-0.66146,-0.26458 -0.3638,0 -0.66146,-0.13083 -0.66146,-0.29072 0,-0.1599 
					-0.35719,-0.62439 -0.79375,-1.0322 -0.43656,-0.40781 -0.79375,-0.99136 
					-0.79375,-1.29678 0,-0.31721 -0.17016,-0.5553 -0.39687,-0.5553 -0.29399,0 
					-0.39688,-0.30868 -0.39688,-1.19063 0,-0.65484 -0.11906,-1.19062 
					-0.26458,-1.19062 -0.14552,0 -0.26459,-0.41672 -0.26459,-0.92604 0,-0.50933 
					0.11907,-0.92605 0.26459,-0.92605 0.14699,0 0.26458,-0.70555 0.26458,-1.5875 
					0,-1.23472 0.0882,-1.5875 0.39688,-1.5875 0.27718,0 0.39687,-0.27928 
					0.39687,-0.92604 0,-0.50932 0.11906,-0.92604 0.26458,-0.92604 0.14553,0 
					0.26459,-0.35719 0.26459,-0.79375 0,-0.43656 0.11906,-0.79375 0.26458,-0.79375 
					0.14552,0 0.26458,-0.29765 0.26458,-0.66146 0,-0.3638 0.11907,-0.66146 
					0.26459,-0.66146 0.14552,0 0.26458,-0.41671 0.26458,-0.92604 0,-0.50932 
					0.12757,-0.92604 0.28348,-0.92604 0.46692,0 1.03944,-0.71825 1.03944,-1.30402 
					0,-0.30143 0.11906,-0.54806 0.26458,-0.54806 0.14552,0 0.26458,-0.29766 
					0.26458,-0.66146 0,-0.3638 0.12448,-0.66146 0.27661,-0.66146 0.48214,0 
					1.57548,-1.27695 1.57548,-1.84006 0,-0.29765 0.12447,-0.54119 0.27661,-0.54119 
					0.35915,0 1.57547,-1.21632 1.57547,-1.57547 0,-0.15214 0.12447,-0.27661 
					0.27661,-0.27661 0.35916,0 1.57547,-1.21632 1.57547,-1.57548 0,-0.15213 
					0.29766,-0.2766 0.66146,-0.2766 0.3638,0 0.66146,-0.11907 0.66146,-0.26459 
					0,-0.15434 0.88194,-0.26458 2.11667,-0.26458 1.23472,0 2.11666,0.11024 
					2.11666,0.26458 0,0.14552 0.29766,0.26459 0.66146,0.26459 0.41158,0 
					0.66146,0.14993 0.66146,0.39687 0,0.21828 0.11906,0.39688 0.26458,0.39688 
					0.14553,0 0.26459,0.23812 0.26459,0.52916 0,0.29104 0.11906,0.52917 
					0.26458,0.52917 0.14552,0 0.26458,0.29765 0.26458,0.66146 0,0.41157 
					0.14994,0.66146 0.39688,0.66146 0.32338,0 0.39687,0.44097 0.39687,2.38124 
					0,1.94028 -0.0735,2.38125 -0.39687,2.38125 -0.29986,0 -0.39688,0.32338 
					-0.39688,1.32292 0,0.72761 -0.11906,1.32292 -0.26458,1.32292 -0.14552,0 
					-0.26458,0.41672 -0.26458,0.92604 0,0.50932 -0.11906,0.92604 -0.26459,0.92604 
					-0.14552,0 -0.26458,0.23813 -0.26458,0.52917 0,0.29398 -0.17639,0.52916 
					-0.39687,0.52916 -0.27719,0 -0.39688,0.27929 -0.39688,0.92605 0,0.50932 
					-0.11906,0.92604 -0.26458,0.92604 -0.14552,0 -0.26459,0.29765 -0.26459,0.66146 
					0,0.3638 -0.11906,0.66145 -0.26458,0.66145 -0.14552,0 -0.26458,0.23813 
					-0.26458,0.52917 0,0.29398 -0.17639,0.52917 -0.39688,0.52917 -0.21828,0 
					-0.39687,0.17859 -0.39687,0.39687 0,0.21828 -0.11906,0.39688 -0.26459,0.39688 
					-0.14552,0 -0.26458,0.23812 -0.26458,0.52916 0,0.29105 -0.11906,0.52917 
					-0.26458,0.52917 -0.14552,0 -0.26459,0.17859 -0.26459,0.39688 0,0.21828 
					-0.17859,0.39687 -0.39687,0.39687 -0.22049,0 -0.39688,0.23519 -0.39688,0.52917 
					0,0.29104 -0.11906,0.52916 -0.26458,0.52916 -0.14552,0 -0.26458,0.1786 
					-0.26458,0.39688 0,0.21828 -0.11906,0.39687 -0.26459,0.39687 -0.14552,0 
					-0.26458,0.23813 -0.26458,0.52917 0,0.29104 -0.11906,0.52917 -0.26458,0.52917 
					-0.14552,0 -0.26459,0.17859 -0.26459,0.39687 0,0.21828 -0.13082,0.39688 
					-0.29072,0.39688 -0.15989,0 -0.62438,0.35718 -1.03219,0.79375 -0.40781,0.43656 
					-0.8723,0.79375 -1.0322,0.79375 -0.15989,0 -0.29072,0.17859 -0.29072,0.39687 
					0,0.22049 -0.23518,0.39688 -0.52917,0.39688 -0.29104,0 -0.52916,0.11906 
					-0.52916,0.26458 0,0.14699 -0.70556,0.26458 -1.5875,0.26458 -0.88195,0 
					-1.5875,-0.11759 -1.5875,-0.26458 z m -52.65209,-4.89479 c 0,-0.27718 
					-0.27928,-0.39688 -0.92604,-0.39688 -0.50932,0 -0.92604,-0.11906 
					-0.92604,-0.26458 0,-0.14552 -0.41672,-0.26458 -0.92604,-0.26458 -0.50932,0 
					-0.92604,-0.11906 -0.92604,-0.26459 0,-0.14552 -0.29766,-0.26458 
					-0.66146,-0.26458 -0.41158,0 -0.66146,-0.14993 -0.66146,-0.39687 0,-0.22049 
					-0.23519,-0.39688 -0.52917,-0.39688 -0.29104,0 -0.52916,-0.11906 
					-0.52916,-0.26458 0,-0.14552 -0.29766,-0.26459 -0.66146,-0.26459 -0.3638,0 
					-0.66146,-0.11906 -0.66146,-0.26458 0,-0.14552 -0.23813,-0.26458 
					-0.52917,-0.26458 -0.29398,0 -0.52916,-0.17639 -0.52916,-0.39688 0,-0.21828 
					-0.1786,-0.39687 -0.39688,-0.39687 -0.21828,0 -0.39687,-0.11906 
					-0.39687,-0.26459 0,-0.14552 -0.23813,-0.26458 -0.52917,-0.26458 -0.29104,0 
					-0.52917,-0.11906 -0.52917,-0.26458 0,-0.14552 -0.17859,-0.26459 
					-0.39687,-0.26459 -0.21828,0 -0.39688,-0.17859 -0.39688,-0.39687 0,-0.22049 
					-0.23518,-0.39688 -0.52916,-0.39688 -0.29105,0 -0.52917,-0.11906 
					-0.52917,-0.26458 0,-0.14552 -0.12757,-0.26458 -0.28348,-0.26458 -0.30836,0 
					-1.03944,-0.65133 -1.03944,-0.92604 0,-0.21714 -0.70365,-0.92605 
					-0.91917,-0.92605 -0.21252,0 -1.46208,-1.24956 -1.46208,-1.46207 0,-0.21553 
					-0.70891,-0.91918 -0.92604,-0.91918 -0.22076,0 -0.92604,-0.70527 
					-0.92604,-0.92604 0,-0.22076 -0.70528,-0.92604 -0.92604,-0.92604 -0.22077,0 
					-0.92604,-0.70527 -0.92604,-0.92604 0,-0.27472 -0.73108,-0.92604 
					-1.03944,-0.92604 -0.15591,0 -0.28348,-0.24354 -0.28348,-0.54119 0,-0.56311 
					-1.09334,-1.84006 -1.57548,-1.84006 -0.15213,0 -0.2766,-0.1786 -0.2766,-0.39688 
					0,-0.21828 -0.11907,-0.39687 -0.26459,-0.39687 -0.14552,0 -0.26458,-0.1871 
					-0.26458,-0.41578 0,-0.47518 -0.6179,-1.17172 -1.03943,-1.17172 -0.15592,0 
					-0.28349,-0.1786 -0.28349,-0.39688 0,-0.21828 -0.11906,-0.39687 
					-0.26458,-0.39687 -0.14552,0 -0.26458,-0.23813 -0.26458,-0.52917 0,-0.29104 
					-0.11907,-0.52916 -0.26459,-0.52916 -0.14552,0 -0.26458,-0.1786 
					-0.26458,-0.39688 0,-0.21828 -0.12447,-0.39687 -0.27661,-0.39687 -0.43338,0 
					-1.57547,-1.2517 -1.57547,-1.72667 0,-0.48518 -0.61341,-1.18375 
					-1.03944,-1.18375 -0.15591,0 -0.28348,-0.15231 -0.28348,-0.33847 0,-0.18615 
					-0.23813,-0.51009 -0.52917,-0.71986 -0.29104,-0.20978 -0.52916,-0.65278 
					-0.52916,-0.98446 0,-0.33167 -0.12448,-0.60304 -0.27661,-0.60304 -0.35916,0 
					-1.57548,-1.21632 -1.57548,-1.57548 0,-0.15213 -0.17859,-0.27661 
					-0.39687,-0.27661 -0.21828,0 -0.39688,-0.21184 -0.39688,-0.47075 0,-0.25892 
					-0.23812,-0.64239 -0.52916,-0.85216 -0.29105,-0.209777 -0.52917,-0.533717 
					-0.52917,-0.719872 0,-0.186156 -0.12447,-0.338466 -0.27661,-0.338466 -0.35916,0 
					-1.57547,-1.216318 -1.57547,-1.575474 0,-0.152136 -0.12448,-0.276609 
					-0.27661,-0.276609 -0.35916,0 -1.57548,-1.216318 -1.57548,-1.575474 0,-0.152136 
					-0.12447,-0.276609 -0.27661,-0.276609 -0.29502,0 -1.57547,-1.188291 
					-1.57547,-1.46208 0,-0.215521 -0.70891,-0.91917 -0.92604,-0.91917 -0.22077,0 
					-0.92604,-0.705276 -0.92604,-0.926042 0,-0.220765 -0.70528,-0.926041 
					-0.92605,-0.926041 -0.22076,0 -0.92604,-0.705276 -0.92604,-0.926042 0,-0.217133 
					-0.70365,-0.926041 -0.91917,-0.926041 -0.27379,0 -1.46208,-1.280451 
					-1.46208,-1.575475 0,-0.152135 -0.12447,-0.276609 -0.27661,-0.276609 -0.35915,0 
					-1.57547,-1.216318 -1.57547,-1.575474 0,-0.152136 -0.12447,-0.276609 
					-0.27661,-0.276609 -0.48214,0 -1.57547,-1.276953 -1.57547,-1.840058 0,-0.297656 
					-0.12757,-0.541192 -0.28349,-0.541192 -0.44791,0 -1.03943,-0.708845 
					-1.03943,-1.245608 0,-0.269311 -0.23813,-0.661286 -0.52917,-0.871058 
					-0.29104,-0.209773 -0.52916,-0.652775 -0.52916,-0.984451 0,-0.359654 
					-0.16019,-0.603049 -0.39688,-0.603049 -0.22048,0 -0.39687,-0.235186 
					-0.39687,-0.529167 0,-0.291042 -0.11907,-0.529167 -0.26459,-0.529167 -0.14552,0 
					-0.26458,-0.416718 -0.26458,-0.926041 0,-0.509323 -0.11906,-0.926042 
					-0.26458,-0.926042 -0.14553,0 -0.26459,-0.416719 -0.26459,-0.926041 0,-0.646759 
					-0.11969,-0.926042 -0.39687,-0.926042 -0.33243,0 -0.39688,-0.558565 
					-0.39688,-3.439583 0,-2.881019 0.0644,-3.439583 0.39688,-3.439583 0.27209,0 
					0.39687,-0.272794 0.39687,-0.867633 0,-0.56411 0.18512,-1.001059 
					0.52917,-1.249034 0.29104,-0.209772 0.52917,-0.652775 0.52917,-0.984451 
					0,-0.331676 0.15231,-0.603049 0.33846,-0.603049 0.18616,0 0.5101,-0.238125 
					0.71987,-0.529167 0.20977,-0.291041 0.59324,-0.529166 0.85216,-0.529166 
					0.25891,0 0.47076,-0.178594 0.47076,-0.396875 0,-0.246944 0.24988,-0.396875 
					0.66145,-0.396875 0.36381,0 0.66146,-0.119063 0.66146,-0.264583 0,-0.145521 
					0.29766,-0.264584 0.66146,-0.264584 0.3638,0 0.66146,-0.119062 0.66146,-0.264583 
					0,-0.165365 1.5875,-0.264583 4.23333,-0.264583 2.64584,0 4.23334,0.09922 
					4.23334,0.264583 0,0.145521 0.41671,0.264583 0.92604,0.264583 0.50932,0 
					0.92604,0.119063 0.92604,0.264584 0,0.14552 0.41672,0.264583 0.92604,0.264583 
					0.64676,0 0.92604,0.119692 0.92604,0.396875 0,0.246943 0.24989,0.396875 
					0.66146,0.396875 0.3638,0 0.66146,0.119062 0.66146,0.264583 0,0.145521 
					0.23812,0.264583 0.52917,0.264583 0.29104,0 0.52916,0.119063 0.52916,0.264584 
					0,0.145521 0.29766,0.264583 0.66146,0.264583 0.3638,0 0.66146,0.127566 
					0.66146,0.283483 0,0.466915 0.71825,1.039434 1.30402,1.039434 0.30143,0 
					0.54806,0.119062 0.54806,0.264583 0,0.145521 0.29766,0.264583 0.66146,0.264583 
					0.3638,0 0.66146,0.124474 0.66146,0.276609 0,0.433382 1.25169,1.575475 
					1.72666,1.575475 0.42227,0 1.18376,0.595706 1.18376,0.926041 0,0.220766 
					0.70527,0.926042 0.92604,0.926042 0.22076,0 0.92604,0.705276 0.92604,0.926041 
					0,0.332693 0.76288,0.926042 1.19062,0.926042 0.4909,0 1.19063,0.610878 
					1.19063,1.039434 0,0.155916 0.12447,0.283483 0.27661,0.283483 0.48213,0 
					1.57547,1.276953 1.57547,1.840058 0,0.297656 0.12757,0.541192 0.28349,0.541192 
					0.30835,0 1.03943,0.651324 1.03943,0.926041 0,0.220766 0.70528,0.926042 
					0.92604,0.926042 0.21713,0 0.92604,0.703649 0.92604,0.91917 0,0.212519 
					1.24956,1.46208 1.46208,1.46208 0.26923,0 0.91917,0.73498 0.91917,1.039434 
					0,0.155916 0.29766,0.283482 0.66146,0.283482 0.3638,0 0.66146,0.119063 
					0.66146,0.264584 0,0.14552 0.23812,0.264583 0.52917,0.264583 0.29104,0 
					0.52916,0.119062 0.52916,0.264583 0,0.145521 0.29766,0.264584 0.66146,0.264584 
					0.41158,0 0.66146,0.149931 0.66146,0.396875 0,0.293981 0.30868,0.396875 
					1.19062,0.396875 0.88195,0 1.19063,-0.102894 1.19063,-0.396875 0,-0.246944 
					0.24988,-0.396875 0.66146,-0.396875 0.48507,0 0.66146,-0.141111 
					0.66146,-0.529167 0,-0.291042 0.11906,-0.529167 0.26458,-0.529167 0.15782,0 
					0.26458,-1.014235 0.26458,-2.513541 0,-1.499307 -0.10676,-2.513542 
					-0.26458,-2.513542 -0.14552,0 -0.26458,-0.535781 -0.26458,-1.190625 0,-0.654843 
					-0.11907,-1.190625 -0.26459,-1.190625 -0.16105,0 -0.26458,-1.190625 
					-0.26458,-3.042708 0,-1.852083 0.10353,-3.042708 0.26458,-3.042708 0.14552,0 
					0.26459,-0.416719 0.26459,-0.926042 0,-0.509323 0.11906,-0.926041 
					0.26458,-0.926041 0.14552,0 0.26458,-0.297657 0.26458,-0.661459 0,-0.363802 
					0.11907,-0.661458 0.26459,-0.661458 0.14552,0 0.26458,-0.238125 
					0.26458,-0.529167 0,-0.293981 0.17639,-0.529166 0.39687,-0.529166 0.21829,0 
					0.39688,-0.124473 0.39688,-0.276609 0,-0.359156 1.21632,-1.575474 
					1.57547,-1.575474 0.15214,0 0.27661,-0.178594 0.27661,-0.396875 0,-0.220486 
					0.23519,-0.396875 0.52917,-0.396875 0.29104,0 0.52917,-0.119063 
					0.52917,-0.264584 0,-0.145521 0.29765,-0.264583 0.66145,-0.264583 0.36381,0 
					0.66146,-0.119063 0.66146,-0.264583 0,-0.145521 0.29766,-0.264584 
					0.66146,-0.264584 0.41158,0 0.66146,-0.149931 0.66146,-0.396875 0,-0.304673 
					0.33808,-0.396875 1.45521,-0.396875 0.80036,0 1.45521,-0.119062 
					1.45521,-0.264583 0,-0.151191 0.79375,-0.264583 1.85208,-0.264583 1.05833,0 
					1.85208,0.113392 1.85208,0.264583 0,0.146992 0.70556,0.264583 1.5875,0.264583 
					1.23473,0 1.5875,0.08819 1.5875,0.396875 0,0.293982 0.30868,0.396875 
					1.19063,0.396875 0.65484,0 1.19062,0.119063 1.19062,0.264584 0,0.164285 
					1.46151,0.264583 3.85536,0.264583 3.86555,0 4.61131,-0.1681 4.61131,-1.039434 
					0,-0.155916 0.17859,-0.283483 0.39688,-0.283483 0.31207,0 0.39687,-0.367477 
					0.39687,-1.719791 0,-1.352315 -0.0848,-1.719792 -0.39687,-1.719792 -0.21829,0 
					-0.39688,-0.124473 -0.39688,-0.276608 0,-0.359156 -1.21632,-1.575475 
					-1.57547,-1.575475 -0.15214,0 -0.27661,-0.178594 -0.27661,-0.396875 0,-0.220485 
					-0.23519,-0.396875 -0.52917,-0.396875 -0.29104,0 -0.52917,-0.119062 
					-0.52917,-0.264583 0,-0.145521 -0.12756,-0.264584 -0.28348,-0.264584 -0.33789,0 
					-1.03943,-0.662122 -1.03943,-0.981024 0,-0.123791 -0.23813,-0.396703 
					-0.52917,-0.606476 -0.29104,-0.209772 -0.52917,-0.652774 -0.52917,-0.984451 
					0,-0.331676 -0.12756,-0.603048 -0.28348,-0.603048 -0.5372,0 -1.03943,-0.757923 
					-1.03943,-1.568601 0,-0.446958 -0.11907,-0.812649 -0.26459,-0.812649 -0.14552,0 
					-0.26458,-0.416719 -0.26458,-0.926042 0,-0.646758 -0.11969,-0.926042 
					-0.39687,-0.926042 -0.31971,0 -0.39688,-0.411575 -0.39688,-2.116666 0,-1.705091 
					0.0772,-2.116667 0.39688,-2.116667 0.27718,0 0.39687,-0.279283 0.39687,-0.926041 
					0,-0.509323 0.11906,-0.926042 0.26458,-0.926042 0.14552,0 0.26459,-0.297656 
					0.26459,-0.661458 0,-0.363802 0.11906,-0.661459 0.26458,-0.661459 0.14552,0 
					0.26458,-0.238125 0.26458,-0.529166 0,-0.293981 0.17639,-0.529167 
					0.39688,-0.529167 0.21828,0 0.39687,-0.123031 0.39687,-0.273402 0,-0.355716 
					1.75214,-2.107848 2.10785,-2.107848 0.15037,0 0.2734,-0.127566 0.2734,-0.283482 
					0,-0.428556 0.69974,-1.039434 1.19063,-1.039434 0.49089,0 1.19062,-0.610878 
					1.19062,-1.039434 0,-0.155917 0.29766,-0.283483 0.66146,-0.283483 0.3638,0 
					0.66146,-0.119062 0.66146,-0.264583 0,-0.145521 0.41672,-0.264584 
					0.92604,-0.264584 0.50932,0 0.92604,-0.119062 0.92604,-0.264583 0,-0.145521 
					0.41672,-0.264583 0.92605,-0.264583 0.64675,0 0.92604,-0.119692 
					0.92604,-0.396875 0,-0.264584 0.26458,-0.396875 0.79375,-0.396875 0.43656,0 
					0.79375,-0.119063 0.79375,-0.264583 0,-0.157822 1.01423,-0.264584 
					2.51354,-0.264584 1.49931,0 2.51354,-0.106762 2.51354,-0.264583 0,-0.161052 
					1.19063,-0.264583 3.04271,-0.264583 1.85208,0 3.04271,0.103531 3.04271,0.264583 
					0,0.167568 1.94027,0.264583 5.29166,0.264583 3.35139,0 5.29167,0.09701 
					5.29167,0.264584 0,0.149251 0.74965,0.264583 1.71979,0.264583 0.97014,0 
					1.71979,-0.115332 1.71979,-0.264583 0,-0.159589 1.10244,-0.264584 
					2.77813,-0.264584 1.67569,0 2.77812,-0.104994 2.77812,-0.264583 0,-0.145521 
					0.29766,-0.264583 0.66146,-0.264583 0.41158,0 0.66146,-0.149932 
					0.66146,-0.396875 0,-0.220486 0.23519,-0.396875 0.52917,-0.396875 0.35277,0 
					0.52916,-0.17639 0.52916,-0.529167 0,-0.293981 0.17639,-0.529167 
					0.39688,-0.529167 0.30868,0 0.39687,-0.352777 0.39687,-1.5875 0,-1.234722 
					-0.0882,-1.5875 -0.39687,-1.5875 -0.22049,0 -0.39688,-0.235185 
					-0.39688,-0.529166 0,-0.352778 -0.17639,-0.529167 -0.52916,-0.529167 -0.29104,0 
					-0.52917,-0.127567 -0.52917,-0.283482 0,-0.537197 -0.75792,-1.039434 
					-1.5686,-1.039434 -0.44696,0 -0.81265,-0.119063 -0.81265,-0.264584 0,-0.145521 
					-0.41672,-0.264583 -0.92604,-0.264583 -0.64676,0 -0.92604,-0.119693 
					-0.92604,-0.396875 0,-0.246944 -0.24989,-0.396875 -0.66146,-0.396875 -0.3638,0 
					-0.66146,-0.119063 -0.66146,-0.264583 0,-0.145521 -0.29766,-0.264584 
					-0.66146,-0.264584 -0.3638,0 -0.66146,-0.119062 -0.66146,-0.264583 0,-0.145521 
					-0.23812,-0.264583 -0.52916,-0.264583 -0.29398,0 -0.52917,-0.176389 
					-0.52917,-0.396875 0,-0.236694 -0.24339,-0.396875 -0.60305,-0.396875 -0.33167,0 
					-0.77468,-0.238125 -0.98445,-0.529167 -0.20977,-0.291042 -0.65277,-0.529167 
					-0.98445,-0.529167 -0.33168,0 -0.60305,-0.124474 -0.60305,-0.276609 0,-0.359156 
					-1.21632,-1.575474 -1.57547,-1.575474 -0.15214,0 -0.27661,-0.124474 
					-0.27661,-0.27661 0,-0.359155 -1.21632,-1.575473 -1.57548,-1.575473 -0.15213,0 
					-0.27661,-0.271372 -0.27661,-0.603049 0,-0.331677 -0.23812,-0.77468 
					-0.52916,-0.984451 -0.29104,-0.209771 -0.52917,-0.652774 -0.52917,-0.984451 
					0,-0.3596544 -0.16018,-0.6030489 -0.39687,-0.6030489 -0.22049,0 
					-0.39688,-0.2351852 -0.39688,-0.5291666 0,-0.2910417 -0.11906,-0.5291667 
					-0.26458,-0.5291667 -0.14552,0 -0.26459,-0.2976562 -0.26459,-0.6614583 
					0,-0.363802 -0.11906,-0.6614583 -0.26458,-0.6614583 -0.14552,0 
					-0.26458,-0.5357812 -0.26458,-1.1906249 0,-0.8819443 -0.1029,-1.1906249 
					-0.39688,-1.1906249 -0.32165,0 -0.39687,-0.4262731 -0.39687,-2.2489583 V 
					0.37519392 h 33.73437 33.73438 V 12.240023 c 0,13.254209 0.0738,12.633712 
					-1.98869,16.71017 -0.55221,1.091406 -1.44899,3.055937 -1.99284,4.365624 
					-3.03104,7.299137 -3.58487,8.335494 -5.70881,10.682525 -1.4646,1.618443 
					-3.53012,2.943516 -4.58838,2.943516 -0.29585,0 -0.53795,0.127566 
					-0.53795,0.283483 0,0.421539 -0.69654,1.039434 -1.17173,1.039434 -0.22868,0 
					-0.41577,0.119062 -0.41577,0.264583 0,0.145521 -0.29765,0.264584 
					-0.66146,0.264584 -0.41158,0 -0.66145,0.149931 -0.66145,0.396875 0,0.236693 
					-0.2434,0.396874 -0.60304,0.396874 -0.33168,0 -0.7747,0.238125 -0.98446,0.529167 
					-0.20977,0.291042 -0.65278,0.529167 -0.98447,0.529167 -0.35964,0 
					-0.60303,0.160181 -0.60303,0.396875 0,0.220485 -0.23519,0.396875 
					-0.52917,0.396875 -0.29104,0 -0.52917,0.119062 -0.52917,0.264583 0,0.145521 
					-0.17859,0.264583 -0.39687,0.264583 -0.21828,0 -0.39688,0.119063 
					-0.39688,0.264584 0,0.145521 -0.41672,0.264583 -0.92604,0.264583 -0.64675,0 
					-0.92604,0.119692 -0.92604,0.396875 0,0.218281 -0.21185,0.396875 
					-0.47075,0.396875 -0.25892,0 -0.64241,0.238125 -0.85217,0.529167 
					-0.24797,0.344048 -0.68493,0.529166 -1.24904,0.529166 -0.59484,0 
					-0.86763,0.124783 -0.86763,0.396875 0,0.246944 -0.24987,0.396875 
					-0.66145,0.396875 -0.41159,0 -0.66146,-0.149931 -0.66146,-0.396875 0,-0.277183 
					-0.2793,-0.396875 -0.92604,-0.396875 -0.50933,0 -0.92605,-0.119062 
					-0.92605,-0.264583 0,-0.145521 -0.29765,-0.264583 -0.66145,-0.264583 -0.36381,0 
					-0.66146,-0.119063 -0.66146,-0.264584 0,-0.145521 -0.41672,-0.264583 
					-0.92604,-0.264583 -0.64676,0 -0.92605,-0.119692 -0.92605,-0.396875 0,-0.277183 
					-0.27928,-0.396875 -0.92604,-0.396875 -0.50932,0 -0.92604,-0.119062 
					-0.92604,-0.264583 0,-0.145521 -0.23812,-0.264584 -0.52917,-0.264584 -0.29104,0 
					-0.52916,-0.119062 -0.52916,-0.264583 0,-0.145521 -0.29766,-0.264583 
					-0.66146,-0.264583 -0.41158,0 -0.66146,-0.149932 -0.66146,-0.396875 0,-0.277183 
					-0.27928,-0.396875 -0.92604,-0.396875 -0.50932,0 -0.92604,-0.119063 
					-0.92604,-0.264584 0,-0.14552 -0.23813,-0.264583 -0.52917,-0.264583 -0.29104,0 
					-0.52917,-0.119062 -0.52917,-0.264583 0,-0.145521 -0.41671,-0.264584 
					-0.92604,-0.264584 -0.64676,0 -0.92604,-0.119692 -0.92604,-0.396874 0,-0.246944 
					-0.24988,-0.396875 -0.66146,-0.396875 -0.3638,0 -0.66146,-0.119063 
					-0.66146,-0.264584 0,-0.145521 -0.41671,-0.264583 -0.92604,-0.264583 -0.50932,0 
					-0.92604,-0.119063 -0.92604,-0.264583 0,-0.145521 -0.41672,-0.264584 
					-0.92604,-0.264584 -0.64676,0 -0.92604,-0.119692 -0.92604,-0.396875 0,-0.304673 
					-0.33808,-0.396875 -1.45521,-0.396875 -0.80037,0 -1.45521,-0.119062 
					-1.45521,-0.264583 0,-0.157821 -1.01423,-0.264583 -2.51354,-0.264583 -1.49931,0 
					-2.51354,0.106762 -2.51354,0.264583 0,0.145521 -0.29766,0.264583 
					-0.66146,0.264583 -0.41158,0 -0.66146,0.149932 -0.66146,0.396875 0,0.218282 
					-0.1871,0.396875 -0.41577,0.396875 -0.58364,0 -1.17173,0.654484 
					-1.17173,1.304018 0,0.301437 -0.12757,0.548065 -0.28348,0.548065 -0.88258,0 
					-1.03944,0.735791 -1.03944,4.875893 0,2.570239 0.0995,4.11994 0.26459,4.11994 
					0.14552,0 0.26458,0.595313 0.26458,1.322917 0,0.999538 0.097,1.322917 
					0.39688,1.322917 0.29398,0 0.39687,0.308681 0.39687,1.190625 0,0.654843 
					0.11906,1.190624 0.26458,1.190624 0.147,0 0.26459,0.705557 0.26459,1.5875 
					0,0.881944 0.11759,1.5875 0.26458,1.5875 0.14552,0 0.26458,0.535782 
					0.26458,1.190625 0,0.654844 0.11907,1.190625 0.26459,1.190625 0.14552,0 
					0.26458,0.535781 0.26458,1.190625 0,0.881944 0.10289,1.190625 0.39688,1.190625 
					0.30868,0 0.39687,0.352777 0.39687,1.5875 0,0.881944 0.11759,1.5875 
					0.26458,1.5875 0.14553,0 0.26459,0.535781 0.26459,1.190625 0,0.654844 
					0.11906,1.190625 0.26458,1.190625 0.14552,0 0.26458,0.654844 0.26458,1.455208 
					0,1.117129 0.0922,1.455208 0.39688,1.455208 0.32493,0 0.39687,0.455671 
					0.39687,2.513542 0,2.057871 -0.0719,2.513541 -0.39687,2.513541 -0.27209,0 
					-0.39688,0.272794 -0.39688,0.867633 0,0.56411 -0.18511,1.001059 
					-0.52916,1.249034 -0.29104,0.209772 -0.52917,0.533712 -0.52917,0.719868 
					0,0.186155 -0.15231,0.338465 -0.33846,0.338465 -0.18616,0 -0.5101,0.238125 
					-0.71987,0.529167 -0.20977,0.291042 -0.59325,0.529167 -0.85216,0.529167 
					-0.25892,0 -0.47076,0.119062 -0.47076,0.264583 0,0.145521 -0.29766,0.264583 
					-0.66146,0.264583 -0.41157,0 -0.66146,0.149932 -0.66146,0.396875 0,0.333878 
					-0.58796,0.396875 -3.70416,0.396875 -3.11621,0 -3.70417,-0.063 
					-3.70417,-0.396875 0,-0.293981 -0.30868,-0.396875 -1.19062,-0.396875 -0.65485,0 
					-1.19063,-0.119062 -1.19063,-0.264583 0,-0.145521 -0.41672,-0.264583 
					-0.92604,-0.264583 -0.50932,0 -0.92604,-0.119063 -0.92604,-0.264584 0,-0.145521 
					-0.41672,-0.264583 -0.92604,-0.264583 -0.50933,0 -0.92605,-0.119063 
					-0.92605,-0.264583 0,-0.145521 -0.41671,-0.264584 -0.92604,-0.264584 -0.64676,0 
					-0.92604,-0.119692 -0.92604,-0.396875 0,-0.246943 -0.24988,-0.396875 
					-0.66146,-0.396875 -0.3638,0 -0.66146,-0.119062 -0.66146,-0.264583 0,-0.145521 
					-0.23812,-0.264583 -0.52916,-0.264583 -0.29104,0 -0.52917,-0.119063 
					-0.52917,-0.264584 0,-0.14552 -0.29766,-0.264583 -0.66146,-0.264583 -0.41157,0 
					-0.66146,-0.149931 -0.66146,-0.396875 0,-0.246944 -0.24988,-0.396875 
					-0.66145,-0.396875 -0.36381,0 -0.66146,-0.119062 -0.66146,-0.264583 0,-0.145521 
					-0.1871,-0.264584 -0.41578,-0.264584 -0.47519,0 -1.17172,-0.617894 
					-1.17172,-1.039434 0,-0.155916 -0.27138,-0.283482 -0.60305,-0.283482 -0.33168,0 
					-0.77468,-0.238125 -0.98445,-0.529167 -0.20978,-0.291041 -0.65278,-0.529166 
					-0.98445,-0.529166 -0.35966,0 -0.60305,-0.160182 -0.60305,-0.396875 0,-0.246944 
					-0.24989,-0.396875 -0.66146,-0.396875 -0.3638,0 -0.66146,-0.119063 
					-0.66146,-0.264584 0,-0.145521 -0.65484,-0.264583 -1.45521,-0.264583 -0.80036,0 
					-1.45521,0.119062 -1.45521,0.264583 0,0.145521 -0.17859,0.264584 
					-0.39687,0.264584 -0.21828,0 -0.39688,0.15231 -0.39688,0.338465 0,0.186156 
					-0.23812,0.510096 -0.52916,0.719868 -0.49404,0.356081 -0.52917,0.684924 
					-0.52917,4.953201 0,2.871477 0.0984,4.571798 0.26458,4.571798 0.14552,0 
					0.26459,0.535782 0.26459,1.190625 0,0.654844 0.11906,1.190625 0.26458,1.190625 
					0.14552,0 0.26458,0.535781 0.26458,1.190625 0,0.881944 0.1029,1.190625 
					0.39688,1.190625 0.27718,0 0.39687,0.279284 0.39687,0.926042 0,0.509323 
					0.11907,0.926041 0.26459,0.926041 0.14552,0 0.26458,0.535782 0.26458,1.190628 
					0,0.65484 0.11906,1.19062 0.26458,1.19062 0.14552,0 0.26459,0.41672 
					0.26459,0.92604 0,0.64676 0.11969,0.92605 0.39687,0.92605 0.27718,0 
					0.39688,0.27928 0.39688,0.92604 0,0.50932 0.11906,0.92604 0.26458,0.92604 
					0.14552,0 0.26458,0.59531 0.26458,1.32292 0,0.7276 0.11907,1.32291 
					0.26459,1.32291 0.14552,0 0.26458,0.65485 0.26458,1.45521 0,1.11713 
					0.0922,1.45521 0.39688,1.45521 0.33387,0 0.39687,0.58796 0.39687,3.70416 
					0,3.11621 -0.063,3.70417 -0.39687,3.70417 -0.29399,0 -0.39688,0.30868 
					-0.39688,1.19063 0,0.65484 -0.11906,1.19062 -0.26458,1.19062 -0.14552,0 
					-0.26459,0.29766 -0.26459,0.66146 0,0.3638 -0.11906,0.66146 -0.26458,0.66146 
					-0.14552,0 -0.26458,0.23812 -0.26458,0.52916 0,0.29399 -0.17639,0.52917 
					-0.39688,0.52917 -0.23669,0 -0.39687,0.2434 -0.39687,0.60305 0,0.33168 
					-0.23813,0.77468 -0.52917,0.98445 -0.29104,0.20977 -0.52917,0.53371 
					-0.52917,0.71987 0,0.18615 -0.12447,0.33846 -0.27661,0.33846 -0.29502,0 
					-1.57547,1.1883 -1.57547,1.46208 0,0.32694 -0.76713,0.91917 -1.19062,0.91917 
					-0.4909,0 -1.19063,0.61088 -1.19063,1.03944 0,0.15591 -0.41672,0.28348 
					-0.92604,0.28348 -0.50932,0 -0.92604,0.11906 -0.92604,0.26458 0,0.14553 
					-0.29766,0.26459 -0.66146,0.26459 -0.3638,0 -0.66146,0.11906 -0.66146,0.26458 
					0,0.14552 -0.53578,0.26458 -1.19062,0.26458 -0.88195,0 -1.19063,0.1029 
					-1.19063,0.39688 0,0.33766 -0.69086,0.39687 -4.63021,0.39687 -3.93935,0 
					-4.63021,-0.0592 -4.63021,-0.39687 z M 19.906996,124.20019 c -0.209771,-0.29104 
					-0.533711,-0.52917 -0.719867,-0.52917 -0.186157,0 -0.338466,-0.17859 
					-0.338466,-0.39687 0,-0.21829 -0.187099,-0.39688 -0.415774,-0.39688 -0.534734,0 
					-1.171726,-0.63699 -1.171726,-1.17172 0,-0.22868 -0.178594,-0.41578 
					-0.396875,-0.41578 -0.218282,0 -0.396875,-0.15231 -0.396875,-0.33846 0,-0.18616 
					-0.238125,-0.5101 -0.529167,-0.71987 -0.291042,-0.20977 -0.529167,-0.65278 
					-0.529167,-0.98445 0,-0.34389 -0.161081,-0.60305 -0.374826,-0.60305 -0.206155,0 
					-0.414514,-0.0893 -0.463021,-0.19844 -0.04851,-0.10914 -0.768921,-0.52266 
					-1.60092,-0.91893 -2.1166,-1.00812 -3.4481033,-2.30541 -4.2886856,-4.17847 
					-0.6307696,-1.40554 -0.6807629,-1.70803 -0.4152549,-2.51253 0.3428008,-1.03869 
					1.211578,-1.81646 2.5063415,-2.24377 0.704563,-0.23252 1.087579,-0.20879 
					1.86366,0.11548 0.537261,0.22448 1.083252,0.34238 1.213314,0.26199 
					0.130061,-0.0804 0.236476,0.0395 0.236476,0.26634 0,0.26361 0.238755,0.4125 
					0.661458,0.4125 0.363802,0 0.661458,0.11906 0.661458,0.26458 0,0.14552 
					0.416719,0.26458 0.926042,0.26458 0.921142,0 0.926042,-0.006 0.926042,-1.19062 
					0,-0.88195 -0.102894,-1.19063 -0.396875,-1.19063 -0.246945,0 -0.396875,-0.24988 
					-0.396875,-0.66146 0,-0.3638 -0.119063,-0.66145 -0.264584,-0.66145 -0.15119,0 
					-0.264583,-0.79375 -0.264583,-1.85209 0,-1.05833 0.113393,-1.85208 
					0.264583,-1.85208 0.145521,0 0.264584,-0.29766 0.264584,-0.66146 0,-0.41157 
					0.14993,-0.66146 0.396875,-0.66146 0.218281,0 0.396875,-0.11906 
					0.396875,-0.26458 0,-0.14552 0.357187,-0.26458 0.79375,-0.26458 0.436562,0 
					0.79375,-0.11907 0.79375,-0.26459 0,-0.14699 0.705555,-0.26458 1.5875,-0.26458 
					0.881944,0 1.5875,0.11759 1.5875,0.26458 0,0.14552 0.416718,0.26459 
					0.926041,0.26459 0.509323,0 0.926042,0.11906 0.926042,0.26458 0,0.14552 
					0.297656,0.26458 0.661458,0.26458 0.411575,0 0.661459,0.14993 0.661459,0.39688 
					0,0.22048 0.235185,0.39687 0.529166,0.39687 0.291042,0 0.529167,0.11906 
					0.529167,0.26459 0,0.14552 0.297656,0.26458 0.661459,0.26458 0.363802,0 
					0.661458,0.11906 0.661458,0.26458 0,0.14552 0.238125,0.26459 0.529167,0.26459 
					0.293981,0 0.529166,0.17639 0.529166,0.39687 0,0.24694 0.249883,0.39688 
					0.661459,0.39688 0.363802,0 0.661458,0.11906 0.661458,0.26458 0,0.14552 
					0.297656,0.26458 0.661458,0.26458 0.363802,0 0.661459,0.11906 0.661459,0.26459 
					0,0.14552 0.238125,0.26458 0.529166,0.26458 0.291042,0 0.529167,-0.11906 
					0.529167,-0.26458 0,-0.14553 0.178594,-0.26459 0.396875,-0.26459 0.277183,0 
					0.396875,-0.27928 0.396875,-0.92604 0,-0.50932 -0.127566,-0.92604 
					-0.283483,-0.92604 -0.36631,0 -1.039434,-0.67312 -1.039434,-1.03944 0,-0.15591 
					-0.119062,-0.28348 -0.264583,-0.28348 -0.145521,0 -0.264583,-0.23812 
					-0.264583,-0.52916 0,-0.29105 -0.127567,-0.52917 -0.283483,-0.52917 -0.466915,0 
					-1.039434,-0.718249 -1.039434,-1.304018 0,-0.301438 -0.119063,-0.548066 
					-0.264583,-0.548066 -0.145521,0 -0.264584,-0.416719 -0.264584,-0.926042 
					0,-0.646758 -0.119692,-0.926041 -0.396875,-0.926041 -0.308681,0 
					-0.396875,-0.352777 -0.396875,-1.5875 0,-1.234723 0.08819,-1.5875 
					0.396875,-1.5875 0.265901,0 0.396875,-0.26592 0.396875,-0.805776 0,-0.793498 
					0.981483,-2.104641 1.575475,-2.104641 0.152135,0 0.276609,-0.178594 
					0.276609,-0.396875 0,-0.220485 0.235185,-0.396875 0.529166,-0.396875 0.291042,0 
					0.529167,-0.119062 0.529167,-0.264583 0,-0.145521 0.595312,-0.264583 
					1.322917,-0.264583 0.727604,0 1.322916,0.119062 1.322916,0.264583 0,0.145521 
					0.416719,0.264583 0.926042,0.264583 0.646758,0 0.926042,0.119692 
					0.926042,0.396875 0,0.220485 0.235185,0.396875 0.529166,0.396875 0.291042,0 
					0.529167,0.119063 0.529167,0.264583 0,0.145521 0.127566,0.264584 
					0.283483,0.264584 0.304453,0 1.039434,0.649941 1.039434,0.91917 0,0.273788 
					1.280451,1.46208 1.575475,1.46208 0.152135,0 0.276608,0.15231 0.276608,0.338465 
					0,0.186156 0.238125,0.510096 0.529167,0.719868 0.291042,0.209772 
					0.529167,0.652775 0.529167,0.984451 0,0.331676 0.127566,0.603049 
					0.283482,0.603049 0.466916,0 1.039434,0.718248 1.039434,1.304017 0,0.301437 
					0.119063,0.548066 0.264584,0.548066 0.14552,0 0.264583,0.238125 
					0.264583,0.529167 0,0.293981 0.17639,0.529166 0.396875,0.529166 0.246943,0 
					0.396875,0.249883 0.396875,0.661459 0,0.363799 0.119062,0.661459 
					0.264583,0.661459 0.145521,0 0.264584,0.23812 0.264584,0.52917 0,0.29104 
					0.119062,0.52916 0.264583,0.52916 0.145521,0 0.264583,0.41672 0.264583,0.92604 
					0,0.64676 0.119692,0.92605 0.396875,0.92605 0.277183,0 0.396875,0.27928 
					0.396875,0.92604 0,0.50932 0.119063,0.92604 0.264584,0.92604 0.14552,0 
					0.264583,0.59531 0.264583,1.32292 0,0.7276 0.119062,1.32291 0.264583,1.32291 
					0.166854,0 0.264584,1.80799 0.264584,4.89479 0,3.08681 -0.09773,4.89479 
					-0.264584,4.89479 -0.145521,0 -0.264583,0.3657 -0.264583,0.81265 0,0.94175 
					-0.521121,1.5686 -1.304018,1.5686 -0.301437,0 -0.548065,0.11907 
					-0.548065,0.26459 0,0.14552 -0.297657,0.26458 -0.661459,0.26458 -0.411575,0 
					-0.661458,0.14993 -0.661458,0.39688 0,0.27718 -0.279284,0.39687 
					-0.926042,0.39687 -0.646758,0 -0.926041,-0.11969 -0.926041,-0.39687 0,-0.29398 
					-0.308682,-0.39688 -1.190626,-0.39688 -0.654843,0 -1.190625,-0.11906 
					-1.190625,-0.26458 0,-0.14552 -0.238125,-0.26459 -0.529166,-0.26459 -0.291042,0 
					-0.529167,-0.11906 -0.529167,-0.26458 0,-0.14552 -0.178594,-0.26458 
					-0.396875,-0.26458 -0.218281,0 -0.396875,-0.1786 -0.396875,-0.39688 0,-0.22048 
					-0.235185,-0.39687 -0.529167,-0.39687 -0.291041,0 -0.529166,-0.11906 
					-0.529166,-0.26459 0,-0.14552 -0.127566,-0.26458 -0.283483,-0.26458 -0.308356,0 
					-1.039434,-0.65132 -1.039434,-0.92604 0,-0.22077 -0.705276,-0.92604 
					-0.926042,-0.92604 -0.220765,0 -0.926041,-0.70528 -0.926041,-0.92604 0,-0.22077 
					-0.705276,-0.92605 -0.926042,-0.92605 -0.220766,0 -0.926042,-0.70527 
					-0.926042,-0.92604 0,-0.21713 -0.703649,-0.92604 -0.91917,-0.92604 -0.273788,0 
					-1.46208,-1.28045 -1.46208,-1.57547 0,-0.16169 -0.65954,-0.27661 
					-1.5875,-0.27661 -1.234722,0 -1.5875,0.0882 -1.5875,0.39687 0,0.21828 
					-0.119062,0.39688 -0.264583,0.39688 -0.145521,0 -0.264584,0.35719 
					-0.264584,0.79375 0,0.43656 -0.119062,0.79375 -0.264583,0.79375 -0.16282,0 
					-0.264583,1.32291 -0.264583,3.43958 0,2.88102 -0.06445,3.43958 -0.396875,3.43958 
					-0.31498,0 -0.396875,0.38218 -0.396875,1.85209 0,1.05833 -0.113393,1.85208 
					-0.264584,1.85208 -0.14552,0 -0.264583,0.23813 -0.264583,0.52917 0,0.47713 
					-0.176389,0.52916 -1.793674,0.52916 -1.490151,0 -1.858214,-0.0895 
					-2.175076,-0.52916 z M 32.342413,82.263732 c 0,-0.265901 -0.26592,-0.396875 
					-0.805775,-0.396875 -0.793499,0 -2.104642,-0.981483 -2.104642,-1.575475 
					0,-0.152135 -0.178594,-0.276609 -0.396875,-0.276609 -0.218281,0 
					-0.396875,-0.119062 -0.396875,-0.264583 0,-0.145521 -0.211841,-0.264583 
					-0.470757,-0.264583 -0.258916,0 -0.642387,-0.238125 -0.852159,-0.529167 
					-0.209773,-0.291042 -0.533712,-0.529167 -0.719868,-0.529167 -0.186156,0 
					-0.338466,-0.178593 -0.338466,-0.396875 0,-0.220485 -0.235185,-0.396875 
					-0.529167,-0.396875 -0.291041,0 -0.529166,-0.119062 -0.529166,-0.264583 
					0,-0.145521 -0.127567,-0.264583 -0.283482,-0.264583 -0.308357,0 
					-1.039435,-0.651325 -1.039435,-0.926042 0,-0.332692 -0.762878,-0.926041 
					-1.190625,-0.926041 -0.490892,0 -1.190625,-0.610878 -1.190625,-1.039435 
					0,-0.155916 -0.271372,-0.283482 -0.603049,-0.283482 -0.331677,0 
					-0.77468,-0.238125 -0.984451,-0.529167 -0.209771,-0.291041 -0.533711,-0.529166 
					-0.719867,-0.529166 -0.186157,0 -0.338466,-0.132771 -0.338466,-0.295045 
					0,-0.599948 -1.955229,-2.056492 -4.31639,-3.215489 C 11.185714,67.717806 
					9.8377852,66.241759 6.9860911,61.097066 5.3658068,58.173939 5.2272482,57.659015 
					4.6366384,52.365816 3.8325041,45.158956 4.0499241,41.780232 5.6456262,36.68609 c 
					0.4221906,-1.347806 0.7676195,-2.678332 0.7676195,-2.956724 0,-0.278392 
					0.5254075,-2.119161 1.1675724,-4.090601 1.7467496,-5.362501 4.8134159,-10.742739 
					6.1232239,-10.742739 0.210166,0 0.382121,-0.119062 0.382121,-0.264583 
					0,-0.145521 0.297656,-0.264583 0.661458,-0.264583 0.363802,0 0.661458,-0.124475 
					0.661458,-0.27661 0,-0.359156 1.216318,-1.575474 1.575474,-1.575474 0.152135,0 
					0.27661,-0.127567 0.27661,-0.283482 0,-0.421538 0.696537,-1.039434 
					1.171726,-1.039434 0.228675,0 0.415774,-0.119063 0.415774,-0.264583 0,-0.145521 
					0.297656,-0.264584 0.661458,-0.264584 0.411574,0 0.661458,-0.14993 
					0.661458,-0.396875 0,-0.246944 0.249885,-0.396875 0.661459,-0.396875 0.363802,0 
					0.661458,-0.119062 0.661458,-0.264583 0,-0.145521 0.238125,-0.264583 
					0.529167,-0.264583 0.291041,0 0.529166,-0.119063 0.529166,-0.264584 0,-0.145521 
					0.297657,-0.264583 0.661459,-0.264583 0.363802,0 0.661458,-0.119063 
					0.661458,-0.264583 0,-0.145521 0.297656,-0.264584 0.661458,-0.264584 0.411575,0 
					0.661459,-0.14993 0.661459,-0.396875 0,-0.277182 0.279282,-0.396875 
					0.926041,-0.396875 0.509323,0 0.926042,-0.119062 0.926042,-0.264583 0,-0.145521 
					0.357188,-0.264583 0.79375,-0.264583 0.436563,0 0.79375,-0.119063 
					0.79375,-0.264584 0,-0.14552 0.595313,-0.264583 1.322917,-0.264583 0.999538,0 
					1.322917,-0.09701 1.322917,-0.396875 0,-0.31498 0.382174,-0.3968749 
					1.852083,-0.3968749 1.058333,0 1.852083,-0.1133927 1.852083,-0.2645833 
					0,-0.1511906 0.79375,-0.2645833 1.852084,-0.2645833 1.058333,0 
					1.852083,-0.1133927 1.852083,-0.2645833 0,-0.1567903 0.97014,-0.2645834 
					2.38125,-0.2645834 1.41111,0 2.38125,0.1077931 2.38125,0.2645834 0,0.1637897 
					1.41111,0.2645833 3.704167,0.2645833 2.293056,0 3.704167,0.1007935 
					3.704167,0.2645833 0,0.1455208 0.416718,0.2645833 0.926041,0.2645833 h 0.926042 
					v 1.3229169 c 0,0.999537 -0.09701,1.322916 -0.396875,1.322916 -0.220485,0 
					-0.396875,0.235186 -0.396875,0.529167 0,0.291042 -0.119062,0.529167 
					-0.264583,0.529167 -0.145521,0 -0.264584,0.535781 -0.264584,1.190625 0,0.654843 
					-0.119062,1.190624 -0.264583,1.190624 -0.145521,0 -0.264583,0.595313 
					-0.264583,1.322917 0,0.727604 -0.119063,1.322917 -0.264584,1.322917 -0.14552,0 
					-0.264583,0.654843 -0.264583,1.455208 0,1.117129 -0.0922,1.455208 
					-0.396875,1.455208 -0.293981,0 -0.396875,0.308681 -0.396875,1.190625 0,0.654844 
					-0.119062,1.190625 -0.264583,1.190625 -0.145521,0 -0.264584,0.416719 
					-0.264584,0.926042 0,0.509323 -0.119062,0.926041 -0.264583,0.926041 -0.145521,0 
					-0.264583,0.595313 -0.264583,1.322917 0,0.999538 -0.09701,1.322917 
					-0.396875,1.322917 -0.293982,0 -0.396875,0.308681 -0.396875,1.190624 0,0.654844 
					-0.119063,1.190625 -0.264584,1.190625 -0.145521,0 -0.264583,0.365692 
					-0.264583,0.81265 0,1.033089 -0.535511,1.5686 -1.568601,1.5686 -0.545965,0 
					-0.812649,0.130241 -0.812649,0.396875 0,0.327581 -0.485069,0.396875 
					-2.778125,0.396875 -1.675694,0 -2.778125,0.104995 -2.778125,0.264584 0,0.14552 
					-0.297656,0.264583 -0.661459,0.264583 -0.363802,0 -0.661458,0.119062 
					-0.661458,0.264583 0,0.145521 -0.238125,0.264584 -0.529167,0.264584 -0.291041,0 
					-0.529166,0.119062 -0.529166,0.264583 0,0.145521 -0.178594,0.264583 
					-0.396875,0.264583 -0.218282,0 -0.396875,0.124473 -0.396875,0.276609 0,0.359156 
					-1.216319,1.575474 -1.575475,1.575474 -0.152135,0 -0.276609,0.178594 
					-0.276609,0.396875 0,0.218282 -0.119062,0.396875 -0.264583,0.396875 -0.145521,0 
					-0.264583,0.238125 -0.264583,0.529167 0,0.291042 -0.119063,0.529167 
					-0.264584,0.529167 -0.145521,0 -0.264583,0.416718 -0.264583,0.926041 0,0.646758 
					-0.119692,0.926042 -0.396875,0.926042 -0.327581,0 -0.396875,0.485068 
					-0.396875,2.778125 0,2.293056 0.06929,2.778125 0.396875,2.778125 0.293981,0 
					0.396875,0.308681 0.396875,1.190625 0,0.654843 0.119062,1.190625 
					0.264583,1.190625 0.145521,0 0.264584,0.416718 0.264584,0.926041 0,0.509323 
					0.119062,0.926042 0.264583,0.926042 0.145521,0 0.264583,0.297656 
					0.264583,0.661458 0,0.411575 0.149932,0.661458 0.396875,0.661458 0.246944,0 
					0.396875,0.249883 0.396875,0.661459 0,0.363802 0.119063,0.661458 
					0.264584,0.661458 0.145521,0 0.264583,0.238125 0.264583,0.529167 0,0.291041 
					0.119063,0.529166 0.264583,0.529166 0.145521,0 0.264584,0.297656 
					0.264584,0.661459 0,0.411575 0.149931,0.661458 0.396875,0.661458 0.220485,0 
					0.396875,0.235185 0.396875,0.529167 0,0.291041 0.119062,0.529166 
					0.264583,0.529166 0.145521,0 0.264583,0.178594 0.264583,0.396875 0,0.218281 
					0.119063,0.396875 0.264584,0.396875 0.145521,0 0.264583,0.238125 
					0.264583,0.529167 0,0.293981 0.17639,0.529166 0.396875,0.529166 0.218281,0 
					0.396875,0.127567 0.396875,0.283483 0,0.421539 0.696537,1.039434 
					1.171726,1.039434 0.228677,0 0.415774,0.119062 0.415774,0.264583 0,0.145521 
					0.178594,0.264584 0.396875,0.264584 0.218281,0 0.396875,0.178593 
					0.396875,0.396875 0,0.218281 0.119063,0.396875 0.264583,0.396875 0.145521,0 
					0.264584,0.238125 0.264584,0.529166 0,0.291042 0.119062,0.529167 
					0.264583,0.529167 0.151191,0 0.264584,0.79375 0.264584,1.852083 0,1.058333 
					-0.113393,1.852083 -0.264584,1.852083 -0.145521,0 -0.264583,0.535782 
					-0.264583,1.190625 0,0.654844 -0.119063,1.190625 -0.264584,1.190625 -0.14552,0 
					-0.264583,0.595313 -0.264583,1.322917 0,0.999537 -0.09702,1.322916 
					-0.396875,1.322916 -0.220485,0 -0.396875,0.235186 -0.396875,0.529167 0,0.291042 
					-0.119062,0.529167 -0.264583,0.529167 -0.145521,0 -0.264584,0.416718 
					-0.264584,0.926041 0,0.509323 -0.119062,0.926042 -0.264583,0.926042 -0.145521,0 
					-0.264583,0.416719 -0.264583,0.926042 0,0.509322 -0.119063,0.926041 
					-0.264584,0.926041 -0.14552,0 -0.264583,0.297656 -0.264583,0.661459 0,0.411575 
					-0.149931,0.661458 -0.396875,0.661458 -0.236694,0 -0.396875,0.243395 
					-0.396875,0.603049 0,0.331676 -0.238125,0.774678 -0.529167,0.984451 
					-0.291041,0.209772 -0.529166,0.593243 -0.529166,0.852159 0,0.258916 
					-0.178594,0.470757 -0.396875,0.470757 -0.225047,0 -0.396875,0.237287 
					-0.396875,0.548066 0,0.701887 -0.602131,1.304018 -1.304018,1.304018 -0.310779,0 
					-0.548066,0.171828 -0.548066,0.396875 0,0.277182 -0.279283,0.396875 
					-0.926041,0.396875 -0.646758,0 -0.926042,-0.119693 -0.926042,-0.396875 z m 
					28.310417,-15.875 c 0,-0.293981 -0.308681,-0.396875 -1.190625,-0.396875 
					-0.654844,0 -1.190625,-0.119062 -1.190625,-0.264583 0,-0.145521 
					-0.416719,-0.264583 -0.926041,-0.264583 -0.509323,0 -0.926042,-0.119063 
					-0.926042,-0.264584 0,-0.14552 -0.416719,-0.264583 -0.926042,-0.264583 
					-0.646758,0 -0.926042,-0.119692 -0.926042,-0.396875 0,-0.277183 
					-0.279283,-0.396875 -0.926041,-0.396875 -0.509323,0 -0.926042,-0.119062 
					-0.926042,-0.264583 0,-0.145521 -0.127566,-0.264584 -0.283482,-0.264584 
					-0.366311,0 -1.039435,-0.673123 -1.039435,-1.039434 0,-0.155916 
					-0.119062,-0.283482 -0.264583,-0.283482 -0.145521,0 -0.264583,-0.238125 
					-0.264583,-0.529167 0,-0.291041 -0.119063,-0.529166 -0.264584,-0.529166 
					-0.14552,0 -0.264583,-0.297657 -0.264583,-0.661459 0,-0.363802 
					0.119063,-0.661458 0.264583,-0.661458 0.145521,0 0.264584,-0.390435 
					0.264584,-0.867632 0,-0.56411 0.185118,-1.001059 0.529166,-1.249035 
					0.291042,-0.209772 0.529167,-0.533712 0.529167,-0.719867 0,-0.186156 
					0.297656,-0.338466 0.661458,-0.338466 0.363803,0 0.661459,-0.119062 
					0.661459,-0.264583 0,-0.145521 0.297656,-0.264583 0.661458,-0.264583 0.363802,0 
					0.661458,-0.119063 0.661458,-0.264584 0,-0.16379 1.411111,-0.264583 
					3.704167,-0.264583 2.293057,0 3.704167,0.100793 3.704167,0.264583 0,0.145521 
					0.535781,0.264584 1.190625,0.264584 0.654844,0 1.190625,0.119062 
					1.190625,0.264583 0,0.145521 0.416719,0.264583 0.926042,0.264583 0.646758,0 
					0.926041,0.119692 0.926041,0.396875 0,0.277183 0.279284,0.396875 
					0.926042,0.396875 0.509323,0 0.926042,0.119063 0.926042,0.264583 0,0.145521 
					0.238125,0.264584 0.529166,0.264584 0.291042,0 0.529167,0.119062 
					0.529167,0.264583 0,0.145521 0.297656,0.264583 0.661458,0.264583 0.363803,0 
					0.661459,0.127567 0.661459,0.283483 0,0.36631 0.673124,1.039434 
					1.039434,1.039434 0.155916,0 0.283483,0.297656 0.283483,0.661458 0,0.363802 
					0.119062,0.661459 0.264583,0.661459 0.145521,0 0.264583,0.654843 
					0.264583,1.455208 0,0.800364 -0.119062,1.455208 -0.264583,1.455208 -0.145521,0 
					-0.264583,0.178594 -0.264583,0.396875 0,0.218281 -0.152311,0.396875 
					-0.338466,0.396875 -0.186156,0 -0.510096,0.238125 -0.719868,0.529167 
					-0.209772,0.291041 -0.652775,0.529166 -0.984451,0.529166 -0.359653,0 
					-0.603049,0.160182 -0.603049,0.396875 0,0.336241 -0.646758,0.396875 
					-4.233333,0.396875 -3.586576,0 -4.233334,-0.06063 -4.233334,-0.396875 z m 
					4.233334,-17.859374 c 0,-0.145521 -0.535782,-0.264583 -1.190625,-0.264583 
					-0.654844,0 -1.190625,-0.119063 -1.190625,-0.264583 0,-0.145521 
					-0.416719,-0.264584 -0.926042,-0.264584 -0.646758,0 -0.926042,-0.119692 
					-0.926042,-0.396875 0,-0.277183 -0.279283,-0.396875 -0.926041,-0.396875 
					-0.509323,0 -0.926042,-0.119062 -0.926042,-0.264583 0,-0.145521 
					-0.390435,-0.264583 -0.867632,-0.264583 -0.564111,0 -1.001059,-0.185119 
					-1.249035,-0.529167 -0.209772,-0.291042 -0.533712,-0.529167 -0.719868,-0.529167 
					-0.186155,0 -0.338465,-0.297656 -0.338465,-0.661458 0,-0.363802 
					-0.119063,-0.661458 -0.264584,-0.661458 -0.14552,0 -0.264583,-0.535781 
					-0.264583,-1.190625 0,-0.654844 0.119063,-1.190625 0.264583,-1.190625 0.145521,0 
					0.264584,-0.297656 0.264584,-0.661458 0,-0.363802 0.127566,-0.661459 
					0.283482,-0.661459 0.366311,0 1.039435,-0.673123 1.039435,-1.039434 0,-0.155916 
					0.297656,-0.283482 0.661458,-0.283482 0.363802,0 0.661458,-0.119063 
					0.661458,-0.264584 0,-0.14552 0.416719,-0.264583 0.926042,-0.264583 0.509323,0 
					0.926042,-0.119062 0.926042,-0.264583 0,-0.161052 1.190625,-0.264584 
					3.042708,-0.264584 1.852083,0 3.042708,0.103532 3.042708,0.264584 0,0.145521 
					0.535782,0.264583 1.190625,0.264583 0.654844,0 1.190625,0.119063 
					1.190625,0.264583 0,0.145521 0.535782,0.264584 1.190625,0.264584 0.881944,0 
					1.190625,0.102893 1.190625,0.396875 0,0.246943 0.249884,0.396875 
					0.661459,0.396875 0.363802,0 0.661458,0.119062 0.661458,0.264583 0,0.145521 
					0.365691,0.264583 0.812649,0.264583 0.735039,0 1.568601,0.492104 
					1.568601,0.926042 0,0.220766 0.705276,0.926042 0.926042,0.926042 0.368432,0 
					0.926042,0.785206 0.926042,1.304017 0,0.301437 0.119062,0.548066 
					0.264583,0.548066 0.145521,0 0.264583,0.416719 0.264583,0.926041 0,0.509323 
					-0.119062,0.926042 -0.264583,0.926042 -0.145521,0 -0.264583,0.238125 
					-0.264583,0.529167 0,0.291041 -0.119063,0.529166 -0.264584,0.529166 -0.145521,0 
					-0.264583,0.178594 -0.264583,0.396875 0,0.218282 -0.178594,0.396875 
					-0.396875,0.396875 -0.218281,0 -0.396875,0.119063 -0.396875,0.264584 0,0.14552 
					-0.238125,0.264583 -0.529167,0.264583 -0.291041,0 -0.529166,0.119062 
					-0.529166,0.264583 0,0.166309 -1.719792,0.264584 -4.630209,0.264584 -2.910417,0 
					-4.630208,-0.09828 -4.630208,-0.264584 z m 99.218756,0 c 0,-0.151191 
					-0.79375,-0.264583 -1.85209,-0.264583 -1.05833,0 -1.85208,-0.113393 
					-1.85208,-0.264583 0,-0.145521 -0.41672,-0.264584 -0.92604,-0.264584 -0.64676,0 
					-0.92604,-0.119692 -0.92604,-0.396875 0,-0.220485 -0.23519,-0.396875 
					-0.52917,-0.396875 -0.29104,0 -0.52917,-0.119062 -0.52917,-0.264583 0,-0.145521 
					-0.27137,-0.264583 -0.60305,-0.264583 -0.33167,0 -0.77468,-0.238125 
					-0.98445,-0.529167 -0.20977,-0.291042 -0.53371,-0.529167 -0.71987,-0.529167 
					-0.21042,0 -0.33846,-0.350308 -0.33846,-0.926041 0,-0.509323 -0.11906,-0.926042 
					-0.26458,-0.926042 -0.14553,0 -0.26459,-0.595312 -0.26459,-1.322916 0,-0.727605 
					0.11906,-1.322917 0.26459,-1.322917 0.14552,0 0.26458,-0.416719 
					0.26458,-0.926042 0,-0.509323 0.12757,-0.926041 0.28348,-0.926041 0.42856,0 
					1.03944,-0.699733 1.03944,-1.190625 0,-0.427747 0.59335,-1.190625 
					0.92604,-1.190625 0.22076,0 0.92604,-0.705276 0.92604,-0.926042 0,-0.350729 
					0.77385,-0.926041 1.24561,-0.926041 0.26931,0 0.66128,-0.238125 
					0.87106,-0.529167 0.20977,-0.291042 0.65277,-0.529167 0.98445,-0.529167 
					0.33167,0 0.60305,-0.127566 0.60305,-0.283482 0,-0.428556 0.69973,-1.039434 
					1.19062,-1.039434 0.49089,0 1.19063,-0.610878 1.19063,-1.039434 0,-0.155917 
					0.24353,-0.283483 0.54119,-0.283483 0.5631,0 1.84006,-1.093338 1.84006,-1.575475 
					0,-0.152135 0.12756,-0.276608 0.28348,-0.276608 0.5892,0 1.03943,-0.794036 
					1.03943,-1.833184 0,-0.592478 -0.11906,-1.077233 -0.26458,-1.077233 -0.14552,0 
					-0.26458,-0.178593 -0.26458,-0.396875 0,-0.246944 -0.24989,-0.396875 
					-0.66146,-0.396875 -0.3638,0 -0.66146,-0.119062 -0.66146,-0.264583 0,-0.145521 
					-0.29766,-0.264583 -0.66146,-0.264583 -0.3638,0 -0.66146,-0.119063 
					-0.66146,-0.264584 0,-0.14552 -0.53578,-0.264583 -1.19062,-0.264583 -0.88195,0 
					-1.19063,-0.102893 -1.19063,-0.396875 0,-0.277182 -0.27928,-0.396875 
					-0.92604,-0.396875 -0.50932,0 -0.92604,-0.119062 -0.92604,-0.264583 0,-0.145521 
					-0.41672,-0.264584 -0.92604,-0.264584 -0.50933,0 -0.92604,-0.119062 
					-0.92604,-0.264583 0,-0.145521 -0.41672,-0.264583 -0.92605,-0.264583 -0.50932,0 
					-0.92604,-0.119063 -0.92604,-0.264583 0,-0.145521 -0.23812,-0.264584 
					-0.52916,-0.264584 -0.29399,0 -0.52917,-0.176389 -0.52917,-0.396875 0,-0.218281 
					-0.17859,-0.396875 -0.39688,-0.396875 -0.30467,0 -0.39687,-0.338079 
					-0.39687,-1.455208 0,-1.11713 0.0922,-1.455208 0.39687,-1.455208 0.2367,0 
					0.39688,-0.243395 0.39688,-0.603049 0,-0.331677 0.23812,-0.77468 
					0.52917,-0.984451 0.29104,-0.209771 0.52916,-0.533712 0.52916,-0.719868 
					0,-0.186156 0.27137,-0.338466 0.60305,-0.338466 0.33168,0 0.77468,-0.238124 
					0.98445,-0.529166 0.20977,-0.291042 0.65278,-0.529167 0.98445,-0.529167 
					0.35966,0 0.60305,-0.160181 0.60305,-0.396875 0,-0.246944 0.24989,-0.396875 
					0.66146,-0.396875 0.3638,0 0.66146,-0.119062 0.66146,-0.264583 0,-0.145521 
					0.23812,-0.264583 0.52917,-0.264583 0.29104,0 0.52916,-0.119063 
					0.52916,-0.264584 0,-0.145521 0.29766,-0.264583 0.66146,-0.264583 0.57957,0 
					0.66146,-0.114653 0.66146,-0.926042 0,-0.646759 -0.11969,-0.926041 
					-0.39688,-0.926041 -0.21828,0 -0.39687,-0.119063 -0.39687,-0.264584 0,-0.14552 
					-0.23813,-0.264583 -0.52917,-0.264583 -0.29398,0 -0.52916,-0.176389 
					-0.52916,-0.396875 0,-0.2469442 -0.24989,-0.3968749 -0.66146,-0.3968749 
					-0.3638,0 -0.66146,-0.1190625 -0.66146,-0.2645833 0,-0.1455208 
					-0.41672,-0.2645833 -0.92604,-0.2645833 -0.50933,0 -0.92604,-0.1190625 
					-0.92604,-0.2645833 0,-0.1455209 -0.53579,-0.2645834 -1.19063,-0.2645834 
					-0.88194,0 -1.19062,-0.1028935 -1.19062,-0.3968749 0,-0.2939815 
					-0.30869,-0.396875 -1.19063,-0.396875 -0.65484,0 -1.19062,-0.1190625 
					-1.19062,-0.2645833 0,-0.1455209 -0.24663,-0.2645834 -0.54807,-0.2645834 
					-0.58577,0 -1.30402,-0.5725175 -1.30402,-1.0394343 0,-0.1559153 
					-0.12447,-0.2834823 -0.27661,-0.2834823 -0.59399,0 -1.57547,-1.3111423 
					-1.57547,-2.1046399 0,-0.443177 -0.11906,-0.8057766 -0.26458,-0.8057766 
					-0.14553,0 -0.26459,-0.2976562 -0.26459,-0.6614583 0,-0.363802 
					0.11906,-0.6614583 0.26459,-0.6614583 0.14552,0 0.26458,-0.2976562 
					0.26458,-0.6614583 V 0.37519392 l 15.0151,0.0360315 c 8.25831,0.0198173 
					14.74722,0.0876295 14.4198,0.15069368 -0.32743,0.0630642 -0.59532,0.22544377 
					-0.59532,0.3608435 0,0.1353997 -0.41672,0.2461813 -0.92604,0.2461813 -0.50932,0 
					-0.92604,0.1190625 -0.92604,0.2645833 0,0.1455208 -0.59531,0.2645833 
					-1.32292,0.2645833 -0.7276,0 -1.32291,0.1190625 -1.32291,0.2645833 0,0.1492522 
					-0.74966,0.2645834 -1.7198,0.2645834 -1.35231,0 -1.71979,0.084802 
					-1.71979,0.3968749 0,0.2250479 -0.23728,0.396875 -0.54806,0.396875 -0.65764,0 
					-1.30402,0.5901788 -1.30402,1.190625 0,0.6004461 0.64638,1.1906249 
					1.30402,1.1906249 0.30143,0 0.54806,0.1190625 0.54806,0.2645833 0,0.1455209 
					0.41672,0.2645833 0.92604,0.2645833 0.50933,0 0.92605,0.1308249 
					0.92605,0.2907216 0,0.1598969 0.35718,0.6243846 0.79375,1.032195 
					0.43656,0.4078105 0.79375,0.9913607 0.79375,1.2967785 0,0.3172153 
					0.17016,0.5553048 0.39687,0.5553048 0.24694,0 0.39688,0.2498841 
					0.39688,0.6614583 0,0.4115742 -0.14994,0.6614582 -0.39688,0.6614582 -0.26555,0 
					-0.39687,0.265563 -0.39687,0.802569 0,0.562975 -0.27383,1.076393 
					-0.91723,1.719792 -0.50447,0.504472 -1.04025,0.917222 -1.19062,0.917222 
					-0.15037,0 -0.2734,0.124475 -0.2734,0.27661 0,0.359156 -1.21632,1.575473 
					-1.57548,1.575473 -0.15213,0 -0.27661,0.416719 -0.27661,0.926042 v 0.926042 h 
					1.5875 c 0.88195,0 1.5875,-0.117593 1.5875,-0.264584 0,-0.14552 
					0.65485,-0.264583 1.45521,-0.264583 0.80037,0 1.45521,0.119063 1.45521,0.264583 
					0,0.145521 0.29766,0.264584 0.66146,0.264584 0.3638,0 0.66146,0.127567 
					0.66146,0.283482 0,0.366309 0.67312,1.039434 1.03943,1.039434 0.15592,0 
					0.28348,0.416719 0.28348,0.926042 0,0.509323 0.11907,0.926042 0.26459,0.926042 
					0.14552,0 0.26458,0.416718 0.26458,0.926041 0,0.509323 -0.11906,0.926042 
					-0.26458,0.926042 -0.14552,0 -0.26459,0.416719 -0.26459,0.926041 0,0.64676 
					-0.11969,0.926042 -0.39687,0.926042 -0.22049,0 -0.39688,0.235185 
					-0.39688,0.529167 0,0.291041 -0.11906,0.529166 -0.26458,0.529166 -0.14552,0 
					-0.26458,0.178594 -0.26458,0.396875 0,0.218282 -0.11907,0.396875 
					-0.26459,0.396875 -0.14552,0 -0.26458,0.238125 -0.26458,0.529167 0,0.293981 
					-0.17639,0.529167 -0.39688,0.529167 -0.21828,0 -0.39687,0.124473 
					-0.39687,0.276608 0,0.359156 -1.21632,1.575475 -1.57548,1.575475 -0.15213,0 
					-0.2766,0.271372 -0.2766,0.603049 0,0.331676 -0.23813,0.774679 -0.52917,0.984451 
					-0.29104,0.209772 -0.52917,0.533712 -0.52917,0.719867 0,0.186156 
					-0.11906,0.338466 -0.26458,0.338466 -0.14552,0 -0.26458,0.238125 
					-0.26458,0.529167 0,0.293981 -0.17639,0.529166 -0.39688,0.529166 -0.27718,0 
					-0.39687,0.279284 -0.39687,0.926042 0,0.646758 0.11969,0.926042 0.39687,0.926042 
					0.21828,0 0.39688,0.119062 0.39688,0.264583 0,0.145521 0.23812,0.264583 
					0.52916,0.264583 0.29104,0 0.52917,-0.119062 0.52917,-0.264583 0,-0.145521 
					0.27137,-0.264583 0.60305,-0.264583 0.33167,0 0.77468,-0.238125 
					0.98445,-0.529167 0.20977,-0.291042 0.65277,-0.529167 0.98445,-0.529167 
					0.35965,0 0.60305,-0.160181 0.60305,-0.396875 0,-0.277182 0.27928,-0.396875 
					0.92604,-0.396875 0.50932,0 0.92604,-0.119062 0.92604,-0.264583 0,-0.151191 
					0.79375,-0.264583 1.85209,-0.264583 1.05833,0 1.85208,0.113392 1.85208,0.264583 
					0,0.145521 0.35719,0.264583 0.79375,0.264583 0.52917,0 0.79375,0.132292 
					0.79375,0.396875 0,0.236694 0.2434,0.396875 0.60305,0.396875 0.33168,0 
					0.77468,0.238125 0.98445,0.529167 0.20977,0.291042 0.53371,0.529167 
					0.71987,0.529167 0.21043,0 0.33846,0.350308 0.33846,0.926041 0,0.509323 
					0.11907,0.926042 0.26459,0.926042 0.14552,0 0.26458,0.416719 0.26458,0.926041 
					0,0.509323 -0.11906,0.926042 -0.26458,0.926042 -0.14552,0 -0.26459,0.416719 
					-0.26459,0.926042 0,0.646758 -0.11969,0.926041 -0.39687,0.926041 -0.22343,0 
					-0.39688,0.236522 -0.39688,0.541192 0,0.563105 -1.09333,1.840058 
					-1.57547,1.840058 -0.15214,0 -0.27661,0.124473 -0.27661,0.276609 0,0.482137 
					-1.27695,1.575474 -1.84006,1.575474 -0.30467,0 -0.54119,0.173451 
					-0.54119,0.396875 0,0.220486 -0.23519,0.396875 -0.52917,0.396875 -0.29104,0 
					-0.52916,0.119063 -0.52916,0.264584 0,0.145521 -0.29766,0.264583 
					-0.66146,0.264583 -0.3638,0 -0.66146,0.119063 -0.66146,0.264583 0,0.145521 
					-0.41672,0.264584 -0.92604,0.264584 -0.50933,0 -0.92604,0.119062 
					-0.92604,0.264583 0,0.145521 -0.41672,0.264583 -0.92605,0.264583 -0.64675,0 
					-0.92604,0.119692 -0.92604,0.396875 0,0.277183 -0.27928,0.396875 
					-0.92604,0.396875 -0.50932,0 -0.92604,0.119063 -0.92604,0.264584 0,0.15119 
					-0.79375,0.264583 -1.85208,0.264583 -1.05834,0 -1.85209,0.113392 
					-1.85209,0.264583 0,0.161052 -1.19062,0.264584 -3.04271,0.264584 -1.85208,0 
					-3.0427,-0.103532 -3.0427,-0.264584 z M 91.344498,32.654359 c 0,-0.157821 
					-1.014236,-0.264583 -2.513542,-0.264583 -1.499307,0 -2.513542,-0.106762 
					-2.513542,-0.264584 0,-0.14552 -0.416719,-0.264583 -0.926042,-0.264583 
					-0.646758,0 -0.926041,-0.119692 -0.926041,-0.396875 0,-0.220485 
					-0.235186,-0.396875 -0.529167,-0.396875 -0.291042,0 -0.529167,-0.119062 
					-0.529167,-0.264583 0,-0.145521 -0.246628,-0.264584 -0.548065,-0.264584 
					-0.701887,0 -1.304018,-0.60213 -1.304018,-1.304017 0,-0.301437 
					-0.127566,-0.548066 -0.283482,-0.548066 -0.537197,0 -1.039435,-0.757923 
					-1.039435,-1.568601 0,-0.446957 -0.119062,-0.812649 -0.264583,-0.812649 
					-0.154339,0 -0.264583,-0.881944 -0.264583,-2.116666 0,-1.234723 
					0.110244,-2.116667 0.264583,-2.116667 0.145521,0 0.264583,-0.416719 
					0.264583,-0.926041 0,-0.509323 0.119063,-0.926042 0.264584,-0.926042 0.145521,0 
					0.264583,-0.297656 0.264583,-0.661458 0,-0.411575 0.149931,-0.661459 
					0.396875,-0.661459 0.223425,0 0.396875,-0.236521 0.396875,-0.541193 0,-0.563104 
					1.093338,-1.840057 1.575475,-1.840057 0.152135,0 0.276608,-0.127567 
					0.276608,-0.283482 0,-0.421538 0.696537,-1.039434 1.171726,-1.039434 0.228677,0 
					0.415774,-0.119063 0.415774,-0.264583 0,-0.145521 0.178594,-0.264584 
					0.396875,-0.264584 0.218282,0 0.396875,-0.178594 0.396875,-0.396875 0,-0.220486 
					0.235186,-0.396875 0.529167,-0.396875 0.291042,0 0.529167,-0.119062 
					0.529167,-0.264583 0,-0.145521 0.297656,-0.264583 0.661458,-0.264583 0.363802,0 
					0.661459,-0.119063 0.661459,-0.264584 0,-0.145521 0.297656,-0.264583 
					0.661458,-0.264583 0.363802,0 0.661458,-0.119063 0.661458,-0.264583 0,-0.145521 
					0.416719,-0.264584 0.926042,-0.264584 0.646758,0 0.926042,-0.119692 
					0.926042,-0.396875 0,-0.277182 0.279283,-0.396875 0.926041,-0.396875 0.509323,0 
					0.926042,-0.119062 0.926042,-0.264583 0,-0.145521 0.535781,-0.264583 
					1.190625,-0.264583 0.654844,0 1.190625,-0.119063 1.190625,-0.264584 0,-0.14552 
					0.654844,-0.264583 1.455208,-0.264583 1.117129,0 1.455209,-0.0922 
					1.455209,-0.396875 0,-0.3216501 0.426273,-0.3968749 2.248962,-0.3968749 
					1.32291,0 2.24895,-0.1089461 2.24895,-0.2645833 0,-0.1677844 1.98438,-0.2645833 
					5.42396,-0.2645833 3.43959,0 5.42396,0.096799 5.42396,0.2645833 0,0.1543402 
					0.88195,0.2645833 2.11667,0.2645833 1.70509,0 2.11666,0.07717 2.11666,0.3968749 
					0,0.277183 0.27929,0.396875 0.92605,0.396875 0.50932,0 0.92604,0.119063 
					0.92604,0.264583 0,0.145521 0.41672,0.264584 0.92604,0.264584 0.50932,0 
					0.92604,0.119062 0.92604,0.264583 0,0.145521 0.29766,0.264583 0.66146,0.264583 
					0.3638,0 0.66146,0.127567 0.66146,0.283483 0,0.428556 0.69973,1.039434 
					1.19062,1.039434 0.60045,0 1.19063,0.646386 1.19063,1.304018 0,0.301436 
					0.12756,0.548065 0.28348,0.548065 0.5372,0 1.03944,0.757922 1.03944,1.568601 
					0,0.446957 0.11906,0.812649 0.26458,0.812649 0.15119,0 0.26458,0.79375 
					0.26458,1.852083 0,1.058334 -0.11339,1.852084 -0.26458,1.852084 -0.14552,0 
					-0.26458,0.416718 -0.26458,0.926041 0,0.509323 -0.11907,0.926042 
					-0.26459,0.926042 -0.14552,0 -0.26458,0.238125 -0.26458,0.529166 0,0.293982 
					-0.17639,0.529167 -0.39688,0.529167 -0.21828,0 -0.39687,0.124475 
					-0.39687,0.27661 0,0.359156 -1.21632,1.575473 -1.57548,1.575473 -0.15213,0 
					-0.2766,0.124475 -0.2766,0.27661 0,0.482135 -1.27696,1.575474 -1.84006,1.575474 
					-0.30467,0 -0.5412,0.17345 -0.5412,0.396875 0,0.246943 -0.24988,0.396875 
					-0.66145,0.396875 -0.36381,0 -0.66146,0.119062 -0.66146,0.264583 0,0.145521 
					-0.23813,0.264583 -0.52917,0.264583 -0.29104,0 -0.52917,0.119063 
					-0.52917,0.264584 0,0.14552 -0.41671,0.264583 -0.92604,0.264583 -0.64675,0 
					-0.92604,0.119692 -0.92604,0.396875 0,0.277183 -0.27928,0.396875 
					-0.92604,0.396875 -0.50932,0 -0.92604,0.119062 -0.92604,0.264583 0,0.145521 
					-0.53578,0.264583 -1.19063,0.264583 -0.65484,0 -1.19062,0.119063 
					-1.19062,0.264584 0,0.145521 -0.59531,0.264583 -1.32292,0.264583 -0.99954,0 
					-1.32292,0.09701 -1.32292,0.396875 0,0.314981 -0.38217,0.396875 
					-1.85208,0.396875 -1.05833,0 -1.85208,0.113393 -1.85208,0.264583 0,0.15875 
					-1.05834,0.264584 -2.64584,0.264584 -1.5875,0 -2.64583,0.105833 
					-2.64583,0.264583 0,0.166854 -1.807984,0.264583 -4.894791,0.264583 -3.086806,0 
					-4.894791,-0.09773 -4.894791,-0.264583 z" />
			</g>
		</svg>`;
  // *INDENT-ON*
}
