const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

// Use a lookup table to find the index.
const lookup = typeof Uint8Array === 'undefined' ? [] : new Uint8Array(256);
for (let i = 0; i < chars.length; i++)
	{
	lookup[chars.charCodeAt(i)] = i;
	}
/**
 * decode a base64 string into a byte array
 */
export const base64Decode = (base64: string): Uint8Array => 
	{
	let bufferLength = base64.length * 0.75;
	if (base64[base64.length - 1] === '=') 
		{
		bufferLength--;
		if (base64[base64.length - 2] === '=') 
			{
			bufferLength--;
			}
		}
	const arraybuffer = new ArrayBuffer(bufferLength);
	const bytes = new Uint8Array(arraybuffer);
	let p = 0;
	for (let i = 0; i < base64.length; i += 4) 
		{
		const encoded1 = lookup[base64.charCodeAt(i)];
		const encoded2 = lookup[base64.charCodeAt(i + 1)];
		const encoded3 = lookup[base64.charCodeAt(i + 2)];
		const encoded4 = lookup[base64.charCodeAt(i + 3)];

		bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
		bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
		bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
		}
	return bytes;
	};
	
const base64abc = [
	"A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
	"N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
	"a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
	"n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
	"0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "/"
];
export const base64Encode = (bytes: Uint8Array): string => 
	{
	let result = '', i, l = bytes.length;
	for (i = 2; i < l; i += 3) {
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		result += base64abc[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)];
		result += base64abc[bytes[i] & 0x3F];
	}
	if (i === l + 1) { // 1 octet yet to write
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[(bytes[i - 2] & 0x03) << 4];
		result += "==";
	}
	if (i === l) { // 2 octets yet to write
		result += base64abc[bytes[i - 2] >> 2];
		result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		result += base64abc[(bytes[i - 1] & 0x0F) << 2];
		result += "=";
	}
	return result;
	}

