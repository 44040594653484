// CoreImage.ts implements all persistent Image operations
import { wsImage } from './schema';
import { PersistentClient } from './PersistentClient';
import { PersistentWorker } from './PersistentWorker';
import { LogLevel } from '~/components/designer2/DynamicLog';
import { base64Decode, base64Encode } from '~/utils/base64';

export enum ImageType {
  Source = 0, // user uploaded
  Band, // a complete generated band artwork
  MAX,
}

/**
 * Implements all persistent image operations, co-ordinating local and server
 * database records, and calculating urls to access images.
 */
export class CoreImage {
  data: wsImage = new wsImage();
  // localId is set only while we are updating to a server issued id
  localId: number = 0;
  // we sometimes store the url here:
  src: string = '';
  // in this database columns are sorted so that the members of the primary key come first
  static columnsStart: number = 1;
  static serverTable: string = 'image';

  public static uniqueguid(): string {
    // for example "36b8f84d-df4e-4d49-b662-bcde71a8764f" - 36 chars
    return crypto.randomUUID();
  }
  constructor(obj: object = null) {
    if (obj) {
      if (Object.hasOwn(obj, 'data')) {
        // json lost type data, regaining with new
        for (const column of CoreImage.columns()) {
          if (Object.hasOwn(obj.data, column)) {
            this.data[column] = obj.data[column];
          }
        }
      } else {
        // returned from the database, populate this object
        for (const column of CoreImage.columns()) {
          if (Object.hasOwn(obj, column)) {
            this.data[column] = obj[column];
          }
        }
      }
    }
  }

  public static columns(): string[] {
    return wsImage.columns;
  }
  public values(): unknown[] {
    return this.data.values();
  }
  public static async getImageByteArrayWorker(
    worker: PersistentWorker,
    local_filename_with_ext: string
  ): Uint8Array {
    let buffer = null;
    try {
      await worker.opfsInitialized;
      worker.log(
        LogLevel.debug,
        'CoreImage.getImageByteArrayWorker opfs initialized file ' +
          local_filename_with_ext
      );
      const fileHandle = await worker.directoryHandle.getFileHandle(
        local_filename_with_ext
      );
      worker.log(
        LogLevel.debug,
        'CoreImage.getImageByteArrayWorker got file handle file ' +
          local_filename_with_ext
      );
      const fileAccessHandle = await fileHandle.createSyncAccessHandle();
      worker.log(
        LogLevel.debug,
        'CoreImage.getImageByteArrayWorker got file sync handle file ' +
          local_filename_with_ext
      );
      buffer = new Uint8Array(new ArrayBuffer(fileAccessHandle.getSize()));
      worker.log(
        LogLevel.debug,
        'CoreImage.getImageByteArrayWorker created buffer file ' +
          local_filename_with_ext
      );
      fileAccessHandle.read(buffer, { at: 0 });
      worker.log(
        LogLevel.debug,
        'CoreImage.getImageByteArrayWorker read buffer file ' +
          local_filename_with_ext
      );
      fileAccessHandle.close();
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImage.getImageByteArrayWorker exception:',
        error
      );
    }
    return buffer;
  }
  public static async getImageByteArrayClient(
    localFilename: string
  ): Uint8Array {
    let buffer = null;
    if (
      PersistentClient.instance &&
      localFilename &&
      localFilename.length > 0
    ) {
      // only client side
      try {
        const uri = await PersistentClient.instance.getImgSrc(
          localFilename + PersistentClient.imageExt
        );
        const blob = await fetch(uri);
        buffer = new Uint8Array(await blob.arrayBuffer());
      } catch (error) {
        PersistentClient.log(
          LogLevel.warn,
          'CoreImage.getImageByteArrayClient(' + localFilename + ') exception:',
          error
        );
      }
    }
    return buffer;
  }
  public getThumbImageByteArray(): Uint8Array {
    let bytes = null;
    if (this.data.thumb_dataurl && this.data.thumb_dataurl.length > 0) {
      const coded = this.data.thumb_dataurl.split('base64,');
      if (coded.length > 1) {
        bytes = base64Decode(coded[1]);
      }
    }
    return bytes;
  }
  // Local Database calls
  private createLocalQuery(nextLocalId: number, timestamp: number = 0): string {
    if (!this.data.imageid || this.data.imageid === 0) {
      this.data.imageid = nextLocalId;
    }
    if (timestamp && timestamp > 0) {
      if (!this.data.created_timestamp || this.data.created_timestamp === 0) {
        this.data.created_timestamp = timestamp;
      }
      if (!this.data.updated_timestamp || this.data.updated_timestamp === 0) {
        this.data.updated_timestamp = timestamp;
      }
    }
    if (!this.data.local_filename || this.data.local_filename.length === 0) {
      this.data.local_filename = CoreImage.uniqueguid();
    }
    const sql =
      'INSERT INTO ' +
      wsImage.table +
      ' (' +
      CoreImage.columns().join(', ') +
      ') ' +
      'VALUES (' +
      Array(CoreImage.columns().length).fill('?').join(', ') +
      ')';
    return sql;
  }
  private static findLocalQuery(where: string = '', orderby = ''): string {
    const sql =
      'SELECT * FROM ' +
      wsImage.table +
      (where.length > 0 ? ' WHERE ' + where : '') +
      (orderby.length > 0 ? ' ORDER BY ' + orderby : '');
    return sql;
  }
  private readLocalQuery(): string {
    return CoreImage.findLocalQuery(
      CoreImage.columns().slice(0, CoreImage.columnsStart).join('=? AND ') +
        '=?'
    );
  }
  private updateLocalQuery(timestamp: number = 0): string {
    if (timestamp && timestamp > 0) {
      this.data.updated_timestamp = timestamp;
    }
    const sql =
      'UPDATE ' +
      wsImage.table +
      ' SET ' +
      CoreImage.columns().slice(CoreImage.columnsStart).join('=?, ') +
      '=? ' +
      'WHERE ' +
      CoreImage.columns().slice(0, CoreImage.columnsStart).join('=? AND ') +
      '=?';
    return sql;
  }
  private updateLocalWithIdQuery(timestamp: number = 0): string {
    if (timestamp && timestamp > 0) {
      this.data.updated_timestamp = timestamp;
    }
    const sql =
      'UPDATE ' +
      wsImage.table +
      ' SET ' +
      CoreImage.columns().join('=?, ') +
      '=? ' +
      'WHERE ' +
      CoreImage.columns().slice(0, CoreImage.columnsStart).join('=? AND ') +
      '=?';
    return sql;
  }
  private deleteLocalQuery(): string {
    const sql = 'DELETE FROM ' + wsImage.table + ' ' + 'WHERE imageid=?';
    return sql;
  }
  // Server Database Calls
  private createServerQuery(timestamp: number): object {
    if (!this.data.created_timestamp || this.data.created_timestamp === 0) {
      this.data.created_timestamp = timestamp;
    }
    if (!this.data.updated_timestamp || this.data.updated_timestamp === 0) {
      this.data.updated_timestamp = timestamp;
    }
    this.data.server_written = timestamp;
    return Object.fromEntries(
      Object.entries(this.data).slice(CoreImage.columnsStart)
    );
  }
  private serverIdentity(): object {
    return { imageid: this.data.imageid };
  }
  private updateServerQuery(timestamp: number): object {
    this.data.server_written = timestamp;
    return Object.fromEntries(
      Object.entries(this.data).slice(CoreImage.columnsStart)
    );
  }
  public hasThumbDataurl(): boolean {
    return this.data.thumb_dataurl && this.data.thumb_dataurl.length > 0;
  }
  public hasLocalFilename(): boolean {
    return this.data.local_filename && this.data.local_filename.length > 0;
  }
  public getLocalFilename(): string {
    return this.hasLocalFilename()
      ? this.data.local_filename + PersistentClient.imageExt
      : '';
  }
  public getServerURL(): string {
    return this.data.server_filename && this.data.server_filename.length > 0
      ? PersistentClient.imageStoreURL + '/' + this.data.server_filename
      : '';
  }
  public getServerThumbURL(): string {
    return this.data.server_thumb_filename &&
      this.data.server_thumb_filename.length > 0
      ? PersistentClient.imageStoreURL + '/' + this.data.server_thumb_filename
      : '';
  }
  public getServerID(): string {
    let image_server_id = this.data.server_filename;
    const extpos = image_server_id.indexOf('.');
    if (extpos !== -1) {
      image_server_id = this.data.server_filename.substring(0, extpos);
    }
    return image_server_id;
  }
  public calculateScale(): number {
    let scale = 1.0;
    if (this.data.width !== this.data.height) {
      const maxDim = Math.max(this.data.width, this.data.height);
      const minDim = Math.min(this.data.width, this.data.height);
      scale = Math.round((1000.0 * maxDim) / minDim) / 1000.0;
    }
    return scale;
  }
  // Convenience functions:
  public static async findServer(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreImage[] {
    const coreImages = new Array<CoreImage>();
    try {
      const jsonFind = await worker.fetchServer(
        CoreImage.serverTable,
        where ? where : {},
        'GET'
      );
      if (jsonFind) {
        if (
          jsonFind.success &&
          jsonFind.results &&
          jsonFind.results.length > 0
        ) {
          // found
          for (const row of jsonFind.results) {
            coreImages.push(new CoreImage(row));
          }
        } else {
          worker.log(
            LogLevel.debug,
            'CoreImage.findServer not found',
            where,
            orderby,
            jsonFind
          );
        }
      } else {
        worker.log(
          LogLevel.warn,
          'CoreImage.findServer failed',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImage.findServer exception:',
        error,
        where,
        orderby
      );
    }
    return coreImages;
  }
  public static async findServerItem(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreImage {
    let coreImage = null;
    try {
      const jsonFind = await worker.fetchServer(
        CoreImage.serverTable,
        where ? where : {},
        'GET'
      );
      if (jsonFind) {
        if (
          jsonFind.success &&
          jsonFind.results &&
          jsonFind.results.length > 0
        ) {
          // found
          coreImage = new CoreImage(jsonFind.results[0]);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreImage.findServerItem not found',
            where,
            orderby,
            jsonFind
          );
        }
      } else {
        worker.log(
          LogLevel.warn,
          'CoreImage.findServerItem failed',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImage.findServerItem exception:',
        error,
        where,
        orderby
      );
    }
    return coreImage;
  }
  public static async findByOriginalServer(
    worker: PersistentWorker,
    userid: number,
    originalName: string,
    originalSize: number,
    originalModified: number,
    imagetype: ImageType
  ): CoreImage {
    return CoreImage.findServerItem(worker, {
      original_name: originalName,
      original_size: originalSize,
      original_modified: originalModified,
      who_created: userid,
      imagetype: imagetype,
    });
  }
  public static async findByUserServer(
    worker: PersistentWorker,
    userid: number
  ): CoreImage[] {
    return CoreImage.findServer(worker, { who_created: userid });
  }
  public static async createServer(
    worker: PersistentWorker,
    coreImage: CoreImage
  ): CoreImage {
    try {
      const timestamp = PersistentWorker.getTimestamp();
      const jsonCreate = await worker.fetchServer(
        CoreImage.serverTable,
        coreImage.createServerQuery(timestamp),
        'POST'
      );
      if (jsonCreate) {
        let localId = 0;
        if (jsonCreate.success) {
          // created
          if (
            jsonCreate.meta &&
            jsonCreate.meta.last_row_id &&
            jsonCreate.meta.last_row_id > 0 &&
            coreImage.data.imageid > 0 &&
            coreImage.data.imageid < PersistentClient.minServerId
          ) {
            localId = coreImage.data.imageid;
            coreImage.localId = localId;
            coreImage.data.imageid = jsonCreate.meta.last_row_id;
          }
          // update server_written, and maybe also id
          coreImage.updateLocal(worker, timestamp);
          // if id changed, trigger a local id update
          if (localId > 0) {
            worker.imageIdChange(localId, coreImage.data.imageid);
          }
        } else {
          worker.log(
            LogLevel.debug,
            'CoreImage.createServer not created',
            jsonCreate
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreImage.createServer failed');
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImage.createServer exception ' + error.message
      );
    }
    return coreImage;
  }
  public async updateServer(worker: PersistentWorker): boolean {
    let worked = false;
    try {
      const timestamp = PersistentWorker.getTimestamp();
      const jsonCreate = await worker.fetchServer(
        CoreImage.serverTable,
        this.updateServerQuery(timestamp),
        'PATCH',
        this.data.imageid
      );
      if (jsonCreate) {
        if (jsonCreate.success) {
          worked = true;
          // now store the new server_written
          this.updateLocal(worker);
        } else {
          worker.log(
            LogLevel.debug,
            'CoreImage.updateServer not updated',
            jsonCreate
          );
        }
      } else {
        worker.log(LogLevel.warn, 'CoreImage.updateServer failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreImage.updateServer exception:', error);
    }
    return worked;
  }
  public async deleteServer(worker: PersistentWorker): boolean {
    let worked = false;
    try {
      const jsonCreate = await worker.fetchServer(
        CoreImage.serverTable,
        {},
        'DELETE',
        this.data.imageid
      );
      if (jsonCreate) {
        if (!jsonCreate.success) {
          worker.log(
            LogLevel.debug,
            'CoreImage.deleteServer not deleted',
            jsonCreate
          );
        } else {
          worked = true;
        }
      } else {
        worker.log(LogLevel.warn, 'CoreImage.deleteServer failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreImage.deleteServer exception:', error);
    }
    return worked;
  }
  public static findLocal(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreImage[] {
    const coreImages = new Array<CoreImage>();
    try {
      let wherestr = '';
      let wherevals = [];
      if (where) {
        wherestr = Object.keys(where).join('=? AND ') + '=?';
        wherevals = Object.values(where);
      }
      const rows = worker.fetchLocal(
        CoreImage.findLocalQuery(wherestr),
        wherevals
      );
      if (rows.length > 0) {
        for (const row of rows) {
          coreImages.push(new CoreImage(row));
        }
      } else {
        worker.log(
          LogLevel.debug,
          'CoreImage.findLocal not found',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImage.findLocal exception:',
        error,
        where,
        orderby
      );
    }
    return coreImages;
  }
  public static findLocalItem(
    worker: PersistentWorker,
    where: object = null,
    orderby = ''
  ): CoreImage {
    let coreImage = null;
    try {
      let wherestr = '';
      let wherevals = [];
      if (where) {
        wherestr = Object.keys(where).join('=? AND ') + '=?';
        wherevals = Object.values(where);
      }
      const rows = worker.fetchLocal(
        CoreImage.findLocalQuery(wherestr),
        wherevals
      );
      if (rows.length > 0) {
        coreImage = new CoreImage(rows[0]);
      } else {
        worker.log(
          LogLevel.debug,
          'CoreImage.findLocalItem not found',
          where,
          orderby
        );
      }
    } catch (error) {
      worker.log(
        LogLevel.warn,
        'CoreImage.findLocalItem exception:',
        error,
        where,
        orderby
      );
    }
    return coreImage;
  }
  public static findByOriginalLocal(
    worker: PersistentWorker,
    userid: number,
    originalName: string,
    originalSize: number,
    originalModified: number,
    imagetype: ImageType
  ): CoreImage {
    return CoreImage.findLocalItem(worker, {
      original_name: originalName,
      original_size: originalSize,
      original_modified: originalModified,
      who_created: userid,
      imagetype: imagetype,
    });
  }
  public static findByUserLocal(
    worker: PersistentWorker,
    userid: number
  ): CoreImage[] {
    return CoreImage.findLocal(worker, { who_created: userid });
  }
  public static findByUserTypeLocal(
    worker: PersistentWorker,
    userid: number,
    imagetype: ImageType
  ): CoreImage[] {
    return CoreImage.findLocal(worker, {
      who_created: userid,
      imagetype: imagetype,
    });
  }
  public static findByIdLocal(
    worker: PersistentWorker,
    imageid: number
  ): CoreImage {
    return CoreImage.findLocalItem(worker, { imageid: imageid });
  }
  public static createLocal(
    worker: PersistentWorker,
    coreImage: CoreImage
  ): CoreImage {
    try {
      if (
        !worker.execLocal(
          coreImage.createLocalQuery(
            worker.nextLocalImageId++,
            PersistentWorker.getTimestamp()
          ),
          coreImage.values()
        )
      ) {
        worker.log(LogLevel.warn, 'CoreImage.createLocal failed');
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreImage.createLocal exception:', error);
    }
    return coreImage;
  }
  public updateLocal(worker: PersistentWorker, timestamp: number = 0): boolean {
    let worked = false;
    try {
      const values = this.values();
      if (this.localId > 0) {
        // switch to server id
        values.push(this.localId);
        if (!worker.execLocal(this.updateLocalWithIdQuery(timestamp), values)) {
          worker.log(LogLevel.warn, 'CoreImage.updateLocal failed');
        } else {
          this.localId = 0;
          worked = true;
        }
      } else {
        values.push(values.shift());
        if (!worker.execLocal(this.updateLocalQuery(timestamp), values)) {
          worker.log(LogLevel.warn, 'CoreImage.updateLocal failed');
        }
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreImage.updateLocal exception:', error);
    }
    return worked;
  }
  public static deleteLocal(
    worker: PersistentWorker,
    coreImage: CoreImage
  ): boolean {
    let worked = false;
    try {
      if (
        !worker.execLocal(
          coreImage.deleteLocalQuery(),
          coreImage.values().slice(0, CoreImage.columnsStart)
        )
      ) {
        worker.log(LogLevel.warn, 'CoreImage.deleteLocal failed');
      } else {
        worked = true;
      }
    } catch (error) {
      worker.log(LogLevel.warn, 'CoreImage.deleteLocal exception:', error);
    }
    return worked;
  }
}
