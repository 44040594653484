import { mergeProps, createSignal } from 'solid-js';
import { CropFrame, Effect } from '~/shared/CoreImageOperation';
import { PersistentClient } from '~/shared/PersistentClient';
import { LogLevel } from '~/components/designer2/DynamicLog';
import {
  unicode2728,
  unicode2764,
  unicode1F603,
  unicode1F389,
  unicode1F44D,
  unicode1F496,
  unicode1F493,
  unicode1F602,
  unicode1F380,
  unicode1F48B,
  unicode1F98B,
} from '~/components/designer2/Stickers';

export const defaultPartitionProps = {
  dim: 128,
  rotation: 0,
  count: 1,
  index: 0,
  selected: 'false',
  classCSS: 'cursor-pointer',
  styleCSS: '',
  styleSVG: '',
  draggable: 'false',
  imgloading: false,
  imageid: 0,
  imglocalfilename: '',
  imgthumbdataurl: '',
  imgtranslatex: 0,
  imgtranslatey: 0,
  imgrotation: 0,
  imgscale: 1,
  imgcropframe: CropFrame.none,
  imgeffect: Effect.none,
  imgbrightness: 1.0,
  imgcontrast: 1.0,
  imgsharpen: 0.0,
  imgblur: 0.0,
  imgposterize: 16,
  imggrayscale: 0.0,
  imgsepia: 0.0,
  imginvert: 0.0,
  imghuerotate: 0,
  imgsaturate: 1.0,
  imgsticker: '',
  bgcolor: '#FFFFFF', // was '#D3D3D3' must be in hex format #RRGGBB
  imgsrc: '',
} as PartitionProps;
export const minPartitionProps = {
  // it is important that these values are whole integer multiples of step
  imgtranslatex: -1.2,
  imgtranslatey: -1.2,
  imgrotation: -180,
  imgscale: 0.2,
  imgbrightness: 0,
  imgcontrast: 0,
  imgsharpen: 0,
  imgblur: 0,
  imgposterize: 1,
  imggrayscale: 0,
  imgsepia: 0,
  imginvert: 0.0,
  imghuerotate: -180,
  imgsaturate: 0,
} as PartitionProps;
export const maxPartitionProps = {
  // it is important that these values are whole integer multiples of step
  imgtranslatex: 1.2, // 300 steps
  imgtranslatey: 1.2,
  imgrotation: 180,
  imgscale: 10,
  imgbrightness: 10,
  imgcontrast: 4,
  imgsharpen: 11,
  imgblur: 32,
  imgposterize: 16,
  imggrayscale: 1,
  imgsepia: 1,
  imginvert: 1.0,
  imghuerotate: 180,
  imgsaturate: 10,
} as PartitionProps;
export const stepPartitionProps = {
  imgtranslatex: 0.004,
  imgtranslatey: 0.004,
  imgrotation: 1,
  imgscale: 0.1,
  imgbrightness: 0.1,
  imgcontrast: 0.01,
  imgsharpen: 0.4,
  imgblur: 0.4,
  imgposterize: 1,
  imggrayscale: 0.01,
  imgsepia: 0.01,
  imginvert: 0.0,
  imghuerotate: 4,
  imgsaturate: 0.1,
} as PartitionProps;

export class PartitionProps {
  public setDefaults() {
    for (const k in defaultPartitionProps) {
      this[k] = defaultPartitionProps[k];
    }
  }
  public static posterizeLevels(levels: number): string {
    const step = 1.0 / levels;
    let levelStr = '';
    for (let l = 0.0; l <= 1.0; l += step) {
      if (levelStr.length > 0) {
        levelStr = levelStr + ' ';
      }
      levelStr = levelStr + parseFloat(l).toFixed(3);
    }
    return levelStr;
  }
  public static uriOf: Map<number, string> = new Map([
    [0x2728, unicode2728], // ✨
    [0x2764, unicode2764], // ❤️
    [0x1f603, unicode1F603], // 😃
    [0x1f389, unicode1F389], // 🎉
    [0x1f44d, unicode1F44D], // 👍
    [0x1f496, unicode1F496], // 💖
    [0x1f493, unicode1F493], // 💓
    [0x1f602, unicode1F602], // 😂
    [0x1f380, unicode1F380], // 🎀
    [0x1f48b, unicode1F48B], // 💋
    [0x1f98b, unicode1F98B], // 🦋
  ]);
  public static getStickerDataurl(sticker: string): string {
    const unicode = sticker.codePointAt(0);
    return PartitionProps.uriOf.has(unicode)
      ? PartitionProps.uriOf.get(unicode)
      : '';
  }
  public static rotateModulo(orientation: number): number {
    while (orientation >= 360) {
      orientation = orientation - 360;
    }
    while (orientation < 0) {
      orientation = orientation + 360;
    }
    return orientation;
  }
  // dimension length of the side of a single square partition
  dim: number;
  // This is the band rotation, not the partition rotation - four possible values: 0, 90, 180, 270
  rotation: number;
  // How many squares make up this partition, default 1, range 1-7 inclusive
  count: number;
  // the index of this partition within the band
  index: number;
  // whether to show this partition as selected, if empty
  selected: string;
  // the id to use for the top level DOM object
  id: string;
  // the id to use for the SVG DOM object
  svgid: string;
  // the CSS class
  classCSS: string;
  // the CSS style
  styleCSS: string;
  // the CSS style for the SVG
  styleSVG: string;
  // onClick function
  onclick: Function;
  // onDragenter function
  ondragenter: Function;
  // onDragover function
  ondragover: Function;
  // onDragleave function
  ondragleave: Function;
  // onDrop function
  ondrop: Function;
  // onDrop function
  draggable: string;
  // onDrop function
  ondragstart: Function;
  // onDrop function
  ondrag: Function;
  // onDrop function
  ondragend: Function;
  // handlers for move, pinch zoom, twist
  onpointerdown: Function;
  onpointermove: Function;
  onpointerup: Function;
  onpointercancel: Function;
  onpointerout: Function;
  onpointerenter: Function;
  onpointerover: Function;
  onpointerleave: Function;
  // the image operation attributes
  imgloading: boolean;
  imageid: number;
  // the name for the local image
  imglocalfilename: string;
  imgthumbdataurl: string;
  imgtranslatex: number;
  imgtranslatey: number;
  imgrotation: number;
  imgscale: number;
  imgcropframe: CropFrame;
  imgeffect: Effect;
  // brightness floating value 0.0 black, 1.0 no change
  imgbrightness: number;
  // contrast floating value 0.0 flat single color, 1.0 no change
  imgcontrast: number;
  // sharpen - convolution matrix 0.0 no change, 4.0 very sharp
  imgsharpen: number;
  // blur is the std deviation for the gaussian bell curve - 0.0 no change, 10 very blurred
  imgblur: number;
  // posterize is the number of levels to quantize each color channel - 256 no change, 32 subtle effect, 5 very posterized
  imgposterize: number;
  imggrayscale: number;
  imgsepia: number;
  imginvert: number;
  imghuerotate: number;
  imgsaturate: number;
  imgsticker: string;
  bgcolor: string; // must be in hex format #RRGGBB
  imgsrc: string = '';
}
export class DynamicPartition {
  sigGetImgLoading: Function;
  sigSetImgLoading: Function;
  sigGetImgLocalFilename: Function;
  sigSetImgLocalFilename: Function;
  sigGetImgThumbDataurl: Function;
  sigSetImgThumbDataurl: Function;
  sigGetImgTranslateX: Function;
  sigSetImgTranslateX: Function;
  sigGetImgTranslateY: Function;
  sigSetImgTranslateY: Function;
  sigGetImgRotation: Function;
  sigSetImgRotation: Function;
  sigGetImgScale: Function;
  sigSetImgScale: Function;
  sigGetImgCropFrame: Function;
  sigSetImgCropFrame: Function;
  sigGetImgEffect: Function;
  sigSetImgEffect: Function;
  sigGetImgBrightness: Function;
  sigSetImgBrightness: Function;
  sigGetImgContrast: Function;
  sigSetImgContrast: Function;
  sigGetImgSharpen: Function;
  sigSetImgSharpen: Function;
  sigGetImgBlur: Function;
  sigSetImgBlur: Function;
  sigGetImgPosterize: Function;
  sigSetImgPosterize: Function;
  sigGetImgGrayscale: Function;
  sigSetImgGrayscale: Function;
  sigGetImgSepia: Function;
  sigSetImgSepia: Function;
  sigGetImgInvert: Function;
  sigSetImgInvert: Function;
  sigGetImgHueRotate: Function;
  sigSetImgHueRotate: Function;
  sigGetImgSaturate: Function;
  sigSetImgSaturate: Function;
  sigGetImgSticker: Function;
  sigSetImgSticker: Function;
  sigGetBgColor: Function;
  sigSetBgColor: Function;
  sigGetImgSrc: Function;
  sigSetImgSrc: Function;
  sigGetSelected: Function;
  sigSetSelected: Function;
  partitionProps: PartitionProps;
  constructor(props: PartitionProps = {}) {
    this.partitionProps = mergeProps(defaultPartitionProps, props);
    [this.sigGetImgLoading, this.sigSetImgLoading] = createSignal(
      this.partitionProps.imgloading
    );
    [this.sigGetImgLocalFilename, this.sigSetImgLocalFilename] = createSignal(
      this.partitionProps.imglocalfilename
    );
    [this.sigGetImgThumbDataurl, this.sigSetImgThumbDataurl] = createSignal(
      this.partitionProps.imgthumbdataurl
    );
    [this.sigGetImgTranslateX, this.sigSetImgTranslateX] = createSignal(
      this.partitionProps.imgtranslatex
    );
    [this.sigGetImgTranslateY, this.sigSetImgTranslateY] = createSignal(
      this.partitionProps.imgtranslatey
    );
    [this.sigGetImgRotation, this.sigSetImgRotation] = createSignal(
      this.partitionProps.imgrotation
    );
    [this.sigGetImgScale, this.sigSetImgScale] = createSignal(
      this.partitionProps.imgscale
    );
    [this.sigGetImgCropFrame, this.sigSetImgCropFrame] = createSignal(
      this.partitionProps.imgcropframe
    );
    [this.sigGetImgEffect, this.sigSetImgEffect] = createSignal(
      this.partitionProps.imgeffect
    );
    [this.sigGetImgBrightness, this.sigSetImgBrightness] = createSignal(
      this.partitionProps.imgbrightness
    );
    [this.sigGetImgContrast, this.sigSetImgContrast] = createSignal(
      this.partitionProps.imgcontrast
    );
    [this.sigGetImgSharpen, this.sigSetImgSharpen] = createSignal(
      this.partitionProps.imgsharpen
    );
    [this.sigGetImgBlur, this.sigSetImgBlur] = createSignal(
      this.partitionProps.imgblur
    );
    [this.sigGetImgPosterize, this.sigSetImgPosterize] = createSignal(
      this.partitionProps.imgposterize
    );
    [this.sigGetImgGrayscale, this.sigSetImgGrayscale] = createSignal(
      this.partitionProps.imggrayscale
    );
    [this.sigGetImgSepia, this.sigSetImgSepia] = createSignal(
      this.partitionProps.imgsepia
    );
    [this.sigGetImgInvert, this.sigSetImgInvert] = createSignal(
      this.partitionProps.imginvert
    );
    [this.sigGetImgHueRotate, this.sigSetImgHueRotate] = createSignal(
      this.partitionProps.imghuerotate
    );
    [this.sigGetImgSaturate, this.sigSetImgSaturate] = createSignal(
      this.partitionProps.imgsaturate
    );
    [this.sigGetImgSticker, this.sigSetImgSticker] = createSignal(
      this.partitionProps.imgsticker
    );
    [this.sigGetBgColor, this.sigSetBgColor] = createSignal(
      this.partitionProps.bgcolor
    );
    [this.sigGetImgLoading, this.sigSetImgLoading] = createSignal(
      this.partitionProps.imgloading
    );
    [this.sigGetImgSrc, this.sigSetImgSrc] = createSignal(
      this.partitionProps.imgsrc
    );
    [this.sigGetSelected, this.sigSetSelected] = createSignal(
      this.partitionProps.selected
    );
  }
  public sigSetFrom(other: DynamicPartition) {
    // this is called when the currently editing partition changes:
    this.clearImgHref();
    this.sigSetCount(Number(other.sigGetCount()));
    this.sigSetIndex(Number(other.sigGetIndex()));
    this.sigSetImgScale(Number(other.sigGetImgScale()));
    this.sigSetImageid(Number(other.sigGetImageid()));
    this.sigSetImgTranslateX(Number(other.sigGetImgTranslateX()));
    this.sigSetImgTranslateY(Number(other.sigGetImgTranslateY()));
    this.sigSetImgRotation(Number(other.sigGetImgRotation()));
    this.sigSetImgCropFrame(other.sigGetImgCropFrame());
    this.sigSetImgEffect(other.sigGetImgEffect());
    this.sigSetImgBrightness(Number(other.sigGetImgBrightness()));
    this.sigSetImgContrast(Number(other.sigGetImgContrast()));
    this.sigSetImgSharpen(Number(other.sigGetImgSharpen()));
    this.sigSetImgBlur(Number(other.sigGetImgBlur()));
    this.sigSetImgPosterize(Number(other.sigGetImgPosterize()));
    this.sigSetImgGrayscale(Number(other.sigGetImgGrayscale()));
    this.sigSetImgSepia(Number(other.sigGetImgSepia()));
    this.sigSetImgInvert(Number(other.sigGetImgInvert()));
    this.sigSetImgHueRotate(Number(other.sigGetImgHueRotate()));
    this.sigSetImgSaturate(Number(other.sigGetImgSaturate()));
    this.sigSetImgSticker(other.sigGetImgSticker());
    this.sigSetBgColor(other.sigGetBgColor());
    this.sigSetImgLocalFilename(other.sigGetImgLocalFilename());
    this.sigSetImgThumbDataurl(other.sigGetImgThumbDataurl());
    this.getImgHref();
  }
  public sigSetFromProps(other: PartitionProps) {
    this.clearImgHref();
    this.sigSetImageid(Number(other.imageid));
    this.sigSetImgTranslateX(Number(other.imgtranslatex));
    this.sigSetImgTranslateY(Number(other.imgtranslatey));
    this.sigSetImgRotation(Number(other.imgrotation));
    this.sigSetImgScale(Number(other.imgscale));
    this.sigSetImgCropFrame(other.imgcropframe);
    this.sigSetImgEffect(other.imgeffect);
    this.sigSetImgBrightness(Number(other.imgbrightness));
    this.sigSetImgContrast(Number(other.imgcontrast));
    this.sigSetImgSharpen(Number(other.imgsharpen));
    this.sigSetImgBlur(Number(other.imgblur));
    this.sigSetImgPosterize(Number(other.imgposterize));
    this.sigSetImgGrayscale(Number(other.imggrayscale));
    this.sigSetImgSepia(Number(other.imgsepia));
    this.sigSetImgInvert(Number(other.imginvert));
    this.sigSetImgHueRotate(Number(other.imghuerotate));
    this.sigSetImgSaturate(Number(other.imgsaturate));
    this.sigSetImgSticker(other.imgsticker);
    this.sigSetBgColor(other.bgcolor);
    this.sigSetImgLocalFilename(other.imglocalfilename);
    this.sigSetImgThumbDataurl(other.imgthumbdataurl);
    this.getImgHref();
  }
  public sigSetToProps(other: PartitionProps): PartitionProps {
    other = this.partitionProps;
    other.imageid = Number(this.sigGetImageid());
    other.imglocalfilename = this.sigGetImgLocalFilename();
    other.imgtranslatex = Number(this.sigGetImgTranslateX());
    other.imgtranslatey = Number(this.sigGetImgTranslateY());
    other.imgrotation = Number(this.sigGetImgRotation());
    other.imgscale = Number(this.sigGetImgScale());
    other.imgcropframe = this.sigGetImgCropFrame();
    other.imgeffect = this.sigGetImgEffect();
    other.imgbrightness = Number(this.sigGetImgBrightness());
    other.imgcontrast = Number(this.sigGetImgContrast());
    other.imgsharpen = Number(this.sigGetImgSharpen());
    other.imgblur = Number(this.sigGetImgBlur());
    other.imgposterize = Number(this.sigGetImgPosterize());
    other.imggrayscale = Number(this.sigGetImgGrayscale());
    other.imgsepia = Number(this.sigGetImgSepia());
    other.imginvert = Number(this.sigGetImgInvert());
    other.imghuerotate = Number(this.sigGetImgHueRotate());
    other.imgsaturate = Number(this.sigGetImgSaturate());
    other.imgsticker = this.sigGetImgSticker();
    other.bgcolor = this.sigGetBgColor();
    return other;
  }
  public clearSettings() {
    this.sigSetImgEffect(defaultPartitionProps.imgeffect);
    this.sigSetImgBrightness(defaultPartitionProps.imgbrightness);
    this.sigSetImgContrast(defaultPartitionProps.imgcontrast);
    this.sigSetImgSharpen(defaultPartitionProps.imgsharpen);
    this.sigSetImgBlur(defaultPartitionProps.imgblur);
    this.sigSetImgPosterize(defaultPartitionProps.imgposterize);
    this.sigSetImgGrayscale(defaultPartitionProps.imggrayscale);
    this.sigSetImgSepia(defaultPartitionProps.imgsepia);
    this.sigSetImgInvert(defaultPartitionProps.imginvert);
    this.sigSetImgHueRotate(defaultPartitionProps.imghuerotate);
    this.sigSetImgSaturate(defaultPartitionProps.imgsaturate);
  }
  public clear(selected: number = 0) {
    this.clearSettings();
    this.clearImgHref();
    this.sigSetImageid(defaultPartitionProps.imageid);
    this.sigSetImgTranslateX(defaultPartitionProps.imgtranslatex);
    this.sigSetImgTranslateY(defaultPartitionProps.imgtranslatey);
    this.sigSetImgRotation(defaultPartitionProps.imgrotation);
    this.sigSetImgScale(defaultPartitionProps.imgscale);
    this.sigSetBgColor(defaultPartitionProps.bgcolor);
    this.sigSetImgCropFrame(defaultPartitionProps.imgcropframe);
    this.sigSetImgSticker(defaultPartitionProps.imgsticker);
    this.sigSetImgLocalFilename(defaultPartitionProps.imglocalfilename);
    this.sigSetImgThumbDataurl(defaultPartitionProps.imgthumbdataurl);
    if (selected > 0) {
      this.sigSetSelected(selected === this.sigGetIndex() ? 'true' : 'false');
    }
    this.getImgHref();
  }
  // attribute signal accessors/updaters
  public sigGetDim(): number {
    return Number(this.partitionProps.dim);
  }
  public sigSetDim(dim: number = 128) {
    this.partitionProps.dim = Number(dim);
  }
  public sigGetRotation(): number {
    return Number(this.partitionProps.rotation);
  }
  public sigSetRotation(rotation: number = 0) {
    this.partitionProps.rotation = Number(rotation);
  }
  public sigGetCount(): number {
    return Number(this.partitionProps.count);
  }
  public sigSetCount(count: number = 1) {
    this.partitionProps.count = Number(count);
  }
  public sigGetIndex(): number {
    return Number(this.partitionProps.index);
  }
  public sigSetIndex(index: number = 0) {
    this.partitionProps.index = Number(index);
  }
  public sigGetImageid(): number {
    return Number(this.partitionProps.imageid);
  }
  public sigSetImageid(imageid: number = 0) {
    this.partitionProps.imageid = Number(imageid);
  }
  clearingImgHref: boolean = false;
  public clearImgHref(): string {
    this.clearingImgHref = true;
    this.sigSetImgSrc('');
    this.sigSetImgThumbDataurl('');
    this.sigSetImgLocalFilename('');
    this.clearingImgHref = false;
  }
  /**
   * Get the url for the main image in this partition.
   * We use the imgthumbdataurl as a quick low resolution version,
   * and request the high-res version if we are above a certain size
   */
  public getImgHref(): string {
    if (!this.clearingImgHref) {
      const hasImgSrc = this.sigGetImgSrc() && this.sigGetImgSrc().length > 0;
      const hasImgThumbDataurl =
        this.sigGetImgThumbDataurl() && this.sigGetImgThumbDataurl().length > 0;
      const hasImgLocalFilename =
        this.sigGetImgLocalFilename() &&
        this.sigGetImgLocalFilename().length > 0;
      if (!hasImgSrc && hasImgThumbDataurl) {
        PersistentClient.log(
          LogLevel.debug,
          'DynamicPartition.getImgHref index:' +
            this.sigGetIndex() +
            ' thumbnail ' +
            this.sigGetImgThumbDataurl().substring(0, 80) +
            ' ...'
        );
        this.sigSetImgSrc(this.sigGetImgThumbDataurl());
      }
      if (
        this.sigGetDim() > 96 &&
        PersistentClient.instance &&
        hasImgLocalFilename
      ) {
        // only client side and larger view
        const imaglocalfilenamewas = this.sigGetImgLocalFilename();
        PersistentClient.log(
          LogLevel.debug,
          'DynamicPartition.getImgHref index:' +
            this.sigGetIndex() +
            ' fetch file ' +
            this.sigGetImgLocalFilename()
        );
        PersistentClient.instance
          .getImgSrc(imaglocalfilenamewas + PersistentClient.imageExt)
          .then((uri) => {
            // we check the filename in case the user has switched partitions before the high-res image arrived
            PersistentClient.log(
              LogLevel.debug,
              'DynamicPartition.getImgHref index:' +
                this.sigGetIndex() +
                ' got file ' +
                this.sigGetImgLocalFilename() +
                ' compare ' +
                imaglocalfilenamewas +
                ' uri:' +
                uri +
                ' compare:' +
                this.sigGetImgSrc().substring(0, 80) +
                ' ...'
            );
            if (
              uri !== this.sigGetImgSrc() &&
              imaglocalfilenamewas === this.sigGetImgLocalFilename()
            ) {
              this.sigSetImgSrc(uri);
            }
          });
      }
    }
    return this.sigGetImgSrc();
  }
  public setImgHref(
    local_filename: string = '',
    thumb_dataurl: string = '',
    imageid: number = 0,
    scale: number = 1.0
  ) {
    this.clearImgHref();
    this.sigSetImgScale(scale);
    this.sigSetImageid(imageid);
    this.sigSetImgLocalFilename(local_filename);
    this.sigSetImgThumbDataurl(thumb_dataurl);
    this.sigSetImgLoading(false);
    this.getImgHref();
  }
  // DOM attributes
  public sigGetId(): string {
    return this.partitionProps.id;
  }
  public sigGetSVGId(): string {
    return this.partitionProps.svgid;
  }
  public sigGetClass(): string {
    return this.partitionProps.classCSS;
  }
  public sigGetStyle(): string {
    return this.partitionProps.styleCSS;
  }
  public sigGetStyleSVG(): string {
    return this.partitionProps.styleSVG;
  }
  // function accessors:
  public sigGetOnClick(): Function {
    return this.partitionProps.onclick;
  }
  public sigGetOnDragenter(): Function {
    return this.partitionProps.ondragenter;
  }
  public sigGetOnDragover(): Function {
    return this.partitionProps.ondragover;
  }
  public sigGetOnDragleave(): Function {
    return this.partitionProps.ondragleave;
  }
  public sigGetOnDrop(): Function {
    return this.partitionProps.ondrop;
  }
  public sigGetDraggable(): string {
    return this.partitionProps.draggable;
  }
  public sigGetOnDragstart(): Function {
    return this.partitionProps.ondragstart;
  }
  public sigGetOnDrag(): Function {
    return this.partitionProps.ondrag;
  }
  public sigGetOnDragend(): Function {
    return this.partitionProps.ondragend;
  }
  public sigGetOnPointerdown(): Function {
    return this.partitionProps.onpointerdown;
  }
  public sigGetOnPointermove(): Function {
    return this.partitionProps.onpointermove;
  }
  public sigGetOnPointerup(): Function {
    return this.partitionProps.onpointerup;
  }
  // Pointer cancel, out, leave can usually be handled by the pointerup event
  public sigGetOnPointercancel(): Function {
    return this.partitionProps.onpointercancel;
  }
  public sigGetOnPointerout(): Function {
    return this.partitionProps.onpointerout;
  }
  public sigGetOnPointerenter(): Function {
    return this.partitionProps.onpointerenter;
  }
  public sigGetOnPointerover(): Function {
    return this.partitionProps.onpointerover;
  }
  public sigGetOnPointerleave(): Function {
    return this.partitionProps.onpointerleave
      ? this.partitionProps.onpointerleave
      : this.partitionProps.onpointerup;
  }
  // We set the id of the filter to avoid a Solid.js bug where svg filter contents do not work with signals
  public sigGetFilterId(): string {
    return (
      'filter' +
      this.sigGetIndex() +
      '_' +
      this.sigGetImgBrightness() +
      '_' +
      this.sigGetImgContrast() +
      '_' +
      this.sigGetImgHueRotate() +
      '_' +
      this.sigGetImgSaturate() +
      '_' +
      this.sigGetImgGrayscale() +
      '_' +
      this.sigGetImgSepia() +
      '_' +
      this.sigGetImgPosterize() +
      '_' +
      this.sigGetImgSharpen() +
      '_' +
      this.sigGetImgBlur()
    );
  }
  public isVertical(): boolean {
    return (
      this.partitionProps.rotation === 90 ||
      this.partitionProps.rotation === 270
    );
  }
  public hasImage(): boolean {
    return this.sigGetImageid() && Number(this.sigGetImageid()) > 0;
  }
  public hasDefaultBgcolor(): boolean {
    return !(
      this.sigGetBgColor() &&
      this.sigGetBgColor() !== defaultPartitionProps.bgcolor
    );
  }
  public hasSticker(): boolean {
    return this.sigGetImgSticker() && this.sigGetImgSticker().length > 0;
  }
  public isEmpty(): boolean {
    return !this.hasImage() && this.hasDefaultBgcolor() && !this.hasSticker();
  }
  public isLoading(): boolean {
    return this.sigGetImgLoading();
  }
  public width(): number {
    return !this.isVertical()
      ? Number(this.sigGetCount()) * Number(this.sigGetDim())
      : Number(this.sigGetDim());
  }
  public height(): number {
    return this.isVertical()
      ? Number(this.sigGetCount()) * Number(this.sigGetDim())
      : Number(this.sigGetDim());
  }
  public posterizeLevels(): string {
    return PartitionProps.posterizeLevels(Number(this.sigGetImgPosterize()));
  }
}
