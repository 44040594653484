// Note that the imports here in this worker require that it be created with type: module
import { PersistentClient, PersistentCall } from './PersistentClient';
import {
  CreateBandProductRequest,
  CreateBandProductResponse,
} from '~/services/server';
import { LogLevel, DynamicLog } from '~/components/designer2/DynamicLog';
import { base64Decode, base64Encode } from '~/utils/base64';
import {
  initializeImageMagick,
  ImageMagick,
  Magick,
  MagickFormat,
  Quantum,
  MagickGeometry,
  MagickColor,
  MagickImage,
  Point,
  CompositeOperator,
} from '@imagemagick/magick-wasm';
import magickWasm from '@imagemagick/magick-wasm/magick.wasm?url';
import sqlite3InitModule from '@sqlite.org/sqlite-wasm';
import { schema, update, databaseVersion } from './schema';
import { CoreUser } from './CoreUser';
import { CoreBand, ProductionStage } from './CoreBand';
import { CoreImage, ImageType } from './CoreImage';
import { CoreImageOperation } from './CoreImageOperation';
import { isServer } from 'solid-js/web';
import { createSvg2png, svg2png, initialize } from 'svg2png-wasm';

if (!isServer) {
  globalThis.onmessage = (event) => {
    try {
      if (typeof event.data === 'string') {
        const data = JSON.parse(event.data) as unknown as PersistentCall;
        if (
          data &&
          Object.hasOwn(data, 'method') &&
          Object.hasOwn(data, 'parameters')
        ) {
          const persistentWorker = PersistentWorker.instance;
          persistentWorker.requestHandler(data);
        }
      }
    } catch (error) {
      console.log('PersistentWorker.onmessage exception:', error);
    }
  };
}
class FileHandleURI {
  file: File;
  uri: string;
  mutex: Promise<boolean>;
  ticket: number = 0;
}
/**
 * This is a headless thread that manages all shared operations, and also broadcasting of updates to
 * the client pages.
 * It maintains the local database, the link to the server database, caching of blob URIs, local image cache addBandImageaccess to the server image store.
 * A worker like this is the only way to be able to use most shared file system operations, and be supported on all browsers.
 */
export class PersistentWorker {
  // the PersistentClient directly connected to this worker
  clientId: number;
  // the PersistentClient reads this from the cookie on startup
  shopifyCustomer: string = '';
  // The current user logged in to shopify
  user: CoreUser;
  // The band we are working on at the moment
  band: CoreBand;

  broadcastChannelRequest: BroadcastChannel;
  broadcastChannelResponse: BroadcastChannel;
  envInitialized: Promise<boolean>;
  imageMagickInitialized: Promise<boolean>;
  svg2PngInitialized: Promise<boolean>;
  opfsInitialized: Promise<boolean>;
  localDbInitialized: Promise<boolean>;
  userLoggedIn: Promise<boolean>;
  bandAvailable: Promise<boolean>;
  directoryHandle: FileSystemDirectoryHandle;
  // database parameters:
  db: sqlite3.oo1.DB;
  svg2pngCreate: unknown;
  static minServerId: number = 65536;
  nextLocalUserId: number = 1;
  nextLocalImageId: number = 1;
  nextLocalBandId: number = 1;
  // set this to true, and the next load will wipe all local data:
  resetAll: boolean = false;
  // image blob caches mapping the local_filename_with_ext to the blob uri:
  imageURI: Map<string, FileHandleURI> = new Map<number, FileHandleURI>();

  // this is the single instance of this class used by all other imports:
  private static _instance: PersistentWorker;
  // the constructor is private to ensure the shared singleton is the only usage:
  private constructor() {
    this.envInitialized = PersistentClient.getEnvParameters(); // don't wait
    this.log(LogLevel.debug, 'PersistentWorker constructor starting ...');
    try {
      this.broadcastChannelRequest = new BroadcastChannel(
        PersistentClient.serviceName + 'Request'
      );
      this.broadcastChannelResponse = new BroadcastChannel(
        PersistentClient.serviceName + 'Response'
      );
      this.broadcastChannelRequest.onmessage = (event) => {
        // The stringify - parse cycle ensures that even packets from other pages can be understood
        const data = JSON.parse(
          JSON.stringify(event.data)
        ) as unknown as PersistentCall;
        this.requestHandler(data);
      };
    } catch (error) {
      this.log(
        LogLevel.error,
        'PersistentWorker.constructor BroadcastChannel exception:',
        error
      );
      this.setFailure(
        'Please update your browser - BroadcastChannel unsupported'
      );
    }
    /**
     * initialize local file store:
     * this sets Promise this.opfsInitialized when completed, and reports:
     * info: PersistentWorker OPFS READY :)
     */
    this.startOpfs();
    /** initialize ImageMagick:
     * this sets Promise this.imageMagickInitialized when completed, and reports, e.g.:
     * info: PersistentWorker ImageMagick 7.1.1-44 Q8 x86_64 76f853c02:20250222 https://imagemagick.org READY :)
     * delegates: freetype heic jng jp2 jpeg jxl lcms lqr openexr png raw tiff webp xml zlib features: Cipher depth: 8
     */
    this.startImageMagick();
    /** initialize Svg2Png:
     * this sets Promise this.svg2PngInitialized and reports:
     * info: PersistentWorker Svg2Png READY :)
     */
    this.startSvg2Png();
    /** async function to start sqlite3 on client
     * this sets Promise this.localDbInitialized and reports, e.g.:
     * info: PersistentWorker SQLite3 READY :) version 3.49.1 storage:opfs-sah created database at /DesignerPersistentStore.sqlite3 versionNumber:2
     */
    this.startLocalDb();
    /** find out our userid
     * this sets Promise this.userLoggedIn and reports:
     * info: PersistentWorker user READY :)
     */
    this.connectUser();
    this.userLoggedIn.then(() => {
      // make sure we can work on a band, even if it is blank:
      this.refreshBand();
      // now get all of this user's data down to the local database:
      this.syncDatabase(this.user);
    });

    this.log(LogLevel.debug, 'PersistentWorker constructor completed');
  }
  public imageIdChange(oldId: number, newId: number) {
    this.log(
      LogLevel.debug,
      'PersistentWorker update image id ' + oldId + ' -> ' + newId
    );
    // update wsImageOperation.imageid, wsBand.imageid
    const timestamp = PersistentWorker.getTimestamp();
    for (const coreImageOperation of CoreImageOperation.findByImageLocal(
      this,
      oldId
    )) {
      coreImageOperation.data.imageid = newId;
      coreImageOperation.updateLocal(this, timestamp);
      coreImageOperation.updateServer(this); // no need to wait
    }
    for (const coreBand of CoreBand.findByImageLocal(this, oldId)) {
      coreBand.data.imageid = newId;
      coreBand.updateLocal(this, timestamp);
      coreBand.updateServer(this); // no need to wait
    }
    // also this.band
    if (this.band) {
      this.band.imageIdChange(oldId, newId);
    }
    this.broadcastMessage('imageIdChange', oldId, newId);
  }
  public userIdChange(oldId: number, newId: number) {
    this.log(
      LogLevel.debug,
      'PersistentWorker update user id ' + oldId + ' -> ' + newId
    );
    // need to update wsImage.who_created, wsBand.who_created, wsBand.who_updated, wsImageOperation.who_created
    const timestamp = PersistentWorker.getTimestamp();
    for (const coreImage of CoreImage.findByUserLocal(this, oldId)) {
      coreImage.data.who_created = newId;
      coreImage.updateLocal(this, timestamp);
      coreImage.updateServer(this); // no need to wait
    }
    for (const coreBand of CoreBand.findByUserCreatModLocal(this, oldId)) {
      if (coreBand.data.who_created === oldId) {
        coreBand.data.who_created = newId;
      }
      if (coreBand.data.who_updated === oldId) {
        coreBand.data.who_updated = newId;
      }
      coreBand.updateLocal(this, timestamp);
      coreBand.updateServer(this); // no need to wait
    }
    for (const coreImageOperation of CoreImageOperation.findByUserLocal(
      this,
      oldId
    )) {
      coreImageOperation.data.who_created = newId;
      coreImageOperation.updateLocal(this, timestamp);
      coreImageOperation.updateServer(this); // no need to wait
    }
    // also this.user and this.band
    if (this.user) {
      this.user.userIdChange(oldId, newId);
    }
    if (this.band) {
      this.band.userIdChange(oldId, newId);
    }
    this.broadcastMessage('userIdChange', oldId, newId);
  }
  public bandIdChange(oldId: number, newId: number) {
    this.log(
      LogLevel.debug,
      'PersistentWorker update band id ' + oldId + ' -> ' + newId
    );
    // need to update wsImageOperation.bandid
    for (const coreImageOperation of CoreImageOperation.findByBandLocal(
      this,
      oldId
    )) {
      coreImageOperation.data.bandid = newId;
      coreImageOperation.updateLocal(this, timestamp);
      coreImageOperation.updateServer(this); // no need to wait
    }
    // also this.band
    if (this.band) {
      this.band.bandIdChange(oldId, newId);
    }
    this.broadcastMessage('bandIdChange', oldId, newId);
  }
  public async syncImageOperations(coreBandids: number[]) {
    // for each table wsImage, wsBand, wsImageOperation get all the server rows relevant for the user,
    // and compare each in turn to the local database to see what needs updating
    for (const bandid of coreBandids) {
      for (const coreImageOperation of await CoreImageOperation.findByBandServer(
        this,
        bandid
      )) {
        const localImageOperation = CoreImageOperation.findByWhoWhenLocal(
          this,
          coreImageOperation.data.who_created,
          coreImageOperation.data.created_timestamp
        );
        if (localImageOperation) {
          // exists, see if it needs updating:
          if (localImageOperation.data.bandid < PersistentClient.minServerId) {
            coreImageOperation.updateLocal(this);
          } else if (
            localImageOperation.data.updated_timestamp >
            coreImageOperation.data.updated_timestamp
          ) {
            // update server from local
            localImageOperation.updateServer(this);
          } else if (
            localImageOperation.data.updated_timestamp <
            coreImageOperation.data.updated_timestamp
          ) {
            // update local from server
            coreImageOperation.updateLocal(this);
          }
        } else {
          CoreImageOperation.createLocal(this, coreImageOperation);
        }
      }
    }
    this.log(LogLevel.debug, 'PersistentWorker.syncImageOperations completed');
  }
  public async syncBands(coreUser: CoreUser) {
    // get all the server rows relevant for the user,
    // and compare each in turn to the local database to see what needs updating
    const bandids = new Array<number>();
    for (const coreBand of await CoreBand.findByUserServer(
      this,
      coreUser.data.userid
    )) {
      bandids.push(coreBand.data.bandid);
      const localBand = CoreBand.findByWhoWhenLocal(
        this,
        coreBand.data.who_created,
        coreBand.data.created_timestamp
      );
      if (localBand) {
        // exists, see if it needs updating:
        if (localBand.data.bandid < PersistentClient.minServerId) {
          const localId = coreBand.data.bandid;
          coreBand.localId = localId;
          coreBand.updateLocal(this);
          // update bandid in Local ImageOperations
          this.bandIdChange(localId, coreBand.data.bandid);
        } else if (
          localBand.data.updated_timestamp > coreBand.data.updated_timestamp
        ) {
          // update server from local
          localBand.updateServer(this);
        } else if (
          localBand.data.updated_timestamp < coreBand.data.updated_timestamp
        ) {
          // update local from server
          coreBand.updateLocal(this);
        }
      } else {
        CoreBand.createLocal(this, coreBand);
      }
    }
    this.log(LogLevel.debug, 'PersistentWorker.syncBands completed');
    await this.syncImageOperations(bandids);
  }
  public async syncImages(coreUser: CoreUser) {
    // get all the server rows relevant for the user,
    // and compare each in turn to the local database to see what needs updating
    await this.opfsInitialized;
    const localFilenames = new Array<string>();
    for (let coreImage of await CoreImage.findByUserServer(
      this,
      coreUser.data.userid
    )) {
      try {
        const localImage = CoreImage.findByOriginalLocal(
          this,
          coreImage.data.who_created,
          coreImage.data.original_name,
          coreImage.data.original_size,
          coreImage.data.original_modified,
          coreImage.data.imagetype
        );
        if (localImage) {
          // exists, see if it needs updating:
          if (localImage.data.imageid < PersistentClient.minServerId) {
            const localId = localImage.data.imageid;
            coreImage.localId = localId;
            coreImage.updateLocal(this);
            // update imageid in Local ImageOperations
            this.imageIdChange(localId, coreImage.data.imageid);
          } else if (
            localImage.data.updated_timestamp > coreImage.data.updated_timestamp
          ) {
            // update server from local
            await localImage.updateServer(this);
            coreImage = localImage;
          } else if (
            localImage.data.updated_timestamp < coreImage.data.updated_timestamp
          ) {
            // update local from server
            coreImage.updateLocal(this);
          }
        } else {
          CoreImage.createLocal(this, coreImage);
        }
        // now check the local file exists:
        localFilenames.push(coreImage.getLocalFilename());
        this.downloadServerImageIff(coreImage).then((downloaded) => {
          if (downloaded) {
            this.broadcastMessage('refreshImages');
          } else if (
            coreImage.hasLocalFilename() &&
            !coreImage.hasThumbDataurl()
          ) {
            this.createThumbDataurl(coreImage.getLocalFilename()).then(
              (thumb_dataurl) => {
                coreImage.data.thumb_dataurl = thumb_dataurl;
                if (coreImage.hasThumbDataurl()) {
                  coreImage.updateServer(this);
                }
              }
            );
          }
        });
      } catch (error) {
        this.log(
          LogLevel.warn,
          'PersistentWorker.syncImages server image exception:',
          error
        );
      }
    }
    for (let localImage of CoreImage.findByUserLocal(
      this,
      coreUser.data.userid
    )) {
      try {
        // exists, see if it needs updating:
        if (localImage.data.imageid < PersistentClient.minServerId) {
          localImage = await CoreImage.createServer(this, localImage);
        }
        if (
          localImage.hasLocalFilename() &&
          !localImage.hasThumbDataurl() &&
          !localFilenames.includes(localImage.getLocalFilename())
        ) {
          localImage.data.thumb_dataurl = await this.createThumbDataurl(
            localImage.data.local_filename + PersistentClient.imageExt
          );
          if (localImage.hasThumbDataurl()) {
            localImage.updateServer(this);
          }
        }
      } catch (error) {
        this.log(
          LogLevel.warn,
          'PersistentWorker.syncImages local update exception:',
          error
        );
      }
    }
    this.log(LogLevel.debug, 'PersistentWorker.syncImages completed');
  }
  public async syncDatabase(coreUser: CoreUser) {
    // for each table wsImage, wsBand, wsImageOperation get all the server rows relevant for the user,
    // and compare each in turn to the local database to see what needs updating
    await this.syncImages(coreUser);
    await this.syncBands(coreUser);
  }
  public async findCreateUser(shopifyCustomer: string = ''): CoreUser {
    let coreUser = null;
    try {
      if (shopifyCustomer > 0) {
        coreUser = CoreUser.findByShopifyCustomerLocal(this, shopifyCustomer);
        if (!coreUser) {
          // not local
          coreUser = await CoreUser.findByShopifyCustomerServer(
            this,
            shopifyCustomer
          );
          if (!coreUser) {
            // create on server
            coreUser = new CoreUser({ shopify_customer: shopifyCustomer });
            await this.fetchShopify(coreUser);
            coreUser = await CoreUser.createServer(this, coreUser);
          }
          if (coreUser) {
            // now on the server, but not local
            coreUser = CoreUser.createLocal(this, coreUser);
          }
        } else {
          // found locally, update from shopify
          await this.fetchShopify(coreUser);
          coreUser.updateLocal(this);
        }
      } else {
        // create a new one
        coreUser = CoreUser.createLocal(this, new CoreUser());
        await CoreUser.createServer(this, coreUser);
      }
    } catch (error) {
      this.log(
        LogLevel.warn,
        'PersistentWorker.findCreateUser exception:',
        error
      );
    }
    return coreUser;
  }
  private async connectUser() {
    let userResolved = null;
    this.userLoggedIn = new Promise<boolean>((resolve) => {
      userResolved = resolve;
    });
    await this.localDbInitialized;
    const localuserid = this.getLocalUserID();
    let coreUser = null;
    if (localuserid > 0) {
      coreUser = CoreUser.findByUserLocal(this, localuserid);
    }
    if (coreUser && this.shopifyCustomer && this.shopifyCustomer.length > 0) {
      if (
        !coreUser.data.shopify_customer ||
        coreUser.data.shopify_customer.length === 0 ||
        coreUser.data.shopify_customer === this.shopifyCustomer
      ) {
        coreUser.data.shopify_customer = this.shopifyCustomer;
        await this.fetchShopify(coreUser);
        coreUser.updateLocal(this);
      } else {
        // we have changed shopify users, remove the old users data:
        coreUser = null;
      }
    }
    if (!coreUser) {
      coreUser = await this.findCreateUser(this.shopifyCustomer);
    }
    this.user = coreUser;
    this.setLocalUserID(this.user.data.userid);
    userResolved(true);
    this.log(LogLevel.info, 'PersistentWorker user READY :)');
    this.broadcastMessage('updateUser', 0, this.user);
  }
  public async refreshBand(clientId: number = 0) {
    let bandResolved = null;
    let bandRejected = null;
    this.bandAvailable = new Promise<boolean>((resolve, reject) => {
      bandResolved = resolve;
      bandRejected = reject;
    });
    if (this.user) {
      // now find the latest band which is not finished:
      this.band = CoreBand.findByStageLatestLocal(
        this,
        this.user.data.userid,
        ProductionStage.DesignInProgress
      );
      if (!this.band) {
        const newBand = new CoreBand({ who_created: this.user.data.userid });
        await newBand.generateIdentity();
        this.band = CoreBand.createLocal(this, newBand);
        bandResolved(true);
        this.log(LogLevel.info, 'PersistentWorker band READY :)');
        CoreBand.createServer(this, this.band);
      } else {
        bandResolved(true);
        this.log(LogLevel.info, 'PersistentWorker band READY :)');
      }
    } else {
      this.log(LogLevel.warn, 'PersistentWorker.refreshBand failed - no user');
      bandRejected('PersistentWorker.refreshBand failed - no user');
    }
    if (this.band) {
      this.band.populateBand(this);
    }
    this.updateBand(clientId);
  }
  private resetOpfs() {
    if (navigator.storage) {
      this.opfsInitialized = new Promise<boolean>((resolve, reject) => {
        try {
          navigator.storage.getDirectory().then((opfsRoot) => {
            opfsRoot
              .removeEntry(PersistentClient.serviceName, { recursive: true })
              .then(() => {
                opfsRoot
                  .getDirectoryHandle(PersistentClient.serviceName, {
                    create: true,
                  })
                  .then((directoryHandle) => {
                    this.directoryHandle = directoryHandle;
                    resolve(true);
                    this.log(
                      LogLevel.info,
                      'PersistentWorker OPFS wiped and READY :)'
                    );
                  });
              });
          });
        } catch (error) {
          this.log(
            LogLevel.error,
            'PersistentWorker.resetOpfs StorageManager unavailable exception:',
            error
          );
          reject(
            'PersistentWorker.resetOpfs StorageManager unavailable ' +
              error.message
          );
        }
      }).catch((error) => {
        this.log(LogLevel.warn, 'PersistentWorker.resetOpfs rejected:', error);
      });
    } else {
      this.log(
        LogLevel.error,
        'PersistentWorker.resetOpfs StorageManager unavailable'
      );
    }
  }
  private startOpfs() {
    this.opfsInitialized = new Promise<boolean>((resolve, reject) => {
      if (!navigator.storage) {
        this.log(LogLevel.error, 'PersistentWorker StorageManager unavailable');
        reject('StorageManager unavailable');
      } else {
        try {
          navigator.storage.getDirectory().then((opfsRoot) => {
            if (this.resetAll) {
              opfsRoot
                .removeEntry(PersistentClient.serviceName, { recursive: true })
                .then(() => {
                  opfsRoot
                    .getDirectoryHandle(PersistentClient.serviceName, {
                      create: true,
                    })
                    .then((directoryHandle) => {
                      this.directoryHandle = directoryHandle;
                      resolve(true);
                      this.log(
                        LogLevel.info,
                        'PersistentWorker OPFS wiped and READY :)'
                      );
                    });
                });
            } else {
              opfsRoot
                .getDirectoryHandle(PersistentClient.serviceName, {
                  create: true,
                })
                .then((directoryHandle) => {
                  this.directoryHandle = directoryHandle;
                  resolve(true);
                  this.log(LogLevel.info, 'PersistentWorker OPFS READY :)');
                });
            }
          });
        } catch (error) {
          this.log(
            LogLevel.error,
            'PersistentWorker StorageManager unavailable exception:',
            error
          );
        }
      }
    }).catch((error) => {
      this.log(LogLevel.warn, 'PersistentWorker.startOpfs rejected:', error);
      this.setFailure(
        'Please update your browser (or disable incognito/private browsing) - StorageManager unsupported'
      );
    });
  }
  private startImageMagick() {
    this.imageMagickInitialized = new Promise<boolean>((resolve) => {
      initializeImageMagick(new URL(magickWasm, import.meta.url)).then(() => {
        resolve(true);
        // ImageMagick 7.1.1-41 Q8 x86_64 bbdcbf78a:20241116 https://imagemagick.org freetype heic jng jp2 jpeg jxl lcms lqr openexr png raw tiff webp xml zlib Cipher 8
        this.log(
          LogLevel.info,
          'PersistentWorker ',
          Magick.imageMagickVersion,
          ' READY :) delegates: ',
          Magick.delegates,
          ' features: ',
          Magick.features,
          ' depth: ',
          Quantum.depth
        );
      });
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.startImageMagick rejected:',
        error
      );
      this.setFailure(
        'Please update your browser (or disable incognito/private browsing) - unknown error'
      );
    });
  }
  private startSvg2Png() {
    this.svg2PngInitialized = new Promise<boolean>((resolve) => {
      initialize(
        fetch(new URL('/assets/svg2png_wasm_bg.wasm', import.meta.url))
      ).then(() => {
        this.svg2pngCreate = createSvg2png();
        resolve(true);
        this.log(LogLevel.info, 'PersistentWorker Svg2Png READY :)');
      });
    }).catch((error) => {
      this.log(LogLevel.warn, 'PersistentWorker Svg2Png rejected:', error);
      this.setFailure(
        'Please update your browser (or disable incognito/private browsing) - Svg2Png failed'
      );
    });
  }
  private async resetLocalDb() {
    await this.localDbInitialized;
    this.localDbInitialized = new Promise<boolean>((resolve, reject) => {
      if (this.runLocalSchema()) {
        this.nextLocalUserId = 1;
        this.nextLocalImageId = 1;
        this.nextLocalBandId = 1;
        resolve(true);
      } else {
        this.log(LogLevel.error, 'PersistentWorker.resetLocalDb failed');
        reject('PersistentWorker.resetLocalDb failed');
      }
    }).catch((error) => {
      this.log(LogLevel.warn, 'PersistentWorker.resetLocalDb rejected:', error);
    });
  }
  public resetLocalAll() {
    this.resetOpfs();
    this.resetLocalDb().then(() => {
      this.connectUser();
      this.userLoggedIn.then(() => {
        // make sure we can work on a band, even if it is blank:
        this.refreshBand();
        // now get all of this user's data down to the local database:
        this.syncDatabase(this.user);
      });
    });
  }
  public resetServerAll() {
    this.resetServerDb();
  }
  private startLocalDb() {
    this.localDbInitialized = new Promise<boolean>((resolve, reject) => {
      // documentation for connecting sqlite3 client here: https://www.npmjs.com/package/@sqlite.org/sqlite-wasm#in-a-worker-with-opfs-if-available
      // API here: https://sqlite.org/wasm/doc/trunk/api-oo1.md
      // examples here: https://sqlite.org/wasm/file/demo-123.js?txt
      try {
        sqlite3InitModule({ print: console.log, printErr: console.error }).then(
          (sqlite3) => {
            const dbname = '/' + PersistentClient.serviceName + '.sqlite3';
            let storageType = 'none';
            if ('opfs' in sqlite3) {
              // We can create a persisted database:
              this.db = new sqlite3.oo1.OpfsDb(dbname);
              storageType = 'opfs';
              const versionNumber = this.initializeLocalDb();
              resolve(true);
              this.log(
                LogLevel.info,
                'PersistentWorker SQLite3 READY :) version ' +
                  sqlite3.version.libVersion +
                  ' storage:' +
                  storageType +
                  ' created database at ' +
                  this.db.filename +
                  ' versionNumber:' +
                  versionNumber
              );
            } else {
              //Safari :
              sqlite3.installOpfsSAHPoolVfs().then((poolUtil) => {
                this.db = new poolUtil.OpfsSAHPoolDb(dbname);
                if (this.db) {
                  storageType = 'opfs-sah';
                } else {
                  // only a transient database is available:
                  this.db = new sqlite3.oo1.DB(dbname, 'ct');
                  storageType = 'ct';
                }
                const versionNumber = this.initializeLocalDb();
                resolve(true);
                this.log(
                  LogLevel.info,
                  'PersistentWorker SQLite3 READY :) version ' +
                    sqlite3.version.libVersion +
                    ' storage:' +
                    storageType +
                    ' created database at ' +
                    this.db.filename +
                    ' versionNumber:' +
                    versionNumber
                );
              });
            }
          }
        );
      } catch (err) {
        reject('Initialization error:' + err.name + ': ' + err.message);
        this.log(LogLevel.error, 'Initialization error:', err);
      }
    }).catch((error) => {
      this.log(LogLevel.warn, 'PersistentWorker.startLocalDb rejected:', error);
      this.setFailure(
        'Please update your browser (or disable incognito/private browsing) - local storage unavailable'
      );
    });
  }
  private initializeLocalDb(): number {
    let versionNumber = this.getLocalVersionNumber();
    if (versionNumber == 0 || this.resetAll) {
      if (this.runLocalSchema()) {
        versionNumber = this.getLocalVersionNumber();
        this.nextLocalUserId = 1;
        this.nextLocalImageId = 1;
        this.nextLocalBandId = 1;
      } else {
        this.log(LogLevel.error, 'PersistentWorker.initializeLocalDb failed');
      }
    } else {
      if (versionNumber < databaseVersion) {
        versionNumber = this.updateLocalSchema(versionNumber, databaseVersion);
      }
      // database just started:
      this.nextLocalUserId =
        this.fetchLocal(
          'SELECT userid FROM wsUser WHERE userid < ' +
            PersistentWorker.minServerId +
            ' ORDER BY userid DESC LIMIT 1'
        )[0].userid + 1;
      this.nextLocalImageId =
        this.fetchLocal(
          'SELECT imageid FROM wsImage WHERE imageid < ' +
            PersistentWorker.minServerId +
            ' ORDER BY imageid DESC LIMIT 1'
        )[0].imageid + 1;
      this.nextLocalBandId =
        this.fetchLocal(
          'SELECT bandid FROM wsBand WHERE bandid < ' +
            PersistentWorker.minServerId +
            ' ORDER BY bandid DESC LIMIT 1'
        )[0].bandid + 1;
    }
    return versionNumber;
  }
  // remote image functions:
  private async serverCreateImage(
    coreImage: CoreImage,
    blocking: boolean = false
  ) {
    if (coreImage.hasLocalFilename()) {
      const buffer = await CoreImage.getImageByteArrayWorker(
        this,
        coreImage.getLocalFilename()
      );
      const server_filename = await this.uploadServerImage(
        buffer,
        coreImage.data.mimetype
      );
      if (server_filename && server_filename.length > 0) {
        coreImage.data.server_filename = server_filename;
        const thumbBuffer = coreImage.getThumbImageByteArray();
        const server_thumb_filename = await this.uploadServerImage(
          thumbBuffer,
          coreImage.data.mimetype
        );
        coreImage.data.server_thumb_filename = server_thumb_filename;
        if (coreImage.data.imageid >= PersistentClient.minServerId) {
          // already has a server allocated id - must be already created on the server:
          if (!blocking) {
            coreImage.updateServer(this); // do not wait for it to complete
          } else {
            await coreImage.updateServer(this);
          }
        } else {
          if (!blocking) {
            CoreImage.createServer(this, coreImage); // do not wait for it to complete
          } else {
            await CoreImage.createServer(this, coreImage);
          }
        }
      } else {
        this.log(
          LogLevel.warn,
          'PersistentWorker.serverCreateImage failed to upload image'
        );
      }
    } else {
      this.log(
        LogLevel.warn,
        'PersistentWorker.serverCreateImage does not have local filename'
      );
    }
  }
  public async createImage(coreImage: CoreImage, blocking: boolean = false) {
    if (CoreImage.createLocal(this, coreImage)) {
      this.broadcastMessage('refreshImages');
      if (!blocking) {
        this.serverCreateImage(coreImage, blocking); // do not wait for it to complete
      } else {
        await this.serverCreateImage(coreImage, blocking);
      }
    }
  }
  // local database functions:
  private runLocalSchema(): boolean {
    return this.execLocal(schema);
  }
  private updateLocalSchema(fromVersion: number, toVersion: number): number {
    let sql = '';
    let versionNumber = fromVersion;
    for (var index = fromVersion + 1; index <= toVersion; ++index) {
      sql = sql + update[index];
    }
    if (this.execLocal(sql)) {
      versionNumber = toVersion;
    } else {
      this.log(
        LogLevel.warn,
        'PersistentWorker.updateLocalSchema ' +
          fromVersion +
          ' -> ' +
          toVersion +
          ' failed to run ' +
          sql
      );
    }
    return versionNumber;
  }
  private getLocalUserID(): number {
    let userid = 0;
    const resultRows = this.fetchLocal(
      'SELECT userid FROM wsInfo ORDER BY last_accessed DESC LIMIT 1'
    );
    if (resultRows.length > 0) {
      userid = resultRows[0].userid;
    }
    return userid;
  }
  private setLocalUserID(userid: number): boolean {
    return this.execLocal(
      'UPDATE wsInfo SET userid=' +
        userid +
        ',last_accessed=' +
        PersistentWorker.getTimestamp().toString() +
        ' WHERE infoid=1'
    );
  }
  public execLocal(sql: string, bind: unknown[] = []): boolean {
    let worked = false;
    if (this.db) {
      try {
        const stmt = this.db.exec({
          sql: sql,
          bind: bind,
        });
        worked = true;
      } catch (error) {
        this.log(
          LogLevel.warn,
          'PersistentWorker.execLocal Could not run ' + sql + ' bind:',
          bind,
          error
        );
      }
    }
    return worked;
  }
  /**
   * fetches data from the local database
   *
   * @returns an array of objects
   */
  public fetchLocal(sql: string, bind: unknown[] = []): unknown[] {
    const resultRows = [];
    if (this.db) {
      try {
        this.db.exec({
          sql: sql,
          bind: bind,
          rowMode: 'object',
          resultRows: resultRows,
        });
        if (!resultRows || resultRows.length === 0) {
          this.log(
            LogLevel.debug,
            'PersistentWorker.fetchLocal no result from fetch ' +
              sql +
              ' bind: ',
            bind
          );
        }
      } catch (error) {
        // normal for database not yet created:
        this.log(LogLevel.warn, 'Could not fetch ' + sql, error);
      }
    }
    return resultRows;
  }
  /**
   * we do not use fetchLocal, because this call is also used to see if the local database needs to be initialized
   */
  private getLocalVersionNumber(): number {
    let versionNumber = 0;
    if (this.db) {
      try {
        const resultRows = [];
        const sql =
          'SELECT version_number FROM wsInfo ORDER BY version_number DESC LIMIT 1';
        this.db.exec({
          sql: sql,
          rowMode: 'object',
          resultRows: resultRows,
        });
        if (!resultRows || resultRows.length === 0) {
          this.log(
            LogLevel.debug,
            'PersistentWorker.getLocalVersionNumber no result'
          );
        } else {
          versionNumber = resultRows[0].version_number;
        }
      } catch (error) {
        // normal for database not yet created:
        this.log(
          LogLevel.debug,
          'Could not fetch getLocalVersionNumber - database will now be initialized',
          error
        );
      }
    }
    return versionNumber;
  }
  public static queryFromObject(data: object): string {
    let query = '';
    for (const column of Object.keys(data)) {
      if (query.length > 0) {
        query = query + '&';
      }
      query = query + column + '=' + encodeURIComponent(data[column]);
    }
    return query;
  }
  public resetServerDb() {
    this.fetchServer('admin', {}, 'GET', 'reset');
  }
  /**
   * fetches data from the server database
   *
   * @returns
   */
  public async fetchServer(
    table: string,
    params: object,
    method: string = 'GET',
    identifier: string = ''
  ): unknown[] {
    let json = null;
    await this.envInitialized; // need the api access key to be fetched from the environment
    const path = table + '/' + identifier;
    params.m = method;
    const query =
      '?' +
      PersistentWorker.queryFromObject(params) +
      '&a=' +
      PersistentClient.wsDatabaseAPIkey;
    params.a = PersistentClient.wsDatabaseAPIkey;
    const buffer = JSON.stringify(params);
    // Make the request
    try {
      // headers: new Headers({'X-Wearshare-Access-Token': PersistentClient.wsDatabaseAPIkey}) Safari fail :(
      const response = await fetch(
        PersistentClient.wsDatabaseAPIURL + path + query,
        {
          method: 'GET',
        }
      );
      if (response.ok) {
        try {
          json = await response.json();
        } catch (error) {
          // we strip the trailing secret before reporting:
          this.log(
            LogLevel.warn,
            'PersistentWorker.fetchServer could not parse content ' +
              method +
              ' ' +
              PersistentClient.wsDatabaseAPIURL +
              path +
              query.substring(
                0,
                query.indexOf('&a=') !== -1
                  ? query.indexOf('&a=') + 3
                  : query.length
              ),
            error,
            response
          );
        }
      } else {
        this.log(
          LogLevel.warn,
          'PersistentWorker.fetchServer failed ' +
            method +
            ' ' +
            PersistentClient.wsDatabaseAPIURL +
            path +
            query.substring(
              0,
              query.indexOf('&a=') !== -1
                ? query.indexOf('&a=') + 3
                : query.length
            ) +
            ' response:',
          response
        );
      }
    } catch (error) {
      this.log(
        LogLevel.error,
        'PersistentWorker.fetchServer exception ' +
          method +
          ' ' +
          PersistentClient.wsDatabaseAPIURL +
          path +
          query.substring(
            0,
            query.indexOf('&a=') !== -1
              ? query.indexOf('&a=') + 3
              : query.length
          ),
        error
      );
    }
    return json;
  }
  /**
					 * upload the image to the server.  example result:
					{
					"filename": "88f11303-911b-432e-86d9-aae6e03cd45c.webp",
					"id": "88f11303-911b-432e-86d9-aae6e03cd45c",
					"meta": {},
					"require_signed": false,
					"uploaded": "2025-02-27T13:09:44.943Z",
					"checksum_type": "md5",
					"checksum": [37,116,12,126,121,179,86,1,183,1,28,157,156,119,30,121],
					"variants": [],
					"success": true
					}
					 */
  public async uploadServerImage(buffer: Uint8Array, mimetype: string): string {
    let server_filename = '';
    let stage = 'start';
    // Make the request
    if (buffer && buffer.length > 0) {
      try {
        stage = 'bytearray';
        if (buffer) {
          stage = 'await env';
          await this.envInitialized;
          stage = 'env available';
          const response = await fetch(PersistentClient.imageStoreURL, {
            method: 'POST',
            /* headers: new Headers({ 'Content-Type': mimetype }), */
            body: buffer,
          });
          stage = 'made request';
          if (response.ok) {
            const json = await response.json();
            server_filename = json.filename;
            this.log(
              LogLevel.debug,
              'PersistentWorker.uploadServerImage worked ' +
                PersistentClient.imageStoreURL +
                '/' +
                json.filename +
                ' result:',
              json
            );
          } else {
            this.log(
              LogLevel.warn,
              'PersistentWorker.uploadServerImage failed',
              response
            );
          }
        } else {
          this.log(
            LogLevel.warn,
            'PersistentWorker.uploadServerImage could not retrieve image byte array'
          );
        }
      } catch (error) {
        this.log(
          LogLevel.warn,
          'PersistentWorker.uploadServerImage(' +
            PersistentClient.imageStoreURL +
            ') stage:' +
            stage +
            ' exception:',
          error
        );
      }
    } else {
      this.log(
        LogLevel.warn,
        'PersistentWorker.uploadServerImage failed local image file data missing'
      );
    }
    return server_filename;
  }
  public async fetchShopify(coreUser: CoreUser): CoreUser {
    // https://shopify.dev/docs/api/admin-graphql/2025-01/queries/customers?language=cURL
    let server_filename = '';
    // Make the request
    /* example
     * {"data":{"customer":{
     * 		"id":"gid://shopify/Customer/7739507867738",
     * 		"firstName":"Kevin",
     * 		"lastName":"Shepherd",
     * 		"email":"kshepherd@scarletline.com",
     * 		"phone":null,
     * 		"numberOfOrders":"0","amountSpent":{"amount":"0.0","currencyCode":"USD"},
     * 		"createdAt":"2024-12-08T17:12:38Z",
     * 		"updatedAt":"2024-12-08T17:14:05Z","note":null,"verifiedEmail":true,"validEmailAddress":true,"tags":[],"lifetimeDuration":"2 months",
     * 		"defaultAddress":{"formattedArea":"Ipswich, United Kingdom","address1":"14 Rectory Lane"},"addresses":[{"address1":"14 Rectory Lane"}],
     * 		"image":{
     * 			"src":"https://cdn.shopify.com/proxy/807bf647a5bd5509813b4b9428e53218d3c2b63ae4b7d614963ac68e53564493/www.gravatar.com/avatar/20c9e70e18eeaadb9d3c94d9c1194d2d.jpg"+
     * 				"?s=2048&d=https%3A%2F%2Fcdn.shopify.com%2Fshopifycloud%2Fshopify%2Fassets%2Fadmin%2Fcustomers%2Fpolaris%2Favatar-1-fce0242e4d66279157b1e260c9163a31bf734548f4d570485a3f543cee0e6664.png"},
     * 		"canDelete":true}},"extensions":{"cost":{"requestedQueryCost":2,"actualQueryCost":2,"throttleStatus":{"maximumAvailable":2000.0,"currentlyAvailable":1998,"restoreRate":100.0}}}}
     */
    if (
      coreUser.data.shopify_customer &&
      coreUser.data.shopify_customer.length > 0
    ) {
      try {
        await this.envInitialized;
        const response = await fetch(PersistentClient.shopifyURL, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            'X-Shopify-Access-Token': PersistentClient.shopifyBearerToken,
          }),
          body:
            '{"query": "query { customer(id: \\"gid://shopify/Customer/' +
            coreUser.data.shopify_customer +
            '\\") { id firstName lastName email createdAt updatedAt image { src } } }" }',
        });
        if (response.ok) {
          const json = await response.json();
          coreUser.data.name = '';
          const hasFirstName =
            json.data.customer.firstName &&
            json.data.customer.firstName.length > 0;
          const hasLastName =
            json.data.customer.lastName &&
            json.data.customer.lastName.length > 0;
          coreUser.data.name = hasFirstName ? json.data.customer.firstName : '';
          if (hasLastName) {
            if (coreUser.data.name.length > 0) {
              coreUser.data.name = coreUser.data.name + ' ';
            }
            coreUser.data.name =
              coreUser.data.name + json.data.customer.lastName;
          }
          coreUser.data.email =
            json.data.customer.email && json.data.customer.email.length > 0
              ? json.data.customer.email
              : '';
          coreUser.data.avatar =
            json.data.customer.image &&
            json.data.customer.image.src &&
            json.data.customer.image.src.length > 0
              ? json.data.customer.image.src
              : '';
          this.log(
            LogLevel.debug,
            'PersistentWorker.fetchShopify worked result:',
            json
          );
        } else {
          this.log(
            LogLevel.warn,
            'PersistentWorker.fetchShopify failed',
            response
          );
        }
      } catch (error) {
        this.log(
          LogLevel.warn,
          'PersistentWorker.fetchShopify exception:',
          error
        );
      }
    } else {
      this.log(
        LogLevel.warn,
        'PersistentWorker.fetchShopify failed shopify_customer number missing'
      );
    }
    return coreUser;
  }
  public async localImageExists(coreImage: CoreImage): boolean {
    let is_local = false;
    if (coreImage.data.server_filename.length > 0) {
      if (coreImage.data.local_filename.length > 0) {
        try {
          await this.opfsInitialized();
          await this.directoryHandle.getFileHandle(
            coreImage.getLocalFilename()
          );
          // if not exist, would have thrown
          is_local = true;
        } catch (error) {
          is_local = false;
        }
      }
    }
    return is_local;
  }
  public async downloadServerImage(coreImage: CoreImage): boolean {
    let downloaded = false;
    await this.envInitialized;
    const serverURL = coreImage.getServerURL();
    if (serverURL && serverURL.length > 0) {
      try {
        const response = await fetch(serverURL, {
          method: 'GET',
        });
        if (response.ok) {
          const bytesBuffer = await response.arrayBuffer();
          const bytes = new Uint8Array(bytesBuffer);
          const fileHandle = await this.directoryHandle.getFileHandle(
            coreImage.getLocalFilename(),
            { create: true }
          );
          const fileAccessHandle = await fileHandle.createSyncAccessHandle();
          fileAccessHandle.write(bytes);
          fileAccessHandle.close();
          if (
            !coreImage.data.thumb_dataurl ||
            coreImage.data.thumb_dataurl.length === 0
          ) {
            coreImage.data.thumb_dataurl = createThumbDataurlFromBytes(bytes);
          }
          downloaded = true;
          // this.log(LogLevel.debug, 'PersistentWorker.downloadServerImage ran '+serverURL+' '+coreImage.data.original_name+' result:'+response.status);
        } else {
          this.log(
            LogLevel.warn,
            'PersistentWorker.downloadServerImage failed ' +
              serverURL +
              ' ' +
              coreImage.data.original_name +
              ' ' +
              response.status
          );
        }
      } catch (error) {
        this.log(
          LogLevel.warn,
          'PersistentWorker.downloadServerImage exception ' +
            serverURL +
            ' ' +
            coreImage.data.original_name +
            ' ',
          error
        );
      }
    }
    return downloaded;
  }
  public async downloadServerImageIff(coreImage: CoreImage): boolean {
    let downloaded = false;
    if (!(await this.localImageExists(coreImage))) {
      downloaded = this.downloadServerImage(coreImage);
    }
    return downloaded;
  }
  public broadcastMessage(method: string, ...args: unknown[]) {
    if (this.broadcastChannelResponse) {
      // only client side
      const response = {
        clientId: 0,
        requestId: 0,
        method: method,
        parameters: args,
      } as PersistentCall;
      this.broadcastChannelResponse.postMessage(response);
    }
  }
  public log(level: LogLevel, ...args: unknown[]) {
    // we lose most of the error data going through the channel, log to console here instead
    DynamicLog.logByLevelCon(level, ...args);
    if (this.broadcastChannelResponse) {
      // only client side
      const allargs = args;
      allargs.unshift(level);
      const response = {
        clientId: this.clientId,
        requestId: 0,
        method: 'logNocon',
        parameters: allargs,
      } as PersistentCall;
      this.broadcastChannelResponse.postMessage(response);
    }
  }
  private setFailure(message: string = '') {
    const response = {
      clientId: 0,
      requestId: 0,
      method: 'setFailure',
      parameters: [message],
    } as PersistentCall;
    if (this.broadcastChannelResponse) {
      this.broadcastChannelResponse.postMessage(response);
    } else {
      // broadcast channel has failed, too
      globalThis.postMessage(JSON.stringify(response));
    }
  }
  private responseHandler(response: PersistentCall) {
    if (this.clientId && this.clientId == response.clientId) {
      // a request from our own client, send directly:
      globalThis.postMessage(JSON.stringify(response));
    } else {
      // a request from a different client:
      if (this.broadcastChannelResponse) {
        // only client side
        this.broadcastChannelResponse.postMessage(response);
      }
    }
  }
  public async requestHandler(request: PersistentCall) {
    try {
      // this.log(LogLevel.debug, 'PersistentWorker received request from client', request);
      if (
        request &&
        Object.hasOwn(request, 'method') &&
        Object.hasOwn(request, 'parameters')
      ) {
        const value = await this.proxycall(
          request.method,
          ...request.parameters
        );
        const response = {
          clientId: request.clientId,
          requestId: request.requestId,
          method: request.method,
          parameters: request.parameters,
          returnValue: value,
        } as PersistentCall;
        this.responseHandler(response);
      } else {
        this.log(
          LogLevel.warn,
          'PersistentWorker.requestHandler unrecognised request:',
          request
        );
      }
    } catch (error) {
      this.log(
        LogLevel.warn,
        'PersistentWorker.requestHandler exception:',
        error,
        request
      );
    }
  }
  // Usage:
  // const perisitentWorker = PersistentWorker.instance;
  public static get instance(): PersistentWorker {
    if (!PersistentWorker._instance) {
      PersistentWorker._instance = new PersistentWorker();
    }
    return PersistentWorker._instance;
  }
  public setClientId(cid: number, shopifyCustomer: number): number {
    this.clientId = cid;
    this.shopifyCustomer = shopifyCustomer;
    return this.clientId;
  }
  public static getTimestamp(): number {
    const timeMs = Date.now();
    return timeMs;
  }
  public async fetchImages(): Array<CoreImage> {
    await this.localDbInitialized;
    return this.user && this.user.data.userid > 0
      ? CoreImage.findByUserTypeLocal(
          this,
          this.user.data.userid,
          ImageType.Source
        )
      : new Array<CoreImage>();
  }
  public async addImage(
    filename: string,
    lastModified: number,
    filesize: number,
    filetype: string,
    dataURL: string,
    blocking: boolean = false
  ): CoreImage {
    let coreImage = null;
    let resolveImage = null;
    const returnImage = new Promise<CoreImage>((resolve) => {
      resolveImage = resolve;
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.addImage return image rejected:',
        error
      );
    });
    await this.localDbInitialized;
    if (this.user) {
      coreImage = CoreImage.findByOriginalLocal(
        this,
        this.user.data.userid,
        filename,
        filesize,
        lastModified,
        ImageType.Source
      );
    }
    if (coreImage) {
      // found existing image
      resolveImage(coreImage);
    } else {
      // new image:
      this.log(
        LogLevel.debug,
        'PersistentWorker.addImage ' + filename + ' create image'
      );
      coreImage = new CoreImage();
      coreImage.data.original_name = filename;
      coreImage.data.original_size = filesize;
      coreImage.data.original_modified = lastModified;
      coreImage.data.who_created = this.user ? this.user.data.userid : 0;
      coreImage.data.original_mimetype = filetype;
      coreImage.data.mimetype = PersistentClient.imageMimeType;
      coreImage.data.local_filename = CoreImage.uniqueguid();
      await this.opfsInitialized;
      // Write the content at the beginning of the file
      this.log(
        LogLevel.debug,
        'PersistentWorker.addImage ' + filename + ' decode image'
      );
      const byteArray = base64Decode(dataURL.split('base64,')[1]);
      await this.imageMagickInitialized;

      const fileHandle = await this.directoryHandle.getFileHandle(
        coreImage.getLocalFilename(),
        { create: true }
      );

      this.log(
        LogLevel.debug,
        'PersistentWorker.addImage ' + filename + ' sync handle'
      );
      const fileAccessHandle = await fileHandle.createSyncAccessHandle();

      const imagesCreated = new Array<Promise<boolean>>();
      let resolveCreateImage = null;
      imagesCreated.push(
        new Promise<boolean>((resolve) => {
          resolveCreateImage = resolve;
        })
      );
      let resolveCreateThumbImage = null;
      imagesCreated.push(
        new Promise<boolean>((resolve) => {
          resolveCreateThumbImage = resolve;
        })
      );
      // There is very little documentation for the wasm, but the methods can be seen here: https://github.com/dlemstra/magick-wasm/blob/main/src/magick-image.ts
      ImageMagick.read(byteArray, (image) => {
        this.log(
          LogLevel.debug,
          'PersistentWorker.addImage ' + filename + ' image read'
        );
        if (
          Math.max(image.height, image.width) > PersistentClient.maxImageDim
        ) {
          image.resize(
            PersistentClient.maxImageDim,
            PersistentClient.maxImageDim
          );
        }
        const thumbScale =
          PersistentClient.thumbnailDim / Math.min(image.height, image.width);
        coreImage.data.width = image.width;
        coreImage.data.height = image.height;
        this.log(
          LogLevel.debug,
          image.toString() +
            ' wxh: ' +
            coreImage.data.width +
            'x' +
            coreImage.data.height
        );
        // webp options https://imagemagick.org/script/webp.php
        // webp:lossless=true
        image.quality = 95;
        image.write(MagickFormat.WebP, (data) => {
          this.log(
            LogLevel.debug,
            'PersistentWorker.addImage ' + filename + ' image write'
          );
          coreImage.data.size = data.length;
          fileAccessHandle.write(data);
          // Flush the changes.
          fileAccessHandle.flush();
          fileAccessHandle.close();
          resolveCreateImage(true);
          this.log(
            LogLevel.debug,
            'PersistentWorker.addImage ' +
              coreImage.data.local_filename +
              ' image created'
          );
        });
        const thumb = image;
        thumb.resize(
          Math.round(thumbScale * thumb.width),
          Math.round(thumbScale * thumb.height)
        );
        thumb.hasAlpha = false;
        thumb.quality = 50;
        thumb.write(MagickFormat.WebP, (datathumb) => {
          const uri = 'data:image/webp;base64,' + base64Encode(datathumb);
          coreImage.data.thumb_dataurl = uri;
          resolveCreateThumbImage(true);
          this.log(
            LogLevel.debug,
            'PersistentWorker.addImage ' +
              coreImage.data.local_filename +
              ' thumbnail length ' +
              uri.length
          );
        });
      });
      Promise.all(imagesCreated).then(() => {
        if (!blocking) {
          this.createImage(coreImage, blocking);
          resolveImage(coreImage);
        } else {
          this.createImage(coreImage, blocking).then(() => {
            resolveImage(coreImage);
          });
        }
      });
    }
    return returnImage;
  }
  public async createThumbDataurlFromBytes(byteArray: Uint8Array): string {
    let resolveUrl = null;
    const returnUrl = new Promise<string>((resolve) => {
      resolveUrl = resolve;
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.createThumbDataurlFromBytes return thumb dataurl rejected:',
        error
      );
    });
    try {
      if (byteArray) {
        ImageMagick.read(byteArray, (thumb) => {
          const thumbScale =
            PersistentClient.thumbnailDim / Math.min(thumb.height, thumb.width);
          thumb.resize(
            Math.round(thumbScale * thumb.width),
            Math.round(thumbScale * thumb.height)
          );
          thumb.hasAlpha = false;
          thumb.quality = 50;
          thumb.write(MagickFormat.WebP, (datathumb) => {
            const uri = 'data:image/webp;base64,' + base64Encode(datathumb);
            resolveUrl(uri);
          });
        });
      } else {
        this.log(
          LogLevel.warn,
          'PersistentWorker.createThumbDataurlFromBytes(' +
            local_filename_with_ext +
            ') file unavailable'
        );
      }
    } catch (error) {
      this.log(
        LogLevel.warn,
        'PersistentWorker.createThumbDataurlFromBytes(' +
          local_filename_with_ext +
          ') exception:',
        error
      );
    }
    return returnUrl;
  }
  public async createThumbDataurl(local_filename_with_ext: string): string {
    let resolveUrl = null;
    const returnUrl = new Promise<string>((resolve) => {
      resolveUrl = resolve;
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.createThumbDataurl return thumb dataurl rejected:',
        error
      );
    });
    try {
      const byteArray = await CoreImage.getImageByteArrayWorker(
        this,
        local_filename_with_ext
      );
      if (byteArray) {
        ImageMagick.read(byteArray, (thumb) => {
          const thumbScale =
            PersistentClient.thumbnailDim / Math.min(thumb.height, thumb.width);
          thumb.resize(
            Math.round(thumbScale * thumb.width),
            Math.round(thumbScale * thumb.height)
          );
          thumb.hasAlpha = false;
          thumb.quality = 50;
          thumb.write(MagickFormat.WebP, (datathumb) => {
            const uri = 'data:image/webp;base64,' + base64Encode(datathumb);
            resolveUrl(uri);
          });
        });
      } else {
        this.log(
          LogLevel.warn,
          'PersistentWorker.createThumbDataurl(' +
            local_filename_with_ext +
            ') file unavailable'
        );
      }
    } catch (error) {
      this.log(
        LogLevel.warn,
        'PersistentWorker.createThumbDataurl(' +
          local_filename_with_ext +
          ') exception:',
        error
      );
    }
    return returnUrl;
  }
  public async generateBand(
    dataURL: string[],
    bleedWidth: number = 51,
    partitionDim: number = 1024,
    blocking: boolean = false
  ): CoreImage {
    let returnImage = null;
    if (this.band) {
      returnImage = this.addBandImages(
        this.band,
        dataURL,
        bleedWidth,
        partitionDim,
        blocking
      );
    }
    return returnImage;
  }
  public async addBandImagesOnWorker(
    coreBand: CoreBand,
    byteArrays: Uint8Array[],
    bleedWidth: number = 51,
    partitionDim: number = 1024,
    blocking: boolean = false
  ): CoreImage {
    // blocking mode ensures the server file and record are created
    let coreImage = null;
    let resolveImage = null;
    const returnImage = new Promise<CoreImage>((resolve) => {
      resolveImage = resolve;
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.addBandImagesOnWorker return image rejected:',
        error
      );
    });
    await this.localDbInitialized;
    if (coreBand.data.imageid > 0) {
      coreImage = CoreImage.findByIdLocal(this, coreBand.data.imageid);
    } else {
      coreImage = CoreImage.createLocal(
        this,
        new CoreImage({
          original_name: coreBand.data.name + PersistentClient.imageExt,
          who_created: this.user ? this.user.data.userid : 0,
          imagetype: ImageType.Band,
        })
      );
      coreBand.data.imageid = coreImage.data.imageid;
      coreBand.updateLocal(this, PersistentWorker.getTimestamp());
      coreBand.updateServer(this);
    }
    coreImage.data.original_name =
      coreBand.data.name + PersistentClient.imageExt;
    coreImage.data.original_modified = PersistentWorker.getTimestamp();
    coreImage.data.who_created = this.user ? this.user.data.userid : 0;
    coreImage.data.original_mimetype = PersistentClient.imageMimeType;
    coreImage.data.mimetype = PersistentClient.imageMimeType;
    coreImage.data.local_filename = CoreImage.uniqueguid();
    coreImage.data.width = partitionDim + 2 * bleedWidth;
    coreImage.data.height = partitionDim * byteArrays.length;
    await this.opfsInitialized;
    await this.imageMagickInitialized;

    const partitionsCreated = new Array<Promise<boolean>>();

    const image = MagickImage.create(
      new MagickColor(255, 255, 255, 255),
      coreImage.data.width,
      coreImage.data.height
    );
    for (let i = 0; i < byteArrays.length; ++i) {
      let resolveCreatePartition = null;
      partitionsCreated.push(
        new Promise<boolean>((resolve, reject) => {
          const byteArray = byteArrays[i];
          if (byteArray) {
            ImageMagick.read(byteArray, (partitionImage) => {
              image.composite(
                partitionImage,
                CompositeOperator.Copy,
                new Point(0, i * partitionDim)
              );
              resolve(true);
            });
          } else {
            reject(
              'PersistentWorker.addBandImagesOnWorker parition image missing'
            );
            this.log(
              LogLevel.warn,
              'PersistentWorker.addBandImagesOnWorker parition image missing'
            );
          }
        }).catch((error) => {
          this.log(
            LogLevel.warn,
            'PersistentWorker.addBandImagesOnWorker ' + i + ' rejected:',
            error
          );
        })
      );
    }

    await Promise.all(partitionsCreated);

    const fileHandle = await this.directoryHandle.getFileHandle(
      coreImage.getLocalFilename(),
      { create: true }
    );

    const fileAccessHandle = await fileHandle.createSyncAccessHandle();

    const imagesCreated = new Array<Promise<boolean>>();
    let resolveCreateImage = null;
    imagesCreated.push(
      new Promise<boolean>((resolve) => {
        resolveCreateImage = resolve;
      })
    );
    let resolveCreateThumbImage = null;
    imagesCreated.push(
      new Promise<boolean>((resolve) => {
        resolveCreateThumbImage = resolve;
      })
    );

    image.quality = 95;
    image.write(MagickFormat.WebP, (data) => {
      coreImage.data.size = data.length;
      coreImage.data.original_size = coreImage.data.size;
      fileAccessHandle.write(data);
      // Flush the changes.
      fileAccessHandle.flush();
      fileAccessHandle.close();
      resolveCreateImage(true);
      if (!blocking) {
        resolveImage(coreImage);
      }
    });
    const thumb = image;
    thumb.crop(
      new MagickGeometry(
        bleedWidth,
        0 * partitionDim,
        partitionDim,
        7 * partitionDim
      )
    );
    const thumbScale = PersistentClient.thumbnailDim / partitionDim;
    thumb.resize(
      Math.round(thumbScale * thumb.width),
      Math.round(thumbScale * thumb.height)
    );
    thumb.rotate(-90);
    thumb.hasAlpha = false;
    thumb.quality = 50;
    thumb.write(MagickFormat.WebP, (datathumb) => {
      const uri = 'data:image/webp;base64,' + base64Encode(datathumb);
      coreImage.data.thumb_dataurl = uri;
      resolveCreateThumbImage(true);
    });

    Promise.all(imagesCreated).then(() => {
      if (!blocking) {
        this.serverCreateImage(coreImage, blocking);
      } else {
        this.serverCreateImage(coreImage, blocking).then(() => {
          resolveImage(coreImage);
        });
      }
    });
    return returnImage;
  }
  public async addBandImages(
    coreBand: CoreBand,
    dataURL: string[],
    bleedWidth: number = 51,
    partitionDim: number = 1024,
    blocking: boolean = false
  ): CoreImage {
    // blocking mode ensures the server file and record are created
    let coreImage = null;
    let resolveImage = null;
    const returnImage = new Promise<CoreImage>((resolve) => {
      resolveImage = resolve;
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.addBandImage return image rejected:',
        error
      );
    });
    await this.localDbInitialized;
    if (coreBand.data.imageid > 0) {
      coreImage = CoreImage.findByIdLocal(this, coreBand.data.imageid);
    } else {
      coreImage = CoreImage.createLocal(
        this,
        new CoreImage({
          original_name: coreBand.data.name + PersistentClient.imageExt,
          who_created: this.user ? this.user.data.userid : 0,
          imagetype: ImageType.Band,
        })
      );
      coreBand.data.imageid = coreImage.data.imageid;
      coreBand.updateLocal(this, PersistentWorker.getTimestamp());
      coreBand.updateServer(this);
    }
    coreImage.data.original_name =
      coreBand.data.name + PersistentClient.imageExt;
    coreImage.data.original_modified = PersistentWorker.getTimestamp();
    coreImage.data.who_created = this.user ? this.user.data.userid : 0;
    coreImage.data.original_mimetype = PersistentClient.imageMimeType;
    coreImage.data.mimetype = PersistentClient.imageMimeType;
    coreImage.data.local_filename = CoreImage.uniqueguid();
    coreImage.data.width = partitionDim + 2 * bleedWidth;
    coreImage.data.height = partitionDim * dataURL.length;
    await this.opfsInitialized;
    await this.imageMagickInitialized;

    const partitionsCreated = new Array<Promise<boolean>>();

    const image = MagickImage.create(
      new MagickColor(255, 255, 255, 255),
      coreImage.data.width,
      coreImage.data.height
    );
    for (let i = 0; i < dataURL.length; ++i) {
      let resolveCreatePartition = null;
      partitionsCreated.push(
        new Promise<boolean>((resolve) => {
          resolveCreatePartition = resolve;
        })
      );
      const byteArray = base64Decode(dataURL[i].split('base64,')[1]);
      ImageMagick.read(byteArray, (partitionImage) => {
        image.composite(
          partitionImage,
          CompositeOperator.Copy,
          new Point(0, i * partitionDim)
        );
        resolveCreatePartition(true);
      });
    }

    await Promise.all(partitionsCreated);

    const fileHandle = await this.directoryHandle.getFileHandle(
      coreImage.getLocalFilename(),
      { create: true }
    );

    const fileAccessHandle = await fileHandle.createSyncAccessHandle();

    const imagesCreated = new Array<Promise<boolean>>();
    let resolveCreateImage = null;
    imagesCreated.push(
      new Promise<boolean>((resolve) => {
        resolveCreateImage = resolve;
      })
    );
    let resolveCreateThumbImage = null;
    imagesCreated.push(
      new Promise<boolean>((resolve) => {
        resolveCreateThumbImage = resolve;
      })
    );

    image.quality = 95;
    image.write(MagickFormat.WebP, (data) => {
      coreImage.data.size = data.length;
      coreImage.data.original_size = coreImage.data.size;
      fileAccessHandle.write(data);
      // Flush the changes.
      fileAccessHandle.flush();
      fileAccessHandle.close();
      resolveCreateImage(true);
      if (!blocking) {
        resolveImage(coreImage);
      }
    });
    const thumb = image;
    thumb.crop(
      new MagickGeometry(
        bleedWidth,
        0 * partitionDim,
        partitionDim,
        7 * partitionDim
      )
    );
    const thumbScale = PersistentClient.thumbnailDim / partitionDim;
    thumb.resize(
      Math.round(thumbScale * thumb.width),
      Math.round(thumbScale * thumb.height)
    );
    thumb.rotate(-90);
    thumb.hasAlpha = false;
    thumb.quality = 50;
    thumb.write(MagickFormat.WebP, (datathumb) => {
      const uri = 'data:image/webp;base64,' + base64Encode(datathumb);
      coreImage.data.thumb_dataurl = uri;
      resolveCreateThumbImage(true);
    });

    Promise.all(imagesCreated).then(() => {
      if (!blocking) {
        this.serverCreateImage(coreImage, blocking);
      } else {
        this.serverCreateImage(coreImage, blocking).then(() => {
          resolveImage(coreImage);
        });
      }
    });
    return returnImage;
  }
  public async addBandImage(
    coreBand: CoreBand,
    dataURL: string,
    bleedWidth: number = 51,
    partitionDim: number = 1024,
    blocking: boolean = false
  ): CoreImage {
    // blocking mode ensures the server file and record are created
    let coreImage = null;
    let resolveImage = null;
    const returnImage = new Promise<CoreImage>((resolve) => {
      resolveImage = resolve;
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.addBandImage return image rejected:',
        error
      );
    });
    await this.localDbInitialized;
    if (coreBand.data.imageid > 0) {
      coreImage = CoreImage.findByIdLocal(this, coreBand.data.imageid);
    } else {
      coreImage = CoreImage.createLocal(
        this,
        new CoreImage({
          original_name: coreBand.data.name + PersistentClient.imageExt,
          who_created: this.user ? this.user.data.userid : 0,
          imagetype: ImageType.Band,
        })
      );
      coreBand.data.imageid = coreImage.data.imageid;
      coreBand.updateLocal(this, PersistentWorker.getTimestamp());
      coreBand.updateServer(this);
    }
    coreImage.data.original_name =
      coreBand.data.name + PersistentClient.imageExt;
    coreImage.data.original_modified = PersistentWorker.getTimestamp();
    coreImage.data.who_created = this.user ? this.user.data.userid : 0;
    coreImage.data.original_mimetype = PersistentClient.imageMimeType;
    coreImage.data.mimetype = PersistentClient.imageMimeType;
    coreImage.data.local_filename = CoreImage.uniqueguid();
    await this.opfsInitialized;
    // Write the content at the beginning of the file
    const byteArray = base64Decode(dataURL.split('base64,')[1]);
    await this.imageMagickInitialized;

    const fileHandle = await this.directoryHandle.getFileHandle(
      coreImage.getLocalFilename(),
      { create: true }
    );

    const fileAccessHandle = await fileHandle.createSyncAccessHandle();

    const imagesCreated = new Array<Promise<boolean>>();
    let resolveCreateImage = null;
    imagesCreated.push(
      new Promise<boolean>((resolve) => {
        resolveCreateImage = resolve;
      })
    );
    let resolveCreateThumbImage = null;
    imagesCreated.push(
      new Promise<boolean>((resolve) => {
        resolveCreateThumbImage = resolve;
      })
    );
    // There is very little documentation for the wasm, but the methods can be seen here: https://github.com/dlemstra/magick-wasm/blob/main/src/magick-image.ts
    ImageMagick.read(byteArray, (image) => {
      coreImage.data.width = image.width;
      coreImage.data.height = image.height;
      this.log(
        LogLevel.debug,
        image.toString() +
          ' wxh: ' +
          coreImage.data.width +
          'x' +
          coreImage.data.height
      );
      // webp options https://imagemagick.org/script/webp.php
      // webp:lossless=true
      image.quality = 95;
      image.write(MagickFormat.WebP, (data) => {
        coreImage.data.size = data.length;
        coreImage.data.original_size = coreImage.data.size;
        fileAccessHandle.write(data);
        // Flush the changes.
        fileAccessHandle.flush();
        fileAccessHandle.close();
        resolveCreateImage(true);
        if (!blocking) {
          resolveImage(coreImage);
        }
      });
      const thumb = image;
      thumb.crop(
        new MagickGeometry(
          bleedWidth,
          0 * partitionDim,
          partitionDim,
          7 * partitionDim
        )
      );
      const thumbScale = PersistentClient.thumbnailDim / partitionDim;
      thumb.resize(
        Math.round(thumbScale * thumb.width),
        Math.round(thumbScale * thumb.height)
      );
      thumb.rotate(-90);
      thumb.hasAlpha = false;
      thumb.quality = 50;
      thumb.write(MagickFormat.WebP, (datathumb) => {
        const uri = 'data:image/webp;base64,' + base64Encode(datathumb);
        coreImage.data.thumb_dataurl = uri;
        resolveCreateThumbImage(true);
      });
    });
    Promise.all(imagesCreated).then(() => {
      if (!blocking) {
        this.serverCreateImage(coreImage, blocking);
      } else {
        this.serverCreateImage(coreImage, blocking).then(() => {
          resolveImage(coreImage);
        });
      }
    });
    return returnImage;
  }
  private async updatePartition(pindex: number, clientId: number = 0) {
    try {
      if (this.band) {
        const partitionProps = this.band.calculateProps(pindex);
        if (
          partitionProps.imageid > 0 &&
          (!partitionProps.imglocalfilename ||
            partitionProps.imglocalfilename.length === 0 ||
            !partitionProps.imgthumbdataurl ||
            partitionProps.imgthumbdataurl.length === 0)
        ) {
          const coreImage = CoreImage.findByIdLocal(
            this,
            partitionProps.imageid
          );
          if (coreImage) {
            partitionProps.imglocalfilename = coreImage.data.local_filename;
            partitionProps.imgthumbdataurl = coreImage.data.thumb_dataurl;
          } else {
            this.log(
              LogLevel.warn,
              'PersistentWorker.updatePartition image not found imageid: ' +
                partitionProps.imageid
            );
          }
        }
        this.broadcastMessage('updatePartition', clientId, partitionProps);
      }
    } catch (error) {
      this.log(
        LogLevel.warn,
        'PersistentWorker.updatePartition exception:',
        error
      );
    }
  }
  public async updateBand(clientId: number = 0) {
    if (this.band) {
      for (let i = 1; i < 8; ++i) {
        this.updatePartition(i, clientId);
      }
      this.broadcastMessage('updateBand', clientId, this.band);
    }
  }
  public async addImageOperation(
    clientId: number,
    coreImageOperationObject: object
  ) {
    await this.userLoggedIn;
    await this.bandAvailable;
    if (this.band && this.user) {
      const coreImageOperation = new CoreImageOperation(
        coreImageOperationObject
      ); // message broadcast looses type info :(
      this.log(
        LogLevel.debug,
        'PersistentWorker.addImageOperation',
        coreImageOperation
      );
      coreImageOperation.data.who_created = this.user.data.userid;
      coreImageOperation.data.bandid = this.band.data.bandid;
      this.band.addImageOperation(this, coreImageOperation);
      this.updatePartition(coreImageOperation.data.partitionid, clientId);
    } else {
      this.log(
        LogLevel.error,
        'PersistentWorker.addImageOperation band or user missing',
        this.band,
        this.user
      );
    }
  }
  public setBandRotation(clientId: number, rotation: number) {
    if (this.band) {
      this.band.data.orientation = rotation;
      this.band.updateLocal(this, PersistentWorker.getTimestamp());
      this.band.updateServer(this);
    } else {
      this.log(LogLevel.error, 'PersistentWorker.setBandRotation band missing');
    }
    this.broadcastMessage('updateBandRotation', clientId, rotation);
  }
  public setBandName(clientId: number, value: string) {
    if (this.band) {
      this.band.data.name = value;
      this.band.updateLocal(this, PersistentWorker.getTimestamp());
      this.band.updateServer(this);
    } else {
      this.log(LogLevel.error, 'PersistentWorker.setBandName band missing');
    }
    this.broadcastMessage('updateBand', clientId, this.band);
  }
  public setBandSecret(clientId: number, value: string) {
    if (this.band) {
      this.band.data.secret_message = value;
      this.band.updateLocal(this, PersistentWorker.getTimestamp());
      this.band.updateServer(this);
    } else {
      this.log(LogLevel.error, 'PersistentWorker.setBandSecret band missing');
    }
    this.broadcastMessage('updateBand', clientId, this.band);
  }
  public addProgress(delta: number = 10) {
    this.broadcastMessage('addProgress', delta);
  }
  public setProgress(total: number = 100) {
    this.broadcastMessage('setProgress', total);
  }
  /**
   * If we are changing the band from ProductionStage.DesignInProgress, we need to replace
   * it as this.band
   */
  public setBandProductionStage(
    clientId: number,
    value: ProductionStage,
    productId: string = '',
    productUrl: string = ''
  ) {
    if (this.band) {
      this.band.data.production_stage = value;
      if (productId && productId.length > 0) {
        this.band.data.product_id = productId;
      }
      if (productUrl && productUrl.length > 0) {
        this.band.data.product_url = productUrl;
      }

      this.band.updateLocal(this, PersistentWorker.getTimestamp());
      this.band.updateServer(this);
      if (value != ProductionStage.DesignInProgress) {
        this.refreshBand();
      }
    } else {
      this.log(
        LogLevel.error,
        'PersistentWorker.setBandProductionStage band missing'
      );
    }
  }
  public setUserEmail(clientId: number, value: string) {
    if (this.user) {
      this.user.data.email = value;
      this.user.updateLocal(this, PersistentWorker.getTimestamp());
      this.user.updateServer(this);
    } else {
      this.log(LogLevel.error, 'PersistentWorker.setUserEmail user missing');
    }
    this.broadcastMessage('updateUser', clientId, this.user);
  }
  public setUserPhone(clientId: number, value: string) {
    if (this.user) {
      this.user.data.phone = value;
      this.user.updateLocal(this, PersistentWorker.getTimestamp());
      this.user.updateServer(this);
    } else {
      this.log(LogLevel.error, 'PersistentWorker.setUserPhone user missing');
    }
    this.broadcastMessage('updateUser', clientId, this.user);
  }
  public async bandDropPartition(fromIndex: number, toIndex: number) {
    if (this.band) {
      this.band.movePartition(
        this,
        fromIndex,
        toIndex,
        PersistentWorker.getTimestamp()
      );
      for (
        let pindex = Math.min(fromIndex, toIndex);
        pindex <= Math.max(fromIndex, toIndex);
        ++pindex
      ) {
        this.updatePartition(pindex);
      }
    } else {
      this.log(LogLevel.error, 'PersistentWorker.setBandRotation band missing');
    }
  }
  public bandLinkPartition(lindex: number) {
    if (this.band) {
      this.band.linkPartition(lindex);
    }
  }
  public bandUnlinkPartition(lindex: number) {
    if (this.band) {
      this.band.unlinkPartition(lindex);
    }
  }
  public async findBands(
    local: boolean = true,
    where: object = null,
    orderby = ''
  ): CoreBand[] {
    let values = [];
    if (local) {
      await this.localDbInitialized;
      values = CoreBand.findLocal(this, where, orderby);
    } else {
      values = await CoreBand.findServer(this, where, orderby);
    }
    return values;
  }
  public async findImages(
    local: boolean = true,
    where: object = null,
    orderby = ''
  ): CoreImage[] {
    let values = [];
    if (local) {
      await this.localDbInitialized;
      values = CoreImage.findLocal(this, where, orderby);
    } else {
      values = await CoreImage.findServer(this, where, orderby);
    }
    return values;
  }
  public async findImageOperations(
    local: boolean = true,
    where: object = null,
    orderby = ''
  ): CoreImageOperation[] {
    let values = [];
    if (local) {
      await this.localDbInitialized;
      values = CoreImageOperation.findLocal(this, where, orderby);
    } else {
      values = await CoreImageOperation.findServer(this, where, orderby);
    }
    return values;
  }
  public async findUsers(
    local: boolean = true,
    where: object = null,
    orderby = ''
  ): CoreUser[] {
    let values = [];
    if (local) {
      await this.localDbInitialized;
      values = CoreUser.findLocal(this, where, orderby);
    } else {
      values = await CoreUser.findServer(this, where, orderby);
    }
    return values;
  }
  public async getUser(): CoreUser {
    await this.userLoggedIn;
    return this.user;
  }
  public async getBand(): CoreBand {
    await this.userLoggedIn;
    return this.band;
  }
  /** Safari often drops blob urls, here is a backup to send the image encoded in a dataurl
   *
   */
  public async getImgDataurl(local_filename_with_ext: string): string {
    const returnDataUrl = new Promise<string>((resolve, reject) => {
      try {
        this.log(
          LogLevel.debug,
          'PersistentWorker.getImgDataurl about to get bytes file ' +
            local_filename_with_ext
        );
        CoreImage.getImageByteArrayWorker(this, local_filename_with_ext).then(
          (bytes) => {
            resolve('data:image/webp;base64,' + base64Encode(bytes));
            this.log(
              LogLevel.debug,
              'PersistentWorker.getImgDataurl got bytes file ' +
                local_filename_with_ext
            );
          }
        );
      } catch (error) {
        reject(
          'PersistentWorker.getImgDataurl failed to read file ' +
            local_filename_with_ext
        );
        this.log(
          LogLevel.warn,
          'PersistentWorker.getImgDataurl failed to read file ' +
            local_filename_with_ext,
          error
        );
      }
    });
    return returnDataUrl;
  }
  public async getImgDataurlPng(local_filename_with_ext: string): string {
    const returnDataUrl = new Promise<string>((resolve, reject) => {
      try {
        CoreImage.getImageByteArrayWorker(this, local_filename_with_ext).then(
          (bytes) => {
            ImageMagick.read(bytes, (image) => {
              //image.quality = 95;
              image.write(MagickFormat.Png, (data) => {
                resolve('data:image/png;base64,' + base64Encode(data));
              });
            });
          }
        );
      } catch (error) {
        reject(
          'PersistentWorker.getImgDataurlPng failed to read file ' +
            local_filename_with_ext
        );
        this.log(
          LogLevel.warn,
          'PersistentWorker.getImgDataurlPng failed to read file ' +
            local_filename_with_ext,
          error
        );
      }
    });
    return returnDataUrl;
  }
  /**
   * Return a blob handle url that can be used in e.g. img.src
   */
  public async getImgSrc(local_filename_with_ext: string): string {
    let uri = '';
    if (
      local_filename_with_ext &&
      local_filename_with_ext.length > 0 &&
      !local_filename_with_ext.startsWith('.') &&
      !local_filename_with_ext.startsWith('-thumb.')
    ) {
      this.log(
        LogLevel.debug,
        'PersistentWorker.getImgSrc enter ' + local_filename_with_ext
      );
      let iref = null;
      if (this.imageURI.has(local_filename_with_ext)) {
        iref = this.imageURI.get(local_filename_with_ext);
        if (iref.mutex) {
          // another thread is actively checking that this uri is correct, simply wait and return
          this.log(
            LogLevel.debug,
            'PersistentWorker.getImgSrc ' +
              local_filename_with_ext +
              ' already exists, wait my turn'
          );
          await iref.mutex;
          iref.mutex = null;
          uri = iref.uri;
          this.log(
            LogLevel.debug,
            'PersistentWorker.getImgSrc ' +
              local_filename_with_ext +
              ' already exists, returning ' +
              uri
          );
        } else {
          // no other thread is checking this, we should ensure it is not stale
          // I have the mutex, everyone else can wait
          let uriResolved = null;
          let uriRejected = null;
          iref.ticket = iref.ticket + 1;
          iref.mutex = new Promise<boolean>((resolve, reject) => {
            uriResolved = resolve;
            uriRejected = reject;
          }).catch((error) => {
            this.log(
              LogLevel.warn,
              'PersistentWorker.getImgSrc existing rejected:',
              error
            );
          });
          // check it is still good
          this.log(
            LogLevel.debug,
            'PersistentWorker.getImgSrc cache hit test file ' +
              local_filename_with_ext +
              ' uri:' +
              iref.uri
          );
          try {
            const blob = await fetch(iref.uri);
            // if we get here, it still works:
            uri = iref.uri;
            iref.mutex = null;
            uriResolved(true);
          } catch (error) {
            // if we throw here, the uri has gone stale
            this.log(
              LogLevel.debug,
              'PersistentWorker.getImgSrc uri gone stale for ' +
                local_filename_with_ext +
                ' uri:' +
                iref.uri
            );
          }
          if (uri.length === 0) {
            URL.revokeObjectURL(iref.uri);
            // try to re-get the url from the existing file
            try {
              iref.uri = URL.createObjectURL(iref.file);
              const blob = await fetch(iref.uri);
              // if we get here, it works:
              uri = iref.uri;
              this.log(
                LogLevel.debug,
                'PersistentWorker.getImgSrc uri renewed from file ' +
                  local_filename_with_ext +
                  ' uri:' +
                  iref.uri
              );
              iref.mutex = null;
              uriResolved(true);
            } catch (error) {
              // if we throw here, the uri has gone stale
              this.log(
                LogLevel.debug,
                'PersistentWorker.getImgSrc uri and file gone stale for ' +
                  local_filename_with_ext +
                  ' uri:' +
                  iref.uri
              );
            }
          }
          if (uri.length === 0) {
            try {
              const fileHandleURI = await this.getFileHandleURI(
                local_filename_with_ext
              );
              iref.file = fileHandleURI.file;
              iref.uri = fileHandleURI.uri;
              uri = iref.uri;
              this.log(
                LogLevel.debug,
                'PersistentWorker.getImgSrc existing cache entry renewed ' +
                  local_filename_with_ext,
                iref.uri
              );
              iref.mutex = null;
              uriResolved(true);
            } catch (error) {
              // if we throw here, the uri has gone stale
              this.log(
                LogLevel.debug,
                'PersistentWorker.getImgSrc could not renew ' +
                  local_filename_with_ext +
                  ' uri:' +
                  fileHandleURI.uri
              );
              iref.mutex = null;
              uriRejected(
                'PersistentWorker.getImgSrc could not renew ' +
                  local_filename_with_ext +
                  ' uri:' +
                  fileHandleURI.uri
              );
            }
          }
        }
      } else {
        // I am the first to ask for this
        let uriResolved = null;
        let uriRejected = null;
        iref = {
          file: null,
          uri: '',
          mutex: new Promise<boolean>((resolve, reject) => {
            uriResolved = resolve;
            uriRejected = reject;
          }).catch((error) => {
            this.log(
              LogLevel.warn,
              'PersistentWorker.getImgSrc new rejected:',
              error
            );
          }),
          ticket: 0,
        } as FileHandleURI;
        this.imageURI.set(local_filename_with_ext, iref);
        try {
          this.log(
            LogLevel.debug,
            'PersistentWorker.getImgSrc new cache entry ' +
              local_filename_with_ext
          );
          const fileHandleURI = await this.getFileHandleURI(
            local_filename_with_ext
          );
          iref.file = fileHandleURI.file;
          iref.uri = fileHandleURI.uri;
          uri = iref.uri;
          this.log(
            LogLevel.debug,
            'PersistentWorker.getImgSrc new cache entry completed ' +
              local_filename_with_ext,
            iref.uri
          );
          iref.mutex = null;
          uriResolved(true);
        } catch (error) {
          // if we throw here, we cannot get the file
          this.log(
            LogLevel.warn,
            'PersistentWorker.getImgSrc could not load file ' +
              local_filename_with_ext,
            error
          );
          iref.mutex = null;
          uriRejected(
            'PersistentWorker.getImgSrc could not load file ' +
              local_filename_with_ext
          );
        }
      }
    }
    return uri;
  }
  private getFileHandleURI(
    local_filename_with_ext: string
  ): Promise<FileHandleURI> {
    let uriResolved = null;
    let uriRejected = null;
    const rtn = new Promise<FileHandleURI>((resolve, reject) => {
      uriResolved = resolve;
      uriRejected = reject;
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.getFileHandleURI rejected:',
        error
      );
    });
    try {
      this.log(
        LogLevel.debug,
        'PersistentWorker.getFileHandleURI await opfsInitialized ' +
          local_filename_with_ext
      );
      this.opfsInitialized.then(() => {
        this.log(
          LogLevel.debug,
          'PersistentWorker.getFileHandleURI await getFileHandle ' +
            local_filename_with_ext
        );
        this.directoryHandle
          .getFileHandle(local_filename_with_ext)
          .then((fileHandle) => {
            fileHandle.getFile().then((storedFile) => {
              this.log(
                LogLevel.debug,
                'PersistentWorker.getFileHandleURI got getFileHandle ' +
                  local_filename_with_ext
              );
              const uri = URL.createObjectURL(storedFile);
              this.log(
                LogLevel.debug,
                'PersistentWorker.getFileHandleURI got uri ' + uri
              );
              const fileHandleURI = {
                file: storedFile,
                uri: uri,
              } as FileHandleURI;
              uriResolved(fileHandleURI);
            });
          });
      });
    } catch (error) {
      this.log(
        LogLevel.warn,
        'PersistentWorker.getFileHandleURI could not find file ' +
          local_filename_with_ext,
        error
      );
      uriRejected(
        'PersistentWorker.getFileHandleURI could not find file ' +
          local_filename_with_ext
      );
    }
    return rtn;
  }
  public getImageById(imageid: number): string {
    return CoreImage.findByIdLocal(this, imageid);
  }
  public async getImgSrcById(imageid: number): string {
    const coreImage = CoreImage.findByIdLocal(this, imageid);
    return coreImage.hasLocalFilename()
      ? this.getImgSrc(coreImage.getLocalFilename() + PersistentClient.imageExt)
      : '';
  }
  public checkWorkerVersion(clientVersion: string): string {
    if (clientVersion !== PersistentClient.versionString) {
      this.log(
        LogLevel.warn,
        'Multiple versions of the app running: client is v' +
          clientVersion +
          ' worker is v' +
          PersistentClient.versionString
      );
    }
    return PersistentClient.versionString;
  }
  public async convertSvg2Png(svgtext: string): Uint8Array {
    await this.svg2PngInitialized;
    return this.svg2pngCreate(svgtext, {});
  }
  public async convertSvg2PngDataurl(svgtext: string): string {
    const uriConverted = new Promise<string>((resolve, reject) => {
      try {
        this.svg2PngInitialized.then(() => {
          svg2png(svgtext, {}).then((pngbytes) => {
            const uri = 'data:image/png;base64,' + base64Encode(pngbytes);
            resolve(uri);
          });
        });
      } catch (error) {
        this.log(
          LogLevel.warn,
          'PersistentWorker.convertSvg2PngDataurl converting ' +
            svgtext.substring(0, 120) +
            ' ... exception',
          error
        );
        reject(
          'PersistentWorker.convertSvg2PngDataurl converting ' +
            svgtext.substring(0, 120) +
            ' ... exception'
        );
      }
    }).catch((error) => {
      this.log(
        LogLevel.warn,
        'PersistentWorker.convertSvg2PngDataurl converting ' +
          svgtext.substring(0, 120) +
          ' ... reject',
        error
      );
    });
    return uriConverted;
  }
  public async convertSvg2PngNonBlocking(svgtext: string): Uint8Array {
    let pngbytes = null;
    try {
      this.log(
        LogLevel.debug,
        'PersistentWorker.convertSvg2PngNonBlocking converting ' +
          svgtext.substring(0, 120) +
          ' ... calling svg2png'
      );
      pngbytes = await svg2png(svgtext);
      this.log(
        LogLevel.debug,
        'PersistentWorker.convertSvg2PngNonBlocking converting ' +
          svgtext.substring(0, 120) +
          ' ... to PNG length:' +
          pngbytes.length
      );
    } catch (error) {
      this.log(
        LogLevel.warn,
        'PersistentWorker.convertSvg2PngNonBlocking converting ' +
          svgtext.substring(0, 120) +
          ' ... exception',
        error
      );
    }
    return pngbytes;
  }
  public async createBandProduct(
    request: CreateBandProductRequest
  ): CreateBandProductResponse {
    return this.fetchServer('service', request, 'GET', 'createbandproduct');
  }
  public async generateBandOnWorker(
    partitionProps: PartitionProps[],
    bleedWidth: number = 51,
    partitionDim: number = 1024,
    blocking: boolean = false
  ): CoreImage {
    let returnImage = null;
    try {
      const coreBand = this.band;
      if (coreBand.data.imageid === 0) {
        const byteArrays = await coreBand.generateArtworksOnWorker(
          this,
          partitionProps,
          bleedWidth,
          partitionDim
        );
        if (byteArrays && byteArrays.length > 0) {
          returnImage = this.addBandImagesOnWorker(
            this.band,
            byteArrays,
            bleedWidth,
            partitionDim,
            blocking
          );
        } else {
          this.log(
            LogLevel.error,
            'PersistentWorker.generateBandOnWorker failed to create image data'
          );
        }
      } else {
        returnImage = this.getImageById(coreBand.data.imageid);
      }
    } catch (error) {
      this.log(
        LogLevel.warn,
        'PersistentWorker.generateBandOnWorker exception:',
        error
      );
    }
    return returnImage;
  }
  public async createProductOnWorker(
    partitionProps: PartitionProps[]
  ): CreateBandProductResponse {
    let response = null;
    if (this.band && this.user) {
      const bleedWidth = 0; //51;
      const partitionDim = 1024;
      // we call this in blocking mode, because we need the server to have the image, and this page will soon exit
      const coreImage = await this.generateBandOnWorker(
        partitionProps,
        bleedWidth,
        partitionDim,
        true
      );
      try {
        const coreBand = this.band;
        const coreUser = this.user;
        const imageGUID = coreImage.getServerID();
        await this.envInitialized;
        this.log(
          LogLevel.debug,
          'PersistentWorker.createProductOnWorker',
          coreImage.data.local_filename,
          coreImage.data.server_filename,
          coreBand,
          coreUser
        );
        const request = {
          bandGUID: coreBand.data.band_guid,
          bandTitle: coreBand.data.name,
          bandDescription: coreBand.data.secret_message,
          userEmail: coreUser.data.email,
          userPhone: coreUser.data.phone,
          imageGUID: imageGUID,
          imageMimetype: coreImage.data.mimetype,
          imageFilename: coreImage.data.server_filename,
          sessionCookieId: PersistentClient.sessionCookieId,
          thumbDataurl: coreImage.getServerThumbURL(),
          identityGuid: coreBand.data.identity_guid,
          identityHash: coreBand.data.identity_hash,
          identityShort: coreBand.data.identity_short,
          productTemplate: PersistentClient.productTemplate,
        };
        response = await this.createBandProduct(request);
        if (response && response.success === true) {
          this.log(
            LogLevel.debug,
            'PersistentWorker.createProductOnWorker [PurchaseModal] success ' +
              response.results[0].productUrl,
            response
          );
        } else {
          this.log(
            LogLevel.warn,
            'PersistentWorker.createProductOnWorker [PurchaseModal] Failed to initiate purchase',
            response
          );
        }
      } catch (error) {
        this.log(
          LogLevel.warn,
          'PersistentWorker.createProductOnWorker exception:',
          error
        );
      }
    } else {
      this.log(
        LogLevel.warn,
        'PersistentWorker.createProductOnWorker user or band missing',
        this.user,
        this.band
      );
    }
    return response;
  }
  functionByName: {
    [K: string]: Function;
  } = {
    setClientId: this.setClientId,
    getTimestamp: PersistentWorker.getTimestamp,
    addImage: this.addImage,
    addImageOperation: this.addImageOperation,
    setBandRotation: this.setBandRotation,
    setBandName: this.setBandName,
    setBandSecret: this.setBandSecret,
    setBandProductionStage: this.setBandProductionStage,
    setUserEmail: this.setUserEmail,
    setUserPhone: this.setUserPhone,
    bandDropPartition: this.bandDropPartition,
    bandLinkPartition: this.bandLinkPartition,
    bandUnlinkPartition: this.bandUnlinkPartition,
    fetchImages: this.fetchImages,
    updateBand: this.updateBand,
    refreshBand: this.refreshBand,
    fetchLocal: this.fetchLocal,
    fetchServer: this.fetchServer,
    findBands: this.findBands,
    findImages: this.findImages,
    findImageOperations: this.findImageOperations,
    findUsers: this.findUsers,
    getUser: this.getUser,
    getBand: this.getBand,
    generateBand: this.generateBand,
    getImgSrc: this.getImgSrc,
    getImgDataurl: this.getImgDataurl,
    getImgDataurlPng: this.getImgDataurlPng,
    getImgSrcById: this.getImgSrcById,
    getImageById: this.getImageById,
    resetLocalAll: this.resetLocalAll,
    resetServerAll: this.resetServerAll,
    createBandProduct: this.createBandProduct,
    convertSvg2Png: this.convertSvg2Png,
    convertSvg2PngDataurl: this.convertSvg2PngDataurl,
    createProductOnWorker: this.createProductOnWorker,
    checkWorkerVersion: this.checkWorkerVersion,
  };
  public async proxycall(method: string, ...args: unknown[]): unknown {
    if (!this.functionByName[method]) {
      throw new Error('PersistentWorker.' + method + ' is not implemented.');
    }
    return this.functionByName[method].apply(this, args);
  }
}
