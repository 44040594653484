import { mergeProps, createSignal } from 'solid-js';

export enum LogLevel {
  none = 0,
  error = 1,
  warn = 2,
  info = 3,
  verbose = 4,
  debug = 5,
}
export class LogProps {
  level: LogLevel;
  nlines: number;
  maxlength: number;
  content: string[];
  message: string;
}
const defaultLogProps = {
  level: LogLevel.info,
  // the maximum number of lines to display, 0 means no limit
  nlines: 60,
  // the maximum length of line to display, 0 means no limit
  maxlength: 4096,
  content: [''],
  message: 'console log connected',
} as DbTableProps;

export class DynamicLog {
  sigGetLevel: Function;
  sigSetLevel: Function;
  sigGetNLines: Function;
  sigSetNLines: Function;
  sigGetMaxLength: Function;
  sigSetMaxLength: Function;
  sigGetContent: Function;
  sigSetContent: Function;
  sigGetMessage: Function;
  sigSetMessage: Function;
  logProps: LogProps;
  // this is the latest instance of this class, used to update the last component created
  private static _instance: DynamicLog;
  constructor(props: LogProps = {}) {
    this.logProps = mergeProps(defaultLogProps, props);
    [this.sigGetLevel, this.sigSetLevel] = createSignal(this.logProps.level);
    [this.sigGetNLines, this.sigSetNLines] = createSignal(this.logProps.nlines);
    [this.sigGetMaxLength, this.sigSetMaxLength] = createSignal(
      this.logProps.maxlength
    );
    [this.sigGetContent, this.sigSetContent] = createSignal(
      this.logProps.content
    );
    [this.sigGetMessage, this.sigSetMessage] = createSignal(
      this.logProps.message
    );
    DynamicLog._instance = this;
  }
  private append(level: LogLevel, args: unknown[]) {
    if (level <= this.logProps.level) {
      let line = LogLevel[level] + ': ';
      for (const element of args) {
        switch (typeof element) {
          case 'string':
            line = line + element;
            break;
          case 'number':
            line = line + element.toString();
            break;
          default:
            line = line + JSON.stringify(element);
            break;
        }
        if (
          this.logProps.maxlength > 0 &&
          line.length > this.logProps.maxlength
        ) {
          break;
        }
      }
      const cpy = this.sigGetContent().slice(-this.logProps.nlines);
      cpy.push(
        this.logProps.maxlength > 0 && line.length > this.logProps.maxlength
          ? line.substring(0, this.logProps.maxlength) + ' ...'
          : line
      );
      this.sigSetContent(cpy);
    }
  }
  public static errorNocon(...args: unknown[]) {
    if (DynamicLog._instance) {
      DynamicLog._instance.append(LogLevel.error, args);
    }
  }
  public static error(...args: unknown[]) {
    console.error(...args);
    DynamicLog.errorNocon(...args);
  }
  public static warnNocon(...args: unknown[]) {
    if (DynamicLog._instance) {
      DynamicLog._instance.append(LogLevel.warn, args);
    }
  }
  public static warn(...args: unknown[]) {
    console.warn(...args);
    DynamicLog.warnNocon(...args);
  }
  public static infoNocon(...args: unknown[]) {
    if (DynamicLog._instance) {
      DynamicLog._instance.append(LogLevel.info, args);
    }
  }
  public static info(...args: unknown[]) {
    console.info(...args);
    DynamicLog.infoNocon(...args);
  }
  public static verboseNocon(...args: unknown[]) {
    if (DynamicLog._instance) {
      DynamicLog._instance.append(LogLevel.verbose, args);
    }
  }
  public static verbose(...args: unknown[]) {
    console.log(...args);
    DynamicLog.verboseNocon(...args);
  }
  public static debugNocon(...args: unknown[]) {
    if (DynamicLog._instance) {
      DynamicLog._instance.append(LogLevel.debug, args);
    }
  }
  public static debug(...args: unknown[]) {
    console.debug(...args);
    DynamicLog.debugNocon(...args);
  }
  public static logByLevel(
    level: LogLevel,
    nocon: boolean,
    ...args: unknown[]
  ) {
    switch (level) {
      case LogLevel.error:
        if (nocon) {
          DynamicLog.errorNocon(...args);
        } else {
          DynamicLog.error(...args);
        }
        break;
      case LogLevel.warn:
        if (nocon) {
          DynamicLog.warnNocon(...args);
        } else {
          DynamicLog.warn(...args);
        }
        break;
      case LogLevel.info:
        if (nocon) {
          DynamicLog.infoNocon(...args);
        } else {
          DynamicLog.info(...args);
        }
        break;
      default:
      case LogLevel.verbose:
        if (nocon) {
          DynamicLog.verboseNocon(...args);
        } else {
          DynamicLog.verbose(...args);
        }
        break;
      case LogLevel.debug:
        if (nocon) {
          DynamicLog.debugNocon(...args);
        } else {
          DynamicLog.debug(...args);
        }
        break;
    }
  }
  public static logByLevelCon(level: LogLevel, ...args: unknown[]) {
    switch (level) {
      case LogLevel.error:
        console.error(...args);
        break;
      case LogLevel.warn:
        console.warn(...args);
        break;
      case LogLevel.info:
        console.info(...args);
        break;
      default:
      case LogLevel.verbose:
        console.log(...args);
        break;
      case LogLevel.debug:
        console.debug(...args);
        break;
    }
  }
}
