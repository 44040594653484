import { createSignal } from 'solid-js';

export class DynamicNav {
  showMenu: boolean = false;
  avatar: string = '';
  username: string = '';
  sigGetShowMenu: Function;
  sigSetShowMenu: Function;
  sigGetAvatar: Function;
  sigSetAvatar: Function;
  sigGetUsername: Function;
  sigSetUsername: Function;
  // there is only ever one instance of this class, we keep track of it here:
  public static instance: DynamicNav;
  constructor() {
    [this.sigGetShowMenu, this.sigSetShowMenu] = createSignal(this.showMenu);
    [this.sigGetAvatar, this.sigSetAvatar] = createSignal(this.avatar);
    [this.sigGetUsername, this.sigSetUsername] = createSignal(this.username);
    DynamicNav.instance = this;
  }
}
